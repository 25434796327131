const langs = {
    gb: {
        _name: 'English',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            updateOrder: 'Update Order',
            uploaded: 'Uploaded'
        },
        newDesign: {
            withdrawalRequests: 'No withdrawal requests yet',
            withdrawalRequestsText1: 'You haven\'t made any withdrawal requests so far.',
            withdrawalRequestsText2: 'Once you initiate a withdrawal, your transaction details will appear here',
            pdfAttachment: 'PDF attachment',
            agent: "Agent",
            closed: 'Closed',
            pending: 'Pending',
            approved: 'Approved',
            declined: 'Declined',
            document: 'Document',
            frontSide: 'Front Side',
            backSide: 'Back Side',
            address: 'Address',
            frontSideDocument: 'Front side of the document',
            creditCard: 'Credit Card',
            selfie: 'Selfie',
            other: 'Other',
            noDocument: 'No documents uploaded yet',
            amountDeposited: 'Amount deposited',
            makeTransfer: 'Make Transfer',
            otherDetails: 'Order details',
            manualWallets: 'Crypto wallets',
            depositText: 'The deposit is processed within 5-10 minutes',
            pendingBankTransfer: 'You have a pending bank transfer deposit.',
            completeDeposit: 'Please follow the instructions below to complete your deposit',
            downloadInvoice: 'Download the invoice',
            payInvoice: 'Pay the invoice in your bank',
            useYourBankText1: 'Use your bank’s online or offline payment system to pay the invoice.',
            useYourBankText2: 'Ensure the payment details are correct.',
            uploadReceipt: 'Upload the confirmation receipt',
            afterMakingPayment: 'After making the payment, upload your receipt to confirm the deposit',
            clickToUpload: 'Click to Upload',
            orDrag: 'or drag and drop',
            fileFormat: 'PDF, JPG, JPEG, PNG less than 10MB',
            waitForDeposit: 'Wait for deposit confirmation',
            onceReceipt: 'Once the receipt is uploaded, we\'ll verify and approve your deposit',
            paymentDetails: 'Payment details',
            nameOnCard: 'Name on card',
            cardNumber: 'Card number',
            expirationDate: 'Expiration date',
            securityCode: 'Security code',
            continue: 'Continue',
            viewAccounts: 'View Accounts',
            continueWithdrawal: 'Continue Withdrawal',
            accountBalance: 'Account Balance',
            accountBalanceText1: 'Why can’t I place a trade?',
            accountsBalanceText2: 'How do I deposit funds?',
            accountsBalanceText3: 'I want to deposit money by Zelle',
            tradingActions: 'Trading Actions',
            tradingActionsText1: 'How do I short sell?',
            tradingActionsText2: 'How can I cancel a trade?',
            tradingActionsText3: 'What is the minimum trade amount?',
            marketInfo: 'Market Info',
            marketInfoText1: 'Where can I see market trends?',
            marketInfoText2: 'What are the top gainers today?',
            securityAndSettings: 'Security and Settings',
            securityAndSettingsText1: 'How do I change my password?',
            securityAndSettingsText2: 'What are the security features?',
            securityAndSettingsText3: 'How do I update my account info?',
            messageCopied: 'Message copied to clipboard',
            addSymbol: 'Add Symbol',
            iHaveRead: 'I have read and agree to the terms and conditions',
            toggleToTurn: 'Toggle to turn on AutoTrade',
            selectTheRisk: 'Select the risk level',
            confirm: 'Confirm',
            cancel: 'Cancel',
            chooseClosingTime: 'Choose a closing time',
            setOrderClosingTime: 'Set Order Closing Time',
            closingTime: 'Closing Time',
            date: 'Date',
            time: 'Time',
            setCloseTime: 'Set Close Time',
            '15m': '15 Minutes',
            '30m': '30 Minutes',
            '1h': '1 hour',
            custom: 'Custom',
            verifyOtp: 'Verify Your OTP',
            enterDigits: 'Enter the 6-digit code sent to your [email/phone]',
            submit: 'Submit',
            order: 'Order',
            closedSuccessfully: 'closed successfully',
            openSuccessfully: 'open successfully',
            deposit: 'deposit',
            withdrawal: 'withdrawal',
            completeVerification: 'Complete Verification',
            verification: 'Verification',
            avatar: 'Avatar',
            saveChanges: 'Save Changes',
            darkMode: 'Dark mode',
            dashboardCurrency: 'Dashboard Currency',
            language: 'Language',
            twoFactor: '2-factor Protection',
            googleTwoFactorIsActive: 'Google 2-factor authenticator is active',
            activateCodeForGoogle: 'Activate code for Google Authenticator',
            ifYouWishDisableEnterCode: 'If you wish to disable protection, enter the authenticator app code',
            scanOrManuallyEnter: 'Scan or manually enter the QR code in Google 2FA to add a token',
            copyCode: 'Copy Code',
            qrCode: 'QR Code',
            activateAndEnterAuthenticator: 'Activate Authenticator and enter a generated code in field below',
            enterTheDigitsCodeGenerated: 'Enter the 6-digit code generated',
            disableTwoFactor: 'Disable Two-Factor Protection',
            activateTwoFactor: 'Activate 2FA Protection',
            fileAdded: 'File successfully added',
            theVerificationStatus: 'The verification status can be viewed in the list of uploaded documents',
            confirmWithdrawal: 'Confirm withdrawal',
            minimumWithdrawal: 'Minimum withdrawal amount is',
            welcomeToTradingPlatform: 'Welcome to the trading platform',
            signIn: 'Sign In',
            activeAccount: 'Active account',
            save: 'Save',
            selectCurrencyAndPeriod: 'Select a currency and a subscription period',
            savingsNotFound: 'Savings Accounts Not Found',
            pleaseCheckInvestment: 'Please check your investment details to continue',
            allPeriodInvestment: 'All period investment',
            pct_0: 'Flexible',
            pct_30: '1 Month',
            pct_60: '1 Quarter',
            pct_90: '6 Month',
            pct_180: '6 Month',
            pct_365: '1 Year',
            savingsCreated: 'Savings account created successfully',
            transferDetails: 'Transfer Details',
            transferAmount: 'Transfer Amount',
            investmentDetails: 'Investment Details',
            investment: 'Investment',
            Period: 'Period',
            created: 'Created',
            releaseTime: 'Release time',
            viewSavingsAccounts: 'View Savings Accounts',
            continueInvestment: 'Continue Investment',
            chooseSubscription: 'Choose Your Subscription',
            selectCurrencyAndSubscription: 'Select a currency and a subscription period to continue.',
            rate: 'Rate',
            accountTransferFunds: 'Account to transfer funds from',
            amount: 'Amount',
            'in': 'in',
            openSavingsAndInvest: 'Open Savings Account and Invest',
            strongSell: 'Strong sell',
            sell: 'Sell',
            neutral: 'Neutral',
            buy: 'Buy',
            stringBuy: 'Strong buy',
            chooseCryptoWallet: 'Choose a Wallet',
            chooseMethod: 'Choose a method',
            maximumDeposit: 'Maximum deposit is',
            minimumDeposit: "Minimum deposit is",
            pleaseCompleteAddress: 'Please complete your address',
            pleaseCompleteCity: 'Please complete your city',
            pleaseCompleteCountry: 'Please complete your country',
            pleaseCompleteZip: 'Please complete your ZIP code',
            amountDeposit: 'Amount deposit',
            transactionStatus: 'Transaction status',
            paymentMethod: 'Payment method',
            depositTo: 'Deposit to',
            depositAddress: 'Deposit Address',
            scanTheQr: 'Scan the QR code or copy the deposit address',
            wallet: 'Wallet',
            copyDepositAddress: 'Copy Deposit Address',
            copy: 'Copy',
            addressCopied: 'Address copied to clipboard',
            '5min:': '5-10 min',
            '2-5days': '2-5 days / 5-10 min',
            otherMethod: 'Other method',
            profileSettings: 'Profile Settings',
            savingsAccounts: 'Savings Accounts',
            subscribe: 'Subscribe',
            toCompleteVerification: 'To complete the verification process for your account, please provide clear and legible copies of the following documents',
            amountToWithdrawal: 'Amount to withdrawal',
            cardDetails: 'Card details',
            cardHolderName: 'Card holder name',
            withdrawalSuccessful: 'Withdrawal Successful',
            selectWithdrawalMethod: 'Select withdrawal method',
            creditDebit: 'Credit/Debit Card',
            crypto: 'Crypto',
            cryptoWalletAddress: 'Crypto wallet address',
            cryptoSubtitle: 'Crypto withdrawals to your wallet 🚀',
            bankWithdrawal: 'Bank Withdrawal',
            bankSubtitle: 'Bank account 🏦',
            zelleSubtitle: 'Fast bank transfers ⚡🏦',
            tradingResults: 'Trading results',
            day: 'Day',
            month: 'Month',
            noTradingData: 'No trading data available',
            startTradingToSee: 'Start trading to see your results here.',
            yourPerformanceMetric: 'Your performance metrics will be displayed once data is available.',
            favorite: 'Favorite',
            all: 'All',
            availableFullTime: 'Available 24/7',
            yourProfileAndSettings: 'Your profile and settings',
            switchToNewDesignMessage: 'Switch to Our New Platform Design Now!',
            switchToOldDesignMessage: 'You can still use the classic design for a limited time',
            switchToOldDesignButton: 'Switch to New Design',
            switchToNewDesignButton: 'Revert to Classic Design',
            howCanIAssist: 'How can I assist you today?',
            generateInvoice: 'Generate Invoice',
            downloadPdf: 'Download PDF',
            confirmDeposit: 'Confirm Deposit',
            maxFileSize: 'The maximum file size is 5MB, and accepted formats are JPEG, PNG, or GIF',
            uploadPhoto: 'Upload Photo',
            featuresDisabled: 'This feature is disabled',
            welcome: 'Welcome',
            signUp: 'Sign Up',
            registration: 'Registration',
            pleaseConfirmAgreement: 'Please confirm customer agreement',
            pleaseEnterPassword: 'Please enter a password',
            passwordNotSame: 'Passwords are not the same',
            noActiveOrders: 'No Active Orders',
            itLikeNoHaveActiveOrders: 'It looks like you don\'t have any active orders at the moment',
            cryptoWallet: 'Crypto wallet',
            bankCard: 'Bank/Card',
            selected: 'Selected',
            amountWithdrawal: 'Amount withdrawn',
            withdrawalTo: 'Withdrawal to',
            governmentIssuedPhoto1: 'Government-issued photo ID (e.g., passport, driver\'s license , national ID card)',
            governmentIssuedPhoto2: 'Residence permit (if applicable)',
            governmentIssuedPhoto3: 'The document must be valid (not expired)',
            governmentIssuedPhoto4: 'The full name, date of birth, and photograph must be visible.',
            uploadRecent1: 'Upload a recent utility bill, bank statement, or rental agreement issued within the last 3 months',
            uploadRecent2: 'Ensure your full name and current residential address are clearly visible.',
            uploadCopy1: 'Upload a copy of the front side of your credit card, ensuring the first six and last four digits are visible',
            uploadCopy2: 'Please cover the middle digits for security',
            takeAndUpload1: 'Take and upload a selfie holding your ID next to your face',
            takeAndUpload2: 'Ensure both your face and the ID are clearly visible',
            otherDocuments: 'Other documents',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Upload a clear and legible image of your Social Security Number (SSN) document. Ensure that all details are visible and free from obstructions.',
            frontSideSsnDocument: 'Front side of the SSN document',
            answerSaved: 'Answers have been saved',
            cryptoWalletDetails: 'Crypto wallet details',
            bitcoinWalletAddress: 'Bitcoin wallet address',
            recovery: 'Recovery',
            passwordRecovery: 'Password recovery',
            searchCountry: 'Search country',
            questionnaire: 'Questionnaire',
            questionnaireText: 'Please complete the questionnaire to verify your identity',
            sendAnswers: 'Send Answers',
        },
        balanceModal: {
            balance: 'Balance',
            credit: 'Credit',
            equity: 'Equity',
            practice: 'PRACTICE',
            real: 'REAL',
            account: 'ACCOUNT',
            invested: 'Invested',
            profit: 'Profit',
            loss: 'Loss',
            margin: 'Margin',
            marginLevel: 'Margin Level',
            marginFree: 'Free Margin'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Deposit'
        },
        sideMenu: {
            marketWatch: 'Market Watch',
            activeOrders: 'Active Orders',
            tradingHistory: 'Trading History',
            economicCalendar: 'Calendar',
            marketNews: 'Market News'
        },
        closePositionModal: {
            closeOrder: 'Close Position',
            areYouSure: 'Are you sure to close the position',
            buy: 'Buy',
            sell: 'Sell',
            yes: 'Yes',
            no: 'No',
        },
        pciDssModal: {
            desc: `Payment Card Industry Data Security Standard (PCI DSS) compliance is required of all entities that store, process, or transmit Visa/Master Card/Stripe
            cardholder data, including financial institutions, merchants and service providers.
            IT is the global security standard for all entities that store, process, or transmit cardholder data and/or sensitive authentication data. PCI DSS sets a baseline level of protection for consumers and helps reduce fraud and data breaches across the entire payment ecosystem.`
        },
        chart: {
            addNewChart: 'Add New Chart',
        },
        symbolsModal: {
            watchlist: 'Watchlist',
            asset: 'Asset',
            price: 'Price',
            changePct: 'Change 24h'
        },
        pendingModal: {
            pending: 'Pending',
            assetPrice: 'Asset Price',
            current: 'Current',
            revert: 'Revert to Market Price',
            automatically: 'Position will be opened automatically when the price reaches this level'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volume',
            lots: 'lots',
            units: 'units',
            currency: 'currency',
            contractSize: 'Contract size',
            position: 'Position',
            margin: 'Margin',
            freeMargin: 'Free Margin',
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            pending: 'Pending',
            market: 'Market',
            leverage: 'Leverage',
            spread: 'Spread',
            notSet: 'Not set',
            at: 'at',
            buy: 'buy',
            sell: 'sell',
        },
        footer: {
            supportBanner: 'EVERY DAY, AROUND THE CLOCK',
            currentTime: 'Current Time',
            liveChat: 'Live Chat'
        },
        statusBar: {
            balance: 'Balance',
            credit: 'Credit',
            equity: 'Equity',
            margin: 'Margin',
            marginLevel: 'Margin level',
            freeMargin: 'Free Margin',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Date Registered',
            userId: 'User ID',
            dashboard: 'Dashboard',
            personalData: 'Personal Data',
            deposit: 'Deposit',
            withdrawFunds: 'Withdraw Funds',
            savings: 'Savings',
            settings: 'Settings',
            logout: 'Logout'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            makeDeposit: 'Make a Deposit',
            practiceAccount: 'Practice Account',
            realAccount: 'Real Account',
            noWithdrawals: 'No Withdrawals',
            easyWithdrawals: 'Easy Withdrawals',
            allAssets: 'All assets available',
            fullFledged: 'Full-ledged platform',
            fillUpTo: 'Fill Up To',
            freeReplenishment: 'Free replenishment',
            topUp: 'Top Up Your Account',
            minimumAmount: 'Minimum amount',
            canSwitch: 'You can switch between your accounts at any moment'
        },
        gridsModal: {
            chartGrids: 'CHART GRIDS',
            chart1: '1 chart',
            chart2: '2 charts',
            chart3: '3 charts',
            chart4: '4 charts',
        },
        noConnectionModal: {
            connectionLost: 'Connection with the server is lost',
            retryNow: 'Retry Now',
        },
        loginModal: {
            loginToTradeRoom: 'Login To Traderoom',
            email: 'Email address',
            enterEmail: 'Enter your email',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA Code (if enabled)',
            twoFactorAuth: 'Two-factor authentication',
            password: 'Password',
            yourPassword: 'Your password',
            signIn: 'Sign In',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Set New Password',
            newPassword: 'New password',
            confirmNewPassword: 'Confirm new password',
            setButton: 'Set'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Create A New Account',
            email: 'Email address',
            firstName: 'First Name',
            lastName: 'Last Name',
            country: 'Country',
            phone: 'Phone',
            password: 'Password',
            createAccount: 'Create Account',
            currency: 'Currency',
            privacyAndPolicy: 'Privacy & Policy',
            customerAgreement: 'Customer Agreement',
        },
        forgotPasswordModal: {
            forgotPassword: 'Forgot Password?',
            submitEmail: 'Please submit an email used for registration, check your inbox and follow the instructions provided',
            submit: 'Submit'
        },
        notifications: {
            error: 'Error',
            success: 'Success',
            deposit: 'Deposit',
            withdrawal: 'Withdrawal',
            depositApproved: 'approved',
            depositDeclined: 'Deposit has been declined',
            withdrawalApproved: 'Withdrawal has been approved',
            withdrawalDeclined: 'Withdrawal has been declined'
        },
        markets: {
            favorites: 'Watchlist',
            all: 'All symbols',
            forex: 'Forex',
            stocks: 'Stocks',
            commodities: 'Commodities',
            indices: 'Indices',
            crypto: 'Crypto',
            metals: 'Metals',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'BUY',
            profitCalculator: 'Profit Calculator',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Market Watch',
            search: 'Search...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volume',
            margin: 'Margin',
            active: 'Active',
            pending: 'Pending',
            activeOrders: 'Active Orders',
            portfolio: 'Portfolio',
            allPositions: 'All Positions',
            noPositionsLine1: 'You have no open',
            noPositionsLine2: 'positions yet',
            show: 'Show',
            more: 'more',
            less: 'less',
            purchaseTime: 'Purchase Time',
            closePosition: 'Close Position',
            priceOpen: 'Purchase Price',
            priceSl: 'Stop Loss Price',
            priceTp: 'Take Profit Price',
            type: 'Position Direction',
            pnl: 'Profit/Loss',
            cancelOrder: 'Cancel Order',
            orderType: 'Order Type',
            cancelled: 'Cancelled',
            tradingHistory: 'Trading History',
            noHistoryLine1: 'You don\'t have any',
            noHistoryLine2: 'closed deals yet',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendar'
        },
        widgetMarketNews: {
            marketNews: 'Market News'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Active Orders',
            ordersHistory: 'Orders History',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Type',
            volume: 'Volume',
            openPrice: 'Open Price',
            openTime: 'Open Time',
            closePrice: 'Close Price',
            closeTime: 'Close Time',
            sl: 'SL',
            tp: 'TP',
            price: 'Price',
            pnl: 'PnL',
            actions: 'Actions',
            edit: 'Edit',
            close: 'Close',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Session Change',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should read the relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'Save',
            cancel: 'Cancel',
        },
        cardBox: {
            name: 'Name',
            cardNumber: 'Card Number',
            expiration: 'Expiration (mm/yy)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Trade",
            startTrading: 'Start Trading',
            deposit: 'Deposit',
            dashboard: 'Dashboard',
            personalInfo: 'Personal Information',
            contactInfo: 'Contact Information',
            withdrawal: 'Withdrawal',
            verification: 'Verification',
            accounts: 'Accounts',
            liveChat: 'Live Chat',
            savings: 'Savings',
            referrals: 'Referral Program',
            settings: 'Settings',
            logOut: 'Log Out',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'MAKE A DEPOSIT',
            instant: 'Instant',
            minutes: 'minutes',
            hours: 'hours',
            days: 'days',
            amount: 'Amount',
            continue: 'Continue',
            qrCode: 'QR CODE',
            depositAddress: 'DEPOSIT ADDRESS',
            copy: 'Copy',
            last: 'Last',
            deposits: 'Deposits',
            time: 'Time',
            currency: 'Currency',
            status: 'Status',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Please wait. Connecting to payment service provider...',
            cardPayment: 'Card payment',
            inOrder: 'In order to send the amount',
            pleaseProvide: 'please provide card details:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'No order yet',
            totalBalance: 'Total Balance',
            totalPnl: 'Total PNL',
            profitableOrders: 'Profitable Orders',
            roi: 'ROI',
            activityLog: 'Activity Log',
            loginFromIp: 'Login from IP',
            tradingResults: 'Trading Results',
            week: 'Week',
            month: 'Month',
            year: 'Year',
            successRate: 'Success rate',
            closedWithProfit: 'Closed With Profit',
            closedWithLoss: 'Closed With Loss',
            account: 'Account',
            balance: 'Balance',
            leverage: 'Leverage',
            credit: 'Credit',
            tradeNow: 'Trade Now',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Total Deposits',
            totalWithdrawals: "Total Withdrawals",
            returnOnInvestment: "Return On Investment"
        },
        fullScreenPersonal: {
            personalDetails: 'Personal Details',
            profilePhoto: 'Profile Photo',
            firstName: 'First Name',
            country: 'Country',
            lastName: 'Last Name',
            address: 'Address',
            email: 'E-mail',
            phone: 'Phone number',
            saveChanges: 'Save Changes',
            state: 'City',
            postcode: 'ZIP',
            created: 'Account created on',
        },
        fullScreenAvatar: {
            dropFile: 'Drop a file on the circle above to upload',
            notAllowed: 'It is not allowed to publish',
            notAllowedLine1: 'Photos of an explicitly sexual or pornographic nature',
            notAllowedLine2: 'Images aimed at inciting ethnic or racial hatred or aggression',
            notAllowedLine3: 'Photos involving persons under 18 years of age',
            notAllowedLine4: 'Third-party copyright protected photos',
            notAllowedLine5: 'Images larger than 5 MB and in a format other than JPG, GIF or PNG',
            requirements: 'Your face must be clearly visible on the photo. All photos and videos uploaded by you must comply with these requirements, otherwise they can be removed.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'Withdrawal requests',
            amount: 'Amount',
            currentBalance: 'Your current account balance is',
            withdrawAll: 'Withdraw all',
            requestWithdrawal: 'Request Withdrawal',
            last: 'LAST',
            withdrawalRequests: 'Withdrawal requests',
            time: 'Time',
            currency: 'Currency',
            info: 'Info',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Card',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Withdrawal Details',
            address: 'Wallet Address',
            bankDetails: 'Bank Details',
            bankAccount: 'Account Number',
            bankHolder: 'Account Holder Name',
            bankIban: 'IBAN',
            bankSwift: 'Bank SWIFT code',
            recipientAddress: 'Recipient Address',
            routingNumber: 'Routing Number',
            bankBeneficiary: 'Bank of Beneficiary Name',
            bankAddress: 'Bank Address',
            bankCode: 'Bank code (BSB)',
            desc: 'Description',
            cardNumber: 'Card Number',
            cardHolder: 'Card Holder Name',
        },
        fullScreenVerification: {
            dragAndDrop: 'drag and drop the document to this area',
            documentsList: 'List of uploaded documents',
            document: 'Document',
            timeUploaded: 'Time Uploaded',
            timeProcessed: 'Time Processed',
            status: 'Status',
            types: ['Proof of Id', 'Proof of Residence', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back', 'Selfie', 'Other'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Upload',
            isConfirmed: 'is confirmed',
            uploadAnotherDocument: 'Upload another document',
            isUnderReview: 'is under review',
            questionnaire: 'Questionnaire',
            sendAnswers: 'Send Answers',
            progressBarOf: 'of',
            progressBarText: 'of your documents have been uploaded and confirmed'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transfer funds between your accounts',
            transferFrom: 'From account',
            transferTo: 'To account',
            amount: 'Amount',
            makeTransfer: 'Make Transfer',
            yourAccounts: 'Your Accounts',
            account: 'Account',
            currency: 'Currency',
            balance: 'Balance',
            credit: 'Credit',
            makeActive: 'Make Active',
            active: 'Active'
        },
        fullScreenSupport: {
            sendMessage: 'How can we help?'
        },
        fullScreenSavings: {
            pct_0: 'Flexible',
            pct_30: 'Locked 1 Month',
            pct_90: 'Locked 1 Quarter',
            pct_180: 'Locked 6 Months',
            pct_365: 'Locked 1 Year',
            savings: 'Savings',
            detailedInformation: 'Detailed Information',
            pleaseSelect: 'Please select a currency and a period',
            openSavings: 'Open Savings',
            currentTime: 'Current Time',
            releaseTime: 'Release Time',
            currencyChosen: 'Currency Chosen',
            periodChosen: 'Period Chosen',
            yourRate: 'Your Rate',
            yourEarnings: 'Your earnings',
            accountFrom: 'Account to transfer funds from',
            enterAmount: 'Enter Amount',
            in: 'In',
            openAndInvest: 'Open Savings Account and invest',
            investment: 'Investment',
            period: 'Period',
            daysTotal: 'Days total',
            finalEarnings: 'Final earnings',
            created: 'Created',
            daysElapsed: 'Days Elapsed',
            days: 'days',
            earnings: 'Earnings',
            fundsReleasedIn: 'Funds Released In',
            claim: 'Claim',
            name: 'Name',
        },
        fullScreenSettings: {
            updatePassword: 'Update Password',
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            changePassword: 'Change Password',
            activationForGoogle: 'Activation code for Google Authenticator',
            activateAndEnter: 'Activate Authenticator and enter a generated code in field below',
            qrCode: 'QR Code',
            activateProtection: 'Activate 2FA Protection',
            protectionActive: '2FA protection is active',
            twoFactorCode: '2FA Code',
            disableProtection: 'Disable 2FA Protection',
            language: 'Language',
            dashboardCurrency: 'Dashboard Currency',
            confirmNewPassword: 'Confirm new password',
        },
        fullScreenReferrals: {
            yourLink: 'YOUR REFERRAL LINK',
            referredFriends: 'REFERRED FRIENDS',
            id: 'ID',
            name: 'Name',
            registered: 'Register',
            level: 'Level',
            payout: 'Payout',
            lastPayouts: 'LAST REFERRAL PAYOUTS',
            time: 'Time',
            amount: 'Amount',
            currency: 'Currency',
            info: 'Info',
            referralDesc: 'Referral Program designed to reward you for expanding our community of valued investors.',
            shareLink: 'Share the link:',
            subtitle: 'Provide him your name or email or trading ID',
            descSuccesfull:'Once your referral successfully registers, your Financial Advisor will contact to assist with his first steps the most effective proficient way on his investment journey.',
            descTracked:'All their contributions are being tracked under your referral.',
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'May',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Buy', 'Sell', 'Buy Limit', 'Sell Limit', 'Buy Stop', 'Sell Stop'],
        statuses: ['Pending', 'Approved', 'Declined', 'Processing'],
        errors: {
            NOT_FOUND: 'Request error',
            FORBIDDEN: 'Access denied. Please re-login',
            SERVER_ERROR: 'Action failed. Please try again later',
            INVALID_ACCOUNT: 'Session expired. Please re-login',
            INVALID_EMAIL: 'Invalid e-mail address',
            INVALID_PHONE: 'Invalid phone number',
            INVALID_COUNTRY: 'Invalid country',
            INVALID_LOGIN: 'Invalid login credentials',
            USER_BLOCKED: 'Account is blocked. Please contact support',
            USER_REGISTERED: 'User with entered e-mail or phone number is already registered',
            INVALID_USER: 'User is not found',
            REJECTED: 'Operation is not allowed due to platform restrictions',
            INVALID_OLD_PASSWORD: 'Old password is invalid',
            INVALID_SYMBOL: 'Invalid trading symbol',
            INVALID_SYMBOL_PRECISION: 'Invalid symbol precision',
            INVALID_FRAME: 'Invalid chart frame',
            INVALID_CURRENCY: 'Invalid currency',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Invalid account trading type',
            INSUFFICIENT_FUNDS: 'Insufficient funds',
            INVALID_WITHDRAWAL: 'Withdrawal is not found',
            INVALID_STATUS: 'Invalid status',
            INVALID_FILE: 'Invalid file extension or file is too large',
            INVALID_DOCUMENT: 'Document is not found',
            INVALID_NAME: 'Invalid name',
            INVALID_LOT_SIZE: 'Invalid lot size',
            INVALID_LOT_STEP: 'Invalid lot step',
            INVALID_MIN_VOLUME: 'Invalid minimal volume',
            INVALID_MAX_VOLUME: 'Invalid maximal volume',
            INVALID_GROUP: 'Invalid group',
            INVALID_SPREAD: 'Invalid spread',
            REQUEST_TIMEOUT: 'Request timed out. Please try again later',
            EXISTING_OPEN_ORDERS: 'You have an open order, first close the order for this user and try again.',
            INVALID_TYPE: 'Invalid order type',
            INVALID_VOLUME: 'Invalid order volume',
            INVALID_SL: 'Invalid stop loss value',
            INVALID_TP: 'Invalid take profit value',
            INVALID_PRICE: 'Invalid order price',
            INVALID_EXPIRATION: 'Invalid order expiration',
            NOT_ENOUGH_MARGIN: 'Not enough margin',
            INVALID_ORDER: 'Order is not found',
            MARKET_CLOSED: 'Trading is not available. Please try again',
            INVALID_BALANCE_TYPE: 'Invalid type of balance operation',
            INVALID_HASH: 'Invalid hash',
            HASH_EXPIRED: 'Your password reset link is expired. Please request a new one',
            INVALID_CODE: 'Invalid 2FA code',
            CHAT_DISABLED: 'Access to the support chat has been closed',
            WITHDRAWAL_NOT_ALLOWED: 'Access to the withdrawals has been closed',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later'
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland Islands',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua And Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnia And Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            CV: 'Cape Verde',
            KY: 'Cayman Islands',
            CF: 'Central African Republic',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands',
            CO: 'Colombia',
            KM: 'Comoros',
            CG: 'Congo',
            CD: 'Congo, Democratic Republic',
            CK: 'Cook Islands',
            CR: 'Costa Rica',
            CI: 'Cote D\'Ivoire',
            HR: 'Croatia',
            CU: 'Cuba',
            CY: 'Cyprus',
            CZ: 'Czech Republic',
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (Malvinas)',
            FO: 'Faroe Islands',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island & Mcdonald Islands',
            VA: 'Holy See (Vatican City State)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle Of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: 'Lao People\'s Democratic Republic',
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libyan Arab Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands',
            AN: 'Netherlands Antilles',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestinian Territory, Occupied',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Romania',
            RU: 'Russia',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts And Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre And Miquelon',
            VC: 'Saint Vincent And Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome And Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia And Sandwich Isl.',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Suriname',
            SJ: 'Svalbard And Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan',
            TJ: 'Tajikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad And Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks And Caicos Islands',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates',
            GB: 'United Kingdom',
            US: 'United States',
            UM: 'United States Outlying Islands',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Virgin Islands, British',
            VI: 'Virgin Islands, U.S.',
            WF: 'Wallis And Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    ru: {
        _name: 'Русский',
        newDesign: {
            withdrawalRequests: 'Заявок на вывод средств пока нет',
            withdrawalRequestsText1: 'До сих пор вы не сделали ни одной заявки на вывод средств.',
            withdrawalRequestsText2: 'Как только вы инициируете снятие средств, детали вашей транзакции появятся здесь',
            pdfAttachment: 'Вложение PDF',
            agent: "Агент",
            closed: 'Закрытый',
            pending: 'В ожидании',
            approved: 'Утверждено',
            declined: 'Отклонено',
            document: 'Документ',
            frontSide: 'Лицевая сторона',
            backSide: 'Тыльная сторона',
            address: 'Адрес',
            frontSideDocument: 'Лицевая сторона документа',
            creditCard: 'Кредитная карта',
            selfie: 'Селфи',
            other: 'Другое',
            noDocument: 'Документы еще не загружены',
            amountDeposited: 'Сумма депозита',
            makeTransfer: 'Выполнить перевод',
            otherDetails: 'Информация о заказе',
            manualWallets: 'Крипто-кошельки',
            depositText: 'Депозит обрабатывается в течение 5-10 минут',
            pendingBankTransfer: 'У вас есть депозит, подлежащий оплате банковским переводом.',
            completeDeposit: 'Для внесения депозита следуйте приведенным ниже инструкциям',
            downloadInvoice: 'Скачать счет-фактуру',
            payInvoice: 'Оплатите счет в вашем банке',
            useYourBankText1: 'Используйте систему онлайн или офлайн-платежей вашего банка для оплаты счета.',
            useYourBankText2: 'Убедитесь в правильности платежных реквизитов.',
            uploadReceipt: 'Загрузите квитанцию с подтверждением',
            afterMakingPayment: 'После оплаты загрузите квитанцию, чтобы подтвердить внесение средств',
            clickToUpload: 'Нажмите для загрузки',
            orDrag: 'или перетащите',
            fileFormat: 'PDF, JPG, JPEG, PNG менее 10 МБ',
            waitForDeposit: 'Дождитесь подтверждения депозита',
            onceReceipt: 'Как только квитанция будет загружена, мы проверим и одобрим ваш депозит',
            paymentDetails: 'Реквизиты для оплаты',
            nameOnCard: 'Имя на карте',
            cardNumber: 'Номер карты',
            expirationDate: 'Срок действия',
            securityCode: 'Код безопасности',
            continue: 'Продолжить',
            viewAccounts: 'Просмотр счетов',
            continueWithdrawal: 'Продолжить вывод средств',
            accountBalance: 'Остаток на счету',
            accountBalanceText1: 'Почему я не могу заключить сделку?',
            accountsBalanceText2: 'Как я могу пополнить счет?',
            accountsBalanceText3: 'Я хочу пополнить счет через Zelle',
            tradingActions: 'Торговые операции',
            tradingActionsText1: 'Как совершать краткосрочные продажи?',
            tradingActionsText2: 'Как я могу отменить сделку?',
            tradingActionsText3: 'Какова минимальная сумма сделки?',
            marketInfo: 'Информация о рынке',
            marketInfoText1: 'Где я могу увидеть тенденции рынка?',
            marketInfoText2: 'Что сегодня набирает популярность?',
            securityAndSettings: 'Безопасность и параметры',
            securityAndSettingsText1: 'Как изменить свой пароль?',
            securityAndSettingsText2: 'Каковы функции безопасности?',
            securityAndSettingsText3: 'Как обновить информацию о своем аккаунте?',
            messageCopied: 'Сообщение копируется в буфер обмена',
            addSymbol: 'Добавьте символ',
            iHaveRead: 'Я прочитал и согласен с условиями',
            toggleToTurn: 'Включить автоторговлю',
            selectTheRisk: 'Выберите степень риска',
            confirm: 'Подтвердите',
            cancel: 'Отменить',
            chooseClosingTime: 'Выберите время закрытия',
            setOrderClosingTime: 'Установить время закрытия заказа',
            closingTime: 'Время закрытия',
            date: 'Дата',
            time: 'Время',
            setCloseTime: 'Настройка времени закрытия',
            '15m': '15 минут',
            '30m': '30 минут',
            '1h': '1 час',
            custom: 'Пользовательское',
            verifyOtp: 'Проверьте свой OTP',
            enterDigits: 'Введите 6-значный код, отправленный на ваш [email/телефон]',
            submit: 'Отправить',
            order: 'Ордер',
            closedSuccessfully: 'успешно закрыт',
            openSuccessfully: 'успешно открыто',
            deposit: 'депозит',
            withdrawal: 'вывод средств',
            completeVerification: 'Полная верификация',
            verification: 'Верификация',
            avatar: 'Аватар',
            saveChanges: 'Сохранить изменения',
            darkMode: 'Темный режим',
            dashboardCurrency: 'Панель валют',
            language: 'Язык',
            twoFactor: 'Двухфакторная защита',
            googleTwoFactorIsActive: 'Двухфакторный аутентификатор Google активен',
            activateCodeForGoogle: 'Код активации для аутентификатора Google',
            ifYouWishDisableEnterCode: 'Если вы хотите отключить защиту, введите код приложения аутентификатора',
            scanOrManuallyEnter: 'Сканируйте или вручную введите QR-код в Google 2FA, чтобы добавить токен',
            copyCode: 'Копировать код',
            qrCode: 'QR-код',
            activateAndEnterAuthenticator: 'Активируйте аутентификатор и введите сгенерированный код в поле ниже',
            enterTheDigitsCodeGenerated: 'Введите сгенерированный 6-значный код',
            disableTwoFactor: 'Отключить двухфакторную защиту',
            activateTwoFactor: 'Активируйте защиту 2FA',
            fileAdded: 'Файл успешно добавлен',
            theVerificationStatus: 'Статус проверки можно просмотреть в списке загруженных документов',
            confirmWithdrawal: 'Подтвердить вывод',
            minimumWithdrawal: 'Минимальная сумма снятия составляет',
            welcomeToTradingPlatform: 'Добро пожаловать на торговую платформу',
            signIn: 'Войти',
            activeAccount: 'Активный счет',
            save: 'Сохранить',
            selectCurrencyAndPeriod: 'Выберите валюту и период подписки',
            savingsNotFound: 'Сберегательные счета не найдены',
            pleaseCheckInvestment: 'Пожалуйста, проверьте информацию о ваших инвестициях, чтобы продолжить',
            allPeriodInvestment: 'Инвестиции за весь период',
            pct_0: 'Гибкий',
            pct_30: '1 месяц',
            pct_60: '1 квартал',
            pct_90: '6 месяцев',
            pct_180: '6 месяцев',
            pct_365: '1 год',
            savingsCreated: 'Сберегательный счет успешно создан',
            transferDetails: 'Детали перевода',
            transferAmount: 'Сумма перевода',
            investmentDetails: 'Детали инвестиций',
            investment: 'Инвестиции',
            Period: 'Период',
            created: 'Создан',
            releaseTime: 'Время выхода',
            viewSavingsAccounts: 'Просмотр сберегательных счетов',
            continueInvestment: 'Продолжить инвестирование',
            chooseSubscription: 'Выберите свою подписку',
            selectCurrencyAndSubscription: 'Выберите валюту и период подписки, чтобы продолжить.',
            rate: 'Тариф',
            accountTransferFunds: 'Счет для перевода средств',
            amount: 'Сумма',
            'in': 'в',
            openSavingsAndInvest: 'Откройте сберегательный счет и инвестируйте',
            strongSell: 'Сильные продажи',
            sell: 'Продать',
            neutral: 'Нейтральный',
            buy: 'Купить',
            stringBuy: 'Сильная покупка',
            chooseCryptoWallet: 'Выберите кошелек для криптовалюты',
            chooseMethod: 'Выберите метод',
            maximumDeposit: 'Максимальный депозит составляет',
            minimumDeposit: "Минимальный депозит",
            pleaseCompleteAddress: 'Пожалуйста, заполните ваш адрес',
            pleaseCompleteCity: 'Пожалуйста, заполните свой город',
            pleaseCompleteCountry: 'Пожалуйста, заполните данные о вашей стране',
            pleaseCompleteZip: 'Пожалуйста, заполните свой почтовый индекс',
            amountDeposit: 'Сумма депозита',
            transactionStatus: 'Статус транзакции',
            paymentMethod: 'Способ оплаты',
            depositTo: 'Депозит в',
            depositAddress: 'Адрес для вклада',
            scanTheQr: 'Отсканируйте QR-код или скопируйте адрес депозита',
            wallet: 'Кошелек',
            copyDepositAddress: 'Копия адреса депозита',
            copy: 'Копировать',
            addressCopied: 'Адрес скопирован в буфер обмена',
            '5min:': '5-10 минут',
            '2-5days': '2-5 дней / 5-10 минут',
            otherMethod: 'Другой метод',
            profileSettings: 'Настройки профиля',
            savingsAccounts: 'Сберегательные счета',
            subscribe: 'Подписаться',
            toCompleteVerification: 'Чтобы завершить процесс проверки вашей учетной записи, предоставьте четкие и разборчивые копии следующих документов',
            amountToWithdrawal: 'Сумма для вывода',
            cardDetails: 'Реквизиты карты',
            cardHolderName: 'Имя владельца карты',
            withdrawalSuccessful: 'Вывод средств Успешный',
            selectWithdrawalMethod: 'Выберите способ вывода средств',
            creditDebit: 'Кредитная/дебетовая карта',
            crypto: 'Криптовалюта',
            cryptoWalletAddress: 'Адрес криптокошелька',
            cryptoSubtitle: 'Крипто-выводы на ваш кошелек 🚀',
            bankWithdrawal: 'Банковский вывод',
            bankSubtitle: 'Банковский счет 🏦',
            zelleSubtitle: 'Быстрые банковские переводы ⚡🏦',
            tradingResults: 'Торговые результаты',
            day: 'День',
            month: 'Месяц',
            noTradingData: 'Торговые данные отсутствуют',
            startTradingToSee: 'Начните торговать, чтобы увидеть свои результаты здесь.',
            yourPerformanceMetric: 'Показатели вашей производительности будут отображаться, как только данные станут доступны.',
            favorite: 'Избранное',
            all: 'Все',
            availableFullTime: 'Доступно 24/7',
            yourProfileAndSettings: 'Ваш профиль и настройки',
            switchToNewDesignMessage: 'Переходите на наш новый дизайн платформы прямо сейчас!',
            switchToOldDesignMessage: 'Вы все еще можете использовать классический дизайн в течение ограниченного времени',
            switchToOldDesignButton: 'Переход на новый дизайн',
            switchToNewDesignButton: 'Возвращение к классическому дизайну',
            generateInvoice: 'Создать счет',
            downloadPdf: 'Скачать PDF',
            confirmDeposit: 'Подтвердить депозит',
            howCanIAssist: 'Чем я могу помочь вам сегодня?',
            maxFileSize: 'Максимальный размер файла 5MB, принимаются форматы JPEG, PNG или GIF',
            uploadPhoto: 'Загрузить фото',
            featuresDisabled: 'Эта функция отключена',
            welcome: 'Добро пожаловать',
            signUp: 'Регистрация',
            registration: 'Регистрация',
            pleaseConfirmAgreement: 'Пожалуйста, подтвердите пользовательское соглашение',
            pleaseEnterPassword: 'Пожалуйста, введите пароль',
            passwordNotSame: 'Пароли не совпадают',
            noActiveOrders: 'Нет активных ордеров',
            itLikeNoHaveActiveOrders: 'Похоже, у вас нет активных ордеров на данный момент',
            cryptoWallet: 'Криптокошелек',
            bankCard: 'Банк/Карта',
            selected: 'Выбрано',
            amountWithdrawal: 'Сумма снятия',
            withdrawalTo: 'Вывести на',
            governmentIssuedPhoto1: 'Удостоверение личности государственного образца (например, паспорт, водительское удостоверение, национальная ID-карта)',
            governmentIssuedPhoto2: 'Вид на жительство (если применимо)',
            governmentIssuedPhoto3: 'Документ должен быть действительным (не истёкшим)',
            governmentIssuedPhoto4: 'Полное имя, дата рождения и фотография должны быть видны.',
            uploadRecent1: 'Загрузите недавний счёт за коммунальные услуги, банковскую выписку или договор аренды, выданный в последние 3 месяца',
            uploadRecent2: 'Убедитесь, что ваше полное имя и текущий адрес проживания чётко видны.',
            uploadCopy1: 'Загрузите копию лицевой стороны вашей кредитной карты, чтобы первые шесть и последние четыре цифры были видны',
            uploadCopy2: 'Пожалуйста, закройте средние цифры для безопасности',
            takeAndUpload1: 'Сделайте и загрузите селфи, держа удостоверение личности рядом с лицом',
            takeAndUpload2: 'Убедитесь, что и ваше лицо, и удостоверение личности чётко видны',
            otherDocuments: 'Другие документы',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Загрузите четкое и разборчивое изображение вашего документа с номером социального страхования (SSN). Убедитесь, что все детали видны и не закрыты.',
            frontSideSsnDocument: 'Лицевая сторона документа SSN',
            answerSaved: 'Ответы сохранены',
            cryptoWalletDetails: 'Данные криптокошелька',
            bitcoinWalletAddress: 'Биткойн-адрес кошелька',
            recovery: 'Восстановление',
            passwordRecovery: 'Восстановление пароля',
            searchCountry: 'Поиск страны',
            questionnaire: 'Опросник',
            questionnaireText: 'Пожалуйста, заполните опросник для подтверждения вашей личности',
            sendAnswers: 'Отправить ответы',
        },
        timePickerModal: {
            title: 'Закрыть по времени'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Тейк Профит и Стоп Лосс',
            takeProfit: 'Тейк Профит',
            stopLoss: 'Стоп Лосс',
            updateOrder: 'Изменить ордер',
        },
        balanceModal: {
            balance: 'Баланс',
            credit: 'Кредит',
            equity: 'Средства',
            practice: 'Пробный',
            real: 'Реальный',
            account: 'Счет',
            invested: 'Вложено',
            profit: 'Прибыль',
            loss: 'Убыток',
            margin: 'Маржа',
            marginLevel: 'Уровень маржи',
            marginFree: 'Свободная маржа'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Депозит'
        },
        sideMenu: {
            marketWatch: 'Обзор Рынков',
            activeOrders: 'Открытые ордера',
            tradingHistory: 'История Торговли',
            economicCalendar: 'Календарь',
            marketNews: 'Новости рынков'
        },
        closePositionModal: {
            closeOrder: 'Закрыть позицию',
            areYouSure: 'Вы уверены, что хотите закрыть позицию',
            buy: 'Купить',
            sell: 'Продать',
            yes: 'Да',
            no: 'Нет',
        },
        pciDssModal: {
            desc: `Соблюдение стандарта безопасности данных платежной индустрии (PCI DSS) обязательно для всех субъектов, которые хранят, обрабатывают или передают данные держателей карт Visa/Master Card/Stripe, включая финансовые учреждения, торговцев и поставщиков услуг. 
            Это глобальный стандарт безопасности для всех субъектов, которые хранят, обрабатывают или передают данные держателей карт и/или чувствительные аутентификационные данные. PCI DSS устанавливает базовый уровень защиты для потребителей и помогает снизить мошенничество и утечки данных во всей платежной экосистеме.`
        },
        chart: {
            addNewChart: 'Добавить график',
        },
        symbolsModal: {
            watchlist: 'Избранное',
            asset: 'Инструмент',
            price: 'Цена',
            changePct: 'Изменения 24ч'
        },
        pendingModal: {
            pending: 'Цена', // <- NB
            assetPrice: 'Цена инструмента',
            current: 'Текущая',
            revert: 'Вернуть к рыночной',
            automatically: 'Позиция будет открыта автоматически при достижении указанного уровня'
        },
        orderMenu: {
            default: 'По умолчанию',
            volume: 'Объем',
            lots: 'лоты',
            units: 'юниты',
            currency: 'валюта',
            contractSize: 'Размер контракта',
            position: 'Позиция',
            margin: 'Маржа',
            freeMargin: 'Свободная маржа',
            takeProfitStopLoss: 'Тейк Профит и Стоп Лосс',
            pending: 'Цена',
            market: 'Рынок',
            leverage: 'Плечо',
            spread: 'Спрэд',
            notSet: 'Не задано',
            at: 'по',
            buy: 'купить',
            sell: 'продать',
        },
        footer: {
            supportBanner: 'КАЖДЫЙ ДЕНЬ, 24/7',
            currentTime: 'ТЕКУЩЕЕ ВРЕМЯ',
            liveChat: 'Поддержка'
        },
        statusBar: {
            balance: 'Баланс',
            credit: 'Кредит',
            equity: 'Средства',
            margin: 'Маржа',
            marginLevel: 'Уровень маржи',
            freeMargin: 'Свободная маржа',
            pnl: 'Прибыль/Убыток',
            profitTotal: 'Прибыль всего'
        },
        accountModal: {
            dateRegistered: 'Дата Регистрации',
            userId: 'ID Пользователя',
            dashboard: 'Панель пользователя',
            personalData: 'Личные Данные',
            deposit: 'Депозит',
            withdrawFunds: 'Вывод Средств',
            savings: 'Накопительные Счета',
            settings: 'Настройки',
            logout: 'Выйти'
        },
        depositModal: {
            BT_INVOICE: 'Введите сумму и сформируйте счет',
            BT_TITLE: 'Ваши платежные реквизиты',
            PENDING_DEPOSITS_LIMIT: 'Лимит депозитов',
            account: 'Счет получателя',
            bank_name: 'Наименование банка получателя',
            beneficiary: 'Получатель',
            beneficiary_address: 'Счет получателя',
            swift: 'БИК Банка получателя',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'ИНН',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent accountКорреспондентский счёт',
            TOKEN_AMOUNT: 'Сумма',
            TOKEN_SUBTOTAL: 'Промежуточный итог',
            TOKEN_TOTAL: 'Всего',
            TOKEN_DESCRIPTION: 'Описание',
            TOKEN_PAYMENT_DETAILS: 'Платежные реквизиты',
            makeDeposit: 'Внести Депозит',
            practiceAccount: 'Учебнй Счет',
            realAccount: 'Реальный Счет',
            noWithdrawals: 'Нет вывода средств',
            easyWithdrawals: 'Легкий вывод средств',
            allAssets: 'Доступны все инструменты',
            fullFledged: 'Полнофункциональная платформа',
            fillUpTo: 'Пополнить до',
            freeReplenishment: 'Бесплатное Пополнение',
            topUp: 'Пополните Ваш Счет',
            minimumAmount: 'Минимальная Сумма',
            canSwitch: 'Вы можете переключиться между счетами в любое время'
        },
        gridsModal: {
            chartGrids: 'СЕТКА ГРАФИКОВ',
            chart1: '1 график',
            chart2: '2 графика',
            chart3: '3 графика',
            chart4: '4 графика',
        },
        noConnectionModal: {
            connectionLost: 'Соединение с сервером потеряно',
            retryNow: 'Попробовать еще',
        },
        loginModal: {
            loginToTradeRoom: 'Авторизация',
            email: 'Электронная Почта',
            enterEmail: 'Введите почту',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: 'Код 2FA (если активна)',
            twoFactorAuth: 'Двухфакторная аутентификация',
            password: 'Пароль',
            yourPassword: 'Ваш пароль',
            signIn: 'Войти',
            dontHaveAccount: 'У вас нет аккаунта?',
            restore: 'Восстановить',
            fullName: 'Полное имя',
            havePromo: 'У меня есть промо код',
            promo: 'Промо код',
            login: 'Вход в систему',
            haveAccount: 'Уже есть аккаунт?',
            hide: 'Скрыть',
            goTo: 'Вернуться назад'
        },
        newPasswordModal: {
            setNewPassword: 'Установить новый пароль',
            newPassword: 'Новый пароль',
            confirmNewPassword: 'Подтвердите новый пароль',
            setButton: 'Установить'
        },
        registerModal: {
            repeatPassword: 'Подтвердить Пароль',
            createNewAccount: 'Создать Новый Счет',
            email: 'Электронная Почта',
            firstName: 'Имя',
            lastName: 'Фамилия',
            country: 'Страна',
            phone: 'Телефон',
            password: 'Пароль',
            createAccount: 'Создать Счет',
            currency: 'Валюта',
            privacyAndPolicy: "Конфиденциальность и Политика",
            customerAgreement: 'Пользовательское Соглашение',
        },
        forgotPasswordModal: {
            forgotPassword: 'Забыли Пароль?',
            submitEmail: 'Пожалуйста, введите имейл, указанный при регистрации, проверьте свой ящик и следуйте инструкциям, полученным в письме',
            submit: 'Отправить'
        },
        notifications: {
            error: 'Ошибка',
            success: 'Успешно',
            deposit: 'Депозит',
            withdrawal: 'Вывод',
            depositApproved: 'одобрен',
            depositDeclined: 'Депозит отклонен',
            withdrawalApproved: 'Вывод средств одобрен',
            withdrawalDeclined: 'Вывод средств отклонен'
        },
        markets: {
            favorites: 'Избранное', 
            all: 'Все символы',
            forex: 'Форекс',
            stocks: 'Акции',
            commodities: 'Сырье',
            indices: 'Индексы',
            crypto: 'Криптовалюта',
            metals: 'Металлы',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'ПОКУПКА',
            profitCalculator: 'Калькулятор прибыли',
            sell: 'ПРОДАЖА',
            volume: 'Объем',
            entryPrice: 'Цена Входа',
            takeProfit: 'Тейк Профит',
            stopLoss: 'Стоп Лосс',
            maxPosition: 'Макс. Позиция',
            calculate: 'Рассчитать',
            calculationsFor: 'Рассчет для',
            leverage: 'Плечо',
            requiredMargin: 'Требуемая Маржа',
            profitFromTP: 'Прибыль от ТП',
            lossFromSL: 'Убыток от СЛ',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Обзор Рынков',
            search: 'Поиск...',
        },
        widgetOrders: {
            commission: 'Комиссия',
            volume: 'Объем',
            margin: 'Маржа',
            active: 'Открытые',
            pending: 'В Ожидании',
            activeOrders: 'Открытые ордера',
            portfolio: 'Портфолио',
            allPositions: 'Все Позиции',
            noPositionsLine1: 'У вас ещё нет',
            noPositionsLine2: 'открытых позиций',
            show: 'Показать',
            more: 'больше',
            less: 'меньше',
            purchaseTime: 'Открыт',
            closePosition: 'Закрыть',
            priceOpen: 'Цена открытия',
            priceSl: 'Цена Стоп Лосс',
            priceTp: 'Цена Тейк Профит',
            type: 'Направление позиции',
            pnl: 'Прибыль/Убыток',
            cancelOrder: 'Отменить Ордер',
            orderType: 'Тип Ордера',
            cancelled: 'Отменено',
            tradingHistory: 'Торговая История',
            noHistoryLine1: 'У вас ещё нет',
            noHistoryLine2: 'закрытых позиций',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Календарь'
        },
        widgetMarketNews: {
            marketNews: 'Новости Рынков'
        },
        ordersPane: {
            closeInTime: 'Закрыть по времени',
            timedOrder: 'Timed Order',
            ordersActive: 'Активные ордеры',
            ordersHistory: 'История Ордеров',
            id: 'ID',
            symbol: 'Символ',
            type: 'Тип',
            volume: 'Объем',
            openPrice: 'Цена открытия',
            openTime: 'Время открытия',
            closePrice: 'Цена закрытия',
            closeTime: 'Время закрытия',
            sl: 'СЛ',
            tp: 'ТП',
            price: 'Цена',
            pnl: 'ПиУ',
            actions: 'Действия',
            edit: 'Править',
            close: 'Закрыть',
            commission: 'Комиссия',
            swap: 'Своп',
            swapLong: 'Своп Лонг',
            swapShort: 'Своп Шорт'
        },
        assetInfoModal: {
            assetInfo: 'Информация об Инструменте',
            tradingConditions: 'Торговые Условия',
            information: 'Изменение за сессию',
            bid: 'Покупка',
            ask: 'Продажа',
            sessionChange: 'Изменение за сессию',
            tradeNow: 'Торговать',
            opens: 'Открывается',
            closes: 'Закрывается',
            at: 'в',
            open247: 'Открыто круглосуточно',
            today: 'сегодня',
            tomorrow: 'завтра',
            sunday: 'Воскресенье',
            monday: 'Понедельник',
            tuesday: 'Вторник',
            wednesday: 'Среда',
            thursday: 'Четверг',
            friday: 'Пятница',
            saturday: 'Суббота',
            contractSpecification: 'Спецификация контракта',
            symbol: 'Символ',
            name: 'Наименование',
            market: 'Рынок',
            baseCurrency: 'Базовая Валюта',
            digits: 'Точность',
            lotSize: 'Размер лота',
            lotStep: 'Шаг лота',
            minVolume: 'Мин Объем',
            maxVolume: 'Макс Объем',
            leverage: 'Плечо',
            commission: 'Комиссия',
            swapLong: 'Своп Лонг',
            swapShort: 'Своп Шорт',
            schedule: 'Расписание',
            weekday: 'День Недели',
            tradingTime: 'Время Торгов',
            closed: 'закрыто',
            sell: 'Продавать',
            buy: 'Покупать',
            low: 'Мин',
            high: 'Макс',
            oneHourChange: 'Изменение 1 час',
            oneDayChange: 'Изменение 1 день',
            oneWeekChange: 'Изменение 1 неделя',
            oneMonthChange: 'Изменение 1 месяц',
            threeMonthsChange: 'Изменение 3 месяца',
            oneYearChange: 'Изменение 1 год',
            loading: 'Загрузка...',
        },
        autoTrader: {
            days: 'дня',
            title: 'Авто Трейдер',
            titleAi: 'AI Trader',
            customTitle: 'Срок действия Автоматического Трейдера',
            riskLevelLow: 'Низкий',
            riskLevelMedium: 'Средний',
            riskLevelHigh: 'Высокий',
            perDay: 'Количество сделок в день:',
            desc: `Торговля CFD и другими продуктами с кредитным плечом может привести к убыткам. Перед началом торговли клиентам следует ознакомиться с соответствующими заявлениями о рисках на нашей странице "Информация о рисках". Автоматизированная торговля не гарантирует результатов. Компания не несет ответственности за потерю средств при автоматической торговле. Пожалуйста, убедитесь, что вы полностью понимаете риски и принимаете меры по управлению ими.`,
            accept: 'Принять',
            save: 'Сохранить',
            cancel: 'Отмена',
        },
        cardBox: {
            name: 'Имя',
            cardNumber: 'Номер карты',
            expiration: 'Срок действия (мм/гг)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Торговля",
            startTrading: 'К Торговле',
            deposit: 'Депозит',
            dashboard: 'Панель Управления',
            personalInfo: 'Личные Данные',
            contactInfo: 'Контактная информация',
            withdrawal: 'Вывод Средств',
            verification: 'Верификация',
            accounts: 'Счета',
            liveChat: 'Поддержка',
            savings: 'Накопительные Счета',
            referrals: 'Реферальная программа',
            settings: 'Настройки',
            logOut: 'Выход',
        },
        fullScreenDeposit: {
            methods: 'МЕТОДЫ',
            confirm: 'Подтвердите в приложении вашего банка',
            sendOtp: 'Отправить код',
            otpCode: 'OTP Код',
            addWalletAddress: 'Кошелек для данного метода не установлен. Пожалуйста, обратитсь к Вашему агенту или в службу поддержки.',
            addressReceipt: 'Пожалуйста, подождите, получаем текущий адрес',
            makeDeposit: 'ВНЕСТИ ДЕПОЗИТ',
            instant: 'Моментально',
            minutes: 'минут',
            hours: 'часов',
            days: 'дней',
            amount: 'Сумма',
            continue: 'Продолжить',
            qrCode: 'QR код',
            depositAddress: 'АДРЕС КОШЕЛЬКА',
            copy: 'Копировать',
            last: 'ПОСЛЕДНИЕ',
            deposits: 'ДЕПОЗИТЫ',
            time: 'Время',
            currency: 'Валюта',
            status: 'Статус',
            info: 'Инфо',
        },
        fullScreenCcExt: {
            pleaseWait: 'Пожалуйста, подождите. Идет соединение с сервисом приема платежей...',
            cardPayment: 'Оплата картоу',
            inOrder: 'Чтобы отправить сумму',
            pleaseProvide: 'пожалуйста предоставьте данные карты:'
        },
        fullScreenDashboard: {
            completeVerification: 'Пожалуйста, заполните верификацию',
            noOrderYet: 'Закрыто в убыток',
            totalBalance: 'Итоговый Баланс',
            totalPnl: 'Прибыль / Убыток',
            profitableOrders: 'Прибыльные Ордеры',
            roi: 'ROI',
            activityLog: 'Лог Активности',
            loginFromIp: 'Вход с IP',
            tradingResults: 'Результаты Торговли',
            week: 'неделя',
            month: 'Месяц',
            year: 'Год',
            successRate: 'Успешные сделки',
            closedWithProfit: 'Закрыто с прибылью',
            closedWithLoss: 'Закрыто в убыток',
            account: 'Счет',
            balance: 'Баланс',
            leverage: 'Плечо',
            credit: 'Кредит',
            tradeNow: 'Торговать',
            usingCurrentRate: 'по текущей выбранной валюте',
            totalDeposits: 'Всего депозитов',
            totalWithdrawals: "Всего выводов",
            returnOnInvestment: "Доходность инвестиций"
        },
        fullScreenPersonal: {
            personalDetails: 'Персональные Данные',
            profilePhoto: 'Фото Пользователя',
            firstName: 'Имя',
            country: 'Страна',
            lastName: 'Фамилия',
            address: 'Адрес',
            email: 'Электронная почта',
            phone: 'Номер телефона',
            saveChanges: 'Сохранить Изменения',
            state: 'Город',
            postcode: 'Почтовый индекс',
            created: 'Учетная запись создана',
        },
        fullScreenAvatar: {
            dropFile: 'Для загрузки перетащите файл на круг выше',
            notAllowed: 'Не разрешается размещать',
            notAllowedLine1: 'Эротические и порнографические фотографии',
            notAllowedLine2: 'Изображения, разжигающие этническую или расовую ненависть или агрессию',
            notAllowedLine3: 'Фотографии с детьми до 18 лет',
            notAllowedLine4: 'Фотографии, защищенные авторским правом',
            notAllowedLine5: 'Фотографии размером более 5 Мб и в формате, отличном от JPG, GIF или PNG',
            requirements: 'На фото должно быть четко видно Ваше лицо. Все загруженные фотографии должны соответсвовать критериям, описанным выше. В противном случае они будут принудительно удалены.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Пожалуйста, свяжитесь с вашим менеджером по работе с клиентами для получения информации о снятии средств',
            cryptocurrency: 'Криптовалюта',
            withdrawal: 'Вывод Средств',
            requestNewWithdrawal: 'ЗАПРОСИТЬ ВЫВОД СРЕДСТВ',
            amount: 'Сумма',
            currentBalance: 'Ваш текущий баланс',
            withdrawAll: 'Вывести все',
            requestWithdrawal: 'Вывод',
            last: 'ПОСЛЕДНИЕ',
            withdrawalRequests: 'ВЫВОД СРЕДСТВ',
            time: 'Время',
            currency: 'Валюта',
            info: 'Инфо',
            status: 'Статус',
            bank: 'Банк',
            bitcoin: 'Биткоин',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Карта',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Детали Вывода Средств',
            address: 'Адрес Кошелька',
            bankDetails: 'Банковские реквизиты',
            bankAccount: 'Номер Счета',
            bankHolder: 'Имя Владельца Счета',
            bankIban: 'IBAN',
            bankSwift: 'Банковский SWIFT-код',
            recipientAddress: 'Адрес получателя',
            routingNumber: 'Банковский идентификационный номер',
            bankBeneficiary: 'Банк получателя',
            bankAddress: 'Адрес банка',
            bankCode: 'Банковский код (BSB)',
            desc: 'Описание',
            cardNumber: 'Номер Карты',
            cardHolder: 'Имя Владельца Карты',
        },
        fullScreenVerification: {
            dragAndDrop: 'для загрузки перетащите документ в эту область',
            documentsList: 'СПИСОК ЗАГРУЖЕННЫХ ДОКУМЕНТОВ',
            document: 'Документ',
            timeUploaded: 'Загружен',
            timeProcessed: 'Обработан',
            status: 'Статус',
            types: ['Подтверждeние Личности', 'Подтверждeние Адреса', 'Карта - Лицевая Сторона', 'Карта - Обратная Сторона', 'Подтверждeние Личности - Обратная Сторона', 'Селфи', 'Другое'],
            types2: ['Подтверждeние Личности', 'Подтверждeние Адреса', 'Карта - Лицевая Сторона', 'Карта - Обратная Сторона', 'Подтверждeние Личности - Обратная Сторона', 'Селфи'],
            upload: 'Загрузить',
            isConfirmed: 'одобрено',
            uploadAnotherDocument: 'Загрузите другой документ',
            isUnderReview: 'находится в обработке',
            questionnaire: 'Опросник',
            sendAnswers: 'Отправить Ответы',
            progressBarOf: 'из',
            progressBarText: 'ваших документов были загружены и подтверждены'
        },
        fullScreenAccounts: {
            manageAccounts: 'Перевод средств между счетами',
            transferFrom: 'Со счета',
            transferTo: 'На счет',
            amount: 'Сумма',
            makeTransfer: 'Перевести Средства',
            yourAccounts: 'Ваши Счета',
            account: 'Счет',
            currency: 'Валюта',
            balance: 'Баланс',
            credit: 'Кредит',
            makeActive: 'Сделать Активным',
            active: 'Активен'
        },
        fullScreenSupport: {
            sendMessage: 'Отправить сообщение'
        },
        fullScreenSavings: {
            pct_0: 'Гибкий',
            pct_30: 'Фиксированный 1 Месяц',
            pct_90: 'Фиксированный 1 Квартал',
            pct_180: 'Фиксированный 6 Месяцев',
            pct_365: 'Фиксированный 1 Год',
            savings: 'Накопительные счета',
            detailedInformation: 'Подробная Информация',
            pleaseSelect: 'Пожалуйста, выберите валюту и период',
            openSavings: 'Открытые накопительные счета',
            currentTime: 'Текущее время',
            releaseTime: 'Время закрытия счета',
            currencyChosen: 'Выбранная валюта',
            periodChosen: 'Выбранный период',
            yourRate: 'Ваша ставка',
            yourEarnings: 'Ваш доход',
            accountFrom: 'Счет, с которого произвести трансфер',
            enterAmount: 'Введите сумму',
            in: 'в',
            openAndInvest: 'Открыть накопительный счет и вложить',
            investment: 'Вложение',
            period: 'Период',
            daysTotal: 'Дней всего',
            finalEarnings: 'Итоговый доход',
            created: 'Создано',
            daysElapsed: 'Дней прошло',
            days: 'дней',
            earnings: 'Доход',
            fundsReleasedIn: 'До закрытия',
            claim: 'Забрать',
            name: "Название",
        },
        fullScreenSettings: {
            updatePassword: 'ОБНОВИТЬ ПАРОЛЬ',
            currentPassword: 'Текущий Пароль',
            newPassword: 'Новый Пароль',
            changePassword: 'Изменить Пароль',
            activationForGoogle: 'Код активации для Google Authenticator',
            activateAndEnter: 'Активируйте Authenticator и введите сгенерированный код в поле ниже',
            qrCode: 'QR код',
            activateProtection: 'Активировать Двухфакторную Защиту',
            protectionActive: 'ДВУХФАКТОРНАЯ ЗАЩИТА АКТИВНА',
            twoFactorCode: 'Код Верификации',
            disableProtection: 'Отключить Двухфакторную Защиту',
            language: 'Язык',
            dashboardCurrency: 'Валюта для панели управления',
            confirmNewPassword: 'Подтвердите новый пароль',
        },
        fullScreenReferrals: {
            yourLink: 'ВАША ССЫЛКА ДЛЯ ПРИВЛЕЧЕНИЯ',
            referredFriends: 'ПРИВЛЕЧЕННЫЕ ДРУЗЬЯ',
            id: 'ID',
            name: 'Имя',
            registered: 'Зарегистрироваться',
            level: 'Уровень',
            payout: 'Выплата',
            lastPayouts: 'ПОСЛЕДНИЕ ВЫПЛАТЫ',
            time: 'Время',
            amount: 'Сумма',
            currency: 'Валюта',
            info: 'Комментарий',
            referralDesc: 'Партнёрская программа разработана, чтобы вознаградить вас за расширение нашего сообщества ценных инвесторов.',
            shareLink: 'Поделитесь ссылкой:',
            subtitle: 'Предоставьте ему ваше имя, email или торговый ID.',
            descSuccesfull:"Как только ваша рекомендация успешно зарегистрируется, ваш Финансовый Консультант свяжется с ним, чтобы помочь сделать первые шаги на пути инвестирования наиболее эффективным и профессиональным способом.",
            descTracked:'Все его взносы будут отслеживаться под вашей рефералкой.',
        },
        months: {
            Jan: 'Янв',
            Feb: 'Фев',
            Mar: 'Мар',
            Apr: 'Апр',
            May: 'Май',
            Jun: 'Июн',
            Jul: 'Июл',
            Aug: 'Авг',
            Sep: 'Сен',
            Oct: 'Окт',
            Nov: 'Ноя',
            Dec: 'Дек'
        },
        orderTypes: ['Покупка', 'Продажа', 'Лимитный Покупка', 'Лимитный Продажа', 'Стоп Покупка', 'Стоп Продажа'],
        statuses: ['Новый', 'Одобрен', 'Отклонен', 'В Обработке'],
        errors: {
            NOT_FOUND: 'Ошибка запроса',
            FORBIDDEN: 'Доступ запрещен. Пожалуйста, авторизуйтесь заново',
            SERVER_ERROR: 'Действие не удалось. Пожалуйста, попробуйте позже',
            INVALID_ACCOUNT: 'Сессия истекла. Пожалуйста, авторизуйтесь заново',
            INVALID_EMAIL: 'Неверная электронная почта',
            INVALID_PHONE: 'Неверный номер телефона',
            INVALID_COUNTRY: 'Неверная страна',
            INVALID_LOGIN: 'Неверные данные авторизации',
            USER_BLOCKED: 'Аккаунт заблокирован. Пожалуйста, свяжитесь со службой поддержки',
            USER_REGISTERED: 'Пользователь с такими электронной почтой или номером телефона уже зарегистрирован',
            INVALID_USER: 'Пользователь не найден',
            REJECTED: 'Операция запрещена согласно настройкам платформы',
            INVALID_OLD_PASSWORD: 'Текущий пароль неверен',
            INVALID_SYMBOL: 'Неверный торговый инструмент',
            INVALID_SYMBOL_PRECISION: 'Неверная точность после запятой',
            INVALID_FRAME: 'Неверный период',
            INVALID_CURRENCY: 'Неверная валюта',
            INVALID_AMOUNT: 'Неверная сумма',
            INVALID_PRODUCT: 'Неверный тип счета',
            INSUFFICIENT_FUNDS: 'Недостаточно денег. Пожалуйста, внесите депозит',
            INVALID_WITHDRAWAL: 'Запрос на вывод средств не найден',
            INVALID_STATUS: 'Неверный статус',
            INVALID_FILE: 'Неверный тип файла или файл слишком большой',
            INVALID_DOCUMENT: 'Документ не найден',
            INVALID_NAME: 'Неверное имя',
            INVALID_LOT_SIZE: 'Неверный размер лота',
            INVALID_LOT_STEP: 'Неверный шаг лота',
            INVALID_MIN_VOLUME: 'Неверный минимальный объем',
            INVALID_MAX_VOLUME: 'Неверный максимальный объем',
            INVALID_GROUP: 'Неверная группа',
            INVALID_SPREAD: 'Неверный спред',
            REQUEST_TIMEOUT: 'Время ожидания запроса истекло. Пожалуйста, попробуйте еще раз',
            EXISTING_OPEN_ORDERS: 'У вас есть открытый ордер, сначала закройте ордер для этого пользователя и повторите попытку.',
            INVALID_TYPE: 'Неверный тип ордера',
            INVALID_VOLUME: 'Неверный объем ордера',
            INVALID_SL: 'Неверный стоп лосс',
            INVALID_TP: 'Неверный тейк профит',
            INVALID_PRICE: 'Неверная цена ордера',
            INVALID_EXPIRATION: 'Неверное время экспирации',
            NOT_ENOUGH_MARGIN: 'Недостаточно маржи',
            INVALID_ORDER: 'Ордер не найден',
            MARKET_CLOSED: 'Торговля недоступна, рынок закрыт',
            INVALID_BALANCE_TYPE: 'Неверный тип балансовой операции',
            INVALID_HASH: 'Неверный хеш',
            HASH_EXPIRED: 'Ваша ссылка на сброс пароля истекла. Пожалуйста, запросите новую',
            INVALID_CODE: 'Неверный код 2FA',
            CHAT_DISABLED: 'Доступ к чату поддержки был закрыт',
            WITHDRAWAL_NOT_ALLOWED: 'Доступ к выводу средств был закрыт',
            TRADING_DISABLED: 'Торговля отключена для этого счета',
            PENDING_DEPOSITS_LIMIT: 'Превышен лимит депозитов',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Возможно только одно неподтвержденное снятие средств',
            LOCAL_DEPOSIT_ONLY_REAL: 'Пополнение возможно только для реального аккаунта',
            LOCAL_DEPOSIT_POSITIVE: 'Сумма депозита должна быть больше 0',
            LOCAL_INVALID_CARD_NUMBER: 'Неверный номер карты',
            LOCAL_INVALID_CARD_EXPIRATION: 'Неверная дата истечения срока действия',
            LOCAL_INVALID_CVC: 'Неверный код CVV/CVC',
            LOCAL_PASSWORDS_NOT_MATCH: 'Пароли не сопадают, пожалуйста, введите снова',
            LOCAL_NO_TRADING_ACCOUNTS: 'У вас нет торговых счетов. Пожалуйста, свяжитесь со службой поддержки',
            LOCAL_MIN_DEPOSIT: 'Минмальная сумма пополнения - $',
            LOCAL_MIN_WITHDRAWAL: 'Минимальная сумма снятия - $',
            LOCAL_INVALID_FILE_TYPE: 'Неподдерживаемый формат файла. Пожалуйста, приложите файл JPG, PNG или PDF',
            LOCAL_MIN_STAKING: 'Минимальная сумма для выбранной валюты ',
            FLOOD_REJECTED: 'Вы отправляете сообщения слишком часто. Пожалуйста, повторите попытку позже'
        },
        countries: {
            AF: 'Афганистан',
            AX: 'Аландские острова',
            AL: 'Албания',
            DZ: 'Алжир',
            AS: 'Американское Самоа',
            AD: 'Андорра',
            AO: 'Ангола',
            AI: 'Ангилья',
            AQ: 'Антарктида',
            AG: 'Антигуа и Барбуда',
            AR: 'Аргентина',
            AM: 'Армения',
            AW: 'Аруба',
            AU: 'Австралия',
            AT: 'Австрия',
            AZ: 'Азербайджан',
            BS: 'Багамские Острова',
            BH: 'Бахрейн',
            BD: 'Бангладеш',
            BB: 'Барбадос',
            BY: 'Белоруссия',
            BE: 'Бельгия',
            BZ: 'Белиз',
            BJ: 'Бенин',
            BM: 'Бермудские Острова',
            BT: 'Бутан',
            BO: 'Боливия',
            BA: 'Босния и Герцеговина',
            BW: 'Ботсвана',
            BV: 'Остров Буве',
            BR: 'Бразилия',
            IO: 'Британская территория в Индийском океане',
            BN: 'Бруней',
            BG: 'Болгария',
            BF: 'Буркина-Фасо',
            BI: 'Бурунди',
            KH: 'Камбоджа',
            CM: 'Камерун',
            CA: 'Канада',
            CV: 'Кабо-Верде',
            KY: 'Острова Кайман',
            CF: 'ЦАР',
            TD: 'Чад',
            CL: 'Чили',
            CN: 'Китай',
            CX: 'Остров Рождества',
            CC: 'Кокосовые острова',
            CO: 'Колумбия',
            KM: 'Коморы',
            CG: 'Конго',
            CD: 'Демократическая Республика Конго',
            CK: 'Острова Кука',
            CR: 'Коста-Рика',
            CI: 'Кот-д’Ивуар',
            HR: 'Хорватия',
            CU: 'Куба',
            CY: 'Кипр',
            CZ: 'Чехия',
            DK: 'Дания',
            DJ: 'Джибути',
            DM: 'Доминика',
            DO: 'Доминиканская Республика',
            EC: 'Эквадор',
            EG: 'Египет',
            SV: 'Сальвадор',
            GQ: 'Экваториальная Гвинея',
            ER: 'Эритрея',
            EE: 'Эстония',
            ET: 'Эфиопия',
            FK: 'Фолклендские острова',
            FO: 'Фарерские острова',
            FJ: 'Фиджи',
            FI: 'Финляндия',
            FR: 'Франция',
            GF: 'Гвиана',
            PF: 'Французская Полинезия',
            TF: 'Французские Южные и Антарктические территории',
            GA: 'Габон',
            GM: 'Гамбия',
            GE: 'Грузия',
            DE: 'Германия',
            GH: 'Гана',
            GI: 'Гибралтар',
            GR: 'Греция',
            GL: 'Гренландия',
            GD: 'Гренада',
            GP: 'Гваделупа',
            GU: 'Гуам',
            GT: 'Гватемала',
            GG: 'Гернси',
            GN: 'Гвинея',
            GW: 'Гвинея-Бисау',
            GY: 'Гайана',
            HT: 'Гаити',
            HM: 'Остров Херд и острова Макдональд',
            VA: 'Ватикан',
            HN: 'Гондурас',
            HK: 'Гонк Конг',
            HU: 'Венгрия',
            IS: 'исландия',
            IN: 'Индия',
            ID: 'Индонезия',
            IR: 'Иран',
            IQ: 'Ирак',
            IE: 'Ирландия',
            IM: 'Остров Мэн',
            IL: 'Израиль',
            IT: 'Италия',
            JM: 'Ямайка',
            JP: 'Япония',
            JE: 'Джерси',
            JO: 'Иордания',
            KZ: 'Казахстан',
            KE: 'Кения',
            KI: 'Кирибати',
            KR: 'Корея',
            KW: 'Кувейт',
            KG: 'Киргизия',
            LA: 'Лаос',
            LV: 'Латвия',
            LB: 'Ливан',
            LS: 'Лесото',
            LR: 'Либерия',
            LY: 'Ливия',
            LI: 'Лихтенштейн',
            LT: 'Литва',
            LU: 'Люксембург',
            MO: 'Макао',
            MK: 'Македония',
            MG: 'Мадагаскар',
            MW: 'Малави',
            MY: 'Малайзия',
            MV: 'Мальдивы',
            ML: 'Мали',
            MT: 'Мальта',
            MH: 'Маршалловы Острова',
            MQ: 'Мартиника',
            MR: 'Мавритания',
            MU: 'Маврикий',
            YT: 'Майотта',
            MX: 'Мексика',
            FM: 'Микронезия',
            MD: 'Молдова',
            MC: 'Монако',
            MN: 'Монголия',
            ME: 'Черногория',
            MS: 'Монтсеррат',
            MA: 'Марокко',
            MZ: 'Мозамбик',
            MM: 'Мьянма',
            NA: 'Намибия',
            NR: 'Науру',
            NP: 'Непал',
            NL: 'Нидерланды',
            AN: 'Нидерландские Антильские острова',
            NC: 'Новая Каледония',
            NZ: 'Новая Зеландия',
            NI: 'Никарагуа',
            NE: 'Нигер',
            NG: 'Нигерия',
            NU: 'Ниуэ',
            NF: 'Норфолк',
            MP: 'Северные Марианские острова',
            NO: 'Норвегия',
            OM: 'Оман',
            PK: 'Пакистан',
            PW: 'Палау',
            PS: 'Палестинские Территории',
            PA: 'Панама',
            PG: 'Папуа — НоваяГвинея',
            PY: 'Парагвай',
            PE: 'Перу',
            PH: 'Филиппины',
            PN: 'Острова Питкэрн',
            PL: 'Польша',
            PT: 'Португалия',
            PR: 'Пуэрто-Рико',
            QA: 'Катар',
            RE: 'Реюньон',
            RO: 'Румыния',
            RU: 'Россия',
            RW: 'Руанда',
            BL: 'Сен-Бартелеми',
            SH: 'Остров Святой Елены',
            KN: 'Сент-Китс и Невис',
            LC: 'Сент-Люсия',
            MF: 'Сен-Мартен',
            PM: 'Сен-Пьер и Микелон',
            VC: 'Сент-Винсент и Гренадины',
            WS: 'Самоа',
            SM: 'Сан Марино',
            ST: 'Сан-Томе и Принсипи',
            SA: 'Саудовская Арабия',
            SN: 'Сенегал',
            RS: 'Сербия',
            SC: 'Сейшелы',
            SL: 'Сьерра-Леоне',
            SG: 'Сингапур',
            SK: 'Словакия',
            SI: 'Словения',
            SB: 'Соломоновы Острова',
            SO: 'Сомали',
            ZA: 'ЮАР',
            GS: 'Южная Георгия и Южные Сандвичевы Острова',
            ES: 'Испания',
            LK: 'Шри-Ланка',
            SD: 'Судан',
            SR: 'Суринам',
            SJ: 'Шпицберген и Ян-Майен',
            SZ: 'Эсватини',
            SE: 'Швеция',
            CH: 'Швейцария',
            SY: 'Сирия',
            TW: 'тайвань',
            TJ: 'Таджикистан',
            TZ: 'Танзания',
            TH: 'Тайланд',
            TL: 'Восточный Тимор',
            TG: 'Того',
            TK: 'Токелау',
            TO: 'Тонга',
            TT: 'Тринидад и Тобаго',
            TN: 'Тунис',
            TR: 'Турция',
            TM: 'Туркменистан',
            TC: 'Тёркс и Кайкос',
            TV: 'Тувалу',
            UG: 'Уганда',
            UA: 'Украина',
            AE: 'ОАЭ',
            GB: 'Великобритания',
            US: 'США',
            UM: 'Внешние малые острова США',
            UY: 'Уругвай',
            UZ: 'Узбекистан',
            VU: 'Вануату',
            VE: 'Венесуэла',
            VN: 'Вьетнам',
            VG: 'Виргинские Острова',
            VI: 'Виргинские Острова (США)',
            WF: 'Уоллис и Футуна',
            EH: 'Западная Сахара',
            YE: 'Йемен',
            ZM: 'Замбия',
            ZW: 'Зимбабве'
        },
    },
    pl: {
        _name: 'Polski',
        newDesign: {
            withdrawalRequests: 'Nie ma jeszcze wniosków o wypłatę',
            withdrawalRequestsText1: 'Jak dotąd nie złożyłeś żadnych wniosków o wypłatę środków.',
            withdrawalRequestsText2: 'Po zainicjowaniu wypłaty szczegóły transakcji pojawią się tutaj',
            pdfAttachment: 'Załącznik PDF',
            agent: "Agenta",
            closed: 'Zamknięta',
            pending: 'Oczekujące',
            approved: 'Zatwierdzono',
            declined: 'Odrzucono',
            document: 'Dokument',
            frontSide: 'Przednia strona',
            backSide: 'Tylna strona',
            address: 'Adres',
            frontSideDocument: 'Przednia strona dokumentu',
            creditCard: 'Karta kredytowa',
            selfie: 'Selfie',
            other: 'Inne',
            noDocument: 'Nie przesłano jeszcze żadnych dokumentów',
            amountDeposited: 'Wpłacona kwota',
            makeTransfer: 'Dokonać transferu',
            otherDetails: 'Szczegóły zamówienia',
            manualWallets: 'Portfele kryptowalut',
            depositText: 'Depozyt jest przetwarzany w ciągu 5-10 minut',
            pendingBankTransfer: 'Masz oczekującą wpłatę przelewem bankowym.',
            completeDeposit: 'Postępuj zgodnie z instrukcjami poniżej, aby dokończyć depozyt',
            downloadInvoice: 'Pobierz fakturę',
            payInvoice: 'Opłać fakturę w swoim banku',
            useYourBankText1: 'Skorzystaj z systemu płatności online lub offline swojego banku, aby opłacić fakturę.',
            useYourBankText2: 'Upewnij się, że szczegóły płatności są prawidłowe.',
            uploadReceipt: 'Załaduj potwierdzenie odbioru',
            afterMakingPayment: 'Po dokonaniu płatności prześlij paragon w celu potwierdzenia wpłaty',
            clickToUpload: 'Kliknij, aby przesłać',
            orDrag: 'lub przeciągnij i upuść',
            fileFormat: 'PDF, JPG, JPEG, PNG mniej niż 10 MB',
            waitForDeposit: 'Oczekiwanie na potwierdzenie wpłaty',
            onceReceipt: 'Po przesłaniu pokwitowania zweryfikujemy i zatwierdzimy wpłatę',
            paymentDetails: 'Szczegóły płatności',
            nameOnCard: 'Imię i nazwisko na karcie',
            cardNumber: 'Numer karty',
            expirationDate: 'Termin ważności',
            securityCode: 'Kod bezpieczeństwa',
            continue: 'Kontynuuj',
            viewAccounts: 'Wyświetl konta',
            continueWithdrawal: 'Kontynuować wycofywanie',
            accountBalance: 'Saldo konta',
            accountBalanceText1: 'Dlaczego nie mogę złożyć zlecenia?',
            accountsBalanceText2: 'Jak mogę wpłacić środki?',
            accountsBalanceText3: 'Chcę wpłacić pieniądze przez Zelle',
            tradingActions: 'Akcje handlowe',
            tradingActionsText1: 'Jak przeprowadzić krótką sprzedaż?',
            tradingActionsText2: 'Jak mogę anulować handel?',
            tradingActionsText3: 'Jaka jest minimalna kwota handlu?',
            marketInfo: 'Informacje o rynku',
            marketInfoText1: 'Gdzie mogę zobaczyć trendy rynkowe?',
            marketInfoText2: 'Którzy są dziś liderami rynku',
            securityAndSettings: 'Bezpieczeństwo i ustawienia',
            securityAndSettingsText1: 'Jak zmienić hasło?',
            securityAndSettingsText2: 'Jakie są funkcje bezpieczeństwa?',
            securityAndSettingsText3: 'Jak zaktualizować informacje o koncie?',
            messageCopied: 'Wiadomość skopiowana do schowka',
            addSymbol: 'Dodać symbol',
            iHaveRead: 'Zapoznałem się i akceptuję warunki regulaminu',
            toggleToTurn: 'Przełącz, by włączyć AutoTrade',
            selectTheRisk: 'Wybierz poziom ryzyka',
            confirm: 'Potwierdź',
            cancel: 'Anuluj',
            chooseClosingTime: 'Wybierz czas zamknięcia',
            setOrderClosingTime: 'Ustaw czas zamknięcia zamówienia',
            closingTime: 'Czas zamknięcia',
            date: 'Data',
            time: 'Czas',
            setCloseTime: 'Ustaw czas zamknięcia',
            '15m': '15 minut',
            '30m': '30 minut',
            '1h': '1 godzina',
            custom: 'Niestandardowe',
            verifyOtp: 'Zweryfikuj swój OTP',
            enterDigits: 'Wprowadź 6-cyfrowy kod wysłany na adres [email/telefon]',
            submit: 'Prześlij',
            order: 'Zlecenie',
            closedSuccessfully: 'zostało pomyślnie zamknięte',
            openSuccessfully: 'otwarte pomyślnie',
            deposit: 'depozyt',
            withdrawal: 'wycofanie',
            completeVerification: 'Kompletna weryfikacja',
            verification: 'Weryfikacja',
            avatar: 'Avatar',
            saveChanges: 'Zapisz zmiany',
            darkMode: 'Tryb ciemny',
            dashboardCurrency: 'Waluta pulpitu nawigacyjnego',
            language: 'Język',
            twoFactor: 'Ochrona 2-czynnikowa',
            googleTwoFactorIsActive: 'Uwierzytelnianie dwuskładnikowe Google jest aktywne',
            activateCodeForGoogle: 'Kod aktywacyjny dla Google Authenticator',
            ifYouWishDisableEnterCode: 'Jeśli chcesz wyłączyć ochronę, wprowadź kod aplikacji uwierzytelniającej',
            scanOrManuallyEnter: 'Zeskanuj lub ręcznie wprowadź kod QR w Google 2FA, aby dodać token',
            copyCode: 'Kopiuj kod',
            qrCode: 'Kod QR',
            activateAndEnterAuthenticator: 'Aktywuj Authenticator i wprowadź wygenerowany kod w polu poniżej',
            enterTheDigitsCodeGenerated: 'Wprowadź wygenerowany 6-cyfrowy kod',
            disableTwoFactor: 'Dezaktywacja ochrony 2FA',
            activateTwoFactor: 'Aktywacja ochrony 2FA',
            fileAdded: 'Plik został pomyślnie dodany',
            theVerificationStatus: 'Status weryfikacji można wyświetlić na liście przesłanych dokumentów',
            confirmWithdrawal: 'Potwierdź wypłatę',
            minimumWithdrawal: 'Minimalna kwota wypłaty wynosi',
            welcomeToTradingPlatform: 'Witamy na platformie transakcyjnej',
            signIn: 'Zalogować się',
            activeAccount: 'Aktywne konto',
            save: 'Zapisz',
            selectCurrencyAndPeriod: 'Wybierz walutę i okres subskrypcji',
            savingsNotFound: 'Nie znaleziono kont oszczędnościowych',
            pleaseCheckInvestment: 'Sprawdź szczegóły inwestycji, aby kontynuować',
            allPeriodInvestment: 'Wszystkie okresy inwestycji',
            pct_0: 'Elastyczny',
            pct_30: '1 Miesiąc',
            pct_60: '1 kwartał',
            pct_90: '6 miesięcy',
            pct_180: '6 miesięcy',
            pct_365: '1 rok',
            savingsCreated: 'Konto oszczędnościowe utworzone pomyślnie',
            transferDetails: 'Szczegóły transferu',
            transferAmount: 'Kwota przelewu',
            investmentDetails: 'Szczegóły Inwestycji',
            investment: 'Inwestycja',
            Period: 'Okres',
            created: 'Utworzony',
            releaseTime: 'Czas zwolnienia',
            viewSavingsAccounts: 'Zobacz konta oszczędnościowe',
            continueInvestment: 'Kontynuuj inwestycję',
            chooseSubscription: 'Wybierz swoją subskrypcję',
            selectCurrencyAndSubscription: 'Wybierz walutę i okres subskrypcji, aby kontynuować',
            rate: 'Stawka',
            accountTransferFunds: 'Konto do przelewu środków z',
            amount: 'Ilość',
            'in': 'w',
            openSavingsAndInvest: 'Otwórz Konto Oszczędnościowe i Inwestuj',
            strongSell: 'Silna sprzedaż',
            sell: 'Sprzedawać',
            neutral: 'Neutralne',
            buy: 'Kupować',
            stringBuy: 'Silny zakup',
            chooseCryptoWallet: 'Wybierz Portfel Kryptowalutowy',
            chooseMethod: 'Wybierz metodę',
            maximumDeposit: 'Maksymalny depozyt wynosi',
            minimumDeposit: "Minimalna wpłata to",
            pleaseCompleteAddress: 'Prosimy o uzupełnienie adresu',
            pleaseCompleteCity: 'Prosimy o podanie miasta',
            pleaseCompleteCountry: 'Prosimy o podanie kraju',
            pleaseCompleteZip: 'Prosimy o podanie kodu pocztowego',
            amountDeposit: 'Kwota depozytu',
            transactionStatus: 'Status transakcji',
            paymentMethod: 'Metoda płatności',
            depositTo: 'Depozyt do',
            depositAddress: 'Adres depozytu',
            scanTheQr: 'Zeskanuj kod QR lub skopiuj adres depozytu',
            wallet: 'Portfel',
            copyDepositAddress: 'Kopia adresu depozytowego',
            copy: 'Kopiuj',
            addressCopied: 'Adres skopiowany do schowka',
            '5min:': '5-10 min',
            '2-5 dni': '2-5 dni / 5-10 min',
            otherMethod: 'Inne metody',
            profileSettings: 'Ustawienia profilu',
            savingsAccounts: 'Konta Oszczędnościowe',
            subscribe: 'Subskrybować',
            toCompleteVerification: 'Aby zakończyć proces weryfikacji konta, należy dostarczyć wyraźne i czytelne kopie następujących dokumentów',
            amountToWithdrawal: 'Kwota wypłaty',
            cardDetails: 'Szczegóły karty',
            cardHolderName: 'Nazwa posiadacza karty',
            withdrawalSuccessful: 'Pomyślne Wycofanie',
            selectWithdrawalMethod: 'Wybierz metodę wypłaty',
            creditDebit: 'Karta kredytowa/debetowa',
            crypto: 'Krypto',
            cryptoWalletAddress: 'Adres portfela Crypro',
            cryptoSubtitle: 'Wypłaty krypto na twój portfel 🚀',
            bankWithdrawal: 'Wypłata bankowa',
            bankSubtitle: 'Konto bankowe 🏦',
            zelleSubtitle: 'Szybkie przelewy bankowe ⚡🏦',
            tradingResults: 'Wyniki handlowe',
            day: 'Dzień',
            month: 'Miesiąc',
            noTradingData: 'Brak danych handlowych',
            startTradingToSee: 'Rozpocznij handel, aby zobaczyć swoje wyniki tutaj.',
            yourPerformanceMetric: 'Wskaźniki wydajności zostaną wyświetlone po udostępnieniu danych.',
            favorite: 'Ulubione',
            all: 'Wszystkie',
            availableFullTime: 'Dostępny 24/7',
            yourProfileAndSettings: 'Twój profil i ustawienia',
            switchToNewDesignMessage: 'Przejdź na Naszą Nową Platformę Już Teraz!',
            switchToOldDesignMessage: 'Z klasycznego wyglądu można korzystać jeszcze przez ograniczony czas',
            switchToOldDesignButton: 'Przejście na Nowy Wygląd',
            switchToNewDesignButton: 'Powrót do Klasycznego Wzornictwa',
            generateInvoice: 'Wygeneruj fakturę',
            downloadPdf: 'Pobierz PDF',
            confirmDeposit: 'Potwierdź depozyt',
            howCanIAssist: 'Jak mogę Ci pomóc dzisiaj?',
            maxFileSize: 'Maksymalny rozmiar pliku to 5MB, akceptowane formaty to JPEG, PNG lub GIF',
            uploadPhoto: 'Prześlij zdjęcie',
            featuresDisabled: 'Ta funkcja jest wyłączona',
            welcome: 'Witamy',
            signUp: 'Zarejestruj się',
            registration: 'Rejestracja',
            pleaseConfirmAgreement: 'Proszę potwierdzić zgodę klienta',
            pleaseEnterPassword: 'Proszę wprowadzić hasło',
            passwordNotSame: 'Hasła nie są takie same',
            noActiveOrders: 'Brak aktywnych zamówień',
            itLikeNoHaveActiveOrders: 'Wygląda na to, że nie masz obecnie żadnych aktywnych zamówień',
            cryptoWallet: 'Portfel kryptowalutowy',
            bankCard: 'Bank/Karta',
            selected: 'Wybrano',
            amountWithdrawal: 'Kwota wypłaty',
            withdrawalTo: 'Wypłata do',
            governmentIssuedPhoto1: 'Government-issued photo ID (e.g., passport, driver\'s license , national ID card)',
            governmentIssuedPhoto2: 'Residence permit (if applicable)',
            governmentIssuedPhoto3: 'The document must be valid (not expired)',
            governmentIssuedPhoto4: 'The full name, date of birth, and photograph must be visible.',
            uploadRecent1: 'Upload a recent utility bill, bank statement, or rental agreement issued within the last 3 months',
            uploadRecent2: 'Ensure your full name and current residential address are clearly visible.',
            uploadCopy1: 'Upload a copy of the front side of your credit card, ensuring the first six and last four digits are visible',
            uploadCopy2: 'Please cover the middle digits for security',
            takeAndUpload1: 'Take and upload a selfie holding your ID next to your face',
            takeAndUpload2: 'Ensure both your face and the ID are clearly visible',
            otherDocuments: 'Other documents',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Prześlij wyraźny i czytelny obraz swojego dokumentu numeru ubezpieczenia społecznego (SSN). Upewnij się, że wszystkie szczegóły są widoczne i niezasłonięte.',
            frontSideSsnDocument: 'Przednia strona dokumentu SSN',
            answerSaved: 'Answers have been saved',
            cryptoWalletDetails: 'Crypto wallet details',
            bitcoinWalletAddress: 'Bitcoin wallet address',
            recovery: 'Recovery',
            passwordRecovery: 'Password recovery',
            searchCountry: 'Search country',
            questionnaire: 'Kwestionariusz',
            questionnaireText: 'Proszę wypełnić kwestionariusz, aby zweryfikować swoją tożsamość',
            sendAnswers: 'Wyślij odpowiedzi',
        },
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'TAKE PROFIT & STOP LOSS',
            takeProfit: ' Take Profit',
            stopLoss: ' Stop Loss',
            updateOrder: ' Zaktualizuj zamówienie'
        },
        balanceModal: {
            balance: 'Saldo',
            credit: ' Kredyt',
            equity: ' Kapital',
            practice: ' PRAKTYKA',
            real: ' REALNY',
            account: ' KONTO',
            invested: ' Zainwestowany',
            profit: ' Zysk',
            loss: ' Strata',
            margin: ' Margines',
            marginLevel: 'Poziom marginesu',
            marginFree: 'Wolny margines'
        },
        header: {
            cfd: 'CFD',
            platform: ' Platforma',
            deposit: ' Depozyt'
        },
        sideMenu: {
            marketWatch: 'Obserwacja rynku',
            activeOrders: 'Aktywne zamówienia',
            tradingHistory: 'Historia handlu',
            economicCalendar: 'Kalendarz',
            marketNews: 'Wiadomosci rynkowe'
        },
        closePositionModal: {
            closeOrder: 'Zamknij pozycje',
            areYouSure: 'Czy na pewno zamkniesz pozycje',
            buy: 'Kupic',
            sell: 'Sprzedac',
            yes: 'Tak',
            no: 'Nie',
        },
        pciDssModal: {
            desc: `Zgodność ze standardem bezpieczeństwa danych branży płatniczej (PCI DSS) jest wymagana od wszystkich podmiotów, które przechowują, przetwarzają lub przesyłają dane posiadaczy kart Visa/Master Card/Stripe, w tym instytucji finansowych, handlowców i dostawców usług. 
            Jest to globalny standard bezpieczeństwa dla wszystkich podmiotów, które przechowują, przetwarzają lub przesyłają dane posiadaczy kart i/lub wrażliwe dane uwierzytelniające. PCI DSS ustanawia podstawowy poziom ochrony dla konsumentów i pomaga zmniejszyć oszustwa oraz naruszenia danych w całym ekosystemie płatności.`
        },
        chart: {
            addNewChart: 'Dodaj nowy wykres',
        },
        symbolsModal: {
            watchlist: 'Lista obserwowanych',
            asset: 'Aktywa',
            price: 'Cena',
            changePct: 'Wymiana 24h'
        },
        pendingModal: {
            pending: 'W oczekiwaniu',
            assetPrice: 'Cena aktywów',
            current: ' Aktualny',
            revert: 'Powrót do ceny rynkowej',
            automatically: 'Pozycja zostanie otwarta automatycznie, gdy cena osiegnie ten poziom'
        },
        orderMenu: {
            default: 'default',
            volume: 'Pojemnosc',
            lots: 'partii',
            units: ' jednostki',
            currency: 'waluta',
            contractSize: 'Wielkosc kontraktu',
            position: 'Pozycja',
            margin: 'Margines',
            freeMargin: 'Wolny margines',
            takeProfitStopLoss: 'Odbierz zysk i zatrzymaj strate',
            pending: 'W oczekiwaniu',
            market: 'Rynek',
            leverage: 'Dźwignia',
            spread: 'Spread',
            notSet: 'Nie ustawiony',
            at: 'w',
            buy: 'kupić',
            sell: 'sprzedać',
        },
        footer: {
            supportBanner: 'KAZDEGO DNIA PRZEZ CALE DOBE',
            currentTime: 'OBECNY CZAS',
            liveChat: 'Czat na zywo'
        },
        statusBar: {
            balance: 'Saldo',
            credit: 'Kredyt',
            equity: 'Kapital',
            margin: 'Margines',
            marginLevel: 'Poziom marginesu',
            freeMargin: 'Wolny margines',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Data rejestracji',
            userId: 'Identyfikator uzytkownika',
            dashboard: 'Dashboard',
            personalData: 'Dane osobiste',
            deposit: 'Depozyt',
            withdrawFunds: 'Wypłatę srodków',
            savings: 'Oszczednosci',
            settings: 'Ustawienia',
            logout: 'Wyloguj sie'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Zlozyc depozyt',
            practiceAccount: 'Konto treningowe',
            realAccount: 'Prawdziwe konto',
            noWithdrawals: 'Brak wyplat',
            easyWithdrawals: 'latwe wyplaty',
            allAssets: 'Wszystkie aktywy dostepne',
            fullFledged: 'Pelnoprawna platforma',
            fillUpTo: 'Wypelnij do',
            freeReplenishment: 'Bezplatne uzupelnianie',
            topUp: 'Doladuj swoje konto',
            minimumAmount: 'Stawka minimalna',
            canSwitch: 'Mozesz przelaczac sie miedzy swoimi kontami w dowolnym momencie'
        },
        gridsModal: {
            chartGrids: 'SIATKI WYKRESÓW',
            chart1: '1 wykres',
            chart2: '2 wykres',
            chart3: '3 wykres',
            chart4: '4 wykres',
        },
        noConnectionModal: {
            connectionLost: 'Polaczenie z serwerem zostalo utracone',
            retryNow: 'Spróbuj teraz',
        },
        loginModal: {
            loginToTradeRoom: 'Zaloguj sie do Traderoom',
            email: 'Adres e-mail',
            enterEmail: 'Wpisz swój e-mail',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: 'Kod 2FA (jesli jest wlaczony)',
            twoFactorAuth: 'Uwierzytelnianie dwuskladnikowe',
            password: 'Haslo',
            yourPassword: 'Twoje haslo',
            signIn: 'Zaloguj sie',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Ustaw nowe haslo',
            newPassword: 'Nasza zalety',
            confirmNewPassword: 'Potwierdź nowe hasło',
            setButton: 'Ustaw'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Utwórz nowe konto',
            email: 'Adres e-mail',
            firstName: 'Imie',
            lastName: 'Nazwisko',
            country: 'Kraj',
            phone: 'Telefon',
            password: 'Haslo',
            createAccount: 'Utwórz konto',
            currency: 'Waluta',
            privacyAndPolicy: "Prywatność i Polityka",
            customerAgreement: 'Umowa z klientem',
        },
        forgotPasswordModal: {
            forgotPassword: 'Nie pamietam hasla?',
            submitEmail: 'Prosimy o podanie e-maila uzytego do rejestracji, sprawdzenie skrzynki odbiorczej i postepowanie zgodnie z podanymi instrukcjami.',
            submit: 'Przeslij'
        },
        notifications: {
            error: 'Blad',
            success: 'Sukces',
            deposit: 'Depozyt',
            withdrawal: 'Wycofanie',
            depositApproved: 'zostal zatwierdzony',
            depositDeclined: 'Depozyt zostal odrzucony',
            withdrawalApproved: 'Wycofanie zatwierdzone',
            withdrawalDeclined: 'Odmówiono wyplaty'
        },
        markets: {
            favorites: 'Lista obserwowanych', 
            all: 'Wszystkie symbole',
            forex: 'Forex',
            stocks: 'Akcje',
            commodities: 'Towary',
            indices: 'Wskazniki',
            crypto: 'Kryptowaluta',
            metals: 'Metale',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'BUY',
            profitCalculator: 'Kalkulator zysku',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Obserwacja rynku',
            search: 'Szukac  ...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Tom',
            margin: 'Marza',
            active: 'Aktywny',
            pending: 'W trakcie realizacji',
            activeOrders: 'Zamówienia aktywne',
            portfolio: 'Portfolio',
            allPositions: 'Wszystkie stanowiska',
            noPositionsLine1: 'Nie masz otwartego',
            noPositionsLine2: 'jeszcze stanowiska',
            show: 'Pokaz',
            more: 'wiecej',
            less: 'mniej',
            purchaseTime: 'Czas zakupu',
            closePosition: 'Pozycja zamkniecia',
            priceOpen: 'Cena zakupu',
            priceSl: 'Cena Stop Loss',
            priceTp: 'Cena Take Profit',
            type: 'Pozycja Kierunek',
            pnl: 'Zysk/Strata',
            cancelOrder: 'Anuluj zamówienie',
            orderType: 'Typ zamówienia',
            cancelled: 'Anulowane',
            tradingHistory: 'Historia handlu',
            noHistoryLine1: 'Nie masz zadnych',
            noHistoryLine2: 'jeszcze zamkniete transakcje',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Kalendarz'
        },
        widgetMarketNews: {
            marketNews: 'Wiadomosci rynkowe'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Zamówienia Aktywne',
            ordersHistory: 'Historia zamówien',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Typ',
            volume: 'Tom',
            openPrice: 'Cena wywolawcza',
            openTime: 'Czas otwarcia',
            closePrice: 'Zamknij cene',
            closeTime: 'Czas zamkniecia',
            sl: 'SL',
            tp: 'TP',
            price: 'Cena',
            pnl: 'PnL',
            actions: 'Dzialania',
            edit: 'Edytować',
            close: 'Zamknać',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Session Change',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        months: {
            Jan: 'Styczen',
            Feb: 'Luty',
            Mar: 'Marzec',
            Apr: 'Kwiecien',
            May: 'Maj',
            Jun: 'Czerwiec',
            Jul: 'Lipiec',
            Aug: 'Sierpien',
            Sep: 'Wrzesien',
            Oct: 'Pazdziernik',
            Nov: 'Listopad',
            Dec: 'Grudzien'
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'Transakcji dziennie:',
            desc: `Handel CFD i innymi produktami lewarowanymi może prowadzić do strat. Przed rozpoczęciem handlu klienci powinni zapoznać się z odpowiednimi oświadczeniami o ryzyku na naszej stronie ujawnienia ryzyka. Handel automatyczny nie gwarantuje wyników. Firma nie ponosi odpowiedzialności za utratę środków w handlu automatycznym. Proszę upewnić się, że w pełni rozumiesz ryzyko i podejmujesz środki w celu jego zarządzania.`,
            accept: 'Accept',
            save: 'Zapisz',
            cancel: 'Anuluj',
        },
        cardBox: {
            name: 'Imię',
            cardNumber: 'Numer karty',
            expiration: 'Wygasa (mm/rr)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Handel",
            startTrading: 'Rozpocznij Handel',
            deposit: 'Dokonaj wplaty',
            dashboard: 'Pulpit',
            personalInfo: 'Aktualizacja danych osobowych',
            contactInfo: 'Informacje kontaktowe',
            withdrawal: 'Wypłata środków',
            verification: 'Weryfikacja tożsamości',
            accounts: 'Zarządzaj kontami',
            liveChat: 'Czat na żywo',
            referrals: 'Program poleceń',
            savings: 'Lokaty',
            settings: 'Ustawienia platformy',
            logOut: 'Wyloguj się',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'DOKONAJ WPLATY DEPOZYTU',
            instant: 'Natychmiast',
            minutes: 'minuty',
            hours: 'godziny',
            days: 'dni',
            amount: 'Kwota',
            continue: 'Kontynuuj',
            qrCode: 'KOD QR',
            depositAddress: 'ADRES DEPOZYTOWY',
            copy: 'Kopia',
            last: 'OSTATNI',
            deposits: 'DEPOZYTY',
            time: 'Czas',
            currency: 'Waluta',
            status: 'Status',
            info: 'Informacje',
        },
        fullScreenCcExt: {
            pleaseWait: 'Prosze czekac. Polaczenie z dostawca uslug platniczych...',
            cardPayment: 'Platnosc karta',
            inOrder: 'W celu wyslania kwoty',
            pleaseProvide: 'Prosze podac dane karty:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'Brak zamówienia',
            totalBalance: 'Całkowite Saldo',
            totalPnl: 'Całkowity PNL',
            profitableOrders: 'Zyskowne pozycję',
            roi: 'ROI',
            activityLog: 'Lista Aktywności',
            loginFromIp: 'Login from IP',
            tradingResults: 'Trading Results',
            week: 'Tydzień',
            month: 'Miesiąc',
            year: 'Rok',
            successRate: 'Wskaźnik Sukcesu',
            closedWithProfit: 'Zamknięte z Zyskiem',
            closedWithLoss: 'Zamknięte ze stratą',
            account: 'Konto',
            balance: 'Saldo',
            leverage: 'Dźwignia',
            credit: 'Kredyt',
            tradeNow: 'Rozpocznij Handel',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Całkowite depozyty',
            totalWithdrawals: "Całkowite wypłaty",
            returnOnInvestment: "Zwrot z inwestycji"
        },
        fullScreenPersonal: {
            personalDetails: 'Dane osobowe',
            profilePhoto: 'Zdjecie profilowe',
            firstName: 'Imię',
            country: 'Kraj',
            lastName: 'Nazwisko',
            address: 'Adres',
            email: 'E-mail',
            phone: 'Numer telefonu',
            saveChanges: 'Zapisac zmiany',
            state: 'Miasto',
            postcode: 'Kod pocztowy',
            created: "Konto utworzone"
        },
        fullScreenAvatar: {
            dropFile: 'Wrzuc plik na okrag powyzej, aby go przeslac',
            notAllowed: 'Niedozwolone jest publikowanie',
            notAllowedLine1: 'Zdjecia o charakterze jednoznacznie seksualnym lub pornograficznym',
            notAllowedLine2: 'Obrazy majace na celu podzeganie do nienawisci lub agresji na tle etnicznym lub rasowym',
            notAllowedLine3: 'Zdjecia z udzialem osób ponizej 18 roku zycia',
            notAllowedLine4: 'Zdjecia chronione prawami autorskimi osób trzecich',
            notAllowedLine5: 'Obrazy wieksze niz 5 MB i w formacie innym niz JPG, GIF lub PNG',
            requirements: 'Twoja twarz musi byc wyraznie widoczna na zdjeciu. Wszystkie zdjecia i filmy zamieszczane przez Ciebie musza spelniac te wymagania, w przeciwnym razie moga zostac usuniete.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'WNIOSEK O NOWA WYPŁATĘ',
            amount: 'Kwota',
            currentBalance: 'Stan Twojego rachunku biezacego wynosi',
            withdrawAll: 'Wycofac wszystkie',
            requestWithdrawal: 'Wypłać',
            last: 'OSTATNI',
            withdrawalRequests: 'Historia',
            time: 'Czas',
            currency: 'WALUTA',
            info: 'Informacje',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Karta',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Szczególy wypłaty',
            address: 'Adres portfela',
            bankDetails: 'Szczegóły bankowe',
            bankAccount: 'Numer konta',
            bankHolder: 'Nazwa posiadacza konta',
            bankIban: 'IBAN',
            bankSwift: 'Kod SWIFT banku',
            recipientAddress: 'Adres odbiorcy',
            routingNumber: 'Numer rozliczeniowy banku',
            bankBeneficiary: 'Nazwa banku beneficjenta',
            bankAddress: 'Adres banku',
            bankCode: 'Kod banku (BSB)',
            desc: 'Opis',
            cardNumber: 'Numer karty',
            cardHolder: 'Nazwa posiadacza karty',
        },
        fullScreenVerification: {
            dragAndDrop: 'przeciagnij i upuść dokument na ten obszar',
            documentsList: 'LISTA PRZESLANYCH DOKUMENTÓW',
            document: 'Dokument',
            timeUploaded: 'Wyslano',
            timeProcessed: 'Zweryfikowano',
            status: 'Status',
            types: ['Przód dowodu', 'Dokument potwierdzający adres zamieszkania', 'Przód karty kredytowej', 'Tył karty kredytowej', 'Tył dowodu', 'Selfie', 'Other'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Wyślij',
            isConfirmed: 'jest potwierdzony',
            uploadAnotherDocument: 'Wyślij inny dokument',
            isUnderReview: 'jest w trakcie przegladu',
            questionnaire: 'Kwestionariusz',
            sendAnswers: 'Wyslij odpowiedzi',
            progressBarOf: 'z',
            progressBarText: 'twoich dokumentów zostały wgrane i potwierdzone'
        },
        fullScreenAccounts: {
            manageAccounts: 'Przelewaj srodki pomiedzy swoimi kontami',
            transferFrom: 'Z konta',
            transferTo: 'Na konto',
            amount: 'Kwota',
            makeTransfer: 'Dokonaj transferu',
            yourAccounts: 'Twoje konta',
            account: 'Konto',
            currency: 'Waluta',
            balance: 'Saldo',
            credit: 'Kredyt',
            makeActive: 'Uczyn aktywnym',
            active: 'Aktywny'
        },
        fullScreenSupport: {
            sendMessage: 'Napisz wiadomość...'
        },
        fullScreenSavings: {
            pct_0: 'Dynamiczna',
            pct_30: 'Terminowa miesiąc',
            pct_90: 'Terminowa kwartał',
            pct_180: 'Terminowa 6 miesięcy',
            pct_365: 'Terminowa rok',
            savings: 'Oszczednosci',
            detailedInformation: 'Szczególowe informacje',
            pleaseSelect: 'Prosze wybrac walute i okres',
            openSavings: 'Oszczednosci otwarte',
            currentTime: 'Aktualny czas',
            releaseTime: 'Czas wydania',
            currencyChosen: 'Wybrana waluta',
            periodChosen: 'Wybrany okres',
            yourRate: 'Twoja stawka',
            yourEarnings: 'Twoje zarobki',
            accountFrom: 'Z konta',
            enterAmount: 'Wprowadz kwote',
            in: 'W',
            openAndInvest: 'Otwórz konto oszczednosciowe i inwestuj',
            investment: 'Lokata',
            period: 'Okres',
            daysTotal: 'Dni',
            finalEarnings: 'Potencjialny zysk',
            created: 'Utworzony',
            daysElapsed: 'Dni, które uplynely',
            days: 'dni',
            earnings: 'Zarobki',
            fundsReleasedIn: 'Odbłokowane za',
            claim: 'Roszczenie',
            name: "Nazwa",
        },
        fullScreenSettings: {
            updatePassword: 'AKTUALIZACJA HASLA',
            currentPassword: 'Aktualne haslo',
            newPassword: 'Nowe hasło',
            changePassword: 'Zmiana hasła',
            activationForGoogle: 'Kod aktywacyjny dla Google Authenticator',
            activateAndEnter: 'Aktywuj kod uwierzytelniający i wprowadź wygenerowany kod w polu poniżej',
            qrCode: 'Kod QR',
            activateProtection: 'Aktywuj ochrone 2FA',
            protectionActive: 'Ochrona 2FA jest aktywna',
            twoFactorCode: 'Kod 2FA',
            disableProtection: 'Wylacz ochrone 2FA',
            language: 'Jezyk',
            dashboardCurrency: 'Waluta na Panelu',
            confirmNewPassword: 'Potwierdź nowe hasło',
        },
        fullScreenReferrals: {
            yourLink: 'TWÓJ LINK POLECAJACY',
            referredFriends: 'POLECENI ZNAJOMI',
            id: 'ID',
            name: 'Imie',
            registered: 'Zarejestrowany',
            level: 'Poziom',
            payout: 'Wypłatę',
            lastPayouts: 'OSTATNIE WYPLATY ZA POLECENIE',
            time: 'Czas',
            amount: 'Kwota',
            currency: 'Walut',
            info: 'Informacje',
            referralDesc:"Program partnerski został zaprojektowany, aby nagradzać Cię za rozwijanie naszej społeczności wartościowych inwestorów.",
            shareLink:"Udostępnij link:",
            subtitle:"Podaj mu swoje imię, e-mail lub ID handlowe.",
            descSuccesfull:"Gdy Twój polecony pomyślnie się zarejestruje, Twój Doradca Finansowy skontaktuje się z nim, aby pomóc mu w pierwszych krokach na drodze inwestycyjnej w najbardziej skuteczny i profesjonalny sposób.",
            descTracked:"Wszystkie jego wkłady są śledzone w ramach Twojego polecenia."
        },
        orderTypes: ['Kupic', 'Sprzedac', 'Wykupic limit', 'Limit sprzedazy', 'Zakup Stop', 'Sprzedac Stop'],
        statuses: ['W trakcie realizacji', 'Zatwierdzony', 'Odrzucony', 'Obróbka'],
        errors: {
            NOT_FOUND: 'Blad zadania',
            FORBIDDEN: 'Odmowa dostepu. Prosimy o ponowne zalogowanie.',
            SERVER_ERROR: 'Akcja nie powiodla sie. Prosze spróbowac ponownie pózniej',
            INVALID_ACCOUNT: 'Akcja nie powiodla sie. Prosze spróbowac ponownie pózniej',
            INVALID_EMAIL: 'Nieprawidlowy adres e-mail',
            INVALID_PHONE: 'Nieprawidlowy numer telefonu',
            INVALID_COUNTRY: 'Nieprawidlowy kraj',
            INVALID_LOGIN: 'Nieprawidlowe poswiadczenia logowania',
            USER_BLOCKED: 'Konto jest zablokowane. Prosimy o kontakt z obsluga',
            USER_REGISTERED: 'Uzytkownik o podanym adresie e-mail lub numerze telefonu jest juz zarejestrowany',
            INVALID_USER: 'Uzytkownik nie zostal znaleziony',
            REJECTED: 'Dzialanie nie jest dozwolone z powodu ograniczen platformy',
            INVALID_OLD_PASSWORD: 'Stare haslo jest niewazne',
            INVALID_SYMBOL: 'Nieprawidlowy symbol handlowy',
            INVALID_SYMBOL_PRECISION: 'Nieprawidlowa precyzja symbolu',
            INVALID_FRAME: 'Nieprawidlowa ramka wykresu',
            INVALID_CURRENCY: 'Nieprawidlowa waluta',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Nieprawidlowy typ handlowy konta',
            INSUFFICIENT_FUNDS: 'Niewystarczajace srodki finansowe',
            INVALID_WITHDRAWAL: 'Wycofanie nie zostalo odnalezione',
            INVALID_STATUS: 'Nieprawidlowy status',
            INVALID_FILE: 'Nieprawidlowe rozszerzenie pliku lub plik jest zbyt duzy',
            INVALID_DOCUMENT: 'Dokument nie zostal znaleziony',
            INVALID_NAME: 'Nieprawidlowa nazwa',
            INVALID_LOT_SIZE: 'Nieprawidlowa wielkosc dzialki',
            INVALID_LOT_STEP: 'Nieprawidlowy krok partii',
            INVALID_MIN_VOLUME: 'Nieprawidlowa objetosc minimalna',
            INVALID_MAX_VOLUME: 'Nieprawidlowa objetosc maksymalna',
            INVALID_GROUP: 'Nieprawidlowa grupa',
            INVALID_SPREAD: 'Niewazny rozrzut',
            REQUEST_TIMEOUT: 'Zadanie zostalo przerwane. Prosze spróbowac ponownie pózniej',
            EXISTING_OPEN_ORDERS: 'Masz otwarte zamówienie, najpierw zamknij zamówienie dla tego użytkownika i spróbuj ponownie.',
            INVALID_TYPE: 'Nieprawidlowy typ zamówienia',
            INVALID_VOLUME: 'Nieprawidlowa wielkosc zamówienia',
            INVALID_SL: 'Nieprawidlowa wartosc stop loss',
            INVALID_TP: 'Nieprawidlowa wartosc take profit',
            INVALID_PRICE: 'Nieprawidlowa cena zamówienia',
            INVALID_EXPIRATION: 'Nieprawidlowy termin waznosci zamówienia',
            NOT_ENOUGH_MARGIN: 'Niewystarczajaca marza',
            INVALID_ORDER: 'Zamówienie nie zostalo znalezione',
            MARKET_CLOSED: 'Handel nie jest dostepny. Prosze spróbowac ponownie',
            INVALID_BALANCE_TYPE: 'Nieprawidlowy typ operacji bilansowej',
            INVALID_HASH: 'Nieprawidlowy hash',
            HASH_EXPIRED: 'Twój link do resetowania hasla wygasl. Popros o nowe haslo.',
            INVALID_CODE: 'Nieprawidlowy kod 2FA',
            CHAT_DISABLED: 'Dostep do czatu pomocy technicznej zostal zamkniety',
            WITHDRAWAL_NOT_ALLOWED: 'Dostep do wyplat zostal zamkniety',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is '
        },
        countries: {
            AF: 'Afganistan',
            AX: 'Wyspy Alandzkie',
            AL: 'Albania',
            DZ: 'Algieria',
            AS: 'Samoa Amerykanskie',
            AD: 'Andora',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktyda',
            AG: 'Antigua i Barbuda',
            AR: 'Argentyna',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbejdzan',
            BS: 'Bahamy',
            BH: 'Bahrajn',
            BD: 'Bangladesz',
            BB: 'Barbados',
            BY: 'Bialorus',
            BE: 'Belgia',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermudy',
            BT: 'Bhutan',
            BO: 'Boliwia',
            BA: 'Bosnia i Hercegowina',
            BW: 'Botswana',
            BV: 'Wyspa Bouveta',
            BR: 'Brazylia',
            IO: 'Brytyjskie Terytorium Oceanu Indyjskiego',
            BN: 'Panstwo Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodza',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Republika Zielonego Przyladka',
            KY: 'Kajmany',
            CF: 'Republika Srodkowoafrykanska',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'Chiny',
            CX: 'Wyspa Bozego Narodzenia',
            CC: 'Wyspy Kokosowe (Keelinga)',
            CO: 'Kolumbia',
            KM: 'Komory',
            CG: 'Kongo',
            CD: 'Kongo, Republika Demokratyczna',
            CK: 'Wyspy Cooka',
            CR: 'Kostaryka',
            CI: 'Wybrzeze Kosci Sloniowej',
            HR: 'Chorwacja',
            CU: 'Kuba',
            CY: 'Cypr',
            CZ: 'Republika Czeska',
            DK: 'Dania',
            DJ: 'Dzibuti',
            DM: 'Dominika',
            DO: 'Republika Dominikanska',
            EC: 'Ekwador',
            EG: 'Egipt',
            SV: 'Salwador',
            GQ: 'Gwinea Równikowa',
            ER: 'Erytrea',
            EE: 'Estonia',
            ET: 'Etiopia',
            FK: 'Wyspy Falklandzkie (Malwiny)',
            FO: 'Wyspy Owcze',
            FJ: 'Fidzi',
            FI: 'Finlandia',
            FR: 'Francja',
            GF: 'Gujana Francuska',
            PF: 'Polinezja Francuska',
            TF: 'Francuskie Terytoria Poludniowe',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Niemcy',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Grecja',
            GL: 'Grenlandia',
            GD: 'Grenada',
            GP: 'Gwadelupa',
            GU: 'Guam',
            GT: 'Gwatemala',
            GG: 'Guernsey',
            GN: 'Gwinea',
            GW: 'Gwinea Bissau',
            GY: 'Gujana',
            HT: 'Haiti',
            HM: 'Wyspa Heard i Wyspy Mcdonalda',
            VA: 'Stolica Apostolska (Panstwo Watykanskie)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Wegry',
            IS: 'Islandia',
            IN: 'Indie',
            ID: 'Indonezja',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irlandia',
            IM: 'Wyspa Man',
            IL: 'Izrael',
            IT: 'Wlochy',
            JM: 'Jamajka',
            JP: 'Japonia',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazachstan',
            KE: 'Kenia',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwejt',
            KG: 'Kirgistan',
            LA: 'Laotanska Republika Ludowo-Demokratyczna',
            LV: 'Lotwa',
            LB: 'Liban',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libijska Arabska Dzamahirija',
            LI: 'Liechtenstein',
            LT: 'Litwa',
            LU: 'Luksemburg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malezja',
            MV: 'Malediwy',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Wyspy Marshalla',
            MQ: 'Martynika',
            MR: 'Mauretania',
            MU: 'Mauritius',
            YT: 'Majotta',
            MX: 'Meksyk',
            FM: 'Mikronezja',
            MD: 'Moldawia',
            MC: 'Monako',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Maroko',
            MZ: 'Mozambik',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Niderlandy',
            AN: 'Antyle Holenderskie',
            NC: 'Nowa Kaledonia',
            NZ: 'Nowa Zelandia',
            NI: 'Nikaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Wyspa Norfolk',
            MP: 'Mariany Pólnocne',
            NO: 'Norwegia',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Terytorium Palestynskie, Okupowane',
            PA: 'Panama',
            PG: 'Papua Nowa Gwinea',
            PY: 'Paragwaj',
            PE: 'Peru',
            PH: 'Filipiny',
            PN: 'Pitcairn',
            PL: 'Polska',
            PT: 'Portugalia',
            PR: 'Puerto Rico',
            QA: 'Katar',
            RE: 'Zjazd',
            RO: 'Rumunia',
            RU: 'Rosja',
            RW: 'Rwanda',
            BL: 'Swiety Bartymeusz',
            SH: 'Swieta Helena',
            KN: 'Saint Kitts i Nevis',
            LC: 'Saint Lucia (Lucja Pólnocna)',
            MF: 'Swiety Marcin',
            PM: 'Saint Pierre i Miquelon',
            VC: 'Saint Vincent i Grenadyny',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Wyspy Swietego Tomasza i Ksiazeca',
            SA: 'Arabia Saudyjska',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seszele',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slowacja',
            SI: 'Slowenia',
            SB: 'Wyspy Salomona',
            SO: 'Somalia',
            ZA: 'Republika Poludniowej Afryki',
            GS: 'Poludniowa Georgia i Sandwich Isl.',
            ES: 'Hiszpania',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard i Jan Mayen',
            SZ: 'Suazi',
            SE: 'Szwecja',
            CH: 'Szwajcaria',
            SY: 'Syryjska Republika Arabska',
            TW: 'Taiwan',
            TJ: 'Tadzykistan',
            TZ: 'Tanzania',
            TH: 'Tajlandia',
            TL: 'Timor Wschodni',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trynidad i Tobago',
            TN: 'Tunezja',
            TR: 'Turcja',
            TM: 'Turkmenistan',
            TC: 'Wyspy Turks i Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraina',
            AE: 'Zjednoczone Emiraty Arabskie',
            GB: 'Zjednoczone Królestwo',
            US: 'Stany Zjednoczone',
            UM: 'Wyspy Zewnetrzne Stanów Zjednoczonych',
            UY: 'Urugwaj',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Wenezuela',
            VN: 'Wietnam',
            VG: 'Brytyjskie Wyspy Dziewicze',
            VI: 'Wyspy Dziewicze, U.S.',
            WF: 'Wallis i Futuna',
            EH: 'Sahara Zachodnia',
            YE: 'Jemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    cz: {
        _name: 'Čeština',
        timePickerModal: {
            title: 'Close In Time'
        },
        newDesign: {
            withdrawalRequests: 'Zatím žádné žádosti o stažení',
            withdrawalRequestsText1: 'Dosud jste nepodali žádnou žádost o výběr.',
            withdrawalRequestsText2: 'Jakmile zahájíte výběr, zobrazí se zde údaje o vaší transakci',
            pdfAttachment: 'Příloha PDF',
            agent: "Agent",
            closed: 'Uzavřeno',
            pending: 'Nevyřízený',
            approved: 'Schváleno',
            declined: 'Odmítl',
            document: 'Dokument',
            frontSide: 'Přední strana',
            backSide: 'Zadní strana',
            address: 'Adresa',
            frontSideDocument: 'Přední strana dokladu',
            creditCard: 'Kreditní karta',
            selfie: 'Selfie',
            other: 'Další',
            noDocument: 'Zatím nebyly nahrány žádné dokumenty',
            amountDeposited: 'Vložená částka',
            makeTransfer: 'Proveďte převod',
            otherDetails: 'Podrobnosti o objednávce',
            manualWallets: 'Krypto peněženky',
            depositText: 'Vklad je zpracován do 5-10 minut',
            pendingBankTransfer: 'Máte nevyřízený vklad bankovním převodem.',
            completeDeposit: 'Pro dokončení vkladu postupujte podle níže uvedených pokynů',
            downloadInvoice: 'Stáhnout fakturu',
            payInvoice: 'Zaplatit fakturu v bance',
            useYourBankText1: 'K úhradě faktury použijte online nebo offline platební systém své banky.',
            useYourBankText2: 'Ujistěte se, že platební údaje jsou správné.',
            uploadReceipt: 'Nahrát potvrzení o přijetí',
            afterMakingPayment: 'Po provedení platby nahrajte účtenku a potvrďte zálohu.',
            clickToUpload: 'Klikněte pro nahrání',
            orDrag: 'nebo přetáhněte a pusťte',
            fileFormat: 'PDF, JPG, JPEG, PNG méně než 10MB',
            waitForDeposit: 'Čekání na potvrzení vkladu',
            onceReceipt: 'Po nahrání účtenky zálohu ověříme a schválíme.',
            paymentDetails: 'Platební údaje',
            nameOnCard: 'Jméno na kartě',
            cardNumber: 'Číslo karty',
            expirationDate: 'Datum vypršení platnosti',
            securityCode: 'Bezpečnostní kód',
            continue: 'Pokračovat',
            viewAccounts: 'Zobrazit účty',
            continueWithdrawal: 'Pokračovat Odnětí',
            accountBalance: 'Zůstatek na účtu',
            accountBalanceText1: 'Proč nemohu provést obchod?',
            accountsBalanceText2: 'Jak mohu vložit finanční prostředky?',
            accountsBalanceText3: 'Chci vložit peníze přes Zelle',
            tradingActions: 'Obchodování akce',
            tradingActionsText1: 'Jak prodávat nakrátko?',
            tradingActionsText2: 'Jak mohu zrušit obchod?',
            tradingActionsText3: 'Jaká je minimální částka obchodu?',
            marketInfo: 'Info o trhu',
            marketInfoText1: 'Kde si mohu prohlédnout trendy na trhu?',
            marketInfoText2: 'Co dnes nabírá největší dynamiku?',
            securityAndSettings: 'Zabezpečení a Nastavení',
            securityAndSettingsText1: 'Jak si mohu změnit heslo?',
            securityAndSettingsText2: 'Jaké jsou funkce zabezpečení?',
            securityAndSettingsText3: 'Jak mohu aktualizovat informace o svém účtu?',
            messageCopied: 'Zpráva zkopírovaná do schránky',
            addSymbol: 'Přidat Symbol',
            iHaveRead: 'Přečetl/a jsem si podmínky a souhlasím s nim',
            toggleToTurn: 'Přepínač pro zapnutí funkce AutoTrade',
            selectTheRisk: 'Zvolte úroveň rizika',
            confirm: 'Potvrďte',
            cancel: 'Zrušit',
            chooseClosingTime: 'Zvolte si čas uzavření',
            setOrderClosingTime: 'Nastavení času uzavření objednávky',
            closingTime: 'Čas uzavření',
            date: 'Datum',
            time: 'Čas',
            setCloseTime: 'Nastavení času zavření',
            '15m': '15 minut',
            '30m': '30 minut',
            '1h': '1 hodina',
            custom: 'Vlastní',
            verifyOtp: 'Ověření vašeho OTP',
            enterDigits: 'Zadejte šestimístný kód zaslaný na váš [e-mail/telefon].',
            submit: 'Odeslat',
            order: 'Objednávka',
            closedSuccessfully: 'byla úspěšně uzavřena',
            openSuccessfully: 'úspěšně otevřít',
            deposit: 'vklad',
            withdrawal: 'stažení',
            completeVerification: 'Kompletní Ověření',
            verification: 'Ověření',
            avatar: 'Avatar',
            saveChanges: 'Uložení Změn',
            darkMode: 'Tmavý režim',
            dashboardCurrency: 'Přístrojová Deska Měny',
            language: 'Jazyk',
            twoFactor: '2-faktorová ochrana',
            googleTwoFactorIsActive: '2-faktorová autentizátor Google je aktivní',
            activateCodeForGoogle: 'Aktivace kódu pro službu Google Authenticator',
            ifYouWishDisableEnterCode: 'To turn off protection, enter the authentication application code',
            scanOrManuallyEnter: 'Naskenování nebo ruční zadání QR kódu v aplikaci Google 2FA pro přidání tokenu',
            copyCode: 'Kopírovat kód',
            qrCode: 'QR kód',
            activateAndEnterAuthenticator: 'Aktivujte Authenticator a zadejte vygenerovaný kód do pole níže',
            enterTheDigitsCodeGenerated: 'Zadejte vygenerovaný 6místný kód',
            disableTwoFactor: 'Zakázání Dvoufaktorové Ochrany',
            activateTwoFactor: 'Aktivovat ochranu 2FA',
            fileAdded: 'Soubor byl úspěšně přidán',
            theVerificationStatus: 'Stav ověření lze zobrazit v seznamu nahraných dokumentů',
            confirmWithdrawal: 'Potvrdit výběr',
            minimumWithdrawal: 'Minimální částka pro výběr je',
            welcomeToTradingPlatform: 'Vítejte na obchodní platformě',
            signIn: 'Přihlaste se',
            activeAccount: 'Aktivní účet',
            save: 'Uložit',
            selectCurrencyAndPeriod: 'Výběr měny a období předplatného',
            savingsNotFound: 'Spořicí Účty Nebyly Nalezeny',
            pleaseCheckInvestment: 'Zkontrolujte prosím své investiční údaje a pokračujte',
            allPeriodInvestment: 'Všechny investice v daném období',
            pct_0: 'Flexibilní',
            pct_30: '1 Měsíc',
            pct_60: '1 Čtvrtletí',
            pct_90: '6 Měsíců',
            pct_180: '6 Měsíců',
            pct_365: '1 Rok',
            savingsCreated: 'Spořicí účet úspěšně vytvořen',
            transferDetails: 'Podrobnosti o přenosu',
            transferAmount: 'Částka Převodu',
            investmentDetails: 'Investiční Podrobnosti',
            investment: 'Investice',
            Period: 'Období',
            created: 'Vytvořeno',
            releaseTime: 'Doba uvolnění',
            viewSavingsAccounts: 'Zobrazit Spořicí Účty',
            continueInvestment: 'Pokračování Investice',
            chooseSubscription: 'Vyberte si Předplatné',
            selectCurrencyAndSubscription: 'Vyberte měnu a období předplatného a pokračujte.',
            rate: 'Hodnotit',
            accountTransferFunds: 'Účet, z něhož se mají převádět prostředky',
            amount: 'Částka',
            'in': 'v',
            openSavingsAndInvest: 'Otevřete Spořicí Účet a Investujte',
            strongSell: 'Silné Prodeje',
            sell: 'Prodat',
            neutral: 'Neutrální',
            buy: 'Koupit',
            stringBuy: 'Silná Koupě',
            chooseCryptoWallet: 'Výběr Kryptoměnové Peněženky',
            chooseMethod: 'Vyberte metodu',
            maximumDeposit: 'Maximální vklad je',
            minimumDeposit: "Minimální vklad je",
            pleaseCompleteAddress: 'Vyplňte prosím svou adresu',
            pleaseCompleteCity: 'Vyplňte prosím své město',
            pleaseCompleteCountry: 'Vyplňte prosím svou zemi',
            pleaseCompleteZip: 'Vyplňte prosím své PSČ',
            amountDeposit: 'Výše vkladu',
            transactionStatus: 'Stav transakce',
            paymentMethod: 'Způsob platby',
            depositTo: 'Vklad na',
            depositAddress: 'Adresa vkladu',
            scanTheQr: 'Naskenujte QR kód nebo zkopírujte adresu vkladu',
            wallet: 'Peněženka',
            copyDepositAddress: 'Kopírovat Adresu Vkladu',
            copy: 'Kopírovat',
            addressCopied: 'Adresa zkopírovaná do schránky',
            '5min:': '5-10 minut',
            '2-5days': '2-5 dní / 5-10 min',
            otherMethod: 'Jiná metoda',
            profileSettings: 'Nastavení profilu',
            savingsAccounts: 'Spořicí účty',
            subscribe: 'Předplatit',
            toCompleteVerification: 'Pro dokončení procesu ověření vašeho účtu předložte jasné a čitelné kopie následujících dokumentů',
            amountToWithdrawal: 'Částka pro výběr',
            cardDetails: 'Detaily karty',
            cardHolderName: 'Jméno majitele karty',
            withdrawalSuccessful: 'Úspěšné Stažení',
            selectWithdrawalMethod: 'Zvolte metodu stažení',
            creditDebit: 'Kreditní/debetní karta',
            crypto: 'Krypto',
            cryptoWalletAddress: 'Adresa peněženky společnosti Crypro',
            cryptoSubtitle: 'Krypto výběry do vaší peněženky 🚀',
            bankWithdrawal: 'Bankovní výběr',
            bankSubtitle: 'Bankovní účet 🏦',
            zelleSubtitle: 'Rychlé bankovní převody ⚡🏦',
            tradingResults: 'Výsledky obchodování',
            day: 'Den',
            month: 'Měsíc',
            noTradingData: 'Údaje o obchodování nejsou k dispozici',
            startTradingToSee: 'Začněte obchodovat a podívejte se na své výsledky zde.',
            yourPerformanceMetric: 'Vaše výkonnostní ukazatele se zobrazí, jakmile budou k dispozici data.',
            favorite: 'Oblíbené',
            all: 'Všechny',
            availableFullTime: 'Dostupné 24/7',
            yourProfileAndSettings: 'Váš profil a nastavení',
            switchToNewDesignMessage: 'Přechod na Náš Nový Design Platformy!',
            switchToOldDesignMessage: 'Po omezenou dobu můžete stále používat klasický design',
            switchToOldDesignButton: 'Přechod na Nový Design',
            switchToNewDesignButton: 'Návrat ke Klasickému Designu',
            generateInvoice: 'Vygenerovat fakturu',
            downloadPdf: 'Stáhnout PDF',
            confirmDeposit: 'Potvrdit vklad',
            howCanIAssist: 'Jak vám mohu dnes pomoci?',
            maxFileSize: 'Maximální velikost souboru je 5MB, přijímané formáty jsou JPEG, PNG nebo GIF',
            uploadPhoto: 'Nahrát fotografii',
            featuresDisabled: 'Tato funkce je deaktivována',
            welcome: 'Vítejte',
            signUp: 'Zaregistrovat se',
            registration: 'Registrace',
            pleaseConfirmAgreement: 'Potvrďte prosím souhlas zákazníka',
            pleaseEnterPassword: 'Zadejte prosím heslo',
            passwordNotSame: 'Hesla se neshodují',
            noActiveOrders: 'Žádné aktivní objednávky',
            itLikeNoHaveActiveOrders: 'Vypadá to, že momentálně nemáte žádné aktivní objednávky',
            cryptoWallet: 'Kryptoměnová peněženka',
            bankCard: 'Banka/Karta',
            selected: 'Vybráno',
            amountWithdrawal: 'Částka k výběru',
            withdrawalTo: 'Výběr na',
            governmentIssuedPhoto1: 'Doklad totožnosti vydaný státem (např. pas, řidičský průkaz, národní průkaz)',
            governmentIssuedPhoto2: 'Povolení k pobytu (pokud je relevantní)',
            governmentIssuedPhoto3: 'Doklad musí být platný (neprošlý)',
            governmentIssuedPhoto4: 'Celé jméno, datum narození a fotografie musí být viditelné.',
            uploadRecent1: 'Nahrajte nedávný účet za služby, bankovní výpis nebo nájemní smlouvu vystavenou za poslední 3 měsíce',
            uploadRecent2: 'Ujistěte se, že vaše celé jméno a aktuální adresa bydliště jsou jasně viditelné.',
            uploadCopy1: 'Nahrajte kopii přední strany vaší kreditní karty a zajistěte, aby bylo vidět prvních šest a poslední čtyři číslice',
            uploadCopy2: 'Zakryjte prosím prostřední číslice kvůli bezpečnosti',
            takeAndUpload1: 'Pořiďte a nahrajte selfie, držíc doklad totožnosti vedle obličeje',
            takeAndUpload2: 'Ujistěte se, že váš obličej a doklad totožnosti jsou jasně viditelné',
            otherDocuments: 'Další dokumenty',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Nahrajte jasný a čitelný obrázek svého dokumentu čísla sociálního zabezpečení (SSN). Ujistěte se, že všechny detaily jsou viditelné a neblokované.',
            frontSideSsnDocument: 'Přední strana dokumentu SSN',
            answerSaved: 'Odpovědi byly uloženy',
            cryptoWalletDetails: 'Podrobnosti o kryptopeněžence',
            bitcoinWalletAddress: 'Adresa Bitcoin peněženky',
            recovery: 'Obnova',
            passwordRecovery: 'Obnova hesla',
            searchCountry: 'Hledat zemi',
            questionnaire: 'Dotazník',
            questionnaireText: 'Prosím, vyplňte dotazník k ověření vaší identity',
            sendAnswers: 'Odeslat odpovědi',
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            takeProfit: 'Přijmout zisk',
            stopLoss: 'Zastavení ztráty',
            updateOrder: 'Aktualizace objednávky',
        },
        balanceModal: {
            balance: 'Rozvaha',
            credit: 'Kredit',
            equity: 'Kapitál',
            practice: 'PRAKTIKA',
            real: 'REÁLNÉ',
            account: 'ÚČET',
            invested: 'Investováno',
            profit: 'Zisk',
            loss: 'Ztráty',
            margin: 'Marže',
            marginLevel: 'Úroveň marže',
            marginFree: 'Volná marže'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platforma',
            deposit: 'Vklad'
        },
        sideMenu: {
            marketWatch: 'Sledování trhu',
            activeOrders: 'Aktivní objednávky',
            tradingHistory: 'Historie obchodování',
            economicCalendar: 'Kalendář',
            marketNews: 'Zprávy z trhu'
        },
        closePositionModal: {
            closeOrder: 'Zavřít pozici',
            areYouSure: 'Určitě chcete pozici uzavřít',
            buy: 'Koupit',
            sell: 'Prodej',
            yes: 'Ano',
            no: 'Ne',
        },
        pciDssModal: {
            desc: `Dodržování standardu bezpečnosti dat odvětví platebních karet (PCI DSS) je vyžadováno od všech subjektů, které uchovávají, zpracovávají nebo přenášejí údaje držitelů karet Visa/Master Card/Stripe, včetně finančních institucí, obchodníků a poskytovatelů služeb. 
            Jedná se o globální bezpečnostní standard pro všechny subjekty, které uchovávají, zpracovávají nebo přenášejí údaje držitelů karet a/nebo citlivé autentizační údaje. PCI DSS stanoví základní úroveň ochrany pro spotřebitele a pomáhá snižovat podvody a úniky dat v celém platebním ekosystému.`,
        },
        chart: {
            addNewChart: 'Přidat nový graf',
        },
        symbolsModal: {
            watchlist: 'Seznam sledování',
            asset: 'Majetek',
            price: 'Cena',
            changePct: 'Změna 24h'
        },
        pendingModal: {
            pending: 'Čekající',
            assetPrice: 'Cena aktiv',
            current: 'Aktuální',
            revert: 'Návrat k tržní ceně',
            automatically: 'Pozice se otevře automaticky, jakmile cena dosáhne této úrovně.'
        },
        orderMenu: {
            default: 'default',
            volume: 'Objem',
            lots: 'šarže',
            units: 'jednotky',
            currency: 'měna',
            contractSize: 'Velikost smlouvy',
            position: 'Pozice',
            margin: 'Marže',
            freeMargin: 'Volná marže',
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            pending: 'Čekající',
            market: 'Trh',
            leverage: 'Pákový efekt',
            spread: 'Rozšíření',
            notSet: 'Není nastaveno',
            at: 'na',
            buy: 'Koupit',
            sell: 'Prodej',
        },
        footer: {
            supportBanner: 'KAŽDÝ DEN, NEPŘETRŽITĚ',
            currentTime: 'BĚŽNÝ ČAS',
            liveChat: 'Živý chat'
        },
        statusBar: {
            balance: 'Balance',
            credit: 'Kredit',
            equity: 'Kapitál',
            margin: 'Marže',
            marginLevel: 'Úroveň marže',
            freeMargin: 'Volná marže',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Datum registrace',
            userId: 'ID uživatele',
            dashboard: 'Dashboard',
            personalData: 'Osobní údaje',
            deposit: 'Vklad',
            withdrawFunds: 'Výběr finančních prostředků',
            savings: 'Úspory',
            settings: 'Nastavení',
            logout: 'Odhlášení'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Provést vklad',
            practiceAccount: 'Účet pro praxi',
            realAccount: 'Skutečný účet',
            noWithdrawals: 'Žádné výběry',
            easyWithdrawals: 'Snadné výběry',
            allAssets: 'Všechna dostupná aktiva',
            fullFledged: 'Plnohodnotná platforma',
            fillUpTo: 'Naplnit do',
            freeReplenishment: 'Bezplatné doplňování',
            topUp: 'Dobití účtu',
            minimumAmount: 'Minimální částka',
            canSwitch: 'Mezi účty můžete kdykoli přepínat'
        },
        gridsModal: {
            chartGrids: 'GRAFICKÉ MŘÍŽKY',
            chart1: '1 diagram',
            chart2: '2 diagram',
            chart3: '3 diagram',
            chart4: '4 diagram',
        },
        noConnectionModal: {
            connectionLost: 'Spojení se serverem je ztraceno',
            retryNow: 'Zkusit to znovu',
        },
        loginModal: {
            loginToTradeRoom: 'Přihlášení do Traderoom',
            email: 'E-mailová adresa',
            enterEmail: 'Zadejte svůj e-mail',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: 'Kód 2FA (je-li povolen)',
            twoFactorAuth: 'Dvoufaktorové ověřování',
            password: 'Heslo',
            yourPassword: 'Vaše heslo',
            signIn: 'Přihlásit se',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Nastavení nového hesla',
            newPassword: 'Nové heslo',
            confirmNewPassword: 'Potvrzení nového hesla',
            setButton: 'Sada'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Vytvořit nový účet',
            email: 'E-mailová adresa',
            firstName: 'Křestní jméno',
            lastName: 'Příjmení',
            country: 'Stát',
            phone: 'Telefon',
            password: 'Heslo',
            createAccount: 'Vytvořit účet',
            currency: 'Měna',
            privacyAndPolicy: "Ochrana osobních údajů a podmínky",
            customerAgreement: 'Smlouva se zákazníkem',

        },
        forgotPasswordModal: {
            forgotPassword: 'Zapomenuté heslo?',
            submitEmail: 'Odešlete e-mail použitý k registraci, zkontrolujte si doručenou poštu a postupujte podle uvedených pokynů.',
            submit: 'Odeslat'
        },
        notifications: {
            error: 'Chyba',
            success: 'Úspěch',
            deposit: 'Vklad',
            withdrawal: 'Stažení',
            depositApproved: 'byl schválen',
            depositDeclined: 'Vklad byl odmítnut',
            withdrawalApproved: 'Stažení bylo schváleno',
            withdrawalDeclined: 'Stažení bylo zamítnuto'
        },
        markets: {
            favorites: 'Seznam sledování', 
            all: 'Všechny symboly',
            forex: 'Forex',
            stocks: 'Stocks',
            commodities: 'Komodity',
            indices: 'Indexy',
            crypto: 'Crypto',
            metals: 'Kovy',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'BUY',
            profitCalculator: 'Kalkulačka zisku',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Sledování trhu',
            search: 'Vyhledávání...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Objem',
            margin: 'Marže',
            active: 'Aktivní',
            pending: 'Čekající',
            activeOrders: 'Aktivní objednávky',
            portfolio: 'Portfolio',
            allPositions: 'Všechny pozice',
            noPositionsLine1: 'Nemáte žádné otevřené',
            noPositionsLine2: 'pozice zatím',
            show: 'Zobrazit',
            more: 'více',
            less: 'méně',
            purchaseTime: 'Čas nákupu',
            closePosition: 'Zavřít pozici',
            priceOpen: 'Nákupní cena',
            priceSl: 'Cena Stop Loss',
            priceTp: 'Cena Take Profit',
            type: 'Pozice Směr',
            pnl: 'Zisk/ztráta',
            cancelOrder: 'Zrušit objednávku',
            orderType: 'Typ objednávky',
            cancelled: 'Zrušeno',
            tradingHistory: 'Historie obchodování',
            noHistoryLine1: 'Nemáte žádné',
            noHistoryLine2: 'dosud uzavřené obchody',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Kalendář'
        },
        widgetMarketNews: {
            marketNews: 'Zprávy z trhu'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Aktivní Objednávky',
            ordersHistory: 'Historie objednávek',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Typ',
            volume: 'Objem',
            openPrice: 'Otevřená cena',
            openTime: 'Otevřený čas',
            closePrice: 'Zavřít Cena',
            closeTime: 'Tiempo de cierre',
            sl: 'SL',
            tp: 'TP',
            price: 'Cena',
            pnl: 'PnL',
            actions: 'Akce',
            edit: 'Upravit',
            close: 'Zavřít',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Session Change',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'Obchodů za den:',
            desc: `Obchodování s CFD a dalšími pákovými produkty může vést ke ztrátám. Před obchodováním by si klienti měli přečíst příslušná prohlášení o rizicích na naší stránce zveřejnění rizik. Automatizované obchodování nezaručuje výsledky. Společnost nenese žádnou odpovědnost za ztrátu finančních prostředků v automatizovaném obchodování. Ujistěte se, že plně rozumíte rizikům a přijímáte opatření k jejich řízení.`,
            accept: 'Accept',
            save: 'Uložit',
            cancel: 'Zrušit',
        },
        cardBox: {
            name: 'Jméno',
            cardNumber: 'Číslo karty',
            expiration: 'Expirace (mm/rr)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Obchod",
            startTrading: 'Začít Obchodovat',
            deposit: 'Provést vklad',
            dashboard: 'Dashboard',
            personalInfo: 'Aktualizace osobních údajů',
            contactInfo: 'Kontaktní informace',
            withdrawal: 'Výběr prostředků',
            verification: 'Ověřování totožnosti',
            accounts: 'Správa účtů',
            liveChat: 'Živý chat',
            referrals: 'Referral program',
            savings: 'Spoření',
            settings: 'Nastavení platformy',
            logOut: 'Odhlášení',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'UPLATNIT ZÁLOHU',
            instant: 'Instantní',
            minutes: 'minuty',
            hours: 'hodin',
            days: 'dny',
            amount: 'Částka',
            continue: 'Pokračovat',
            qrCode: 'QR KÓD',
            depositAddress: 'ADRESA VKLADU',
            copy: 'Kopírovat',
            last: 'POSLEDNÍ',
            deposits: 'DEPOSITY',
            time: 'Čas',
            currency: 'Měna',
            status: 'Status',
            info: 'Informace',
        },
        fullScreenCcExt: {
            pleaseWait: 'Počkejte prosím. Připojení k poskytovateli platebních služeb...',
            cardPayment: 'Platba kartou',
            inOrder: 'Za účelem odeslání částky',
            pleaseProvide: 'uveďte prosím údaje o kartě:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'Žádná objednávka',
            totalBalance: 'Finální Balance',
            totalPnl: 'Totální Pnl',
            profitableOrders: 'Výdělečné Objednávky',
            roi: 'ROI',
            activityLog: 'Aktivační Log',
            loginFromIp: 'Login from IP',
            tradingResults: 'Tržní Výsledky ',
            week: 'Týden',
            month: 'Měsíc',
            year: 'Rok',
            successRate: 'Úspěšná Hodnota',
            closedWithProfit: 'Zavřeno s Výdělkem',
            closedWithLoss: 'Zavřeno s Prodělkem',
            account: 'Profil',
            balance: 'Balance',
            leverage: 'Obchodní Rameno',
            credit: 'Úvěr',
            tradeNow: 'Obchod Nyní',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Celkové vklady',
            totalWithdrawals: "Celkové výběry",
            returnOnInvestment: "Návratnost investic"
        },
        fullScreenPersonal: {
            personalDetails: 'Osobní údaje',
            profilePhoto: 'Profilová fotografie',
            firstName: 'Jméno',
            country: 'Stát',
            lastName: 'Příjmení',
            address: 'Adresa',
            email: 'E-mail',
            phone: 'Telefonní číslo',
            saveChanges: 'Uložit změny',
            state: 'Město',
            postcode: 'PSČ',
            created: "Účet vytvořen"
        },
        fullScreenAvatar: {
            dropFile: 'Nahrajte soubor do výše uvedeného kroužku.',
            notAllowed: 'Není povoleno zveřejňovat',
            notAllowedLine1: 'Fotografie vysloveně sexuální nebo pornografické povahy',
            notAllowedLine2: 'Snímky podněcující etnickou nebo rasovou nenávist nebo agresi.',
            notAllowedLine3: 'Fotografie osob mladších 18 let',
            notAllowedLine4: 'Fotografie třetích stran chráněné autorskými právy',
            notAllowedLine5: 'Obrázky větší než 5 MB a v jiném formátu než JPG, GIF nebo PNG.',
            requirements: 'Na fotografii musí být jasně vidět váš obličej. Všechny vámi nahrané fotografie a videa musí splňovat tyto požadavky, jinak mohou být odstraněny.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'POŽÁDAT O NOVÝ VÝBĚR',
            amount: 'Částka',
            currentBalance: 'Zůstatek na vašem běžném účtu je',
            withdrawAll: 'Stáhněte všechny',
            requestWithdrawal: 'Žádost o stažení',
            last: 'POSLEDNÍ',
            withdrawalRequests: 'ŽÁDOSTI O STAŽENÍ',
            time: 'Čas',
            currency: 'Měna',
            info: 'Informace',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Karta',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Podrobnosti o stažení',
            address: 'Adresa peněženky',
            bankDetails: 'Bankovní údaje',
            bankAccount: 'Číslo účtu',
            bankHolder: 'Jméno držitele účtu',
            bankIban: 'IBAN',
            bankSwift: 'Bankovní SWIFT kód',
            recipientAddress: 'Adresa příjemce',
            routingNumber: 'Směrové číslo banky',
            bankBeneficiary: 'Název banky příjemce',
            bankAddress: 'Adresa banky',
            bankCode: 'Bankovní kód (BSB)',
            desc: 'Popis',
            cardNumber: 'Číslo karty',
            cardHolder: 'Jméno držitele karty',
        },
        fullScreenVerification: {
            dragAndDrop: 'přetáhněte dokument do této oblasti',
            documentsList: 'SEZNAM NAHRANÝCH DOKUMENTŮ',
            document: 'Dokument',
            timeUploaded: 'Čas nahrání',
            timeProcessed: 'Zpracovaný čas',
            status: 'Status',
            types: ['Doklad totožnosti', 'Doklad o bydlišti', 'Přední strana kreditní karty', 'Kreditní karta zpět', 'Doklad o totožnosti zpět', 'Selfie', 'Other'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Nahrát',
            isConfirmed: 'je potvrzeno',
            uploadAnotherDocument: 'Nahrát další dokument',
            isUnderReview: 'je předmětem přezkumu',
            questionnaire: 'Dotazník',
            sendAnswers: 'Odeslat odpovědi',
            progressBarOf: 'of',
            progressBarText: 'of your documents have been uploaded and confirmed'
        },
        fullScreenAccounts: {
            manageAccounts: 'Převádění prostředků mezi účty',
            transferFrom: 'Z účtu',
            transferTo: 'Na účet',
            amount: 'Částka',
            makeTransfer: 'Proveďte přenos',
            yourAccounts: 'Vaše účty',
            account: 'Účet',
            currency: 'Měna',
            balance: 'Balance',
            credit: 'Kredit',
            makeActive: 'Udělat aktivní',
            active: 'Aktivní'
        },
        fullScreenSupport: {
            sendMessage: 'Odeslat zprávu'
        },
        fullScreenSavings: {
            pct_0: 'Flexibilní',
            pct_30: 'Uzamčeno na 1 měsíc',
            pct_90: 'Uzamčeno 1 čtvrtletí',
            pct_180: 'Uzamčeno na 6 měsíců',
            pct_365: 'Uzamčeno na 1 rok',
            savings: 'Úspory',
            detailedInformation: 'Podrobné informace',
            pleaseSelect: 'Vyberte měnu a období',
            openSavings: 'Otevřené spoření',
            currentTime: 'Aktuální čas',
            releaseTime: 'Čas vydání',
            currencyChosen: 'Vybraná měna',
            periodChosen: 'Vybrané období',
            yourRate: 'Vaše sazba',
            yourEarnings: 'Vaše příjmy',
            accountFrom: 'Účet, ze kterého se mají převádět prostředky',
            enterAmount: 'Zadejte částku',
            in: 'V',
            openAndInvest: 'Otevřete si spořicí účet a investujte',
            investment: 'Investice',
            period: 'Období',
            daysTotal: 'Dny celkem',
            finalEarnings: 'Konečné příjmy',
            created: 'Vytvořeno',
            daysElapsed: 'Uplynulé dny',
            days: 'dny',
            earnings: 'Výdělky',
            fundsReleasedIn: 'Uvolněné prostředky v',
            claim: 'Reklamace',
            name: "Název",
        },
        fullScreenSettings: {
            updatePassword: 'AKTUALIZOVAT HESLO',
            currentPassword: 'Aktuální heslo',
            newPassword: 'Nové heslo',
            changePassword: 'Změna hesla',
            activationForGoogle: 'Aktivační kód pro Google Authenticator',
            activateAndEnter: 'Aktivujte Authenticator a zadejte vygenerovaný kód do pole níže.',
            qrCode: 'QR kód',
            activateProtection: 'Aktivace ochrany 2FA',
            protectionActive: 'Ochrana 2FA je aktivní',
            twoFactorCode: '2FA Kód',
            disableProtection: 'Vypnutí ochrany 2FA',
            language: 'Jazyk',
            dashboardCurrency: 'měna na nástěnce',
            confirmNewPassword: 'Potvrzení nového hesla',
        },
        fullScreenReferrals: {
            yourLink: 'VÁŠ DOPORUČUJÍCÍ ODKAZ',
            referredFriends: 'DOPORUČENÉ PŘÁTELE',
            id: 'ID',
            name: 'Jméno',
            registered: 'Registrováný',
            level: 'Úroveň',
            payout: 'Výplata',
            lastPayouts: 'POSLEDNÍ VÝPLATY ZA DOPORUČENÍ',
            time: 'Čas',
            amount: 'Částka',
            currency: 'Měna',
            info: 'Informace',
            referralDesc: "Partnerský program je navržen tak, aby vás odměnil za rozšiřování naší komunity cenných investorů.",
            shareLink: "Sdílejte odkaz:",
            subtitle: "Dejte mu své jméno, e-mail nebo obchodní ID.",
            descSuccesfull:"Jakmile se váš doporučený úspěšně zaregistruje, váš finanční poradce jej bude kontaktovat, aby mu pomohl s prvními kroky na investiční cestě co nejefektivněji a nejodborněji.",
            descTracked:"Všechny jeho příspěvky jsou sledovány pod vaším doporučením."
        },
        months: {
            Jan: 'Leden',
            Feb: 'Únor',
            Mar: 'Březen',
            Apr: 'Duben',
            May: 'Květen',
            Jun: 'Červen',
            Jul: 'Červenec',
            Aug: 'Srpen',
            Sep: 'Září',
            Oct: 'Říjen',
            Nov: 'Listopad',
            Dec: 'Prosinec'
        },
        orderTypes: ['Koupit', 'Prodej', 'Nákupní limit', 'Prodejní limit', 'Koupit Stop', 'Prodejní zastávka'],
        statuses: ['Čekající', 'Schváleno', 'Odmítnuto', 'Zpracování'],
        errors: {
            NOT_FOUND: 'Chyba požadavku',
            FORBIDDEN: 'Přístup odepřen. Přihlaste se znovu',
            SERVER_ERROR: 'Akce se nezdařila. Zkuste to prosím později',
            INVALID_ACCOUNT: 'Zasedání skončilo. Znovu se přihlaste',
            INVALID_EMAIL: 'Neplatná e-mailová adresa',
            INVALID_PHONE: 'Neplatné telefonní číslo',
            INVALID_COUNTRY: 'Neplatný stát',
            INVALID_LOGIN: 'Neplatné přihlašovací údaje',
            USER_BLOCKED: 'Účet je zablokován. Kontaktujte prosím podpor',
            USER_REGISTERED: 'Uživatel se zadaným e-mailem nebo telefonním číslem je již registrován',
            INVALID_USER: 'Uživatel nebyl nalezen',
            REJECTED: 'Provoz není povolen z důvodu omezení platformy',
            INVALID_OLD_PASSWORD: 'Staré heslo je neplatné',
            INVALID_SYMBOL: 'Neplatný obchodní symbol',
            INVALID_SYMBOL_PRECISION: 'Nesprávná přesnost symbolu',
            INVALID_FRAME: 'Neplatný rámec grafu',
            INVALID_CURRENCY: 'Neplatná měna',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Neplatný typ obchodního účtu',
            INSUFFICIENT_FUNDS: 'Nedostatek finančních prostředků',
            INVALID_WITHDRAWAL: 'Stažení není nalezeno',
            INVALID_STATUS: 'Neplatný stav',
            INVALID_FILE: 'Nesprávná přípona souboru nebo příliš velký soubor',
            INVALID_DOCUMENT: 'Dokument nebyl nalezen',
            INVALID_NAME: 'Nesprávné jméno',
            INVALID_LOT_SIZE: 'Nesprávná velikost pozemku',
            INVALID_LOT_STEP: 'Neplatný krok šarže',
            INVALID_MIN_VOLUME: 'Neplatný minimální objem',
            INVALID_MAX_VOLUME: 'Neplatný maximální objem',
            INVALID_GROUP: 'Neplatná skupina',
            INVALID_SPREAD: 'Neplatné rozpětí',
            REQUEST_TIMEOUT: 'Požadavek vypršel. Zkuste to prosím později',
            EXISTING_OPEN_ORDERS: 'Máte otevřenou objednávku, nejprve zavřete objednávku pro tohoto uživatele a zkuste to znovu.',
            INVALID_TYPE: 'Nesprávný typ objednávky',
            INVALID_VOLUME: 'Neplatný objem objednávky',
            INVALID_SL: 'Neplatná hodnota stop loss',
            INVALID_TP: 'Neplatná hodnota take profit',
            INVALID_PRICE: 'Neplatná cena objednávky',
            INVALID_EXPIRATION: 'Neplatné vypršení platnosti objednávky',
            NOT_ENOUGH_MARGIN: 'Nedostatečná marže',
            INVALID_ORDER: 'Objednávka nebyla nalezena',
            MARKET_CLOSED: 'Obchodování není k dispozici. Zkuste to prosím znovu',
            INVALID_BALANCE_TYPE: 'Nesprávný typ operace vyvážení',
            INVALID_HASH: 'Neplatný hash',
            HASH_EXPIRED: 'Platnost odkazu pro obnovení hesla vypršela. Vyžádejte si prosím nové',
            INVALID_CODE: 'Neplatný kód 2FA',
            CHAT_DISABLED: 'Přístup k chatu podpory byl uzavřen',
            WITHDRAWAL_NOT_ALLOWED: 'Přístup k výběrům byl uzavřen',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is '
        },
        countries: {
            AF: 'Afghánistán',
            AX: 'Alandské ostrovy',
            AL: 'Albánie',
            DZ: 'Alžírsko',
            AS: 'Americká Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktida',
            AG: 'Antigua a Barbuda',
            AR: 'Argentina',
            AM: 'Arménie',
            AW: 'Aruba',
            AU: 'Austrálie',
            AT: 'Austria',
            AZ: 'Ázerbájdžán',
            BS: 'Bahamy',
            BH: 'Bahrajn',
            BD: 'Bangladéš',
            BB: 'Barbados',
            BY: 'Bělorusko',
            BE: 'Belgie',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermudy',
            BT: 'Bhútán',
            BO: 'Bolívie',
            BA: 'Bosna a Hercegovina',
            BW: 'Botswana',
            BV: 'Bouvetův ostrov',
            BR: 'Brazílie',
            IO: 'Britské území v Indickém oceánu',
            BN: 'Brunej Darussalam',
            BG: 'Bulharsko',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodža',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Kapverdy',
            KY: 'Kajmanské ostrovy',
            CF: 'Středoafrická republika',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'Čína',
            CX: 'Vánoční ostrov',
            CC: 'Kokosové (Keelingovy) ostrovy',
            CO: 'Kolumbie',
            KM: 'Komory',
            CG: 'Kongo',
            CD: 'Kongo, Demokratická republika',
            CK: 'Cookovy ostrovy',
            CR: 'Kostarika',
            CI: 'Pobřeží slonoviny ',
            HR: 'Chorvatsko',
            CU: 'Kuba',
            CY: 'Kypr',
            CZ: 'Česká republika',
            DK: 'Dánsko',
            DJ: 'Džibutsko',
            DM: 'Dominika',
            DO: 'Dominikánská republika',
            EC: 'Ekvádor',
            EG: 'Egypt',
            SV: 'Salvador',
            GQ: 'Rovníková Guinea',
            ER: 'Eritrea',
            EE: 'Estonsko',
            ET: 'Etiopie',
            FK: 'Falklandy (Malvíny)',
            FO: 'Faerské ostrovy',
            FJ: 'Fidži',
            FI: 'Finsko',
            FR: 'Francie',
            GF: 'Francouzská Guyana',
            PF: 'Francouzská Polynésie',
            TF: 'Francouzská jižní území',
            GA: 'Gabon',
            GM: 'Gambie',
            GE: 'Georgia',
            DE: 'Německo',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Řecko',
            GL: 'Grónsko',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heardův ostrov a Mcdonaldovy ostrovy',
            VA: 'Svatý stolec (Vatikánský městský stát)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Maďarsko',
            IS: 'Island',
            IN: 'Indie',
            ID: 'Indonésie',
            IR: 'Írán',
            IQ: 'Irák',
            IE: 'Irsko',
            IM: 'Ostrov Man',
            IL: 'Izrael',
            IT: 'Itálie',
            JM: 'Jamajka',
            JP: 'Japonsko',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazachstán',
            KE: 'Keňa',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuvajt',
            KG: 'Kyrgyzstán',
            LA: 'Laoská lidově demokratická republika',
            LV: 'Lotyšsko',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Libérie',
            LY: 'Libyjská arabská džamáhíríja',
            LI: 'Lichtenštejnsko',
            LT: 'Litva',
            LU: 'Lucembursko',
            MO: 'Macao',
            MK: 'Makedonie',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malajsie',
            MV: 'Maledivy',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshallovy ostrovy',
            MQ: 'Martinik',
            MR: 'Mauritánie',
            MU: 'Mauricius',
            YT: 'Mayotte',
            MX: 'Mexiko',
            FM: 'Mikronésie',
            MD: 'Moldavsko',
            MC: 'Monako',
            MN: 'Mongolsko',
            ME: 'Černá Hora',
            MS: 'Montserrat',
            MA: 'Maroko',
            MZ: 'Mosambik',
            MM: 'Myanmar',
            NA: 'Namibie',
            NR: 'Nauru',
            NP: 'Nepál',
            NL: 'Nizozemsko',
            AN: 'Nizozemské Antily',
            NC: 'Nová Kaledonie',
            NZ: 'Nový Zéland',
            NI: 'Nikaragua',
            NE: 'Niger',
            NG: 'Nigérie',
            NU: 'Niue',
            NF: 'Ostrov Norfolk',
            MP: 'Severní Mariany',
            NO: 'Norsko',
            OM: 'Omán',
            PK: 'Pákistán',
            PW: 'Palau',
            PS: 'Palestinské území, okupované území',
            PA: 'Panama',
            PG: 'Papua-Nová Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipíny',
            PN: 'Pitcairn',
            PL: 'Polsko',
            PT: 'Portugalsko',
            PR: 'Portoriko',
            QA: 'Katar',
            RE: 'Sraz',
            RO: 'Rumunsko',
            RU: 'Rusko',
            RW: 'Rwanda',
            BL: 'Svatý Barthelemy',
            SH: 'Svatá Helena',
            KN: 'Svatý Kryštof a Nevis',
            LC: 'Svatá Lucie',
            MF: 'Svatý Martin',
            PM: 'Svatý Pierre a Miquelon',
            VC: 'Svatý Vincenc a Grenadiny',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Svatý Tomáš a Princův',
            SA: 'Saúdská Arábie',
            SN: 'Senegal',
            RS: 'Srbsko',
            SC: 'Seychely',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slovensko',
            SI: 'Slovinsko',
            SB: 'Šalamounovy ostrovy',
            SO: 'Somálsko',
            ZA: 'Jižní Afrika',
            GS: 'Jižní Georgie a Sandwichovy ostrovy',
            ES: 'Španělsko',
            LK: 'Srí Lanka',
            SD: 'Súdán',
            SR: 'Surinam',
            SJ: 'Špicberky a Jan Mayen',
            SZ: 'Svazijsko',
            SE: 'Švédsko',
            CH: 'Švýcarsko',
            SY: 'Syrská arabská republika',
            TW: 'Tchaj-wan',
            TJ: 'Tádžikistán',
            TZ: 'Tanzanie',
            TH: 'Thajsko',
            TL: 'Východní Timor',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad a Tobago',
            TN: 'Tunisko',
            TR: 'Krocan',
            TM: 'Turkmenistán',
            TC: 'Ostrovy Turks a Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukrajina',
            AE: 'Arabské emiráty',
            GB: 'Velká Británie',
            US: 'Spojené státy americké',
            UM: 'Odlehlé ostrovy Spojených států',
            UY: 'Uruguay',
            UZ: 'Uzbekistán',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Britské Panenské ostrovy',
            VI: 'Panenské ostrovy, USA',
            WF: 'Wallis a Futuna',
            EH: 'Západní Sahara',
            YE: 'Jemen',
            ZM: 'Zambie',
            ZW: 'Zimbabwe'
        }
    },
    de: {
        _name: 'Deutsch',
        newDesign: {
            withdrawalRequests: 'Noch keine Anträge auf Rücknahme',
            withdrawalRequestsText1: 'Sie haben bisher keine Abhebungsanträge gestellt.',
            withdrawalRequestsText2: 'Sobald Sie eine Abhebung veranlasst haben, werden Ihre Transaktionsdetails hier angezeigt',
            pdfAttachment: 'PDF-Anlage',
            agent: "Agent",
            closed: 'Geschlossen',
            pending: 'Anhängig',
            approved: 'Zugelassen',
            declined: 'Abgelehnt',
            document: 'Dokument',
            frontSide: 'Vorderseite',
            backSide: 'Hintere Seite',
            address: 'Adresse',
            frontSideDocument: 'Vorderseite des Dokuments',
            creditCard: 'Kreditkarte',
            selfie: 'Selfie',
            other: 'Andere',
            noDocument: 'Noch keine Dokumente hochgeladen',
            amountDeposited: 'Eingezahlter Betrag',
            makeTransfer: 'Übertragung vornehmen',
            otherDetails: 'Details zur Bestellung',
            manualWallets: 'Krypto-Wallets',
            depositText: 'Die Einzahlung wird innerhalb von 5-10 Minuten bearbeitet',
            pendingBankTransfer: 'Sie haben eine ausstehende Einzahlung per Banküberweisung.',
            completeDeposit: 'Bitte folgen Sie den nachstehenden Anweisungen, um Ihre Einzahlung abzuschließen',
            downloadInvoice: 'Download der Rechnung',
            payInvoice: 'Bezahlen Sie die Rechnung bei Ihrer Bank',
            useYourBankText1: 'Nutzen Sie das Online- oder Offline-Zahlungssystem Ihrer Bank, um die Rechnung zu bezahlen..',
            useYourBankText2: 'Sicherstellen, dass die Zahlungsangaben korrekt sind.',
            uploadReceipt: 'Hochladen der Empfangsbestätigung',
            afterMakingPayment: 'Laden Sie nach der Zahlung Ihre Quittung hoch, um die Einzahlung zu bestätigen.',
            clickToUpload: 'Zum Hochladen klicken',
            orDrag: 'oder ziehen und ablegen',
            fileFormat: 'PDF, JPG, JPEG, PNG weniger als 10MB',
            waitForDeposit: 'Warten auf die Einzahlungsbestätigung',
            onceReceipt: 'Sobald die Quittung hochgeladen ist, werden wir Ihre Einzahlung überprüfen und genehmigen.',
            paymentDetails: 'Einzelheiten zur Zahlung',
            nameOnCard: 'Name auf der Karte',
            cardNumber: 'Die Kartennummer',
            expirationDate: 'Verfallsdatum',
            securityCode: 'Sicherheitscode',
            continue: 'Weiter',
            viewAccounts: 'Konten ansehen',
            continueWithdrawal: 'Rückzug fortsetzen',
            accountBalance: 'Kontostand',
            accountBalanceText1: 'Warum kann ich keinen Handel platzieren?',
            accountsBalanceText2: 'Wie kann ich Geld einzahlen??',
            accountsBalanceText3: 'Ich möchte Geld über Zelle einzahlen',
            tradingActions: 'Handelsaktionen',
            tradingActionsText1: 'Wie kann ich Leerverkäufe tätigen??',
            tradingActionsText2: 'Wie kann ich einen Handel stornieren??',
            tradingActionsText3: 'Wie hoch ist der Mindesthandelsbetrag??',
            marketInfo: 'Marktinformationen',
            marketInfoText1: 'Wo kann ich Markttrends sehen??',
            marketInfoText2: 'Was sind die Top-Gewinner heute?',
            securityAndSettings: 'Sicherheit und Einstellungen',
            securityAndSettingsText1: 'Wie kann ich mein Passwort ändern??',
            securityAndSettingsText2: 'Was sind die Sicherheitsmerkmale??',
            securityAndSettingsText3: 'Wie kann ich meine Kontoinformationen aktualisieren??',
            messageCopied: 'Nachricht in die Zwischenablage kopiert',
            addSymbol: 'Symbol hinzufügen',
            iHaveRead: 'Ich habe die Allgemeinen Geschäftsbedingungen gelesen und stimme ihnen zu.',
            toggleToTurn: 'Umschalten zum Einschalten von AutoTrade',
            selectTheRisk: 'Wählen Sie die Risikostufe',
            confirm: 'Bestätigen',
            cancel: 'Abbrechen',
            chooseClosingTime: 'Wählen Sie eine Schließzeit',
            setOrderClosingTime: 'Bestellschlusszeitpunkt festlegen',
            closingTime: 'Feierabend',
            date: 'Datum',
            time: 'Zeit',
            setCloseTime: 'Schließzeit einstellen',
            '15m': '15 Minuten',
            '30m': '30 Minuten',
            '1h': '1 Stunde',
            custom: 'Individuell',
            verifyOtp: 'Überprüfen Sie Ihr OTP',
            enterDigits: 'Geben Sie den 6-stelligen Code ein, der an Ihre [E-Mail/Telefon] gesendet wurde.',
            submit: 'Einreichen',
            order: 'Auftrag',
            closedSuccessfully: 'wurde erfolgreich geschlossen',
            openSuccessfully: 'erfolgreich öffnen',
            deposit: 'Einzahlung',
            withdrawal: 'Rückzug',
            completeVerification: 'Vollständige Verifizierung',
            verification: 'Verifizierung',
            avatar: 'Avatar',
            saveChanges: 'Änderungen speichern',
            darkMode: 'Dunkler Modus',
            dashboardCurrency: 'Dashboard Währung',
            language: 'Sprache',
            twoFactor: '2-Faktoren-Schutz',
            googleTwoFactorIsActive: 'Google 2-Faktor-Authentifikator ist aktiv',
            activateCodeForGoogle: 'Aktivierungscode für Google Authenticator',
            ifYouWishDisableEnterCode: 'Wenn Sie den Schutz deaktivieren möchten, geben Sie den Code der Authenticator-App ein',
            scanOrManuallyEnter: 'Scannen Sie den QR-Code oder geben Sie ihn manuell in Google 2FA ein, um ein Token hinzuzufügen.',
            copyCode: 'Code kopieren',
            qrCode: 'QR-Code',
            activateAndEnterAuthenticator: 'Aktivieren Sie Authenticator und geben Sie einen generierten Code in das Feld unten ein',
            enterTheDigitsCodeGenerated: 'Geben Sie den generierten 6-stelligen Code ein',
            disableTwoFactor: '2-Faktoren-Schutz deaktivieren',
            activateTwoFactor: 'Aktivieren Sie den 2FA-Schutz',
            fileAdded: 'Datei erfolgreich hinzugefügt',
            theVerificationStatus: 'Der Verifizierungsstatus kann in der Liste der hochgeladenen Dokumente eingesehen werden',
            confirmWithdrawal: 'Auszahlung bestätigen',
            minimumWithdrawal: 'Der Mindestauszahlungsbetrag beträgt',
            welcomeToTradingPlatform: 'Willkommen auf der Handelsplattform',
            signIn: 'Eintragen',
            activeAccount: 'Aktives Konto',
            save: 'Speichern',
            selectCurrencyAndPeriod: 'Wählen Sie eine Währung und eine Abonnementdauer',
            savingsNotFound: 'Sparkonten nicht gefunden',
            pleaseCheckInvestment: 'Bitte überprüfen Sie Ihre Investitionsdaten, um fortzufahren',
            allPeriodInvestment: 'Investitionen für den gesamten Zeitraum',
            pct_0: 'Flexibel',
            pct_30: '1 Monat',
            pct_60: '1 Quartal',
            pct_90: '6 Monate',
            pct_180: '6 Monate',
            pct_365: '1 Jahr',
            savingsCreated: 'Sparkonto erfolgreich erstellt',
            transferDetails: 'Details übertragen',
            transferAmount: 'Transferbetrag',
            investmentDetails: 'Details zur Investition',
            investment: 'Investition',
            Period: 'Zeitraum',
            created: 'Erstellt',
            releaseTime: 'Freigabezeit',
            viewSavingsAccounts: 'Sparkonten anzeigen',
            continueInvestment: 'Investition fortsetzen',
            chooseSubscription: 'Wählen Sie Ihr Abonnement',
            selectCurrencyAndSubscription: 'Wählen Sie eine Währung und einen Abonnementzeitraum, um fortzufahren.',
            rate: 'Rate',
            accountTransferFunds: 'Konto zur Überweisung von Geldern von',
            amount: 'Betrag',
            'in': 'unter',
            openSavingsAndInvest: 'Sparkonto eröffnen und investieren',
            strongSell: 'Starker Verkauf',
            sell: 'Verkaufen',
            neutral: 'Neutral',
            buy: 'Kaufen',
            stringBuy: 'Starker Kauf',
            chooseCryptoWallet: 'Wählen Sie eine Cryptocurrency-Wallet',
            chooseMethod: 'Wählen Sie eine Methode',
            maximumDeposit: 'Die maximale Einzahlung beträgt',
            minimumDeposit: "Die Mindesteinzahlung beträgt",
            pleaseCompleteAddress: 'Bitte ergänzen Sie Ihre Adresse',
            pleaseCompleteCity: 'Bitte geben Sie Ihre Stadt an',
            pleaseCompleteCountry: 'Bitte füllen Sie Ihr Land aus',
            pleaseCompleteZip: 'Bitte geben Sie Ihre Postleitzahl an',
            amountDeposit: 'Betrag der Einzahlung',
            transactionStatus: 'Status des Vorgangs',
            paymentMethod: 'Zahlungsmethode',
            depositTo: 'Einzahlung auf',
            depositAddress: 'Adresse der Hinterlegung',
            scanTheQr: 'Scannen Sie den QR-Code oder kopieren Sie die Einzahlungsadresse',
            wallet: 'Geldbörse',
            copyDepositAddress: 'Kopie der Einzahlungsadresse',
            copy: 'Kopieren',
            addressCopied: 'Kopie der Einzahlungsadresse',
            '5min:': '5-10 min',
            '2-5days': '2-5 tage / 5-10 min',
            otherMethod: 'Andere Methode',
            profileSettings: 'Profil-Einstellungen',
            savingsAccounts: 'Sparkonten',
            subscribe: 'Abonnieren',
            toCompleteVerification: 'Um den Verifizierungsprozess für Ihr Konto abzuschließen, legen Sie bitte gut lesbare Kopien der folgenden Dokumente vor',
            amountToWithdrawal: 'Zu entziehender Betrag',
            cardDetails: 'Einzelheiten zur Karte',
            cardHolderName: 'Name des Karteninhabers',
            withdrawalSuccessful: 'Rückzug Erfolgreich',
            selectWithdrawalMethod: 'Auszahlungsmethode wählen',
            creditDebit: 'Kredit-/Debitkarte',
            crypto: 'Krypto',
            cryptoWalletAddress: 'Adresse der Krypro-Geldbörse',
            cryptoSubtitle: 'Krypto-Auszahlungen auf Ihr Wallet 🚀',
            bankWithdrawal: 'Bankauszahlung',
            bankSubtitle: 'Bankkonto 🏦',
            zelleSubtitle: 'Schnelle Banküberweisungen ⚡🏦',
            tradingResults: 'Handelsergebnisse',
            day: 'Tage',
            month: 'Monat',
            noTradingData: 'Keine Handelsdaten verfügbar',
            startTradingToSee: 'Starten Sie den Handel und sehen Sie Ihre Ergebnisse hier.',
            yourPerformanceMetric: 'Ihre Leistungskennzahlen werden angezeigt, sobald die Daten verfügbar sind..',
            favorite: 'Favorit',
            all: 'Alle',
            availableFullTime: '24/7 verfügbar',
            yourProfileAndSettings: 'Ihr Profil und Ihre Einstellungen',
            switchToNewDesignMessage: 'Wechseln Sie jetzt zu unserem neuen Plattformdesign!',
            switchToOldDesignMessage: 'Sie können das klassische Design für eine begrenzte Zeit verwenden',
            switchToOldDesignButton: 'Wechsel auf neues Design',
            switchToNewDesignButton: 'Zurück zum klassischen Design',
            generateInvoice: 'Rechnung erstellen',
            downloadPdf: 'PDF herunterladen',
            confirmDeposit: 'Einzahlung bestätigen',
            howCanIAssist: 'Wie kann ich Ihnen heute helfen?',
            maxFileSize: 'Die maximale Dateigröße beträgt 5 MB, akzeptierte Formate sind JPEG, PNG oder GIF',
            uploadPhoto: 'Foto hochladen',
            featuresDisabled: 'Diese Funktion ist deaktiviert',
            welcome: 'Willkommen',
            signUp: 'Registrieren',
            registration: 'Registrierung',
            pleaseConfirmAgreement: 'Bitte bestätigen Sie die Kundenvereinbarung',
            pleaseEnterPassword: 'Bitte geben Sie ein Passwort ein',
            passwordNotSame: 'Passwörter stimmen nicht überein',
            noActiveOrders: 'Keine aktiven Bestellungen',
            itLikeNoHaveActiveOrders: 'Es sieht so aus, als hätten Sie derzeit keine aktiven Bestellungen',
            cryptoWallet: 'Krypto-Wallet',
            bankCard: 'Bank/Karte',
            selected: 'Ausgewählt',
            amountWithdrawal: 'Abgehobener Betrag',
            withdrawalTo: 'Abhebung auf',
            governmentIssuedPhoto1: 'Von der Regierung ausgestellter Lichtbildausweis (z. B. Reisepass, Führerschein, Personalausweis)',
            governmentIssuedPhoto2: 'Aufenthaltserlaubnis (falls zutreffend)',
            governmentIssuedPhoto3: 'Das Dokument muss gültig sein (nicht abgelaufen)',
            governmentIssuedPhoto4: 'Der vollständige Name, das Geburtsdatum und das Foto müssen sichtbar sein.',
            uploadRecent1: 'Laden Sie eine aktuelle Rechnung, einen Kontoauszug oder einen Mietvertrag hoch, der in den letzten 3 Monaten ausgestellt wurde',
            uploadRecent2: 'Stellen Sie sicher, dass Ihr vollständiger Name und Ihre aktuelle Wohnadresse klar sichtbar sind.',
            uploadCopy1: 'Laden Sie eine Kopie der Vorderseite Ihrer Kreditkarte hoch und stellen Sie sicher, dass die ersten sechs und letzten vier Ziffern sichtbar sind',
            uploadCopy2: 'Bitte decken Sie die mittleren Ziffern aus Sicherheitsgründen ab',
            takeAndUpload1: 'Machen Sie ein Selfie, auf dem Sie Ihren Ausweis neben Ihrem Gesicht halten, und laden Sie es hoch',
            takeAndUpload2: 'Stellen Sie sicher, dass sowohl Ihr Gesicht als auch der Ausweis klar sichtbar sind',
            otherDocuments: 'Andere Dokumente',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Laden Sie ein klares und lesbares Bild Ihres Sozialversicherungsnummer (SSN)-Dokuments hoch. Stellen Sie sicher, dass alle Details sichtbar und nicht verdeckt sind.',
            frontSideSsnDocument: 'Vorderseite des SSN-Dokuments',
            answerSaved: 'Antworten wurden gespeichert',
            cryptoWalletDetails: 'Details zur Krypto-Wallet',
            bitcoinWalletAddress: 'Bitcoin-Wallet-Adresse',
            recovery: 'Wiederherstellung',
            passwordRecovery: 'Passwort-Wiederherstellung',
            searchCountry: 'Land suchen',
            questionnaire: 'Fragebogen',
            questionnaireText: 'Bitte füllen Sie den Fragebogen aus, um Ihre Identität zu verifizieren',
            sendAnswers: 'Antworten senden',
        },
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Gewinnmitnahme & Stop-Loss',
            takeProfit: ' Gewinnmitnahme',
            stopLoss: 'Stop-Loss',
            updateOrder: 'Handel aktualisieren',
        },
        balanceModal: {
            balance: 'Kontostand',
            credit: ' Kredit',
            equity: 'Eigenkapital ',
            practice: 'Praxis',
            real: 'Real',
            account: 'Konto',
            invested: 'Investiert',
            profit: 'Profit',
            loss: 'Verlust',
            margin: 'Margin',
            marginLevel: 'Margin Level',
            marginFree: 'Freie Margin'
        },
        header: {
            cfd: 'CFD',
            platform: 'Plattform',
            deposit: 'Einzahlung '
        },
        sideMenu: {
            marketWatch: 'Marktübersicht',
            activeOrders: 'Aktive Handel',
            tradingHistory: 'Handel Geschichte',
            economicCalendar: 'Kalender',
            marketNews: 'Markt Nachrichten'
        },
        closePositionModal: {
            closeOrder: 'Handel schließen',
            areYouSure: 'Sind Sie sicher, den Order zu schließen?',
            buy: 'Kaufen',
            sell: 'Verkaufen',
            yes: 'Ja',
            no: 'Nein',
        },
        pciDssModal: {
            desc: `Die Einhaltung des Payment Card Industry Data Security Standard (PCI DSS) ist für alle Unternehmen erforderlich, die Daten von Karteninhabern von Visa/Master Card/Stripe speichern, verarbeiten oder übertragen, einschließlich Finanzinstitute, Händler und Dienstleister. 
            Es handelt sich um den globalen Sicherheitsstandard für alle Unternehmen, die Karteninhaberdaten und/oder sensible Authentifizierungsdaten speichern, verarbeiten oder übertragen. PCI DSS legt einen grundlegenden Schutz für Verbraucher fest und trägt dazu bei, Betrug und Datenverstöße im gesamten Zahlungssystem zu reduzieren.`,
        },
        chart: {
            addNewChart: 'Neue Chart hinzufügen',
        },
        symbolsModal: {
            watchlist: 'Überwachungsliste',
            asset: 'Vermögenswert',
            price: 'Preise',
            changePct: '24h Wechsel'
        },
        pendingModal: {
            pending: 'Ausstehend',
            assetPrice: 'Vermögenswertpreis',
            current: 'aktuell',
            revert: 'Zurück zum Marktpreis',
            automatically: 'Handel wird automatisch eröffnet, wenn der Preis dieses Niveau erreicht'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volumen',
            lots: 'lots',
            units: 'einheiten',
            currency: 'währung',
            contractSize: 'kontraktgröße',
            position: 'Position',
            margin: 'Margin',
            freeMargin: 'Freie Margin',
            takeProfitStopLoss: 'Gewinnmitnahme & Stop-Loss',
            pending: 'Ausstehend',
            market: 'Markt',
            leverage: 'Hebelwirkung',
            spread: 'Spread',
            notSet: 'Nicht eingestellt',
            at: 'um',
            buy: 'Kaufen',
            sell: 'Verkaufen',
        },
        footer: {
            supportBanner: 'JEDEN TAG, RUND UM DIE UHR',
            currentTime: 'AKTUELLE UHRZEIT',
            liveChat: 'Live Chat'
        },
        statusBar: {
            balance: 'Kontostand',
            credit: 'Kredit',
            equity: 'Eigenkapital',
            margin: 'Margin',
            marginLevel: 'Margin level',
            freeMargin: 'Freie Margin',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Registrierungsdatum',
            userId: 'Benutzer ID',
            dashboard: 'Dashboard',
            personalData: 'Persönliche Daten',
            deposit: 'Einzahlung',
            withdrawFunds: 'Auszahlung',
            savings: 'Ersparnisse',
            settings: 'Einstelungen',
            logout: 'Ausloggen'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Einzahlung tätigen',
            practiceAccount: 'Demokonto',
            realAccount: 'Reales Konto',
            noWithdrawals: 'Keine Auszahlungen',
            easyWithdrawals: 'Einfache Auszahlungen ',
            allAssets: 'Alle Vermögenswerte verfügbar',
            fullFledged: 'Vollgeführte Plattform',
            fillUpTo: 'Auffüllen bis',
            freeReplenishment: 'Kostenloser Nachschub',
            topUp: 'Laden Sie Ihr Konto auf',
            minimumAmount: 'Mindestbetrag',
            canSwitch: 'Sie können jederzeit zwischen Ihren Konten wechseln'
        },
        gridsModal: {
            chartGrids: 'Chartaufstellung',
            chart1: '1 chart',
            chart2: '2 charts',
            chart3: '3 charts',
            chart4: '4 charts',
        },
        noConnectionModal: {
            connectionLost: 'Die Verbindung mit dem Server wurde unterbrochen',
            retryNow: 'Jetzt erneut versuchen',
        },
        loginModal: {
            loginToTradeRoom: 'Melden Sie sich bei Handelsroom an',
            email: 'E-Mail-Addresse',
            enterEmail: 'Geben sie ihre E-Mail Adresse ein',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA-Code (falls aktiviert)',
            twoFactorAuth: 'Zwei-Faktor-Authentifizierung',
            password: 'Passwort',
            yourPassword: 'Ihr Passwort',
            signIn: 'Anmelden',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Neues Passwort festlegen',
            newPassword: 'Neues Passwort',
            confirmNewPassword: 'Bestätige neues Passwort',
            setButton: 'Festsetzen'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Ein neues Konto erstellen',
            email: 'E-Mail-Addresse',
            firstName: 'Vorname',
            lastName: 'Nachname',
            country: 'Land',
            phone: 'Telefon',
            password: 'Passwort',
            createAccount: 'Benutzerkonto erstellen',
            currency: 'Währung',
            privacyAndPolicy: "Datenschutz & Richtlinien",
            customerAgreement: 'Kundenvereinbarung',

        },
        forgotPasswordModal: {
            forgotPassword: 'Passwort vergessen?',
            submitEmail: 'Bitte senden Sie eine für die Registrierung verwendete E-Mail-Adresse, überprüfen Sie Ihren Posteingang und befolgen Sie die bereitgestellten Anweisungen',
            submit: 'Bestätigen'
        },
        notifications: {
            error: 'Fehler',
            success: 'Erfolg',
            deposit: 'Einzahlung',
            withdrawal: 'Auszahlung',
            depositApproved: 'wurde genehmigt',
            depositDeclined: 'Einzahlung wurde abgelehnt',
            withdrawalApproved: 'Auszahlung wurde genehmigt',
            withdrawalDeclined: 'Auszahlung wurde abgelehnt'
        },
        markets: {
            favorites: 'Beobachtungsliste', 
            all: 'Alle Symbole',
            forex: 'Devisen',
            stocks: 'Aktien',
            commodities: 'Rohstoffe',
            indices: 'Indizes',
            crypto: 'Krypto',
            metals: 'Metalle',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'BUY',
            profitCalculator: 'Gewinnrechner',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Marktübersicht',
            search: 'Suche...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volumen',
            margin: 'Margin',
            active: 'Aktiv',
            pending: 'Ausstehend',
            activeOrders: 'Aktive Orders',
            portfolio: 'Portfolio',
            allPositions: 'Alle Positionen',
            noPositionsLine1: 'Sie haben noch keine',
            noPositionsLine2: 'geöffneten Positionen',
            show: 'Anzeigen',
            more: 'mehr',
            less: 'weniger',
            purchaseTime: 'Transaktionszeit',
            closePosition: 'Position schließen',
            priceOpen: 'Transaktionspreis',
            priceSl: 'Stop Loss Preis',
            priceTp: 'Take Profit Pries',
            type: 'Positionsrichtung',
            pnl: 'Profit/Verlust',
            cancelOrder: 'Order stornieren',
            orderType: 'Ordertyp',
            cancelled: 'Storniert',
            tradingHistory: 'Handelsgeschichte',
            noHistoryLine1: 'Sie haben noch keine',
            noHistoryLine2: 'geschlossenen Positionen',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Kalender'
        },
        widgetMarketNews: {
            marketNews: 'Marktnachrichten'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Aktive Orders',
            ordersHistory: 'Handelsgeschichte',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Type',
            volume: 'Volumen',
            openPrice: 'Eröffnungspreis',
            openTime: 'Eröffnungszeit',
            closePrice: 'Schlusskurs',
            closeTime: 'Schließzeit',
            sl: 'SL',
            tp: 'TP',
            price: 'Preis',
            pnl: 'PnL',
            actions: 'Aktionen',
            edit: 'Bearbeiten',
            close: 'Schließen',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Session Change',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'Handel pro Tag:',
            desc: `Der Handel mit CFDs und anderen gehebelten Produkten kann zu Verlusten führen. Vor dem Handel sollten Kunden die relevanten Risikohinweise auf unserer Risikohinweis-Seite lesen. Automatisierter Handel garantiert keine Ergebnisse. Das Unternehmen übernimmt keine Verantwortung für den Verlust von Geldern im automatisierten Handel. Bitte stellen Sie sicher, dass Sie die Risiken vollständig verstehen und Maßnahmen zur Risikokontrolle ergreifen.`,
            accept: 'Accept',
            save: 'Speichern',
            cancel: 'Abbrechen',
        },
        cardBox: {
            name: 'Name',
            cardNumber: 'Kartennummer',
            expiration: 'Ablaufsdatum (mm/jj)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Handel",
            startTrading: 'Handel starten',
            deposit: 'Einzahlung tätigen',
            dashboard: 'Dashboard',
            personalInfo: 'Persönliche Daten',
            contactInfo: 'Kontaktinformationen',
            withdrawal: 'Auszahlung',
            verification: 'Identitätsprüfung',
            accounts: 'Konten verwalten',
            liveChat: 'Live Chat',
            referrals: 'Empfehlungsprogramm',
            savings: 'Ersparnisse',
            settings: 'Plattformeinstellungen',
            logOut: 'Ausloggen',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'MACHEN SIE EINE EINZAHLUNG',
            instant: 'Sofort',
            minutes: 'Minuten',
            hours: 'Stunden',
            days: 'Tage',
            amount: 'Betrag',
            continue: 'Fortsetzen',
            qrCode: 'QR-CODE',
            depositAddress: 'EINZAHLUNGSADRESSE',
            copy: 'Kopieren',
            last: 'ZULETZT',
            deposits: 'EINZAHLUNGEN',
            time: 'Zeit',
            currency: 'Währung',
            status: 'Status',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Warten Sie mal. Verbindung zum Zahlungsdienstleister wird hergestellt...',
            cardPayment: 'Kartenzahlung',
            inOrder: 'Um den Betrag zu senden',
            pleaseProvide: 'Bitte geben Sie die Kartendaten an:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'Noch keine Bestellung',
            totalBalance: 'Kontostand',
            totalPnl: 'PnL Insgesamt',
            profitableOrders: 'Erfolgreiche Trades',
            roi: 'ROI',
            activityLog: 'Aktivitätsprotokoll',
            loginFromIp: 'Login from IP',
            tradingResults: 'Handelsergebnisse',
            week: 'Woche',
            month: 'Monat',
            year: 'Jahr',
            successRate: 'Erfolgsquote',
            closedWithProfit: 'Geschlossen mit Gewinn',
            closedWithLoss: 'Geschlossen mit Verlust',
            account: 'Konto',
            balance: 'Saldo',
            leverage: 'Hebelwirkung',
            credit: 'Kredit',
            tradeNow: 'Zum Handel',
            usingCurrentRate: 'zum aktuellen Wechselkurs',
            totalDeposits: 'Gesamteinzahlungen',
            totalWithdrawals: "Gesamtauszahlungen",
            returnOnInvestment: "Rendite"
        },
        fullScreenPersonal: {
            personalDetails: 'Persönliche Daten',
            profilePhoto: 'Profilfoto',
            firstName: 'Vorname',
            country: 'Land',
            lastName: 'Nachname',
            address: 'Adresse',
            email: 'E-Mail',
            phone: 'Telefonnummer',
            saveChanges: 'Änderungen speichern',
            state: 'Stadt',
            postcode: 'PLZ',
            created: "Konto erstellt am",
        },
        fullScreenAvatar: {
            dropFile: 'Legen Sie eine Datei zum Hochladen auf dem Kreis oben ab',
            notAllowed: 'Es darf nicht veröffentlicht werden',
            notAllowedLine1: 'Fotos explizit sexueller oder pornografischer Natur',
            notAllowedLine2: 'Bilder, die darauf abzielen, zu ethnischem oder rassistischem Hass oder Aggression aufzustacheln',
            notAllowedLine3: 'Fotos mit Personen unter 18 Jahren',
            notAllowedLine4: 'Fotos mit Third-party Urheberrechte Schutz',
            notAllowedLine5: 'Bilder größer als 5 MB und in einem anderen Format als JPG, GIF oder PNG',
            requirements: 'Auf dem Foto muss Ihr Gesicht gut zu erkennen sein. Alle von Ihnen hochgeladenen Fotos und Videos müssen diese Anforderungen erfüllen, andernfalls können sie entfernt werden.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'NEUE AUSZAHLUNG ANFORDERN',
            amount: 'Betrag',
            currentBalance: 'Ihr aktueller Kontostand ist',
            withdrawAll: 'Alles auszahlen',
            requestWithdrawal: 'Auszahlung anfordern',
            last: 'ZULETZT',
            withdrawalRequests: 'AUSZAHLUNGSANTRÄGE',
            time: 'Zeit',
            currency: 'Währung',
            info: 'Info',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Karte',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Bankverbindung',
            address: 'Wallet Address',
            bankDetails: 'Bankdetails',
            bankAccount: 'Kontonummer',
            bankHolder: 'Anmeldename/VR- Net Key/ Login',
            bankIban: 'IBAN',
            bankSwift: 'Pin/Passwort/TAN',
            recipientAddress: 'Empfängeradresse',
            routingNumber: 'Routing-Nummer',
            bankBeneficiary: 'Name der Bank des Begünstigten',
            bankAddress: 'Bankadresse',
            bankCode: 'Bankcode (BSB)',
            desc: 'Beschreibung',
            cardNumber: 'Kartennummer',
            cardHolder: 'Name des Karteninhabers',
        },
        fullScreenVerification: {
            dragAndDrop: 'Ziehen Sie das Dokument per Drag & Drop in diese Zelle',
            documentsList: 'LISTE DER HOCHGELADENEN DOKUMENTE',
            document: 'Dokument',
            timeUploaded: 'Zeit hochgeladen',
            timeProcessed: 'Zeit verarbeitet',
            status: 'Status',
            types: ['Ausweis', 'Nachweis des Wohnsitzes', 'Kreditkartenvorderseite', 'Kreditkartenrückseite', 'Ausweisrückseite', 'Selfie', 'Other'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Hochladen',
            isConfirmed: 'bestätigt',
            uploadAnotherDocument: 'Anderes Dokument hochladen',
            isUnderReview: 'wird überprüft',
            questionnaire: 'Fragebogen',
            sendAnswers: 'Antworten senden',
            progressBarOf: 'von',
            progressBarText: 'Ihrer Dokumente sind hochgeladen und geprüft'
        },
        fullScreenAccounts: {
            manageAccounts: 'Überweisen Sie Gelder zwischen Ihren Konten',
            transferFrom: 'Vom Konto',
            transferTo: 'Auf Konto ',
            amount: 'Betrag',
            makeTransfer: 'Überweisung vornehmen',
            yourAccounts: 'Ihre Konten',
            account: 'Konto',
            currency: 'Währung',
            balance: 'Kontostand',
            credit: 'Kredit',
            makeActive: 'Aktivieren',
            active: 'Aktiv'
        },
        fullScreenSupport: {
            sendMessage: 'Nachricht zu senden'
        },
        fullScreenSavings: {
            pct_0: 'Flexibel',
            pct_30: '1 Monat gesperrt',
            pct_90: '1 Quartal gesperrt',
            pct_180: '6 Monate gesperrt',
            pct_365: '1 Jahr gesperrt',
            savings: 'Ersparnisse',
            detailedInformation: 'Genaue Information',
            pleaseSelect: 'Bitte wählen Sie eine Währung und einen Zeitraum',
            openSavings: 'Ersparnisse öffnen',
            currentTime: 'Aktuelle Uhrzeit',
            releaseTime: 'Eröffnungsdatum',
            currencyChosen: 'Gewählte Währung',
            periodChosen: 'Gewählter Zeitraum',
            yourRate: 'Ihr Tarif',
            yourEarnings: 'Dein Verdienst',
            accountFrom: 'Konto, von dem Geld überwiesen werden soll',
            enterAmount: 'Enter Amount',
            in: 'In',
            openAndInvest: 'Sparkonto eröffnen und anlegen',
            investment: 'Investition',
            period: 'Zeitraum',
            daysTotal: 'Tage insgesamt',
            finalEarnings: 'Totales Einkommen',
            created: 'Erstellt',
            daysElapsed: 'Verstrichene Tage',
            days: 'Tage',
            earnings: 'Verdienste',
            fundsReleasedIn: 'Freigegebene Mittel in',
            claim: 'Versicherungsanspruch ',
            name: "Name",
        },
        fullScreenSettings: {
            updatePassword: 'Passwort AKTUALISIEREN',
            currentPassword: 'Aktuelles Passwort',
            newPassword: 'Neues Passwort',
            changePassword: 'Passwort ändern',
            activationForGoogle: 'Aktivierungscode für Google Authenticator',
            activateAndEnter: 'Aktivieren Sie Authenticator und geben Sie einen generierten Code in das Feld unten ein',
            qrCode: 'QR-Code',
            activateProtection: 'Aktivieren Sie den 2FA-Schutz',
            protectionActive: '2FA-Schutz ist aktiv',
            twoFactorCode: '2FA-Code',
            disableProtection: 'Deaktivieren Sie den 2FA-Schutz',
            language: 'Sprache',
            dashboardCurrency: 'Dashboard-Währung',
            confirmNewPassword: 'Bestätige neues Passwort',
        },
        fullScreenReferrals: {
            yourLink: 'IHR EMPFEHLUNGSLINK',
            referredFriends: 'EMPFOHLENE FREUNDE',
            id: 'ID',
            name: 'Name',
            registered: 'Registriert',
            level: 'Niveua',
            payout: 'Gewinnauszahlung',
            lastPayouts: 'LETZTE EMPFOHLENE Gewinnauszahlung',
            time: 'Zeit',
            amount: 'Betrag',
            currency: 'Währung',
            info: 'Info',
            referralDesc:"Das Empfehlungsprogramm wurde entwickelt, um Sie für die Erweiterung unserer Gemeinschaft wertvoller Investoren zu belohnen.",
            shareLink:"Teilen Sie den Link:",
            subtitle:"Geben Sie ihm Ihren Namen, Ihre E-Mail-Adresse oder Ihre Handels-ID.",
            descSuccesfull:"Sobald sich Ihre Empfehlung erfolgreich registriert hat, wird sich Ihr Finanzberater mit ihm in Verbindung setzen, um ihn auf seinem Investitionsweg bestmöglich und professionell zu unterstützen.",
            descTracked:"Alle seine Beiträge werden unter Ihrer Empfehlung verfolgt."
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'May',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Kaufen', 'Verkaufen', 'Kaufen Limit', 'Verkaufen Limit', 'Kaufen Stop', 'Verkaufen Stop'],
        statuses: ['Ausstehend', 'Genehmigt', 'Abgelehnt', 'Wird bearbeitet'],
        errors: {
            NOT_FOUND: 'Anforderungsfehler',
            FORBIDDEN: 'Zugriff abgelehnt. Bitte neu einloggen',
            SERVER_ERROR: 'Aktion: fehlgeschlagen. Bitte versuchen Sie es später noch einmal.',
            INVALID_ACCOUNT: 'Sitzung abgelaufen. Bitte neu einloggen',
            INVALID_EMAIL: 'Ungültige E-Mail-Adresse',
            INVALID_PHONE: 'Ungültige Telefonnummer',
            INVALID_COUNTRY: 'Ungültiges Land',
            INVALID_LOGIN: 'Ungültige Login-Details',
            USER_BLOCKED: 'Konto ist gesperrt. Bitte wenden Sie sich an den Support.',
            USER_REGISTERED: 'Benutzer mit eingegebener E-Mail oder Telefonnummer ist bereits registriert',
            INVALID_USER: 'Benutzer wurde nicht gefunden',
            REJECTED: 'Der Betrieb ist aufgrund von Plattformbeschränkungen nicht erlaubt',
            INVALID_OLD_PASSWORD: 'Altes Passwort ist ungültig',
            INVALID_SYMBOL: 'Ungültiges Handelssymbol',
            INVALID_SYMBOL_PRECISION: 'Ungültige Symbolpräzision',
            INVALID_FRAME: 'Ungültiger Diagrammrahmen',
            INVALID_CURRENCY: 'Ungültige Währung',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Ungültiger Typ des Handelskontos',
            INSUFFICIENT_FUNDS: 'Nicht genügend Geld auf dem Konto',
            INVALID_WITHDRAWAL: 'Auszahlung nicht gefunden',
            INVALID_STATUS: 'Ungültiger Status',
            INVALID_FILE: 'Ungültige Dateierweiterung oder Datei ist zu groß',
            INVALID_DOCUMENT: 'Dokument wurde nicht gefunden',
            INVALID_NAME: 'Ungültiger Name',
            INVALID_LOT_SIZE: 'Ungültige Lotgröße',
            INVALID_LOT_STEP: 'Ungültiger Lotschritt',
            INVALID_MIN_VOLUME: 'Ungültiges Mindestvolumen',
            INVALID_MAX_VOLUME: 'Ungültiges maximales Volumen',
            INVALID_GROUP: 'Ungültige Gruppe',
            INVALID_SPREAD: 'Ungültiger Spread',
            REQUEST_TIMEOUT: 'Zeitüberschreitung der Anforderung. Bitte versuchen Sie es später noch einmal',
            EXISTING_OPEN_ORDERS: 'Sie haben eine offene Bestellung, schließen Sie zuerst die Bestellung für diesen Benutzer und versuchen Sie es erneut.',
            INVALID_TYPE: 'Ungültiger Auftragstyp',
            INVALID_VOLUME: 'Ungültiges Auftragsvolumen',
            INVALID_SL: 'Ungültiger Stop-Verlust-Wert',
            INVALID_TP: 'Ungültiger Gewinnmitnahme-Wert',
            INVALID_PRICE: 'Ungültiger Auftragspreis',
            INVALID_EXPIRATION: 'Ungültiger Auftragsablauf',
            NOT_ENOUGH_MARGIN: 'Nicht genug Margin',
            INVALID_ORDER: 'Auftrag nicht gefunden',
            MARKET_CLOSED: 'Handel ist nicht verfügbar. Bitte versuche es erneut',
            INVALID_BALANCE_TYPE: 'Ungültiger Typ der Kontostandoperation',
            INVALID_HASH: 'Ungültiger Hash',
            HASH_EXPIRED: 'Ihr Link zum Zurücksetzen des Passworts ist abgelaufen. Bitte fordern Sie eine neue an',
            INVALID_CODE: 'Ungültiger 2FA-Code',
            CHAT_DISABLED: 'Zugriff auf den Support-Chat wurde geschlossen',
            WITHDRAWAL_NOT_ALLOWED: 'Zugriff auf die Auszahlungen wurde gesperrt',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later'
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland-Inseln',
            AL: 'Albanien',
            DZ: 'Algerien',
            AS: 'Amerikanisch-Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktis',
            AG: 'Antigua und Barbuda',
            AR: 'Argentinien',
            AM: 'Armenien',
            AW: 'Aruba',
            AU: 'Australien',
            AT: 'Österreich',
            AZ: 'Aserbaidschan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesch',
            BB: 'Barbados',
            BY: 'Weißrussland',
            BE: 'Belgien',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivien',
            BA: 'Bosnien und Herzegowina',
            BW: 'Botswana',
            BV: 'Bouvetinsel',
            BR: 'Brasilien',
            IO: 'Britisches Territorium im Indischen Ozean',
            BN: 'Brunei Darussalam',
            BG: 'Bulgarien',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodscha',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Kap Verde',
            KY: 'Kaimaninseln',
            CF: 'Zentralafrikanische Republik',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Weihnachtsinsel',
            CC: 'Cocos (Keeling) Inseln',
            CO: 'Kolumbien',
            KM: 'Komoren',
            CG: 'Kongo',
            CD: 'Kongo, Demokratische Republik',
            CK: 'Cookinseln',
            CR: 'Costa Rica',
            CI: 'Cote D\'Ivoire',
            HR: 'Kroatien',
            CU: 'Kuba',
            CY: 'Zypern',
            CZ: 'Tschechische Republik',
            DK: 'Dänemark',
            DJ: 'Dschibuti',
            DM: 'Dominica',
            DO: 'Dominikanische Republik',
            EC: 'Ecuador',
            EG: 'Ägypten',
            SV: 'El Salvador',
            GQ: 'Äquatorialguinea',
            ER: 'Eritrea',
            EE: 'Estland',
            ET: 'Äthiopien',
            FK: 'Falklandinseln (Malwinen)',
            FO: 'Färöer Inseln',
            FJ: 'Fidschi',
            FI: 'Finnland',
            FR: 'Frankreich',
            GF: 'Französisch-Guayana',
            PF: 'Französisch-Polynesien',
            TF: 'Französische Süd-Territorien',
            GA: 'Gabun',
            GM: 'Gambia',
            GE: 'Georgien',
            DE: 'Deutschland',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Griechenland',
            GL: 'Grönland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard-Insel & Mcdonald-Inseln',
            VA: 'Heiliger Stuhl (Staat Vatikanstadt)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Ungarn',
            IS: 'Island',
            IN: 'Indien',
            ID: 'Indonesien',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irland',
            IM: 'Isle Of Man',
            IL: 'Israel',
            IT: 'Italien',
            JM: 'Jamaika',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordanien',
            KZ: 'Kasachstan',
            KE: 'Kenia',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kirgisistan',
            LA: 'Demokratische Volksrepublik Laos',
            LV: 'Lettland',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libysch-Arabische Dschamahirija',
            LI: 'Liechtenstein',
            LT: 'Litauen',
            LU: 'Luxemburg',
            MO: 'Macao',
            MK: 'Mazedonien',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Malediven',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshallinseln',
            MQ: 'Martinique',
            MR: 'Mauretanien',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexiko',
            FM: 'Mikronesien',
            MD: 'Moldawien',
            MC: 'Monaco',
            MN: 'Mongolei',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Marokko',
            MZ: 'Mosambik',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Niederlande',
            AN: 'Niederländische Antillen',
            NC: 'Neukaledonien',
            NZ: 'Neuseeland',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolkinsel',
            MP: 'Nördliche Marianen',
            NO: 'Norwegen',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palästinensische Gebiete, Besetzt',
            PA: 'Panama',
            PG: 'Papua-Neuguinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippinen',
            PN: 'Pitcairn',
            PL: 'Polen',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Katar',
            RE: 'Wiedersehen',
            RO: 'Rumänien',
            RU: 'Russland',
            RW: 'Ruanda',
            BL: 'St. Barthelemy',
            SH: 'Sankt Helena',
            KN: 'St. Kitts und Nevis',
            LC: 'St. Lucia',
            MF: 'Sankt Martin',
            PM: 'St. Pierre und Miquelon',
            VC: 'St. Vincent und Grenadinen',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome und Principe',
            SA: 'Saudi-Arabien',
            SN: 'Senegal',
            RS: 'Serbien',
            SC: 'Seychellen',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slowakei',
            SI: 'Slowenien',
            SB: 'Salomoninseln',
            SO: 'Somalia',
            ZA: 'Südafrika',
            GS: 'Südgeorgien und Sandwichinseln',
            ES: 'Spanien',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard und Jan Mayen',
            SZ: 'Swasiland',
            SE: 'Schweden',
            CH: 'Schweiz',
            SY: 'Arabische Republik Syrien',
            TW: 'Taiwan',
            TJ: 'Tadschikistan',
            TZ: 'Tansania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad und Tobago',
            TN: 'Tunesien',
            TR: 'Türkei',
            TM: 'Turkmenistan',
            TC: 'Turks- und Caicosinseln',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'Vereinigte Arabische Emirate',
            GB: 'Vereinigtes Königreich',
            US: 'Vereinigte Staaten',
            UM: 'Überseeische Inseln der Vereinigten Staaten',
            UY: 'Uruguay',
            UZ: 'Usbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Jungferninseln, Britisch',
            VI: 'Jungferninseln, U.S.A.',
            WF: 'Wallis und Futuna',
            EH: 'Westsahara',
            YE: 'Jemen',
            ZM: 'Sambia',
            ZW: 'Simbabwe'
        },
    },
    fr: {
        _name: 'Français',
        newDesign: {
            withdrawalRequests: 'Pas encore de demande de retrait',
            withdrawalRequestsText1: 'Vous n`avez pas encore effectué de demande de retrait..',
            withdrawalRequestsText2: 'Lorsque vous effectuez un retrait, les détails de votre transaction apparaissent ici',
            pdfAttachment: 'PDF - pièce jointe',
            agent: "Agent",
            closed: 'Fermé',
            pending: 'En attente',
            approved: 'Approuvé',
            declined: 'Refusé',
            document: 'Document',
            frontSide: 'Face avant',
            backSide: 'Face arrière',
            address: 'Adresse',
            frontSideDocument: 'Face avant du document',
            creditCard: 'Carte de crédit',
            selfie: 'Selfie',
            other: 'Autres',
            noDocument: 'Aucun document n’a encore été téléchargé',
            amountDeposited: 'Montant déposé',
            makeTransfer: 'faire le transfert',
            otherDetails: 'Détails d`ordre',
            manualWallets: 'Portefeuilles crypto',
            depositText: 'Le dépôt est traité sous 5 à 10 minutes',
            pendingBankTransfer: 'Vous avez un dépôt de virement bancaire en attente.',
            completeDeposit: 'Veuillez suivre les instructions ci-dessous pour effectuer votre dépôt',
            downloadInvoice: 'Télécharger la facture',
            payInvoice: 'Payer la facture dans votre banque',
            useYourBankText1: 'Utilisez le système de paiement en ligne ou hors ligne de votre banque pour payer la facture..',
            useYourBankText2: 'S`assurer que les détails du paiement sont corrects.',
            uploadReceipt: 'Télécharger le reçu de confirmation',
            afterMakingPayment: 'Après avoir effectué le paiement, téléchargez votre reçu pour confirmer le dépôt',
            clickToUpload: 'Cliquer pour télécharger',
            orDrag: 'ou glisser et déposer',
            fileFormat: 'PDF, JPG, JPEG, PNG moins de 10MB',
            waitForDeposit: 'Attendre la confirmation du dépôt',
            onceReceipt: 'Une fois le reçu téléchargé, nous vérifierons et approuverons votre dépôt',
            paymentDetails: 'Détails de paiement',
            nameOnCard: 'Nom sur la carte',
            cardNumber: 'Numéro de carte',
            expirationDate: 'Date d`expiration',
            securityCode: 'Code de sécurité',
            continue: 'Continuer',
            viewAccounts: 'Voir les comptes',
            continueWithdrawal: 'Continuer le retrait',
            accountBalance: 'Solde du compte',
            accountBalanceText1: 'Pourquoi je ne peux pas placer un échange?',
            accountsBalanceText2: 'Comment déposer des fonds?',
            accountsBalanceText3: 'Je veux déposer de l\'argent par Zelle',
            tradingActions: 'Actions de trading',
            tradingActionsText1: 'Comment vendre à découvert?',
            tradingActionsText2: 'Comment annuler une transaction?',
            tradingActionsText3: 'Quel est le montant minimum de la transaction?',
            marketInfo: 'Information du marché',
            marketInfoText1: 'Où puis-je voir les tendances du marché?',
            marketInfoText2: 'Quelles sont les principales hausses aujourd`hui?',
            securityAndSettings: 'Sécurité et paramètres',
            securityAndSettingsText1: 'Comment puis-je modifier mon mot de passe?',
            securityAndSettingsText2: 'Quelles sont les caractéristiques de sécurité?',
            securityAndSettingsText3: 'Comment mettre à jour les informations de mon compte?',
            messageCopied: 'Message copié dans le presse-papiers',
            addSymbol: 'Ajouter un symbole',
            iHaveRead: 'J’ai lu et accepte les conditions générales',
            toggleToTurn: 'Basculer pour activer AutoTrade',
            selectTheRisk: 'Sélectionnez le niveau de risque',
            confirm: 'Confirmer',
            cancel: 'Annuler',
            chooseClosingTime: 'Choisissez une heure de fermeture',
            setOrderClosingTime: 'Définir l’heure de clôture des commandes',
            closingTime: 'Heure de fermeture',
            date: 'Date',
            time: 'L`heure',
            setCloseTime: 'Régler l`heure de fermeture',
            '15m': '15 Minutes',
            '30m': '30 Minutes',
            '1h': '1 heure',
            custom: 'Personnalisé',
            verifyOtp: 'Vérifiez votre mot de passe',
            enterDigits: 'Entrez le code à 6 chiffres envoyé à votre [email/phone]',
            submit: 'Soumettre',
            order: 'Ordre',
            closedSuccessfully: 'clôturé avec succès',
            openSuccessfully: 'ouvrir avec succès',
            deposit: 'dépôt',
            withdrawal: 'retrait',
            completeVerification: 'Vérification complète',
            verification: 'Vérification',
            avatar: 'Avatar',
            saveChanges: 'Enregistrer les modifications',
            darkMode: 'Mode noir',
            dashboardCurrency: 'Devise du tableau de bord',
            language: 'Langue',
            twoFactor: 'Protection à 2 facteurs',
            googleTwoFactorIsActive: 'L`authentificateur à 2 facteurs de Google est actif',
            activateCodeForGoogle: 'Activer le code pour Google Authenticator',
            ifYouWishDisableEnterCode: 'Si vous souhaitez désactiver la protection, entrez le code de l’application d’authentification',
            scanOrManuallyEnter: 'Scannez ou entrez manuellement le code QR dans Google 2FA pour ajouter un jeton',
            copyCode: 'Copier le code',
            qrCode: 'Code QR',
            activateAndEnterAuthenticator: 'Activer l’authentificateur et entrer un code généré dans le champ ci-dessous',
            enterTheDigitsCodeGenerated: 'Entrez le code à 6 chiffres généré',
            disableTwoFactor: 'Désactiver la protection 2FA',
            activateTwoFactor: 'Activer la protection 2FA',
            fileAdded: 'Fichier ajouté avec succès',
            theVerificationStatus: 'Le statut de vérification peut être consulté dans la liste des documents téléchargés',
            confirmWithdrawal: 'Confirmer le retrait',
            minimumWithdrawal: 'Le montant minimum de retrait est',
            welcomeToTradingPlatform: 'Bienvenue sur la plateforme de trading',
            signIn: 'Connectez-vous',
            activeAccount: 'Compte actif',
            save: 'Sauver',
            selectCurrencyAndPeriod: 'Sélectionnez une devise et une période d’abonnement',
            savingsNotFound: 'Comptes d’épargne non trouvés',
            pleaseCheckInvestment: 'Veuillez vérifier vos renseignements sur le placement pour continuer',
            allPeriodInvestment: 'Tous les investissements de la période',
            pct_0: 'Flexible',
            pct_30: '1 Mois',
            pct_60: '1 Quart',
            pct_90: '6 Month',
            pct_180: '6 Month',
            pct_365: '1 Année',
            savingsCreated: 'Compte d’épargne créé avec succès',
            transferDetails: 'Détails du transfert',
            transferAmount: 'Montant de transfert',
            investmentDetails: 'Détails de l’investissement',
            investment: 'Investissement',
            Period: 'Période',
            created: 'Créé',
            releaseTime: 'Heure de sortie',
            viewSavingsAccounts: 'Afficher les comptes d’épargne',
            continueInvestment: 'Continuer d`investir',
            chooseSubscription: 'Choisissez votre abonnement',
            selectCurrencyAndSubscription: 'Sélectionnez une devise et une période d’abonnement pour continuer.',
            rate: 'Taux',
            accountTransferFunds: 'Compte pour transférer des fonds',
            amount: 'Montant',
            'in': 'in',
            openSavingsAndInvest: 'Ouvrir un compte d’épargne et investir',
            strongSell: 'Forte vente',
            sell: 'Vente',
            neutral: 'Neutre',
            buy: 'Acheter',
            stringBuy: 'Achat fort',
            chooseCryptoWallet: 'Choisissez un portefeuille de crypto-monnaie',
            chooseMethod: 'Choisissez une méthode',
            maximumDeposit: 'Le dépôt maximum est',
            minimumDeposit: "Le dépôt minimum est",
            pleaseCompleteAddress: 'Veuillez compléter votre adresse',
            pleaseCompleteCity: 'Veuillez compléter votre ville',
            pleaseCompleteCountry: 'Veuillez indiquer votre pays',
            pleaseCompleteZip: 'Veuillez indiquer votre code postal',
            amountDeposit: 'Dépôt de montant',
            transactionStatus: 'Statut de la transaction',
            paymentMethod: 'Méthode de paiement',
            depositTo: 'Dépôt pour',
            depositAddress: 'Adresse de dépôt',
            scanTheQr: 'Scannez le code QR ou copiez l’adresse de dépôt',
            wallet: 'Portefeuille',
            copyDepositAddress: 'Adresse de dépôt de copie',
            copy: 'Copier',
            addressCopied: 'Adresse copiée dans le presse-papiers',
            '5min:': '5-10 min',
            '2-5days': '2-5 jours / 5-10 min',
            otherMethod: 'Autre méthode',
            profileSettings: 'Paramètres de profil',
            savingsAccounts: 'Comptes d`épargne',
            subscribe: 'S`abonner',
            toCompleteVerification: 'Pour compléter le processus de vérification de votre compte, veuillez fournir des copies claires et lisibles des documents suivants',
            amountToWithdrawal: 'Montant à retirer',
            cardDetails: 'Détails de carte',
            cardHolderName: 'Nom du titulaire de la carte',
            withdrawalSuccessful: 'Retrait réussi',
            selectWithdrawalMethod: 'Sélectionner la méthode de retrait',
            creditDebit: 'Carte de crédit/débit',
            crypto: 'Crypto',
            cryptoWalletAddress: 'Adresse du portefeuille Crypro',
            cryptoSubtitle: 'Retraits crypto vers votre portefeuille 🚀',
            bankWithdrawal: 'Retrait bancaire',
            bankSubtitle: 'Compte bancaire 🏦',
            zelleSubtitle: 'Transferts bancaires rapides ⚡🏦',
            tradingResults: 'Résultats de trading',
            day: 'Jour',
            month: 'Mois',
            noTradingData: 'Aucune donnée de trading disponible',
            startTradingToSee: 'Commencez à trader pour voir vos résultats ici.',
            yourPerformanceMetric: 'Your performance metrics will be displayed once data is available.',
            favorite: 'Préféré',
            all: 'Tout',
            availableFullTime: 'Disponible 24/7',
            yourProfileAndSettings: 'Votre profil et vos paramètres',
            switchToNewDesignMessage: 'Passez à notre nouvelle conception de plate-forme maintenant!',
            switchToOldDesignMessage: 'Vous pouvez toujours utiliser le design classique pour une durée limitée',
            switchToOldDesignButton: 'Passer au nouveau design',
            switchToNewDesignButton: 'Revenir à la conception classique',
            generateInvoice: 'Générer une facture',
            downloadPdf: 'Télécharger le PDF',
            confirmDeposit: 'Confirmer le dépôt',
            howCanIAssist: 'Comment puis-je vous aider aujourd’hui ?',
            maxFileSize: 'La taille maximale du fichier est de 5 Mo, formats acceptés : JPEG, PNG ou GIF',
            uploadPhoto: 'Télécharger une photo',
            featuresDisabled: 'Cette fonctionnalité est désactivée',
            welcome: 'Bienvenue',
            signUp: 'S’inscrire',
            registration: 'Inscription',
            pleaseConfirmAgreement: 'Veuillez confirmer l’accord client',
            pleaseEnterPassword: 'Veuillez entrer un mot de passe',
            passwordNotSame: 'Les mots de passe ne correspondent pas',
            noActiveOrders: 'Aucune commande active',
            itLikeNoHaveActiveOrders: 'Il semble que vous n’ayez aucune commande active pour le moment',
            cryptoWallet: 'Portefeuille crypto',
            bankCard: 'Banque/Carte',
            selected: 'Sélectionné',
            amountWithdrawal: 'Montant retiré',
            withdrawalTo: 'Retrait vers',
            governmentIssuedPhoto1: 'Pièce d’identité avec photo délivrée par le gouvernement (ex : passeport, permis de conduire, carte nationale d’identité)',
            governmentIssuedPhoto2: 'Titre de séjour (si applicable)',
            governmentIssuedPhoto3: 'Le document doit être valide (non expiré)',
            governmentIssuedPhoto4: 'Le nom complet, la date de naissance et la photo doivent être visibles.',
            uploadRecent1: 'Téléchargez une facture de services publics récente, un relevé bancaire ou un contrat de location émis au cours des 3 derniers mois',
            uploadRecent2: 'Assurez-vous que votre nom complet et votre adresse résidentielle actuelle sont clairement visibles.',
            uploadCopy1: 'Téléchargez une copie du recto de votre carte de crédit en veillant à ce que les six premiers et les quatre derniers chiffres soient visibles',
            uploadCopy2: 'Veuillez masquer les chiffres intermédiaires pour des raisons de sécurité',
            takeAndUpload1: 'Prenez un selfie tenant votre pièce d’identité à côté de votre visage et téléchargez-le',
            takeAndUpload2: 'Assurez-vous que votre visage et la pièce d’identité sont clairement visibles',
            otherDocuments: 'Autres documents',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Téléchargez une image claire et lisible de votre document de numéro de sécurité sociale (SSN). Assurez-vous que tous les détails sont visibles et non obstrués.',
            frontSideSsnDocument: 'Recto du document SSN',
            answerSaved: 'Les réponses ont été enregistrées',
            cryptoWalletDetails: 'Détails du portefeuille crypto',
            bitcoinWalletAddress: 'Adresse du portefeuille Bitcoin',
            recovery: 'Récupération',
            passwordRecovery: 'Récupération de mot de passe',
            searchCountry: 'Rechercher un pays',
            questionnaire: 'Questionnaire',
            questionnaireText: 'Veuillez remplir le questionnaire pour vérifier votre identité',
            sendAnswers: 'Envoyer les réponses',
        },
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            updateOrder: 'Ordre de mise à jour',
        },
        balanceModal: {
            balance: 'Solde',
            credit: 'Crédit',
            equity: 'Equity',
            practice: 'PRACTICE',
            real: 'REAL',
            account: 'ACCOUNT',
            invested: 'Investi',
            profit: 'Profit',
            loss: 'Perte',
            margin: 'Marge',
            marginLevel: 'Marge Niveau',
            marginFree: 'Marge libre'
        },
        header: {
            cfd: 'CFD',
            platform: 'Plateforme',
            deposit: 'Dépôt'
        },
        sideMenu: {
            marketWatch: 'Surveillance du marché',
            activeOrders: 'Commandes actives',
            tradingHistory: 'Historique des transactions',
            economicCalendar: 'Calendrier',
            marketNews: 'Nouvelles du marché'
        },
        closePositionModal: {
            closeOrder: 'Position fermée',
            areYouSure: 'Etes-vous sûr de fermer la position',
            buy: 'Acheter',
            sell: 'Vendez',
            yes: 'Oui',
            no: 'Non',
        },
        pciDssModal: {
            desc: `La conformité au standard de sécurité des données de l'industrie des cartes de paiement (PCI DSS) est requise de tous les entités qui stockent, traitent ou transmettent les données des porteurs de cartes Visa/Master Card/Stripe, y compris les institutions financières, les marchands et les prestataires de services. 
            Il s'agit de la norme de sécurité mondiale pour toutes les entités qui stockent, traitent ou transmettent des données de porteurs de cartes et/ou des données d'authentification sensibles. Le PCI DSS établit un niveau de protection de base pour les consommateurs et contribue à réduire la fraude et les violations de données dans l'ensemble de l'écosystème de paiement.`
        },
        chart: {
            addNewChart: 'Ajouter un nouveau graphique',
        },
        symbolsModal: {
            watchlist: 'Liste de surveillance',
            asset: 'Active',
            price: 'Prix',
            changePct: 'Changement 24h'
        },
        pendingModal: {
            pending: 'En attente',
            assetPrice: 'Prix de l\'actif',
            current: 'Actuel',
            revert: 'Retour au prix du marché',
            automatically: 'La position sera ouverte automatiquement lorsque le prix atteindra ce niveau'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volume',
            lots: 'lots',
            units: 'unités',
            currency: 'devise',
            contractSize: 'Taille du contrat',
            position: 'Position',
            margin: 'Marge',
            freeMargin: 'Marge libre',
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            pending: 'En attente',
            market: 'Marché',
            leverage: 'Effet de levier',
            spread: 'Écartement',
            notSet: 'Non défini',
            at: 'à l\'adresse',
            buy: 'acheter',
            sell: 'vendre',
        },
        footer: {
            supportBanner: 'TOUS LES JOURS, 24 HEURES SUR 24',
            currentTime: 'HEURE ACTUELLE',
            liveChat: 'Support client'
        },
        statusBar: {
            balance: 'Balance',
            credit: 'Crédit',
            equity: 'Equity',
            margin: 'Marge',
            marginLevel: 'Niveau de marge',
            freeMargin: 'Marge libre',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Date d\'enregistrement',
            userId: 'ID utilisateur',
            dashboard: 'Tableau de bord',
            personalData: 'Données personnelles',
            deposit: 'Dépôt',
            withdrawFunds: 'Retirer des fonds',
            savings: 'Épargne',
            settings: 'Paramètres',
            logout: 'Déconnexion'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Effectuer un dépôt',
            practiceAccount: 'Effectuer un dépôt',
            realAccount: 'Compte réel',
            noWithdrawals: 'Pas de retraits',
            easyWithdrawals: 'Retraits faciles',
            allAssets: 'Tous les actifs disponibles',
            fullFledged: 'Une plateforme complète',
            fillUpTo: 'Remplir jusqu\'à',
            freeReplenishment: 'Réapprovisionnement gratuit',
            topUp: 'Rechargez votre compte',
            minimumAmount: 'Montant minimum',
            canSwitch: 'Vous pouvez passer d\'un compte à l\'autre à tout moment'
        },
        gridsModal: {
            chartGrids: 'GRILLES DE CARTES',
            chart1: '1 graphique',
            chart2: '2 graphiques',
            chart3: '3 graphiques',
            chart4: '4 graphiques',
        },
        noConnectionModal: {
            connectionLost: 'La connexion avec le serveur est perdue',
            retryNow: 'Réessayer maintenant',
        },
        loginModal: {
            loginToTradeRoom: 'Se connecter à Traderoom',
            email: 'Adresse électronique',
            enterEmail: 'Entrez votre courriel',
        enterTwoFactor: 'Enter 2FA code',
            twoFactor: 'Code 2FA (si activé)',
            twoFactorAuth: 'Authentification à deux facteurs',
            password: 'Mot de passe',
            yourPassword: 'Votre mot de passe',
            signIn: 'S\'inscrire',
            dontHaveAccount: 'Vous n\'avez pas de compte?',
            restore: 'Restaurer',
            fullName: 'Nom complet',
            havePromo: 'J\'ai un code promo',
            promo: 'Code promo',
            login: 'Connexion',
            haveAccount: 'Vous avez déjà un compte?',
            hide: 'Cacher',
            goTo: 'Retourner au début'
        },
        newPasswordModal: {
            setNewPassword: 'Définir un nouveau mot de passe',
            newPassword: 'Nouveau mot de passe',
            confirmNewPassword: 'Confirmer le nouveau mot de passe',
            setButton: 'Définir'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Créer un nouveau compte',
            email: 'Adresse électronique',
            firstName: 'Prénom',
            lastName: 'Nom de famille',
            country: 'Pays',
            phone: 'Téléphone',
            password: 'Mot de passe',
            createAccount: 'Créer un compte',
            currency: 'Monnaie',
            privacyAndPolicy: "Confidentialité et Politique",
            customerAgreement: 'Accord client',

        },
        forgotPasswordModal: {
            forgotPassword: 'Mot de passe oublié?',
            submitEmail: 'Veuillez soumettre un courriel utilisé pour l\'inscription, vérifiez votre boîte de réception et suivez les instructions fournies.',
            submit: 'Soumettre'
        },
        notifications: {
            error: 'Erreur',
            success: 'Succès',
            deposit: 'Dépôt',
            withdrawal: 'Retrait',
            depositApproved: 'a été approuvé',
            depositDeclined: 'Le dépôt a été refusé',
            withdrawalApproved: 'Le retrait a été approuvé',
            withdrawalDeclined: 'Le retrait a été refusé'
        },
        markets: {
            favorites: 'Liste de surveillance', 
            all: 'Tous les symboles',
            forex: 'Forex',
            stocks: 'Stocks',
            commodities: 'Produits de base',
            indices: 'Indices',
            crypto: 'Crypto',
            metals: 'Métaux',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'BUY',
            profitCalculator: 'Calculateur de profit',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Dépôt réussi',
            text: 'Votre dépôt a été traité avec succès !',
        },
        depositFailModal: {
            title: 'Dépôt échoué',
            text: 'Votre dépôt n\'a pas été traité.',
        },
        widgetMarketWatch: {
            marketWatch: 'Surveillance du marché',
            search: 'Recherche...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volume',
            margin: 'Marge',
            active: 'Actif',
            pending: 'En attente',
            activeOrders: 'Commandes actives',
            portfolio: 'Portefeuille',
            allPositions: 'Tous les postes',
            noPositionsLine1: 'Vous n\'avez pas d\'ouverture',
            noPositionsLine2: 'postes encore',
            show: 'Afficher',
            more: 'plus',
            less: 'moins',
            purchaseTime: 'Temps d\'achat',
            closePosition: 'Position fermée',
            priceOpen: 'Prix d\'achat',
            priceSl: 'Prix du Stop Loss',
            priceTp: 'Prix du Take Profit',
            type: 'Envoyer les réponses',
            pnl: 'Bénéfice/perte',
            cancelOrder: 'Annuler la commande',
            orderType: 'Type de commande',
            cancelled: 'Annulé',
            tradingHistory: 'Historique des transactions',
            noHistoryLine1: 'Vous n\'avez pas de',
            noHistoryLine2: 'des affaires conclues pour le moment',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendrier'
        },
        widgetMarketNews: {
            marketNews: 'Nouvelles du marché'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Commandes actives',
            ordersHistory: 'Historique des commandes',
            id: 'ID',
            symbol: 'Symbole',
            type: 'Type',
            volume: 'Volume',
            openPrice: 'Prix ouvert',
            openTime: 'Temps ouvert',
            closePrice: 'Fermer le prix',
            closeTime: 'Heure de fermeture',
            sl: 'SL',
            tp: 'TP',
            price: 'Prix',
            pnl: 'PnL',
            actions: 'Actions',
            edit: 'Modifier',
            close: 'Fermer',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Session Change',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'bas',
            riskLevelMedium: 'moyen',
            riskLevelHigh: 'élevé',
            perDay: 'Négoces par jour:',
            desc: `Le trading de CFD et d'autres produits à effet de levier peut entraîner des pertes. Avant de trader, les clients doivent lire les déclarations de risque pertinentes sur notre page de divulgation des risques. Le trading automatisé ne garantit pas de résultats. L'entreprise n'accepte aucune responsabilité pour la perte de fonds dans le trading automatique. Assurez-vous de bien comprendre les risques et de prendre des mesures pour les gérer.`,
            accept: 'Accepter',
            save: 'Sauvegarder',
            cancel: 'Annuler',
        },
        cardBox: {
            name: 'Nom Prénom',
            cardNumber: 'Numéro de la carte',
            expiration: 'Expiration (mm/aa)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Salut',
            trade: "Les marchés",
            startTrading: 'Commencer à trader',
            deposit: 'Effectuer un dépôt',
            dashboard: 'Tableau de bord',
            personalInfo: 'Mise à jour des données personnelles',
            contactInfo: 'Coordonnées',
            withdrawal: 'Demander un retrait',
            verification: 'Vérification de l\'identité',
            accounts: 'Gérer les comptes',
            liveChat: 'Support client',
            savings: 'Épargne',
            referrals: 'Programme de parrainage',
            settings: 'Paramètres de la plate-forme',
            logOut: 'Déconnexion',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'FAIRE UN DÉPÔT',
            instant: 'Instantané',
            minutes: 'minutes',
            hours: 'heures',
            days: 'jours',
            amount: 'Montant',
            continue: 'Continuer',
            qrCode: 'QR CODE',
            depositAddress: 'ADRESSE DE DÉPÔT',
            copy: 'Copie',
            last: 'DERNIER',
            deposits: 'DÉPÔTS',
            time: 'Temps',
            currency: 'Monnaie',
            status: 'Statut',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Veuillez patienter. Connexion au fournisseur de services de paiement...',
            cardPayment: 'Paiement par carte',
            inOrder: 'Afin d\'envoyer le montant',
            pleaseProvide: 'veuillez fournir les détails de la carte :'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'Pas encore de commande',
            totalBalance: 'Solde Total',
            totalPnl: 'PnL Total',
            profitableOrders: 'Commandes Rentables',
            roi: 'ROI',
            activityLog: "Journal d'activités",
            loginFromIp: 'Login from IP',
            tradingResults: 'Résultats de Trading',
            week: 'Semaine',
            month: 'Mois',
            year: 'Année',
            successRate: 'Taux de Réussite',
            closedWithProfit: 'Clôturé avec un Bénéfice',
            closedWithLoss: 'Clôturé avec une Perte',
            account: 'Compte',
            balance: 'Solde',
            leverage: 'Levier',
            credit: 'Crédit',
            tradeNow: 'Trading Maintenant',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Dépôts totaux',
            totalWithdrawals: "Retraits totaux",
            returnOnInvestment: "Retour sur investissement"
        },
        fullScreenPersonal: {
            personalDetails: 'Détails personnels',
            profilePhoto: 'Photo de profil',
            firstName: 'Prénom',
            country: 'Pays',
            lastName: 'Nom de famille',
            address: 'Adresse',
            email: 'Courriel',
            phone: 'Numéro de téléphone',
            saveChanges: 'Sauvegarder les changements',
            state: 'Ville',
            postcode: 'Code postal',
            created: "Compte créé le",
        },
        fullScreenAvatar: {
            dropFile: 'Déposez un fichier sur le cercle ci-dessus pour le télécharger',
            notAllowed: 'Il n\'est pas permis de publier',
            notAllowedLine1: 'Photos à caractère explicitement sexuel ou pornographique',
            notAllowedLine2: 'Images visant à inciter à la haine ou à l\'agression ethnique ou raciale',
            notAllowedLine3: 'Photos impliquant des personnes de moins de 18 ans',
            notAllowedLine4: 'Photos protégées par le droit d\'auteur de tiers',
            notAllowedLine5: 'Images de plus de 5 MB et dans un format autre que JPG, GIF ou PNG',
            requirements: 'Votre visage doit être clairement visible sur la photo. Toutes les photos et vidéos que vous mettez en ligne doivent respecter ces exigences, faute de quoi elles peuvent être supprimées.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'DEMANDER UN NOUVEAU RETRAIT',
            amount: 'Montant',
            currentBalance: 'Le solde de votre compte courant est de',
            withdrawAll: 'Retirer tout',
            requestWithdrawal: 'Demande de retrait',
            last: 'DERNIER',
            withdrawalRequests: 'DEMANDES DE RETRAIT',
            time: 'Temps',
            currency: 'Monnaie',
            info: 'Info',
            status: 'Statut',
            bank: 'Banque',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Carte',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Détails du retrait',
            address: 'Adresse du portefeuille',
            bankDetails: 'Détails bancaires',
            bankAccount: 'Numéro de compte',
            bankHolder: 'Nom du titulaire du compte',
            bankIban: 'IBAN',
            bankSwift: 'Code SWIFT bancaire',
            recipientAddress: 'Adresse du destinataire',
            routingNumber: 'Numéro de routage bancaire',
            bankBeneficiary: 'Nom de la banque du bénéficiaire',
            bankAddress: 'Adresse de la banque',
            bankCode: 'Code bancaire (BSB)',
            desc: 'Description',
            cardNumber: 'Numéro de la carte',
            cardHolder: 'Nom du titulaire de la carte',
        },
        fullScreenVerification: {
            dragAndDrop: 'glisser et déposer le document dans cette zone',
            documentsList: 'LISTE DES DOCUMENTS TÉLÉCHARGÉS',
            document: 'Document',
            timeUploaded: 'Temps téléchargé',
            timeProcessed: 'Temps traité',
            status: 'Statut',
            types: ['Preuve d\'identité', 'Preuve de résidence', 'Recto de la carte de crédit', 'Retour de la carte de crédit', 'Preuve d\'identité en arrière', 'Selfie', 'Other'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Télécharger',
            isConfirmed: 'est confirmé',
            uploadAnotherDocument: 'Télécharger un autre document',
            isUnderReview: 'est en cours de révision',
            questionnaire: 'Questionnaire',
            sendAnswers: 'Envoyer les réponses',
            progressBarOf: 'of',
            progressBarText: 'of your documents have been uploaded and confirmed'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transférer des fonds entre vos comptes',
            transferFrom: 'Du compte',
            transferTo: 'Pour le compte',
            amount: 'Montant',
            makeTransfer: 'Effectuer un transfert',
            yourAccounts: 'Vos comptes',
            account: 'Compte',
            currency: 'Monnaie',
            balance: 'Balance',
            credit: 'Crédit',
            makeActive: 'Rendre actif',
            active: 'Actif'
        },
        fullScreenSupport: {
            sendMessage: 'Envoyer un message'
        },
        fullScreenSavings: {
            pct_0: 'Flexible',
            pct_30: 'Verrouillé 1 mois',
            pct_90: 'Verrouillé 1 trimestre',
            pct_180: 'Verrouillé 6 mois',
            pct_365: 'Verrouillé 1 an',
            savings: 'Épargne',
            detailedInformation: 'Informations détaillées',
            pleaseSelect: 'Veuillez sélectionner une devise et une période',
            openSavings: 'Épargne ouverte',
            currentTime: 'Heure actuelle',
            releaseTime: 'Heure de sortie',
            currencyChosen: 'Devise choisie',
            periodChosen: 'Période choisie',
            yourRate: 'Votre taux',
            yourEarnings: 'Vos revenus',
            accountFrom: 'Compte pour transférer des fonds de',
            enterAmount: 'Entrez le montant',
            in: 'Sur',
            openAndInvest: 'Ouvrir un compte d\'épargne et investir',
            investment: 'Investissement',
            period: 'Période',
            daysTotal: 'Nombre total de jours',
            finalEarnings: 'Revenu final',
            created: 'Créé',
            daysElapsed: 'Jours écoulés',
            days: 'jours',
            earnings: 'Revenus',
            fundsReleasedIn: 'Fonds libérés en',
            claim: 'Demande d\'indemnisation',
            name: "Nom",
        },
        fullScreenSettings: {
            updatePassword: 'METTRE À JOUR LE MOT DE PASSE',
            currentPassword: 'Mot de passe actuel',
            newPassword: 'Nouveau mot de passe',
            changePassword: 'Changer le mot de passe',
            activationForGoogle: 'Code d\'activation pour Google Authenticator',
            activateAndEnter: 'Activez l\'Authenticator et entrez un code généré dans le champ ci-dessous.',
            qrCode: 'QR Code',
            activateProtection: 'Activer la protection 2FA',
            protectionActive: 'La protection 2FA est active',
            twoFactorCode: 'Code 2FA',
            disableProtection: 'Désactiver la protection 2FA',
            language: 'Langue',
            dashboardCurrency: 'Tableau de bord des devises',
            confirmNewPassword: 'Confirmer le nouveau mot de passe',
        },
        fullScreenReferrals: {
            yourLink: 'VOTRE LIEN DE RÉFÉRENCE',
            referredFriends: 'AMIS RÉFÉRENCÉS',
            id: 'ID',
            name: 'Nom Prénom',
            registered: 'Enregistré',
            level: 'Niveau',
            payout: 'Payout',
            lastPayouts: 'LES DERNIERS PAIEMENTS DE PARRAINAGE',
            time: 'Temps',
            amount: 'Montant',
            currency: 'Monnaie',
            info: 'Info',
            referralDesc:"Le programme de parrainage est conçu pour vous récompenser d’agrandir notre communauté d’investisseurs de valeur.",
            shareLink:"Partagez le lien :",
            subtitle:"Fournissez-lui votre nom, votre e-mail ou votre identifiant de trading.",
            descSuccesfull:"Une fois votre filleul enregistré avec succès, votre conseiller financier le contactera pour l’aider efficacement et professionnellement dans ses premiers pas sur le chemin de l’investissement.",
            descTracked:"Toutes ses contributions sont suivies sous votre parrainage."
        },
        months: {
            Jan: 'Jan',
            Feb: 'Fev',
            Mar: 'Mar',
            Apr: 'Abr',
            May: 'Mai',
            Jun: 'Juin',
            Jul: 'Jui',
            Aug: 'Aoû',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Acheter', 'Vendez', 'Limite d\'achat', 'Limite de vente', 'Stop à l\'achat', 'Stop à la vente'],
        statuses: ['En attente', 'Approuvé', 'Refusé', 'Traitement'],
        errors: {
            NOT_FOUND: 'Erreur de demande',
            FORBIDDEN: 'Accès refusé. Veuillez vous reconnecter',
            SERVER_ERROR: 'L\'action a échoué. Veuillez réessayer plus tard',
            INVALID_ACCOUNT: 'La session a expiré. Veuillez vous reconnecter',
            INVALID_EMAIL: 'Adresse électronique non valide',
            INVALID_PHONE: 'Numéro de téléphone non valide',
            INVALID_COUNTRY: 'Pays non valide',
            INVALID_LOGIN: 'Identifiants de connexion non valides',
            USER_BLOCKED: 'Le compte est bloqué. Veuillez contacter le support',
            USER_REGISTERED: 'L\'utilisateur dont l\'adresse électronique ou le numéro de téléphone a été saisi est déjà enregistré.',
            INVALID_USER: 'L\'utilisateur n\'est pas trouvé',
            REJECTED: 'L\'opération n\'est pas autorisée en raison des restrictions de la plate-forme',
            INVALID_OLD_PASSWORD: 'L\'ancien mot de passe n\'est pas valide',
            INVALID_SYMBOL: 'Symbole commercial non valide',
            INVALID_SYMBOL_PRECISION: 'Précision du symbole non valide',
            INVALID_FRAME: 'Cadre graphique non valide',
            INVALID_CURRENCY: 'Devise non valide',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Type de trading de compte invalide',
            INSUFFICIENT_FUNDS: 'Fonds insuffisants',
            INVALID_WITHDRAWAL: 'Le retrait n\'est pas trouvé',
            INVALID_STATUS: 'Statut non valide',
            INVALID_FILE: 'Extension de fichier non valide ou fichier trop volumineux',
            INVALID_DOCUMENT: 'Le document n\'est pas trouvé',
            INVALID_NAME: 'Nom non valide',
            INVALID_LOT_SIZE: 'Taille de lot non valide',
            INVALID_LOT_STEP: 'Pas de lot invalide',
            INVALID_MIN_VOLUME: 'Volume minimal non valide',
            INVALID_MAX_VOLUME: 'Volume maximal invalide',
            INVALID_GROUP: 'Groupe non valide',
            INVALID_SPREAD: 'Diffusion non valide',
            REQUEST_TIMEOUT: 'La demande a expiré. Veuillez réessayer plus tard',
            EXISTING_OPEN_ORDERS: 'Vous avez une commande en cours, fermez d\'abord la commande pour cet utilisateur et réessayez.',
            INVALID_TYPE: 'Type de commande non valide',
            INVALID_VOLUME: 'Volume d\'ordre invalide',
            INVALID_SL: 'Valeur invalide de l\'excédent de pertes',
            INVALID_TP: 'Valeur de prise de bénéfices invalide',
            INVALID_PRICE: 'Prix de la commande invalide',
            INVALID_EXPIRATION: 'Expiration de la commande invalide',
            NOT_ENOUGH_MARGIN: 'Pas assez de marge',
            INVALID_ORDER: 'L\'ordre n\'est pas trouvé',
            MARKET_CLOSED: 'Le commerce n\'est pas disponible. Veuillez réessayer',
            INVALID_BALANCE_TYPE: 'Type d\'opération de balance non valide',
            INVALID_HASH: 'Hachage non valide',
            HASH_EXPIRED: 'Le lien de réinitialisation de votre mot de passe a expiré. Veuillez en demander un nouveau',
            INVALID_CODE: 'Code 2FA invalide',
            CHAT_DISABLED: 'L\'accès au chat d\'assistance a été fermé',
            WITHDRAWAL_NOT_ALLOWED: 'L\'accès aux retraits a été fermé',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is '
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland Islands',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua And Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnia And Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            CV: 'Cape Verde',
            KY: 'Cayman Islands',
            CF: 'Central African Republic',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands',
            CO: 'Colombia',
            KM: 'Comoros',
            CG: 'Congo',
            CD: 'Congo, Democratic Republic',
            CK: 'Cook Islands',
            CR: 'Costa Rica',
            CI: 'Cote D\'Ivoire',
            HR: 'Croatia',
            CU: 'Cuba',
            CY: 'Cyprus',
            CZ: 'Czech Republic',
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (Malvinas)',
            FO: 'Faroe Islands',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island & Mcdonald Islands',
            VA: 'Holy See (Vatican City State)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle Of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: 'Lao People\'s Democratic Republic',
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libyan Arab Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands',
            AN: 'Netherlands Antilles',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestinian Territory, Occupied',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Romania',
            RU: 'Russia',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts And Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre And Miquelon',
            VC: 'Saint Vincent And Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome And Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia And Sandwich Isl.',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Suriname',
            SJ: 'Svalbard And Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan',
            TJ: 'Tajikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad And Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks And Caicos Islands',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates',
            GB: 'United Kingdom',
            US: 'United States',
            UM: 'United States Outlying Islands',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Virgin Islands, British',
            VI: 'Virgin Islands, U.S.',
            WF: 'Wallis And Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    es: {
        _name: 'Español',
        timePickerModal: {
            title: 'Close In Time'
        },
        newDesign: {
            withdrawalRequests: 'Aún no hay solicitudes de retirada',
            withdrawalRequestsText1: 'Hasta ahora no has hecho ninguna solicitud de retirada.',
            withdrawalRequestsText2: 'Una vez que inicie una retirada, los detalles de su transacción aparecerán aquí',
            pdfAttachment: 'Adjuntar PDF',
            agent: "Agente",
            closed: 'Cerrado',
            pending: 'Pendiente',
            approved: 'Aprobado',
            declined: 'Rechazado',
            document: 'Documento',
            frontSide: 'Anverso',
            backSide: 'Reverso',
            address: 'Dirección',
            frontSideDocument: 'Anverso del documento',
            creditCard: 'Tarjeta de crédito',
            selfie: 'Selfie',
            other: 'Otros',
            noDocument: 'Aún no se ha cargado ningún documento',
            amountDeposited: 'Importe depositado',
            makeTransfer: 'Realizar transferencia',
            otherDetails: 'Detalles del pedido',
            manualWallets: 'Billeteras cripto',
            depositText: 'El depósito se procesa en un plazo de 5-10 minutos',
            pendingBankTransfer: 'Tiene pendiente un ingreso por transferencia bancaria.',
            completeDeposit: 'Siga las siguientes instrucciones para realizar el ingreso',
            downloadInvoice: 'Descargar la factura',
            payInvoice: 'Pague la factura en su banco',
            useYourBankText1: 'Utilice el sistema de pago en línea o fuera de línea de su banco para abonar la factura.',
            useYourBankText2: 'Asegúrese de que los datos de pago son correctos.',
            uploadReceipt: 'Cargar el recibo de confirmación',
            afterMakingPayment: 'Después de efectuar el pago, cargue su recibo para confirmar el ingreso',
            clickToUpload: 'Haga clic para cargar',
            orDrag: 'o arrastrar y soltar',
            fileFormat: 'PDF, JPG, JPEG, PNG menos de 10MB',
            waitForDeposit: 'Esperar la confirmación del depósito',
            onceReceipt: 'Una vez cargado el recibo, verificaremos y aprobaremos su depósito.',
            paymentDetails: 'Datos de pago',
            nameOnCard: 'Nombre en la tarjeta',
            cardNumber: 'Número de tarjeta',
            expirationDate: 'Fecha de expiración',
            securityCode: 'Código de seguridad',
            continue: 'Continúe en',
            viewAccounts: 'Ver cuentas',
            continueWithdrawal: 'Continuar la retirada',
            accountBalance: 'Saldo de la cuenta',
            accountBalanceText1: '¿Por qué no puedo realizar una operación?',
            accountsBalanceText2: '¿Cómo ingreso fondos?',
            accountsBalanceText3: 'Quiero depositar dinero a través de Zelle',
            tradingActions: 'Acciones comerciales',
            tradingActionsText1: '¿Cómo puedo vender en corto?',
            tradingActionsText2: '¿Cómo puedo anular una operación?',
            tradingActionsText3: '¿Cuál es el importe mínimo de las operaciones?',
            marketInfo: 'Información de mercado de trading',
            marketInfoText1: '¿Dónde puedo ver las tendencias del mercado?',
            marketInfoText2: '¿Cuáles son las principales ganancias de hoy?',
            securityAndSettings: 'Seguridad y configuración',
            securityAndSettingsText1: '¿Cómo puedo cambiar mi contraseña?',
            securityAndSettingsText2: '¿Cuáles son los elementos de seguridad?',
            securityAndSettingsText3: '¿Cómo actualizo los datos de mi cuenta?',
            messageCopied: 'Mensaje copiado en el portapapeles',
            addSymbol: 'Añadir símbolo',
            iHaveRead: 'He leído y acepto los términos y condiciones',
            toggleToTurn: 'Activar AutoTrade',
            selectTheRisk: 'Seleccione el nivel de riesgo',
            confirm: 'Confirme',
            cancel: 'Anular',
            chooseClosingTime: 'Elija una hora de cierre',
            setOrderClosingTime: 'Fijar hora de cierre del pedido',
            closingTime: 'Hora de cierre',
            date: 'Date',
            time: 'Tiempo',
            setCloseTime: 'Fijar hora de cierre',
            '15m': '15 minutos',
            '30m': '30 minutos',
            '1h': '1 hora',
            custom: 'Personalizar',
            verifyOtp: 'Verifique su OTP',
            enterDigits: 'Introduzca el código de 6 dígitos enviado a su [correo email/teléfono].',
            submit: 'Enviar',
            order: 'Orden',
            closedSuccessfully: 'cerrado con éxito',
            openSuccessfully: 'abrir con éxito',
            deposit: 'depósito',
            withdrawal: 'retirada',
            completeVerification: 'Completar la verificación',
            verification: 'Verificación',
            avatar: 'Avatar',
            saveChanges: 'Guardar cambios',
            darkMode: 'Modo oscuro',
            dashboardCurrency: 'Divisa del Panel',
            language: 'Idioma',
            twoFactor: 'Protección de 2 factores',
            googleTwoFactorIsActive: 'El autenticador de 2 factores de Google está activo',
            activateCodeForGoogle: 'Activar código para Google Authenticator',
            ifYouWishDisableEnterCode: 'Si desea desactivar la protección, introduzca el código de la aplicación de autenticación',
            scanOrManuallyEnter: 'Escanee o introduzca manualmente el código QR en Google 2FA para añadir un token',
            copyCode: 'Copiar código',
            qrCode: 'Código QR',
            activateAndEnterAuthenticator: 'Active Authenticator e introduzca un código generado en el campo inferior',
            enterTheDigitsCodeGenerated: 'Introduzca el código de 6 cifras generado',
            disableTwoFactor: 'Desactivar la protección de dos factores',
            activateTwoFactor: 'Activar la protección 2FA',
            fileAdded: 'Fichero añadido correctamente',
            theVerificationStatus: 'El estado de la verificación puede verse en la lista de documentos cargados',
            confirmWithdrawal: 'Confirmar retiro',
            minimumWithdrawal: 'El importe mínimo de retirada es de',
            welcomeToTradingPlatform: 'Bienvenido a la plataforma de negociación',
            signIn: 'Iniciar sesión',
            activeAccount: 'Cuenta activa',
            save: 'Guarde',
            selectCurrencyAndPeriod: 'Seleccione una moneda y un periodo de suscripción',
            savingsNotFound: 'Cuentas de ahorro no encontradas',
            pleaseCheckInvestment: 'Compruebe los datos de su inversión para continuar',
            allPeriodInvestment: 'Inversión de todo el periodo',
            pct_0: 'Flexible',
            pct_30: '1 mes',
            pct_60: '1 trimestre',
            pct_90: '6 meses',
            pct_180: '6 meses',
            pct_365: '1 año',
            savingsCreated: 'Cuenta de ahorro creada correctamente',
            transferDetails: 'Detalles de la transferencia',
            transferAmount: 'Monto de la transferencia',
            investmentDetails: 'Detalles de la inversión',
            investment: 'Inversión',
            Period: 'Periodo',
            created: 'Creado',
            releaseTime: '"Hora de lanzamiento',
            viewSavingsAccounts: 'Ver cuentas de ahorro',
            continueInvestment: 'Continuar inversión',
            chooseSubscription: 'Elige tu suscripción',
            selectCurrencyAndSubscription: 'Selecciona una moneda y un periodo de suscripción para continuar.',
            rate: 'Tasa',
            accountTransferFunds: 'Cuenta desde la cual transferir fondos',
            amount: 'Monto',
            'in': 'en',
            openSavingsAndInvest: 'Abrir una cuenta de ahorro e invertir',
            strongSell: 'Venta fuerte',
            sell: 'Vender',
            neutral: 'Neutral',
            buy: 'Comprar',
            stringBuy: 'Compra fuerte',
            chooseCryptoWallet: 'Elige una billetera de criptomonedas',
            chooseMethod: 'Elija un método',
            maximumDeposit: 'El depósito máximo es',
            minimumDeposit: "El depósito mínimo es",
            pleaseCompleteAddress: 'Por favor, completa tu dirección',
            pleaseCompleteCity: 'Por favor, completa tu ciudad',
            pleaseCompleteCountry: 'Por favor, completa tu país',
            pleaseCompleteZip: 'Por favor, completa tu código postal',
            amountDeposit: 'Monto del depósito',
            transactionStatus: 'Estado de la transacción',
            paymentMethod: 'Método de pago',
            depositTo: 'Depositar en',
            depositAddress: 'Dirección de depósito',
            scanTheQr: 'Escanea el código QR o copia la dirección de depósito',
            wallet: 'Billetera',
            copyDepositAddress: 'Copiar dirección de depósito',
            copy: 'Copiar',
            addressCopied: 'Dirección copiada al portapapeles',
            '5min:': '5-10 minutos',
            '2-5days': '2-5 días / 5-10 minutos',
            otherMethod: 'Otro método',
            profileSettings: 'Configuración del perfil',
            savingsAccounts: 'Cuentas de ahorro',
            subscribe: 'Suscríbase',
            toCompleteVerification: 'Para completar el proceso de verificación de tu cuenta, por favor proporciona copias claras y legibles de los siguientes documentos.',
            amountToWithdrawal: 'Monto a retirar',
            cardDetails: 'Datos de la tarjeta',
            cardHolderName: 'Nombre del titular de la tarjeta',
            withdrawalSuccessful: 'Retiro exitoso',
            selectWithdrawalMethod: 'Selecciona el método de retiro',
            creditDebit: 'Tarjeta de crédito/débito',
            crypto: 'Cripto',
            cryptoWalletAddress: 'Dirección de billetera de criptomonedas',
            cryptoSubtitle: 'Retiros de cripto a tu billetera 🚀',
            bankWithdrawal: 'Retiro bancario',
            bankSubtitle: 'Cuenta bancaria 🏦',
            zelleSubtitle: 'Transferencias bancarias rápidas ⚡🏦',
            tradingResults: 'Resultados de trading',
            day: 'Día',
            month: 'Mes',
            noTradingData: 'No hay datos de trading disponibles',
            startTradingToSee: 'Comienza a operar para ver tus resultados aquí',
            yourPerformanceMetric: 'Tus métricas de rendimiento se mostrarán una vez que los datos estén disponibles.',
            favorite: 'Favorito',
            all: 'Todos',
            availableFullTime: 'Disponible 24/7',
            yourProfileAndSettings: 'Perfil y configuración',
            switchToNewDesignMessage: '¡Cambia ahora al nuevo diseño de nuestra plataforma!',
            switchToOldDesignMessage: 'Aún puedes utilizar el diseño clásico durante un tiempo limitado',
            switchToOldDesignButton: 'Cambia al nuevo diseño',
            switchToNewDesignButton: 'Volver al diseño clásico',
            generateInvoice: 'Generar factura',
            downloadPdf: 'Descargar PDF',
            confirmDeposit: 'Confirmar depósito',
            howCanIAssist: '¿Cómo puedo ayudarte hoy?',
            maxFileSize: 'El tamaño máximo de archivo es de 5 MB, formatos aceptados: JPEG, PNG o GIF',
            uploadPhoto: 'Subir foto',
            featuresDisabled: 'Esta función está deshabilitada',
            welcome: 'Bienvenido',
            signUp: 'Regístrate',
            registration: 'Registro',
            pleaseConfirmAgreement: 'Por favor, confirma el acuerdo del cliente',
            pleaseEnterPassword: 'Por favor, introduce una contraseña',
            passwordNotSame: 'Las contraseñas no coinciden',
            noActiveOrders: 'No hay pedidos activos',
            itLikeNoHaveActiveOrders: 'Parece que no tienes pedidos activos en este momento',
            cryptoWallet: 'Cartera cripto',
            bankCard: 'Banco/Tarjeta',
            selected: 'Seleccionado',
            amountWithdrawal: 'Monto retirado',
            withdrawalTo: 'Retiro a',
            governmentIssuedPhoto1: 'Documento de identidad con foto emitido por el gobierno (por ejemplo, pasaporte, licencia de conducir, tarjeta de identificación nacional)',
            governmentIssuedPhoto2: 'Permiso de residencia (si aplica)',
            governmentIssuedPhoto3: 'El documento debe ser válido (no caducado)',
            governmentIssuedPhoto4: 'El nombre completo, la fecha de nacimiento y la fotografía deben ser visibles.',
            uploadRecent1: 'Sube una factura reciente de servicios, estado de cuenta bancaria o contrato de alquiler emitido en los últimos 3 meses',
            uploadRecent2: 'Asegúrate de que tu nombre completo y dirección de residencia actual sean claramente visibles.',
            uploadCopy1: 'Sube una copia del anverso de tu tarjeta de crédito asegurándote de que los primeros seis y los últimos cuatro dígitos sean visibles',
            uploadCopy2: 'Por favor, cubre los dígitos del medio por seguridad',
            takeAndUpload1: 'Toma y sube una selfie sosteniendo tu identificación junto a tu rostro',
            takeAndUpload2: 'Asegúrate de que tanto tu rostro como la identificación sean claramente visibles',
            otherDocuments: 'Otros documentos',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Sube una imagen clara y legible de tu documento de Número de Seguro Social (SSN). Asegúrate de que todos los detalles sean visibles y no estén obstruidos.',
            frontSideSsnDocument: 'Anverso del documento SSN',
            answerSaved: 'Las respuestas han sido guardadas',
            cryptoWalletDetails: 'Detalles de la cartera de criptomonedas',
            bitcoinWalletAddress: 'Dirección de cartera Bitcoin',
            recovery: 'Recuperación',
            passwordRecovery: 'Recuperación de contraseña',
            searchCountry: 'Buscar país',
            questionnaire: 'Cuestionario',
            questionnaireText: 'Por favor, complete el cuestionario para verificar su identidad',
            sendAnswers: 'Enviar respuestas',
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Tomar ganancias y detener pérdida',
            takeProfit: 'Tomar ganancias',
            stopLoss: 'Detener la pérdida',
            updateOrder: 'Actualizar pedido',
        },
        balanceModal: {
            balance: 'Saldo',
            credit: 'Crédito',
            equity: 'Capital',
            practice: 'PRÁCTICA',
            real: 'VERDADERO',
            account: 'CUENTA',
            invested: 'Invertido',
            profit: 'Lucro',
            loss: 'Pérdida',
            margin: 'Margen',
            marginLevel: 'Nivel de margen',
            marginFree: 'Margen libre'
        },
        header: {
            cfd: 'CFD (Contratos por Diferencia)',
            platform: 'Plataforma',
            deposit: 'Depositar'
        },
        sideMenu: {
            marketWatch: 'Reloj de mercado',
            activeOrders: 'Pedidos Activos',
            tradingHistory: 'Historial de operaciones',
            economicCalendar: 'Calendario',
            marketNews: 'Noticias del mercado'
        },
        closePositionModal: {
            closeOrder: 'Cerrar Posición',
            areYouSure: '¿Está seguro de cerrar la posición?',
            buy: 'Comprar',
            sell: 'Vender',
            yes: 'Sí',
            no: 'No',
        },
        pciDssModal: {
            desc: `El cumplimiento del Estándar de Seguridad de Datos de la Industria de Tarjetas de Pago (PCI DSS) es obligatorio para todas las entidades que almacenan, procesan o transmiten datos de titulares de tarjetas Visa/Master Card/Stripe, incluidas las instituciones financieras, comerciantes y proveedores de servicios. 
            Es el estándar de seguridad global para todas las entidades que almacenan, procesan o transmiten datos de titulares de tarjetas y/o datos de autenticación sensibles. PCI DSS establece un nivel básico de protección para los consumidores y ayuda a reducir el fraude y las violaciones de datos en todo el ecosistema de pagos.`
        },
        chart: {
            addNewChart: 'Agregar nuevo gráfico',
        },
        symbolsModal: {
            watchlist: 'Lista de observación',
            asset: 'Activo',
            price: 'Precio',
            changePct: 'Cambio 24h'
        },
        pendingModal: {
            pending: 'Pendiente',
            assetPrice: 'Precios de los activos',
            current: 'Actual',
            revert: 'Volver al precio de mercado',
            automatically: 'La posición se abrirá automáticamente cuando el precio alcance este nivel'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volumen',
            lots: 'lotes',
            units: 'unidades',
            currency: 'divisa',
            contractSize: 'Tamaño del contrato',
            position: 'Posición',
            margin: 'Margen',
            freeMargin: 'Margen libre',
            takeProfitStopLoss: 'Tomar ganancias y detener pérdidas',
            pending: 'Pendiente',
            market: 'Mercado',
            leverage: 'apalancamiento',
            spread: 'Spread',
            notSet: 'No establecido',
            at: 'en',
            buy: 'Comprar',
            sell: 'Vender',
        },
        footer: {
            supportBanner: 'TODOS LOS DÍAS, LAS 24 HORAS',
            currentTime: 'TIEMPO ACTUAL',
            liveChat: 'Chat en vivo'
        },
        statusBar: {
            balance: 'Saldo',
            credit: 'Crédito',
            equity: 'Capital',
            margin: 'Margen',
            marginLevel: 'Nivel de margen',
            freeMargin: 'Margen libre',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Fecha de registro',
            userId: 'ID de usuario',
            dashboard: 'Dashboard',
            personalData: 'Información personal',
            deposit: 'Depositar',
            withdrawFunds: 'Retirar Fondos',
            savings: 'Ahorros',
            settings: 'Ajustes',
            logout: 'Cerrar sesión'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Hacer un depósito',
            practiceAccount: 'Cuenta de práctica',
            realAccount: 'Cuenta real',
            noWithdrawals: 'Sin retiros',
            easyWithdrawals: 'Retiros Fáciles',
            allAssets: 'Todos los activos disponibles',
            fullFledged: 'Plataforma completa',
            fillUpTo: 'Llenar hasta',
            freeReplenishment: 'Reposición gratuita',
            topUp: 'Recargue su cuenta',
            minimumAmount: 'Monto minimo',
            canSwitch: 'Usted puede cambiar entre sus cuentas en cualquier momento'
        },
        gridsModal: {
            chartGrids: 'Cuadrículas de gráficos',
            chart1: '1 gráfico',
            chart2: '2 gráficos',
            chart3: '3 gráficos',
            chart4: '4 gráficos',
        },
        noConnectionModal: {
            connectionLost: 'Se perdió la conexión con el servidor',
            retryNow: 'Reintentar ahora',
        },
        loginModal: {
            loginToTradeRoom: 'Iniciar sesión en Traderoom',
            email: 'Dirección de correo electrónico',
            enterEmail: 'Introduzca su correo electrónico',
        enterTwoFactor: 'Enter 2FA code',
            twoFactor: 'Código de 2FA(si está activado)',
            twoFactorAuth: 'Verificación en dos pasos',
            password: 'Contraseña',
            yourPassword: 'Su contraseña',
            signIn: 'Iniciar sesión',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Establecer nueva contraseña',
            newPassword: 'Nueva contraseña',
            confirmNewPassword: 'Confirmar nueva contraseña',
            setButton: 'Establecer'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Crear una cuenta nueva',
            email: 'Dirección de correo electrónico',
            firstName: 'Nombre',
            lastName: 'Apellido',
            country: 'País',
            phone: 'Teléfono',
            password: 'Contraseña',
            createAccount: 'Crear una cuenta',
            currency: 'Divisa',
            privacyAndPolicy: "Privacidad y Política",
            customerAgreement: 'Acuerdo con el cliente',

        },
        forgotPasswordModal: {
            forgotPassword: 'Ha olvidado su contraseña?',
            submitEmail: 'Envíe un correo electrónico utilizado para el registro, verifique su bandeja de entrada y siga las instrucciones provistas.',
            submit: 'Enviar'
        },
        notifications: {
            error: 'Error',
            success: 'Éxito',
            deposit: 'Depositar',
            withdrawal: 'Retirar',
            depositApproved: 'ha sido aprobado',
            depositDeclined: 'El depósito ha sido rechazado',
            withdrawalApproved: 'Retiro ha sido aprobado',
            withdrawalDeclined: 'Retiro ha sido rechazado'
        },
        markets: {
            favorites: 'Lista de seguimiento',
            all: 'Todos los símbolos',
            forex: 'Forex',
            stocks: 'Acciones',
            commodities: 'Mercancías',
            indices: 'Índices',
            crypto: 'Cripto',
            metals: 'Metales',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'BUY',
            profitCalculator: 'Calculadora de ganancias',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Reloj de mercado',
            search: 'Buscar...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volumen',
            margin: 'Margen',
            active: 'Activo',
            pending: 'Pendiente',
            activeOrders: 'Órdenes activas',
            portfolio: 'Cartera',
            allPositions: 'Todas las posiciones',
            noPositionsLine1: 'No tienes abierto',
            noPositionsLine2: 'Posiciones todavía',
            show: 'mostrar',
            more: 'mas',
            less: 'menos',
            purchaseTime: 'Tiempo de compra',
            closePosition: 'Cerrar posición',
            priceOpen: 'Precio de compra',
            priceSl: 'precio de parada de perdida',
            priceTp: 'precio de toma de ganancias',
            type: 'Dirección de posición',
            pnl: 'Beneficios/pérdidas',
            cancelOrder: 'Cancelar orden',
            orderType: 'Tipo de orden',
            cancelled: 'Cancelado',
            tradingHistory: 'Historial comercial',
            noHistoryLine1: 'No tienes ninguno',
            noHistoryLine2: 'Acuerdos cerrados todavía',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendario'
        },
        widgetMarketNews: {
            marketNews: 'Noticias de mercado'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Ordenes activas',
            ordersHistory: 'Historial de ordenes',
            id: 'ID',
            symbol: 'Símbolo',
            type: 'Tipo',
            volume: 'Volumen',
            openPrice: 'Precio de apertura',
            openTime: 'Tiempo de apertura',
            closePrice: 'Precio de cierre',
            closeTime: 'Tiempo de cierre',
            sl: 'SL',
            tp: 'TP',
            price: 'Precio',
            pnl: 'PnL',
            actions: 'Acciones',
            edit: 'Editar',
            close: 'Cerrar',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Session Change',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'Operaciones por día:',
            desc: `Operar con CFDs y otros productos apalancados puede generar pérdidas. Antes de operar, los clientes deben leer las declaraciones de riesgos pertinentes en nuestra página de divulgación de riesgos. El trading automatizado no garantiza resultados. La empresa no se hace responsable de la pérdida de fondos en el trading automático. Asegúrese de comprender completamente los riesgos y de tomar medidas para gestionarlos.`,
            accept: 'Accept',
            save: 'Guardar',
            cancel: 'Cancelar',
        },
        cardBox: {
            name: 'Nombre',
            cardNumber: 'Número de tarjeta',
            expiration: 'Caducidad (mm/aa)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Comercio",
            startTrading: 'Comenzar a operar',
            deposit: 'Hacer un depósito',
            dashboard: 'Dashboard',
            personalInfo: 'Actualizar datos personales',
            contactInfo: 'Información de contacto',
            withdrawal: 'Solicitar un Retiro',
            verification: 'Verificación de identidad',
            accounts: 'Cuentas de administración',
            liveChat: 'Chat en vivo',
            savings: 'Ahorros',
            referrals: 'Programa de referidos',
            settings: 'Configuración de la plataforma',
            logOut: 'Cerrar sesión',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'HACER UN DEPÓSITO',
            instant: 'Instante',
            minutes: 'minutos',
            hours: 'horas',
            days: 'días',
            amount: 'Monto',
            continue: 'Continuar',
            qrCode: 'CÓDIGO QR',
            depositAddress: 'DIRECCIÓN DE DEPÓSITO',
            copy: 'Copiar',
            last: 'ÚLTIMOS',
            deposits: 'DEPÓSITOS',
            time: 'Hora',
            currency: 'Divisa',
            status: 'Estado',
            info: 'Información',
        },
        fullScreenCcExt: {
            pleaseWait: 'Espere por favor. Conectando con el proveedor de servicios de pago...',
            cardPayment: 'Pagar con tarjeta',
            inOrder: 'Para enviar el importe',
            pleaseProvide: 'por favor proporcione los detalles de la tarjeta:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'Aún no hay pedido',
            totalBalance: 'Saldo Total',
            totalPnl: 'PnL Total',
            profitableOrders: 'Pedidos Rentables',
            roi: 'ROI',
            activityLog: 'Registro de Actividad',
            loginFromIp: 'Login from IP',
            tradingResults: 'Resultados de Negocios',
            week: 'Semana',
            month: 'Mes',
            year: 'Año',
            successRate: 'Tasa de Éxito',
            closedWithProfit: 'Cerrado con Beneficio',
            closedWithLoss: 'Cerrado con Pérdida',
            account: 'Cuenta',
            balance: 'Saldo',
            leverage: 'Apalancamiento',
            credit: 'Crédito',
            tradeNow: 'Negociar Ahora',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Depósitos totales',
            totalWithdrawals: "Retiros totales",
            returnOnInvestment: "Retorno de la inversión"
        },
        fullScreenPersonal: {
            personalDetails: 'Detalles personales',
            profilePhoto: 'Foto de perfil',
            firstName: 'Nombre',
            country: 'País',
            lastName: 'Apellido',
            address: 'Dirección',
            email: 'E-mail',
            phone: 'Número de teléfono',
            saveChanges: 'Guardar cambios',
            state: 'Ciudad',
            postcode: 'Código postal',
            created: "Cuenta creada el",
        },
        fullScreenAvatar: {
            dropFile: 'Soltar un archivo en el círculo de arriba para subirlo',
            notAllowed: 'No está permitido publicar',
            notAllowedLine1: 'Fotos de carácter explícitamente sexual o pornográfico',
            notAllowedLine2: 'Imágenes destinadas a incitar al odio o la agresión étnica o racial',
            notAllowedLine3: 'Fotos de personas menores de 18 años',
            notAllowedLine4: 'Fotos protegidas por derechos de autor de terceros',
            notAllowedLine5: 'Imágenes de más de 5 MB y en un formato que no sea JPG, GIF o PNG',
            requirements: 'Su cara debe ser claramente visible en la foto. Todas las fotos y videos subidos por usted deben cumplir con estos requisitos, de lo contrario, pueden ser eliminados.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'SOLICITE UNA NUEVA RETIRADA',
            amount: 'Cantidad',
            currentBalance: 'El saldo de su cuenta corriente es',
            withdrawAll: 'Retirar todo',
            requestWithdrawal: 'Solicitar retiro',
            last: 'ÚLTIMO',
            withdrawalRequests: 'SOLICITUDES DE RETIRAR',
            time: 'Tiempo',
            currency: 'Moneda',
            info: 'Información',
            status: 'Estado',
            bank: 'Banco',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Tarjeta',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Detalles del retiro',
            address: 'Dirección de la billetera',
            bankDetails: 'Detalles bancarios',
            bankAccount: 'Número de cuenta',
            bankHolder: 'Nombre del titular de la cuenta',
            bankIban: 'IBAN',
            bankSwift: 'Código SWIFT bancario',
            recipientAddress: 'Dirección del destinatario',
            routingNumber: 'Número de ruta bancaria',
            bankBeneficiary: 'Nombre del banco del beneficiario',
            bankAddress: 'Dirección del banco',
            bankCode: 'Código bancario (BSB)',
            desc: 'Descripción',
            cardNumber: 'Número de tarjeta',
            cardHolder: 'Nombre del titular de la tarjeta',
        },
        fullScreenVerification: {
            dragAndDrop: 'Arrastrar y soltar el documento a esta área',
            documentsList: 'LISTA DE DOCUMENTOS SUBIDOS',
            document: 'Documento',
            timeUploaded: 'Tiempo subido',
            timeProcessed: 'Tiempo de procesamiento',
            status: 'Estado',
            types: ['Prueba de identificación', 'Prueba de residencia', 'Parte frontal de tarjeta de crédito', 'Parte trasera de tarjeta de crédito', 'Parte trasera de prueba de identificacion', 'Autofoto', 'Other'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Subir',
            isConfirmed: 'Está confirmado',
            uploadAnotherDocument: 'Subir otro documento',
            isUnderReview: 'Está bajo revisió',
            questionnaire: 'Cuestionario',
            sendAnswers: 'Enviar respuestas',
            progressBarOf: 'of',
            progressBarText: 'of your documents have been uploaded and confirmed'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transferir fondos entre sus cuentas',
            transferFrom: 'De la cuenta',
            transferTo: 'A la cuenta',
            amount: 'Monto',
            makeTransfer: 'Hacer transferencia',
            yourAccounts: 'Sus Cuentas',
            account: 'Cuenta',
            currency: 'Divisa',
            balance: 'Saldo',
            credit: 'Crédito',
            makeActive: 'Activar',
            active: 'Activo'
        },
        fullScreenSupport: {
            sendMessage: 'Enviar mensaje'
        },
        fullScreenSavings: {
            pct_0: 'Flexible',
            pct_30: 'Bloqueado 1 mes',
            pct_90: 'Bloqueado 1 cuarto',
            pct_180: 'Bloqueado 6 meses',
            pct_365: 'Bloqueado 1 año',
            savings: 'Ahorros',
            detailedInformation: 'Información detallada',
            pleaseSelect: 'Seleccione una moneda y un período',
            openSavings: 'Ahorros abiertos',
            currentTime: 'Tiempo actual',
            releaseTime: 'Tiempo de lanzamiento',
            currencyChosen: 'Moneda elegida',
            periodChosen: 'Período elegido',
            yourRate: 'Tu tarifa',
            yourEarnings: 'Tus ganancias',
            accountFrom: 'Cuenta de la que se va transferir fondos',
            enterAmount: 'Introduzca la cantidad',
            in: 'V',
            openAndInvest: 'Abre una cuenta de ahorros e invierte',
            investment: 'Inversión',
            period: 'Período',
            daysTotal: 'Días totales',
            finalEarnings: 'Ganancias finales',
            created: 'Creado',
            daysElapsed: 'Días transcurridos',
            days: 'Días',
            earnings: 'Ganancias',
            fundsReleasedIn: 'Fondos liberados',
            claim: 'Reclamación',
            name: "Nombre",
        },
        fullScreenSettings: {
            updatePassword: 'ACTUALIZAR CONTRASEÑA',
            currentPassword: 'Contraseña actual',
            newPassword: 'Nueva contraseña',
            changePassword: 'Cambiar la contraseña',
            activationForGoogle: 'Código de activación para Google Authenticator',
            activateAndEnter: 'Active Authenticator e ingrese un código generado en el campo a continuación',
            qrCode: 'QR Código',
            activateProtection: 'Activar Protección de 2FA',
            protectionActive: 'La protección 2FA está activada',
            twoFactorCode: 'Código de 2FA',
            disableProtection: 'Desactivar protección de 2F',
            language: 'Idioma',
            dashboardCurrency: 'moneda del panel',
            confirmNewPassword: 'Confirmar nueva contraseña',
        },
        fullScreenReferrals: {
            yourLink: 'SU ENLACE DE REFERENCIA',
            referredFriends: 'AMIGOS RECOMENDADOS',
            id: 'ID',
            name: 'Nombre',
            registered: 'Registrado',
            level: 'Nivel',
            payout: 'Pagar',
            lastPayouts: 'ÚLTIMOS PAGOS DE REFERENCIA',
            time: 'Hora',
            amount: 'Monto',
            currency: 'Divisa',
            info: 'Información',
            referralDesc:"El programa de referidos está diseñado para recompensarte por expandir nuestra comunidad de valiosos inversionistas.",
            shareLink:"Comparte el enlace:",
            subtitle:"Proporciónale tu nombre, correo electrónico o ID de trading.",
            descSuccesfull:"Una vez que tu referido se registre con éxito, tu asesor financiero se pondrá en contacto con él para ayudarlo en sus primeros pasos de inversión de la manera más eficaz y profesional.",
            descTracked:"Todas sus contribuciones se rastrean bajo tu referencia."
        },
        months: {
            Jan: 'Enero',
            Feb: 'Febrero',
            Mar: 'Marzo',
            Apr: 'Abril',
            May: 'Mayo',
            Jun: 'Junio',
            Jul: 'Julio',
            Aug: 'Agosto',
            Sep: 'Septiembre',
            Oct: 'Octubre',
            Nov: 'Noviembre',
            Dec: 'Diciembre'
        },
        orderTypes: ['Comprar', 'Vender', 'Limite de compra', 'Límite de venta', 'Parada de compra', 'Parada de venta'],
        statuses: ['Pendiente', 'Aprobado', 'Declinado', 'Procesamiento'],
        errors: {
            NOT_FOUND: 'Solicitar error',
            FORBIDDEN: 'Acceso denegado.Vuelve a iniciar sesión,',
            SERVER_ERROR: 'La acción falló. Inténtalo de nuevo más tarde',
            INVALID_ACCOUNT: 'La sesión expiró.Vuelve a iniciar sesión,',
            INVALID_EMAIL: 'Dirección de correo electrónico no válida',
            INVALID_PHONE: 'Número de teléfono no válido',
            INVALID_COUNTRY: 'País no válido',
            INVALID_LOGIN: 'Credenciales de acceso invalidos',
            USER_BLOCKED: 'La cuenta está bloqueada. Póngase en contacto con el soporte técnico',
            USER_REGISTERED: 'El usuario con correo electrónico o número de teléfono introducido ya está registrado',
            INVALID_USER: 'No se encuentra al usuario',
            REJECTED: 'La operación no está permitida debido a las restricciones de la plataforma',
            INVALID_OLD_PASSWORD: 'La contraseña antigua no es válida',
            INVALID_SYMBOL: 'Símbolo comercial no válido',
            INVALID_SYMBOL_PRECISION: 'Precisión del símbolo no válida',
            INVALID_FRAME: 'Marco de gráfico no válido',
            INVALID_CURRENCY: 'Moneda no válida',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Tipo de operación de cuenta no válida',
            INSUFFICIENT_FUNDS: 'Fondos insuficientes',
            INVALID_WITHDRAWAL: 'No se encuentra la retirada',
            INVALID_STATUS: 'Estado no válido',
            INVALID_FILE: 'La extensión de archivo o archivo no válido es demasiado grande',
            INVALID_DOCUMENT: 'No se encuentra el documento',
            INVALID_NAME: 'Nombre no válido',
            INVALID_LOT_SIZE: 'Tamaño de lote no válido',
            INVALID_LOT_STEP: 'Escalón de lote no válido',
            INVALID_MIN_VOLUME: 'Volumen mínimo no válido',
            INVALID_MAX_VOLUME: 'Volumen máximo no válido',
            INVALID_GROUP: 'Grupo no válido',
            INVALID_SPREAD: 'Extienda no válida',
            REQUEST_TIMEOUT: 'La solicitud agotó el tiempo de espera. Inténtalo de nuevo más tarde',
            EXISTING_OPEN_ORDERS: 'Tienes un pedido abierto, primero cierra el pedido para este usuario y vuelve a intentarlo.',
            INVALID_TYPE: 'Tipo de orden no válido',
            INVALID_VOLUME: 'Volumen de pedidos no valido',
            INVALID_SL: 'Valor de perdida no valido',
            INVALID_TP: 'Valor de beneficio de toma no válido',
            INVALID_PRICE: 'Precio de pedido no válido',
            INVALID_EXPIRATION: 'Caducidad de orden no válida',
            NOT_ENOUGH_MARGIN: 'No hay suficiente margen',
            INVALID_ORDER: 'No se encuentra el pedido',
            MARKET_CLOSED: 'El comercio no está disponible. Por favor, inténtelo de nuevo',
            INVALID_BALANCE_TYPE: 'Tipo de operación de saldo no válido',
            INVALID_HASH: 'Hash no válido',
            HASH_EXPIRED: 'El enlace de restablecimiento de contraseña ha caducado. Por favor, solicite uno nuevo',
            INVALID_CODE: 'Código 2FA no válido',
            CHAT_DISABLED: 'Se ha cerrado el acceso al chat de soporte',
            WITHDRAWAL_NOT_ALLOWED: 'Se ha cerrado el acceso a los retiros',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later'
        },
        countries: {
            AF: 'Afganistán',
            AX: 'Islas Aland',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'Samoa Americana',
            AD: 'Andora',
            AO: 'Angola',
            AI: 'Anguila',
            AQ: 'Antarctida',
            AG: 'Antigua And Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaiyan',
            BS: 'Bahamas',
            BH: 'Barein',
            BD: 'Banglades',
            BB: 'Barbados',
            BY: 'Bielorrusia',
            BE: 'Belgica',
            BZ: 'Belice',
            BJ: 'Benin',
            BM: 'Islas Bermudas',
            BT: 'Butan',
            BO: 'Bolivia',
            BA: 'Bosnia y Herzegovin',
            BW: 'Botsuana',
            BV: 'Islas Bouvet',
            BR: 'Brasil',
            IO: 'Territorio Británico del Océano Indico',
            BN: 'Brunei Darassalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Camboya',
            CM: 'Camerun',
            CA: 'Canada',
            CV: 'Cabo Verde',
            KY: 'Islas Caiman',
            CF: 'República Centroafricana',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Isla de Navidad',
            CC: 'Islas de Cocos',
            CO: 'Colombia',
            KM: 'Comoras',
            CG: 'Congo',
            CD: 'República Dominicana',
            CK: 'Islas Cook',
            CR: 'Costa Rica',
            CI: 'Costa de Marfil',
            HR: 'Croacia',
            CU: 'Cuba',
            CY: 'Chipre',
            CZ: 'República Checa',
            DK: 'Dinamarca',
            DJ: 'Yibuti',
            DM: 'Dominica',
            DO: 'República Dominicana',
            EC: 'Ecuador',
            EG: 'Egipto',
            SV: 'El Salvador',
            GQ: 'Guinea Ecuatorial',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Etiopía',
            FK: 'Islas Malvinas',
            FO: 'Islas Faroe',
            FJ: 'Fiyi',
            FI: 'Finlandia',
            FR: 'Francia',
            GF: 'Guayana Francesa',
            PF: 'Polinesia Frances',
            TF: 'Territorios Franceses del Sur',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Alemania',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Grecia',
            GL: 'Groenlandia',
            GD: 'Granada',
            GP: 'Guadelupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernesey',
            GN: 'Guinea',
            GW: 'Guinea-Bisau',
            GY: 'Guayana',
            HT: 'Haiti',
            HM: 'Isla Mcdonald',
            VA: 'Estado de la Ciudad del Vaticano',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungría',
            IS: 'Islandia',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Irán',
            IQ: 'Irak',
            IE: 'Irlanda',
            IM: 'Isla del Hombre',
            IL: 'Israel',
            IT: 'Italia',
            JM: 'Jamaica',
            JP: 'Japón',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazaistan',
            KE: 'Kenia',
            KI: 'Kiribati',
            KR: 'Corea',
            KW: 'Kuwait',
            KG: 'Kirguistán',
            LA: 'República Democrática Popular de Laos',
            LV: 'Letonia',
            LB: 'Líbano',
            LS: 'Lesoto',
            LR: 'Liberia',
            LY: 'Jamahiriya Arabe Libia',
            LI: 'Liechtenstein',
            LT: 'Lituania',
            LU: 'Luxemburgo',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malauí',
            MY: 'Malasia',
            MV: 'Maldivas',
            ML: 'Malí',
            MT: 'Malta',
            MH: 'Islas Marshall',
            MQ: 'Martinica',
            MR: 'Mauritania',
            MU: 'Mauricio',
            YT: 'Mayotte',
            MX: 'México',
            FM: 'Micronesia',
            MD: 'Moldavia',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Marruecos',
            MZ: 'Mozambique',
            MM: 'Birmania',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Países Bajos',
            AN: 'Antillas Holandesas',
            NC: 'Nueva Caledonia',
            NZ: 'Nueva Zelanda',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Isla Norfolk',
            MP: 'Islas Marianas del Norte',
            NO: 'Noruega',
            OM: 'Omán',
            PK: 'Pakistán',
            PW: 'Palaos',
            PS: 'Territorio Palestino Ocupado',
            PA: 'Panama',
            PG: 'Papúa Nueva Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipinas',
            PN: 'Pitcairn',
            PL: 'Polonia',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Katar',
            RE: 'Reunion',
            RO: 'Rumania',
            RU: 'Rusia',
            RW: 'Ruanda',
            BL: 'San Bartolomé',
            SH: 'Santa Elena',
            KN: 'San Cristóbal y Nieves',
            LC: 'Santa Lucía',
            MF: 'San Martín',
            PM: 'San Pedro y Miquelon',
            VC: 'San Vicente y las Granadillas',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tomé And Principe',
            SA: 'Arabia Saudita',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leona',
            SG: 'Singapur',
            SK: 'Eslovaquia',
            SI: 'Eslovenia',
            SB: 'Islas Solomon',
            SO: 'Somalia',
            ZA: 'Sudáfrica',
            GS: 'Islas Georgias del Sur y Sandwich.',
            ES: 'España',
            LK: 'Sri Lanka',
            SD: 'Sudán',
            SR: 'Surinam',
            SJ: 'Svalbard And Jan Mayen',
            SZ: 'Suazilandia',
            SE: 'Suecia',
            CH: 'Suiza',
            SY: 'República Árabe Siria',
            TW: 'Taiwán',
            TJ: 'Tayikistán',
            TZ: 'Tanzania',
            TH: 'Tailandia',
            TL: 'Timor Oriental',
            TG: 'Ir',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad y Tobago',
            TN: 'Túnez',
            TR: 'Pavo',
            TM: 'Turkmenistán',
            TC: 'Islas Turcas y Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ucrania',
            AE: 'Emiratos Árabes Unidos',
            GB: 'Reino Unido',
            US: 'Estados Unidos',
            UM: 'Islas periféricas de Estados Unidos',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuata',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Islas Virgenes Británicas',
            VI: 'Islas Virgenes.',
            WF: 'Wallis y Futuna',
            EH: 'Sáhara Occidental',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabue'
        },
    },
    dk: {
        _name: 'Dansk',
        newDesign: {
            withdrawalRequests: 'Ingen anmodninger om udbetaling endnu',
            withdrawalRequestsText1: 'Du har ikke lavet nogen udbetalingsanmodninger indtil videre.',
            withdrawalRequestsText2: 'Når du starter en udbetaling, vises dine transaktionsdetaljer her',
            pdfAttachment: 'PDF-vedhæftning',
            agent: "Agent",
            closed: 'Lukket',
            pending: 'Afventer',
            approved: 'Godkendt',
            declined: 'Afvist',
            document: 'Dokument',
            frontSide: 'Forsiden',
            backSide: 'Bagside',
            address: 'Adresse',
            frontSideDocument: 'Forsiden af dokumentet',
            creditCard: 'Kreditkort',
            selfie: 'Selfie',
            other: 'Andet',
            noDocument: 'Ingen dokumenter uploadet endnu',
            amountDeposited: 'Indsat beløb',
            makeTransfer: 'Foretag Overførsel',
            otherDetails: 'Bestil detaljer',
            manualWallets: 'Krypto-tegnebøger',
            depositText: 'Indbetalingen behandles inden for 5-10 minutter',
            pendingBankTransfer: 'Du har en ventende bankoverførsel.',
            completeDeposit: 'Følg instruktionerne nedenfor for at gennemføre din indbetaling',
            downloadInvoice: 'Download fakturaen',
            payInvoice: 'Betal fakturaen i din bank',
            useYourBankText1: 'Brug din banks online eller offline betalingssystem til at betale fakturaen.',
            useYourBankText2: 'Sørg for, at betalingsoplysningerne er korrekte.',
            uploadReceipt: 'Upload bekræftelseskvitteringen',
            afterMakingPayment: 'Når du har betalt, skal du uploade din kvittering for at bekræfte indbetalingen',
            clickToUpload: 'Klik for at Uploade',
            orDrag: 'eller træk og slip',
            fileFormat: 'PDF, JPG, JPEG, PNG mindre end 10MB',
            waitForDeposit: 'Vent på bekræftelse af indbetaling',
            onceReceipt: 'Når kvitteringen er uploadet, kontrollerer og godkender vi din indbetaling.',
            paymentDetails: 'Betalingsoplysninger',
            nameOnCard: 'Navn på kort',
            cardNumber: 'Kortnummer',
            expirationDate: 'Udløbsdato',
            securityCode: 'Sikkerhedskode',
            continue: 'Fortsæt',
            viewAccounts: 'Se konti',
            continueWithdrawal: 'Fortsæt tilbagetrækning',
            howCanIAssist: 'Hvordan kan jeg hjælpe dig',
            accountBalance: 'Kontosaldo',
            accountBalanceText1: 'Hvorfor kan jeg ikke placere en handel?',
            accountsBalanceText2: 'Hvordan indbetaler jeg penge?',
            accountsBalanceText3: 'Jeg vil indsætte penge via Zelle',
            tradingActions: 'Handelsmuligheder',
            tradingActionsText1: 'Hvordan short-sælger jeg?',
            tradingActionsText2: 'Hvordan kan jeg annullere en handel?',
            tradingActionsText3: 'Hvad er minimum handelsbeløb?',
            marketInfo: 'Markedsinfo',
            marketInfoText1: 'Hvor kan jeg se markedstendenser?',
            marketInfoText2: 'Hvad er de største gevinster i dag?',
            securityAndSettings: 'Sikkerhed og indstillinger',
            securityAndSettingsText1: 'Hvordan ændrer jeg min adgangskode?',
            securityAndSettingsText2: 'Hvad er sikkerhedsfunktionerne?',
            securityAndSettingsText3: 'Hvordan opdaterer jeg mine kontooplysninger?',
            messageCopied: 'Besked kopieret til udklipsholder',
            addSymbol: 'Tilføj Symbol',
            iHaveRead: 'Jeg har læst og accepterer vilkårene og betingelserne',
            toggleToTurn: 'Skift for at slå AutoTrade til',
            selectTheRisk: 'Vælg risikoniveau',
            confirm: 'Bekræft',
            cancel: 'Annuller',
            chooseClosingTime: 'Vælg en lukketid',
            setOrderClosingTime: 'Indstil Lukketid For Ordre',
            closingTime: 'Lukketid',
            date: 'Dato',
            time: 'Tid',
            setCloseTime: 'Indstil lukketid',
            '15m': '15 minutter',
            '30m': '30 minutter',
            '1h': '1 time',
            custom: 'Brugerdefineret',
            verifyOtp: 'Bekræft din OTP',
            enterDigits: 'Indtast den 6-cifrede kode, der er sendt til din [e-mail/telefon]',
            submit: 'Indsend',
            order: 'Ordre',
            closedSuccessfully: 'blev lukket med succes',
            openSuccessfully: 'åbn med succes',
            deposit: 'depositum',
            withdrawal: 'tilbagetrækning',
            completeVerification: 'Fuldfør Verifikation',
            verification: 'Bekræftelse',
            avatar: 'Avatar',
            saveChanges: 'Gem Ændringer',
            darkMode: 'Mørk tilstand',
            dashboardCurrency: 'Dashboard Valuta',
            language: 'Sprog',
            twoFactor: '2-faktor Beskyttelse',
            googleTwoFactorIsActive: 'Google 2-faktor autentificering er aktiv',
            activateCodeForGoogle: 'Aktivér kode for Google Authenticator',
            ifYouWishDisableEnterCode: 'Hvis du ønsker at deaktivere beskyttelse, indtast koden fra autentificeringsappen',
            scanOrManuallyEnter: 'Scan eller indtast QR-koden manuelt i Google 2FA for at tilføje en token',
            copyCode: 'Kopier kode',
            qrCode: 'QR Kode',
            activateAndEnterAuthenticator: 'Aktivér Authenticator og indtast en genereret kode i feltet nedenfor',
            enterTheDigitsCodeGenerated: 'Indtast den 6-cifrede kode genereret',
            disableTwoFactor: 'Deaktiver To Faktor Beskyttelse',
            activateTwoFactor: 'Aktivér 2FA beskyttelse',
            fileAdded: 'Fil tilføjet med succes',
            theVerificationStatus: 'Verifikationsstatus kan ses på listen over uploadede dokumenter',
            confirmWithdrawal: 'Bekræft udbetaling',
            minimumWithdrawal: 'Minimum udbetalingsbeløb er',
            welcomeToTradingPlatform: 'Velkommen til handelsplatformen',
            signIn: 'Log Ind',
            activeAccount: 'Aktiv konto',
            save: 'Gemme',
            selectCurrencyAndPeriod: 'Vælg en valuta og en abonnementsperiode',
            savingsNotFound: 'Opsparingskonti Ikke Fundet',
            pleaseCheckInvestment: 'Tjek venligst dine investeringsoplysninger for at fortsætte',
            allPeriodInvestment: 'Investering i hele perioden',
            pct_0: 'Fleksibel',
            pct_30: '1 Måned',
            pct_60: '1 Kvartal',
            pct_90: '6 Måneder',
            pct_180: '6 Måneder',
            pct_365: '1  År',
            savingsCreated: 'Opsparingskonto oprettet med succes',
            transferDetails: 'Detaljer Om Overførslen',
            transferAmount: 'Overførselsbeløb',
            investmentDetails: 'Investeringsdetaljer',
            investment: 'Investering',
            Period: 'Periode',
            created: 'Oprettet',
            releaseTime: 'Udgivelsestidspunkt',
            viewSavingsAccounts: 'Se opsparingskonti',
            continueInvestment: 'Fortsæt med at investere',
            chooseSubscription: 'Vælg dit abonnement',
            selectCurrencyAndSubscription: 'Vælg en valuta og en abonnementsperiode for at fortsætte.',
            rate: 'Kurs',
            accountTransferFunds: 'Konto, der skal overføres penge fra',
            amount: 'Beløb',
            'in': 'in',
            openSavingsAndInvest: 'Åbn En Opsparingskonto Og Invester',
            strongSell: 'Stærkt salg',
            sell: 'Sælg',
            neutral: 'Neutral',
            buy: 'Køb',
            stringBuy: 'Stærkt køb',
            chooseCryptoWallet: 'Vælg en Kryptovaluta Tegnebog',
            chooseMethod: 'Vælg en metode',
            maximumDeposit: 'Maksimal indbetaling er',
            minimumDeposit: "Minimumsindskuddet er",
            pleaseCompleteAddress: 'Udfyld venligst din adresse',
            pleaseCompleteCity: 'Udfyld venligst din by',
            pleaseCompleteCountry: 'Udfyld venligst dit land',
            pleaseCompleteZip: 'Udfyld venligst dit postnummer',
            amountDeposit: 'Indskudt beløb',
            transactionStatus: 'Transaktionsstatus',
            paymentMethod: 'Betalingsmetode',
            depositTo: 'Indbetal til',
            depositAddress: 'Adresse På Indskud',
            scanTheQr: 'Scan QR-koden eller kopier indbetalingsadressen',
            wallet: 'Tegnebog',
            copyDepositAddress: 'Kopi af indbetalingsadresse',
            copy: 'Kopiér',
            addressCopied: 'Adresse kopieret til udklipsholder',
            '5min:': '5-10 min',
            '2-5days': '2-5 dage / 5-10 min',
            otherMethod: 'Anden metode',
            profileSettings: 'Profilindstillinger',
            savingsAccounts: 'Opsparingskonti',
            subscribe: 'Abonnér',
            toCompleteVerification: 'For at afslutte bekræftelsesprocessen for din konto skal du levere tydelige og læsbare kopier af følgende dokumenter',
            amountToWithdrawal: 'Beløb til udbetaling',
            cardDetails: 'Kortoplysninger',
            cardHolderName: 'Kortholderens navn',
            withdrawalSuccessful: 'Tilbagetrækning Vellykket',
            selectWithdrawalMethod: 'Vælg udbetalingsmetode',
            creditDebit: 'Kredit-/debetkort',
            crypto: 'Krypto',
            cryptoWalletAddress: 'Crypro-tegnebogens adresse',
            cryptoSubtitle: 'Krypto-udbetalinger til din tegnebog 🚀',
            bankWithdrawal: 'Bankhæving',
            bankSubtitle: 'Bankkonto 🏦',
            zelleSubtitle: 'Hurtige bankoverførsler ⚡🏦',
            tradingResults: 'Handelsresultater',
            day: 'Dag',
            month: 'Måned',
            noTradingData: 'Ingen handelsdata tilgængelige',
            startTradingToSee: 'Begynd at handle for at se dine resultater her.',
            yourPerformanceMetric: 'Dine præstationsmålinger vil blive vist, når data er tilgængelige.',
            favorite: 'Favorit',
            all: 'Alle',
            availableFullTime: 'Tilgængelig 24/7',
            yourProfileAndSettings: 'Din profil og dine indstillinger',
            switchToNewDesignMessage: 'Skift til Vores Nye Platformdesign Nu!',
            switchToOldDesignMessage: 'Du kan stadig bruge det klassiske design i en begrænset periode',
            switchToOldDesignButton: 'Skift til Nyt Design',
            switchToNewDesignButton: 'Gå Tilbage til Klassisk Design',
            generateInvoice: 'Generer faktura',
            downloadPdf: 'Download PDF',
            confirmDeposit: 'Bekræft indbetaling',
            maxFileSize: 'Den maksimale filstørrelse er 5 MB, og accepterede formater er JPEG, PNG eller GIF',
            uploadPhoto: 'Upload billede',
            featuresDisabled: 'Denne funktion er deaktiveret',
            welcome: 'Velkommen',
            signUp: 'Tilmeld dig',
            registration: 'Registrering',
            pleaseConfirmAgreement: 'Bekræft venligst kundeaftale',
            pleaseEnterPassword: 'Indtast venligst en adgangskode',
            passwordNotSame: 'Adgangskoderne er ikke ens',
            noActiveOrders: 'Ingen aktive ordrer',
            itLikeNoHaveActiveOrders: 'Det ser ud til, at du ikke har nogen aktive ordrer på nuværende tidspunkt',
            cryptoWallet: 'Kryptovaluta wallet',
            bankCard: 'Bank/Kort',
            selected: 'Valgt',
            amountWithdrawal: 'Udbetalt beløb',
            withdrawalTo: 'Udbetaling til',
            governmentIssuedPhoto1: 'Officielt udstedt billed-ID (fx pas, kørekort, nationalt ID-kort)',
            governmentIssuedPhoto2: 'Opholdstilladelse (hvis relevant)',
            governmentIssuedPhoto3: 'Dokumentet skal være gyldigt (ikke udløbet)',
            governmentIssuedPhoto4: 'Fulde navn, fødselsdato og billede skal være synlige.',
            uploadRecent1: 'Upload en nylig regning, kontoudtog eller lejekontrakt udstedt inden for de sidste 3 måneder',
            uploadRecent2: 'Sørg for, at dit fulde navn og din nuværende adresse er tydeligt synlige.',
            uploadCopy1: 'Upload en kopi af forsiden af dit kreditkort og sørg for, at de første seks og sidste fire cifre er synlige',
            uploadCopy2: 'Dæk venligst de midterste cifre for sikkerhed',
            takeAndUpload1: 'Tag og upload en selfie, hvor du holder dit ID ved siden af dit ansigt',
            takeAndUpload2: 'Sørg for, at både dit ansigt og ID’et er tydeligt synlige',
            otherDocuments: 'Andre dokumenter',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Upload et klart og læseligt billede af dit dokument med dit sociale sikringsnummer (SSN). Sørg for, at alle detaljer er synlige og ikke dækket.',
            frontSideSsnDocument: 'Forside af SSN-dokumentet',
            answerSaved: 'Svar er gemt',
            cryptoWalletDetails: 'Krypto-wallet detaljer',
            bitcoinWalletAddress: 'Bitcoin-wallet adresse',
            recovery: 'Genopretning',
            passwordRecovery: 'Gendannelse af adgangskode',
            searchCountry: 'Søg efter land',
            questionnaire: 'Spørgeskema',
            questionnaireText: 'Udfyld venligst spørgeskemaet for at bekræfte din identitet',
            sendAnswers: 'Send svar',
        },
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Tag overskud  & Stop Tab',
            takeProfit: 'Tag overskud',
            stopLoss: 'Stop Tab',
            updateOrder: 'Opdatering af ordre',
        },
        balanceModal: {
            balance: 'Balance',
            credit: 'Kredit',
            equity: 'Egenkapital',
            practice: 'PRAKTIK',
            real: 'REAL',
            account: 'KONTO',
            invested: 'Investeret',
            profit: 'Overskud',
            loss: 'Tab',
            margin: 'Margen',
            marginLevel: 'Margin-niveau',
            marginFree: 'Fri margen'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Depositum'
        },
        sideMenu: {
            marketWatch: 'Markedsovervågning',
            activeOrders: 'Aktive ordrer',
            tradingHistory: 'Handelshistorie',
            economicCalendar: 'Kalender',
            marketNews: 'Nyheder om markedet'
        },
        closePositionModal: {
            closeOrder: 'Luk position',
            areYouSure: 'Er du sikker på, at du vil lukke stillingen',
            buy: 'Køb',
            sell: 'Sælg',
            yes: 'Ja',
            no: 'Nej',
        },
        pciDssModal: {
            desc: `Overholdelse af Payment Card Industry Data Security Standard (PCI DSS) er påkrævet for alle enheder, der gemmer, behandler eller transmitterer data fra Visa/Master Card/Stripe kortindehavere, herunder finansielle institutioner, forhandlere og tjenesteudbydere. 
            Det er den globale sikkerhedsstandard for alle enheder, der gemmer, behandler eller transmitterer kortindehaverdata og/eller følsomme autentificeringsdata. PCI DSS fastlægger et grundlæggende beskyttelsesniveau for forbrugerne og hjælper med at reducere svindel og dataovertrædelser på tværs af hele betalingsekosystemet.`
        },
        chart: {
            addNewChart: 'Tilføj nyt diagram',
        },
        symbolsModal: {
            watchlist: 'Overvågningsliste',
            asset: 'Formue',
            price: 'Pris',
            changePct: 'Ændring 24 timer'
        },
        pendingModal: {
            pending: 'I vente',
            assetPrice: 'Aktivpris',
            current: 'Nuværende',
            revert: 'Tilbage til markedsprisen',
            automatically: 'Positionen vil blive åbnet automatisk, når prisen når dette niveau'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volumen',
            lots: 'partier',
            units: 'enheder',
            currency: 'valuta',
            contractSize: 'Kontraktens størrelse',
            position: 'Position',
            margin: 'Margen',
            freeMargin: 'Fri margen',
            takeProfitStopLoss: 'Tag overskud  & Stop Tab',
            pending: 'I vente',
            market: 'Marked',
            leverage: 'Løftestang',
            spread: 'Spred',
            notSet: 'Ikke indstillet',
            at: 'på',
            buy: 'Køb',
            sell: 'Sælg',
        },
        footer: {
            supportBanner: 'HVER DAG, DØGNET RUNDT',
            currentTime: 'AKTUEL TID',
            liveChat: 'Live Chat'
        },
        statusBar: {
            balance: 'Balance',
            credit: 'Kredit',
            equity: 'Egenkapital',
            margin: 'Margen',
            marginLevel: 'Margen-niveau',
            freeMargin: 'Fri margen',
            pnl: 'PnL',
            profitTotal: 'Livstid PnL'
        },
        accountModal: {
            dateRegistered: 'Dato for registrering',
            userId: 'Bruger ID',
            dashboard: 'Instrumentbræt',
            personalData: 'Personlige data',
            deposit: 'Depositum',
            withdrawFunds: 'Trække penge ud',
            savings: 'Opsparing',
            settings: 'Indstillinger',
            logout: 'Logout'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Foretag en indbetaling',
            practiceAccount: 'Praksiskonto',
            realAccount: 'Rigtig konto',
            noWithdrawals: 'No Withdrawals',
            easyWithdrawals: 'Nemme udbetalinger',
            allAssets: 'Alle disponible aktiver',
            fullFledged: 'En fuldgyldig platform',
            fillUpTo: 'Fyld op til',
            freeReplenishment: 'Gratis genopfyldning',
            topUp: 'Opfyld din konto',
            minimumAmount: 'Mindste beløb',
            canSwitch: 'Du kan skifte mellem dine konti når som helst'
        },
        gridsModal: {
            chartGrids: 'DIAGRAMMER',
            chart1: '1 diagram',
            chart2: '2 diagrammer',
            chart3: '3 diagrammer',
            chart4: '4 diagrammer',
        },
        noConnectionModal: {
            connectionLost: 'Forbindelsen med serveren er mistet',
            retryNow: 'Prøv igen nu',
        },
        loginModal: {
            loginToTradeRoom: 'Log ind på Traderoom',
            email: 'E-mail-adresse',
            enterEmail: 'Indtast din e-mail',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA-kode (hvis aktiveret)',
            twoFactorAuth: 'To-faktor-autentifikation',
            password: 'Adgangskode',
            yourPassword: 'Your password',
            signIn: 'Log ind',
            dontHaveAccount: 'Har du ikke en konto?',
            restore: 'Gendan',
            fullName: 'Fuldt navn',
            havePromo: 'Jeg har en promo-kode',
            promo: 'Promo-kode',
            login: 'Login',
            haveAccount: 'Har du allerede en konto?',
            hide: 'Skjul',
            goTo: 'Gå tilbage'
        },
        newPasswordModal: {
            setNewPassword: 'Indstil nyt kodeord',
            newPassword: 'Ny adgangskode',
            confirmNewPassword: 'Bekræft ny adgangskode',
            setButton: 'Indstil'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Opret en ny konto',
            email: 'E-mail-adresse',
            firstName: 'Fornavn',
            lastName: 'Efternavn',
            country: 'Land',
            phone: 'Telefon',
            password: 'Adgangskode',
            createAccount: 'Opret konto',
            currency: 'Valuta',
            privacyAndPolicy: "Privatliv og Politik",
            customerAgreement: 'Kundeaftale',

        },
        forgotPasswordModal: {
            forgotPassword: 'Har du glemt din adgangskode?',
            submitEmail: 'Send venligst en e-mail, der anvendes til registrering, tjek din indbakke og følg instruktionerne',
            submit: 'Indsend'
        },
        notifications: {
            error: 'Fejl',
            success: 'Succes',
            deposit: 'Depositum',
            withdrawal: 'Tilbagetrækning',
            depositApproved: 'er blevet godkendt',
            depositDeclined: 'Depositum er blevet afvist',
            withdrawalApproved: 'Tilbagetrækningen er blevet godkendt',
            withdrawalDeclined: 'Tilbagetrækning er blevet afvist'
        },
        markets: {
            favorites: 'Overvågningsliste', 
            all: 'Alle symboler',
            forex: 'Forex',
            stocks: 'Aktier',
            commodities: 'Råvarer',
            indices: 'Indekser',
            crypto: 'Crypto',
            metals: 'Metals',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'KØB',
            profitCalculator: 'Winstcalculator',
            sell: 'SÆLG',
            volume: 'Volumen',
            entryPrice: 'Indgangspris',
            takeProfit: 'Tag overskud',
            stopLoss: 'Stop Tab',
            maxPosition: 'Max Position',
            calculate: 'Beregn',
            calculationsFor: 'Beregninger for',
            leverage: 'Løftestang',
            requiredMargin: 'Nødvendig margen',
            profitFromTP: 'Overskud fra TP',
            lossFromSL: 'Tab fra SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Depositum lykkedes',
            text: 'Din indbetaling er blevet behandlet med succes!',
        },
        depositFailModal: {
            title: 'Depositum mislykkedes',
            text: 'Din indbetaling er ikke blevet behandlet.',
        },
        widgetMarketWatch: {
            marketWatch: 'Markedsovervågning',
            search: 'Søg på...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volumen',
            margin: 'Margen',
            active: 'Aktiv',
            pending: 'I vente',
            activeOrders: 'Aktive ordrer',
            portfolio: 'Portefølje',
            allPositions: 'Alle stillinger',
            noPositionsLine1: 'Du har ingen åbne',
            noPositionsLine2: 'stillinger endnu',
            show: 'Vis',
            more: 'mere',
            less: 'mindre',
            purchaseTime: 'Tidspunkt for køb',
            closePosition: 'Luk position',
            priceOpen: 'Købspris',
            priceSl: 'Stop Loss-pris',
            priceTp: 'Take Profit Pris',
            type: 'Position Retning',
            pnl: 'Overskud/tab',
            cancelOrder: 'Annuller bestilling',
            orderType: 'Bestillingstype',
            cancelled: 'Annulleret',
            tradingHistory: 'Handelshistorie',
            noHistoryLine1: 'Du har ikke nogen',
            noHistoryLine2: 'endnu ikke indgået aftaler',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Kalender'
        },
        widgetMarketNews: {
            marketNews: 'Nyheder om markedet'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Aktive ordrer',
            ordersHistory: 'Ordrehistorik',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Type',
            volume: 'Volumen',
            openPrice: 'Åben pris',
            openTime: 'Åben tid',
            closePrice: 'Luk pris',
            closeTime: 'Luk tid',
            sl: 'SL',
            tp: 'TP',
            price: 'Pris',
            pnl: 'PnL',
            actions: 'Foranstaltninger',
            edit: 'Rediger',
            close: 'Luk',
            commission: 'Kommissionen',
            swap: 'Byt',
            swapLong: 'Bytte lang',
            swapShort: 'Bytte kort'
        },
        assetInfoModal: {
            assetInfo: 'Oplysninger om aktiver',
            tradingConditions: 'Handelsbetingelser',
            information: 'Ændring af session',
            bid: 'Bud',
            ask: 'Spørg',
            sessionChange: 'Ændring af session',
            tradeNow: 'Handel nu',
            opens: 'Åbner',
            closes: 'Lukker',
            at: 'på',
            open247: 'Åben 24/7',
            today: 'i dag',
            tomorrow: 'i morgen',
            sunday: 'Søndag',
            monday: 'Mandag',
            tuesday: 'Tirsdag',
            wednesday: 'Onsdag',
            thursday: 'Torsdag',
            friday: 'Fredag',
            saturday: 'Lørdag',
            contractSpecification: 'Kontraktspecifikation',
            symbol: 'Symbol',
            name: 'Navn',
            market: 'Marked',
            baseCurrency: 'Basisvaluta',
            digits: 'Cifre',
            lotSize: 'Størrelse af parti',
            lotStep: 'Parti trin',
            minVolume: 'Min Volumen',
            maxVolume: 'Max Volumen',
            leverage: 'Løftestang',
            commission: 'Kommissionen',
            swapLong: 'Bytte lang',
            swapShort: 'Bytte kort',
            schedule: 'Tidsplan',
            weekday: 'Hverdag',
            tradingTime: 'Handelstid',
            closed: 'lukket',
            sell: 'Sælg',
            buy: 'Køb',
            low: 'Lav',
            high: 'Høj',
            oneHourChange: '1 times ændring',
            oneDayChange: '1 dags ændring',
            oneWeekChange: '1 uges ændring',
            oneMonthChange: '1 måneds ændring',
            threeMonthsChange: '3 måneders ændring',
            oneYearChange: '1 års ændring',
            loading: 'Indlæsning...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'Handler pr. dag:',
            desc: `Handel med CFD'er og andre gearede produkter kan føre til tab. Før handel bør kunder læse de relevante risikoudtalelser på vores risikodisclosureside. Automatiseret handel garanterer ikke resultater. Virksomheden påtager sig intet ansvar for tab af midler i automatisk handel. Sørg for, at du fuldt ud forstår risiciene og træffer foranstaltninger til at håndtere dem.`,
            accept: 'Accept',
            save: 'Gem',
            cancel: 'Annuller',

        },
        cardBox: {
            name: 'Navn',
            cardNumber: 'Kortnummer',
            expiration: 'Udløb (mm/åå)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Handel",
            startTrading: 'Start handel',
            deposit: 'Depositum',
            dashboard: 'Instrumentbræt',
            personalInfo: 'Personlig information',
            contactInfo: 'Kontaktinformation',
            withdrawal: 'Tilbagetrækning',
            verification: 'Verifikation',
            accounts: 'Regnskaber',
            liveChat: 'Live Chat',
            savings: 'Opsparing',
            referrals: 'Henvisningsprogram',
            settings: 'Indstillinger',
            logOut: 'Log ud',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'GØR ET INDBETALING',
            instant: 'Øjeblikkelig',
            minutes: 'minutter',
            hours: 'timer',
            days: 'dage',
            amount: 'Beløb',
            continue: 'Fortsæt',
            qrCode: 'QR CODE',
            depositAddress: 'INDBETALINGSADRESSE',
            copy: 'Kopier',
            last: 'SIDSTE',
            deposits: 'INDBETALINGER',
            time: 'Tid',
            currency: 'Valuta',
            status: 'Status',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Vent venligst. Oprettelse af forbindelse til udbyder af betalingstjenester...',
            cardPayment: 'Card payment',
            inOrder: 'For at sende beløbet',
            pleaseProvide: 'Angiv venligst kortoplysninger:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'Ingen ordre endnu',
            totalBalance: 'Samlet saldo',
            totalPnl: 'Samlet PNL',
            profitableOrders: 'Rentable ordrer',
            roi: 'ROI',
            activityLog: 'Aktivitetslogbog',
            loginFromIp: 'Login fra IP',
            tradingResults: 'Handelsresultater',
            week: 'Uge',
            month: 'Måned',
            year: 'År',
            successRate: 'Succesrate',
            closedWithProfit: 'Lukket med overskud',
            closedWithLoss: 'Lukket med tab',
            account: 'Konto',
            balance: 'Balance',
            leverage: 'Løftestang',
            credit: 'Kredit',
            tradeNow: 'Handel nu',
            usingCurrentRate: 'ved anvendelse af den aktuelle valutakurs',
            totalDeposits: 'Samlede indskud',
            totalWithdrawals: "Samlede udbetalinger",
            returnOnInvestment: "Afkast af investering"
        },
        fullScreenPersonal: {
            personalDetails: 'Personlige oplysninger',
            profilePhoto: 'Profilbillede',
            firstName: 'Fornavn',
            country: 'Land',
            lastName: 'Efternavn',
            address: 'Adresse',
            email: 'E-mail',
            phone: 'Telefon nummer',
            saveChanges: 'Gem ændringer',
            state: 'By',
            postcode: 'Postnummer',
            created: "Konto oprettet den",
        },
        fullScreenAvatar: {
            dropFile: 'Smid en fil på cirklen ovenfor for at uploade',
            notAllowed: 'Det er ikke tilladt at offentliggøre',
            notAllowedLine1: 'Billeder af eksplicit seksuel eller pornografisk karakter',
            notAllowedLine2: 'Billeder, der har til formål at tilskynde til etnisk eller racemæssigt had eller aggression',
            notAllowedLine3: 'Billeder, der involverer personer under 18 år',
            notAllowedLine4: 'Ophavsretligt beskyttede fotos fra tredjeparter',
            notAllowedLine5: 'Billeder, der er større end 5 MB og i et andet format end JPG, GIF eller PNG',
            requirements: 'Dit ansigt skal være tydeligt synligt på billedet. Alle billeder og videoer, som du uploader, skal opfylde disse krav, ellers kan de blive fjernet.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Tilbagetrækning',
            requestNewWithdrawal: 'ANMODE OM EN NY UDBETALING',
            amount: 'Beløb',
            currentBalance: 'Din nuværende kontosaldo er',
            withdrawAll: 'Tilbagetrækning af alle',
            requestWithdrawal: 'Anmodning om tilbagetrækning',
            last: 'SIDSTE',
            withdrawalRequests: 'ANMODNINGER OM TILBAGETRÆKNING',
            time: 'Tid',
            currency: 'Valuta',
            info: 'Info',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Kort',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Oplysninger om tilbagetrækning',
            address: 'Tegnebogens adresse',
            bankDetails: 'Bankoplysninger',
            bankAccount: 'Kontonummer',
            bankHolder: 'Kontohaverens navn',
            bankIban: 'IBAN',
            bankSwift: 'Bankens SWIFT-kode',
            recipientAddress: 'Modtagerens adresse',
            routingNumber: 'Bankens routingnummer',
            bankBeneficiary: 'Modtagers banknavn',
            bankAddress: 'Bankens adresse',
            bankCode: 'Bankkode (BSB)',
            desc: 'Beskrivelse',
            cardNumber: 'Kortnummer',
            cardHolder: 'Kortindehaverens navn',
        },
        fullScreenVerification: {
            dragAndDrop: 'træk og slip dokumentet til dette område',
            documentsList: 'LISTE OVER UPLOADEDE DOKUMENTER',
            document: 'Dokument',
            timeUploaded: 'Tidspunkt uploadet',
            timeProcessed: 'Behandlet tid',
            status: 'Status',
            types: ['Bevis for id', 'Bevis for bopæl', 'Kreditkort forside', 'Kreditkort tilbage', 'Bevis for id tilbage', 'Selfie', 'Other'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Overfør',
            isConfirmed: 'er bekræftet',
            uploadAnotherDocument: 'Upload et andet dokument',
            isUnderReview: 'er under revision',
            questionnaire: 'Spørgeskema',
            sendAnswers: 'Send svar',
            progressBarOf: 'på',
            progressBarText: 'alle dine dokumenter er blevet uploadet og bekræftet'
        },
        fullScreenAccounts: {
            manageAccounts: 'Overfør penge mellem dine konti',
            transferFrom: 'Fra konto',
            transferTo: 'Til regnskab',
            amount: 'Beløb',
            makeTransfer: 'Foretag overførsel',
            yourAccounts: 'Dine konti',
            account: 'Konto',
            currency: 'Valuta',
            balance: 'Balance',
            credit: 'Kredit',
            makeActive: 'Gør aktiv',
            active: 'Aktiv'
        },
        fullScreenSupport: {
            sendMessage: 'Send besked'
        },
        fullScreenSavings: {
            pct_0: 'Fleksibel',
            pct_30: 'Låst 1 måned',
            pct_90: 'Låst 1 kvartal',
            pct_180: 'Låst 6 måneder',
            pct_365: 'Låst 1 år',
            savings: 'Opsparing',
            detailedInformation: 'Detaljerede oplysninger',
            pleaseSelect: 'Vælg venligst en valuta og en periode',
            openSavings: 'Åben opsparing',
            currentTime: 'Nuværende tid',
            releaseTime: 'Udgivelsestidspunkt',
            currencyChosen: 'Valgt valuta',
            periodChosen: 'Valgt periode',
            yourRate: 'Din pris',
            yourEarnings: 'Din indtjening',
            accountFrom: 'Konto til overførsel af midler fra',
            enterAmount: 'Indtast beløbet',
            in: 'På',
            openAndInvest: 'Åbn en opsparingskonto og invester',
            investment: 'Investering',
            period: 'Periode',
            daysTotal: 'Antal dage i alt',
            finalEarnings: 'Endelig indtjening',
            created: 'Oprettet',
            daysElapsed: 'Forløbne dage',
            days: 'dage',
            earnings: 'Indtjening',
            fundsReleasedIn: 'Midler frigivet i',
            claim: 'Krav',
            name: "Navn",
        },
        fullScreenSettings: {
            updatePassword: 'OPDATERE ADGANGSKODE',
            currentPassword: 'Nuværende adgangskode',
            newPassword: 'Nyt kodeord',
            changePassword: 'Ændre adgangskode',
            activationForGoogle: 'Aktiveringskode til Google Authenticator',
            activateAndEnter: 'Aktivér Authenticator, og indtast en genereret kode i feltet nedenfor',
            qrCode: 'QR-kode',
            activateProtection: 'Aktiver 2FA-beskyttelse',
            protectionActive: '2FA-beskyttelse er aktiv',
            twoFactorCode: '2FA-kode',
            disableProtection: 'Deaktivere 2FA-beskyttelse',
            language: 'Sprog',
            dashboardCurrency: 'Dashboard Valuta',
            confirmNewPassword: 'Bekræft ny adgangskode',
        },
        fullScreenReferrals: {
            yourLink: 'DIT HENVISNINGSLINK',
            referredFriends: 'HENVISTE VENNER',
            id: 'ID',
            name: 'Navn',
            registered: 'Registreret',
            level: 'Niveau',
            payout: 'Udbetaling',
            lastPayouts: 'SENESTE UDBETALINGER VED HENVISNING',
            time: 'Tid',
            amount: 'Beløb',
            currency: 'Valuta',
            info: 'Info',
            referralDesc:"Henvisningsprogrammet er designet til at belønne dig for at udvide vores fællesskab af værdifulde investorer.",
            shareLink:"Del linket:",
            subtitle:"Giv ham dit navn, e-mail eller handels-ID.",
            descSuccesfull:"Når din henvisning har registreret sig, vil din finansielle rådgiver kontakte ham for at hjælpe ham med hans første investeringsskridt på den mest effektive og professionelle måde.",
            descTracked:"Alle deres bidrag spores under din henvisning."
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Maj',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Køb', 'Salg', 'Køb Limit', 'Salg Limit', 'Køb Stop', 'Salg Stop'],
        statuses: ['Afventer', 'Godkendt', 'Afvist', 'Behandling'],
        errors: {
            NOT_FOUND: 'Fejl i anmodningen',
            FORBIDDEN: 'Adgang nægtet. Log venligst ind igen',
            SERVER_ERROR: 'Indsatsen mislykkedes. Prøv venligst igen senere',
            INVALID_ACCOUNT: 'Sessionen udløb. Log venligst ind igen',
            INVALID_EMAIL: 'Ugyldig e-mail-adresse',
            INVALID_PHONE: 'Ugyldigt telefonnummer',
            INVALID_COUNTRY: 'Ugyldigt land',
            INVALID_LOGIN: 'Ugyldige loginoplysninger',
            USER_BLOCKED: 'Kontoen er spærret. Kontakt venligst support',
            USER_REGISTERED: 'Brugeren med det indtastede e-mail eller telefonnummer er allerede registreret',
            INVALID_USER: 'Brugeren er ikke fundet',
            REJECTED: 'Drift er ikke tilladt på grund af platformsrestriktioner',
            INVALID_OLD_PASSWORD: 'Det gamle password er ugyldigt',
            INVALID_SYMBOL: 'Ugyldigt handelssymbol',
            INVALID_SYMBOL_PRECISION: 'Ugyldig symbolpræcision',
            INVALID_FRAME: 'Ugyldig diagramramme',
            INVALID_CURRENCY: 'Ugyldig valuta',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Ugyldig konto handelstype',
            INSUFFICIENT_FUNDS: 'Utilstrækkelige midler',
            INVALID_WITHDRAWAL: 'Tilbagetrækning er ikke fundet',
            INVALID_STATUS: 'Ugyldig status',
            INVALID_FILE: 'Ugyldig filudvidelse eller filen er for stor',
            INVALID_DOCUMENT: 'Dokumentet er ikke fundet',
            INVALID_NAME: 'Ugyldigt navn',
            INVALID_LOT_SIZE: 'Ugyldig partistørrelse',
            INVALID_LOT_STEP: 'Ugyldigt partitrin',
            INVALID_MIN_VOLUME: 'Ugyldig minimumsvolumen',
            INVALID_MAX_VOLUME: 'Ugyldig maksimal volumen',
            INVALID_GROUP: 'Ugyldig gruppe',
            INVALID_SPREAD: 'Ugyldig spredning',
            REQUEST_TIMEOUT: 'Anmodningen er udløbet. Prøv venligst igen senere',
            EXISTING_OPEN_ORDERS: 'Du har en åben ordre, luk først ordren for denne bruger og prøv igen.',
            INVALID_TYPE: 'Ugyldig bestillingstype',
            INVALID_VOLUME: 'Ugyldig ordremængde',
            INVALID_SL: 'Ugyldig stop loss-værdi',
            INVALID_TP: 'Ugyldig take profit-værdi',
            INVALID_PRICE: 'Ugyldig ordrepris',
            INVALID_EXPIRATION: 'Ugyldig ordreudløb',
            NOT_ENOUGH_MARGIN: 'Ikke tilstrækkelig margen',
            INVALID_ORDER: 'Ordren er ikke fundet',
            MARKET_CLOSED: 'Handel er ikke tilgængelig. Prøv venligst igen',
            INVALID_BALANCE_TYPE: 'Ugyldig type af balanceoperation',
            INVALID_HASH: 'Ugyldig hash',
            HASH_EXPIRED: 'Dit link til nulstilling af adgangskode er udløbet. Anmod venligst om et nyt',
            INVALID_CODE: 'Ugyldig 2FA-kode',
            CHAT_DISABLED: 'Adgang til supportchatten er blevet lukket',
            WITHDRAWAL_NOT_ALLOWED: 'Adgangen til tilbagekøb er blevet lukket',
            TRADING_DISABLED: 'Handel er deaktiveret for den løbende konto',
            PENDING_DEPOSITS_LIMIT: 'Kun én igangværende indbetaling er tilladt',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Kun én igangværende udbetaling er tilladt',
            LOCAL_DEPOSIT_ONLY_REAL: 'Indbetaling er kun tilgængelig for rigtige konti',
            LOCAL_DEPOSIT_POSITIVE: 'Indbetalingsbeløbet skal være over 0',
            LOCAL_INVALID_CARD_NUMBER: 'Ugyldigt kortnummer',
            LOCAL_INVALID_CARD_EXPIRATION: 'Ugyldigt kortudløb',
            LOCAL_INVALID_CVC: 'Ugyldig CVV/CVC-kode',
            LOCAL_PASSWORDS_NOT_MATCH: 'Adgangskoderne stemmer ikke overens, indtast venligst igen',
            LOCAL_NO_TRADING_ACCOUNTS: 'Du har ingen handelskonti til rådighed. Kontakt venligst supporten',
            LOCAL_MIN_DEPOSIT: 'Mindste indbetaling er $',
            LOCAL_MIN_WITHDRAWAL: 'Mindste udbetalingsbeløb er $',
            LOCAL_INVALID_FILE_TYPE: 'Filformat ikke understøttet. Vedhæft venligst kun JPG, PNG eller PDF',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later'
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland-øerne',
            AL: 'Albanien',
            DZ: 'Algeriet',
            AS: 'Amerikanske Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktis',
            AG: 'Antigua og Barbuda',
            AR: 'Argentina',
            AM: 'Armenien',
            AW: 'Aruba',
            AU: 'Australien',
            AT: 'Østrig',
            AZ: 'Aserbajdsjan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Hviderusland',
            BE: 'Belgien',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnien og Hercegovina',
            BW: 'Botswana',
            BV: 'Bouvet-øen',
            BR: 'Brasilien',
            IO: 'Britisk territorium i Det Indiske Ocean',
            BN: 'Brunei Darussalam',
            BG: 'Bulgarien',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodja',
            CM: 'Cameroun',
            CA: 'Canada',
            CV: 'Kap Verde',
            KY: 'Caymanøerne',
            CF: 'Den Centralafrikanske Republik',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'Kina',
            CX: 'Juleøen',
            CC: 'Cocos- (Keeling) øerne',
            CO: 'Colombia',
            KM: 'Comorerne',
            CG: 'Congo',
            CD: 'Congo, Den Demokratiske Republik',
            CK: 'Cookøerne',
            CR: 'Costa Rica',
            CI: 'Elfenbenskysten',
            HR: 'Kroatien',
            CU: 'Cuba',
            CY: 'Cypern',
            CZ: 'Tjekkiet',
            DK: 'Danmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Den Dominikanske Republik',
            EC: 'Ecuador',
            EG: 'Egypten',
            SV: 'El Salvador',
            GQ: 'Ækvatorialguinea',
            ER: 'Eritrea',
            EE: 'Estland',
            ET: 'Etiopien',
            FK: 'Falklandsøerne (Malvinas)',
            FO: 'Færøerne',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'Frankrig',
            GF: 'Fransk Guyana',
            PF: 'Fransk Polynesien',
            TF: 'Franske sydlige territorier',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgien',
            DE: 'Tyskland',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Grækenland',
            GL: 'Grønland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island og Mcdonald-øerne',
            VA: 'Den Hellige Stol (Vatikanstaten)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Ungarn',
            IS: 'Island',
            IN: 'Indien',
            ID: 'Indonesien',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irland',
            IM: 'Man-øen',
            IL: 'Israel',
            IT: 'Italien',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kasakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kirgisistan',
            LA: 'Den Demokratiske Folkerepublik Laos',
            LV: 'Letland',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libysk-Arabisk Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Litauen',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Makedonien',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldiverne',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshalløerne',
            MQ: 'Martinique',
            MR: 'Mauritanien',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Mikronesien',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongoliet',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morokko',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Nederlandene',
            AN: 'De Nederlandske Antiller',
            NC: 'Ny Kaledonien',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk-øen',
            MP: 'Nordmarianerne',
            NO: 'Norge',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palæstinensisk område, besat',
            PA: 'Panama',
            PG: 'Papua Ny Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filippinerne',
            PN: 'Pitcairn',
            PL: 'Polen',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Rumænien',
            RU: 'Rusland',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts And Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre og Miquelon',
            VC: 'Saint Vincent og Grenadinerne',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome Og Principe',
            SA: 'Saudi Arabien',
            SN: 'Senegal',
            RS: 'Serbien',
            SC: 'Seychellerne',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakiet',
            SI: 'Slovenien',
            SB: 'Salomonøerne',
            SO: 'Somalia',
            ZA: 'Sydafrika',
            GS: 'Sydgeorgien og Sandwichøerne',
            ES: 'Spanien',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard og Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Sverige',
            CH: 'Schweiz',
            SY: 'Den Syriske Arabiske Republik',
            TW: 'Taiwan',
            TJ: 'Tadsjikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad Og Tobago',
            TN: 'Tunisien',
            TR: 'Turkiet',
            TM: 'Turkmenistan',
            TC: 'Turks- og Caicosøerne',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'Forenede Arabiske Emirater',
            GB: 'Det Forenede Kongerige',
            US: 'Forenede Stater',
            UM: 'United States Outlying Islands',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Jomfruøerne, Britiske',
            VI: 'Virgin Islands, U.S.',
            WF: 'Wallis Og Futuna',
            EH: 'Vestsahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    nl: {
        _name: 'Nederlandse taal',
        newDesign: {
            withdrawalRequests: 'Nog geen opnamemeldingen',
            withdrawalRequestsText1: 'U heeft nog geen opnamemeldingen gedaan.',
            withdrawalRequestsText2: 'Zodra u een opname start, verschijnen de transactiegegevens hier',
            pdfAttachment: 'PDF-bijlage',
            agent: 'Agent',
            closed: 'Gesloten',
            pending: 'In behandeling',
            approved: 'Goedgekeurd',
            declined: 'Afgewezen',
            document: 'Document',
            frontSide: 'Voorzijde',
            backSide: 'Achterzijde',
            address: 'Adres',
            frontSideDocument: 'Voorzijde van het document',
            creditCard: 'Kredietkaart',
            selfie: 'Selfie',
            other: 'Anders',
            noDocument: 'Nog geen documenten geüpload',
            amountDeposited: 'Gestort bedrag',
            makeTransfer: 'Maak over',
            otherDetails: 'Bestelgegevens',
            manualWallets: 'Crypto-wallets',
            depositText: 'De storting wordt binnen 5-10 minuten verwerkt',
            pendingBankTransfer: 'U heeft een lopende bankoverschrijving.',
            completeDeposit: 'Volg de onderstaande instructies om uw storting te voltooien',
            downloadInvoice: 'Download de factuur',
            payInvoice: 'Betaal de factuur bij uw bank',
            useYourBankText1: 'Gebruik het online of offline betalingssysteem van uw bank om de factuur te betalen.',
            useYourBankText2: 'Zorg ervoor dat de betalingsgegevens correct zijn.',
            uploadReceipt: 'Upload de betalingsbevestiging',
            afterMakingPayment: 'Upload uw betalingsbewijs om de storting te bevestigen',
            clickToUpload: 'Klik om te uploaden',
            orDrag: 'of sleep en zet neer',
            fileFormat: 'PDF, JPG, JPEG, PNG kleiner dan 10MB',
            waitForDeposit: 'Wacht op stortingsbevestiging',
            onceReceipt: 'Zodra het bewijs is geüpload, controleren en keuren we uw storting goed',
            paymentDetails: 'Betalingsdetails',
            nameOnCard: 'Naam op kaart',
            cardNumber: 'Kaartnummer',
            expirationDate: 'Vervaldatum',
            securityCode: 'Beveiligingscode',
            continue: 'Doorgaan',
            viewAccounts: 'Bekijk rekeningen',
            continueWithdrawal: 'Ga door met opname',
            accountBalance: 'Saldo',
            accountBalanceText1: 'Waarom kan ik geen transactie uitvoeren?',
            accountsBalanceText2: 'Hoe kan ik geld storten?',
            accountsBalanceText3: 'Ik wil geld storten via Zelle',
            tradingActions: 'Handelsacties',
            tradingActionsText1: 'Hoe doe ik aan short selling?',
            tradingActionsText2: 'Hoe kan ik een transactie annuleren?',
            tradingActionsText3: 'Wat is het minimale handelsbedrag?',
            marketInfo: 'Marktinformatie',
            marketInfoText1: 'Waar kan ik markttrends bekijken?',
            marketInfoText2: 'Wat zijn de grootste stijgers van vandaag?',
            securityAndSettings: 'Beveiliging en instellingen',
            securityAndSettingsText1: 'Hoe verander ik mijn wachtwoord?',
            securityAndSettingsText2: 'Wat zijn de beveiligingsfuncties?',
            securityAndSettingsText3: 'Hoe werk ik mijn accountinformatie bij?',
            messageCopied: 'Bericht gekopieerd naar klembord',
            addSymbol: 'Voeg symbool toe',
            iHaveRead: 'Ik heb gelezen en ga akkoord met de algemene voorwaarden',
            toggleToTurn: 'Schakel over om AutoTrade in te schakelen',
            selectTheRisk: 'Selecteer het risiconiveau',
            confirm: 'Bevestigen',
            cancel: 'Annuleren',
            chooseClosingTime: 'Kies een sluitingstijd',
            setOrderClosingTime: 'Stel de sluitingstijd van de bestelling in',
            closingTime: 'Sluitingstijd',
            date: 'Datum',
            time: 'Tijd',
            setCloseTime: 'Sluitingstijd instellen',
            '15m': '15 minuten',
            '30m': '30 minuten',
            '1h': '1 uur',
            custom: 'Aangepast',
            verifyOtp: 'Verifieer uw OTP',
            enterDigits: 'Voer de 6-cijferige code in die naar uw [e-mail/telefoon] is verzonden',
            submit: 'Verzenden',
            order: 'Order',
            closedSuccessfully: 'succesvol gesloten',
            openSuccessfully: 'succesvol geopend',
            deposit: 'storting',
            withdrawal: 'opname',
            completeVerification: 'Voltooi verificatie',
            verification: 'Verificatie',
            avatar: 'Avatar',
            saveChanges: 'Wijzigingen opslaan',
            darkMode: 'Donkere modus',
            dashboardCurrency: 'Dashboardvaluta',
            language: 'Taal',
            twoFactor: '2-factor beveiliging',
            googleTwoFactorIsActive: 'Google 2-factor authenticator is actief',
            activateCodeForGoogle: 'Activeer code voor Google Authenticator',
            ifYouWishDisableEnterCode: 'Als u de beveiliging wilt uitschakelen, voer de code in van de authenticator-app',
            scanOrManuallyEnter: 'Scan of voer de QR-code handmatig in bij Google 2FA om een token toe te voegen',
            copyCode: 'Kopieer code',
            qrCode: 'QR-code',
            activateAndEnterAuthenticator: 'Activeer de authenticator en voer een gegenereerde code in het veld hieronder in',
            enterTheDigitsCodeGenerated: 'Voer de gegenereerde 6-cijferige code in',
            disableTwoFactor: 'Schakel 2-factor beveiliging uit',
            activateTwoFactor: 'Activeer 2FA beveiliging',
            fileAdded: 'Bestand succesvol toegevoegd',
            theVerificationStatus: 'De verificatiestatus kan worden bekeken in de lijst met geüploade documenten',
            confirmWithdrawal: 'Bevestig opname',
            minimumWithdrawal: 'Het minimale opnamebedrag is',
            welcomeToTradingPlatform: 'Welkom op het handelsplatform',
            signIn: 'Inloggen',
            activeAccount: 'Actieve account',
            save: 'Opslaan',
            selectCurrencyAndPeriod: 'Selecteer een valuta en een abonnementsperiode',
            savingsNotFound: 'Geen spaarrekeningen gevonden',
            pleaseCheckInvestment: 'Controleer uw investeringsdetails om door te gaan',
            allPeriodInvestment: 'Investeringen voor alle periodes',
            pct_0: 'Flexibel',
            pct_30: '1 maand',
            pct_60: '1 kwartaal',
            pct_90: '6 maanden',
            pct_180: '6 maanden',
            pct_365: '1 jaar',
            savingsCreated: 'Spaarrekening succesvol aangemaakt',
            transferDetails: 'Overboekingsdetails',
            transferAmount: 'Overboekingsbedrag',
            investmentDetails: 'Investeringsdetails',
            investment: 'Investering',
            Period: 'Periode',
            created: 'Gemaakt',
            releaseTime: 'Vrijgavetijd',
            viewSavingsAccounts: 'Bekijk spaarrekeningen',
            continueInvestment: 'Doorgaan met investeren',
            chooseSubscription: 'Kies uw abonnement',
            selectCurrencyAndSubscription: 'Selecteer een valuta en een abonnementsperiode om door te gaan.',
            rate: 'Tarief',
            accountTransferFunds: 'Account om geld van over te maken',
            amount: 'Bedrag',
            'in': 'in',
            openSavingsAndInvest: 'Open een spaarrekening en investeer',
            strongSell: 'Sterke verkoop',
            sell: 'Verkoop',
            neutral: 'Neutraal',
            buy: 'Kopen',
            stringBuy: 'Sterke koop',
            chooseCryptoWallet: 'Kies een cryptocurrency-portemonnee',
            chooseMethod: 'Kies een methode',
            maximumDeposit: 'Maximale storting is',
            minimumDeposit: "Minimale storting is",
            pleaseCompleteAddress: 'Vul uw adres volledig in',
            pleaseCompleteCity: 'Vul uw stad volledig in',
            pleaseCompleteCountry: 'Vul uw land volledig in',
            pleaseCompleteZip: 'Vul uw postcode volledig in',
            amountDeposit: 'Gestort bedrag',
            transactionStatus: 'Transactiestatus',
            paymentMethod: 'Betalingsmethode',
            depositTo: 'Storten naar',
            depositAddress: 'Stortingsadres',
            scanTheQr: 'Scan de QR-code of kopieer het stortingsadres',
            wallet: 'Portemonnee',
            copyDepositAddress: 'Kopiëren',
            copy: 'Copy',
            addressCopied: 'Adres gekopieerd naar klembord',
            '5min:': '5-10 minuten',
            '2-5days': '2-5 dagen / 5-10 minuten',
            otherMethod: 'Andere methode',
            profileSettings: 'Profielinstellingen',
            savingsAccounts: 'Spaarrekeningen',
            subscribe: 'Abonneren',
            toCompleteVerification: 'Om het verificatieproces voor uw account te voltooien, dient u duidelijke en leesbare kopieën van de volgende documenten te verstrekken',
            amountToWithdrawal: 'Bedrag voor opname',
            cardDetails: 'Kaartgegevens',
            cardHolderName: 'Naam kaarthouder',
            withdrawalSuccessful: 'Opname succesvol',
            selectWithdrawalMethod: 'Selecteer opnamemethode',
            creditDebit: 'Credit-/debitkaart',
            crypto: 'Crypto',
            cryptoWalletAddress: 'Cryptowallet-adres',
            cryptoSubtitle: 'Crypto-opnames naar uw wallet 🚀',
            bankWithdrawal: 'Bankopname',
            bankSubtitle: 'Bankrekening 🏦',
            zelleSubtitle: 'Snelle bankoverschrijvingen ⚡🏦',
            tradingResults: 'Handelsresultaten',
            day: 'Dag',
            month: 'Maand',
            noTradingData: 'Geen handelsgegevens beschikbaar',
            startTradingToSee: 'Begin met handelen om hier uw resultaten te zien.',
            yourPerformanceMetric: 'Uw prestatiegegevens worden weergegeven zodra ze beschikbaar zijn.',
            favorite: 'Favoriet',
            all: 'Alles',
            availableFullTime: '24/7 beschikbaar',
            yourProfileAndSettings: 'Uw profiel en instellingen',
            switchToNewDesignMessage: 'Schakel nu over naar ons nieuwe platformontwerp!',
            switchToOldDesignMessage: 'Je kunt nog steeds het klassieke ontwerp gebruiken voor een beperkte tijd',
            switchToOldDesignButton: 'Schakel over naar het nieuwe ontwerp',
            switchToNewDesignButton: 'Terug naar het klassieke ontwerp',
            generateInvoice: 'Factuur genereren',
            downloadPdf: 'PDF downloaden',
            confirmDeposit: 'Storting bevestigen',
            howCanIAssist: 'Hoe kan ik u vandaag helpen?',
            maxFileSize: 'De maximale bestandsgrootte is 5 MB, toegestane formaten zijn JPEG, PNG of GIF',
            uploadPhoto: 'Foto uploaden',
            featuresDisabled: 'Deze functie is uitgeschakeld',
            welcome: 'Welkom',
            signUp: 'Aanmelden',
            registration: 'Registratie',
            pleaseConfirmAgreement: 'Bevestig alstublieft de klantenovereenkomst',
            pleaseEnterPassword: 'Voer alstublieft een wachtwoord in',
            passwordNotSame: 'Wachtwoorden komen niet overeen',
            noActiveOrders: 'Geen actieve bestellingen',
            itLikeNoHaveActiveOrders: 'Het lijkt erop dat u momenteel geen actieve bestellingen heeft',
            cryptoWallet: 'Cryptowallet',
            bankCard: 'Bank/Kaart',
            selected: 'Geselecteerd',
            amountWithdrawal: 'Bedrag opgenomen',
            withdrawalTo: 'Opname naar',
            governmentIssuedPhoto1: 'Door de overheid uitgegeven identiteitsbewijs met foto (bijvoorbeeld paspoort, rijbewijs, nationaal identiteitsbewijs)',
            governmentIssuedPhoto2: 'Verblijfsvergunning (indien van toepassing)',
            governmentIssuedPhoto3: 'Het document moet geldig zijn (niet verlopen)',
            governmentIssuedPhoto4: 'De volledige naam, geboortedatum en foto moeten zichtbaar zijn.',
            uploadRecent1: 'Upload een recente energierekening, bankafschrift of huurovereenkomst die in de afgelopen 3 maanden is uitgegeven',
            uploadRecent2: 'Zorg ervoor dat je volledige naam en huidige woonadres duidelijk zichtbaar zijn.',
            uploadCopy1: 'Upload een kopie van de voorkant van je creditcard, zorg ervoor dat de eerste zes en laatste vier cijfers zichtbaar zijn',
            uploadCopy2: 'Bedek de middelste cijfers voor de veiligheid',
            takeAndUpload1: 'Maak en upload een selfie terwijl je je ID naast je gezicht houdt',
            takeAndUpload2: 'Zorg ervoor dat zowel je gezicht als het ID duidelijk zichtbaar zijn',
            otherDocuments: 'Andere documenten',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Upload een duidelijke en leesbare afbeelding van uw document met uw sociale zekerheidsnummer (SSN). Zorg ervoor dat alle details zichtbaar en onbelemmerd zijn.',
            frontSideSsnDocument: 'Voorzijde van het SSN-document',
            answerSaved: 'Antwoorden zijn opgeslagen',
            cryptoWalletDetails: 'Details van de cryptowallet',
            bitcoinWalletAddress: 'Bitcoin-walletadres',
            recovery: 'Herstel',
            passwordRecovery: 'Wachtwoordherstel',
            searchCountry: 'Zoek land',
            questionnaire: 'Vragenlijst',
            questionnaireText: 'Vul alstublieft de vragenlijst in om uw identiteit te verifiëren',
            sendAnswers: 'Antwoorden verzenden',
        },
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Neem Winst & Stoppen Verlies',
            takeProfit: 'Neem Winst ',
            stopLoss: 'Stoppen Verlies',
            updateOrder: 'Bestelling Bijwerken',
        },
        balanceModal: {
            balance: 'Balans',
            credit: 'krediet',
            equity: 'Equity',
            practice: 'PRAKTIJK',
            real: 'ECHT',
            account: 'ACCOUNT',
            invested: 'Geïnvesteerd',
            profit: 'Winst',
            loss: 'Verlies',
            margin: 'marge',
            marginLevel: 'Onderhoudsmarge',
            marginFree: 'Vrij Marge'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Storting'
        },
        sideMenu: {
            marketWatch: 'Markt bekijken',
            activeOrders: 'Open Posities',
            tradingHistory: 'Handel Geschiedenis',
            economicCalendar: 'Kalender',
            marketNews: 'Markt Nieuws'
        },
        closePositionModal: {
            closeOrder: 'Gesloten positie ',
            areYouSure: 'Weet je zeker dat je de positie sluit?',
            buy: 'Kopen',
            sell: 'Verkopen',
            yes: 'Ja',
            no: 'Nee',
        },
        pciDssModal: {
            desc: `Naleving van de Payment Card Industry Data Security Standard (PCI DSS) is vereist voor alle entiteiten die Visa/Master Card/Stripe-kaarthoudergegevens opslaan, verwerken of verzenden, inclusief financiële instellingen, handelaren en dienstverleners. Het is de wereldwijde beveiligingsstandaard voor alle entiteiten die kaarthoudergegevens en/of gevoelige authenticatiegegevens opslaan, verwerken of verzenden. PCI DSS stelt een basisniveau van bescherming voor consumenten vast en helpt fraude en datalekken in het hele betalingsecosysteem te verminderen.`
        },
        chart: {
            addNewChart: 'Nieuwe grafiek toevoegen',
        },
        symbolsModal: {
            watchlist: 'Volglijst',
            asset: 'Bedrijfsmiddel',
            price: 'Prijs',
            changePct: 'verandering 24u'
        },
        pendingModal: {
            pending: 'Laden',
            assetPrice: 'Bedrijfsmiddel Prijs',
            current: 'Actueel',
            revert: 'Terug naar Markt Prijs',
            automatically: 'Positie wordt automatisch geopend wanneer de prijs dit niveau bereikt'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volume',
            lots: 'lots',
            units: 'units',
            currency: 'valuta',
            contractSize: 'Contractgrootte',
            position: 'Positie',
            margin: 'Marge',
            freeMargin: 'Vrij Marge',
            takeProfitStopLoss: 'Neem Winst & Stoppen Verlies',
            pending: 'Laden',
            market: 'Markt',
            leverage: 'Hefboom',
            spread: 'Verspreiding',
            notSet: 'Niet ingesteld',
            at: 'Bij',
            buy: 'kopen',
            sell: 'verkopen',
        },
        footer: {
            supportBanner: 'ELKE DAG, DE KLOK ROND',
            currentTime: 'ACTUEEL TIJD',
            liveChat: 'Live chatten'
        },
        statusBar: {
            balance: 'Balans',
            credit: 'Credit',
            equity: 'billijkheid',
            margin: 'Marge',
            marginLevel: 'Onderhoudsmarge',
            freeMargin: 'Vrij Marge',
            pnl: 'PNL',
            profitTotal: 'Winst totaal'
        },
        accountModal: {
            dateRegistered: 'Datum Geregistreerd',
            userId: 'Gebruiker ID',
            dashboard: 'Dashboard',
            personalData: 'Persoonlijke gegevens',
            deposit: 'Storting',
            withdrawFunds: 'Geld opnemen',
            savings: 'Spaargeld',
            settings: 'Instellingen',
            logout: 'Uitloggen'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Storting doen',
            practiceAccount: 'praktijk account',
            realAccount: 'Echte account',
            noWithdrawals: 'Geen opnames',
            easyWithdrawals: 'Gemakkelijke opnames',
            allAssets: 'Alle middelen beschikbaar',
            fullFledged: 'Full-ledged platform',
            fillUpTo: 'Fill Up To',
            freeReplenishment: 'Gratis aanvulling',
            topUp: 'Waardeer je account op',
            minimumAmount: 'Minimale bedrag',
            canSwitch: 'U kunt op elk moment tussen uw accounts schakelen'
        },
        gridsModal: {
            chartGrids: 'GRAFIEKRASTERS',
            chart1: '1 grafiek',
            chart2: '2 grafieken',
            chart3: '3 grafieken',
            chart4: '4 grafieken',
        },
        noConnectionModal: {
            connectionLost: 'Verbinding met de server is verbroken',
            retryNow: 'Probeer het nu opnieuw',
        },
        loginModal: {
            loginToTradeRoom: 'Inloggen op Traderoom',
            email: 'E-mailadres',
            enterEmail: 'Vul je e-mailadres in',
 enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA-code (indien ingeschakeld)',
            twoFactorAuth: 'Tweefactorauthenticatie',
            password: 'Wachtwoord',
            yourPassword: 'Uw wachtwoord',
            signIn: 'Log in',
            dontHaveAccount: 'Heb je geen account?',
            restore: 'Herstellen',
            fullName: 'Voor-en achternaam',
            havePromo: 'Ik heb een promotiecode',
            promo: 'Promotiecode',
            login: 'Login',
            haveAccount: 'Heb je al een account?',
            hide: 'Zich verstoppen',
            goTo: 'Ga naar terug'
        },
        newPasswordModal: {
            setNewPassword: 'Nieuw wachtwoord instellen',
            newPassword: 'Nieuw wachtwoord',
            confirmNewPassword: 'Bevestig nieuw wachtwoord',
            setButton: 'Set'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Maak een nieuw account aan',
            email: 'E-mailadres',
            firstName: 'Voornaam',
            lastName: 'Achternaam',
            country: 'Land',
            phone: 'Telefoon',
            password: 'Wachtwoord',
            createAccount: 'Maak account',
            currency: 'Valuta',
            privacyAndPolicy: "Privacy & Beleid",
            customerAgreement: 'Klantovereenkomst',

        },
        forgotPasswordModal: {
            forgotPassword: 'Wachtwoord vergeten?',
            submitEmail: 'Dien een e-mail in die is gebruikt voor registratie, controleer uw inbox en volg de instructies',
            submit: 'Indienen'
        },
        notifications: {
            error: 'Fout',
            success: 'Succes',
            deposit: 'Storting',
            withdrawal: 'Opname',
            depositApproved: 'is goedgekeurd',
            depositDeclined: 'Aanbetaling is geweigerd',
            withdrawalApproved: 'Opname goedgekeurd',
            withdrawalDeclined: 'Opname geweigerd'
        },
        markets: {
            favorites: 'Volglijst', 
            all: 'Alle symbolen',
            forex: 'Forex',
            stocks: 'Aandelen',
            commodities: 'Goederen',
            indices: 'Indices',
            crypto: 'Crypto',
            metals: 'Metalen',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'Kopen',
            sell: 'Verkopen',
            volume: 'Volume',
            entryPrice: 'Toegangs prijs',
            takeProfit: 'Winst nemen',
            stopLoss: 'Stop-Loss',
            maxPosition: 'Maximale positie',
            calculate: 'Berekenen',
            calculationsFor: 'Berekeningen voor',
            leverage: 'Hefboom',
            requiredMargin: 'Vereiste marge',
            profitFromTP: 'Profiteer van TP',
            lossFromSL: 'Verlies van SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Storting succesvol',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Storting mislukt',
            text: 'Uw storting is niet verwerkt.',
        },
        widgetMarketWatch: {
            marketWatch: 'Markt bekijken',
            search: 'Zoeken...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volume',
            margin: 'Margen',
            active: 'Actief',
            pending: 'Laden',
            activeOrders: 'Open posities',
            portfolio: 'Portefeuille',
            allPositions: 'Alle posities',
            noPositionsLine1: 'Je hebt geen open',
            noPositionsLine2: 'posities nog',
            show: 'Tonen',
            more: 'meer',
            less: 'minder',
            purchaseTime: 'Aankooptijd',
            closePosition: 'Positie sluiten',
            priceOpen: 'Aankoopprijs',
            priceSl: 'Stop Loss-prijs',
            priceTp: 'Winstprijs nemen',
            type: 'Positie Richting',
            pnl: 'Winst/verlies',
            cancelOrder: 'Annuleer bestelling',
            orderType: 'Order Type',
            cancelled: 'Geannuleerd',
            tradingHistory: 'Geschiedenis',
            noHistoryLine1: 'Je hebt er geen',
            noHistoryLine2: 'nog gesloten deals',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Kalender'
        },
        widgetMarketNews: {
            marketNews: 'Marktnieuws'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Open posities',
            ordersHistory: 'Bestelgeschiedenis',
            id: 'ID',
            symbol: 'Symbool',
            type: 'Type',
            volume: 'Volume',
            openPrice: 'Open prijs',
            openTime: 'Open tijd',
            closePrice: 'Sluit prijs',
            closeTime: 'Sluitingstijd',
            sl: 'SL',
            tp: 'TP',
            price: 'Prijs',
            pnl: 'PnL',
            actions: 'Acties',
            edit: 'Bewerken',
            close: 'Sluiten',
            commission: 'Commissie',
            swap: 'Swap',
            swapLong: 'Ruil lang',
            swapShort: 'Wissel kort'
        },
        assetInfoModal: {
            assetInfo: 'Activa-info',
            tradingConditions: 'Handelsvoorwaarden',
            information: 'Sessie wijzigen',
            bid: 'Bod',
            ask: 'Vragen',
            sessionChange: 'Sessie wijzigen',
            tradeNow: 'Nu handelen',
            opens: 'Opent',
            closes: 'Sluit',
            at: 'bij',
            open247: 'Open 24/7',
            today: 'Vandaag',
            tomorrow: 'Morgen',
            sunday: 'Zondag',
            monday: 'Maandag',
            tuesday: 'Dinsdag',
            wednesday: 'Woensdag',
            thursday: 'Donderdag',
            friday: 'Vrijdag',
            saturday: 'Zaterdag',
            contractSpecification: 'Contractspecificatie',
            symbol: 'Symbool',
            name: 'Naam',
            market: 'Markt',
            baseCurrency: 'Basisvaluta',
            digits: 'Cijfers',
            lotSize: 'Kavelgrootte',
            lotStep: 'Lot stap',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Hefboom',
            commission: 'Commissie',
            swapLong: 'Ruil lang',
            swapShort: 'Wissel kort',
            schedule: 'Schema',
            weekday: 'Weekdag',
            tradingTime: 'Handelstijd',
            closed: 'gesloten',
            sell: 'Verkopen',
            buy: 'Kopen',
            low: 'Laag',
            high: 'Hoog',
            oneHourChange: '1 uur wissel',
            oneDayChange: '1 dag verandering',
            oneWeekChange: '1 week wissel',
            oneMonthChange: '1 maand verandering',
            threeMonthsChange: '3 maanden verandering',
            oneYearChange: '1 jaar verandering',
            loading: 'Bezig met laden...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'Transacties per dag:',
            desc: `Handelen in CFD's en andere hefboomproducten kan leiden tot verliezen. Voordat u handelt, moeten klanten de relevante risicoverklaringen op onze risicobekendmakingspagina lezen. Geautomatiseerd handelen garandeert geen resultaten. Het bedrijf aanvaardt geen verantwoordelijkheid voor het verlies van fondsen bij geautomatiseerd handelen. Zorg ervoor dat u de risico's volledig begrijpt en maatregelen neemt om deze te beheren.`,
            accept: 'Accept',
            save: 'Opslaan',
            cancel: 'Annuleren',
        },
        cardBox: {
            name: 'Naam',
            cardNumber: 'Kaartnummer',
            expiration: 'Vervaldatum (mm/jj)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Handel",
            startTrading: 'Begin met handelen',
            deposit: 'Storting',
            dashboard: 'Dashboard',
            personalInfo: 'Persoonlijke informatie',
            contactInfo: 'Contactgegevens',
            withdrawal: 'Opname',
            verification: 'Verificatie',
            accounts: 'Rekeningen',
            liveChat: 'Live chatten',
            savings: 'Spaargeld',
            referrals: 'Verwijzingsprogramma',
            settings: 'Instellingen',
            logOut: 'Uitloggen',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'STORTING DOEN',
            instant: 'Instant',
            minutes: 'minuten',
            hours: 'uur',
            days: 'dagen',
            amount: 'Bedrag',
            continue: 'Doorgaan met',
            qrCode: 'QR CODE',
            depositAddress: 'BETALINGS ADRES',
            copy: 'Kopiëren',
            last: 'LAATSTE',
            deposits: 'DEPOSITO\'S',
            time: 'Tijd',
            currency: 'Valuta',
            status: 'Toestand',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Even geduld aub. Verbinding maken met betalingsdienstaanbieder...',
            cardPayment: 'Kaart betaling',
            inOrder: 'Om het bedrag te verzenden',
            pleaseProvide: 'geef kaartgegevens op:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'Nog geen bestelling',
            totalBalance: 'Eigen Vermogen',
            totalPnl: 'Totaal PNL',
            profitableOrders: 'Winstgevende posties',
            roi: 'ROI',
            activityLog: 'Activiteiten logboek',
            loginFromIp: 'Inloggen vanaf IP',
            tradingResults: 'Handelsresultaten',
            week: 'Week',
            month: 'Maand',
            year: 'Jaar',
            successRate: 'Slaagkans',
            closedWithProfit: 'Gesloten met winst',
            closedWithLoss: 'Gesloten met verlies',
            account: 'Account',
            balance: 'Balans',
            leverage: 'Hefboom',
            credit: 'Credit',
            tradeNow: 'Nu handelen',
            usingCurrentRate: 'met huidige wisselkoers',
            totalDeposits: 'Totale stortingen',
            totalWithdrawals: "Totale opnames",
            returnOnInvestment: "Rendement op investering"
        },
        fullScreenPersonal: {
            personalDetails: 'Persoonlijke gegevens',
            profilePhoto: 'Profielfoto',
            firstName: 'Voornaam',
            country: 'Land',
            lastName: 'Achternaam',
            address: 'Adres',
            email: 'E-mail',
            phone: 'Telefoonnummer',
            saveChanges: 'Wijzigingen opslaan',
            state: 'Stad',
            postcode: 'Postcode',
            created: "Account aangemaakt op",
        },
        fullScreenAvatar: {
            dropFile: 'Zet een bestand in de cirkel hierboven om te uploaden',
            notAllowed: 'Het is niet toegestaan ​​om te publiceren',
            notAllowedLine1: 'Foto\'s van expliciet seksuele of pornografische aard',
            notAllowedLine2: 'Afbeeldingen gericht op het aanzetten tot etnische of rassenhaat of agressie',
            notAllowedLine3: 'Foto\'s met personen jonger dan 18 jaar',
            notAllowedLine4: 'Auteursrechtelijk beschermde foto\'s van derden',
            notAllowedLine5: 'Afbeeldingen groter dan 5 MB en in een ander formaat dan JPG, GIF of PNG',
            requirements: 'Je gezicht moet duidelijk zichtbaar zijn op de foto. Alle door jou geüploade foto\'s en video\'s moeten aan deze eisen voldoen, anders kunnen ze worden verwijderd.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Uitbetalen',
            requestNewWithdrawal: 'EEN NIEUWE OPNAME AANVRAGEN',
            amount: 'Bedrag',
            currentBalance: 'Uw huidige rekeningsaldo is',
            withdrawAll: 'Alles uitlaten betalen',
            requestWithdrawal: 'Uitbetaling aanvragen',
            last: 'LAATSTE',
            withdrawalRequests: 'INTREKKING VERZOEKEN',
            time: 'Tijd',
            currency: 'Valuta',
            info: 'Info',
            status: 'Toestand',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Kaart',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Uitbetalingsdetails',
            address: 'Portemonnee-adres',
            bankDetails: 'Bankgegevens',
            bankAccount: 'Rekeningnummer',
            bankHolder: 'Naam rekeninghouder',
            bankIban: 'IBAN',
            bankSwift: 'SWIFT-code van de bank',
            recipientAddress: 'Adres van de ontvanger',
            routingNumber: 'Bankrouteringsnummer',
            bankBeneficiary: 'Naam van de begunstigde bank',
            bankAddress: 'Bankadres',
            bankCode: 'Bankcode (BSB)',
            desc: 'Beschrijving',
            cardNumber: 'Kaartnummer',
            cardHolder: 'Naam pashouder',
        },

        fullScreenVerification: {
            dragAndDrop: 'slepen en neerzetten van het document naar dit gebied',
            documentsList: 'LIJST MET GEUPLOAD DOCUMENTEN',
            document: 'Document',
            timeUploaded: 'Tijd geüpload',
            timeProcessed: 'Tijd verwerkt',
            status: 'Toestand',
            types: ['Bewijs van Id', 'Bewijs van Residentie', 'Creditcard voorzijde', 'Creditcard achterzijde', 'Bewijs van identiteitsbewijs achterzijde', 'Selfie', 'Other'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Uploaden',
            isConfirmed: 'bevestigd',
            uploadAnotherDocument: 'Nog een document uploaden',
            isUnderReview: 'wordt beoordeeld',
            questionnaire: 'Vragenlijst',
            sendAnswers: 'Send Answers',
            progressBarOf: 'van',
            progressBarText: 'Van uw documenten zijn geüpload en bevestigd'
        },
        fullScreenAccounts: {
            manageAccounts: 'Geld overboeken tussen uw rekeningen',
            transferFrom: 'Van account',
            transferTo: 'Verklaren',
            amount: 'Bedrag',
            makeTransfer: 'Overboeking maken',
            yourAccounts: 'Uw rekeningen',
            account: 'Account',
            currency: 'Valuta',
            balance: 'Balans',
            credit: 'Credit',
            makeActive: 'Maak actief',
            active: 'Actief'
        },
        fullScreenSupport: {
            sendMessage: 'Bericht versturen'
        },
        fullScreenSavings: {
            pct_0: 'Flexibel',
            pct_30: '1 maand vergrendeld',
            pct_90: '1 Kwart vergrendeld',
            pct_180: '6 maanden vergrendeld',
            pct_365: '1 jaar vergrendeld',
            savings: 'Besparingen',
            detailedInformation: 'Gedetailleerde informatie',
            pleaseSelect: 'Selecteer een valuta en een periode',
            openSavings: 'Spaargeld openen',
            currentTime: 'Huidige tijd',
            releaseTime: 'Vrijgavetijd',
            currencyChosen: 'Valuta gekozen',
            periodChosen: 'Periode gekozen',
            yourRate: 'Uw tarief',
            yourEarnings: 'Uw verdiensten',
            accountFrom: 'Rekening om geld van over te boeken',
            enterAmount: 'Voer een bedrag in',
            in: 'In',
            openAndInvest: 'Spaarrekening openen en beleggen',
            investment: 'Investering',
            period: 'Periode',
            daysTotal: 'Dagen totaal',
            finalEarnings: 'Eindinkomsten',
            created: 'gemaakt',
            daysElapsed: 'Verstreken dagen',
            days: 'dagen',
            earnings: 'Verdiensten',
            fundsReleasedIn: 'Fondsen vrijgegeven in',
            claim: 'Beweren',
            name: "Naam",
        },
        fullScreenSettings: {
            updatePassword: 'VERNIEUW WACHTWOORD',
            currentPassword: 'Huidig ​​wachtwoord',
            newPassword: 'Nieuw wachtwoord',
            changePassword: 'Verander wachtwoord',
            activationForGoogle: 'Activation code for Google Authenticator',
            activateAndEnter: 'Activeer Authenticator en voer een gegenereerde code in het onderstaande veld in',
            qrCode: 'QR Code',
            activateProtection: 'Activeer 2FA-beveiliging',
            protectionActive: '2FA-beveiliging is actief',
            twoFactorCode: '2FA-Code',
            disableProtection: 'Schakel 2FA-beveiliging uit',
            language: 'Taal',
            dashboardCurrency: 'Dashboardvaluta',
            confirmNewPassword: 'Bevestig nieuw wachtwoord',
        },
        fullScreenReferrals: {
            yourLink: 'JOUW VERWIJSLINK',
            referredFriends: 'VERWEZEN VRIENDEN',
            id: 'ID',
            name: 'Naam',
            registered: 'geregistreerd',
            level: 'Niveau',
            payout: 'Uitbetaling',
            lastPayouts: 'LAATSTE VERWIJZINGSUITBETALINGEN',
            time: 'Tijd',
            amount: 'Bedrag',
            currency: 'Valuta',
            info: 'Info',
            referralDesc:"Het referralprogramma is ontworpen om je te belonen voor het uitbreiden van onze gemeenschap van waardevolle investeerders.",
            shareLink: "Deel de link:",
            subtitle:"Geef hem je naam, e-mail of trading ID.",
            descSuccesfull:"Zodra je referral zich succesvol registreert, neemt je financieel adviseur contact met hem op om hem zo professioneel en effectief mogelijk te begeleiden in zijn investeringsreis.",
            descTracked:"Al zijn bijdragen worden gevolgd onder jouw referral."
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Mei',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Okt',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Kopen', 'Verkopen', 'Kooplimiet', 'Verkooplimiet', 'Koopstop', 'Verkoopstop'],
        statuses: ['In behandeling', 'Goedgekeurd', 'Geweigerd', 'Bezig met verwerken'],
        errors: {
            NOT_FOUND: 'Verzoek fout',
            FORBIDDEN: 'Toegang geweigerd. Gelieve opnieuw in te loggen',
            SERVER_ERROR: 'Actie: mislukt. Probeer het later opnieuw',
            INVALID_ACCOUNT: 'Sessie verlopen. Gelieve opnieuw in te loggen',
            INVALID_EMAIL: 'Ongeldig e-mailadres',
            INVALID_PHONE: 'Ongeldig telefoonnummer',
            INVALID_COUNTRY: 'Ongeldig land',
            INVALID_LOGIN: 'Ongeldige inloggegevens',
            USER_BLOCKED: 'Account is geblokkeerd. Neem contact op met ondersteuning',
            USER_REGISTERED: 'Gebruiker met ingevoerd e-mailadres of telefoonnummer is al geregistreerd',
            INVALID_USER: 'Gebruiker is niet gevonden',
            REJECTED: 'Bediening is niet toegestaan ​​vanwege platformbeperkingen',
            INVALID_OLD_PASSWORD: 'Oud wachtwoord is ongeldig',
            INVALID_SYMBOL: 'Ongeldig handelssymbool',
            INVALID_SYMBOL_PRECISION: 'Invalid symbol precision',
            INVALID_FRAME: 'Ongeldig kaartframe',
            INVALID_CURRENCY: 'Ongeldige valuta',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Ongeldig handelstype account',
            INSUFFICIENT_FUNDS: 'Onvoldoende middelen',
            INVALID_WITHDRAWAL: 'Opname niet gevonden',
            INVALID_STATUS: 'Ongeldige status',
            INVALID_FILE: 'Ongeldige bestandsextensie of bestand is te groot',
            INVALID_DOCUMENT: 'Document niet gevonden',
            INVALID_NAME: 'Ongeldige naam',
            INVALID_LOT_SIZE: 'Ongeldige partijgrootte',
            INVALID_LOT_STEP: 'Ongeldige partijstap',
            INVALID_MIN_VOLUME: 'Ongeldig minimaal volume',
            INVALID_MAX_VOLUME: 'Ongeldig maximaal volume',
            INVALID_GROUP: 'Ongeldige groep',
            INVALID_SPREAD: 'Ongeldige spreiding',
            REQUEST_TIMEOUT: 'Verzoek time-out. Probeer het later opnieuw',
            EXISTING_OPEN_ORDERS: 'U heeft een openstaande bestelling, sluit eerst de bestelling voor deze gebruiker en probeer het opnieuw.',
            INVALID_TYPE: 'Ongeldig ordertype',
            INVALID_VOLUME: 'Ongeldig bestelvolume',
            INVALID_SL: 'Ongeldige stop loss-waarde',
            INVALID_TP: 'Ongeldige winstwaarde',
            INVALID_PRICE: 'Ongeldige bestelprijs',
            INVALID_EXPIRATION: 'Ongeldige vervaldatum van bestelling',
            NOT_ENOUGH_MARGIN: 'Niet genoeg marge',
            INVALID_ORDER: 'Bestelling niet gevonden',
            MARKET_CLOSED: 'Handelen is niet mogelijk. Probeer het opnieuw',
            INVALID_BALANCE_TYPE: 'Ongeldig type saldobewerking',
            INVALID_HASH: 'Ongeldige hash',
            HASH_EXPIRED: 'De link voor het opnieuw instellen van uw wachtwoord is verlopen. Vraag een nieuwe aan',
            INVALID_CODE: 'Ongeldige 2FA-code',
            CHAT_DISABLED: 'Toegang tot de supportchat is gesloten',
            WITHDRAWAL_NOT_ALLOWED: 'Toegang tot de opnames is gesloten',
            TRADING_DISABLED: 'Handelen is uitgeschakeld voor lopende rekening',
            PENDING_DEPOSITS_LIMIT: 'Slechts één lopende storting toegestaan',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Slechts één lopende opname toegestaan',
            LOCAL_DEPOSIT_ONLY_REAL: 'Storting is alleen beschikbaar voor een echt account',
            LOCAL_DEPOSIT_POSITIVE: 'Het aanbetalingsbedrag moet meer dan 0 zijn',
            LOCAL_INVALID_CARD_NUMBER: 'Ongeldig kaart nummer',
            LOCAL_INVALID_CARD_EXPIRATION: 'Ongeldige kaart vervalt',
            LOCAL_INVALID_CVC: 'Ongeldige CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Wachtwoorden komen niet overeen, gelieve opnieuw in te voeren',
            LOCAL_NO_TRADING_ACCOUNTS: 'U heeft geen handelsaccounts beschikbaar. Neem contact op met de ondersteuning',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum opnamebedrag is $',
            LOCAL_INVALID_FILE_TYPE: 'Bestands formaat niet ondersteund. Gelieve alleen JPG, PNG of PDF bij te voegen',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later'
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland-eilanden',
            AL: 'Albanië',
            DZ: 'Algerije',
            AS: 'Amerikaans Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua En Barbuda',
            AR: 'Argentinië',
            AM: 'Armenië',
            AW: 'Aruba',
            AU: 'Australië',
            AT: 'Oostenrijk',
            AZ: 'Azerbeidzjan',
            BS: 'Bahamas',
            BH: 'Bahrein',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Wit-Rusland',
            BE: 'België',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnië en Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet-eiland',
            BR: 'Brazilië',
            IO: 'Brits Territorium in de Indische Oceaan',
            BN: 'Brunei Darussalam',
            BG: 'Bulgarije',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodja',
            CM: 'Kameroen',
            CA: 'Canada',
            CV: 'Kaapverdië',
            KY: 'Kaaimaneilanden',
            CF: 'Centraal-Afrikaanse Republiek',
            TD: 'Tsjaad',
            CL: 'Chili',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands',
            CO: 'Colombia',
            KM: 'Comoren',
            CG: 'Congo',
            CD: 'Congo, Democratische Republiek',
            CK: 'Cook Islands',
            CR: 'Costa Rica',
            CI: 'Ivoorkust',
            HR: 'Kroatië',
            CU: 'Cuba',
            CY: 'Cyprus',
            CZ: 'Tsjechië',
            DK: 'Denemarken',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominicaanse Republiek',
            EC: 'Ecuador',
            EG: 'Egypte',
            SV: 'El Salvador',
            GQ: 'Equatoriaal-Guinea',
            ER: 'Eritrea',
            EE: 'Estland',
            ET: 'Ethiopië',
            FK: 'Falklandeilanden (Malvinas)',
            FO: 'Faeröer Eilanden',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'Frankrijk',
            GF: 'Frans-Guyana',
            PF: 'Frans-Polynesië',
            TF: 'Franse zuidelijke gebieden',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgië',
            DE: 'Duitsland',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Griekenland',
            GL: 'Groenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haïti',
            HM: 'Heard Island & Mcdonald Islands',
            VA: 'Heilige Stoel (Vaticaanstad)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hongarije',
            IS: 'IJsland',
            IN: 'India',
            ID: 'Indonesië',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Ierland',
            IM: 'Isle Of Man',
            IL: 'Israëll',
            IT: 'Italië',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordanië',
            KZ: 'Kazachstan',
            KE: 'Kenia',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Koeweit',
            KG: 'Kirgizië',
            LA: 'Lao Democratische Volksrepubliek',
            LV: 'Letland',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libische Arabische Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Litouwen',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Macedonië',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Maleisië',
            MV: 'Malediven',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshalleilanden',
            MQ: 'Martinique',
            MR: 'Mauritanië',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesië',
            MD: 'Moldavië',
            MC: 'Monaco',
            MN: 'Mongolië',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morokko',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Nederland',
            AN: 'Nederlandse Antillen',
            NC: 'Nieuw-Caledonië',
            NZ: 'Nieuw-Zeeland',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolkeiland',
            MP: 'Noordelijke Marianen',
            NO: 'Noorwegen',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestijns grondgebied, bezet',
            PA: 'Panama',
            PG: 'Papoea-Nieuw-Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipijnen',
            PN: 'Pitcairn',
            PL: 'Polen',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Roemenië',
            RU: 'Rusland',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts En Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre En Miquelon',
            VC: 'Saint Vincent En Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tomé en Principe',
            SA: 'Saoedi-Arabië',
            SN: 'Senegal',
            RS: 'Servië',
            SC: 'Seychellen',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakije',
            SI: 'Slovenië',
            SB: 'Salomonseilanden',
            SO: 'Somalië',
            ZA: 'Zuid-Afrika',
            GS: 'Zuid-Georgië en Sandwich-eilanden',
            ES: 'Spanje',
            LK: 'Sri Lanka',
            SD: 'Soedan',
            SR: 'Suriname',
            SJ: 'Svalbard En Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Zweden',
            CH: 'Zwitserland',
            SY: 'Syrische Arabische Republiek',
            TW: 'Taiwan',
            TJ: 'Tadzjikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad En Tobago',
            TN: 'Tuvalu',
            TR: 'Turkije',
            TM: 'Turkmenistan',
            TC: 'Turks- en Caicoseilanden',
            TV: 'Tuvalu',
            UG: 'Oeganda',
            UA: 'Oekraïne',
            AE: 'Verenigde Arabische Emiraten',
            GB: 'Verenigd Koninkrijk',
            US: 'Verenigde Staten',
            UM: 'Verenigde Staten perifere eilanden',
            UY: 'Uruguay',
            UZ: 'Oezbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Maagdeneilanden, Brits',
            VI: 'Maagdeneilanden, VS',
            WF: 'Wallis En Futuna',
            EH: 'Westelijke Sahara',
            YE: 'Jemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    sa: {
        _name: 'عربي',
        timePickerModal: {
            title: 'Close In Time'
        },
        newDesign: {
            "withdrawalRequests": "لا توجد طلبات سحب حتى الآن",
            "withdrawalRequestsText1": "لم تقم بإجراء أي طلبات سحب حتى الآن.",
            "withdrawalRequestsText2": "بمجرد أن تبدأ عملية السحب، ستظهر تفاصيل معاملتك هنا",
            "pdfAttachment": "مرفق PDF",
            "agent": "الوكيل",
            "closed": "مغلق",
            "pending": "قيد الانتظار",
            "approved": "تم الموافقة",
            "declined": "تم الرفض",
            "identify": "عربي",
            "frontSide": "الجانب الأمامي",
            "backSide": "الجانب الخلفي",
            "address": "العنوان",
            "frontSideDocument": "الجانب الأمامي للوثيقة",
            "creditCard": "بطاقة الائتمان",
            "selfie": "صورة شخصية",
            "other": "أخرى",
            "noDocument": "لم يتم تحميل أي مستندات حتى الآن",
            "amountDeposited": "المبلغ المودع",
            "makeTransfer": "إجراء التحويل",
            "otherDetails": "تفاصيل الطلب",
            "manualWallets": "محافظ العملات الرقمية",
            "depositText": "يتم معالجة الإيداع خلال 5-10 دقائق",
            "pendingBankTransfer": "لديك إيداع بنكي معلق.",
            "completeDeposit": "يرجى اتباع التعليمات أدناه لإكمال عملية الإيداع",
            "downloadInvoice": "تحميل الفاتورة",
            "payInvoice": "دفع الفاتورة عبر البنك",
            "useYourBankText1": "استخدم نظام الدفع عبر الإنترنت أو خارج الإنترنت الخاص ببنكك لدفع الفاتورة.",
            "useYourBankText2": "تأكد من صحة تفاصيل الدفع.",
            "uploadReceipt": "تحميل إيصال التأكيد",
            "afterMakingPayment": "بعد إتمام الدفع، قم بتحميل الإيصال لتأكيد الإيداع",
            "clickToUpload": "انقر للتحميل",
            "orDrag": "أو اسحب وأفلت",
            "fileFormat": "PDF, JPG, JPEG, PNG أقل من 10MB",
            "waitForDeposit": "انتظر تأكيد الإيداع",
            "onceReceipt": "بمجرد تحميل الإيصال، سنقوم بالتحقق والموافقة على إيداعك",
            "paymentDetails": "تفاصيل الدفع",
            "nameOnCard": "الاسم على البطاقة",
            "cardNumber": "رقم البطاقة",
            "expirationDate": "تاريخ الانتهاء",
            "securityCode": "رمز الأمان",
            "continue": "متابعة",
            "viewAccounts": "عرض الحسابات",
            "continueWithdrawal": "متابعة السحب",
            "accountBalance": "رصيد الحساب",
            "accountBalanceText1": "لماذا لا يمكنني إجراء صفقة؟",
            "accountsBalanceText2": "كيف يمكنني إيداع الأموال؟",
            "accountsBalanceText3": 'أريد إيداع المال عبر Zelle',
            "tradingActions": "إجراءات التداول",
            "tradingActionsText1": "كيف يمكنني البيع على المكشوف؟",
            "tradingActionsText2": "كيف يمكنني إلغاء صفقة؟",
            "tradingActionsText3": "ما هو الحد الأدنى لمبلغ الصفقة؟",
            "marketInfo": "معلومات السوق",
            "marketInfoText1": "أين يمكنني رؤية اتجاهات السوق؟",
            "marketInfoText2": "ما هي أفضل الأسهم ارتفاعًا اليوم؟",
            "securityAndSettings": "الأمان والإعدادات",
            "securityAndSettingsText1": "كيف يمكنني تغيير كلمة المرور؟",
            "securityAndSettingsText2": "ما هي ميزات الأمان؟",
            "securityAndSettingsText3": "كيف يمكنني تحديث معلومات حسابي؟",
            "messageCopied": "تم نسخ الرسالة إلى الحافظة",
            "addSymbol": "إضافة رمز",
            "iHaveRead": "لقد قرأت وأوافق على الشروط والأحكام",
            "toggleToTurn": "قم بالتبديل لتشغيل التداول التلقائي",
            "selectTheRisk": "اختر مستوى المخاطرة",
            "confirm": "تأكيد",
            "cancel": "إلغاء",
            "chooseClosingTime": "اختر وقت الإغلاق",
            "setOrderClosingTime": "تعيين وقت إغلاق الطلب",
            "closingTime": "وقت الإغلاق",
            "date": "التاريخ",
            "time": "الوقت",
            "setCloseTime": "تعيين وقت الإغلاق",
            "15m": "15 دقيقة",
            "30m": "30 دقيقة",
            "1h": "1 ساعة",
            "custom": "مخصص",
            "verifyOtp": "تحقق من رمز OTP الخاص بك",
            "enterDigits": "أدخل الرمز المكون من 6 أرقام المرسل إلى [بريدك الإلكتروني/هاتفك]",
            "submit": "إرسال",
            "order": "بنجاح",
            "closedSuccessfully": "تم إغلاق الطلب",
            "openSuccessfully": "تم الفتح بنجاح",
            "deposit": "الإيداع",
            "withdrawal": "السحب",
            "completeVerification": "إكمال التحقق",
            "verification": "التحقق",
            "avatar": "الصورة الرمزية",
            "saveChanges": "حفظ التغييرات",
            "darkMode": "الوضع الداكن",
            "dashboardCurrency": "عملة لوحة التحكم",
            "language": "اللغة",
            "twoFactor": "الحماية بخطوتين",
            "googleTwoFactorIsActive": "المصادقة الثنائية من Google نشطة",
            "activateCodeForGoogle": "تفعيل الرمز لتطبيق Google Authenticator",
            "ifYouWishDisableEnterCode": "إذا كنت ترغب في تعطيل الحماية، أدخل رمز تطبيق المصادقة",
            "scanOrManuallyEnter": "امسح أو أدخل رمز QR يدويًا في تطبيق Google 2FA لإضافة رمز",
            "copyCode": "نسخ الرمز",
            "qrCode": "رمز QR",
            "activateAndEnterAuthenticator": "تفعيل المصادقة وإدخال رمز تم إنشاؤه في الحقل أدناه",
            "enterTheDigitsCodeGenerated": "أدخل الرمز المكون من 6 أرقام الذي تم إنشاؤه",
            "disableTwoFactor": "تعطيل الحماية بخطوتين",
            "activateTwoFactor": "تفعيل الحماية بخطوتين",
            "fileAdded": "تمت إضافة الملف بنجاح",
            "theVerificationStatus": "يمكن عرض حالة التحقق في قائمة المستندات المرفوعة",
            "confirmWithdrawal": "تأكيد السحب",
            "minimumWithdrawal": "الحد الأدنى لمبلغ السحب هو",
            "welcomeToTradingPlatform": "مرحبًا بكم في منصة التداول",
            "signIn": "تسجيل الدخول",
            "activeAccount": "الحساب النشط",
            "save": "حفظ",
            "selectCurrencyAndPeriod": "اختر العملة وفترة الاشتراك",
            "savingsNotFound": "لم يتم العثور على حسابات التوفير",
            "pleaseCheckInvestment": "يرجى التحقق من تفاصيل استثمارك للمتابعة",
            "allPeriodInvestment": "استثمار كل الفترات",
            "pct_0": "مرن",
            "pct_30": "1 شهر",
            "pct_60": "1 ربع سنة",
            "pct_90": "6 أشهر",
            "pct_180": "6 أشهر",
            "pct_365": "1 سنة",
            "savingsCreated": "تم إنشاء حساب التوفير بنجاح",
            "transferDetails": "تفاصيل التحويل",
            "transferAmount": "مبلغ التحويل",
            "investmentDetails": "تفاصيل الاستثمار",
            "investment": "الاستثمار",
            "Period": "الفترة",
            "created": "تم الإنشاء",
            "releaseTime": "وقت الإصدار",
            "viewSavingsAccounts": "عرض حسابات التوفير",
            "continueInvestment": "متابعة الاستثمار",
            "chooseSubscription": "اختر اشتراكك",
            "selectCurrencyAndSubscription": "اختر العملة وفترة الاشتراك للمتابعة.",
            "rate": "المعدل",
            "accountTransferFunds": "الحساب لتحويل الأموال منه",
            "amount": "المبلغ",
            "in": "في",
            "openSavingsAndInvest": "فتح حساب توفير والاستثمار",
            "strongSell": "بيع قوي",
            "sell": "بيع",
            "neutral": "محايد",
            "buy": "شراء",
            "stringBuy": "شراء قوي",
            "chooseCryptoWallet": "اختر محفظة العملات المشفرة",
            "chooseMethod": ' اختر طريقة',
            "maximumDeposit": "الحد الأقصى للإيداع هو",
            "minimumDeposit": "الحد الأدنى للإيداع هو",
            "pleaseCompleteAddress": "يرجى إكمال عنوانك",
            "pleaseCompleteCity": "يرجى إكمال مدينتك",
            "pleaseCompleteCountry": "يرجى إكمال بلدك",
            "pleaseCompleteZip": "يرجى إكمال الرمز البريدي",
            "amountDeposit": "مبلغ الإيداع",
            "transactionStatus": "حالة المعاملة",
            "paymentMethod": "طريقة الدفع",
            "depositTo": "الإيداع إلى",
            "depositAddress": "عنوان الإيداع",
            "scanTheQr": "امسح رمز QR أو انسخ عنوان الإيداع",
            "wallet": "المحفظة",
            "copyDepositAddress": "نسخ عنوان الإيداع",
            "copy": 'عربي',
            "addressCopied": "تم نسخ العنوان إلى الحافظة",
            "5min:": "5-10 دقائق",
            "2-5days": "2-5 أيام / 5-10 دقائق",
            "otherMethod": "طريقة أخرى",
            "profileSettings": "إعدادات الملف الشخصي",
            "savingsAccounts": "حسابات التوفير",
            "subscribe": "الاشتراك",
            "toCompleteVerification": "لإكمال عملية التحقق لحسابك، يرجى تقديم نسخ واضحة ويمكن قراءتها من المستندات التالية",
            "amountToWithdrawal": "مبلغ السحب",
            "cardDetails": "تفاصيل البطاقة",
            "cardHolderName": "اسم حامل البطاقة",
            "withdrawalSuccessful": "تم السحب بنجاح",
            "selectWithdrawalMethod": "اختر طريقة السحب",
            "creditDebit": "بطاقة الائتمان/الخصم",
            "crypto": "العملات المشفرة",
            "cryptoWalletAddress": "عنوان محفظة العملات المشفرة",
            "cryptoSubtitle": 'سحوبات الكريبتو إلى محفظتك 🚀',
            "bankWithdrawal": 'سحب مصرفي',
            "bankSubtitle": 'الحساب البنكي 🏦',
            "zelleSubtitle": 'تحويلات مصرفية سريعة ⚡🏦',
            "tradingResults": "نتائج التداول",
            "day": "اليوم",
            "month": "الشهر",
            "noTradingData": "لا توجد بيانات تداول متاحة",
            "startTradingToSee": "ابدأ التداول لرؤية نتائجك هنا.",
            "yourPerformanceMetric": "سيتم عرض مقاييس أدائك بمجرد توفر البيانات.",
            "favorite": "المفضلة",
            "all": "الكل",
            "availableFullTime": "متاح 24/7",
            "yourProfileAndSettings": "ملفك الشخصي وإعداداتك",
            "switchToNewDesignMessage": "انتقل إلى تصميم منصتنا الجديد الآن!",
            "switchToOldDesignMessage": "لا يزال بإمكانك استخدام التصميم الكلاسيكي لفترة محدودة",
            "switchToOldDesignButton": "التبديل إلى التصميم الجديد",
            "switchToNewDesignButton": "العودة إلى التصميم الكلاسيكي",
            howCanIAssist: 'كيف يمكنني مساعدتك اليوم؟',
            generateInvoice: 'إنشاء فاتورة',
            downloadPdf: 'تحميل PDF',
            confirmDeposit: 'تأكيد الإيداع',
            maxFileSize: 'الحجم الأقصى للملف هو 5 ميغابايت، والصيغ المقبولة هي JPEG, PNG, أو GIF',
            uploadPhoto: 'تحميل صورة',
            featuresDisabled: 'هذه الميزة معطلة',
            welcome: 'مرحبًا',
            signUp: 'التسجيل',
            registration: 'التسجيل',
            pleaseConfirmAgreement: 'يرجى تأكيد اتفاقية العميل',
            pleaseEnterPassword: 'يرجى إدخال كلمة المرور',
            passwordNotSame: 'كلمات المرور غير متطابقة',
            noActiveOrders: 'لا توجد طلبات نشطة',
            itLikeNoHaveActiveOrders: 'يبدو أنه ليس لديك أي طلبات نشطة في الوقت الحالي',
            cryptoWallet: 'محفظة العملات الرقمية',
            bankCard: 'البنك/البطاقة',
            selected: 'المحدد',
            amountWithdrawal: 'المبلغ المسحوب',
            withdrawalTo: 'سحب إلى',
            governmentIssuedPhoto1: 'هوية صادرة عن الحكومة (مثل جواز السفر، رخصة القيادة، بطاقة الهوية الوطنية)',
            governmentIssuedPhoto2: 'تصريح الإقامة (إن وجد)',
            governmentIssuedPhoto3: 'يجب أن يكون المستند ساري المفعول (غير منتهي الصلاحية)',
            governmentIssuedPhoto4: 'يجب أن يكون الاسم الكامل وتاريخ الميلاد والصورة واضحة.',
            uploadRecent1: 'قم برفع فاتورة خدمات حديثة أو كشف حساب بنكي أو عقد إيجار صادر خلال الأشهر الثلاثة الماضية',
            uploadRecent2: 'تأكد من أن اسمك الكامل وعنوانك السكني الحالي ظاهرين بوضوح.',
            uploadCopy1: 'قم برفع نسخة من الجهة الأمامية لبطاقتك الائتمانية مع التأكد من أن الأرقام الستة الأولى والأربعة الأخيرة مرئية',
            uploadCopy2: 'يرجى تغطية الأرقام الوسطى لأغراض الأمان',
            takeAndUpload1: 'قم بالتقاط وتحميل صورة ذاتية (سيلفي) وأنت تمسك بهويتك بجانب وجهك',
            takeAndUpload2: 'تأكد من أن وجهك وبطاقتك واضحان تمامًا',
            otherDocuments: 'مستندات أخرى',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'قم بتحميل صورة واضحة ومقروءة لوثيقة رقم الضمان الاجتماعي (SSN) الخاصة بك. تأكد من أن جميع التفاصيل مرئية وخالية من العوائق.',
            frontSideSsnDocument: 'الجانب الأمامي لوثيقة SSN',
            answerSaved: 'تم حفظ الإجابات',
            cryptoWalletDetails: 'تفاصيل محفظة العملات الرقمية',
            bitcoinWalletAddress: 'عنوان محفظة البيتكوين',
            recovery: 'استعادة',
            passwordRecovery: 'استعادة كلمة المرور',
            searchCountry: 'البحث عن بلد',
            questionnaire: 'استبيان',
            questionnaireText: 'يرجى إكمال الاستبيان للتحقق من هويتك',
            sendAnswers: 'إرسال الإجابات',
        },
        autoCloseModal: {
            takeProfitStopLoss: 'جني الربح وإيقاف الخسارة',
            takeProfit: 'جني الربح',
            stopLoss: 'إيقاف الخسارة',
            updateOrder: 'تحديث الطلب',
        },
        balanceModal: {
            balance: 'الرصيد',
            credit: 'الائتمان',
            equity: 'حقوق الملكية',
            practice: 'تدريب',
            real: 'حقيقى',
            account: 'حساب',
            invested: 'المُستثمَر',
            profit: 'الربح',
            loss: 'الخسارة',
            margin: 'الهامش',
            marginLevel: 'مستوى الهامش',
            marginFree: 'الهامش الحر'
        },
        header: {
            cfd: 'العقود مقابل الفروقات (CFD)',
            platform: 'المنصة',
            deposit: 'الإيداع'
        },
        sideMenu: {
            marketWatch: 'مراقبة السوق',
            activeOrders: 'الطلبات النشطة',
            tradingHistory: 'تاريخ التداول',
            economicCalendar: 'التقويم',
            marketNews: 'أخبار السوق'
        },
        closePositionModal: {
            closeOrder: 'إغلاق الموضع',
            areYouSure: 'هل أنت متأكد من إغلاق الموضع',
            buy: 'شراء',
            sell: 'بيع',
            yes: 'نعم',
            no: 'لا',
        },
        pciDssModal: {
            desc: `يُطلب الامتثال لمعيار أمان بيانات صناعة بطاقات الدفع (PCI DSS) من جميع الكيانات التي تخزن أو تعالج أو تنقل بيانات حاملي بطاقات Visa/Master Card/Stripe، بما في ذلك المؤسسات المالية والتجار ومقدمي الخدمات. إنه المعيار الأمني العالمي لجميع الكيانات التي تخزن أو تعالج أو تنقل بيانات حاملي البطاقات و/أو بيانات المصادقة الحساسة. يحدد معيار PCI DSS مستوى قاعديًا من الحماية للمستهلكين ويساعد في تقليل الاحتيال وانتهاكات البيانات عبر النظام البياني للدفع برمته.`
        },
        chart: {
            addNewChart: 'إضافة جدول بياني جديد',
        },
        symbolsModal: {
            watchlist: 'قائمة المراقبة',
            asset: 'الأصل',
            price: 'السعر',
            changePct: 'التغيير إلى نظام 24 ساعة'
        },
        pendingModal: {
            pending: 'قيد الانتظار',
            assetPrice: 'سعر الأصل',
            current: 'الحالى',
            revert: 'العودة إلى سعر السوق',
            automatically: 'سوف يتم فتح الموضع بشكل تلقائى عندما يصل السعر إلى هذا المستوى'
        },
        orderMenu: {
            default: 'default',
            volume: 'الحجم',
            lots: 'العقود',
            units: 'الوحدات',
            currency: 'العملة',
            contractSize: 'حجم العقد',
            position: 'الموضع',
            margin: 'الهامش',
            freeMargin: 'الهامش الحر',
            takeProfitStopLoss: 'جني الربح وإيقاف الخسارة',
            pending: 'قيد الانتظار',
            market: 'السوق',
            leverage: 'الرافعة المالية',
            spread: 'عرض السعر',
            notSet: 'لم يتم تحديده',
            at: 'حتى',
            buy: 'شراء',
            sell: 'بيع',
        },
        footer: {
            supportBanner: 'كل يوم ، وكل الساعة',
            currentTime: 'الوقت الحالي',
            liveChat: 'محادثة مباشرة'
        },
        statusBar: {
            balance: 'الرصيد',
            credit: 'الائتمان',
            equity: 'حقوق الملكية',
            margin: 'الهامش',
            marginLevel: 'مستوى الهامش',
            freeMargin: 'الهامش الحر',
            pnl: 'قيمة PnL',
            profitTotal: 'قيمة PnL مدى الحياة'
        },
        accountModal: {
            dateRegistered: 'تاريخ التسجيل',
            userId: 'رقم تعريف المستخدم',
            dashboard: 'لوحة التحكُّم',
            personalData: 'البيانات الشخصية',
            deposit: 'الإيداع',
            withdrawFunds: 'سحب الأموال',
            savings: 'المُدخَرات',
            settings: 'الإعدادات',
            logout: 'تسجيل الخروج'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'برجاء القيام بعملية إيداع',
            practiceAccount: 'حساب تجريبي',
            realAccount: 'حساب حقيقي',
            noWithdrawals: 'لا توجد عمليات سحب',
            easyWithdrawals: 'عمليات سحب يسيرة',
            allAssets: 'جميع الأصول المتاحة',
            fullFledged: 'منصة كاملة متكاملة',
            fillUpTo: 'يعمل حتى',
            freeReplenishment: 'تجديد مجاني',
            topUp: 'اشحن حسابك',
            minimumAmount: 'الحد الأدنى للقيمة المالية',
            canSwitch: 'يمكنك التبديل بين حساباتك في أي وقت تُريد'
        },
        gridsModal: {
            chartGrids: 'شبكات الرسم البياني',
            chart1: '1 رسم بيانى',
            chart2: '2 رسم بيانى',
            chart3: '3 رسوم بيانية',
            chart4: '4 رسوم بيانية',
        },
        noConnectionModal: {
            connectionLost: 'لقد تم فقد الاتصال بالخادم',
            retryNow: 'أعد المحاولة الآن',
        },
        loginModal: {
            loginToTradeRoom: 'تسجيل الدخول إلى Traderoom',
            email: 'عنوان البريد الإلكتروني',
            enterEmail: 'أدخل بريدك الإلكتروني',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: 'كود 2FA (إذا كان مُتاح)',
            twoFactorAuth: 'تحقيق الهوية بخطوتين',
            password: 'كلمة المرور',
            yourPassword: 'كلمة مرورك',
            signIn: 'سجِّل الدخول',
            dontHaveAccount: 'ليس لديك حساب؟',
            restore: 'إستعادة حسابى',
            fullName: 'الاسم بالكامل',
            havePromo: 'لديَّ رمز ترويجي',
            promo: 'الرمز الترويجي',
            login: 'تسجيل الدخول',
            haveAccount: 'هل لديك حساب بالفعل؟',
            hide: 'إخفاء',
            goTo: 'العودة إلى الخلف'
        },
        newPasswordModal: {
            setNewPassword: 'حدِّد كلمة مرور جديدة',
            newPassword: 'كلمة مرور جديدة',
            confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
            setButton: 'تعيين'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'إنشاء حساب جديد',
            email: 'عنوان البريد الإلكتروني',
            firstName: 'الاسم الأول',
            lastName: 'اللقب',
            country: 'الدولة',
            phone: 'رقم الهاتف',
            password: 'كلمة المرور',
            createAccount: 'إنشاء حساب',
            currency: 'العملة',
            privacyAndPolicy: "الخصوصية والسياسة",
            customerAgreement: 'اتفاقية العميل',

        },
        forgotPasswordModal: {
            forgotPassword: 'هل نسيت كلمة المرور؟',
            submitEmail: 'من فضلك، قم بالتأكيد على البريد الإلكتروني المُستخَدم للتسجيل ، وتحقق من صندوق الوارد لديك، ثم اتبع التعليمات الملحَقة',
            submit: 'تأكيد'
        },
        notifications: {
            error: 'خطأ',
            success: 'تم بنجاح',
            deposit: 'الإيداع',
            withdrawal: 'سحب',
            depositApproved: 'تم التصديق على ',
            depositDeclined: 'تم رفض الإيداع',
            withdrawalApproved: 'تم التصديق على السحب',
            withdrawalDeclined: 'تم رفض السحب'
        },
        markets: {
            favorites: 'قائمة المراقبة', 
            all: 'كل الرموز' ,
            forex: 'فوركس',
            stocks: 'الأسهم',
            commodities: 'السلع',
            indices: 'المؤشرات',
            crypto: 'العملات المشفَّرة',
            metals: 'المعادن',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'شراء',
            sell: 'بيع',
            volume: 'الحجم',
            entryPrice: 'سعر الدخول',
            takeProfit: 'جني الربح',
            stopLoss: 'إيقاف الخسارة',
            maxPosition: 'أقصى موضع',
            calculate: 'احسب',
            calculationsFor: 'حسابات',
            leverage: 'الرافعة المالية',
            requiredMargin: 'الهامش المطلوب',
            profitFromTP: 'الربح من TP',
            lossFromSL: 'الخسارة من SL',
            roe: 'سعر الصرف (ROE)',
            profitCalculator: 'حاسبة الأرباح',
        },
        depositSuccessModal: {
            title: 'لقد تم الإيداع بنجاح',
            text: 'لقد تمت معالجة إيداعك بنجاح!',
        },
        depositFailModal: {
            title: 'لقد فشل الإيداع',
            text: 'لم تتم معالجة إيداعك.',
        },
        widgetMarketWatch: {
            marketWatch: 'مراقبة السوق',
            search: 'بحث...',
        },
        widgetOrders: {
            commission: 'العمولة',
            volume: 'الحجم',
            margin: 'الهامش',
            active: 'نشط',
            pending: 'قيد الانتظار',
            activeOrders: 'الطلبات النشطة',
            portfolio: 'المَحفَظَة',
            allPositions: 'جميع المواضع',
            noPositionsLine1: 'ليس لديك موضع مفتوح',
            noPositionsLine2: 'المواضع حتى الآن',
            show: 'عرض',
            more: 'أكثر',
            less: 'أقل',
            purchaseTime: 'وقت الشراء',
            closePosition: 'إغلاق الموضع',
            priceOpen: 'سعر الشراء',
            priceSl: 'سعر إيقاف الخسارة',
            priceTp: 'سعر جني الأرباح',
            type: 'اتجاه الموضع',
            pnl: 'الربح/الخسارة',
            cancelOrder: 'إلغاء الطلب',
            orderType: 'نوع الطلب',
            cancelled: 'تم الإلغاء',
            tradingHistory: 'تاريخ التداول',
            noHistoryLine1: 'ليس لديك أى',
            noHistoryLine2: 'صفقات مُغلَقة بعد',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'التقويم'
        },
        widgetMarketNews: {
            marketNews: 'أخبار السوق'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'الطلبات النشطة',
            ordersHistory: 'سجل الطلبات',
            id: 'رقم التعريف',
            symbol: 'الرمز',
            type: 'النوع',
            volume: 'الحجم',
            openPrice: 'سعر الفتح',
            openTime: 'وقت الفتح',
            closePrice: 'سعر الإغلاق',
            closeTime: 'وقت الإغلاق',
            sl: 'SL',
            tp: 'TP',
            price: 'السعر',
            pnl: 'قيمة PnL',
            actions: 'الإجراءات',
            edit: 'تعديل',
            close: 'إغلاق',
            commission: 'العمولة',
            swap: 'مُبادلة',
            swapLong: 'مقايضة إلى وضع الطويل',
            swapShort: 'مُقايضة إلى وضع القصير'
        },
        assetInfoModal: {
            assetInfo: 'معلومات الأصول',
            tradingConditions: 'شروط التداول',
            information: 'تغيير الجلسة',
            bid: 'العطاء',
            ask: 'طلب',
            sessionChange: 'تغيير الجلسة',
            tradeNow: 'تداول الآن',
            opens: 'مرات الفتح',
            closes: 'مرات الإغلاق',
            at: 'حتى',
            open247: 'مفتوح 24/7',
            today: 'اليوم',
            tomorrow: 'الغد',
            sunday: 'الأحد',
            monday: 'الإثنين',
            tuesday: 'الثلاثاء',
            wednesday: 'الأربعاء',
            thursday: 'الخميس',
            friday: 'الجمعة',
            saturday: 'السبت',
            contractSpecification: 'مواصفات العقد',
            symbol: 'الرمز',
            name: 'الاسم',
            market: 'السوق',
            baseCurrency: 'العملة الأساسية',
            digits: 'الأرقام',
            lotSize: 'حجم العقد',
            lotStep: 'درجة العقد',
            minVolume: 'أدنى حجم',
            maxVolume: 'أقصى حجم',
            leverage: 'الرافعة المالية',
            commission: 'العمولة',
            swapLong: 'مقايضة إلى وضع الطويل',
            swapShort: 'مُقايضة إلى وضع القصير',
            schedule: 'الجدول الزمنى',
            weekday: 'يوم من أيام الأسبوع',
            tradingTime: 'وقت التداول',
            closed: 'تم الإغلاق',
            sell: 'بيع',
            buy: 'شراء',
            low: 'منخفض',
            high: 'عالى',
            oneHourChange: 'تغيير لمدة ساعة',
            oneDayChange: 'تغيير لمدة يوم واحد',
            oneWeekChange: 'تغيير لمدة أسبوع واحد',
            oneMonthChange: 'تغيير لمدة شهر واحد',
            threeMonthsChange: 'تغيير لمدة 3 شهور',
            oneYearChange: 'تغيير لمدة عام واحد',
            loading: 'جاري التحميل...',
        },
        autoTrader: {
            days: 'days',
            title: 'المتاجر الآلي',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'الصفقات في اليوم:',
            desc: `التداول بعقود الفروقات والمنتجات ذات الرافعة المالية يمكن أن يؤدي إلى خسائر. قبل التداول، يجب على العملاء قراءة بيانات المخاطر ذات الصلة في صفحة الإفصاح عن المخاطر. لا يضمن التداول الآلي النتائج. لا تتحمل الشركة أي مسؤولية عن خسارة الأموال في التداول الآلي. يُرجى التأكد من فهمك الكامل للمخاطر واتخاذ التدابير لإدارتها.`,
            accept: 'Accept',
            save: 'حفظ',
            cancel: 'إلغاء',
        },
        cardBox: {
            name: 'اسم',
            cardNumber: 'رقم البطاقة',
            expiration: 'انتهاء الصلاحية (شهر/سنة)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "تداول الآن",
            startTrading: 'ابدأ التداول',
            deposit: 'الإيداع',
            dashboard: 'لوحة التحكُّم',
            personalInfo: 'المعلومات الشخصية',
            contactInfo: 'معلومات الاتصال',
            withdrawal: 'سحب',
            verification: 'التحقق',
            accounts: 'الحسابات',
            liveChat: 'محادثة مباشرة',
            savings: 'المُدخَرات',
            referrals: 'برنامج الإحالة',
            settings: 'الإعدادات',
            logOut: 'تسجيل الخروج',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'لم يتم تعيين المحفظة لطريقة الإيداع هذه. يُرجى الاتصال بوكيلك أو فريق الدعم',
            addressReceipt: 'يُرجى الانتظار حتى يتم تحميل العنوان',
            makeDeposit: 'برجاء القيام بعملية إيداع',
            instant: 'فى الحال',
            minutes: 'دقائق',
            hours: 'ساعات',
            days: 'الأيام',
            amount: 'المبلغ',
            continue: 'استمر',
            qrCode: 'رمز الاستجابة السريعة',
            depositAddress: 'عنوان الإيداع',
            copy: 'نسخ',
            last: 'آخر',
            deposits: 'الإيداعات',
            time: 'الوقت',
            currency: 'العملة',
            status: 'الحالة',
            info: 'معلومات',
        },
        fullScreenCcExt: {
            pleaseWait: 'يُرجى الانتظار. جاري الاتصال بمزود خدمة الدفع...',
            cardPayment: 'دفع البطاقة',
            inOrder: 'من أجل إرسال المبلغ',
            pleaseProvide: 'يُرجى تقديم تفاصيل البطاقة'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'لا يوجد طلب بعد',
            totalBalance: 'الرصيد الإجمالى',
            totalPnl: 'PNLالإجمالى',
            profitableOrders: 'الطلبات المُربحة',
            roi: 'ROI',
            activityLog: 'سجل النشاط',
            loginFromIp: 'تسجيل الدخول من عنوان IP',
            tradingResults: 'نتائج التداول',
            week: 'الأسبوع',
            month: 'الشهر',
            year: 'العام',
            successRate: 'معدَّل النجاح',
            closedWithProfit: 'تم الإغلاق بالربح',
            closedWithLoss: 'تم الإغلاق بالخسارة',
            account: 'حساب',
            balance: 'الرصيد',
            leverage: 'الرافعة المالية',
            credit: 'الائتمان',
            tradeNow: 'تداول الآن',
            usingCurrentRate: 'باستخدام سعر الصرف الحالي',
            totalDeposits: 'إجمالي الودائع',
            totalWithdrawals: "إجمالي السحوبات",
            returnOnInvestment: "العائد على الاستثمار"
        },
        fullScreenPersonal: {
            personalDetails: 'التفاصيل الشخصية',
            profilePhoto: 'صورة الملف الشخصي',
            firstName: 'الاسم الأول',
            country: 'الدولة',
            lastName: 'اللقب',
            address: 'العنوان',
            email: 'البريد الإلكتروني',
            phone: 'رقم الهاتف',
            saveChanges: 'حفظ التغييرات',
            state: 'مدينة',
            postcode: 'الرمز البريدي',
            created: "تم إنشاء الحساب في",
        },
        fullScreenAvatar: {
            dropFile: 'أسقط ملفًا على الدائرة التى بالأعلى لبدء تحميله',
            notAllowed: 'ممنوع نشر',
            notAllowedLine1: 'صور ذات طبيعة جنسية أو إباحية صريحة',
            notAllowedLine2: 'أو الصور التي تهدف إلى التحريض على الكراهية أو العدوان العرقي',
            notAllowedLine3: 'أو صور لأشخاص تقل أعمارهم عن 18 عام',
            notAllowedLine4: 'أو الصور المحمية بحقوق الطبع والنشر من جهات خارجية',
            notAllowedLine5: 'أو الصور التي يزيد حجمها عن 5 ميغابايت وبتنسيق غير JPG أو GIF أو PNG',
            requirements: ' يجب أن يكون وجهك ظاهرًا في الصورة بوضوح. إن جميع الصور ومقاطع الفيديو التي قمت بتحميلها يجب أن تتوافق مع هذه المتطلبات ، وإلا يمكنكَ إزالتها',
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'سحب',
            requestNewWithdrawal: 'طلب سحب جديد',
            amount: 'المبلغ',
            currentBalance: 'رصيد حسابك الجاري هو',
            withdrawAll: 'سحب كل المبلغ',
            requestWithdrawal: 'طلب سحب',
            last: 'آخر',
            withdrawalRequests: 'طلبات السحب',
            time: 'الوقت',
            currency: 'العملة',
            info: 'معلومات',
            status: 'الحالة',
            bank: 'البنك',
            bitcoin: 'البيتكوين',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'البطاقة',
            ethereum: 'الإيثريوم',
            eth: 'Ethereum',
            usdt: 'USDT',
            withdrawalDetails: 'تفاصيل السحب',
            address: 'عنوان المحفظة',
            bankDetails: 'تفاصيل البنك',
            bankAccount: 'رقم الحساب',
            bankHolder: 'اسم صاحب الحساب',
            bankIban: 'IBAN',
            bankSwift: 'رمز SWIFT للبنك',
            recipientAddress: 'عنوان المستلم',
            routingNumber: 'رقم التوجيه المصرفي',
            bankBeneficiary: 'اسم بنك المستفيد',
            bankAddress: 'عنوان البنك',
            bankCode: 'رمز البنك (BSB)',
            desc: 'وصف',
            cardNumber: 'رقم البطاقة',
            cardHolder: 'اسم حامل البطاقة',
        },
        fullScreenVerification: {
            dragAndDrop: 'اسحب وأفلت المستند في هذه المنطقة',
            documentsList: 'قائمة المستندات التى تحميلها',
            document: 'المستند',
            timeUploaded: 'وقت التحميل',
            timeProcessed: 'وقت المعالجة',
            status: 'الحالة',
            types: ['إثبات الهوية', 'إثبات الإقامة', 'واجهة بطاقة الائتمان', 'بطاقة الائتمان من الخلف', 'بطاقة الهوية من الخلف', 'صورة شخصية', 'Other'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'الرفع',
            isConfirmed: 'تم تأكيده',
            uploadAnotherDocument: 'رفع وثيقة أخرى',
            isUnderReview: 'قيد المراجعة',
            questionnaire: 'استطلاع',
            sendAnswers: 'إرسال الإجابات',
            progressBarOf: 'من',
            progressBarText: 'تم تحميل مستنداتك والتحقُّق منها'
        },
        fullScreenAccounts: {
            manageAccounts: 'تحويل الأموال بين حساباتك',
            transferFrom: 'من الحساب',
            transferTo: 'إلى الحساب',
            amount: 'المبلغ',
            makeTransfer: 'إجراء تحويل أموال',
            yourAccounts: 'حساباتك',
            account: 'حساب',
            currency: 'العملة',
            balance: 'الرصيد',
            credit: 'الائتمان',
            makeActive: 'تنشيط',
            active: 'نشط'
        },
        fullScreenSupport: {
            sendMessage: 'أرسل رسالة'
        },
        fullScreenSavings: {
            pct_0: 'مرن',
            pct_30: 'مُغلَق لمدة شهر',
            pct_90: 'مُغلَق لمدة ربع سنة واحد',
            pct_180: 'مُغلَق لمدة 6 شهور',
            pct_365: 'مُغلَق لمدة عام واحد',
            savings: 'المُدخَرات',
            detailedInformation: 'معلومات تفصيلية',
            pleaseSelect: 'يُرجى تحديد العملة والفترة',
            openSavings: 'المُدَّخرات المفتوحة',
            currentTime: 'الوقت الحالي',
            releaseTime: 'وقت الإنطلاق',
            currencyChosen: 'العملة التى تم اختيارها',
            periodChosen: 'الفترة التى تم اختيارها',
            yourRate: 'سعرك',
            yourEarnings: 'أرباحك',
            accountFrom: 'الحساب المراد تحويل الأموال منه',
            enterAmount: 'أدخل المبلغ',
            in: 'فى',
            openAndInvest: 'افتح حساب التوفير واستثمر',
            investment: 'الاستثمار',
            period: 'الفترة',
            daysTotal: 'إجمالى الأيام',
            finalEarnings: 'الأرباح النهائية',
            created: 'تم الإنشاء',
            daysElapsed: 'الأيام المنقضية',
            days: 'الأيام',
            earnings: 'الأرباح',
            fundsReleasedIn: 'الأموال التي تم إصدارها في',
            claim: 'مُطالَبة',
            name: "اسم",
        },
        fullScreenSettings: {
            updatePassword: 'تحديث كلمة المرور',
            currentPassword: 'كلمة المرور الحالية',
            newPassword: 'كلمة مرور جديدة',
            changePassword: 'تغيير كلمة المرور',
            activationForGoogle: 'رمز تنشيط تطبيق جوجل للتحقُّق',
            activateAndEnter: 'قم بتنشيط المصادقة وأدخل الرمز الموجود في الحقل أدناه',
            qrCode: 'رمز الاستجابة السريعة',
            activateProtection: 'قم بتنشيط حماية 2FA',
            protectionActive: 'إن حماية 2FA نشطة',
            twoFactorCode: 'رمز 2FA',
            disableProtection: 'تعطيل حماية 2FA',
            language: 'اللغة',
            dashboardCurrency: 'لوحة التحكم فى العملات',
            confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
        },
        fullScreenReferrals: {
            yourLink: 'رابط التحويل لديك',
            referredFriends: 'الأصدقاء الذين تمت الإحالة إليهم',
            id: 'رقم التعريف',
            name: 'الاسم',
            registered: 'التسجيل',
            level: 'المستوى',
            payout: 'الدفع',
            lastPayouts: 'مدفوعات الإحالة الأخيرة',
            time: 'الوقت',
            amount: 'المبلغ',
            currency: 'العملة',
            info: 'معلومات',
            referralDesc:"تم تصميم برنامج الإحالة لمكافأتك على توسيع مجتمعنا من المستثمرين القيمين.",
            shareLink:"شارك الرابط:",
            subtitle:"قدّم له اسمك أو بريدك الإلكتروني أو معرف التداول الخاص بك.",
            descSuccesfull:"بمجرد تسجيل الإحالة بنجاح، سيتواصل معه مستشارك المالي لمساعدته في اتخاذ خطواته الأولى في رحلة الاستثمار بأكثر الطرق كفاءة واحترافية.",
            descTracked:"يتم تتبع جميع مساهماته ضمن إحالتك."
        },
        months: {
            Jan: 'يناير',
            Feb: 'قبراير',
            Mar: 'مارس',
            Apr: 'أبريل',
            May: 'مايو',
            Jun: 'يونيو',
            Jul: 'يوليو',
            Aug: 'أغسطس',
            Sep: 'سبتمبر',
            Oct: 'أكتوبر',
            Nov: 'نوفمبر',
            Dec: 'ديسمبر'
        },
        orderTypes: ['شراء', 'بيع', 'حد الشراء', 'حد البيع', 'إيقاف الشراء', 'إيقاف البيع'],
        statuses: ['قيد الانتظار', 'تم التصديق عليه', 'تم الرفض', 'جارى المعالجة'],
        errors: {
            NOT_FOUND: 'خطأ في الطلب',
            FORBIDDEN: 'الوصول مرفوض. يُرجى إعادة تسجيل الدخول',
            SERVER_ERROR: 'فشل الإجراء.. يُرجى المحاولة لاحقًا.',
            INVALID_ACCOUNT: 'انتهت الجلسة. يُرجى إعادة تسجيل الدخول',
            INVALID_EMAIL: 'عنوان البريد الإلكتروني غير صالح',
            INVALID_PHONE: 'رقم الهاتف غير صحيح',
            INVALID_COUNTRY: 'الدولة غير صحيحة',
            INVALID_LOGIN: 'بيانات اعتماد تسجيل الدخول غير صالحة',
            USER_BLOCKED: 'لقد تم حظر الحساب. من فضلك اتصل بالدعم',
            USER_REGISTERED: 'المستخدم الذي لديه ذلك بريد إلكتروني أو رقم الهاتف مسجَّل بالفعل',
            INVALID_USER: 'المستخدم غير موجود',
            REJECTED: 'التشغيل غير مسموح به بسبب قيود المنصة',
            INVALID_OLD_PASSWORD: 'كلمة المرور القديمة غير صالحة',
            INVALID_SYMBOL: 'رمز التداول غير صالح',
            INVALID_SYMBOL_PRECISION: 'دقة الرمز غير صالحة',
            INVALID_FRAME: 'إطار جدول بيانى غير صالح',
            INVALID_CURRENCY: 'عملة غير صالحة',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'نوع تداول الحساب غير صالح',
            INSUFFICIENT_FUNDS: 'ليس لديك تمويل كافى',
            INVALID_WITHDRAWAL: 'عملية السحب غير موجودة',
            INVALID_STATUS: 'لبحالة غير صالحة',
            INVALID_FILE: 'امتداد الملف غير صالح أو أن حجمه كبير جدًا',
            INVALID_DOCUMENT: 'لم يتم العثور على المستند',
            INVALID_NAME: 'اسم غير صالح',
            INVALID_LOT_SIZE: 'حجم العقد غير صالح',
            INVALID_LOT_STEP: 'درجة عقد غير صالحة',
            INVALID_MIN_VOLUME: 'حجم أدنى غير صالح',
            INVALID_MAX_VOLUME: 'حجم أقصى غير صالح',
            INVALID_GROUP: 'مجموعة غير صالحة',
            INVALID_SPREAD: 'عرض سعر غير صالح',
            REQUEST_TIMEOUT: 'انتهت مهلة الطلب. يُرجى المحاولة لاحقًا.',
            EXISTING_OPEN_ORDERS: 'لديك طلب مفتوح، أغلق الطلب لهذا المستخدم أولاً وحاول مرة أخرى.',
            INVALID_TYPE: 'نوع الطلب غير صالح',
            INVALID_VOLUME: 'حجم الطلب غير صالح',
            INVALID_SL: 'قيمة إيقاف الخسارة غير صالحة',
            INVALID_TP: 'قيمة جني الأرباح غير صالحة',
            INVALID_PRICE: 'سعر الطلب غير صالح',
            INVALID_EXPIRATION: 'وقت انتهاء صلاحية الطلب غير صالح',
            NOT_ENOUGH_MARGIN: 'الهامش غير كافى',
            INVALID_ORDER: 'لم يتم العثور على الطلب',
            MARKET_CLOSED: 'التداول غير متاح. يُرجى المحاولة لاحقًا',
            INVALID_BALANCE_TYPE: 'نوع غير صالح لعملية الرصيد',
            INVALID_HASH: 'تجزئة غير صالحة',
            HASH_EXPIRED: 'لقد انتهت صلاحية رابط إعادة تعيين كلمة المرور. يُرجى طلب رابط جديد',
            INVALID_CODE: 'رمز 2FA غير صالح',
            CHAT_DISABLED: 'تم إغلاق الوصول إلى دردشة الدعم',
            WITHDRAWAL_NOT_ALLOWED: 'تم إغلاق الوصول إلى عمليات السحب',
            TRADING_DISABLED: 'التداول مُعطَّل للحساب الجاري',
            PENDING_DEPOSITS_LIMIT: 'تم تجاوز حد الإيداعات',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'مسموح بسحب مُعلَّق واحد فقط',
            LOCAL_DEPOSIT_ONLY_REAL: 'الإيداع متاح فقط للحساب الحقيقي',
            LOCAL_DEPOSIT_POSITIVE: 'يجب أن يكون مبلغ الإيداع أكثر من 0',
            LOCAL_INVALID_CARD_NUMBER: 'رقم البطاقة غير صالح',
            LOCAL_INVALID_CARD_EXPIRATION: 'تاريخ انتهاء صلاحية البطاقة غير صالح',
            LOCAL_INVALID_CVC: 'رمز CVV / CVC غير صالح',
            LOCAL_PASSWORDS_NOT_MATCH: 'كلمات المرور غير متطابقة ، يُرجى إعادة إدخال كلمة المرور',
            LOCAL_NO_TRADING_ACCOUNTS: 'ليس لديك حسابات تداول متاحة. من فضلك اتصل بالدعم',
            LOCAL_MIN_DEPOSIT: 'الحد الأدنى للإيداع هو $',
            LOCAL_MIN_WITHDRAWAL: 'الحد الأدنى للسحب هو $',
            LOCAL_INVALID_FILE_TYPE: 'تنسيق الملف غير مدعوم. يرجى إرفاق الملفات ذات الإمتدادات JPG أو PNG أو PDF فقط',
            LOCAL_MIN_STAKING: 'الحد الأدنى للإيداع لهذه العملة هو',
            FLOOD_REJECTED: 'نك ترسل الرسائل بشكل متكرر. يُرجى المحاولة لاحقًا'
        },
        countries: {
            AF: 'أفغانستان',
            AX: 'جزر آلاند',
            AL: 'ألبانيا',
            DZ: 'الجزائر',
            AS: 'ساموا الأمريكية',
            AD: 'أندورا',
            AO: 'أنغولا',
            AI: 'أنغيلا',
            AQ: 'أنتاركتيكا',
            AG: 'أنتيغوا وبربودا',
            AR: 'الأرجنتين',
            AM: 'أرمينيا',
            AW: 'أروبا',
            AU: 'أستراليا',
            AT: 'النمسا',
            AZ: 'أذربيجان',
            BS: 'جزر البهاما',
            BH: 'البحرين',
            BD: 'بنغلاديش',
            BB: 'باربادوس',
            BY: 'بيلاروسيا',
            BE: 'بلجيكا',
            BZ: 'بليز',
            BJ: 'بنين',
            BM: 'برمودا',
            BT: 'بوتان',
            BO: 'بوليفيا',
            BA: 'البوسنة والهرسك',
            BW: 'بوتسوانا',
            BV: 'جزيرة بوفيت',
            BR: 'البرازيل',
            IO: 'إقليم المحيط الهندي البريطاني',
            BN: 'بروناي دار السلام',
            BG: 'بلغاريا',
            BF: 'بوركينا فاسو',
            BI: 'بوروندي',
            KH: 'كمبوديا',
            CM: 'الكاميرون',
            CA: 'كندا',
            CV: 'الرأس الأخضر',
            KY: 'جزر كايمان',
            CF: 'جمهورية أفريقيا الوسطى',
            TD: 'تشاد',
            CL: 'تشيلي',
            CN: 'الصين',
            CX: 'جزيرة الكريسماس',
            CC: 'جزر كوكوس (كيلينغ)',
            CO: 'كولومبيا',
            KM: 'جزر القمر',
            CG: 'الكونغو',
            CD: 'جمهورية الكونغو الديمقراطية',
            CK: 'جزر كوك',
            CR: 'كوستاريكا',
            CI: 'كوت ديفوار',
            HR: 'كرواتيا',
            CU: 'كوبا',
            CY: 'قبرص',
            CZ: 'جمهورية التشيك',
            DK: 'الدنمارك',
            DJ: 'جيبوتى',
            DM: 'دومينيكا',
            DO: 'جمهورية الدومينيكان',
            EC: 'الإكوادور',
            EG: 'مصر',
            SV: 'السلفادور',
            GQ: 'غينيا الاستوائية',
            ER: 'إريتريا',
            EE: 'إستونيا',
            ET: 'إثيوبيا',
            FK: 'جزر فولكلاند (مالفيناس)',
            FO: 'جزر فارو',
            FJ: 'فيجي',
            FI: 'فنلندا',
            FR: 'فرنسا',
            GF: 'جويانا الفرنسية',
            PF: 'بولينيزيا الفرنسية',
            TF: 'الأقاليم الجنوبية الفرنسية',
            GA: 'الجابون',
            GM: 'غامبيا',
            GE: 'جورجيا',
            DE: 'ألمانيا',
            GH: 'غانا',
            GI: 'جبل طارق',
            GR: 'اليونان',
            GL: 'جرينلاند',
            GD: 'غرينادا',
            GP: 'جوادلوب',
            GU: 'غوام',
            GT: 'غواتيمالا',
            GG: 'غيرنسي',
            GN: 'غينيا',
            GW: 'غينيا بيساو',
            GY: 'غيانا',
            HT: 'هايتي',
            HM: 'جزيرة هيرد وجزر ماكدونالد',
            VA: 'الكرسي الرسولي (دولة الفاتيكان)',
            HN: 'هندوراس',
            HK: 'هونج كونج',
            HU: 'المجر',
            IS: 'أيسلندا',
            IN: 'الهند',
            ID: 'إندونيسيا',
            IR: 'إيران',
            IQ: 'العراق',
            IE: 'أيرلندا',
            IM: 'جزيرة مان',
            IL: 'إسرائيل',
            IT: 'إيطاليا',
            JM: 'جامايكا',
            JP: 'اليابان',
            JE: 'جيرسي',
            JO: 'الأردن',
            KZ: 'كازاخستان',
            KE: 'كينيا',
            KI: 'كيريباتي',
            KR: 'كوريا',
            KW: 'الكويت',
            KG: 'قيرغيزستان',
            LA: 'جمهورية لاو الديمقراطية الشعبية',
            LV: 'لاتفيا',
            LB: 'لبنان',
            LS: 'ليسوتو',
            LR: 'ليبيريا',
            LY: 'الجماهيرية العربية الليبية',
            LI: 'ليختنشتاين',
            LT: 'ليتوانيا',
            LU: 'لوكسمبورغ',
            MO: 'ماكاو',
            MK: 'مقدونيا',
            MG: 'مدغشقر',
            MW: 'ملاوى',
            MY: 'ماليزيا',
            MV: 'جزر المالديف',
            ML: 'مالى',
            MT: 'مالطا',
            MH: 'جزر مارشال',
            MQ: 'مارتينيك',
            MR: 'موريتانيا',
            MU: 'موريشيوس',
            YT: 'مايوت',
            MX: 'المكسيك',
            FM: 'ميكرونيزيا',
            MD: 'مولدوفا',
            MC: 'موناكو',
            MN: 'منغوليا',
            ME: 'الجبل الأسود',
            MS: 'مونتسيرات',
            MA: 'المغرب',
            MZ: 'موزمبيق',
            MM: 'ميانمار',
            NA: 'ناميبيا',
            NR: 'ناورو',
            NP: 'نيبال',
            NL: 'هولندا',
            AN: 'جزر الأنتيل الهولندية',
            NC: 'كاليدونيا الجديدة',
            NZ: 'نيوزيلندا',
            NI: 'نيكاراغوا',
            NE: 'النيجر',
            NG: 'نيجيريا',
            NU: 'نيوي',
            NF: 'جزيرة نورفولك',
            MP: 'جزر ماريانا الشمالية',
            NO: 'النرويج',
            OM: 'عمان',
            PK: 'باكستان',
            PW: 'بالاو',
            PS: 'الأراضي الفلسطينية المحتلة',
            PA: 'بنما',
            PG: 'بابوا غينيا الجديدة',
            PY: 'باراغواي',
            PE: 'بيرو',
            PH: 'الفلبين',
            PN: 'بيتكيرن',
            PL: 'بولندا',
            PT: 'البرتغال',
            PR: 'بورتوريكو',
            QA: 'قطر',
            RE: 'ريونيون',
            RO: 'رومانيا',
            RU: 'روسيا',
            RW: 'رواندا',
            BL: 'سانت بارتيليمي',
            SH: 'سانت هيلانة',
            KN: 'سانت كيتس ونيفيس',
            LC: 'سانت لوسيا',
            MF: 'سانت مارتن',
            PM: 'سان بيير وميكلون',
            VC: 'سانت فنسنت وجزر غرينادين',
            WS: 'ساموا',
            SM: 'سان مارينو',
            ST: 'ساو تومي وبرينسيبي',
            SA: 'المملكة العربية السعودية',
            SN: 'السنغال',
            RS: 'صربيا',
            SC: 'سيشل',
            SL: 'سيراليون',
            SG: 'سنغافورة',
            SK: 'سلوفاكيا',
            SI: 'سلوفينيا',
            SB: 'جزر سليمان',
            SO: 'الصومال',
            ZA: 'جنوب إفريقيا',
            GS: 'جورجيا الجنوبية وجزيرة ساندويتش.',
            ES: 'إسبانيا',
            LK: 'سريلانكا',
            SD: 'السودان',
            SR: 'سورينام',
            SJ: 'سفالبارد وجان ماين',
            SZ: 'سوازيلاند',
            SE: 'السويد',
            CH: 'سويسرا',
            SY: 'الجمهورية العربية السورية',
            TW: 'تايوان',
            TJ: 'طاجيكستان',
            TZ: 'تنزانيا',
            TH: 'تايلاند',
            TL: 'تيمور الشرقية',
            TG: 'توغو',
            TK: 'توكيلاو',
            TO: 'تونجا',
            TT: 'ترينيداد وتوباغو',
            TN: 'تونس',
            TR: 'تركيا',
            TM: 'تركمانستان',
            TC: 'جزر تركس وكايكوس',
            TV: 'توفالو',
            UG: 'أوغندا',
            UA: 'أوكرانيا',
            AE: 'الإمارات العربية المتحدة',
            GB: 'المملكة المتحدة',
            US: 'الولايات المتحدة الأمريكية',
            UM: 'جزر الولايات المتحدة النائية',
            UY: 'أوروغواي',
            UZ: 'أوزبكستان',
            VU: 'فانواتو',
            VE: 'فنزويلا',
            VN: 'فيتنام',
            VG: 'جزر فيرجن البريطانية',
            VI: 'جزر فيرجن الأمريكية.',
            WF: 'واليس وفوتونا',
            EH: 'الصحراء الغربية',
            YE: 'اليمن',
            ZM: 'زامبيا',
            ZW: 'زيمبابوي'
        },
    },
    it: {
        _name: 'Italiano',
        newDesign: {
            withdrawalRequests: 'Nessuna richiesta di prelievo',
            withdrawalRequestsText1: 'Non hai fatto alcuna richiesta di prelievo finora.',
            withdrawalRequestsText2: 'Una volta avviato un prelievo, i dettagli della transazione appariranno qui',
            pdfAttachment: 'allegato PDF',
            agent: "Agente",
            closed: 'Chiuso',
            pending: 'Pendente',
            approved: 'Aapprovato',
            declined: 'Rifiutato',
            document: 'Documento',
            frontSide: 'Lato Anteriore',
            backSide: 'Lato Posteriore',
            address: 'Indirizzo',
            frontSideDocument: 'Fronte del documento',
            creditCard: 'Carta di Credito',
            selfie: 'Selfie',
            other: 'Altre',
            noDocument: 'Non ci sono ancora documenti caricati',
            amountDeposited: 'Importo depositato',
            makeTransfer: 'Fare il trasferimento',
            otherDetails: 'Dettagli dell`ordine',
            manualWallets: 'Portafogli cripto',
            depositText: 'Il deposito viene elaborato entro 5-10 minuti',
            pendingBankTransfer: 'Hai un deposito di bonifico bancario in sospeso.',
            completeDeposit: 'Si prega di seguire le istruzioni qui sotto per completare il deposito',
            downloadInvoice: 'Scaricare la fattura',
            payInvoice: 'Pagare la fattura nella vostra banca',
            useYourBankText1: 'Utilizzare il sistema di pagamento online o offline della vostra banca per pagare la fattura.',
            useYourBankText2: 'Assicurarsi che i dettagli di pagamento sono corretti.',
            uploadReceipt: 'Caricare la ricevuta di conferma',
            afterMakingPayment: 'Dopo aver effettuato il pagamento, caricare la ricevuta per confermare il deposito',
            clickToUpload: 'Clicca per caricare',
            orDrag: 'o trascinare e rilasciare',
            fileFormat: 'PDF, JPG, JPEG, PNG meno di 10MB',
            waitForDeposit: 'Attendere la conferma del deposito',
            onceReceipt: 'Una volta che la ricevuta è caricata, verificheremo e approveremo il tuo deposito',
            paymentDetails: 'Dettagli di pagamento',
            nameOnCard: 'Nome su carta',
            cardNumber: 'Numero di carta',
            expirationDate: 'Data di scadenza',
            securityCode: 'Codice di sicurezza',
            continue: 'Continuare',
            viewAccounts: 'Visualizza account',
            continueWithdrawal: 'Continuano ritiro',
            accountBalance: 'Saldo',
            accountBalanceText1: 'Perché non posso inserire un trade?',
            accountsBalanceText2: 'Come faccio a depositare i fondi?',
            accountsBalanceText3: 'Voglio depositare denaro tramite Zelle',
            tradingActions: 'Azioni di Trading',
            tradingActionsText1: 'Come faccio a vendere allo scoperto?',
            tradingActionsText2: 'Come posso annullare un trade?',
            tradingActionsText3: 'Qual è l`importo minimo di scambio?',
            marketInfo: 'Informazioni di Mercato',
            marketInfoText1: 'Dove posso vedere le tendenze del mercato?',
            marketInfoText2: 'Quali sono i mercati migliori oggi?',
            securityAndSettings: 'Sicurezza e Impostazioni',
            securityAndSettingsText1: 'Come faccio a cambiare la mia password?',
            securityAndSettingsText2: 'Quali sono le caratteristiche di sicurezza?',
            securityAndSettingsText3: 'Come posso aggiornare le informazioni del mio account?',
            messageCopied: 'Messaggio copiato negli appunti',
            addSymbol: 'Aggiungere Simbolo',
            iHaveRead: 'Ho letto e accetto i termini e le condizioni',
            toggleToTurn: 'Toggle per attivare AutoTrade',
            selectTheRisk: 'Selezionare il livello di rischio',
            confirm: 'Confermare',
            cancel: 'Annullare',
            chooseClosingTime: 'Scegli un orario di chiusura',
            setOrderClosingTime: 'Impostare l`orario di chiusura dell`ordine',
            closingTime: 'Chiusura Tempo',
            date: 'Data',
            time: 'Tempo',
            setCloseTime: 'Impostare Chiudere di Tempo',
            '15m': '15 Minuti',
            '30m': '30 Minuti',
            '1h': '1 ora',
            custom: 'Personalizzabile',
            verifyOtp: 'Verificare la Tua OTP',
            enterDigits: 'Inserisci il codice a 6 cifre inviato al tuo [email/phone]',
            submit: 'Inviare',
            order: 'Ordine',
            closedSuccessfully: 'chiuso con successo',
            openSuccessfully: 'aprire con successo',
            deposit: 'deposito',
            withdrawal: 'recesso',
            completeVerification: 'Verifica Completa',
            verification: 'Verifica',
            avatar: 'Avatar',
            saveChanges: 'Salvare le Modifiche',
            darkMode: 'Modalità oscura',
            dashboardCurrency: 'Cruscotto valuta',
            language: 'Linguaggio',
            twoFactor: '2-fattore protezione',
            googleTwoFactorIsActive: 'Google 2-fattore autenticatore è attivo',
            activateCodeForGoogle: 'Attiva codice per Google Authenticator',
            ifYouWishDisableEnterCode: 'Se si desidera disattivare la protezione, inserire il codice app autenticatore',
            scanOrManuallyEnter: 'Scansione o inserire manualmente il codice QR in Google 2FA per aggiungere un token',
            copyCode: 'Copiare Codice',
            qrCode: 'Codice QR',
            activateAndEnterAuthenticator: 'Attiva l`Autenticatore e inserisci un codice generato nel campo sottostante ',
            enterTheDigitsCodeGenerated: 'Inserire il codice a 6 cifre generato',
            disableTwoFactor: 'Disattivare la protezione a due fattori',
            activateTwoFactor: 'Attivi la 2FA protezione',
            fileAdded: 'file con successo aggiunto',
            theVerificationStatus: 'Lo stato di verifica può essere visualizzato nell`elenco dei documenti caricati',
            confirmWithdrawal: 'Conferma prelievo',
            minimumWithdrawal: 'Importo minimo di prelievo è',
            welcomeToTradingPlatform: 'Benvenuti nella piattaforma di trading',
            signIn: 'Accedere',
            activeAccount: 'Account attivo',
            save: 'Salvare',
            selectCurrencyAndPeriod: 'Seleziona una valuta e un periodo di abbonamento',
            savingsNotFound: 'Conti di Risparmio non trovati',
            pleaseCheckInvestment: 'Si prega di controllare i dettagli del vostro investimento per continuare',
            allPeriodInvestment: 'Tutto investimento periodo',
            pct_0: 'Flessibili',
            pct_30: '1 Mese',
            pct_60: '1 Trimestre',
            pct_90: '6 Mese',
            pct_180: '6 Mese',
            pct_365: '1 Anno',
            savingsCreated: 'Conto di risparmio creato con successo',
            transferDetails: 'Informazioni sui Trasferimenti',
            transferAmount: 'Importo del Trasferimento',
            investmentDetails: 'dettagli investimento',
            investment: 'Investimento',
            Period: 'Periodo',
            created: 'Creato',
            releaseTime: 'Tempo di Rilascio',
            viewSavingsAccounts: 'Vista Conto di Risparmio',
            continueInvestment: 'Continuare Investimento',
            chooseSubscription: 'Scegli il tuo Abbonamento',
            selectCurrencyAndSubscription: 'Seleziona una valuta e un periodo di abbonamento per continuare.',
            rate: 'Tasso',
            accountTransferFunds: 'Conto per il trasferimento di fondi da',
            amount: 'Quantità',
            'in': 'in',
            openSavingsAndInvest: 'Aprire un conto di risparmio e investire',
            strongSell: 'Forte vendi',
            sell: 'Vendere',
            neutral: 'Neutro',
            buy: 'Comprare',
            stringBuy: 'Forte comprare',
            chooseCryptoWallet: 'Scegli un portafoglio di criptovaluta',
            chooseMethod: 'Scegli un metodo',
            maximumDeposit: 'Massimo deposito è',
            minimumDeposit: "Il deposito minimo è",
            pleaseCompleteAddress: 'Per favore completate il tuo indirizzo',
            pleaseCompleteCity: 'Per favore completate il tuo città',
            pleaseCompleteCountry: 'Per favore completate il tuo contadina',
            pleaseCompleteZip: 'Per favore completate il tuo codice postale',
            amountDeposit: 'Importo Caparra',
            transactionStatus: 'Lo stato dell`operazione',
            paymentMethod: 'Metodo di Pagamento',
            depositTo: 'Deposito per',
            depositAddress: 'Depositare indirizzo',
            scanTheQr: 'Scansiona il codice QR o copia del deposito indirizzo',
            wallet: 'Portafoglio',
            copyDepositAddress: 'Copiare Deposita Indirizzo',
            copy: 'Copia',
            addressCopied: 'Indirizzo copiato negli appunti',
            '5min:': '5-10 minuti',
            '2-5days': '2-5 giorni / 5-10 minuti',
            otherMethod: 'Altro metodo',
            profileSettings: 'Impostazioni del Profilo',
            savingsAccounts: 'Conti di Risparmio',
            subscribe: 'Abbonarsi',
            toCompleteVerification: 'Per completare il processo di verifica del tuo account, ti preghiamo di fornire copie chiare e leggibili dei seguenti documenti',
            amountToWithdrawal: 'Ammantare di prelieva',
            cardDetails: 'Dati della carta',
            cardHolderName: 'Nome del titolare della carta',
            withdrawalSuccessful: 'Ritiro Successo',
            selectWithdrawalMethod: 'Selezionate metodo di ritiro',
            creditDebit: 'Carta di credito/debito',
            crypto: 'Criptovalute',
            cryptoWalletAddress: 'Criptovalute-wallet indirizzo',
            cryptoSubtitle: 'Prelievi cripto sul tuo portafoglio 🚀',
            bankWithdrawal: 'Prelievo bancario',
            bankSubtitle: 'Conto bancario 🏦',
            zelleSubtitle: 'Bonifici bancari veloci ⚡🏦',
            tradingResults: 'Risultati commerciali',
            day: 'Giorno',
            month: 'Mese',
            noTradingData: 'Nessun dato di trading disponibile',
            startTradingToSee: 'Inizia a fare trading per vedere i tuoi risultati qui.',
            yourPerformanceMetric: 'Le tue metriche di prestazione verranno visualizzate non appena i dati saranno disponibili.',
            favorite: 'Preferito',
            all: 'Tutto',
            availableFullTime: 'Disponibile 24/7',
            yourProfileAndSettings: 'Il tuo profilo e le tue impostazioni',
            switchToNewDesignMessage: 'Passa subito al nostro nuovo design della piattaforma!',
            switchToOldDesignMessage: 'Puoi ancora utilizzare il design classico per un periodo limitato',
            switchToOldDesignButton: 'Passa al nuovo design',
            switchToNewDesignButton: 'Torna al design classico',
            generateInvoice: 'Genera fattura',
            downloadPdf: 'Scarica PDF',
            confirmDeposit: 'Conferma deposito',
            howCanIAssist: 'Come posso aiutarti oggi?',
            maxFileSize: 'La dimensione massima del file è 5 MB, i formati accettati sono JPEG, PNG o GIF',
            uploadPhoto: 'Carica foto',
            featuresDisabled: 'Questa funzione è disattivata',
            welcome: 'Benvenuto',
            signUp: 'Iscriviti',
            registration: 'Registrazione',
            pleaseConfirmAgreement: 'Conferma l’accordo con il cliente',
            pleaseEnterPassword: 'Inserisci una password',
            passwordNotSame: 'Le password non corrispondono',
            noActiveOrders: 'Nessun ordine attivo',
            itLikeNoHaveActiveOrders: 'Sembra che non ci siano ordini attivi al momento',
            cryptoWallet: 'Portafoglio crypto',
            bankCard: 'Banca/Carta',
            selected: 'Selezionato',
            amountWithdrawal: 'Importo prelevato',
            withdrawalTo: 'Prelievo su',
            governmentIssuedPhoto1: 'Documento d’identità emesso dal governo (ad es. passaporto, patente di guida, carta d’identità nazionale)',
            governmentIssuedPhoto2: 'Permesso di soggiorno (se applicabile)',
            governmentIssuedPhoto3: 'Il documento deve essere valido (non scaduto)',
            governmentIssuedPhoto4: 'Il nome completo, la data di nascita e la fotografia devono essere visibili.',
            uploadRecent1: 'Carica una bolletta recente, un estratto conto bancario o un contratto di affitto emesso negli ultimi 3 mesi',
            uploadRecent2: 'Assicurati che il tuo nome completo e l’indirizzo di residenza attuale siano chiaramente visibili.',
            uploadCopy1: 'Carica una copia del fronte della tua carta di credito assicurandoti che le prime sei e le ultime quattro cifre siano visibili',
            uploadCopy2: 'Coprire le cifre centrali per sicurezza',
            takeAndUpload1: 'Scatta e carica un selfie con il tuo documento d’identità accanto al viso',
            takeAndUpload2: 'Assicurati che il tuo viso e il documento siano chiaramente visibili',
            otherDocuments: 'Altri documenti',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: "Carica un'immagine chiara e leggibile del tuo documento del numero di previdenza sociale (SSN). Assicurati che tutti i dettagli siano visibili e privi di ostruzioni.",
            frontSideSsnDocument: 'Lato anteriore del documento SSN',
            answerSaved: 'Le risposte sono state salvate',
            cryptoWalletDetails: 'Dettagli del wallet crypto',
            bitcoinWalletAddress: 'Indirizzo del wallet Bitcoin',
            recovery: 'Recupero',
            passwordRecovery: 'Recupero password',
            searchCountry: 'Cerca paese',
            questionnaire: 'Questionario',
            questionnaireText: 'Si prega di completare il questionario per verificare la tua identità',
            sendAnswers: 'Invia risposte',
        },
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Trai profitto & blocca le perdite',
            takeProfit: 'Trai profitto',
            stopLoss: 'Blocca le perdite',
            updateOrder: 'Aggiorna ordine',
        },
        balanceModal: {
            balance: 'Saldo',
            credit: 'Credito',
            equity: 'Equità',
            practice: 'PRATICA',
            real: 'REALE',
            account: 'CONTO',
            invested: 'Investito',
            profit: 'Profitto',
            loss: 'Perdita',
            margin: 'Margine',
            marginLevel: 'Livello di margine',
            marginFree: 'Margine gratuito'
        },
        header: {
            cfd: 'CFD',
            platform: 'Piattaforma',
            deposit: 'Deposito'
        },
        sideMenu: {
            marketWatch: 'Osservatorio di mercato',
            activeOrders: 'Ordini attivi',
            tradingHistory: 'Storia di trading',
            economicCalendar: 'Calendario',
            marketNews: 'Notizie di mercato'
        },
        closePositionModal: {
            closeOrder: 'Posizione chiusa',
            areYouSure: 'Sei sicuro di chiudere la posizione',
            buy: 'Acquista',
            sell: 'Vendi',
            yes: 'Sì',
            no: 'No',
        },
        pciDssModal: {
            desc: `La conformità allo standard di sicurezza dei dati del settore delle carte di pagamento (PCI DSS) è richiesta per tutte le entità che memorizzano, elaborano o trasmettono dati del titolare della carta Visa/Master Card/Stripe, incluse istituzioni finanziarie, commercianti e fornitori di servizi. È lo standard di sicurezza globale per tutte le entità che memorizzano, elaborano o trasmettono dati del titolare della carta e/o dati di autenticazione sensibili. PCI DSS stabilisce un livello base di protezione per i consumatori e aiuta a ridurre frodi e violazioni dei dati in tutto l'ecosistema dei pagamenti.`
        },
        chart: {
            addNewChart: 'Aggiungi nuova tabella',
        },
        symbolsModal: {
            watchlist: 'Elenco di controllo',
            asset: 'Attività',
            price: 'Prezzo',
            changePct: 'Cambio 24h'
        },
        pendingModal: {
            pending: 'In sospeso',
            assetPrice: 'Prezzo dell\'attività',
            current: 'Attuale',
            revert: 'Ritorno al prezzo di mercato',
            automatically: 'La posizione verrà aperta automaticamente quando il prezzo raggiungerà questo livello'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volume',
            lots: 'lotti',
            units: 'unità',
            currency: 'valuta',
            contractSize: 'Dimensione del contratto',
            position: 'Posizione',
            margin: 'Margine',
            freeMargin: 'Margine gratuito',
            takeProfitStopLoss: 'Trai profitto & blocca le perdite',
            pending: 'Sospeso',
            market: 'Mercato',
            leverage: 'Leva',
            spread: 'Spread',
            notSet: 'Non impostato',
            at: 'a',
            buy: 'acquista',
            sell: 'vendi',
        },
        footer: {
            supportBanner: 'OGNI GIORNO, 24 ORE SU 24',
            currentTime: 'ORA ATTUALE',
            liveChat: 'Chat dal vivo'
        },
        statusBar: {
            balance: 'Saldo',
            credit: 'Credito',
            equity: 'Equità',
            margin: 'Margine',
            marginLevel: 'Livello del margine',
            freeMargin: 'Margine gratuito',
            pnl: 'PnL',
            profitTotal: 'Durata della vita PnL'
        },
        accountModal: {
            dateRegistered: 'Data di registrazione',
            userId: 'ID utente',
            dashboard: 'Pannello',
            personalData: 'Dati personali',
            deposit: 'Deposito',
            withdrawFunds: 'Prelievo di fondi',
            savings: 'Risparmi',
            settings: 'Impostazioni',
            logout: 'Disconnessione'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Effettuare un deposito',
            practiceAccount: 'Conto corrente',
            realAccount: 'Conto reale',
            noWithdrawals: 'Nessun prelievo',
            easyWithdrawals: 'Prelievi facili',
            allAssets: 'Tutte le attività disponibili',
            fullFledged: 'Piattaforma completa',
            fillUpTo: 'Riempire fino a',
            freeReplenishment: 'Rifornimento gratuito',
            topUp: 'Ricaricare il conto',
            minimumAmount: 'Importo minimo',
            canSwitch: 'Si può passare da un conto all\'altro in qualsiasi momento'
        },
        gridsModal: {
            chartGrids: 'GRIGLIE DELLA TABELLA',
            chart1: '1 tabella',
            chart2: '2 tabelle',
            chart3: '3 tabelle',
            chart4: '4 tabelle',
        },
        noConnectionModal: {
            connectionLost: 'La connessione con il server è stata persa',
            retryNow: 'Riprova ora',
        },
        loginModal: {
            loginToTradeRoom: 'Accedi a Traderoom',
            email: 'Indirizzo e-mail',
            enterEmail: 'Inserisci la tua e-mail',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA Codice (se abilitato)',
            twoFactorAuth: 'Autenticazione a due fattori',
            password: 'Password',
            yourPassword: 'La tua password',
            signIn: 'Accedi',
            dontHaveAccount: 'Non hai un account?',
            restore: 'Ripristina',
            fullName: 'Nome completo',
            havePromo: 'Non ho codici promozionale',
            promo: 'Codice promozionale',
            login: 'Accedi',
            haveAccount: 'Hai già un conto?',
            hide: 'Nascondi',
            goTo: 'Torna indietro'
        },
        newPasswordModal: {
            setNewPassword: 'Imposta nuova password',
            newPassword: 'Nuova password',
            confirmNewPassword: 'Conferma nuova password',
            setButton: 'Impostazione'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Crea un nuovo conto',
            email: 'Indirizzo e-mail',
            firstName: 'Nome',
            lastName: 'Cognome',
            country: 'Paese',
            phone: 'Telefono',
            password: 'Password',
            createAccount: 'Crea conto',
            currency: 'Valuta',
            privacyAndPolicy: "Privacy e Politica",
            customerAgreement: 'Accordo con il cliente',

        },
        forgotPasswordModal: {
            forgotPassword: 'Password dimenticata?',
            submitEmail: 'Per favore, invia l\'e-mail utilizzata per la registrazione, controlla la tua casella di posta e segui le istruzioni fornite',
            submit: 'Invia'
        },
        notifications: {
            error: 'Errore',
            success: 'Successo',
            deposit: 'Deposito',
            withdrawal: 'Prelievo',
            depositApproved: 'è stato approvato',
            depositDeclined: 'Il deposito è stato rifiutato',
            withdrawalApproved: 'Il prelievo è stato approvato',
            withdrawalDeclined: 'Il prelievo è stato rifiutato'
        },
        markets: {
            favorites: 'Lista di controllo', 
            all: 'Tutti i simboli' ,
            forex: 'Forex',
            stocks: 'Stocks',
            commodities: 'Materie prime',
            indices: 'Indici',
            crypto: 'Crypto',
            metals: 'Metalli',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'ACQUISTA',
            profitCalculator: 'Calcolatore di profitto',
            sell: 'VENDI',
            volume: 'Volume',
            entryPrice: 'Prezzo di ingresso',
            takeProfit: 'Trai profitto',
            stopLoss: 'Blocca le perdite',
            maxPosition: 'Posizione massima',
            calculate: 'Calcolare',
            calculationsFor: 'Calcoli per',
            leverage: 'Leva',
            requiredMargin: 'Margine richiesto',
            profitFromTP: 'Utile da TP',
            lossFromSL: 'Perdita da SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposito andato a buon fine',
            text: 'Il tuo deposito è stato elaborato con successo!',
        },
        depositFailModal: {
            title: 'Deposito fallito',
            text: 'Il tuo deposito non è stato elaborato.',
        },
        widgetMarketWatch: {
            marketWatch: 'Osservatorio del mercato',
            search: 'Ricerca...',
        },
        widgetOrders: {
            commission: 'Commissione',
            volume: 'Volume',
            margin: 'Margine',
            active: 'Attivo',
            pending: 'In sospeso',
            activeOrders: 'Ordini attivi',
            portfolio: 'Portfolio',
            allPositions: 'Tutte le posizioni',
            noPositionsLine1: 'Non hai aperte',
            noPositionsLine2: 'posizioni ancora',
            show: 'Mostra',
            more: 'di più',
            less: 'meno',
            purchaseTime: 'Tempo di acquisto',
            closePosition: 'Posizione chiusa',
            priceOpen: 'Prezzo di acquisto',
            priceSl: 'Prezzo del blocco della perdita',
            priceTp: 'Prezzo di presa di profitto',
            type: 'Direzione della posizione',
            pnl: 'Profitto/Perdita',
            cancelOrder: 'Annullare l\'ordine',
            orderType: 'Tipo di ordine',
            cancelled: 'Cancellato',
            tradingHistory: 'Storia del trading',
            noHistoryLine1: 'Non hai ancora',
            noHistoryLine2: 'concluso alcun affare',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendario'
        },
        widgetMarketNews: {
            marketNews: 'Notizie di mercato'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Ordini attivi',
            ordersHistory: 'Cronologia degli ordini',
            id: 'ID',
            symbol: 'Simbolo',
            type: 'Tipo',
            volume: 'Volume',
            openPrice: 'Prezzo di apertura',
            openTime: 'Orario di apertura',
            closePrice: 'Prezzo di chiusura',
            closeTime: 'Orario di chiusura',
            sl: 'SL',
            tp: 'TP',
            price: 'Prezzo',
            pnl: 'PnL',
            actions: 'Azioni',
            edit: 'Modifica',
            close: 'Chiusi',
            commission: 'Commissione',
            swap: 'Scambio',
            swapLong: 'Scambio lungo',
            swapShort: 'Scambio breve'
        },
        assetInfoModal: {
            assetInfo: 'Info sulle attività',
            tradingConditions: 'Condizioni di trading',
            information: 'Cambio sessione',
            bid: 'Offerta',
            ask: 'Chiedi',
            sessionChange: 'Cambio sessione',
            tradeNow: 'Scambia ora',
            opens: 'Apri',
            closes: 'Chiudi',
            at: 'a',
            open247: 'Aperto 24/7',
            today: 'oggi',
            tomorrow: 'domani',
            sunday: 'Domenica',
            monday: 'Lunedì',
            tuesday: 'Martedì',
            wednesday: 'Mercoledì',
            thursday: 'Giovedì',
            friday: 'Venerdì',
            saturday: 'Sabato',
            contractSpecification: 'Specifiche del contratto',
            symbol: 'Simbolo',
            name: 'Nome',
            market: 'Mercato',
            baseCurrency: 'Valuta base',
            digits: 'Digita',
            lotSize: 'Dimensione del lotto',
            lotStep: 'Passo del lotto',
            minVolume: 'Volume minimo',
            maxVolume: 'Volume massimo',
            leverage: 'Leva',
            commission: 'Commissione',
            swapLong: 'Scambio lungo',
            swapShort: 'Scambio corto',
            schedule: 'Programma',
            weekday: 'Giorno feriale',
            tradingTime: 'Tempo di trading',
            closed: 'chiuso',
            sell: 'Vendi',
            buy: 'Acquista',
            low: 'Basso',
            high: 'Alto',
            oneHourChange: 'Cambio di 1 ora',
            oneDayChange: 'Cambio di 1 giorno',
            oneWeekChange: 'Cambio di 1 settimana',
            oneMonthChange: 'Cambio di 1 mese',
            threeMonthsChange: 'Cambio di 3 mesi',
            oneYearChange: 'Cambio di 1 anno',
            loading: 'Caricando...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'Operazioni al giorno:',
            desc: `Il trading di CFD e altri prodotti a leva può comportare perdite. Prima di fare trading, i clienti dovrebbero leggere le dichiarazioni sui rischi pertinenti nella nostra pagina di divulgazione dei rischi. Il trading automatico non garantisce risultati. L'azienda non si assume alcuna responsabilità per la perdita di fondi nel trading automatico. Assicurati di comprendere appieno i rischi e di adottare misure per gestirli.`,
            accept: 'Accept',
            save: 'Salva',
            cancel: 'Annulla',
        },
        cardBox: {
            name: 'Nome',
            cardNumber: 'Numero di carta',
            expiration: 'Scadenza (mm/aa)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Commercio",
            startTrading: 'Iniziare il trading',
            deposit: 'Deposito',
            dashboard: 'Pannello',
            personalInfo: 'Informazioni personali',
            contactInfo: 'Informazioni di contatto',
            withdrawal: 'Prelievo',
            verification: 'Verifica',
            accounts: 'Conti',
            liveChat: 'Chat in diretta',
            savings: 'Risparmi',
            referrals: 'Programma di referenza',
            settings: 'Impostazioni',
            logOut: 'Disconnessione',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Il portafoglio per questo metodo di deposito non è impostato. Si prega di contattare il proprio agente o il team di supporto.',
            addressReceipt: 'Attendere il caricamento dell\'indirizzo',
            makeDeposit: 'EFFETTUARE UN DEPOSITO',
            instant: 'Immediato',
            minutes: 'minuti',
            hours: 'ore',
            days: 'giorni',
            amount: 'Importo',
            continue: 'Continua',
            qrCode: 'CODICE QR',
            depositAddress: 'INDIRIZZO DI DEPOSITO',
            copy: 'Copia',
            last: 'ULTIMO',
            deposits: 'DEPOSITI',
            time: 'Orario',
            currency: 'Valuta',
            status: 'Status',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Attendere prego. Connessione al fornitore di servizi di pagamento...',
            cardPayment: 'Pagamento con carta',
            inOrder: 'Per inviare l\'importo',
            pleaseProvide: 'si prega di fornire i dati della carta:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'Nessun ordine ancora',
            totalBalance: 'Saldo totale',
            totalPnl: 'Totale PNL',
            profitableOrders: 'Ordini redditizi',
            roi: 'ROI',
            activityLog: 'Registro delle attività',
            loginFromIp: 'Accesso da IP',
            tradingResults: 'Risultati del trading',
            week: 'Settimana',
            month: 'Mese',
            year: 'Anno',
            successRate: 'Tasso di successo',
            closedWithProfit: 'Chiuso con profitto',
            closedWithLoss: 'Chiuso con perdita',
            account: 'Conto',
            balance: 'Saldo',
            leverage: 'Leva',
            credit: 'Credito',
            tradeNow: 'Scambia ora',
            usingCurrentRate: 'al tasso di cambio corrente',
            totalDeposits: 'Depositi totali',
            totalWithdrawals: "Prelievi totali",
            returnOnInvestment: "Ritorno sull'investimento"
        },
        fullScreenPersonal: {
            personalDetails: 'Dettagli personali',
            profilePhoto: 'Foto profilo',
            firstName: 'Nome',
            country: 'Paese',
            lastName: 'Cognome',
            address: 'Indirizzo',
            email: 'E-mail',
            phone: 'Numero di telefono',
            saveChanges: 'Salva i cambiamenti',
            state: 'Città',
            postcode: 'CAP',
            created: "Account creato il",
        },
        fullScreenAvatar: {
            dropFile: 'Lascia un file nel cerchio qui sopra per caricarlo',
            notAllowed: 'Non è consentito pubblicare',
            notAllowedLine1: 'Foto di natura esplicitamente sessuale o pornografica',
            notAllowedLine2: 'Immagini che incitano all\'odio o all\'aggressione etnica o razziale.',
            notAllowedLine3: 'Foto che coinvolgono persone di età inferiore ai 18 anni',
            notAllowedLine4: 'Foto protette da copyright di terzi',
            notAllowedLine5: 'Immagini di dimensioni superiori a 5 MB e in un formato diverso da JPG, GIF o PNG',
            requirements: 'Il tuo volto deve essere chiaramente visibile nella foto. Tutte le foto e i video caricati dall\'utente devono essere conformi a questi requisiti, altrimenti potranno essere rimossi.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Prelievo',
            requestNewWithdrawal: 'RICHIEDERE UN NUOVO PRELIEVO',
            amount: 'Importo',
            currentBalance: 'Il saldo del conto corrente è',
            withdrawAll: 'Prelevare tutti',
            requestWithdrawal: 'Richiesta di ritiro',
            last: 'ULTIMO',
            withdrawalRequests: 'RICHIESTE DI PRELIEVO',
            time: 'Orario',
            currency: 'Valuta',
            info: 'Info',
            status: 'Status',
            bank: 'Banca',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Carta',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Dettagli del prelievo',
            address: 'Indirizzo portafoglio',
            bankDetails: 'Dettagli bancari',
            bankAccount: 'Numero di conto',
            bankHolder: 'Nome del titolare del conto',
            bankIban: 'IBAN',
            bankSwift: 'Codice SWIFT bancario',
            recipientAddress: 'Indirizzo del destinatario',
            routingNumber: 'Numero di instradamento bancario',
            bankBeneficiary: 'Nome della banca del beneficiario',
            bankAddress: 'Indirizzo della banca',
            bankCode: 'Codice bancario (BSB)',
            desc: 'Descrizione',
            cardNumber: 'Numero carta',
            cardHolder: 'Nome del titolare della carta',
        },
        fullScreenVerification: {
            dragAndDrop: 'trascinare e rilasciare il documento in quest\'area',
            documentsList: 'ELENCO DEI DOCUMENTI CARICATI',
            document: 'Documento',
            timeUploaded: 'Ora di caricamento',
            timeProcessed: 'Orario elaborato',
            status: 'Status',
            types: ['Prova d\'identità', 'Prova di residenza', 'Fronte carta di credito', 'Retro della carta di credito', 'Retro del documento d\'identità', 'Selfie', 'Other'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Carica',
            isConfirmed: 'è confermato',
            uploadAnotherDocument: 'Caricare un altro documento',
            isUnderReview: 'è in fase di revisione',
            questionnaire: 'Questionario',
            sendAnswers: 'Invia risposte',
            progressBarOf: 'di',
            progressBarText: 'i documenti sono stati caricati e confermati'
        },
        fullScreenAccounts: {
            manageAccounts: 'Trasferire fondi tra i vostri conti',
            transferFrom: 'Da un conto',
            transferTo: 'Ad un conto',
            amount: 'Importo',
            makeTransfer: 'Effettuare il trasferimento',
            yourAccounts: 'I tuoi conti',
            account: 'Conto',
            currency: 'Valuta',
            balance: 'Saldo',
            credit: 'Credito',
            makeActive: 'Rendi attivo',
            active: 'Attivo'
        },
        fullScreenSupport: {
            sendMessage: 'Invia messaggio'
        },
        fullScreenSavings: {
            pct_0: 'Flessibile',
            pct_30: 'Bloccato 1 mese',
            pct_90: 'Bloccato 1 trimestre',
            pct_180: 'Bloccato 6 mesi',
            pct_365: 'Bloccato 1 anno',
            savings: 'Risparmi',
            detailedInformation: 'Informazioni dettagliate',
            pleaseSelect: 'Selezionare una valuta e un periodo',
            openSavings: 'Risparmi aperti',
            currentTime: 'Ora corrente',
            releaseTime: 'Tempo di rilascio',
            currencyChosen: 'Valuta scelta',
            periodChosen: 'Periodo scelto',
            yourRate: 'La tua tariffa',
            yourEarnings: 'I tuoi guadagni',
            accountFrom: 'Conto da cui trasferire i fondi',
            enterAmount: 'Inserire importo',
            in: 'In',
            openAndInvest: 'Aprire un conto di risparmio e investire',
            investment: 'Investimento',
            period: 'Periodo',
            daysTotal: 'Giorni totali',
            finalEarnings: 'Guadagni finali',
            created: 'Creato',
            daysElapsed: 'Giorni rimanenti',
            days: 'giorni',
            earnings: 'Guadagni',
            fundsReleasedIn: 'Fondi erogati in',
            claim: 'Richiedi',
            name: "Nome",
        },
        fullScreenSettings: {
            updatePassword: 'AGGIORNA PASSWORD',
            currentPassword: 'Password corrente',
            newPassword: 'Nuova Password',
            changePassword: 'Cambia Password',
            activationForGoogle: 'Codice di attivazione per Google Authenticator',
            activateAndEnter: 'Attiva l\'Autenticatore e inserisci un codice generato nel campo sottostante',
            qrCode: 'Codice QR',
            activateProtection: 'Attivare la protezione 2FA',
            protectionActive: 'La protezione 2FA è attiva',
            twoFactorCode: 'Codice 2FA',
            disableProtection: 'Disattivare la protezione 2FA',
            language: 'Lingua',
            dashboardCurrency: 'Pannello valuta',
            confirmNewPassword: 'Conferma nuova password',
        },
        fullScreenReferrals: {
            yourLink: 'IL TUO LINK DI AFFILIAZIONE',
            referredFriends: 'AMICI INDIRIZZATI',
            id: 'ID',
            name: 'Nome',
            registered: 'Registra',
            level: 'Livello',
            payout: 'Pagamento',
            lastPayouts: 'ULTIMI PAGAMENTI DI AFFILIAZIONE',
            time: 'Tempo',
            amount: 'Importo',
            currency: 'Valuta',
            info: 'Info',
            referralDesc:"Il Programma di Referral è progettato per ricompensarti per l'espansione della nostra comunità di investitori di valore.",
            shareLink:"Condividi il link:",
            subtitle:"Forniscigli il tuo nome, email o ID di trading.",
            descSuccesfull:"Una volta che il tuo referral si registra con successo, il tuo consulente finanziario lo contatterà per aiutarlo nei primi passi del suo percorso di investimento nel modo più efficace e professionale.",
            descTracked:"Tutti i suoi contributi sono monitorati sotto la tua referral."
        },
        months: {
            Jan: 'Gen',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Mag',
            Jun: 'Giu',
            Jul: 'Lug',
            Aug: 'Ago',
            Sep: 'Set',
            Oct: 'Ott',
            Nov: 'Nov',
            Dec: 'Dic'
        },
        orderTypes: ['Acquista', 'Vendi', 'Limite di acquisto', 'Limite di vendita', 'Stop acquisti', 'Stop vendita'],
        statuses: ['In sospeso', 'Approvato', 'Rifiutato', 'Elaborazione'],
        errors: {
            NOT_FOUND: 'Errore di richiesta',
            FORBIDDEN: 'Accesso negato. Effettuare nuovamente il login',
            SERVER_ERROR: 'Azione fallita. Riprovare più tardi',
            INVALID_ACCOUNT: 'Sessione scaduta. Effettuare nuovamente il login',
            INVALID_EMAIL: 'Indirizzo e-mail non valido',
            INVALID_PHONE: 'Numero di telefono non valido',
            INVALID_COUNTRY: 'Paese non valido',
            INVALID_LOGIN: 'Credenziali di accesso non valide',
            USER_BLOCKED: 'L\'account è bloccato. Contattare l\'assistenza',
            USER_REGISTERED: 'L\'utente con l\'e-mail o il numero di telefono inseriti è già registrato',
            INVALID_USER: 'L\'utente non è stato trovato',
            REJECTED: 'Il funzionamento non è consentito a causa delle restrizioni della piattaforma',
            INVALID_OLD_PASSWORD: 'La vecchia password non è valida',
            INVALID_SYMBOL: 'Simbolo di trading non valido',
            INVALID_SYMBOL_PRECISION: 'Invalid symbol precision',
            INVALID_FRAME: 'Cornice della tabella non valida',
            INVALID_CURRENCY: 'Valuta non valida',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Tipo di trading del conto non valido',
            INSUFFICIENT_FUNDS: 'Fondi insufficienti',
            INVALID_WITHDRAWAL: 'Withdrawal is not found',
            INVALID_STATUS: 'Status non valido',
            INVALID_FILE: 'Estensione file non valida o file troppo grande',
            INVALID_DOCUMENT: 'Il documento non è stato trovato',
            INVALID_NAME: 'Nome non valido',
            INVALID_LOT_SIZE: 'Dimensione del lotto non valida',
            INVALID_LOT_STEP: 'Fase del lotto non valida',
            INVALID_MIN_VOLUME: 'Volume minimo non valido',
            INVALID_MAX_VOLUME: 'Volume massimo non valido',
            INVALID_GROUP: 'Gruppo non valido',
            INVALID_SPREAD: 'Spread non valido',
            REQUEST_TIMEOUT: 'La richiesta è scaduta. Riprovare più tardi',
            EXISTING_OPEN_ORDERS: 'Hai un ordine aperto, chiudi prima l\'ordine per questo utente e riprova.',
            INVALID_TYPE: 'Tipo di ordine non valido',
            INVALID_VOLUME: 'Volume dell\'ordine non valido',
            INVALID_SL: 'Valore di arresto della perdita non valido',
            INVALID_TP: 'Valore di presa di profitto non valido',
            INVALID_PRICE: 'Prezzo dell\'ordine non valido',
            INVALID_EXPIRATION: 'Scadenza dell\'ordine non valida',
            NOT_ENOUGH_MARGIN: 'Margine insufficiente',
            INVALID_ORDER: 'L\'ordine non è stato trovato',
            MARKET_CLOSED: 'Il trading non è disponibile. Si prega di riprovare',
            INVALID_BALANCE_TYPE: 'Tipo di operazione del saldo non valido',
            INVALID_HASH: 'Hash non valido',
            HASH_EXPIRED: 'Il link per la reimpostazione della password è scaduto. Richiedine una nuova',
            INVALID_CODE: 'Codice 2FA non valido',
            CHAT_DISABLED: 'L\'accesso alla chat di supporto è stato chiuso',
            WITHDRAWAL_NOT_ALLOWED: 'L\'accesso ai prelievi è stato chiuso',
            TRADING_DISABLED: 'Il trading è disabilitato per il conto corrente',
            PENDING_DEPOSITS_LIMIT: 'Superamento del limite dei depositi',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'È consentito un solo prelievo in sospeso',
            LOCAL_DEPOSIT_ONLY_REAL: 'Il deposito è disponibile solo per il conto reale',
            LOCAL_DEPOSIT_POSITIVE: 'L\'importo del deposito deve essere superiore a 0',
            LOCAL_INVALID_CARD_NUMBER: 'Numero di carta non valido',
            LOCAL_INVALID_CARD_EXPIRATION: 'Scadenza della carta non valida',
            LOCAL_INVALID_CVC: 'Codice CVV/CVC non valido',
            LOCAL_PASSWORDS_NOT_MATCH: 'Le password non corrispondono, inserire nuovamente',
            LOCAL_NO_TRADING_ACCOUNTS: 'Non sono disponibili conti di trading. Si prega di contattare l\'assistenza',
            LOCAL_MIN_DEPOSIT: 'Il deposito minimo è di $',
            LOCAL_MIN_WITHDRAWAL: 'L\'importo minimo di prelievo è di $',
            LOCAL_INVALID_FILE_TYPE: 'Formato di file non supportato. Allegare solo JPG, PNG o PDF',
            LOCAL_MIN_STAKING: 'Il deposito minimo per questa valuta è',
            FLOOD_REJECTED: 'L\'invio di messaggi è troppo frequente. Riprovare più tardi'
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Isole Aland',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'Samoa Americane',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antartide',
            AG: 'Antigua e Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Biellorussia',
            BE: 'Belgio',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnia ed Erzegovina',
            BW: 'Botswana',
            BV: 'Isola di Bouvet',
            BR: 'Brasile',
            IO: 'Territorio britannico dell\'Oceano Indiano',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambogia',
            CM: 'Camerun',
            CA: 'Canada',
            CV: 'Capo Verde',
            KY: 'Isole Cayman',
            CF: 'Repubblica Centrafricana',
            TD: 'Ciad',
            CL: 'Cile',
            CN: 'Cina',
            CX: 'Isola di Natale',
            CC: 'Isole Cocos (Keeling)',
            CO: 'Colombia',
            KM: 'Comore',
            CG: 'Congo',
            CD: 'Congo, Repubblica Democratica',
            CK: 'Isole Cook',
            CR: 'Costa Rica',
            CI: 'Cote D\'Avorio',
            HR: 'Croazia',
            CU: 'Cuba',
            CY: 'Cipro',
            CZ: 'Repubblica Ceca',
            DK: 'Danimarca',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Repubblica Dominicana',
            EC: 'Ecuador',
            EG: 'Egitto',
            SV: 'El Salvador',
            GQ: 'Guinea Equatoriale',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Etiopia',
            FK: 'Isole Falkland (Malvinas)',
            FO: 'Isole Faroe',
            FJ: 'Fiji',
            FI: 'Finlandia',
            FR: 'Francia',
            GF: 'Guiana Francese',
            PF: 'Polinesia francese',
            TF: 'Territori meridionali francesi',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Germania',
            GH: 'Ghana',
            GI: 'Gibilterra',
            GR: 'Grecia',
            GL: 'Groenlandia',
            GD: 'Grenada',
            GP: 'Guadalupa',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Isola Heard e Isole Mcdonald',
            VA: 'Santa Sede (Stato della Città del Vaticano)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Ungheria',
            IS: 'Islanda',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran',
            IQ: 'Iraq',
            IE: 'Irlanda',
            IM: 'Isola di Man',
            IL: 'Israele',
            IT: 'Italia',
            JM: 'Giamaica',
            JP: 'Giappone',
            JE: 'Jersey',
            JO: 'Giordania',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Corea',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: 'Repubblica Democratica Popolare del Laos',
            LV: 'Lettonia',
            LB: 'Libano',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Giamahiria Araba Libica',
            LI: 'Liechtenstein',
            LT: 'Lituania',
            LU: 'Lussemburgo',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malesia',
            MV: 'Maldive',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Isole Marshall',
            MQ: 'Martinica',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia',
            MD: 'Moldavia',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Marocco',
            MZ: 'Mozambico',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Olanda',
            AN: 'Antille Olandesi',
            NC: 'Nuova Caledonia',
            NZ: 'Nuova Zelanda',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Isola di Norfolk',
            MP: 'Isole Marianne Settentrionali',
            NO: 'Norvegia',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Territorio palestinese occupato',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Perù',
            PH: 'Filippine',
            PN: 'Pitcairn',
            PL: 'Polonia',
            PT: 'Portogallo',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Romania',
            RU: 'Russia',
            RW: 'Rwanda',
            BL: 'San Bartolomeo',
            SH: 'Sant\'Elena',
            KN: 'Saint Kitts e Nevis',
            LC: 'Santa Lucia',
            MF: 'San Martino',
            PM: 'Saint-Pierre e Miquelon',
            VC: 'Saint Vincent e Grenadine',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'São Tomé e Principe',
            SA: 'Arabia Saudita',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovacchia',
            SI: 'Slovenia',
            SB: 'Isole Salomone',
            SO: 'Somalia',
            ZA: 'Sudafrica',
            GS: 'Georgia del Sud e isole Sandwich',
            ES: 'Spagna',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Suriname',
            SJ: 'Svalbard e Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Svezia',
            CH: 'Svizzera',
            SY: 'Repubblica Araba Siriana',
            TW: 'Taiwan',
            TJ: 'Tajikistan',
            TZ: 'Tanzania',
            TH: 'Tailandia',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad e Tobago',
            TN: 'Tunisia',
            TR: 'Turchia',
            TM: 'Turkmenistan',
            TC: 'Isole Turks e Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ucraina',
            AE: 'Emirati Arabi Uniti',
            GB: 'Regno Unito',
            US: 'Stati Uniti',
            UM: 'Isole periferiche degli Stati Uniti',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Isole Vergini britanniche',
            VI: 'Isole Vergini, Stati Uniti',
            WF: 'Wallis e Futuna',
            EH: 'Sahara occidentale',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    sk: {
        _name: 'Slovak',
        newDesign: {
            withdrawalRequests: 'Zatiaľ žiadne žiadosti o stiahnutie',
            withdrawalRequestsText1: 'Ešte ste nepredložili žiadnu žiadosť o odstúpenie od zmluvy.',
            withdrawalRequestsText2: 'Po iniciovaní výberu sa tu zobrazia údaje o vašej transakcii.',
            pdfAttachment: 'Príloha PDF',
            agent: "Agent",
            closed: 'Uzavreté',
            pending: 'Čakanie na',
            approved: 'Schválené',
            declined: 'Odmietnuté',
            document: 'Dokument',
            frontSide: 'Predná strana',
            backSide: 'Zadná strana',
            address: 'Adresa',
            frontSideDocument: 'Predná strana dokumentu',
            creditCard: 'Kreditná karta',
            selfie: 'Selfie',
            other: 'Iné',
            noDocument: 'Zatiaľ neboli nahrané žiadne dokumenty',
            amountDeposited: 'Vložená suma',
            makeTransfer: 'Vykonajte prevod',
            otherDetails: 'Podrobnosti o objednávke',
            manualWallets: 'Krypto peňaženky',
            depositText: 'Vklad je spracovaný do 5-10 minút',
            pendingBankTransfer: 'Čaká na vás záloha bankovým prevodom.',
            completeDeposit: 'Ak chcete dokončiť vklad, postupujte podľa týchto pokynov',
            downloadInvoice: 'Stiahnite si faktúru',
            payInvoice: 'Úhrada faktúry v banke',
            useYourBankText1: 'Na zaplatenie faktúry použite online alebo offline platobný systém svojej banky.',
            useYourBankText2: 'Skontrolujte, či sú platobné údaje správne.',
            uploadReceipt: 'Odoslanie potvrdenia o prijatí',
            afterMakingPayment: 'Po vykonaní platby nahrajte potvrdenie o zaplatení zálohy.',
            clickToUpload: 'Kliknite na tlačidlo Nahrať',
            orDrag: 'alebo pretiahni a pusť',
            fileFormat: 'PDF, JPG, JPEG, PNG menej ako 10 MB',
            waitForDeposit: 'Počkajte na potvrdenie vkladu',
            onceReceipt: 'Po odoslaní potvrdenia overíme a schválime vašu zálohu.',
            paymentDetails: 'Podrobnosti o platbe',
            nameOnCard: 'Meno na karte',
            cardNumber: 'Číslo karty',
            expirationDate: 'Obdobie platnosti',
            securityCode: 'Bezpečnostný kód',
            continue: 'Pokračovať',
            viewAccounts: 'Zobraziť kontá',
            continueWithdrawal: 'Pokračovať v preberaní',
            accountBalance: 'Zostatok na účte',
            accountBalanceText1: 'Prečo nemôžem uskutočniť obchod?',
            accountsBalanceText2: 'Ako môžem vložiť finančné prostriedky?',
            accountsBalanceText3: 'Chcem vložiť peniaze cez Zelle',
            tradingActions: 'Obchodné akcie',
            tradingActionsText1: 'Ako môžem predávať nakrátko?',
            tradingActionsText2: 'Ako môžem zrušiť obchod?',
            tradingActionsText3: 'Aká je minimálna výška obchodu?',
            marketInfo: 'Informácie o trhu',
            marketInfoText1: 'Kde si môžem pozrieť trendy na trhu?',
            marketInfoText2: 'Aké sú dnešné najväčšie zisky?',
            securityAndSettings: 'Zabezpečenie a nastavenia',
            securityAndSettingsText1: 'Ako si môžem zmeniť heslo?',
            securityAndSettingsText2: 'Aké sú bezpečnostné funkcie?',
            securityAndSettingsText3: 'Ako môžem aktualizovať informácie o svojom konte?',
            messageCopied: 'Správa skopírovaná do schránky',
            addSymbol: 'Pridať symbol',
            iHaveRead: 'Prečítal som si podmienky a súhlasím s nimi',
            toggleToTurn: 'Prepnutie na zapnutie funkcie AutoTrade',
            selectTheRisk: 'Vyberte úroveň rizika',
            confirm: 'Potvrďte',
            cancel: 'Zrušiť',
            chooseClosingTime: 'Zvoľte si čas ukončenia',
            setOrderClosingTime: 'Nastavenie času ukončenia objednávky',
            closingTime: 'Čas ukončenia',
            date: 'Dátum',
            time: 'Čas',
            setCloseTime: 'Nastavenie času zatvorenia',
            '15m': '15 minút',
            '30m': '30 minút',
            '1h': '1 hodina',
            custom: 'Vlastné',
            verifyOtp: 'Overenie vášho OTP',
            enterDigits: 'Zadajte 6-miestny kód zaslaný na váš [e-mail/telefón]',
            submit: 'Odoslať',
            order: 'Objednávka',
            closedSuccessfully: 'bola úspešne uzavretá',
            openSuccessfully: 'úspešne otvoriť',
            deposit: 'záloha',
            withdrawal: 'odstúpenie',
            completeVerification: 'Kompletné overenie',
            verification: 'Overovanie',
            avatar: 'Avatar',
            saveChanges: 'Uloženie zmien',
            darkMode: 'Tmavý režim',
            dashboardCurrency: 'Prístrojová doska Mena',
            language: 'Jazyk',
            twoFactor: '2-faktorová ochrana',
            googleTwoFactorIsActive: '2-faktorový autentifikátor Google je aktívny',
            activateCodeForGoogle: 'Aktivácia kódu pre službu Google Authenticator',
            ifYouWishDisableEnterCode: 'Ak chcete vypnúť ochranu, zadajte kód aplikácie autentifikátora',
            scanOrManuallyEnter: 'Naskenovanie alebo ručné zadanie kódu QR v službe Google 2FA na pridanie tokenu',
            copyCode: 'Kopírovať kód',
            qrCode: 'Kód QR',
            activateAndEnterAuthenticator: 'Aktivujte Authenticator a zadajte vygenerovaný kód do poľa nižšie',
            enterTheDigitsCodeGenerated: 'Zadajte vygenerovaný 6-miestny kód',
            disableTwoFactor: 'Zakázanie dvojfaktorovej ochrany',
            activateTwoFactor: 'Aktivácia ochrany 2FA',
            fileAdded: 'Súbor bol úspešne pridaný',
            theVerificationStatus: 'Stav overenia je možné zobraziť v zozname nahraných dokumentov',
            confirmWithdrawal: 'Potvrdiť výber',
            minimumWithdrawal: 'Minimálna výška výberu je',
            welcomeToTradingPlatform: 'Vitajte na obchodnej platforme',
            signIn: 'Prihlásiť sa',
            activeAccount: 'Aktívny účet',
            save: 'Uložiť',
            selectCurrencyAndPeriod: 'Vyberte menu a obdobie predplatného',
            savingsNotFound: 'Nenájdené sporiace účty',
            pleaseCheckInvestment: 'Ak chcete pokračovať, skontrolujte svoje investičné údaje',
            allPeriodInvestment: 'Všetky dobové investície',
            pct_0: 'Flexibilné',
            pct_30: '1 mesiac',
            pct_60: '1 štvrťrok',
            pct_90: '6 mesiacov',
            pct_180: '6 mesiacov',
            pct_365: '1 rok',
            savingsCreated: 'Úspešne vytvorený sporiaci účet',
            transferDetails: 'Podrobnosti o prenose',
            transferAmount: 'Výška transferu',
            investmentDetails: 'Podrobnosti o investíciách',
            investment: 'Investície',
            Period: 'Obdobie',
            created: 'Vytvoril',
            releaseTime: 'Čas vydania',
            viewSavingsAccounts: 'Zobraziť sporiace účty',
            continueInvestment: 'Pokračovať v investovaní',
            chooseSubscription: 'Vyberte si predplatné',
            selectCurrencyAndSubscription: 'Ak chcete pokračovať, vyberte menu a obdobie predplatného.',
            rate: 'Hodnotiť',
            accountTransferFunds: 'Účet, z ktorého sa majú previesť finančné prostriedky',
            amount: 'Suma',
            'in': 'v',
            openSavingsAndInvest: 'Otvorte si sporiaci účet a investujte',
            strongSell: 'Silný predaj',
            sell: 'Predaj',
            neutral: 'Neutrálne',
            buy: 'Kúpiť',
            stringBuy: 'Silná kúpa',
            chooseCryptoWallet: 'Výber peňaženky s kryptomenami',
            chooseMethod: 'Vyberte metódu',
            maximumDeposit: 'Maximálny vklad je',
            minimumDeposit: "Minimálny vklad je",
            pleaseCompleteAddress: 'Vyplňte svoju adresu',
            pleaseCompleteCity: 'Vyplňte svoje mesto',
            pleaseCompleteCountry: 'Vyplňte svoju krajinu',
            pleaseCompleteZip: 'Vyplňte svoje PSČ',
            amountDeposit: 'Výška vkladu',
            transactionStatus: 'Stav transakcie',
            paymentMethod: 'Spôsob platby',
            depositTo: 'Vklad do',
            depositAddress: 'Adresa vkladu',
            scanTheQr: 'Naskenujte kód QR alebo skopírujte adresu vkladu',
            wallet: 'Peňaženka',
            copyDepositAddress: 'Kopírovať adresu vkladu',
            copy: 'Kopírovať',
            addressCopied: 'Adresa skopírovaná do schránky',
            '5min:': '5-10 minút',
            '2-5days': '2-5 dní / 5-10 minút',
            otherMethod: 'Iná metóda',
            profileSettings: 'Nastavenia profilu',
            savingsAccounts: 'Sporiace účty',
            subscribe: 'Prihlásiť sa na',
            toCompleteVerification: 'Na dokončenie procesu overenia vášho účtu predložte jasné a čitateľné kópie týchto dokumentov',
            amountToWithdrawal: 'Suma na výber',
            cardDetails: 'Podrobnosti o karte',
            cardHolderName: 'Meno držiteľa karty',
            withdrawalSuccessful: 'Úspešné stiahnutie',
            selectWithdrawalMethod: 'Vyberte spôsob odstúpenie',
            creditDebit: 'Kreditná/debetná karta',
            crypto: 'Krypto',
            cryptoWalletAddress: 'Adresa peňaženky krypto',
            cryptoSubtitle: 'Krypto výbery do vašej peňaženky 🚀',
            bankWithdrawal: 'Bankový výber',
            bankSubtitle: 'Bankový účet 🏦',
            zelleSubtitle: 'Rýchle bankové prevody ⚡🏦',
            tradingResults: 'Výsledky obchodovania',
            day: 'Deň',
            month: 'Mesiac',
            noTradingData: 'Nie sú k dispozícii žiadne údaje o obchodovaní',
            startTradingToSee: 'Začnite obchodovať a pozrite si svoje výsledky tu.',
            yourPerformanceMetric: 'Vaše výkonnostné ukazovatele sa zobrazia, keď budú k dispozícii údaje.',
            favorite: 'Obľúbené',
            all: 'Všetky',
            availableFullTime: 'K dispozícii 24 hodín denne, 7 dní v týždni',
            yourProfileAndSettings: 'Váš profil a nastavenia',
            switchToNewDesignMessage: 'Prejdite na náš nový dizajn platformy teraz!',
            switchToOldDesignMessage: 'Klasický dizajn môžete používať ešte obmedzený čas',
            switchToOldDesignButton: 'Prechod na nový dizajn',
            switchToNewDesignButton: 'Návrat ku klasickému dizajnu',
            generateInvoice: 'Vytvoriť faktúru',
            downloadPdf: 'Stiahnuť PDF',
            confirmDeposit: 'Potvrdiť vklad',
            howCanIAssist: 'Ako vám môžem dnes pomôcť?',
            maxFileSize: 'Maximálna veľkosť súboru je 5 MB, akceptované formáty sú JPEG, PNG alebo GIF',
            uploadPhoto: 'Nahrať fotografiu',
            featuresDisabled: 'Táto funkcia je deaktivovaná',
            welcome: 'Vitajte',
            signUp: 'Zaregistrujte sa',
            registration: 'Registrácia',
            pleaseConfirmAgreement: 'Potvrďte, prosím, dohodu so zákazníkom',
            pleaseEnterPassword: 'Zadajte, prosím, heslo',
            passwordNotSame: 'Heslá sa nezhodujú',
            noActiveOrders: 'Žiadne aktívne objednávky',
            itLikeNoHaveActiveOrders: 'Zdá sa, že momentálne nemáte žiadne aktívne objednávky',
            cryptoWallet: 'Krypto peňaženka',
            bankCard: 'Banka/Karta',
            selected: 'Vybrané',
            amountWithdrawal: 'Vybraná suma',
            withdrawalTo: 'Výber na',
            governmentIssuedPhoto1: 'Vládou vydaný doklad totožnosti (napr. pas, vodičský preukaz, národný preukaz totožnosti)',
            governmentIssuedPhoto2: 'Povolenie na pobyt (ak je to relevantné)',
            governmentIssuedPhoto3: 'Dokument musí byť platný (neprepadnutý)',
            governmentIssuedPhoto4: 'Celé meno, dátum narodenia a fotografia musia byť viditeľné.',
            uploadRecent1: 'Nahrajte aktuálny účet za služby, výpis z účtu alebo nájomnú zmluvu vydanú za posledné 3 mesiace',
            uploadRecent2: 'Uistite sa, že vaše celé meno a súčasná adresa sú jasne viditeľné.',
            uploadCopy1: 'Nahrajte kópiu prednej strany vašej kreditnej karty, uistite sa, že je vidieť prvých šesť a posledné štyri čísla',
            uploadCopy2: 'Zakryte prosím stredné čísla z bezpečnostných dôvodov',
            takeAndUpload1: 'Odfotografujte sa a nahrajte selfie, kde držíte doklad totožnosti vedľa tváre',
            takeAndUpload2: 'Uistite sa, že vaša tvár a doklad totožnosti sú jasne viditeľné',
            otherDocuments: 'Iné dokumenty',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Nahrajte jasný a čitateľný obrázok vášho dokumentu s číslom sociálneho zabezpečenia (SSN). Uistite sa, že všetky detaily sú viditeľné a neprekryté.',
            frontSideSsnDocument: 'Predná strana dokumentu SSN',
            answerSaved: 'Odpovede boli uložené',
            cryptoWalletDetails: 'Podrobnosti o kryptopeňaženke',
            bitcoinWalletAddress: 'Adresa Bitcoin peňaženky',
            recovery: 'Obnova',
            passwordRecovery: 'Obnova hesla',
            searchCountry: 'Hľadať krajinu',
            questionnaire: 'Dotazník',
            questionnaireText: 'Prosím, vyplňte dotazník na overenie vašej identity',
            sendAnswers: 'Odoslať odpovede',
        },
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit a Stop Loss',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            updateOrder: 'Upraviť Objednávku',
        },
        balanceModal: {
            balance: 'Zostatok',
            credit: 'Kredit',
            equity: 'Hodnota',
            practice: 'DEMO',
            real: 'REAL',
            account: 'ÚČET',
            invested: 'Investované',
            profit: 'Profit',
            loss: 'Strata',
            margin: 'Marža',
            marginLevel: 'Úroveň marže',
            marginFree: 'Voľná marža'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platforma',
            deposit: 'Vklad'
        },
        sideMenu: {
            marketWatch: 'Sledovanie trhu',
            activeOrders: 'Aktívne objednávky',
            tradingHistory: 'História obchodov',
            economicCalendar: 'Kalendár',
            marketNews: 'Správy z trhu'
        },
        closePositionModal: {
            closeOrder: 'Zatvoriť pozíciu',
            areYouSure: 'Určite chcete zatvoriť pozíciu',
            buy: 'Buy',
            sell: 'Sell',
            yes: 'Áno',
            no: 'Nie',
        },
        pciDssModal: {
            desc: `Dodržiavanie štandardu bezpečnosti údajov priemyslu platobných kariet (PCI DSS) je vyžadované od všetkých subjektov, ktoré ukladajú, spracúvajú alebo prenášajú údaje držiteľa karty Visa/Master Card/Stripe, vrátane finančných inštitúcií, obchodníkov a poskytovateľov služieb. Je to celosvetový bezpečnostný štandard pre všetky subjekty, ktoré ukladajú, spracúvajú alebo prenášajú údaje držiteľa karty a/alebo citlivé autentifikačné údaje. PCI DSS stanovuje základnú úroveň ochrany pre spotrebiteľov a pomáha znížiť podvody a porušenia údajov v celom platobnom ekosystéme.`
        },
        chart: {
            addNewChart: 'Pridať nový graf',
        },
        symbolsModal: {
            watchlist: 'Zoznam sledovaných',
            asset: 'Inštrument',
            price: 'Cena',
            changePct: 'Zmena za 24h'
        },
        pendingModal: {
            pending: 'Čakajúca',
            assetPrice: 'Cena inštrumentu',
            current: 'Aktuálna',
            revert: 'Zmeniť na trhovú cenu',
            automatically: 'Pozícia sa automaticky otvorí, keď cena dosiahne túto úroveň'
        },
        orderMenu: {
            default: 'predvolené',
            volume: 'Objem',
            lots: 'loty',
            units: 'jednotky',
            currency: 'mena',
            contractSize: 'Veľkosť kontraktu',
            position: 'Pozícia',
            margin: 'Marža',
            freeMargin: 'Voľná marža',
            takeProfitStopLoss: 'Take Profit a Stop Loss',
            pending: 'Čakajúca',
            market: 'Trhová',
            leverage: 'Páka',
            spread: 'Spread',
            notSet: 'Nenastavené',
            at: 'na',
            buy: 'buy',
            sell: 'sell',
        },
        footer: {
            supportBanner: 'NONSTOP KAŽDÝ DEŇ',
            currentTime: 'AKTUÁLNY ČAS',
            liveChat: 'Živý Chat'
        },
        statusBar: {
            balance: 'Zostatok',
            credit: 'Kredit',
            equity: 'Hodnota',
            margin: 'Marža',
            marginLevel: 'Úroveň marže',
            freeMargin: 'Voľná marža',
            pnl: 'PnL',
            profitTotal: 'Celkové PnL'
        },
        accountModal: {
            dateRegistered: 'Dátum registrácie',
            userId: 'ID užívateľa',
            dashboard: 'Prehľad',
            personalData: 'Osobné údaje',
            deposit: 'Vklad',
            withdrawFunds: 'Výber prostriedkov',
            savings: 'Sporenie',
            settings: 'Nastavenia',
            logout: 'Odhlásiť'
        },
        depositModal: {
            BT_INVOICE: 'Zadajte sumu a generujte faktúru',
            BT_TITLE: 'Naše bankové údaje',
            account: 'Účet',
            bank_name: 'Názov banky',
            beneficiary: 'Príjemca',
            beneficiary_address: 'Adresa príjemcu',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Suma',
            TOKEN_SUBTOTAL: 'Medzisúčet',
            TOKEN_TOTAL: 'Spolu',
            TOKEN_DESCRIPTION: 'Popis',
            TOKEN_PAYMENT_DETAILS: 'Platobné údaje',
            PENDING_DEPOSITS_LIMIT: 'Vkladový limit',
            makeDeposit: 'Urobiť vklad',
            practiceAccount: 'Demo účet',
            realAccount: 'Real účet',
            noWithdrawals: 'Bez výberov',
            easyWithdrawals: 'Jednoduché výbery',
            allAssets: 'Dostupné všetky inštrumenty',
            fullFledged: 'Plnohodnotná platforma',
            fillUpTo: 'Doplňte až do',
            freeReplenishment: 'Bezplatné doplnenie',
            topUp: 'Doplňte svoj účet',
            minimumAmount: 'Minimálna suma',
            canSwitch: 'Medzi rôznymi účtami môžete kedykoľvek prepínať'
        },
        gridsModal: {
            chartGrids: 'MRIEŽKA GRAFOV',
            chart1: '1 graf',
            chart2: '2 grafy',
            chart3: '3 grafy',
            chart4: '4 grafy',
        },
        noConnectionModal: {
            connectionLost: 'Spojenie so serverom bolo stratené',
            retryNow: 'Skúsiť teraz',
        },
        loginModal: {
            loginToTradeRoom: 'Prihlásiť do Traderoom',
            email: 'E-mailová adresa',
            enterEmail: 'Zadajte svoj e-mail',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA Kód (ak je aktivovaný)',
            twoFactorAuth: 'Dvojfaktorová autentifikácia',
            password: 'Heslo',
            yourPassword: 'Vaše heslo',
            signIn: 'Prihlásiť',
            dontHaveAccount: 'Ešte nemáte účet',
            restore: 'Obnoviť',
            fullName: 'Celé meno',
            havePromo: 'Mám promo kód',
            promo: 'Promo kód',
            login: 'Prihlásiť',
            haveAccount: 'Už máte účet?',
            hide: 'Skryť',
            goTo: 'Späť'
        },
        newPasswordModal: {
            setNewPassword: 'Nastaviť nové heslo',
            newPassword: 'Nové heslo',
            confirmNewPassword: 'Potvrďte nové heslo',
            setButton: 'Nastaviť'
        },
        registerModal: {
            repeatPassword: 'Zopakujte heslo',
            createNewAccount: 'Vytvoriť nový účet',
            email: 'E-mailová adresa',
            firstName: 'Krstné meno',
            lastName: 'Priezvisko',
            country: 'Štát',
            phone: 'Tel. číslo',
            password: 'Heslo',
            createAccount: 'Vytvoriť účet',
            currency: 'Mena',
            privacyAndPolicy: "Ochrana súkromia a politika",
            customerAgreement: 'Zmluva so zákazníkom',

        },
        forgotPasswordModal: {
            forgotPassword: 'Zabudnuté heslo?',
            submitEmail: 'Prosím, zadajte e-mailovú adresu, ktorá bola použitá pri registrácii, skontrolujte prijatú poštu pre inštrukcie',
            submit: 'Odoslať'
        },
        notifications: {
            error: 'Chyba',
            success: 'Úspešné',
            deposit: 'Vklad',
            withdrawal: 'Výber',
            depositApproved: 'bol schválený',
            depositDeclined: 'Vklad bol zamietnutý',
            withdrawalApproved: 'Výber bol schválený',
            withdrawalDeclined: 'Výber bol zamietnutý'
        },
        markets: {
            favorites: 'Zoznam sledovania', 
            all: 'Všetky symboly' ,
            forex: 'Forex',
            stocks: 'Akcia',
            commodities: 'Komodity',
            indices: 'Indexy',
            crypto: 'Crypto',
            metals: 'Kovy',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'BUY',
            profitCalculator: 'Kalkulačka zisku',
            sell: 'SELL',
            volume: 'Objem',
            entryPrice: 'Vstupná cena',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max pozícia',
            calculate: 'Kalkulovať',
            calculationsFor: 'Kalkulácie pre',
            leverage: 'Páka',
            requiredMargin: 'Potrebná marža',
            profitFromTP: 'Profit z TP',
            lossFromSL: 'Strata zo SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Vklad bol úspešný',
            text: 'Váš vklad bol úspešne spracovaný!',
        },
        depositFailModal: {
            title: 'Vklad zlyhal',
            text: 'Váš vklad nebol spracovaný.',
        },
        widgetMarketWatch: {
            marketWatch: 'Sledovanie trhu',
            search: 'Vyhľadávanie...',
        },
        widgetOrders: {
            commission: 'Komisia',
            volume: 'Objem',
            margin: 'Marža',
            active: 'Aktívne',
            pending: 'Čakajúce',
            activeOrders: 'Aktívne objednávky',
            portfolio: 'Portfólio',
            allPositions: 'Všetky pozície',
            noPositionsLine1: 'Ešte nemáte otvorené',
            noPositionsLine2: 'žiadne pozície',
            show: 'Zobraziť',
            more: 'viac',
            less: 'menej',
            purchaseTime: 'Čas nákupu',
            closePosition: 'Uzavrieť pozíciu',
            priceOpen: 'Nákupná cena',
            priceSl: 'Cena Stop Loss',
            priceTp: 'Cena Take Profit',
            type: 'Smer pozície',
            pnl: 'Profit/Strata',
            cancelOrder: 'Zrušiť objednávku',
            orderType: 'Typ objednávky',
            cancelled: 'Zrušená',
            tradingHistory: 'História obchodov',
            noHistoryLine1: 'Ešte nemáte žiadne',
            noHistoryLine2: 'uzavreté obchody',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Kalendár'
        },
        widgetMarketNews: {
            marketNews: 'Správy z trhu'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Aktívne objednávky',
            ordersHistory: 'História objednávok',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Typ',
            volume: 'Objem',
            openPrice: 'Otváracia cena',
            openTime: 'Otváracia čas',
            closePrice: 'Zatváracia cena',
            closeTime: 'Zatváracia čas',
            sl: 'SL',
            tp: 'TP',
            price: 'Cena',
            pnl: 'PnL',
            actions: 'Možnosti',
            edit: 'Upraviť',
            close: 'Zavrieť',
            commission: 'Poplatok',
            swap: 'Swap',
            swapLong: 'Swap Short',
            swapShort: 'Swap Long'
        },
        assetInfoModal: {
            assetInfo: 'Informácie o inštrumente',
            tradingConditions: 'Obchodné podmienky',
            information: 'Informácie',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Zmena seansy',
            tradeNow: 'Obchodovať teraz',
            opens: 'Otvára sa',
            closes: 'Zatvára sa',
            at: 'o',
            open247: 'Otvorené 24/7',
            today: 'dnes',
            tomorrow: 'zajtra',
            sunday: 'Nedeľa',
            monday: 'Pondelok',
            tuesday: 'Utorok',
            wednesday: 'Streda',
            thursday: 'Štvrtok',
            friday: 'Piatok',
            saturday: 'Sobota',
            contractSpecification: 'Špecifikácia kontraktu',
            symbol: 'Symbol',
            name: 'Názov',
            market: 'Trh',
            baseCurrency: 'Základná mena',
            digits: 'Desatinné miesta',
            lotSize: 'Veľkosť lotu',
            lotStep: 'Krok lotu',
            minVolume: 'Minimálny objem',
            maxVolume: 'Maximálny objem',
            leverage: 'Páka',
            commission: 'Komisia',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Rozvrh',
            weekday: 'Pracovný deň',
            tradingTime: 'Čas obchodovania',
            closed: 'uzavreté',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Nízka',
            high: 'Vysoká',
            oneHourChange: 'Zmena za 1 hodinu',
            oneDayChange: 'Zmena za 1 deň',
            oneWeekChange: 'Zmena za 1 týždeň',
            oneMonthChange: 'Zmena za 1 mesiac',
            threeMonthsChange: 'Zmena za 3 mesiace',
            oneYearChange: 'Zmena za 1 rok',
            loading: 'Načítavanie...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'Obchodov za deň:',
            desc: `Obchodovanie s CFD a inými pákovými produktmi môže viesť k stratám. Pred obchodovaním by si klienti mali prečítať príslušné vyhlásenia o rizikách na našej stránke zverejňovania rizík. Automatizované obchodovanie nezaručuje výsledky. Spoločnosť nenesie žiadnu zodpovednosť za stratu finančných prostriedkov v automatizovanom obchodovaní. Uistite sa, že plne rozumiete rizikám a prijímate opatrenia na ich riadenie.`,
            accept: 'Accept',
            save: 'Uložiť',
            cancel: 'Zrušiť',
        },
        cardBox: {
            name: 'Meno',
            cardNumber: 'Číslo karty',
            expiration: 'Expirácia (mm/rr)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Obchodovať",
            startTrading: 'Začať obchodovať',
            deposit: 'Vklad',
            dashboard: 'Prehľad',
            personalInfo: 'Osobné údaje',
            contactInfo: 'Kontaktné informácie',
            withdrawal: 'Výber',
            verification: 'Overenie',
            accounts: 'Účty',
            liveChat: 'Živý Chat',
            savings: 'Sporenie',
            referrals: 'Odporúčací program',
            settings: 'Nastavenia',
            logOut: 'Odhlásiť',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            sendOtp: 'Poslať otp kód',
            otpCode: 'Otp kód',
            addWalletAddress: 'Peňaženka pre tento spôsob vkladu nie je nastavená. Obráťte sa prosím na svojho zástupcu alebo tím podpory.',
            addressReceipt: 'Počkajte, kým sa adresa načíta',
            makeDeposit: 'UROBIŤ VKLAD',
            instant: 'Okamžité',
            minutes: 'minúty',
            hours: 'hodiny',
            days: 'dni',
            amount: 'Suma',
            continue: 'Pokračovať',
            qrCode: 'QR KÓD',
            depositAddress: 'ADRESA VKLADU',
            copy: 'Kopírovať',
            last: 'POSLEDNÉ',
            deposits: 'VKLADY',
            time: 'Čas',
            currency: 'Mena',
            status: 'Stav',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Prosím, počkajte. Pripájanie k poskytovateľovi platobného systému...',
            cardPayment: 'Platba kartou',
            inOrder: 'Aby ste mohli odoslať danú sumu,',
            pleaseProvide: 'prosím, poskytnite údaje karty:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'Zatiaľ žiadna objednávka',
            totalBalance: 'Celkový zostatok',
            totalPnl: 'Celkové PNL',
            profitableOrders: 'Ziskové objednávky',
            roi: 'ROI',
            activityLog: 'Log aktivity',
            loginFromIp: 'Prihlásenie z IP',
            tradingResults: 'Obchodné výsledky',
            week: 'Týždeň',
            month: 'Mesiac',
            year: 'Rok',
            successRate: 'Pomer úspešných',
            closedWithProfit: 'Uzavreté s profitom',
            closedWithLoss: 'Uzavreté so stratou',
            account: 'Účet',
            balance: 'Zostatok',
            leverage: 'Páka',
            credit: 'Kredit',
            tradeNow: 'Obchodovať teraz',
            usingCurrentRate: 's použitím aktuálnych výmenných kurzov',
            totalDeposits: 'Celkové vklady',
            totalWithdrawals: "Celkové výbery",
            returnOnInvestment: "Návratnosť investícií"
        },
        fullScreenPersonal: {
            personalDetails: 'Osobné údaje',
            profilePhoto: 'Profilová fotka',
            firstName: 'Krstné meno',
            country: 'Štát',
            lastName: 'Priezvisko',
            address: 'Adresa',
            email: 'E-mail',
            phone: 'Tel. číslo',
            saveChanges: 'Uložiť zmeny',
            state: 'Mesto',
            postcode: 'PSČ',
            created: "Účet vytvorený",
        },
        fullScreenAvatar: {
            dropFile: 'Pretiahnite súbor na kruh vyššie pre nahratie',
            notAllowed: 'Nie je povolené zverejniť',
            notAllowedLine1: 'Fotografie s explicitne sexuálnym alebo pornografickým obsahom',
            notAllowedLine2: 'Obrázky s cieľom podnecovať etnickú alebo rasovú nenávisť alebo agresiu',
            notAllowedLine3: 'Fotografie zobrazujúce osoby mladšie ako 18 rokov',
            notAllowedLine4: 'Fotografie chránené autorskými právami tretích strán',
            notAllowedLine5: 'Obrázky väčšie ako 5 MB a v inom formáte ako JPG, GIF alebo PNG',
            requirements: 'Vaša tvár musí byť na fotografii jasne viditeľná. Všetky fotografie a videá odoslané vami musia spĺňať tieto požiadavky, inak môžu byť odstránené.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Prosím, kontaktujte vášho správcu účtu pre podrobnosti o výbere',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Výber',
            requestNewWithdrawal: 'POŽIADAŤ O NOVÝ VÝBER',
            amount: 'Suma',
            currentBalance: 'Váš aktuálny zostatok na účte je',
            withdrawAll: 'Vybrať všetko',
            requestWithdrawal: 'Požiadať o výber',
            last: 'POSLEDNÉ',
            withdrawalRequests: 'POŽIADAVKY O VÝBER',
            time: 'Čas',
            currency: 'Mena',
            info: 'Info',
            status: 'Stav',
            bank: 'Banka',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Karta',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Podrobnosti o výbere',
            address: 'Adresa peňaženky',
            bankDetails: 'Bankové údaje',
            bankAccount: 'Číslo účtu',
            bankHolder: 'Meno držiteľa účtu',
            bankIban: 'IBAN',
            bankSwift: 'Bankový SWIFT kód',
            recipientAddress: 'Adresa príjemcu',
            routingNumber: 'Smerové číslo banky',
            bankBeneficiary: 'Názov banky príjemcu',
            bankAddress: 'Adresa banky',
            bankCode: 'Bankový kód (BSB)',
            desc: 'Popis',
            cardNumber: 'Číslo karty',
            cardHolder: 'Meno držiteľa karty'
        },
        fullScreenVerification: {
            dragAndDrop: 'presuňte dokument do tejto oblasti',
            documentsList: 'ZOZNAM NAHRATÝCH SÚBOROV',
            document: 'Doklad',
            timeUploaded: 'Čas nahratia',
            timeProcessed: 'Čas spracovania',
            status: 'Stav',
            types: ['Doklad totožnosti', 'Doklad o adrese', 'Predná strana kreditnej karty', 'Zadná strana kreditnej karty', 'Doklad totožnosti zadná strana', 'Selfie', 'Other'],
            types2: ['Doklad totožnosti (POI)', 'Doklad o trvalom bydlisku', 'Predná strana kreditnej karty', 'Zadná strana kreditnej karty', 'Doklad totožnosti zadná strana', 'Selfie spolu s dokladom'],
            upload: 'Nahrať',
            isConfirmed: 'potvrdené',
            uploadAnotherDocument: 'Nahrať ďalší dokument',
            isUnderReview: 'posudzuje sa',
            questionnaire: 'Dotazník',
            sendAnswers: 'Odoslať odpovede',
            progressBarOf: 'z',
            progressBarText: 'vašich dokumentov je nahraných a potvrdených'
        },
        fullScreenAccounts: {
            manageAccounts: 'Previesť prostriedky medzi vašimi účtami',
            transferFrom: 'Z účtu',
            transferTo: 'Na účet',
            amount: 'Čiastka',
            makeTransfer: 'Previesť',
            yourAccounts: 'Vaše účty',
            account: 'Účet',
            currency: 'Mena',
            balance: 'Zostatok',
            credit: 'Kredit',
            makeActive: 'Aktivovať',
            active: 'Aktívny'
        },
        fullScreenSupport: {
            sendMessage: 'Odoslať správu'
        },
        fullScreenSavings: {
            pct_0: 'Flexibilné',
            pct_30: 'Uzamknuté na 1 mesiac',
            pct_90: 'Uzamknuté na 1 kvartál',
            pct_180: 'Uzamknuté na 6 mesiacov',
            pct_365: 'Uzamknuté na 1 rok',
            savings: 'Sporenie',
            detailedInformation: 'Podrobné informácie',
            pleaseSelect: 'Prosím, vyberte menu a časové obdobie',
            openSavings: 'Otvoriť sporenie',
            currentTime: 'Aktuálny čas',
            releaseTime: 'Čas odomknutia',
            currencyChosen: 'Vybraná mena',
            periodChosen: 'Vybrané obdobie',
            yourRate: 'Vaša sadzba',
            yourEarnings: 'Vaše zárobky',
            accountFrom: 'Účet, z ktorého sa prevedú prostriedky',
            enterAmount: 'Zadajte sumu',
            in: 'V',
            openAndInvest: 'Otvoriť sporiaci účet a investovať',
            investment: 'Investícia',
            period: 'Obdobie',
            daysTotal: 'Spolu dní',
            finalEarnings: 'Finálne zárobky',
            created: 'Vytvorené',
            daysElapsed: 'Ubehlo dní',
            days: 'dní',
            earnings: 'zárobky',
            fundsReleasedIn: 'Prostriedky odomknuté',
            claim: 'Prevziať',
            name: "Názov",
        },
        fullScreenSettings: {
            updatePassword: 'AKTUALIZOVAŤ HESLO',
            currentPassword: 'Aktuálne heslo',
            newPassword: 'Nové heslo',
            changePassword: 'Zmeniť heslo',
            activationForGoogle: 'Aktivačný kód pre Google Authenticator',
            activateAndEnter: 'Aktivujte Authenticator a zadajte generovaný kód do poľa nižšie',
            qrCode: 'QR kód',
            activateProtection: 'Aktivovať ochranu 2FA',
            protectionActive: 'Ochrana 2FA je aktívna',
            twoFactorCode: 'Kód 2FA',
            disableProtection: 'Vypnúť ochranu 2FA',
            language: 'Jazyk',
            dashboardCurrency: 'Mena prehľadu',
            confirmNewPassword: 'Potvrďte nové heslo',
        },
        fullScreenReferrals: {
            yourLink: 'VÁŠ REFERRAL ODKAZ',
            referredFriends: 'ODKÁZANÍ PRIATELIA',
            id: 'ID',
            name: 'Meno',
            registered: 'Registrovaný',
            level: 'Level',
            payout: 'Vyplatenie',
            lastPayouts: 'POSLEDNÉ REFERRAL VYPLATENIA',
            time: 'Čas',
            amount: 'Suma',
            currency: 'Mena',
            info: 'Info',
            referralDesc:"Referral program je navrhnutý tak, aby vás odmenil za rozšírenie našej komunity cenných investorov.",
            shareLink:"Zdieľajte odkaz:",
            subtitle:"Poskytnite mu svoje meno, email alebo obchodné ID.",
            descSuccesfull:"Akonáhle sa vaša odporúčaná osoba úspešne zaregistruje, váš finančný poradca ho bude kontaktovať a pomôže mu s prvými krokmi na investičnej ceste čo najefektívnejším a najprofesionálnejším spôsobom.",
            descTracked:"Všetky jeho príspevky sú sledované pod vaším odporúčaním."
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Máj',
            Jun: 'Jún',
            Jul: 'Júl',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Okt',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Buy', 'Sell', 'Buy Limit', 'Sell Limit', 'Buy Stop', 'Sell Stop'],
        statuses: ['Čakajúca', 'Schválená', 'Zamietnutá', 'Spracúva sa'],
        errors: {
            NOT_FOUND: 'Chyba požiadavky',
            FORBIDDEN: 'Prístup zamietnutý. Prosím, prihláste sa znova',
            SERVER_ERROR: 'Akcia zlyhala. Skúste to prosím neskôr',
            INVALID_ACCOUNT: 'Platnosť relácie vypršala. Prosím, prihláste sa znova',
            INVALID_EMAIL: 'Neplatná e-mailová adresa',
            INVALID_PHONE: 'Neplatné telefónne číslo',
            INVALID_COUNTRY: 'Neplatný štát',
            INVALID_LOGIN: 'Neplatné prihlasovacie údaje',
            USER_BLOCKED: 'Účet je blokovaný. Prosím, obráťte sa na podporu',
            USER_REGISTERED: 'Používateľ so zadanou e-mailovou adresou alebo telefónnym číslom je už zaregistrovaný',
            INVALID_USER: 'Používateľ nebol nájdený',
            REJECTED: 'Operácia nie je povolená kvôli obmedzeniam platformy',
            INVALID_OLD_PASSWORD: 'Neplatné staré heslo',
            INVALID_SYMBOL: 'Neplatný obchodný symbol',
            INVALID_SYMBOL_PRECISION: 'Neplatná presnosť symbolu',
            INVALID_FRAME: 'Neplatný časový rámec grafu',
            INVALID_CURRENCY: 'Neplatná mena',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Neplatný typ obchodovania účtu',
            INSUFFICIENT_FUNDS: 'Nedostatok prostriedkov',
            INVALID_WITHDRAWAL: 'Výber sa nenašiel',
            INVALID_STATUS: 'Neplatný stav',
            INVALID_FILE: 'Neplatná prípona súboru alebo súbor je príliš veľký',
            INVALID_DOCUMENT: 'Dokument sa nenašiel',
            INVALID_NAME: 'Neplatné meno',
            INVALID_LOT_SIZE: 'Neplatná veľkosť lotu',
            INVALID_LOT_STEP: 'Neplatný krok lotu',
            INVALID_MIN_VOLUME: 'Neplatný minimálny objem',
            INVALID_MAX_VOLUME: 'Neplatný maximálny objem',
            INVALID_GROUP: 'Neplatná skupina',
            INVALID_SPREAD: 'Neplatný spread',
            REQUEST_TIMEOUT: 'Vypršal časový limit požiadavky. Skúste to prosím neskôr',
            EXISTING_OPEN_ORDERS: 'Máte otvorenú objednávku, najprv uzavrite objednávku pre tohto používateľa a skúste to znova.',
            INVALID_TYPE: 'Neplatný typ objednávky',
            INVALID_VOLUME: 'Neplatný objem objednávky',
            INVALID_SL: 'Neplatná hodnota stop loss',
            INVALID_TP: 'Neplatná hodnota take profit',
            INVALID_PRICE: 'Neplatná cena objednávky',
            INVALID_EXPIRATION: 'Neplatná expirácia objednávky',
            NOT_ENOUGH_MARGIN: 'Nedostatočná marža',
            INVALID_ORDER: 'Objednávka sa nenašla',
            MARKET_CLOSED: 'Obchodovanie nie je k dispozícii. Skúste to prosím znova',
            INVALID_BALANCE_TYPE: 'Neplatný typ operácie so zostatkom',
            INVALID_HASH: 'Neplatný hash',
            HASH_EXPIRED: 'Platnosť vášho odkazu na obnovenie hesla vypršala. Požiadajte prosím o nový',
            INVALID_CODE: 'Neplatný 2FA kód',
            CHAT_DISABLED: 'Prístup k chatu s podporou bol uzatvorený',
            WITHDRAWAL_NOT_ALLOWED: 'Prístup k výberom bol uzatvorený',
            TRADING_DISABLED: 'Obchodovanie je zakázané pre aktuálny účet',
            PENDING_DEPOSITS_LIMIT: 'Prekročený limit vkladov',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Povolený je len jeden čakajúci výber',
            LOCAL_DEPOSIT_ONLY_REAL: 'Vklad je možný iba pre reálny účet',
            LOCAL_DEPOSIT_POSITIVE: 'Suma vkladu musí byť viac ako 0',
            LOCAL_INVALID_CARD_NUMBER: 'Neplatné číslo karty',
            LOCAL_INVALID_CARD_EXPIRATION: 'Neplatná platnosť karty',
            LOCAL_INVALID_CVC: 'Neplatný kód CVV/CVC',
            LOCAL_PASSWORDS_NOT_MATCH: 'Heslá sa nezhodujú, zadajte ich prosím znova',
            LOCAL_NO_TRADING_ACCOUNTS: 'Nemáte k dispozícii žiadne obchodné účty. Obráťte sa prosím na podporu',
            LOCAL_MIN_DEPOSIT: 'Minimálny vklad je ',
            LOCAL_MIN_WITHDRAWAL: 'Minimálna čiastka výberu je ',
            LOCAL_INVALID_FILE_TYPE: 'Nepodporovaný formát súboru. Pripojte prosím iba súbory JPG, PNG alebo PDF',
            LOCAL_MIN_STAKING: 'Minimálny vklad pre túto menu je ',
            FLOOD_REJECTED: 'Posielate správy príliš často. Skúste to prosím neskôr'
        },
        countries: {
            AF: 'Afganistan',
            AX: 'Alandské ostrovy',
            AL: 'Albánsko',
            DZ: 'Alžírsko',
            AS: 'Americká Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktída',
            AG: 'Antigua a Barbuda',
            AR: 'Argentína',
            AM: 'Arménsko',
            AW: 'Aruba',
            AU: 'Austrália',
            AT: 'Rakúsko',
            AZ: 'Azerbajdžan',
            BS: 'Bahamy',
            BH: 'Bahrajn',
            BD: 'Bangladéš',
            BB: 'Barbados',
            BY: 'Bielorusko',
            BE: 'Belgicko',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermudy',
            BT: 'Bhután',
            BO: 'Bolívia',
            BA: 'Bosna a Hercegovina',
            BW: 'Botswana',
            BV: 'Bouvetov ostrov',
            BR: 'Brazília',
            IO: 'Britské indickooceánske územie',
            BN: 'Brunej Darussalam',
            BG: 'Bulharsko',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodža',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Kapverdy',
            KY: 'Kajmanské ostrovy',
            CF: 'Stredoafrická republika',
            TD: 'Čad',
            CL: 'Čile',
            CN: 'Čína',
            CX: 'Vianočný ostrov',
            CC: 'Kokosové (Keelingove) ostrovy',
            CO: 'Kolumbia',
            KM: 'Komory',
            CG: 'Kongo',
            CD: 'Konžská demokratická republika',
            CK: 'Cookove ostrovy',
            CR: 'Kostarika',
            CI: 'Pobrežie Slonoviny',
            HR: 'Chorvátsko',
            CU: 'Kuba',
            CY: 'Cyprus',
            CZ: 'Česká republika',
            DK: 'Dánsko',
            DJ: 'Džibutsko',
            DM: 'Dominika',
            DO: 'Dominikánska republika',
            EC: 'Ekvádor',
            EG: 'Egypt',
            SV: 'Salvador',
            GQ: 'Rovníková Guinea',
            ER: 'Eritrea',
            EE: 'Estónsko',
            ET: 'Etiópia',
            FK: 'Falklandské ostrovy (Malvíny)',
            FO: 'Faerské ostrovy',
            FJ: 'Fidži',
            FI: 'Fínsko',
            FR: 'Francúzsko',
            GF: 'Francúzska Guyana',
            PF: 'Francúzska Polynézia',
            TF: 'Francúzske južné a antarktické územia',
            GA: 'Gabon',
            GM: 'Gambie',
            GE: 'Gruzínsko',
            DE: 'Nemecko',
            GH: 'Ghana',
            GI: 'Gibraltár',
            GR: 'Grécko',
            GL: 'Grónsko',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heardov ostrov a McDonaldove ostrovy',
            VA: 'Svätá stolica (Vatikánsky mestský štát)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Maďarsko',
            IS: 'Island',
            IN: 'India',
            ID: 'Indonézia',
            IR: 'Irán',
            IQ: 'Irak',
            IE: 'Írsko',
            IM: 'Ostrov Man',
            IL: 'Izrael',
            IT: 'Taliansko',
            JM: 'Jamajka',
            JP: 'Japonsko',
            JE: 'Jersey',
            JO: 'Jordánsko',
            KZ: 'Kazachstan',
            KE: 'Keňa',
            KI: 'Kiribati',
            KR: 'Kórejská republika',
            KW: 'Kuvajt',
            KG: 'Kirgizsko',
            LA: 'Laoská ľudovodemokratická republika',
            LV: 'Lotyšsko',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Libéria',
            LY: 'Líbya',
            LI: 'Lichtenštajnsko',
            LT: 'Litva',
            LU: 'Luxembursko',
            MO: 'Macao',
            MK: 'Macedónsko',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malajzia',
            MV: 'Maldivy',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshallove ostrovy',
            MQ: 'Martinik',
            MR: 'Mauritánia',
            MU: 'Maurícius',
            YT: 'Mayotte',
            MX: 'Mexiko',
            FM: 'Mikronézia',
            MD: 'Moldavsko',
            MC: 'Monako',
            MN: 'Mongolsko',
            ME: 'Čierna Hora',
            MS: 'Montserrat',
            MA: 'Maroko',
            MZ: 'Mozambik',
            MM: 'Mjanmarsko (Barma)',
            NA: 'Namíbia',
            NR: 'Nauru',
            NP: 'Nepál',
            NL: 'Holandsko',
            AN: 'Holandské Antily',
            NC: 'Nová Kaledónia',
            NZ: 'Nový Zéland',
            NI: 'Nikaragua',
            NE: 'Niger',
            NG: 'Nigéria',
            NU: 'Niue',
            NF: 'Ostrov Norfolk',
            MP: 'Severné Mariány',
            NO: 'Nórsko',
            OM: 'Omán',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestínske územie, okupované',
            PA: 'Panama',
            PG: 'Papua-Nová Guinea',
            PY: 'Paraguaj',
            PE: 'Peru',
            PH: 'Filipíny',
            PN: 'Pitcairnove ostrovy',
            PL: 'Poľsko',
            PT: 'Portugalsko',
            PR: 'Portoriko',
            QA: 'Katar',
            RE: 'Réunion',
            RO: 'Rumunsko',
            RU: 'Rusko',
            RW: 'Rwanda',
            BL: 'Svätý Bartolomej',
            SH: 'Svätá Helena',
            KN: 'Svätý Krištof a Nevis',
            LC: 'Svätá Lucia',
            MF: 'Svätý Martin',
            PM: 'Saint Pierre a Miquelon',
            VC: 'Svätý Vincent a Grenadíny',
            WS: 'Samoa',
            SM: 'San Maríno',
            ST: 'Svätý Tomáš a Princov ostrov',
            SA: 'Saudská Arábia',
            SN: 'Senegal',
            RS: 'Srbsko',
            SC: 'Seychely',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slovensko',
            SI: 'Slovinsko',
            SB: 'Šalamúnove ostrovy',
            SO: 'Somálsko',
            ZA: 'Južná Afrika',
            GS: 'Južná Georgia a Južné Sandwichove ostrovy',
            ES: 'Španielsko',
            LK: 'Srí Lanka',
            SD: 'Sudán',
            SR: 'Surinam',
            SJ: 'Svalbard a Jan Mayen',
            SZ: 'Svazijsko',
            SE: 'Švédsko',
            CH: 'Švajčiarsko',
            SY: 'Sýria',
            TW: 'Taiwan',
            TJ: 'Tadžikistan',
            TZ: 'Tanzánia',
            TH: 'Thajsko',
            TL: 'Východný Timor',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad a Tobago',
            TN: 'Tunisko',
            TR: 'Turecko',
            TM: 'Turkménsko',
            TC: 'Turks a Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukrajina',
            AE: 'Spojené arabské emiráty',
            GB: 'Spojené kráľovstvo',
            US: 'Spojené štáty',
            UM: 'Menšie odľahlé ostrovy USA',
            UY: 'Uruguaj',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Britské Panenské ostrovy',
            VI: 'Americké Panenské ostrovy',
            WF: 'Wallis a Futuna',
            EH: 'Západná Sahara',
            YE: 'Jemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    hu: {
        _name: 'Hungarian',
        newDesign: {
            withdrawalRequests: 'Még nincs visszavonási kérelem',
            withdrawalRequestsText1: 'Eddig még nem nyújtott be kifizetési kérelmet.',
            withdrawalRequestsText2: 'Miután kezdeményezte a kifizetést, a tranzakció részletei itt jelennek meg.',
            pdfAttachment: 'PDF melléklet',
            agent: "Ügynök",
            closed: 'Zárt',
            pending: 'Függőben',
            approved: 'Jóváhagyva',
            declined: 'Elutasítva',
            document: 'Dokumentum',
            frontSide: 'Elülső oldal',
            backSide: 'Hátsó oldal',
            address: 'Cím:',
            frontSideDocument: 'A dokumentum elülső oldala',
            creditCard: 'Hitelkártya',
            selfie: 'Szelfi',
            other: 'Egyéb',
            noDocument: 'Még nincsenek feltöltött dokumentumok',
            amountDeposited: 'Befizetett összeg',
            makeTransfer: 'Tegyen átutalást',
            otherDetails: 'Megrendelés részletei',
            manualWallets: 'Kriptotárcák',
            depositText: 'A befizetés feldolgozása 5-10 percen belül megtörténik',
            pendingBankTransfer: 'Önnek van egy függőben lévő banki átutalásos befizetése.',
            completeDeposit: 'Kérjük, kövesse az alábbi utasításokat a befizetés befejezéséhez',
            downloadInvoice: 'A számla letöltése',
            payInvoice: 'Fizesse ki a számlát a bankjában',
            useYourBankText1: 'A számla kifizetéséhez használja bankja online vagy offline fizetési rendszerét.',
            useYourBankText2: 'Győződjön meg arról, hogy a fizetési adatok helyesek.',
            uploadReceipt: 'Töltse fel a visszaigazoló bizonylatot',
            afterMakingPayment: 'A befizetés után töltse fel a nyugtát a befizetés megerősítésére.',
            clickToUpload: 'Kattintson a feltöltéshez',
            orDrag: 'vagy húzza és tegye le',
            fileFormat: 'PDF, JPG, JPEG, PNG 10 MB-nál kisebb méretben',
            waitForDeposit: 'Várjon a befizetés visszaigazolására',
            onceReceipt: 'Miután feltöltöttük a bizonylatot, ellenőrizzük és jóváhagyjuk a befizetést.',
            paymentDetails: 'Fizetési részletek',
            nameOnCard: 'Név a kártyán',
            cardNumber: 'Kártya száma',
            expirationDate: 'Lejárati idő',
            securityCode: 'Biztonsági kód',
            continue: 'Folytatás',
            viewAccounts: 'Fiókok megtekintése',
            continueWithdrawal: 'Folytassa a visszavonást',
            accountBalance: 'Számlaegyenleg',
            accountBalanceText1: 'Miért nem tudok kereskedést kötni?',
            accountsBalanceText2: 'Hogyan fizethetek be pénzt?',
            accountsBalanceText3: 'Pénzt szeretnék befizetni a Zellén keresztül',
            tradingActions: 'Kereskedelmi tevékenységek',
            tradingActionsText1: 'Hogyan lehet shortolni?',
            tradingActionsText2: 'Hogyan tudom törölni a kereskedést?',
            tradingActionsText3: 'Mennyi a minimális kereskedési összeg?',
            marketInfo: 'Piaci információk',
            marketInfoText1: 'Hol láthatom a piaci trendeket?',
            marketInfoText2: 'Melyek ma a legnagyobb nyereségesek?',
            securityAndSettings: 'Biztonság és beállítások',
            securityAndSettingsText1: 'Hogyan változtathatom meg a jelszavam?',
            securityAndSettingsText2: 'Mik a biztonsági jellemzők?',
            securityAndSettingsText3: 'Hogyan frissíthetem a számlaadataimat?',
            messageCopied: 'Üzenet a vágólapra másolva',
            addSymbol: 'Szimbólum hozzáadása',
            iHaveRead: 'Elolvastam és elfogadom a feltételeket.',
            toggleToTurn: 'Kapcsolja be az AutoTrade bekapcsolásához',
            selectTheRisk: 'Válassza ki a kockázati szintet',
            confirm: 'Megerősítés',
            cancel: 'Törölje',
            chooseClosingTime: 'Válasszon zárási időt',
            setOrderClosingTime: 'Beállított rendelési zárási idő',
            closingTime: 'Záróra',
            date: 'Dátum',
            time: 'Idő',
            setCloseTime: 'Beállított zárási idő',
            '15m': '15 perc',
            '30m': '30 perc',
            '1h': '1 óra',
            custom: 'OTP ellenőrzése',
            verifyOtp: 'OTP ellenőrzése',
            enterDigits: 'Írja be a [email/telefon] címre küldött 6 számjegyű kódot.',
            submit: 'Elküldés',
            order: 'A(z)',
            closedSuccessfully: 'megrendelés sikeresen lezárva',
            openSuccessfully: 'sikeresen megnyitni',
            deposit: 'letét',
            withdrawal: 'visszavonás',
            completeVerification: 'Teljes ellenőrzés',
            verification: 'Ellenőrzés',
            avatar: 'Avatár',
            saveChanges: 'Változások mentése',
            darkMode: 'Sötét üzemmód',
            dashboardCurrency: 'Műszerfal Pénznem',
            language: 'Nyelv',
            twoFactor: '2-faktoros védelem',
            googleTwoFactorIsActive: 'A Google 2-faktoros hitelesítő aktív',
            activateCodeForGoogle: 'Aktiválja a Google Authenticator kódját',
            ifYouWishDisableEnterCode: 'Ha le kívánja tiltani a védelmet, adja meg az autentikátor alkalmazás kódját.',
            scanOrManuallyEnter: 'A QR-kód beolvasása vagy kézi beírása a Google 2FA-ban a token hozzáadásához',
            copyCode: 'Kód másolása',
            qrCode: 'QR-kód',
            activateAndEnterAuthenticator: 'Aktiválja a Hitelesítőt, és írja be a generált kódot az alábbi mezőbe',
            enterTheDigitsCodeGenerated: 'Adja meg a generált 6 számjegyű kódot',
            disableTwoFactor: 'Kétfaktoros védelem kikapcsolása',
            activateTwoFactor: '2FA-védelem aktiválása',
            fileAdded: 'Fájl sikeresen hozzáadva',
            theVerificationStatus: 'Az ellenőrzés állapota a feltöltött dokumentumok listáján tekinthető meg',
            confirmWithdrawal: 'Kivonás megerősítése',
            minimumWithdrawal: 'A minimális kifizetési összeg',
            welcomeToTradingPlatform: 'Üdvözöljük a kereskedési platformon',
            signIn: 'Bejelentkezés',
            activeAccount: 'Aktív fiók',
            save: 'Mentés',
            selectCurrencyAndPeriod: 'Válassza ki a pénznemet és az előfizetési időszakot',
            savingsNotFound: 'Nem talált megtakarítási számlák',
            pleaseCheckInvestment: 'Kérjük, ellenőrizze befektetési adatait a folytatáshoz',
            allPeriodInvestment: 'Minden időszaki beruházás',
            pct_0: 'Rugalmas',
            pct_30: '1 hónap',
            pct_60: '1 negyedév',
            pct_90: '6 hónap',
            pct_180: '6 hónap',
            pct_365: '1 év',
            savingsCreated: 'Sikeresen létrehozott megtakarítási számla',
            transferDetails: 'Transzfer részletek',
            transferAmount: 'Átutalás összege',
            investmentDetails: 'Befektetési részletek',
            investment: 'Befektetés',
            Period: 'Időszak',
            created: 'Létrehozva',
            releaseTime: 'Kibocsátási idő',
            viewSavingsAccounts: 'Megtakarítási számlák megtekintése',
            continueInvestment: 'Folytassa a beruházást',
            chooseSubscription: 'Válasszon előfizetést',
            selectCurrencyAndSubscription: 'A folytatáshoz válassza ki a pénznemet és az előfizetési időszakot.',
            rate: 'Értékelés',
            accountTransferFunds: 'Számla, ahonnan pénzt kell átutalni',
            amount: 'Összeg',
            'in': 'a  oldalon.',
            openSavingsAndInvest: 'Megtakarítási számla megnyitása és befektetés',
            strongSell: 'Erős eladás',
            sell: 'Eladó',
            neutral: 'Semleges',
            buy: 'Vásárlás',
            stringBuy: 'Erős vétel',
            chooseCryptoWallet: 'Válasszon egy kriptopénz tárcát',
            chooseMethod: 'Válasszon egy módszert',
            maximumDeposit: 'A maximális befizetés',
            minimumDeposit: "A minimális befizetés",
            pleaseCompleteAddress: 'Kérjük, töltse ki a címét',
            pleaseCompleteCity: 'Kérjük, töltse ki a várost',
            pleaseCompleteCountry: 'Kérjük, töltse ki az országot',
            pleaseCompleteZip: 'Kérjük, töltse ki az irányítószámát',
            amountDeposit: 'Betét összege',
            transactionStatus: 'Tranzakció állapota',
            paymentMethod: 'Fizetési mód',
            depositTo: 'Letétbe helyezés',
            depositAddress: 'Letéti cím',
            scanTheQr: 'Szkennelje be a QR-kódot vagy másolja le a letéti címet',
            wallet: 'Pénztárca',
            copyDepositAddress: 'Letéti cím másolása',
            copy: 'Másolás',
            addressCopied: 'Cím másolása a vágólapra',
            '5min:': '5-10 perc',
            '2-5days': '2-5 nap / 5-10 perc',
            otherMethod: 'Egyéb módszer',
            profileSettings: 'Profil beállítások',
            savingsAccounts: 'Megtakarítási számlák',
            subscribe: 'Feliratkozás',
            toCompleteVerification: 'A számla hitelesítési folyamatának befejezéséhez kérjük, hogy nyújtsa be a következő dokumentumok tiszta és olvasható másolatát',
            amountToWithdrawal: 'Visszavehető összeg',
            cardDetails: 'Kártya adatai',
            cardHolderName: 'Kártyabirtokos neve',
            withdrawalSuccessful: 'Sikeres visszavonás',
            selectWithdrawalMethod: 'Válassza ki a kifizetési módot',
            creditDebit: 'Hitel-/betéti kártya',
            crypto: 'Kriptó',
            cryptoWalletAddress: 'Crypro pénztárca cím',
            cryptoSubtitle: 'Kripto-kifizetések a tárcádba 🚀',
            bankWithdrawal: 'Banki kifizetés',
            bankSubtitle: 'Bankszámla 🏦',
            zelleSubtitle: 'Gyors banki átutalások ⚡🏦',
            tradingResults: 'Kereskedési eredmények',
            day: 'Nap',
            month: 'Hónap',
            noTradingData: 'Nem állnak rendelkezésre kereskedési adatok',
            startTradingToSee: 'Kezdje el a kereskedést, hogy lássa az eredményeit itt.',
            yourPerformanceMetric: 'Az Ön teljesítménymutatói megjelennek, amint az adatok rendelkezésre állnak.',
            favorite: 'Kedvenc',
            all: 'Összes',
            availableFullTime: 'Elérhető 24/7',
            yourProfileAndSettings: 'Az Ön profilja és beállításai',
            switchToNewDesignMessage: 'Váltson át az új platform dizájnunkra most!',
            switchToOldDesignMessage: 'Korlátozott ideig még használhatja a klasszikus dizájnt.',
            switchToOldDesignButton: 'Új dizájnra váltás',
            switchToNewDesignButton: 'Visszatérés a klasszikus kialakításhoz',
            generateInvoice: 'Számla létrehozása',
            downloadPdf: 'PDF letöltése',
            confirmDeposit: 'Letét jóváhagyása',
            howCanIAssist: 'Hogyan segíthetek ma?',
            maxFileSize: 'A maximális fájlméret 5 MB, az elfogadott formátumok: JPEG, PNG vagy GIF',
            uploadPhoto: 'Fénykép feltöltése',
            featuresDisabled: 'Ez a funkció le van tiltva',
            welcome: 'Üdvözöljük',
            signUp: 'Regisztráció',
            registration: 'Regisztráció',
            pleaseConfirmAgreement: 'Kérjük, erősítse meg az ügyfélszerződést',
            pleaseEnterPassword: 'Kérjük, adjon meg egy jelszót',
            passwordNotSame: 'A jelszavak nem egyeznek',
            noActiveOrders: 'Nincs aktív rendelés',
            itLikeNoHaveActiveOrders: 'Úgy tűnik, jelenleg nincsenek aktív rendelései',
            cryptoWallet: 'Kriptotárca',
            bankCard: 'Bank/Kártya',
            selected: 'Kiválasztva',
            amountWithdrawal: 'Kivett összeg',
            withdrawalTo: 'Kivét ide',
            governmentIssuedPhoto1: 'Kormány által kiállított fényképes igazolvány (például útlevél, jogosítvány, nemzeti személyi igazolvány)',
            governmentIssuedPhoto2: 'Tartózkodási engedély (ha releváns)',
            governmentIssuedPhoto3: 'Az okmánynak érvényesnek kell lennie (nem lejárt)',
            governmentIssuedPhoto4: 'A teljes név, születési dátum és fénykép látható kell, hogy legyen.',
            uploadRecent1: 'Töltsön fel egy legutóbbi közüzemi számlát, bankszámlakivonatot vagy bérleti szerződést, amely az elmúlt 3 hónapban készült',
            uploadRecent2: 'Győződjön meg arról, hogy a teljes neve és a jelenlegi lakcíme jól látható.',
            uploadCopy1: 'Töltse fel a hitelkártyája elülső oldalának másolatát, győződjön meg róla, hogy az első hat és az utolsó négy számjegy látható',
            uploadCopy2: 'Kérjük, biztonsági okokból takarja el a középső számjegyeket',
            takeAndUpload1: 'Készítsen szelfit, miközben az arcához tartja az igazolványát, és töltse fel',
            takeAndUpload2: 'Győződjön meg róla, hogy az arca és az igazolvány jól látható.',
            otherDocuments: 'Egyéb dokumentumok',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Töltsön fel egy tiszta és olvasható képet a társadalombiztosítási számot (SSN) tartalmazó dokumentumáról. Győződjön meg róla, hogy minden részlet látható és akadálymentes.',
            frontSideSsnDocument: 'Az SSN-dokumentum elülső oldala',
            answerSaved: 'A válaszok elmentve',
            cryptoWalletDetails: 'Kriptotárca részletei',
            bitcoinWalletAddress: 'Bitcoin tárca címe',
            recovery: 'Helyreállítás',
            passwordRecovery: 'Jelszó helyreállítása',
            searchCountry: 'Ország keresése',
            questionnaire: 'Kérdőív',
            questionnaireText: 'Kérjük, töltse ki a kérdőívet személyazonossága igazolásához',
            sendAnswers: 'Válaszok elküldése',
        },
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit és Stop Loss',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            updateOrder: 'Megbízás frissítése ',
        },
        balanceModal: {
            balance: 'Egyenleg',
            credit: 'Kredit',
            equity: 'Mennyiség',
            practice: 'Gyakorlás',
            real: 'Éles ',
            account: 'SZÁMLA',
            invested: 'Befektetve',
            profit: 'Profit',
            loss: 'Veszteség',
            margin: 'Tőke',
            marginLevel: 'Tőke szint',
            marginFree: 'Szabad Tőke'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Befizetés'
        },
        sideMenu: {
            marketWatch: 'Piacfigyelő ',
            activeOrders: 'Aktív megbizások',
            tradingHistory: 'Számlatörénet',
            economicCalendar: 'Naptár',
            marketNews: 'Piaci hírek'
        },
        closePositionModal: {
            closeOrder: 'Pozíció zárása',
            areYouSure: 'Biztos, hogy zárni akarja a pozíciót?',
            buy: 'Vétel',
            sell: 'Eladás',
            yes: 'Igen',
            no: 'Nem',
        },
        pciDssModal: {
            desc: `A Payment Card Industry Data Security Standard (PCI DSS) szabvány betartása kötelező minden olyan szervezetre, amely tárolja, feldolgozza vagy továbbítja a Visa/Master Card/Stripe kártyabirtokosok adatait, beleértve a pénzintézeteket, kereskedőket és szolgáltatókat. Ez a globális biztonsági szabvány minden olyan szervezetre vonatkozik, amely tárolja, feldolgozza vagy továbbítja a kártyabirtokosok adatait és/vagy érzékeny hitelesítési adatokat. A PCI DSS alapvető szintű védelmet biztosít a fogyasztók számára, és segít csökkenteni a csalásokat és az adatszivárgásokat az egész fizetési ökoszisztémában.`
        },
        chart: {
            addNewChart: 'új chart megnyitása',
        },
        symbolsModal: {
            watchlist: 'Megfigyelő lista',
            asset: 'Eszköz',
            price: 'Ár',
            changePct: 'Napi mozgás'
        },
        pendingModal: {
            pending: 'Függőben',
            assetPrice: 'Eszköz ára',
            current: 'Aktuális',
            revert: 'Vissza a piaci árhoz',
            automatically: 'A pozíció automatikusan megnyílik, ha az ár eléri ezt a szintet.'
        },
        orderMenu: {
            default: 'alapértelmezett',
            volume: 'Volumen',
            lots: 'lot-ok',
            units: 'egységek',
            currency: 'pénznem',
            contractSize: 'Kötési méret',
            position: 'Pozíció',
            margin: 'Tőke',
            freeMargin: 'Szabad Tőke',
            takeProfitStopLoss: 'Take Profit és Stop Loss',
            pending: 'Függőben',
            market: 'Piac',
            leverage: 'Áttét',
            spread: 'Szórás',
            notSet: 'Nincs beállítva',
            at: '-nál',
            buy: 'vétel',
            sell: 'eladás',
        },
        footer: {
            supportBanner: 'MINDEN NAP, ÉJJEL-NAPPAL',
            currentTime: 'AKTUÁLIS IDŐ',
            liveChat: 'Élő Chat'
        },
        statusBar: {
            balance: 'Egyenleg',
            credit: 'Kredit',
            equity: 'Mennyiség',
            margin: 'Tőke',
            marginLevel: 'Tőke szint',
            freeMargin: 'Szabad tőke',
            pnl: 'PnL',
            profitTotal: 'Összesített PnL'
        },
        accountModal: {
            dateRegistered: 'Nyilvántartásba vétel dátuma',
            userId: 'Felhasználó ID',
            dashboard: 'Vezérlőpult',
            personalData: 'Személyes Adat',
            deposit: 'Befizetés',
            withdrawFunds: 'Kivont alapok',
            savings: 'Megtakarítások',
            settings: 'Beállítások',
            logout: 'Kijelentkezés'
        },
        depositModal: {
            BT_INVOICE: 'Az összeg megadása és a számla létrehozása',
            BT_TITLE: 'Banki részletek',
            account: 'Felhasználó',
            bank_name: 'Bank neve',
            beneficiary: 'Kedvezményezett',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Mennyiség',
            TOKEN_SUBTOTAL: 'Részösszeg',
            TOKEN_TOTAL: 'Összesített',
            TOKEN_DESCRIPTION: 'Leírás',
            TOKEN_PAYMENT_DETAILS: 'Utalási részletek',
            PENDING_DEPOSITS_LIMIT: 'Befizetési Limit',
            makeDeposit: 'Fizessen be',
            practiceAccount: 'Gyakorló számla',
            realAccount: 'Élő számla',
            noWithdrawals: 'Nincs kifizetés',
            easyWithdrawals: 'Egyszerű kifizetés',
            allAssets: 'Minden eszköz elérhető',
            fullFledged: 'Teljes körű platform',
            fillUpTo: 'Töltse fel',
            freeReplenishment: 'Ingyenes feltöltés',
            topUp: 'Töltse fel számláját',
            minimumAmount: 'Minimum mennyiség',
            canSwitch: 'Bármikor válthat a számlái közt'
        },
        gridsModal: {
            chartGrids: 'CHART Rácsok',
            chart1: '1 chart',
            chart2: '2 chart',
            chart3: '3 chart',
            chart4: '4 chart',
        },
        noConnectionModal: {
            connectionLost: 'Megszakadt a kapcsolat a szerverrel',
            retryNow: 'Újrapróbálkozás',
        },
        loginModal: {
            loginToTradeRoom: 'Bejelentkezés a kereskedőfelületre',
            email: 'Email cím',
            enterEmail: 'Írja be az email címét',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA Kód (ha engedélyezett)',
            twoFactorAuth: 'Két faktoros azonosítás',
            password: 'Jelszó',
            yourPassword: 'Az ön jelszava',
            signIn: 'Bejelentkezés',
            dontHaveAccount: 'Nincs még számlája?',
            restore: 'Visszaállítás',
            fullName: 'Teljes név',
            havePromo: 'Van promóciós kódom',
            promo: 'Promóciós kód',
            login: 'Bejelentkezés',
            haveAccount: 'Már van számlája?',
            hide: 'Elrejtés',
            goTo: 'Lépjen vissza'
        },
        newPasswordModal: {
            setNewPassword: 'Új jelszó beállítása',
            newPassword: 'Új jelszó',
            confirmNewPassword: 'Új jelszó megerősítése',
            setButton: 'Beállítás'
        },
        registerModal: {
            repeatPassword: 'Jelszó ismét',
            createNewAccount: 'Készítsen új fiókot',
            email: 'Email cím',
            firstName: 'Keresztnév',
            lastName: 'Vezetéknév',
            country: 'Ország',
            phone: 'Telefonszám',
            password: 'Jelszó',
            createAccount: 'Fiók létrehozása',
            currency: 'Valuta',
            privacyAndPolicy: "Adatvédelem és Szabályzat",
            customerAgreement: 'Ügyfélmegállapodás',

        },
        forgotPasswordModal: {
            forgotPassword: 'Elfelejtette a jelszavát?',
            submitEmail: 'Kérjük, küldje el a regisztrációhoz használt e-mailt, ellenőrizze postaládáját, és kövesse a megadott utasításokat.',
            submit: 'Elküldés'
        },
        notifications: {
            error: 'Hiba',
            success: 'Sikeres',
            deposit: 'Befizetés',
            withdrawal: 'Kivétel',
            depositApproved: 'jóvá hagyták',
            depositDeclined: 'A befizetést elutasították',
            withdrawalApproved: 'A kivételt jóvá hagyták',
            withdrawalDeclined: 'A kivételt elutasították'
        },
        markets: {
            favorites: 'Figyelőlista', 
            all: 'Minden szimbólum',
            forex: 'Forex',
            stocks: 'Kötvények',
            commodities: 'Áruk',
            indices: 'Indexek',
            crypto: 'Kriptó',
            metals: 'Nyersanyagok',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'VÉTEL',
            profitCalculator: 'Nyereségkalkulátor',
            sell: 'ELADÁS',
            volume: 'Volumen',
            entryPrice: 'Belépési ár',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Maximum pozíció',
            calculate: 'Kalkuláció',
            calculationsFor: 'Kalkuláció',
            leverage: 'Tőkeáttét',
            requiredMargin: 'Elvárt tőke',
            profitFromTP: 'Profit a TP-ből',
            lossFromSL: 'Veszteség a SL-ból',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Befizetés sikeres',
            text: 'A befizetését sikeresen feldolgoztuk!',
        },
        depositFailModal: {
            title: 'Befizetés elutasítva',
            text: 'Az Ön befizetése nem került feldolgozásra.',
        },
        widgetMarketWatch: {
            marketWatch: 'Piacfigyelő',
            search: 'Keresés...',
        },
        widgetOrders: {
            commission: 'Bizottság',
            volume: 'Volumen',
            margin: 'Tőke',
            active: 'Aktív',
            pending: 'Függő',
            activeOrders: 'Aktív ügyletek',
            portfolio: 'Portfólió',
            allPositions: 'Összes Pozíció',
            noPositionsLine1: 'Nincsenek nyitott',
            noPositionsLine2: 'pozíciók jelenleg',
            show: 'Mutasd',
            more: 'több',
            less: 'kevesebb',
            purchaseTime: 'Vételi idő',
            closePosition: 'Pozíció zárása',
            priceOpen: 'Vételi ár',
            priceSl: 'Stop Loss Ár',
            priceTp: 'Take Profit Ár',
            type: 'Pozíció iránya',
            pnl: 'Profit/Loss',
            cancelOrder: 'Pozíció törlése',
            orderType: 'Ügylet típusa',
            cancelled: 'Törölve',
            tradingHistory: 'Kereskedési történet',
            noHistoryLine1: 'Eddig nincs ...',
            noHistoryLine2: 'Zárt pozíciók eddig',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Naptár'
        },
        widgetMarketNews: {
            marketNews: 'Piaci hírek'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Aktív megbízások',
            ordersHistory: 'Megbízások története',
            id: 'ID',
            symbol: 'Szimbólum',
            type: 'Típus',
            volume: 'Volumen',
            openPrice: 'Nyitási ár',
            openTime: 'Nyitási időpont',
            closePrice: 'Záró ár',
            closeTime: 'Zárási idő',
            sl: 'SL',
            tp: 'TP',
            price: 'Ár',
            pnl: 'PnL',
            actions: 'Akciók',
            edit: 'Szerkesztés',
            close: 'Zárás',
            commission: 'Bizottság',
            swap: 'Csere',
            swapLong: 'Long csere',
            swapShort: 'Short csere'
        },
        assetInfoModal: {
            assetInfo: 'Eszköz infó',
            tradingConditions: 'Kereskedési kondíciók',
            information: 'Információ',
            bid: 'Ajánlat',
            ask: 'Kérés',
            sessionChange: 'Munkamenet váltás',
            tradeNow: 'Kereskedjen most',
            opens: 'Nyitások',
            closes: 'Zárások',
            at: '-nál',
            open247: 'Non-stop nyitva',
            today: 'ma',
            tomorrow: 'holnap',
            sunday: 'Vasárnap',
            monday: 'Hétfő',
            tuesday: 'Kedd',
            wednesday: 'Szerda',
            thursday: 'Csütörtök',
            friday: 'Péntek',
            saturday: 'Szombat',
            contractSpecification: 'Szerződéses előírás',
            symbol: 'Szimbólum',
            name: 'Név',
            market: 'Piac',
            baseCurrency: 'Fő valuta',
            digits: 'Számjegyek',
            lotSize: 'Lot méret',
            lotStep: 'Lot lépés',
            minVolume: 'Minimum Volumen',
            maxVolume: 'Maximum Volumen',
            leverage: 'Áttét',
            commission: 'Kamat',
            swapLong: 'Long csere',
            swapShort: 'Short csere',
            schedule: 'Ütemterv',
            weekday: 'Munkanap',
            tradingTime: 'Kereskedési idő',
            closed: 'zárva',
            sell: 'Eladás',
            buy: 'Vétel',
            low: 'Alacsony',
            high: 'Magas',
            oneHourChange: '1 órás változás',
            oneDayChange: '1 napos változás',
            oneWeekChange: '1 heti változás',
            oneMonthChange: '1 havi változás',
            threeMonthsChange: '3 havi változás',
            oneYearChange: '1 éves változás',
            loading: 'Töltés...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'Ügyletek naponta:',
            desc: `A CFD-k és más tőkeáttételes termékekkel való kereskedés veszteségekhez vezethet. Kereskedés előtt az ügyfeleknek el kell olvasniuk a megfelelő kockázati nyilatkozatokat a kockázati tájékoztató oldalunkon. Az automatizált kereskedés nem garantál eredményeket. A vállalat nem vállal felelősséget az automatizált kereskedés során bekövetkező pénzveszteségekért. Győződjön meg arról, hogy teljes mértékben megérti a kockázatokat, és tegyen lépéseket azok kezelésére.`,
            accept: 'Accept',
            save: 'Mentés',
            cancel: 'Mégse',
        },
        cardBox: {
            name: 'Név',
            cardNumber: 'Kártyaszám',
            expiration: 'Lejár (hh/éé)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: 'Kereskedés',
            startTrading: 'Kereskedés kezdése',
            deposit: 'Befizetés',
            dashboard: 'Vezérlőpult',
            personalInfo: 'Személyes infó',
            contactInfo: 'Kapcsolatfelvételi információk',
            withdrawal: 'Kivétel',
            verification: 'Ellenőrzés',
            accounts: 'Fiókok',
            liveChat: 'Élő Chat',
            savings: 'Megtakarítások',
            referrals: 'Ajánlóprogram',
            settings: 'Beállítások',
            logOut: 'Kijelentkezés',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            sendOtp: 'Küldés otp-be',
            otpCode: 'Otp kód',
            addWalletAddress: 'A pénztárca ehhez a befizetési módhoz nincs beállítva. Kérjük, lépjen kapcsolatba ügynökével vagy ügyfélszolgálatával.',
            addressReceipt: 'Kérjük, várjon a cím betöltésére',
            makeDeposit: 'Fizessen be',
            instant: 'Azonnali',
            minutes: 'percek',
            hours: 'órák',
            days: 'napok',
            amount: 'Mennyiség',
            continue: 'Folytatás',
            qrCode: 'QR kód',
            depositAddress: 'Befizetési cím',
            copy: 'Másol',
            last: 'UTOLSÓ',
            deposits: 'BEFIZETÉS',
            time: 'Idő',
            currency: 'Valuta',
            status: 'Státusz',
            info: 'Infó',
        },
        fullScreenCcExt: {
            pleaseWait: 'Kérem, várjon. Kapcsolódás a fizetési szolgáltatóhoz...',
            cardPayment: 'Kártyás fizetés',
            inOrder: 'Az összeg elküldéséhez',
            pleaseProvide: 'kérjük, adja meg a kártya adatait:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'Még nincs rendelés',
            totalBalance: 'Teljes egyenleg',
            totalPnl: 'Összes PNL',
            profitableOrders: 'Nyereséges megrendelések',
            roi: 'ROI',
            activityLog: 'Tevékenységi napló',
            loginFromIp: 'Bejelentkezés IP-ről',
            tradingResults: 'Trading Results',
            week: 'Hét',
            month: 'Hónap',
            year: 'Év',
            successRate: 'Sikerességi arány',
            closedWithProfit: 'Nyereséggel zárt',
            closedWithLoss: 'Veszteséggel zárt',
            account: 'Fiók',
            balance: 'Egyenleg',
            leverage: 'Tőkeáttétel',
            credit: 'Hitel',
            tradeNow: 'Kereskedés most',
            usingCurrentRate: 'aktuális árfolyamot használ',
            totalDeposits: 'Összes betét',
            totalWithdrawals: "Összes kivét",
            returnOnInvestment: "Befektetés megtérülése"
        },
        fullScreenPersonal: {
            personalDetails: 'Személyes adatok',
            profilePhoto: 'Profilkép',
            firstName: 'Keresztnév',
            country: 'Ország',
            lastName: 'Utónév',
            address: 'Cím',
            email: 'E-mail',
            telefon: 'Telefonszám',
            saveChanges: 'Változások mentése',
            state: 'Város',
            postcode: 'Irányítószám',
            created: "Fiók létrehozva",
        },
        fullScreenAvatar: {
            dropFile: 'Huzzon egy fájlt a fenti körre a feltöltéshez',
            notAllowed: 'Nem engedélyezett a közzététel',
            notAllowedLine1: 'Kifejezetten szexuális vagy pornográf jellegű fényképek',
            notAllowedLine2: 'Etnikai vagy faji gyűlöletkeltésre vagy agresszióra irányuló képek',
            notAllowedLine3: '18 év alatti személyeket ábrázoló képek',
            notAllowedLine4: 'Harmadik fél által szerzői jogvédelem alatt álló fényképek',
            notAllowedLine5: '5 MB-nál nagyobb és nem JPG, GIF vagy PNG formátumú képek',
            requirements: 'Az arcnak jól láthatónak kell lennie a fényképen. Az Ön által feltöltött összes fényképnek és videónak meg kell felelnie ezeknek a követelményeknek, ellenkező esetben eltávolíthatók.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Kérjük, lépjen kapcsolatba számlavezetőjével a kifizetés részleteiért',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Visszavonás',
            requestNewWithdrawal: 'ÚJ KIFIZETÉS KÉRÉSE',
            amount: 'Összeg',
            currentBalance: 'Az Ön aktuális számlaegyenlege',
            withdrawAll: 'Minden pénzfelvétel',
            requestWithdrawal: 'Kifizetés kérése',
            last: 'UTOLSÓ',
            withdrawalRequests: 'Kifizetési kérelmek',
            time: 'Idő',
            currency: 'Pénznem',
            info: 'Info',
            status: 'Állapot',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Kártya',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Kifizetés részletei',
            address: 'Pénztárca címe',
            bankDetails: 'Banki adatok',
            bankAccount: 'Számlaszám',
            bankHolder: 'Számlatulajdonos neve',
            bankIban: 'IBAN',
            bankSwift: 'Banki SWIFT-kód',
            recipientAddress: 'Címzett címe',
            routingNumber: 'Banki útválasztási szám',
            bankBeneficiary: 'Kedvezményezett bankjának neve',
            bankAddress: 'Bank címe',
            bankCode: 'Bankkód (BSB)',
            desc: 'Leírás',
            cardNumber: 'Kártyaszám',
            cardHolder: 'Kártyabirtokos neve',
        },
        fullScreenVerification: {
            dragAndDrop: 'húzza és helyezze a dokumentumot erre a területre',
            documentsList: 'A FELTÖLTÖTT DOKUMENTUMOK LISTÁJA',
            document: 'Dokumentum',
            timeUploaded: 'Feltöltés ideje',
            timeProcessed: 'Feldolgozott idő',
            status: 'Állapot',
            types: ['Személyi igazolvány', 'Lakcímkártya', 'Bankkártya előlap', 'Bankkártya hátlap', 'Személyi igazolvány hátlap', 'Szelfi', 'Other'],
            types2: ['Személyazonossági igazolvány (POI)', 'Lakcímkártya', 'Bankkártya előlap', 'Bankkártya hátlap', 'Személyazonossági igazolvány hátlap', 'Selfie azonosítóval'],
            upload: 'Feltöltés',
            isConfirmed: 'megerősített',
            uploadAnotherDocument: 'Újabb dokumentum feltöltése',
            isUnderReview: 'felülvizsgálat alatt',
            questionnaire: 'Kérdőív',
            sendAnswers: 'Válaszok küldése',
            progressBarOf: 'ennyiből: ',
            progressBarText: 'dokumentumai feltöltésre és megerősítésre kerültek'
        },
        fullScreenAccounts: {
            manageAccounts: 'Pénzátutalás a számlák között',
            transferFrom: 'Számláról',
            transferTo: 'Számlára',
            amount: 'Összeg',
            makeTransfer: 'Átutalás végrehajtása',
            yourAccounts: 'Saját számlái',
            account: 'Fiók',
            currency: 'Pénznem',
            balance: 'Egyenleg',
            credit: 'Hitel',
            makeActive: 'Tegye aktívvá',
            active: 'Aktív'
        },
        fullScreenSupport: {
            sendMessage: 'Üzenet küldése'
        },
        fullScreenSavings: {
            pct_0: 'Rugalmas',
            pct_30: '1 hónapig zárolva',
            pct_90: '1 negyedévig zárolva',
            pct_180: '6 hónapig zárolva',
            pct_365: ' 1 évig zárolva',
            savings: 'Megtakarítás',
            detailedInformation: 'Részletes információ',
            pleaseSelect: 'Kérjük, válasszon egy pénznemet és egy időszakot',
            openSavings: 'Nyitott megtakarítások',
            currentTime: 'Aktuális idő',
            releaseTime: 'Kibocsátási idő',
            currencyChosen: 'Kiválasztott pénznem',
            periodChosen: 'Kiválasztott időszak',
            yourRate: 'Saját árfolyam',
            yourEarnings: 'Bevételei',
            accountFrom: 'Számla, ahonnan átutalni kívánja a pénzt',
            enterAmount: 'Adja meg az összeget',
            in: 'Bent',
            openAndInvest: 'Takarékszámla megnyitása és befektetés',
            investment: 'Befektetés',
            period: 'Időszak',
            daysTotal: 'Napok összesen',
            finalEarnings: 'Végleges bevétel',
            created: 'Létrehozva',
            daysElapsed: 'Elmúlt napok',
            days: 'napok',
            earnings: 'Bevételek',
            fundsReleasedIn: 'Alapok ekkor szabadultak fel',
            claim: 'Igénylés',
            name: "Név",
        },
        fullScreenSettings: {
            updatePassword: 'JELSZÓ FRISSÍTÉSE',
            currentPassword: 'Jelenlegi jelszó',
            newPassword: 'Új jelszó',
            changePassword: 'Jelszó módosítása',
            activationForGoogle: 'Aktiváló kód a Google Authenticatorhoz',
            activateAndEnter: 'Aktiválja a hitelesítőt és adja meg a generált kódot az alábbi mezőben',
            qrCode: 'QR-kód',
            activateProtection: '2FA-védelem aktiválása',
            protectionActive: 'A 2FA-védelem aktív',
            twoFactorCode: '2FA-kód',
            disableProtection: '2FA-védelem kikapcsolása',
            language: 'Nyelv',
            dashboardCurrency: 'Az irányítópult pénzneme',
            confirmNewPassword: 'Új jelszó megerősítése',
        },
        fullScreenReferrals: {
            yourLink: 'AZ ÖN HIVATKOZÁSI LINKJE',
            referredFriends: 'REFERRED FRIENDS',
            id: 'ID',
            name: 'Név',
            registered: 'Regisztráció',
            level: 'Szint',
            payout: 'Kifizetés',
            lastPayouts: 'UTOLSÓ KIFIZETÉS',
            time: 'Idő',
            amount: 'Összeg',
            currency: 'Pénznem',
            info: 'Info',
            referralDesc:"Az ajánlóprogram célja, hogy jutalmazza Önt a befektetői közösségünk bővítéséért.",
            shareLink:"Ossza meg a hivatkozást:",
            subtitle:"Adja meg neki a nevét, e-mail címét vagy kereskedési azonosítóját.",
            descSuccesfull:"Amint az Ön által ajánlott személy sikeresen regisztrál, pénzügyi tanácsadója felveszi vele a kapcsolatot, hogy segítse első lépéseiben a befektetési útján a lehető leghatékonyabban és legprofesszionálisabban.",
            descTracked:"Minden hozzájárulása az Ön ajánlása alatt kerül nyomon követésre."
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Márc',
            Apr: 'Ápr',
            May: 'Máj',
            Jun: 'Jún',
            Jul: 'Júl',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Okt',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Vásárlás', 'Eladás', 'Vételi limit', 'Eladási limit', 'Vételi stop', 'Eladási stop'],
        statuses: ['Függőben', 'Jóváhagyva', 'Elutasítva', 'Feldolgozás'],
        errors: {
            NOT_FOUND: 'Hiba a kérésben',
            FORBIDDEN: 'Hozzáférés megtagadva. Kérjük, jelentkezzen be újra',
            SERVER_ERROR: 'A művelet sikertelen. Kérjük, próbálja meg később újra',
            INVALID_ACCOUNT: 'A munkamenet lejárt. Kérjük, jelentkezzen be újra',
            INVALID_EMAIL: 'Érvénytelen e-mail cím',
            INVALID_PHONE: 'Érvénytelen telefonszám',
            INVALID_COUNTRY: 'Érvénytelen ország',
            INVALID_LOGIN: 'Érvénytelen bejelentkezési adatok',
            USER_BLOCKED: 'Fiók letiltva. Kérjük, lépjen kapcsolatba az ügyfélszolgálattal',
            USER_REGISTERED: 'A megadott e-mail címmel vagy telefonszámmal rendelkező felhasználó már regisztrált',
            INVALID_USER: 'A felhasználó nem található',
            REJECTED: 'A művelet nem engedélyezett a platformkorlátozások miatt',
            INVALID_OLD_PASSWORD: 'A régi jelszó érvénytelen',
            INVALID_SYMBOL: 'Érvénytelen kereskedési szimbólum',
            INVALID_SYMBOL_PRECISION: 'Érvénytelen szimbólum pontossága',
            INVALID_FRAME: 'Érvénytelen grafikonkeret',
            INVALID_CURRENCY: 'Érvénytelen pénznem',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Érvénytelen számla kereskedési típusa',
            INSUFFICIENT_FUNDS: 'Elégtelen pénzeszközök',
            INVALID_WITHDRAWAL: 'Kifizetés nem található',
            INVALID_STATUS: 'Érvénytelen státusz',
            INVALID_FILE: 'Érvénytelen fájlkiterjesztés vagy túl nagy fájl',
            INVALID_DOCUMENT: 'Dokumentum nem található',
            INVALID_NAME: 'Érvénytelen név',
            INVALID_LOT_SIZE: 'Érvénytelen tételméret',
            INVALID_LOT_STEP: 'Érvénytelen tétellépés',
            INVALID_MIN_VOLUME: 'Érvénytelen minimális mennyiség',
            INVALID_MAX_VOLUME: 'Érvénytelen maximális mennyiség',
            INVALID_GROUP: 'Érvénytelen csoport',
            INVALID_SPREAD: 'Érvénytelen szórás',
            REQUEST_TIMEOUT: 'A kérés időzített. Kérjük, próbálja meg később újra',
            EXISTING_OPEN_ORDERS: 'Van egy nyitott rendelése, először zárja le a rendelést ehhez a felhasználóhoz, majd próbálja újra.',
            INVALID_TYPE: 'Érvénytelen megbízás típusa',
            INVALID_VOLUME: 'Érvénytelen megbízási mennyiség',
            INVALID_SL: 'Érvénytelen stop loss érték',
            INVALID_TP: 'Érvénytelen take profit érték',
            INVALID_PRICE: 'Érvénytelen megbízási ár',
            INVALID_EXPIRATION: 'Érvénytelen megbízás lejárata',
            NOT_ENOUGH_MARGIN: 'Nem elégséges margin',
            INVALID_ORDER: 'A megbízás nem található',
            MARKET_CLOSED: 'A kereskedés nem elérhető. Kérjük, próbálja meg újra',
            INVALID_BALANCE_TYPE: 'Érvénytelen típusú egyenlegművelet',
            INVALID_HASH: 'Érvénytelen hash',
            HASH_EXPIRED: 'A jelszó-visszaállítási link lejárt. Kérjük, kérjen újat',
            INVALID_CODE: 'Érvénytelen 2FA-kód',
            CHAT_DISABLED: 'A támogatási chathez való hozzáférés le lett zárva',
            WITHDRAWAL_NOT_ALLOWED: 'A kifizetésekhez való hozzáférés lezárult',
            TRADING_DISABLED: 'A kereskedés letiltva a folyószámlán',
            PENDING_DEPOSITS_LIMIT: 'Betéti limit túllépve',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Csak egy függőben lévő kifizetés engedélyezett',
            LOCAL_DEPOSIT_ONLY_REAL: 'Betét csak a valós számlán érhető el',
            LOCAL_DEPOSIT_POSITIVE: 'A befizetés összegének nagyobbnak kell lennie 0-nál',
            LOCAL_INVALID_CARD_NUMBER: 'Érvénytelen kártyaszám',
            LOCAL_INVALID_CARD_EXPIRATION: 'Érvénytelen kártya lejárat',
            LOCAL_INVALID_CVC: 'Érvénytelen CVV/CVC kód',
            LOCAL_PASSWORDS_NOT_MATCH: 'Jelszavak nem egyeznek, kérjük, adja meg újra',
            LOCAL_NO_TRADING_ACCOUNTS: 'Ön nem rendelkezik elérhető fizetési számlával. Kérjük, lépjen kapcsolatba az ügyfélszolgálattal',
            LOCAL_MIN_DEPOSIT: 'A minimális befizetés $',
            LOCAL_MIN_WITHDRAWAL: 'A minimális kifizetési összeg $',
            LOCAL_INVALID_FILE_TYPE: 'Nem támogatott fájlformátum. Kérjük, csak JPG, PNG vagy PDF fájlokat csatoljon',
            LOCAL_MIN_STAKING: 'A minimális befizetés ebben a pénznemben ',
            FLOOD_REJECTED: 'Ön túl gyakran küld üzeneteket. Kérjük, próbálja újra később'
        },
        countries: {
            AF: 'Afganisztán',
            AX: 'Aland-szigetek',
            AL: 'Albánia',
            DZ: 'Algéria',
            AS: 'Amerikai Szamoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktisz',
            AG: 'Antigua és Barbuda',
            AR: 'Argentína',
            AM: 'Örményország',
            AW: 'Aruba',
            AU: 'Ausztrália',
            AT: 'Ausztria',
            AZ: 'Azerbajdzsán',
            BS: 'Bahama-szigetek',
            BH: 'Bahrein',
            BD: 'Banglades',
            BB: 'Barbados',
            BY: 'Fehéroroszország',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhután',
            BO: 'Bolívia',
            BA: 'Bosznia-Hercegovina',
            BW: 'Botswana',
            BV: 'Bouvet-sziget',
            BR: 'Brazília',
            IO: 'Brit Indiai-óceáni Terület',
            BN: 'Brunei Darussalam',
            BG: 'Bulgária',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodzsa',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Zöld-foki Köztársaság',
            KY: 'Kajmán-szigetek',
            CF: 'Közép-afrikai Köztársaság',
            TD: 'Csád',
            CL: 'Chile',
            CN: 'Kína',
            CX: 'Karácsony-sziget',
            CC: 'Cocos (Keeling) szigetek',
            CO: 'Kolumbia',
            KM: 'Comore-szigetek',
            CG: 'Kongó',
            CD: 'Kongói Demokratikus Köztársaság',
            CK: 'Cook-szigetek',
            CR: 'Costa Rica',
            CI: 'Elefántcsontpart',
            HR: 'Horvátország',
            CU: 'Kuba',
            CY: 'Ciprus',
            CZ: 'Cseh Köztársaság',
            DK: 'Dánia',
            DJ: 'Dzsibuti',
            DM: 'Dominika',
            DO: 'Dominikai Köztársaság',
            EC: 'Ecuador',
            EG: 'Egyiptom',
            SV: 'El Salvador',
            GQ: 'Egyenlítői-Guinea',
            ER: 'Eritrea',
            EE: 'Észtország',
            ET: 'Etiópia',
            FK: 'Falkland-szigetek (Malvin-szigetek)',
            FO: 'Feröer-szigetek',
            FJ: 'Fidzsi-szigetek',
            FI: 'Finnország',
            FR: 'Franciaország',
            GF: 'Francia Guyana',
            PF: 'Francia Polinézia',
            TF: 'Francia Déli Területek',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Grúzia',
            DE: 'Németország',
            GH: 'Ghána',
            GI: 'Gibraltár',
            GR: 'Görögország',
            GL: 'Grönland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard-sziget és Mcdonald-szigetek',
            VA: 'Szentszék (Vatikánváros Állam)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Magyarország',
            IS: 'Izland',
            IN: 'India',
            ID: 'Indonézia',
            IR: 'Irán',
            IQ: 'Irak',
            IE: 'Írország',
            IM: 'Man-sziget',
            IL: 'Izrael',
            IT: 'Olaszország',
            JM: 'Jamaica',
            JP: 'Japán',
            JE: 'Jersey',
            JO: 'Jordánia',
            KZ: 'Kazahsztán',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuvait',
            KG: 'Kirgizisztán',
            LA: 'Laoszi Népi Demokratikus Köztársaság',
            LV: 'Lettország',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Libéria',
            LY: 'Líbiai Arab Dzsamahiriya',
            LI: 'Liechtenstein',
            LT: 'Litvánia',
            LU: 'Luxemburg',
            MO: 'Makaó',
            MK: 'Macedónia',
            MG: 'Madagaszkár',
            MW: 'Malawi',
            MY: 'Malajzia',
            MV: 'Maldív-szigetek',
            ML: 'Mali',
            MT: 'Málta',
            MH: 'Marshall-szigetek',
            MQ: 'Martinique',
            MR: 'Mauritánia',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexikó',
            FM: 'Mikronézia',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongólia',
            ME: 'Montenegró',
            MS: 'Montserrat',
            MA: 'Marokkó',
            MZ: 'Mozambik',
            MM: 'Mianmar',
            NA: 'Namíbia',
            NR: 'Nauru',
            NP: 'Nepál',
            NL: 'Hollandia',
            AN: 'Holland Antillák',
            NC: 'Új-Kaledónia',
            NZ: 'Új-Zéland',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigéria',
            NU: 'Niue',
            NF: 'Norfolk-sziget',
            MP: 'Északi-Mariana-szigetek',
            NO: 'Norvégia',
            OM: 'Omán',
            PK: 'Pakisztán',
            PW: 'Palau',
            PS: 'Palesztin terület, megszállva',
            PA: 'Panama',
            PG: 'Pápua Új-Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Fülöp-szigetek',
            PN: 'Pitcairn',
            PL: 'Lengyelország',
            PT: 'Portugália',
            PR: 'Puerto Rico',
            QA: 'Katar',
            RE: 'Reunion',
            RO: 'Románia',
            RU: 'Oroszország',
            RW: 'Ruanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts és Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre és Miquelon',
            VC: 'Saint Vincent és Grenadine-szigetek',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tomé és Príncipe',
            SA: 'Szaúd-Arábia',
            SN: 'Szenegál',
            RS: 'Szerbia',
            SC: 'Seychelle-szigetek',
            SL: 'Sierra Leone',
            SG: 'Szingapúr',
            SK: 'Szlovákia',
            SI: 'Szlovénia',
            SB: 'Salamon-szigetek',
            SO: 'Szomália',
            ZA: 'Dél-Afrika',
            GS: 'Dél-Georgia és a Szendvics-szigetek',
            ES: 'Spanyolország',
            LK: 'Sri Lanka',
            SD: 'Szudán',
            SR: 'Suriname',
            SJ: 'Svalbard és Jan Mayen',
            SZ: 'Szváziföld',
            SE: 'Svédország',
            CH: 'Svájc',
            SY: 'Szíriai Arab Köztársaság',
            TW: 'Tajvan',
            TJ: 'Tádzsikisztán',
            TZ: 'Tanzánia',
            TH: 'Thaiföld',
            TL: 'Kelet-Timor',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad és Tobago',
            TN: 'Tunézia',
            TR: 'Törökország',
            TM: 'Türkmenisztán',
            TC: 'Turks- és Caicos-szigetek',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukrajna',
            AE: 'Egyesült Arab Emírségek',
            GB: 'Egyesült Királyság',
            US: 'Egyesült Államok',
            UM: 'Egyesült Államok külső szigetei',
            UY: 'Uruguay',
            UZ: 'Üzbegisztán',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Brit Virgin-szigetek',
            VI: 'Virgin-szigetek, Egyesült Államok',
            WF: 'Wallis és Futuna',
            EH: 'Nyugat-Szahara',
            YE: 'Jemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    ro: {
        _name: 'Romanian',
        newDesign: {
            withdrawalRequests: 'Nu există cerere de retragere',
            withdrawalRequestsText1: 'Nu ați făcut nicio cerere de retragere până acum',
            withdrawalRequestsText2: 'Odată ce inițiați o retragere, detaliile tranzacției vor apărea aici',
            pdfAttachment: 'Atașament PDF',
            agent: "Agent",
            closed: 'Închis',
            pending: 'În curs',
            approved: 'Aprobat',
            declined: 'Refuzat',
            document: 'Document',
            frontSide: 'Partea frontală',
            backSide: 'Partea posterioară',
            address: 'Adresă',
            frontSideDocument: 'Partea frontală a documentului',
            creditCard: 'Cardul de credit',
            selfie: 'Selfie',
            other: 'Altul',
            noDocument: 'Nu există încă documente încărcate',
            amountDeposited: 'Suma depusă',
            makeTransfer: 'Efectuați transferul',
            otherDetails: 'Detalii de comandă',
            manualWallets: 'Portofele cripto',
            depositText: 'Depozitul este procesat în 5-10 minute',
            pendingBankTransfer: 'Aveți un depozit prin transfer bancar în așteptare.',
            completeDeposit: 'Vă rugăm să urmați instrucțiunile de mai jos pentru a finaliza depunerea dvs.',
            downloadInvoice: 'Descărcați factura',
            payInvoice: 'Plătiți factura în banca dvs.',
            useYourBankText1: 'Utilizați sistemul de plată online sau offline al băncii dvs. pentru a plăti factura.',
            useYourBankText2: 'Asigurați-vă că datele de plată sunt corecte.',
            uploadReceipt: 'Încărcați chitanța de confirmare',
            afterMakingPayment: 'După efectuarea plății, încărcați chitanța pentru a confirma depozitul',
            clickToUpload: 'Faceți clic pentru a încărca',
            orDrag: 'sau glisați și fixați',
            fileFormat: 'PDF, JPG, JPEG, PNG mai puțin de 10MB',
            waitForDeposit: 'Așteptați confirmarea depozitului',
            onceReceipt: 'Odată ce chitanța este încărcată, vom verifica și aproba depozitul dvs.',
            paymentDetails: 'Detalii de plată',
            nameOnCard: 'Numele pe card',
            cardNumber: 'Numărul cardului',
            expirationDate: 'Data expirării',
            securityCode: 'Codul de securitate',
            continue: 'Continuați',
            viewAccounts: 'Vizualizați conturile',
            continueWithdrawal: 'Continuarea retragerii',
            accountBalance: 'Soldul contului',
            accountBalanceText1: 'De ce nu pot plasa o tranzacționare?',
            accountsBalanceText2: 'Cum pot depune fonduri?',
            accountsBalanceText3: 'Vreau să depun bani prin Zelle',
            tradingActions: 'Acțiuni comerciale',
            tradingActionsText1: 'Cum pot vinde în lipsă?',
            tradingActionsText2: 'Cum pot anula o tranzacție?',
            tradingActionsText3: 'Care este valoarea minimă a tranzacției?',
            marketInfo: 'Informații de piață',
            marketInfoText1: 'Unde pot vedea tendințele pieței?',
            marketInfoText2: 'Care sunt câștigătorii de top de astăzi?',
            securityAndSettings: 'Securitate și setări',
            securityAndSettingsText1: 'Cum îmi schimb parola?',
            securityAndSettingsText2: 'Care sunt caracteristicile de securitate?',
            securityAndSettingsText3: 'Cum îmi actualizez informațiile despre contul meu?',
            messageCopied: 'Mesaj copiat în clipboard',
            addSymbol: 'Adăugați simbolul',
            iHaveRead: 'Am citit și sunt de acord cu termenii și condițiile',
            toggleToTurn: 'Comutator pentru activarea AutoTrade',
            selectTheRisk: 'Selectați nivelul de risc',
            confirm: 'Confirmați',
            cancel: 'Anulează',
            chooseClosingTime: 'Alegeți o oră de închidere',
            setOrderClosingTime: 'Setați ora de închidere a comenzii',
            closingTime: 'Ora închiderii',
            date: 'Data',
            time: 'Timp',
            setCloseTime: 'Setați ora de închidere',
            '15m': '15 Minute',
            '30m': '30 Minute',
            '1h': '1 oră',
            custom: 'Custom',
            verifyOtp: 'Verificarea OTP-ului dvs.',
            enterDigits: 'Introduceți codul din 6 cifre trimis la [e-mail/telefon]',
            submit: 'Trimite',
            order: 'Comandă',
            closedSuccessfully: 'a fost închisă cu succes',
            openSuccessfully: 'deschis cu succes',
            deposit: 'depozit',
            withdrawal: 'retragere',
            completeVerification: 'Trebuie să finalizați verificarea',
            verification: 'Verificare',
            avatar: 'Avatar',
            saveChanges: 'Salvați modificările',
            darkMode: 'Mod întunecat',
            dashboardCurrency: 'Tabloul de bord',
            language: 'Limba',
            twoFactor: 'Protecție 2-factor',
            googleTwoFactorIsActive: 'Autentificatorul Google 2-factor este activ',
            activateCodeForGoogle: 'Activează codul pentru Google Authenticator',
            ifYouWishDisableEnterCode: 'Dacă doriți să dezactivați protecția, introduceți codul aplicației de autentificare',
            scanOrManuallyEnter: 'Scanați sau introduceți manual codul QR în Google 2FA pentru a adăuga un token',
            copyCode: 'Copiați codul',
            qrCode: 'Codul QR',
            activateAndEnterAuthenticator: 'Activați Authenticator și introduceți un cod generat în câmpul de mai jos',
            enterTheDigitsCodeGenerated: 'Introduceți codul de 6 cifre generat',
            disableTwoFactor: 'Dezactivați protecția cu doi factori',
            activateTwoFactor: 'Activați protecția 2FA',
            fileAdded: 'Fișiere adăugate cu succes',
            theVerificationStatus: 'Stadiul verificării poate fi vizualizat în lista documentelor încărcate',
            confirmWithdrawal: 'Confirmă retragerea',
            minimumWithdrawal: 'Suma minimă de retragere este de',
            welcomeToTradingPlatform: 'Bine ați venit la platforma de tranzacționare',
            signIn: 'Înregistrează-te',
            activeAccount: 'Cont activ',
            save: 'Salvați',
            selectCurrencyAndPeriod: 'Selectați o monedă și o perioadă de abonament',
            savingsNotFound: 'Conturi de economii nu au fost găsite',
            pleaseCheckInvestment: 'Vă rugăm să verificați detaliile investiției dvs. pentru a continua',
            allPeriodInvestment: 'Toate perioadele de investiții',
            pct_0: 'Flexibil',
            pct_30: '1 trimestru',
            pct_60: '1 Quarter',
            pct_90: '6 luni',
            pct_180: '6 luni',
            pct_365: '1 an',
            savingsCreated: 'Cont de economii creat cu succes',
            transferDetails: 'Transfer Detalii',
            transferAmount: 'Transfer Suma',
            investmentDetails: 'Detalii privind investițiile',
            investment: 'Investiții',
            Period: 'Perioada',
            created: 'Creat',
            releaseTime: 'Timp de eliberare',
            viewSavingsAccounts: 'Vezi conturile de economii',
            continueInvestment: 'Continuă investiția',
            chooseSubscription: 'Alegeți abonamentul dvs.',
            selectCurrencyAndSubscription: 'Selectați o monedă și o perioadă de abonament pentru a continua.',
            rate: 'Rata',
            accountTransferFunds: 'Cont din care să transferați fonduri',
            amount: 'Suma',
            'in': 'în',
            openSavingsAndInvest: 'Deschideți un cont de economii și investiți',
            strongSell: 'Vânzare solidă',
            sell: 'Vânzare',
            neutral: 'Neutru',
            buy: 'Cumpărați',
            stringBuy: 'Cumpărare solidă',
            chooseCryptoWallet: 'Alegeți un portofel pentru criptovalută',
            chooseMethod: 'Alegeți o metodă',
            maximumDeposit: 'Depozitul maxim este de',
            minimumDeposit: "Depozitul minim este",
            pleaseCompleteAddress: 'Vă rugăm să completați adresa dvs.',
            pleaseCompleteCity: 'Vă rugăm să completați orașul dvs.',
            pleaseCompleteCountry: 'Vă rugăm să completați țara dvs.',
            pleaseCompleteZip: 'Vă rugăm să completați codul poștal',
            amountDeposit: 'Suma depozitului',
            transactionStatus: 'Starea tranzacției',
            paymentMethod: 'Metoda de plată',
            depositTo: 'Depozit pentru',
            depositAddress: 'Adresa depozitului',
            scanTheQr: 'Scanați codul QR sau copiați adresa depozitului',
            wallet: 'Portofel',
            copyDepositAddress: 'Copiați adresa depozitului',
            copy: 'Copiază',
            addressCopied: 'Address copied to clipboard',
            '5min:': '5-10 minute',
            '2-5days': '2-5 zile / 5-10 minute',
            otherMethod: 'Altă metodă',
            profileSettings: 'Setări profil',
            savingsAccounts: 'Conturi de economii',
            subscribe: 'Subscrie',
            toCompleteVerification: 'Pentru a finaliza procesul de verificare a contului dvs., vă rugăm să furnizați copii clare și lizibile ale următoarelor documente',
            amountToWithdrawal: 'Suma de retras',
            cardDetails: 'Detaliile cardului',
            cardHolderName: 'Numele titularului de card',
            withdrawalSuccessful: 'Retragere cu succes',
            selectWithdrawalMethod: 'Selectați metoda de retragere',
            creditDebit: 'Card de credit/debit',
            crypto: 'Crypto',
            cryptoWalletAddress: 'Adresa portofelului Crypro',
            cryptoSubtitle: 'Retrageri cripto în portofelul tău 🚀',
            bankWithdrawal: 'Retragere bancară',
            bankSubtitle: 'Cont bancar 🏦',
            zelleSubtitle: 'Transferuri bancare rapide ⚡🏦',
            tradingResults: 'Rezultate comerciale',
            day: 'Zi',
            month: 'Luna',
            noTradingData: 'Nu sunt disponibile date comerciale',
            startTradingToSee: 'Începeți să tranzacționați pentru a vă vedea rezultatele aici.',
            yourPerformanceMetric: 'Indicatorii dvs. de performanță vor fi afișați odată ce datele sunt disponibile.',
            favorite: 'Favorit',
            all: 'Toate',
            availableFullTime: 'Disponibil 24/7',
            yourProfileAndSettings: 'Profilul și setările dvs.',
            switchToNewDesignMessage: 'Schimbă acum la noul design al platformei noastre!',
            switchToOldDesignMessage: 'Poti încă să folosești designul clasic pentru o perioadă limitată',
            switchToOldDesignButton: 'Schimbă la noul design',
            switchToNewDesignButton: 'Revenire la designul clasic',
            generateInvoice: 'Generează factură',
            downloadPdf: 'Descarcă PDF',
            confirmDeposit: 'Confirmă depunerea',
            howCanIAssist: 'Cum te pot ajuta astăzi?',
            maxFileSize: 'Dimensiunea maximă a fișierului este de 5 MB, formatele acceptate sunt JPEG, PNG sau GIF',
            uploadPhoto: 'Încarcă fotografie',
            featuresDisabled: 'Această funcție este dezactivată',
            welcome: 'Bine ai venit',
            signUp: 'Înregistrează-te',
            registration: 'Înregistrare',
            pleaseConfirmAgreement: 'Te rugăm să confirmi acordul clientului',
            pleaseEnterPassword: 'Te rugăm să introduci o parolă',
            passwordNotSame: 'Parolele nu se potrivesc',
            noActiveOrders: 'Nu există comenzi active',
            itLikeNoHaveActiveOrders: 'Se pare că nu ai comenzi active în acest moment',
            cryptoWallet: 'Portofel crypto',
            bankCard: 'Bancă/Card',
            selected: 'Selectat',
            amountWithdrawal: 'Sumă retrasă',
            withdrawalTo: 'Retragere către',
            governmentIssuedPhoto1: 'Act de identitate emis de guvern (ex.: pașaport, permis de conducere, carte de identitate națională)',
            governmentIssuedPhoto2: 'Permis de ședere (dacă este cazul)',
            governmentIssuedPhoto3: 'Documentul trebuie să fie valabil (nu expirat)',
            governmentIssuedPhoto4: 'Numele complet, data nașterii și fotografia trebuie să fie vizibile.',
            uploadRecent1: 'Încărcați o factură recentă de utilități, un extras de cont bancar sau un contract de închiriere emis în ultimele 3 luni',
            uploadRecent2: 'Asigurați-vă că numele complet și adresa dvs. de reședință actuală sunt clar vizibile.',
            uploadCopy1: 'Încărcați o copie a feței cardului de credit, asigurându-vă că primele șase și ultimele patru cifre sunt vizibile',
            uploadCopy2: 'Vă rugăm să acoperiți cifrele din mijloc pentru siguranță',
            takeAndUpload1: 'Faceți o poză selfie ținând actul de identitate lângă fața dvs. și încărcați-o',
            takeAndUpload2: 'Asigurați-vă că atât fața, cât și actul de identitate sunt clar vizibile',
            otherDocuments: 'Alte documente',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Încărcați o imagine clară și lizibilă a documentului cu numărul de securitate socială (SSN). Asigurați-vă că toate detaliile sunt vizibile și fără obstrucții.',
            frontSideSsnDocument: 'Fața documentului SSN',
            answerSaved: 'Răspunsurile au fost salvate',
            cryptoWalletDetails: 'Detalii portofel crypto',
            bitcoinWalletAddress: 'Adresă portofel Bitcoin',
            recovery: 'Recuperare',
            passwordRecovery: 'Recuperare parolă',
            searchCountry: 'Caută țară',
            questionnaire: 'Chestionar',
            questionnaireText: 'Vă rugăm să completați chestionarul pentru a vă verifica identitatea',
            sendAnswers: 'Trimite răspunsuri',
        },
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Încasare profit & Oprire pierdere',
            takeProfit: 'Încasare profit',
            stopLoss: 'Oprire pierdere',
            updateOrder: 'Actualizați comanda',
        },
        balanceModal: {
            balance: 'Sold',
            credit: 'Credit',
            equity: 'Capital',
            practice: 'EXERSAȚI',
            real: 'REAL',
            account: 'CONT',
            invested: 'Investit',
            profit: 'Profit',
            loss: 'Pierderi',
            margin: 'Marjă',
            marginLevel: 'Nivelul marjei',
            marginFree: 'Marjă liberă'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platformă',
            deposit: 'Depozit'
        },
        sideMenu: {
            marketWatch: 'Monitorizarea pieței',
            activeOrders: 'Comenzi active',
            tradingHistory: 'Istoric de tranzacționare',
            economicCalendar: 'Calendar',
            marketNews: 'Știri despre piață'
        },
        closePositionModal: {
            closeOrder: 'Poziția de închidere',
            areYouSure: 'Sunteți sigur că închideți poziția',
            buy: 'Cumpărați',
            sell: 'Vindeți',
            yes: 'Da',
            no: 'Nu',
        },
        pciDssModal: {
            desc: `Conformitatea cu standardul de securitate a datelor din industria cardurilor de plată (PCI DSS) este obligatorie pentru toate entitățile care stochează, procesează sau transmit datele titularilor de carduri Visa/Master Card/Stripe, inclusiv instituții financiare, comercianți și furnizori de servicii. Acesta este standardul global de securitate pentru toate entitățile care stochează, procesează sau transmit datele titularilor de card și/sau date de autentificare sensibile. PCI DSS stabilește un nivel de protecție de bază pentru consumatori și ajută la reducerea fraudei și a încălcărilor de date în întregul ecosistem de plată.`
        },
        chart: {
            addNewChart: 'Adăugați o nouă diagramă',
        },
        symbolsModal: {
            watchlist: 'Lista de supraveghere',
            asset: 'Activ',
            price: 'Preț',
            changePct: 'Modificare 24h'
        },
        pendingModal: {
            pending: 'În așteptare',
            assetPrice: 'Prețul activului',
            current: 'Curent',
            revert: 'Revenirea la prețul pieței',
            automatically: 'Poziția va fi deschisă automat atunci când prețul atinge acest nivel'
        },
        orderMenu: {
            default: 'implicit',
            volume: 'Volum',
            lots: 'loturi',
            units: 'unități',
            currency: 'monedă',
            contractSize: 'Dimensiunea contractului',
            position: 'Poziția',
            margin: 'Marjă',
            freeMargin: 'Marjă liberă',
            takeProfitStopLoss: 'Încasare profit & Oprire pierdere',
            pending: 'În așteptare',
            market: 'Piață',
            leverage: 'Pârghie',
            spread: 'Marjă',
            notSet: 'Nedefinită',
            at: 'la',
            buy: 'cumpărați',
            sell: 'vindeți',
        },
        footer: {
            supportBanner: 'ÎN FIECARE ZI, 24 DE ORE DIN 24',
            currentTime: 'ORA CURENTĂ',
            liveChat: 'Live Chat'
        },
        statusBar: {
            balance: 'Sold',
            credit: 'Credit',
            equity: 'Capital',
            margin: 'Marjă',
            marginLevel: 'Nivelul marjei',
            freeMargin: 'Marjă liberă',
            pnl: 'PnL',
            profitTotal: 'PnL pe întreaga perioadă'
        },
        accountModal: {
            dateRegistered: 'Dată înregistrare',
            userId: 'ID-ul utilizatorului',
            dashboard: 'Tabloul de bord',
            personalData: 'Date cu caracter personal',
            deposit: 'Depozit',
            withdrawFunds: 'Retrageți fonduri',
            savings: 'Economii',
            settings: 'Setări',
            logout: 'Ieșire'
        },
        depositModal: {
            BT_INVOICE: 'Introduceți suma și generați factura',
            BT_TITLE: 'Datele noastre bancare',
            account: 'Cont',
            bank_name: 'Numele băncii',
            beneficiary: 'Beneficiar',
            beneficiary_address: 'Adresa beneficiarului',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Suma',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Descriere',
            TOKEN_PAYMENT_DETAILS: 'Detalii de plată',
            PENDING_DEPOSITS_LIMIT: 'Limita depozitului',
            makeDeposit: 'Efectuați un depozit',
            practiceAccount: 'Contul de practică',
            realAccount: 'Cont real',
            noWithdrawals: 'Fără retrageri',
            easyWithdrawals: 'Retrageri ușoare',
            allAssets: 'Toate activele disponibile',
            fullFledged: 'Platformă cu drepturi depline',
            fillUpTo: 'Alimentați până la',
            freeReplenishment: 'Reaprovizionare liberă',
            topUp: 'Reîncărcați contul dumneavoastră',
            minimumAmount: 'Suma minimă',
            canSwitch: 'Puteți trece de la un cont la altul în orice moment'
        },
        gridsModal: {
            chartGrids: 'GRILE GRAFIC',
            chart1: '1 diagramă',
            chart2: '2 diagrame',
            chart3: '3 diagrame',
            chart4: '4 diagrame',
        },
        noConnectionModal: {
            connectionLost: 'Conexiunea cu serverul este pierdută',
            retryNow: 'Reîncercați acum',
        },
        loginModal: {
            loginToTradeRoom: 'Conectați-vă la Traderoom',
            email: 'Adresa de e-mail',
            enterEmail: 'Introduceți e-mailul dumneavoastră',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: 'Codul 2FA (dacă este activat)',
            twoFactorAuth: 'Autentificare cu doi factori',
            password: 'Parola',
            yourPassword: 'Parola dumneavoastră',
            signIn: 'Conectați-vă',
            dontHaveAccount: 'Nu aveți un cont?',
            restore: 'Restaurați',
            fullName: 'Nume complet',
            havePromo: 'Am un cod promoțional',
            promo: 'Cod promoțional',
            login: 'Autentificare',
            haveAccount: 'Aveți deja un cont?',
            hide: 'Ascundeți',
            goTo: 'Mergeți înapoi'
        },
        newPasswordModal: {
            setNewPassword: 'Setați o nouă parolă',
            newPassword: 'Parolă nouă',
            confirmNewPassword: 'Confirmați noua parolă',
            setButton: 'Setați'
        },
        registerModal: {
            repeatPassword: 'Repetați parola',
            createNewAccount: 'Creați un cont nou',
            email: 'Adresa de e-mail',
            firstName: 'Prenume',
            lastName: 'Nume',
            country: 'Țara',
            phone: 'Telefon',
            password: 'Parola',
            createAccount: 'Creați cont',
            currency: 'Monedă',
            privacyAndPolicy: "Confidențialitate și Politică",
            customerAgreement: 'Acord cu clientul',

        },
        forgotPasswordModal: {
            forgotPassword: 'Ați uitat parola?',
            submitEmail: 'Vă rugăm să trimiteți un e-mail folosit pentru înregistrare, verificați-vă căsuța de e-mail și urmați instrucțiunile furnizate',
            submit: 'Trimiteți'
        },
        notifications: {
            error: 'Eroare',
            success: 'Succes',
            deposit: 'Depozit',
            withdrawal: 'Retragere',
            depositApproved: 'a fost aprobat',
            depositDeclined: 'Depozitul a fost refuzat',
            withdrawalApproved: 'Retragerea a fost aprobată',
            withdrawalDeclined: 'Retragerea a fost refuzată'
        },
        markets: {
            favorites: 'Lista de urmărire', 
            all: 'Toate simbolurile',
            forex: 'Forex',
            stocks: 'Stocuri',
            commodities: 'Mărfuri',
            indices: 'Indici',
            crypto: 'Cripto',
            metals: 'Metale',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'Cumpărați',
            profitCalculator: 'Calculator de profit',
            sell: 'Vindeți',
            volume: 'Volum',
            entryPrice: 'Preț de intrare',
            takeProfit: 'Încasare profit',
            stopLoss: 'Oprire pierdere',
            maxPosition: 'Poziția maximă',
            calculate: 'Calculați',
            calculationsFor: 'Calcule pentru',
            leverage: 'Pârghie',
            requiredMargin: 'Marja necesară',
            profitFromTP: 'Profit din TP',
            lossFromSL: 'Pierdere din SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Depozitul a avut succes',
            text: 'Depozitul dumneavoastră a fost procesat cu succes!',
        },
        depositFailModal: {
            title: 'Depozitul a eșuat',
            text: 'Depozitul dumneavoastră nu a fost procesat.',
        },
        widgetMarketWatch: {
            marketWatch: 'Monitorizarea pieței',
            search: 'Căutați...',
        },
        widgetOrders: {
            commission: 'Comision',
            volume: 'Volum',
            margin: 'Marjă',
            active: 'Activ',
            pending: 'În așteptare',
            activeOrders: 'Comenzi active',
            portfolio: 'Portofoliu',
            allPositions: 'Toate pozițiile',
            noPositionsLine1: 'Nu aveți deschise',
            noPositionsLine2: 'poziții deocamdată',
            show: 'Afișați',
            more: 'mai mult',
            less: 'mai puțin',
            purchaseTime: 'Ora achiziției',
            closePosition: 'Poziția de închidere',
            priceOpen: 'Preț de achiziție',
            priceSl: 'Preț Stop Loss',
            priceTp: 'Preț Take Profit',
            type: 'Direcția poziției',
            pnl: 'Profit/Pierderi',
            cancelOrder: 'Anulați comanda',
            orderType: 'Tip comandă',
            cancelled: 'Anulată',
            tradingHistory: 'Istoric de tranzacționare',
            noHistoryLine1: 'Nu aveți',
            noHistoryLine2: 'tranzacții încheiate până acum',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendar'
        },
        widgetMarketNews: {
            marketNews: 'Știri despre piață'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Comenzi active',
            ordersHistory: 'Istoricul comenzilor',
            id: 'ID',
            symbol: 'Simbol',
            type: 'Tip',
            volume: 'Volum',
            openPrice: 'Prețul de deschidere',
            openTime: 'Oră de deschidere',
            closePrice: 'Preț de închidere',
            closeTime: 'Oră de închidere',
            sl: 'SL',
            tp: 'TP',
            price: 'Preț',
            pnl: 'PnL',
            actions: 'Acțiuni',
            edit: 'Editați',
            close: 'Închideți',
            commission: 'Comision',
            swap: 'Schimbați',
            swapLong: 'Schimbați lung',
            swapShort: 'Schimbați scurt'
        },
        assetInfoModal: {
            assetInfo: 'Informații despre activ',
            tradingConditions: 'Condiții comerciale',
            information: 'Informații',
            bid: 'Ofertă',
            ask: 'Cereți',
            sessionChange: 'Schimbare sesiune',
            tradeNow: 'Tranzacționați acum',
            opens: 'Se deschide',
            closes: 'Se închide',
            at: 'la',
            open247: 'Deschis 24/7',
            today: 'astăzi',
            tomorrow: 'mâine',
            sunday: 'Duminică',
            monday: 'Luni',
            tuesday: 'Marți',
            wednesday: 'Miercuri',
            thursday: 'Joi',
            friday: 'Vineri',
            saturday: 'Sâmbătă',
            contractSpecification: 'Specificația contractului',
            symbol: 'Simbol',
            name: 'Nume',
            market: 'Piață',
            baseCurrency: 'Moneda de bază',
            digits: 'Cifre',
            lotSize: 'Dimensiunea lotului',
            lotStep: 'Pasul lotului',
            minVolume: 'Volum minim',
            maxVolume: 'Volum maxim',
            leverage: 'Pârghie',
            commission: 'Comision',
            swapLong: 'Schimbați lung',
            swapShort: 'Schimbați scurt',
            schedule: 'Program',
            weekday: 'În timpul săptămânii',
            tradingTime: 'Oră de tranzacționare',
            closed: 'închis',
            sell: 'Vindeți',
            buy: 'Cumpărați',
            low: 'Scăzut',
            high: 'Ridicat',
            oneHourChange: 'Schimbare 1 oră',
            oneDayChange: 'Schimbare 1 zi',
            oneWeekChange: 'Schimbare 1 săptămână',
            oneMonthChange: 'Schimbare 1 lună',
            threeMonthsChange: 'Schimbare la 3 luni',
            oneYearChange: 'Schimbare 1 an',
            loading: 'Se încarcă...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'Tranzacții pe zi:',
            desc: `Tranzacționarea CFD-urilor și a altor produse cu efect de levier poate duce la pierderi. Înainte de a tranzacționa, clienții ar trebui să citească declarațiile de risc relevante de pe pagina noastră de divulgare a riscurilor. Tranzacționarea automată nu garantează rezultate. Compania nu își asumă nicio responsabilitate pentru pierderea fondurilor în tranzacționarea automată. Asigurați-vă că înțelegeți pe deplin riscurile și luați măsuri pentru a le gestiona.`,
            accept: 'Accept',
            save: 'Salvează',
            cancel: 'Anulează',
        },
        cardBox: {
            name: 'Nume',
            cardNumber: 'Număr card',
            expiration: 'Expirare (ll/aa)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Tranzacționați",
            startTrading: 'Începeți să tranzacționați',
            deposit: 'Depozit',
            dashboard: 'Tabloul de bord',
            personalInfo: 'Informații personale',
            contactInfo: 'Informații de contact',
            withdrawal: 'Retragere',
            verification: 'Verificare',
            accounts: 'Conturi',
            liveChat: 'Live Chat',
            savings: 'Economii',
            referrals: 'Program de recomandări',
            settings: 'Setări',
            logOut: 'Ieșire',
        },
        fullScreenDeposit: {
            methods: 'METODE',
            sendOtp: 'Trimiteți otp',
            otpCode: 'Cod Otp',
            addWalletAddress: 'Portofelul pentru această metodă de depunere nu este setat. Vă rugăm să contactați agentul dumneavoastră sau echipa de asistență.',
            addressReceipt: 'Vă rugăm să așteptați ca adresa să se încarce',
            makeDeposit: 'Efectuați un depozit',
            instant: 'Instant',
            minutes: 'minute',
            hours: 'ore',
            days: 'zile',
            amount: 'Suma',
            continue: 'Continuați',
            qrCode: 'COD QR',
            depositAddress: 'ADRESA DE DEPOZIT',
            copy: 'Copiați',
            last: 'ULTIMUL',
            deposits: 'DEPOZITE',
            time: 'Oră',
            currency: 'monedă',
            status: 'Stare',
            info: 'Informații',
        },
        fullScreenCcExt: {
            pleaseWait: 'Vă rugăm așteptați. Se conectează la furnizorul de servicii de plăți...',
            cardPayment: 'Plată cu cardul',
            inOrder: 'Pentru a trimite suma',
            pleaseProvide: 'vă rugăm să furnizați datele cardului:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'Nicio comandă încă',
            totalBalance: 'Sold total',
            totalPnl: 'PNL Total',
            profitableOrders: 'Comenzi profitabile',
            roi: 'ROI',
            activityLog: 'Jurnal de activități',
            loginFromIp: 'Autentificare de la IP',
            tradingResults: 'Rezultate de tranzacționare',
            week: 'Săptămâna',
            month: 'Luna',
            year: 'Anul',
            successRate: 'Rata de succes',
            closedWithProfit: 'Închis cu profit',
            closedWithLoss: 'Închis cu pierdere',
            account: 'CONT',
            balance: 'Sold',
            leverage: 'Pârghie',
            credit: 'Credit',
            tradeNow: 'Tranzacționați acum',
            usingCurrentRate: 'utilizând cursul de schimb actual',
            totalDeposits: 'Total depozite',
            totalWithdrawals: "Retrageri totale",
            returnOnInvestment: "Rentabilitatea investiției"
        },
        fullScreenPersonal: {
            personalDetails: 'Date personale',
            profilePhoto: 'Fotografie de profil',
            firstName: 'Prenume',
            country: 'Țara',
            lastName: 'Nume',
            address: 'Adresa',
            email: 'E-mail',
            phone: 'Număr de telefon',
            saveChanges: 'Salvați modificările',
            state: 'Oraș',
            postcode: 'Cod poștal',
            created: "Cont creat la",
        },
        fullScreenAvatar: {
            dropFile: 'Poziționați un fișier pe cercul de mai sus pentru a-l încărca',
            notAllowed: 'Nu este permisă publicarea',
            notAllowedLine1: 'Fotografii cu caracter explicit sexual sau pornografic',
            notAllowedLine2: 'Imagini care au ca scop incitarea la ură sau agresiune etnică sau rasială',
            notAllowedLine3: 'Fotografii în care sunt implicate persoane cu vârsta sub 18 ani',
            notAllowedLine4: 'Fotografii protejate de drepturi de autor ale unor terțe părți',
            notAllowedLine5: 'Imagini mai mari de 5 MB și într-un alt format decât JPG, GIF sau PNG',
            requirements: 'Fața dumneavoastră trebuie să fie clar vizibilă în fotografie. Toate fotografiile și videoclipurile încărcate de dumneavoastră trebuie să respecte aceste cerințe, în caz contrar acestea pot fi eliminate.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Vă rugăm să contactați managerul dumneavoastră de cont pentru detalii de retragere',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Retragere',
            requestNewWithdrawal: 'SOLICITAȚI O NOUĂ RETRAGERE',
            amount: 'Suma',
            currentBalance: 'Soldul contului dumneavoastră curent este',
            withdrawAll: 'Retrageți tot',
            requestWithdrawal: 'Cerere de retragere',
            last: 'ULTIMUL',
            withdrawalRequests: 'CERERI DE RETRAGERE',
            time: 'Oră',
            currency: 'monedă',
            info: 'Informații',
            status: 'Stare',
            bank: 'Banca',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Card',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Detalii de retragere',
            address: 'Adresa portofelului',
            bankDetails: 'Detalii bancare',
            bankAccount: 'Număr de cont',
            bankHolder: 'Numele titularului contului',
            bankIban: 'IBAN',
            bankSwift: 'Cod SWIFT bancar',
            recipientAddress: 'Adresa destinatarului',
            routingNumber: 'Număr de rutare bancară',
            bankBeneficiary: 'Numele băncii beneficiarului',
            bankAddress: 'Adresa băncii',
            bankCode: 'Cod bancar (BSB)',
            desc: 'Descriere',
            cardNumber: 'Numărul cardului',
            cardHolder: 'Numele titularului de card',
        },
        fullScreenVerification: {
            dragAndDrop: 'trageți și plasați documentul în această zonă',
            documentsList: 'LISTA DOCUMENTELOR ÎNCĂRCATE',
            document: 'Document',
            timeUploaded: 'Ora de încărcare',
            timeProcessed: 'Oră de procesare',
            status: 'Stare',
            types: ['Dovada de identitate', 'Dovada de reședință', 'Partea din față a cardului de credit', 'Partea din spate a cardului de credit', 'Partea din spate a dovezii de identitate', 'Selfie', 'Other'],
            types2: ['Dovada de identitate (POI)', 'Dovada adresei de reședință', 'Partea din față a cardului de credit', 'Partea din spate a cardului de credit', 'Partea din spate a dovezii de identitate', 'Selfie cu dovada identității'],
            upload: 'Încărcați',
            isConfirmed: 'este confirmat',
            uploadAnotherDocument: 'Încărcați un alt document',
            isUnderReview: 'este în curs de examinare',
            questionnaire: 'Chestionar',
            sendAnswers: 'Trimiteți răspunsuri',
            progressBarOf: 'din',
            progressBarText: 'din încărcarea documentelor dumneavoastră au fost încărcate și confirmate'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transferați fonduri între conturile dumneavoastră',
            transferFrom: 'Din contul',
            transferTo: 'În contul',
            amount: 'Suma',
            makeTransfer: 'Efectuați transferul',
            yourAccounts: 'Conturile dumneavoastră',
            account: 'CONT',
            currency: 'monedă',
            balance: 'Sold',
            credit: 'Credit',
            makeActive: 'Activați',
            active: 'Activ'
        },
        fullScreenSupport: {
            sendMessage: 'Trimiteți mesajul'
        },
        fullScreenSavings: {
            pct_0: 'Flexibil',
            pct_30: 'Blocat 1 lună',
            pct_90: 'Blocat 1 trimestru',
            pct_180: 'Blocat 6 luni',
            pct_365: 'Blocat 1 an',
            savings: 'Economii',
            detailedInformation: 'Informații detaliate',
            pleaseSelect: 'Vă rugăm să selectați o monedă și o perioadă',
            openSavings: 'Deschideți economii',
            currentTime: 'ORA CURENTĂ',
            releaseTime: 'Oră eliberare',
            currencyChosen: 'Moneda aleasă',
            periodChosen: 'Perioada aleasă',
            yourRate: 'Tariful dumneavoastră de schimb',
            yourEarnings: 'Câștigurile dumneavoastră',
            accountFrom: 'Cont pentru a transfera fonduri din',
            enterAmount: 'Introduceți suma',
            in: 'în',
            openAndInvest: 'Deschideți un cont de economii și investiți',
            investment: 'Investiție',
            period: 'Perioadă',
            daysTotal: 'Total zile',
            finalEarnings: 'Câștiguri finale',
            created: 'Creat',
            daysElapsed: 'Zilele scurse',
            days: 'zile',
            earnings: 'Câștiguri',
            fundsReleasedIn: 'Fonduri deblocate în',
            claim: 'Revendicați',
            name: "Nume",
        },
        fullScreenSettings: {
            updatePassword: 'ACTUALIZAȚI PAROLA',
            currentPassword: 'Parola curentă',
            newPassword: 'Parolă nouă',
            changePassword: 'Schimbați parola',
            activationForGoogle: 'Cod de activare pentru Google Authenticator',
            activateAndEnter: 'Activați Authenticator și introduceți un cod generat în câmpul de mai jos',
            qrCode: 'COD QR',
            activateProtection: 'Activați protecția autentificări în doi pași 2FA',
            protectionActive: 'Protecția 2FA este activă',
            twoFactorCode: 'Cod 2FA',
            disableProtection: 'Dezactivați protecția 2FA',
            language: 'Limba',
            dashboardCurrency: 'Monedă tablou de bord',
            confirmNewPassword: 'Confirmați noua parolă',
        },
        fullScreenReferrals: {
            yourLink: 'LINK-UL DUMNEAVOASTRĂ DE REFERIRE',
            referredFriends: 'PRIETENI RECOMANDAȚI',
            id: 'ID',
            name: 'Nume',
            registered: 'Înregistrare',
            level: 'Nivel',
            payout: 'Plată',
            lastPayouts: 'ULTIMELE PLĂȚI DE RECOMANDARE',
            time: 'Oră',
            amount: 'Suma',
            currency: 'monedă',
            info: 'Informații',
            referralDesc:"Programul de recomandare este conceput pentru a vă recompensa pentru extinderea comunității noastre de investitori valoroși.",
            shareLink:"Distribuie linkul:",
            subtitle:"Oferă-i numele, e-mailul sau ID-ul tău de tranzacționare.",
            descSuccesfull:"Odată ce recomandarea ta se înregistrează cu succes, consilierul tău financiar îl va contacta pentru a-l ajuta în primii pași pe drumul investițional într-un mod eficient și profesionist.",
            descTracked:"Toate contribuțiile lui sunt urmărite sub recomandarea ta."
        },
        months: {
            Jan: 'Ian',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Mai',
            Jun: 'Iun',
            Jul: 'Iul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Cumpărați', 'Vindeți', 'Limită cumpărare', 'Limită de vânzare', 'Oprire cumpărare', 'Oprire vânzare'],
        statuses: ['În așteptare', 'Aprobată', 'Refuzată', 'În procesare'],
        errors: {
            NOT_FOUND: 'Eroare de cerere',
            FORBIDDEN: 'Acces refuzat. Vă rugăm să vă autentificați din nou',
            SERVER_ERROR: 'Acțiune eșuată. Vă rugăm să încercați din nou mai târziu',
            INVALID_ACCOUNT: 'Sesiune expirată. Vă rugăm să vă autentificați din nou',
            INVALID_EMAIL: 'Adresa de e-mail invalidă',
            INVALID_PHONE: 'Număr de telefon invalid',
            INVALID_COUNTRY: 'Țară invalidă',
            INVALID_LOGIN: 'Date de autentificare invalide',
            USER_BLOCKED: 'Contul este blocat. Vă rugăm să contactați serviciul de asistență',
            USER_REGISTERED: 'Utilizatorul cu adresa de e-mail sau numărul de telefon introdus este deja înregistrat',
            INVALID_USER: 'Utilizatorul nu a fost găsit',
            REJECTED: 'Funcționarea nu este permisă din cauza restricțiilor platformei',
            INVALID_OLD_PASSWORD: 'Parola veche este invalidă',
            INVALID_SYMBOL: 'Simbol de tranzacționare invalid',
            INVALID_SYMBOL_PRECISION: 'Precizie invalidă a simbolului',
            INVALID_FRAME: 'Cadru grafic invalid',
            INVALID_CURRENCY: 'Monedă invalidă',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Tip de cont de tranzacționare invalid',
            INSUFFICIENT_FUNDS: 'Fonduri insuficiente',
            INVALID_WITHDRAWAL: 'Retragerea nu a fost găsită',
            INVALID_STATUS: 'Stare invalidă',
            INVALID_FILE: 'Extensie de fișier invalidă sau fișierul este prea mare',
            INVALID_DOCUMENT: 'Documentul nu a fost găsit',
            INVALID_NAME: 'Nume invalid',
            INVALID_LOT_SIZE: 'Dimensiunea invalidă a lotului',
            INVALID_LOT_STEP: 'Pas de lot nevalabil',
            INVALID_MIN_VOLUME: 'Volum minim invalid',
            INVALID_MAX_VOLUME: 'Volum maxim invalid',
            INVALID_GROUP: 'Grup invalid',
            INVALID_SPREAD: 'Marjă invalidă',
            REQUEST_TIMEOUT: 'Cererea a expirat. Vă rugăm să încercați din nou mai târziu',
            EXISTING_OPEN_ORDERS: 'Aveți o comandă deschisă, închideți mai întâi comanda pentru acest utilizator și încercați din nou.',
            INVALID_TYPE: 'Tip de comandă invalid',
            INVALID_VOLUME: 'Volum de comandă invalid',
            INVALID_SL: 'Valoare invalidă a stop loss',
            INVALID_TP: 'Valoare nevalabilă a take profit',
            INVALID_PRICE: 'Preț de comandă invalid',
            INVALID_EXPIRATION: 'Expirarea comenzii invalidă',
            NOT_ENOUGH_MARGIN: 'Marjă insuficientă',
            INVALID_ORDER: 'Comanda nu a fost găsită',
            MARKET_CLOSED: 'Tranzacționarea nu este disponibilă. Vă rugăm să încercați din nou',
            INVALID_BALANCE_TYPE: 'Tip nevalabil de operațiune sold',
            INVALID_HASH: 'Hash invalid',
            HASH_EXPIRED: 'Linkul de resetare a parolei dumneavoastră a expirat. Vă rugăm să solicitați una din nou',
            INVALID_CODE: 'Cod 2FA invalid',
            CHAT_DISABLED: 'Accesul la chat-ul de asistență a fost închis',
            WITHDRAWAL_NOT_ALLOWED: 'Accesul la retrageri a fost închis',
            TRADING_DISABLED: 'Tranzacționarea este dezactivată pentru contul curent',
            PENDING_DEPOSITS_LIMIT: 'Limita depozitelor a fost depășită',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Este permisă doar o singură retragere în așteptare',
            LOCAL_DEPOSIT_ONLY_REAL: 'Depozitul este disponibil doar pentru contul real',
            LOCAL_DEPOSIT_POSITIVE: 'Valoarea depozitului trebuie să fie mai mare de 0',
            LOCAL_INVALID_CARD_NUMBER: 'Număr de card invalid',
            LOCAL_INVALID_CARD_EXPIRATION: 'Expirare invalidă a cardului',
            LOCAL_INVALID_CVC: 'Cod CVV/CVC invalid',
            LOCAL_PASSWORDS_NOT_MATCH: 'Parolele nu se potrivesc, vă rugăm să le introduceți din nou',
            LOCAL_NO_TRADING_ACCOUNTS: 'Nu aveți conturi de tranzacționare disponibile. Vă rugăm să contactați serviciul de asistență',
            LOCAL_MIN_DEPOSIT: 'Depozitul minim este $',
            LOCAL_MIN_WITHDRAWAL: 'Suma minimă de retragere este de $',
            LOCAL_INVALID_FILE_TYPE: 'Formatul de fișier nu este acceptat. Vă rugăm să atașați doar JPG, PNG sau PDF',
            LOCAL_MIN_STAKING: 'Depozitul minim pentru această monedă este ',
            FLOOD_REJECTED: 'Trimiteți mesaje prea des. Vă rugăm să încercați din nou mai târziu'
        },
        countries: {
            AF: 'Afganistan',
            AX: 'Insulele Aland',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'Samoa Americană',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua și Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgia',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Butan',
            BO: 'Bolivia',
            BA: 'Bosnia și Herțegovina',
            BW: 'Botswana',
            BV: 'Insula Bouvet',
            BR: 'Brazilia',
            IO: 'Teritoriul britanic din Oceanul Indian',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodgia',
            CM: 'Camerun',
            CA: 'Canada',
            CV: 'Capul Verde',
            KY: 'Insulele Cayman',
            CF: 'Republica Centrafricană',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Insula Christmas',
            CC: 'Insulele Cocos (Keeling)',
            CO: 'Colombia',
            KM: 'Comore',
            CG: 'Congo',
            CD: 'Congo, Republica Democrată',
            CK: 'Insulele Cook',
            CR: 'Costa Rica',
            CI: 'Coasta de Fildeș',
            HR: 'Croația',
            CU: 'Cuba',
            CY: 'Cipru',
            CZ: 'Republica Cehă',
            DK: 'Danemarca',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Republica Dominicană',
            EC: 'Ecuador',
            EG: 'Egipt',
            SV: 'El Salvador',
            GQ: 'Guineea Ecuatorială',
            ER: 'Eritreea',
            EE: 'Estonia',
            ET: 'Etiopia',
            FK: 'Insulele Falkland (Malvine)',
            FO: 'Insulele Feroe',
            FJ: 'Fiji',
            FI: 'Finlanda',
            FR: 'Franța',
            GF: 'Guiana Franceză',
            PF: 'Polinezia Franceză',
            TF: 'Teritoriile australe franceze',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Germania',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Grecia',
            GL: 'Groenlanda',
            GD: 'Grenada',
            GP: 'Guadelupa',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guineea',
            GW: 'Guinee-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Insula Heard și Insulele Mcdonald',
            VA: 'Sfântul Scaun (Statul Vatican)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Ungaria',
            IS: 'Islanda',
            IN: 'India',
            ID: 'Indonezia',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irlanda',
            IM: 'Insula Man',
            IL: 'Israel',
            IT: 'Italia',
            JM: 'Jamaica',
            JP: 'Japonia',
            JE: 'Jersey',
            JO: 'Iordania',
            KZ: 'Kazahstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Coreea',
            KW: 'Kuweit',
            KG: 'Kârgâzstan',
            LA: 'Republica Democrată Populară Laos',
            LV: 'Letonia',
            LB: 'Liban',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Marea Jamahirie Arabă Socialistă Populară Libiană',
            LI: 'Liechtenstein',
            LT: 'Lituania',
            LU: 'Luxemburg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldive',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Insulele Marshall',
            MQ: 'Martinica',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexic',
            FM: 'Micronezia',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Muntenegru',
            MS: 'Montserrat',
            MA: 'Maroc',
            MZ: 'Mozambic',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Țările de Jos',
            AN: 'Antilele Olandeze',
            NC: 'Noua Caledonie',
            NZ: 'Noua Zeelandă',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Insula Norfolk',
            MP: 'Insulele Mariane de Nord',
            NO: 'Norvegia',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Teritoriul palestinian, ocupat',
            PA: 'Panama',
            PG: 'Papua Noua Guinee',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipine',
            PN: 'Pitcairn',
            PL: 'Polonia',
            PT: 'Portugalia',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'România',
            RU: 'Rusia',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Sfânta Elena',
            KN: 'Sfântul Cristofor și Nevis',
            LC: 'Sfânta Lucia',
            MF: 'Insula Sfântul Martin',
            PM: 'Saint Pierre și Miquelon',
            VC: 'Sfântul Vincențiu și Grenadinele',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome și Principe',
            SA: 'Arabia Saudită',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovacia',
            SI: 'Slovenia',
            SB: 'Insulele Solomon',
            SO: 'Somalia',
            ZA: 'Africa de Sud',
            GS: 'Georgia de Sud și Insulele Sandwich de Sud',
            ES: 'Spania',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard și Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Suedia',
            CH: 'Elveția',
            SY: 'Republica Arabă Siriană',
            TW: 'Taiwan',
            TJ: 'Tajikistan',
            TZ: 'Tanzania',
            TH: 'Thailanda',
            TL: 'Timorul de Est',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad și Tobago',
            TN: 'Tunisia',
            TR: 'Turcia',
            TM: 'Turkmenistan',
            TC: 'Insulele Turks și Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ucraina',
            AE: 'Emiratele Arabe Unite',
            GB: 'Regatul Unit',
            US: 'Statele Unite ale Americii',
            UM: 'Insulele Minore Îndepărtate ale Statelor Unite',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Insulele Virgine, britanice',
            VI: 'Insulele Virgine, S.U.A.',
            WF: 'Wallis și Futuna',
            EH: 'Sahara Occidentală',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    ge: {
        _name: 'Georgia',
        timePickerModal: {
            title: 'Close In Time'
        },
        newDesign: {
            withdrawalRequests: 'არ ფიქსირდება გამოტანის მოთხოვნები',
            withdrawalRequestsText1: 'ჯერ-ჯერობით არ გაგიკეთებიათ გამოტანის მოთხოვნები.',
            withdrawalRequestsText2: 'გამოტანის დაწყებისას, ტრანზაქციის დეტალები გამოჩნდება აქ.',
            pdfAttachment: 'PDF ფაილი',
            agent: "აგენტი",
            closed: 'დახურულია',
            pending: 'მუშავდება',
            approved: 'დამტკიცებულია',
            declined: 'უარყოფილია',
            document: 'დოკუმენტი',
            frontSide: 'წინა მხარე',
            backSide: 'უკანა მხარე',
            address: 'მისამართი',
            frontSideDocument: 'დოკუმენტის წინა მხარე',
            creditCard: 'საკრედიტო ბარათი',
            selfie: 'სელფი',
            other: 'სხვა',
            noDocument: 'დოკუმენტები ჯერ არ არის ატვირთული',
            amountDeposited: 'ჩარიცხული თანხა',
            makeTransfer: 'გადარიცხვა',
            otherDetails: 'შეკვეთის დეტალები',
            manualWallets: 'კრიპტო საფულეები',
            depositText: 'ანაბარი მუშავდება 5-10 წუთის განმავლობაში',
            pendingBankTransfer: 'თქვენი საბანკო ჩარიცხვა დამუშავების პროცესშია.',
            completeDeposit: 'შეასრულეთ ქვემოთ მოცემული ინსტრუქციები და მიიღეთ ჩარიცხვა.',
            downloadInvoice: 'ინვოისის ჩაწერა',
            payInvoice: 'გადაიხადეთ ინვოისი ბანკში',
            useYourBankText1: 'გამოიყენეთ თქვენი ბანკის ონლაინ/ოფლაინ სისტემები ინვოისის გადასახდელად.',
            useYourBankText2: 'დარწმუნდით, რომ გადახდის დეტალები სწორია.',
            uploadReceipt: 'ატვირთეთ დადასტურების ჩეკი',
            afterMakingPayment: 'გადახდის შემდეგ, ატვირთეთ ჩეკი, რათა დაადასტუროთ ჩარიცხვა',
            clickToUpload: 'დააჭირეთ და ატვირთეთ',
            orDrag: 'ან ჩააგდეთ',
            fileFormat: 'PDF, JPG, JPEG, PNG 10MB-ზე ნაკლები',
            waitForDeposit: 'დაელოდეთ ჩარიცხვის დადასტურებას',
            onceReceipt: 'ჩეკის ატვირთვის შემდეგ, ჩვენ დავადასტურებთ თქვენს ჩარიცხვას',
            paymentDetails: 'გადახდის დეტალები',
            nameOnCard: 'სახელი ბარათზე',
            cardNumber: 'ბარათის ნომერი',
            expirationDate: 'მოქმედების ვადა',
            securityCode: 'უსაფრთხოების კოდი',
            continue: 'გაგრძელება',
            viewAccounts: 'ანგარიშების ნახვა',
            continueWithdrawal: 'გამოტანის გაგრძელება',
            accountBalance: 'ანგარიშის ბალანსი',
            accountBalanceText1: 'რატომ არ შემიძლია ვაჭრობა?',
            accountsBalanceText2: 'როგორ უნდა შევიტანო თანხა?',
            accountsBalanceText3: 'მინდა ფულის შეტანა Zelle-ის საშუალებით',
            tradingActions: 'სავაჭრო ქმედებები',
            tradingActionsText1: 'როგორ უნდა შევასრულო მოკლე გაყიდვა?',
            tradingActionsText2: 'როგორ უნდა გავაუქმო სავაჭრო მოქმედება?',
            tradingActionsText3: 'რა არის სავაჭრო ქმედების მინიმალური ოდენობა?',
            marketInfo: 'ბაზრის ინფო',
            marketInfoText1: 'სად შემიძლია ვნახო ბაზრის ტრენდები?',
            marketInfoText2: 'ვინ არიან დღევანდელი ლიდერები ზრდაში?',
            securityAndSettings: 'უსაფრთხოება და პარამეტრები',
            securityAndSettingsText1: 'როგორ შევცვალო პაროლი?',
            securityAndSettingsText2: 'რა უსაფრთხოების ფუნქციებია?',
            securityAndSettingsText3: 'როგორ განვაახლო ჩემი ანგარიშის ინფო?',
            messageCopied: 'მესიჯი კოპირებულია',
            addSymbol: 'სიმბოლოს დამატება',
            iHaveRead: 'მე წავიკითხე და ვეთანხმები მომსახურების პირობებს',
            toggleToTurn: 'დააჭირეთ და ჩართეთ AutoTrade',
            selectTheRisk: 'აირჩიეთ რისკის დონე',
            confirm: 'დადასტურება',
            cancel: 'გაუქმება',
            chooseClosingTime: 'აირჩიეთ დახურვის დრო',
            setOrderClosingTime: 'აირჩიეთ შეკვეთის დახურვის დრო',
            closingTime: 'დახურვის დრო',
            date: 'თარიღი',
            time: 'დრო',
            setCloseTime: 'დააყენეთ დახურვის დრო',
            '15m': '15 წუთი',
            '30m': '30 წუთი',
            '1h': '1 საათი',
            custom: 'სხვა',
            verifyOtp: 'დაადასტურეთ თქვენი OTP',
            enterDigits: 'შეიყვანეთ [ელ-ფოსტაზე/ტელეფონზე] მიღებული 6-ნიშნა კოდი',
            submit: 'დადასტურება',
            order: 'შეკვეთა',
            closedSuccessfully: 'წარმატებით დაიხურა',
            openSuccessfully: 'გაიხსნა წარმატებით',
            deposit: 'შეტანა',
            withdrawal: 'გამოტანა',
            completeVerification: 'ვერიფიკაციის დასრულება',
            verification: 'დადასტურება',
            avatar: 'ავატარი',
            saveChanges: 'ცვლილებების შენახვა',
            darkMode: 'მუქი რეჟიმი',
            dashboardCurrency: 'ნაჩვენები ვალუტა',
            language: 'ენა',
            twoFactor: '2-ფაქტორიანი დაცვა',
            googleTwoFactorIsActive: 'Google-ის 2-ფაქტორიანი დაცვა მოქმედებს',
            activateCodeForGoogle: 'დაადასტურეთ კოდი Google Authenticator-თვის',
            ifYouWishDisableEnterCode: 'თუ გსურთ დაცვის გაუქმება, შეიყვანეთ authenticator-ის კოდი.',
            scanOrManuallyEnter: 'დაასკანერეთ ან შეიყვანეთ QR კოდი Google 2FA-ში ტოკენის დასამატებლად.',
            copyCode: 'კოდი კოპირება',
            qrCode: 'QR კოდი',
            activateAndEnterAuthenticator: 'ჩართეთ Authenticator-ი და შეიყვანეთ კოდი ქვემოთ მოცემულ ველში.',
            enterTheDigitsCodeGenerated: 'შეიყვანეთ 6-ნიშნა კოდი',
            disableTwoFactor: 'ორ-ფაქტორიანი დაცვის გაუქმება',
            activateTwoFactor: 'ორ-ფაქტორიანი დაცვის ჩართვა',
            fileAdded: 'ფაილი წარმატებით დაემატა',
            theVerificationStatus: 'შეგიძლიათ იხილოთ ვერიფიკაციის სტატუსი ატვირთული დოკუმენტების ჩამონათვალში',
            confirmWithdrawal: 'დაადასტურეთ თანხის გატანა',
            minimumWithdrawal: 'გამოტანის მინიმალური ოდენობა არის',
            welcomeToTradingPlatform: 'კეთილი იყოს თქვენი მობრძანება სავაჭრო პლატფორმაზე',
            signIn: 'შესვლა',
            activeAccount: 'აქტიური ანგარიში',
            save: 'შენახვა',
            selectCurrencyAndPeriod: 'აირჩიეთ ვალუტა და გამოწერის პერიოდი',
            savingsNotFound: 'დანაზოგის ანგარიშები ვერ მოიძებნა',
            pleaseCheckInvestment: 'შეამოწმეთ თქვენი ინვესტირების დეტალები, რათა გააგრძელოთ',
            allPeriodInvestment: 'ყველა პერიოდის ინვესტირება',
            pct_0: 'ცვალებადი',
            pct_30: '1 თვე',
            pct_60: '1 კვარტალი',
            pct_90: '6 თვე',
            pct_180: '6 თვე',
            pct_365: '1 წელი',
            savingsCreated: 'დანაზოგის ანგარიში წარმატებით შეიქმნა',
            transferDetails: 'გადარიცხვის დეტალები',
            transferAmount: 'გადარიცხვის ოდენობა',
            investmentDetails: 'ინვესტიციის დეტალები',
            investment: 'ინვესტიცია',
            Period: 'პერიოდი',
            created: 'შეიქმნა',
            releaseTime: 'გაშვების დრო',
            viewSavingsAccounts: 'დანაზოგის ანგარიშების ნახვა',
            continueInvestment: 'ინვესტიციის გაგრძელება',
            chooseSubscription: 'აირჩიეთ გამოწერის ვარიანტი',
            selectCurrencyAndSubscription: 'აირჩიეთ ვალუტა და გამოწერის პერიოდი.',
            rate: 'კურსი',
            accountTransferFunds: 'ანგარიში, საიდანაც მოხდება გადარიცხვა',
            amount: 'რაოდენობა',
            'in': 'ში',
            openSavingsAndInvest: 'დანაზოგის ანგარიშის გახსნა და ინვესტირება',
            strongSell: 'ძლიერი გაყიდვა',
            sell: 'გაყიდვა',
            neutral: 'ნეიტრალური',
            buy: 'ყიდვა',
            stringBuy: 'ძლიერი ყიდვა',
            chooseCryptoWallet: 'აირჩიეთ კრიპტოვალუტის საფულე',
            chooseMethod: 'აირჩიეთ მეთოდი',
            maximumDeposit: 'მაქსიმალური დეპოზიტი არის',
            minimumDeposit: "მინიმალური დეპოზიტია",
            pleaseCompleteAddress: 'გთხოვთ შეავსეთ მისამართი',
            pleaseCompleteCity: 'გთხოვთ შეავსეთ ქალაქი',
            pleaseCompleteCountry: 'გთხოვთ შეავსეთ ქვეყანა',
            pleaseCompleteZip: 'გთხოვთ შეავსეთ ZIP კოდი',
            amountDeposit: 'დეპოზიტის ოდენობა',
            transactionStatus: 'ტრანზაქციის სტატუსი',
            paymentMethod: 'გადახდის მეთოდი',
            depositTo: 'გადარიცხვა აქ',
            depositAddress: 'დეპოზიტის მისამართი',
            scanTheQr: 'დაასკანერეთ QR კოდი ან დააკოპირეთ დეპოზიტის მისამართი',
            wallet: 'საფულე',
            copyDepositAddress: 'დეპოზიტის მისამართის კოპირება',
            copy: 'კოპირება',
            addressCopied: 'მისამართი კოპირებულია',
            '5min:': '5-10 წუთი',
            '2-5days': '2-5 დღე / 5-10 წუთი',
            otherMethod: 'სხვა მეთოდი',
            profileSettings: 'პროფილის პარამეტრები',
            savingsAccounts: 'დანაზოგის ანგარიშები',
            subscribe: 'გამოწერა',
            toCompleteVerification: 'თქვენი ანგარიშის ვერიფიკაციის პროცესის დასასრულებლად, მოგვაწოდეთ შემდეგი დოკუმენტების მაღალი ხარისხის, მარტივად წასაკითხი ასლები',
            amountToWithdrawal: 'გამოსატანი ოდენობა',
            cardDetails: 'ბარათის დეტალები',
            cardHolderName: 'ბარათის მეპატრონეს სახელი',
            withdrawalSuccessful: 'გამოტანა წარმატებით შესრულდა',
            selectWithdrawalMethod: 'აირჩიეთ გამოტანის მეთოდი',
            creditDebit: 'საკრედიტო/სადებეტო ბარათი',
            crypto: 'კრიპტო',
            cryptoWalletAddress: 'კრიპტო საფულეს მისამართი',
            cryptoSubtitle: 'კრიპტო განაღდება თქვენს საფულეში 🚀',
            bankWithdrawal: 'საბანკო განაღდება',
            bankSubtitle: 'საბანკო ანგარიში 🏦',
            zelleSubtitle: 'სწრაფი საბანკო გადარიცხვები ⚡🏦',
            tradingResults: 'ვაჭრობის შედეგები',
            day: 'დღე',
            month: 'თვე',
            noTradingData: 'სავაჭრო მონაცემები არ იძებნება',
            startTradingToSee: 'დაიწყეთ ვაჭრობა და იხილეთ შედეგები აქ.',
            yourPerformanceMetric: 'თქვენი შედეგები გამოისახება აქ, როდესაც მონაცემები ხელმისაწვდომი იქნება.',
            favorite: 'ფავორიტი',
            all: 'ყველა',
            availableFullTime: 'ხელმისაწვდომია 24/7',
            yourProfileAndSettings: 'თქვენი პროფილი და პარამეტრები',
            switchToNewDesignMessage: 'გადადით ჩვენი პლატფორმის ახალ დიზაინზე!',
            switchToOldDesignMessage: 'კვლავ შეგიძლიათ კლასიკური დიზაინის გამოყენება შეზღუდული დროით',
            switchToOldDesignButton: 'ახალ დიზაინზე გადასვლა',
            switchToNewDesignButton: 'კლასიკურ დიზაინზე დაბრუნება',
            generateInvoice: 'ინვოისის შექმნა',
            downloadPdf: 'PDF-ის ჩამოტვირთვა',
            confirmDeposit: 'დეპოზიტის დადასტურება',
            howCanIAssist: 'როგორ შემიძლია დაგეხმაროთ დღეს?',
            maxFileSize: 'ფაილის მაქსიმალური ზომა არის 5MB, მხარდაჭერილი ფორმატებია JPEG, PNG ან GIF',
            uploadPhoto: 'ფოტოს ატვირთვა',
            featuresDisabled: 'ეს ფუნქცია გამორთულია',
            welcome: 'კეთილი იყოს თქვენი მობრძანება',
            signUp: 'რეგისტრაცია',
            registration: 'რეგისტრაცია',
            pleaseConfirmAgreement: 'გთხოვთ, დაადასტურეთ მომხმარებლის შეთანხმება',
            pleaseEnterPassword: 'გთხოვთ, შეიყვანეთ პაროლი',
            passwordNotSame: 'პაროლები არ ემთხვევა',
            noActiveOrders: 'აქტიური შეკვეთები არ არის',
            itLikeNoHaveActiveOrders: 'როგორც ჩანს, ამჟამად არ გაქვთ აქტიური შეკვეთები',
            cryptoWallet: 'კრიპტო საფულე',
            bankCard: 'ბანკი/ბარათი',
            selected: 'არჩეული',
            amountWithdrawal: 'გატანილი თანხა',
            withdrawalTo: 'გატანა',
            governmentIssuedPhoto1: 'მთავრობის მიერ გაცემული ფოტო დოკუმენტი (მაგალითად: პასპორტი, მართვის მოწმობა, ეროვნული პირადობის ბარათი)',
            governmentIssuedPhoto2: 'საცხოვრებელი ნებართვა (თუ ვრცელდება)',
            governmentIssuedPhoto3: 'დოკუმენტი უნდა იყოს მოქმედი (არ ამოწურული)',
            governmentIssuedPhoto4: 'სახელი, დაბადების თარიღი და ფოტო უნდა იყოს ხილული.',
            uploadRecent1: 'ატვირთეთ ბოლო სამი თვის განმავლობაში გაცემული კომუნალური გადასახადი, საბანკო ამონაწერი ან ქირის ხელშეკრულება',
            uploadRecent2: 'დარწმუნდით, რომ თქვენი სრული სახელი და საცხოვრებელი მისამართი ნათლად ჩანს.',
            uploadCopy1: 'ატვირთეთ თქვენი საკრედიტო ბარათის წინა მხარის ასლი, დარწმუნდით, რომ პირველი ექვსი და ბოლო ოთხი ციფრი ჩანს',
            uploadCopy2: 'გთხოვთ, უსაფრთხოებისთვის დაფარეთ შუა ციფრები',
            takeAndUpload1: 'გადაიღეთ სელფი, რომელზეც თქვენი პირადობის მოწმობა თქვენს სახესთან ერთად ჩანს, და ატვირთეთ იგი',
            takeAndUpload2: 'დარწმუნდით, რომ თქვენი სახე და პირადობის მოწმობა ნათლად ჩანს',
            otherDocuments: 'სხვა დოკუმენტები',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'ატვირთეთ თქვენი სოციალური დაცვის ნომრის (SSN) დოკუმენტის მკაფიო და იკითხებადი სურათი. დარწმუნდით, რომ ყველა დეტალი ჩანს და არაფერია დაფარული.',
            frontSideSsnDocument: 'SSN დოკუმენტის წინა მხარე',
            answerSaved: 'პასუხები შენახულია',
            cryptoWalletDetails: 'კრიპტო საფულის დეტალები',
            bitcoinWalletAddress: 'Bitcoin საფულის მისამართი',
            recovery: 'აღდგენა',
            passwordRecovery: 'პაროლის აღდგენა',
            searchCountry: 'ქვეყნის ძიება',
            questionnaire: 'კითხვარი',
            questionnaireText: 'გთხოვთ, შეავსოთ კითხვარი თქვენი პიროვნების დასადასტურებლად',
            sendAnswers: 'პასუხების გაგზავნა',
        },
        autoCloseModal: {
            takeProfitStopLoss: 'სარგებლის მიღება, დანაკარგების გაჩერება',
            takeProfit: 'სარგებლის მიღება',
            stopLoss: 'დანაკარგების გაჩერება',
            updateOrder: 'შეკვეთის განახლება',
        },
        balanceModal: {
            balance: 'ბალანსი',
            credit: 'კრედიტი',
            equity: 'აქცია',
            practice: 'პრაქტიკა',
            real: 'ნამდვილი',
            account: 'ანგარიში',
            invested: 'ინვესტირებული',
            profit: 'მოგება',
            loss: 'წაგება',
            margin: 'შემოსავალი',
            marginLevel: 'შემოსავლის დონე',
            marginFree: 'ხელმისაწვდომი შემოსავალი'
        },
        header: {
            cfd: 'სხვაობათა კონტრაქტი',
            platform: 'პლატფორმა',
            deposit: 'ჩარიცხვა/დეპოზიტი'
        },
        sideMenu: {
            marketWatch: 'ბაზარზე დაკვირვება',
            activeOrders: 'აქტიური შეკვეთები',
            tradingHistory: 'ვაჭრობის ისტორია',
            economicCalendar: 'კალენდარი',
            marketNews: 'ბაზრის ახალი ამბები'
        },
        closePositionModal: {
            closeOrder: 'პოზიციის დახურვა',
            areYouSure: 'დარწმუნებული ხარ, რომ პოზიციის დახურვა გსურს?',
            buy: 'გიყდვა',
            sell: 'გაყიდვა',
            yes: 'კი',
            no: 'არა',
        },
        pciDssModal: {
            desc: `გადახდის ბარათების ინდუსტრიის მონაცემთა უსაფრთხოების სტანდარტი (PCI DSS) მოთხოვნა არის საჭირო ყველა ენთიტებისთვის, რომელნიც შენახავენ, გადაამოწმებენ ან გადაამოწმებენ Visa/Master Card/Stripe ბარათის პორტინფოლიოს მონაცემებს, მოწყობილობებს და მომსახურებელებს.
            ეს არის გლობალური უსაფრთხოების სტანდარტი ყველა ენთიტეტისთვის, რომელნიც შენახავენ, გადაამოწმებენ ან გადაამოწმებენ ბარათის პორტინფოლიოს მონაცემებს და/ან გამფრთხილებიანი ავტენტიკაციის მონაცემებს. PCI DSS დაყენებისთვის აწყობს საბაზისო დაცვის დონის კონსუმერებისთვის და გადახაზებებისა და მონაცემთა დაცვის პრაქტიკის საერთაშორისო ეკოსისტემაში დამატებითი უზრუნველყოფა.`
        },
        chart: {
            addNewChart: 'ახალი ცხრილის აგება',
        },
        symbolsModal: {
            watchlist: 'საყურადღებო აქციები',
            asset: 'აქცია',
            price: 'ფასი',
            changePct: 'ცვლილება ბოლო 24 სთ'
        },
        pendingModal: {
            pending: 'განხილვაშია',
            assetPrice: 'აქციის ფასი',
            current: 'ახლანდელი',
            revert: 'საბაზრო ფასზე გადატანა',
            automatically: 'პოზიცია აუტომატურად გაიხსნება, როდესაც ფასი მიაღწევს ამ დონეს'
        },
        orderMenu: {
            default: 'ჩვეულებრივი',
            volume: 'მოცულობა',
            lots: 'წილები',
            units: 'ერთეულები',
            currency: 'ვალუტა',
            contractSize: 'კონტრაქტის ზომა',
            position: 'პოზიცია',
            margin: 'შემოსავალი',
            freeMargin: 'ხელმისაწვდომი შემოსავალი',
            takeProfitStopLoss: 'სარგებლის მიღება & დანაკარგების შეჩერება',
            pending: 'განხილვაშია',
            market: 'ბაზარი',
            leverage: 'ბერკეტი',
            spread: 'ფასთა სხვაობა',
            notSet: 'არაა დაყენებული',
            at: 'ამ დონეზე',
            buy: 'ყიდვა',
            sell: 'გაყიდვა',
        },
        footer: {
            supportBanner: 'ყოველ დღე, მთელი დღე',
            currentTime: 'დრო',
            liveChat: 'ლაივ ჩატი'
        },
        statusBar: {
            balance: 'ბალანსი',
            credit: 'კრედიტი',
            equity: 'აქცია',
            margin: 'შემოსავალი',
            marginLevel: 'შემოსავლის დონე',
            freeMargin: 'ხელმისაწვდომი შემოსავალი',
            pnl: 'PnL',
            profitTotal: 'PnL-ის ამოუწურავი მარაგი'
        },
        accountModal: {
            dateRegistered: 'რეგისტრაციის თარიღი',
            userId: 'მომხმარებლის ID',
            dashboard: 'მართვის დაფა',
            personalData: 'პირადი მონაცემები',
            deposit: 'დეპოზიტი',
            withdrawFunds: 'თანხის გამოტანა',
            savings: 'დაგროვილი თანხა',
            settings: 'პარამეტრები',
            logout: 'გასვლა'
        },
        depositModal: {
            BT_INVOICE: 'შეიტანეთ რაოდენობა და შექმნეით ინვოისი',
            BT_TITLE: 'ჩვენი საბანკო მონაცემები',
            account: 'ანგარიში',
            bank_name: 'ბანკის დასახელება',
            beneficiary: 'მიმღები',
            beneficiary_address: 'მიმღების მისამართი',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'რაოდენობა',
            TOKEN_SUBTOTAL: 'შეუალედური ჯამი',
            TOKEN_TOTAL: 'ჯამი',
            TOKEN_DESCRIPTION: 'აღწერა',
            TOKEN_PAYMENT_DETAILS: 'გადახდის დეტალები',
            PENDING_DEPOSITS_LIMIT: 'დეპოზიტის ლიმიტი',
            makeDeposit: 'დეპოზიტის შექმნა',
            practiceAccount: 'საპრაქტიკო ანგარიში',
            realAccount: 'ნამდვილი ანგარიში',
            noWithdrawals: 'არაა გამოტანები',
            easyWithdrawals: 'მარტივი გამოტანები',
            allAssets: 'ყველა აქცია ხელმისაწვდომია',
            fullFledged: 'სრულყოფილი პლატფორმა',
            fillUpTo: 'შევსება ამ დონემდე',
            freeReplenishment: 'თავისუფალი შევსება',
            topUp: 'ანგარიშის შევსება',
            minimumAmount: 'მინიმალური რაოდენობა',
            canSwitch: 'ნებისმიერ დროს შეგიძლიათ შეცვალოთ ანგარიშები'
        },
        gridsModal: {
            chartGrids: 'ცხრილები',
            chart1: '1 ცხრილი',
            chart2: '2 ცხრილი',
            chart3: '3 ცხრილი',
            chart4: '4 ცხრილი',
        },
        noConnectionModal: {
            connectionLost: 'სერვერთან კავშირი დაიკარგა',
            retryNow: 'თავიდან ცდა',
        },
        loginModal: {
            loginToTradeRoom: 'შესვლა სავაჭრო ოთახში',
            email: 'ი-მეილი',
            enterEmail: 'შეიყვანეთ ი-მეილი',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA კოდი (თუ არის ჩართული)',
            twoFactorAuth: 'ორ-ფაქტორიანი ავტორიზაცია',
            password: 'პაროლი',
            yourPassword: 'შენი პაროლი',
            signIn: 'შესვლა',
            dontHaveAccount: 'არ გაქვთ ანგარიში?',
            restore: 'აღდგენა',
            fullName: 'სახელი & გვარი',
            havePromo: 'მაქვს პრომო კოდი',
            promo: 'პრომო კოდი',
            login: 'შესვლა',
            haveAccount: 'უკვე გაქვთ ანგარიში?',
            hide: 'დამალვა',
            goTo: 'უკან'
        },
        newPasswordModal: {
            setNewPassword: 'ახალი პაროლის დაყენება',
            newPassword: 'ახალი პაროლი',
            confirmNewPassword: 'ახალი პაროლის დადასტურება',
            setButton: 'დაყენება'
        },
        registerModal: {
            repeatPassword: 'პაროლის გამეორება',
            createNewAccount: 'ახალი ანგარიშის შექმნა',
            email: 'ი-მეილი',
            firstName: 'სახელი',
            lastName: 'გვარი',
            country: 'ქვეყანა',
            phone: 'ტელეფონი',
            password: 'პაროლი',
            createAccount: 'ანგარიშის შექმნა',
            currency: 'ვალუტა',
            privacyAndPolicy: "კონფიდენციალურობა და პოლიტიკა",
            customerAgreement: 'მომხმარებლის შეთანხმება',

        },
        forgotPasswordModal: {
            forgotPassword: 'დაგავიწყდა პაროლი?',
            submitEmail: 'გთხოვთ, შეიყვანეთ ი-მეილი, გადადით ი-მეილზე და მიყევით ინსტრუქციებს',
            submit: 'დადასტურება'
        },
        notifications: {
            error: 'შეცდომა',
            success: 'წარმატება',
            deposit: 'დეპოზიტი',
            withdrawal: 'გამოტანა',
            depositApproved: 'დადასტურებულია',
            depositDeclined: 'დეპოზიტი უარყოფილია',
            withdrawalApproved: 'გამოტანა დადასტურებულია',
            withdrawalDeclined: 'გამოტანა უარყოფილია'
        },
        markets: {
            favorites: 'საყურე სია', 
            all: 'ყველა სიმბოლო',
            forex: 'ფორექსი',
            stocks: 'აქციები',
            commodities: 'საქონელი',
            indices: 'ინდექსები',
            crypto: 'კრიპტო',
            metals: 'მეტალები',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'ყიდვა',
            profitCalculator: 'მოგების კალკულატორი',
            sell: 'გაყიდვა',
            volume: 'მოცულობა',
            entryPrice: 'თავდაპირველი ფასი',
            takeProfit: 'მოგების გამოტანა',
            stopLoss: 'წაგების შეჩერება',
            maxPosition: 'მაქს. პოზიცია',
            calculate: 'კალკულაცია',
            calculationsFor: 'კალკულაცია შემდეგი მონაცემისთვის',
            leverage: 'ბერკეტი',
            requiredMargin: 'საჭირო სხვაობა',
            profitFromTP: 'მოგება TP-დან',
            lossFromSL: 'წაგება SL-დან',
            roe: 'მოგება ინვესტიციიდან',
        },
        depositSuccessModal: {
            title: 'დეპოზიტი წარმატებულად შესრულდა',
            text: 'თქვენი დეპოზიტი წარმატებულად შესრულდა!',
        },
        depositFailModal: {
            title: 'დეპოზიტი წარუმატებელია',
            text: 'თქვენი დეპოზიტი ვერ შესრულდა.',
        },
        widgetMarketWatch: {
            marketWatch: 'ბაზარზე დაკვირვება',
            search: 'ძებნა...',
        },
        widgetOrders: {
            commission: 'საკომისიო',
            volume: 'მოცულობა',
            margin: 'სხვაობა',
            active: 'აქტიური',
            pending: 'მუშავდება',
            activeOrders: 'აქტიური შეკვეთები',
            portfolio: 'პორტფელი',
            allPositions: 'ყველა პოზიცია',
            noPositionsLine1: 'თქვენ არ გაქვთ ღია პოზიციები',
            noPositionsLine2: 'ჯერ-ჯერობით',
            show: 'ჩვენება',
            more: 'მეტი',
            less: 'ნაკლები',
            purchaseTime: 'ყიდვის დრო',
            closePosition: 'დახურვის პოზიცია',
            priceOpen: 'ფასი ყიდვისას',
            priceSl: 'წაგების შეჩერების ფასი',
            priceTp: 'მოგების გამოტანის ფასი',
            type: 'პოზიციის მიმართულება',
            pnl: 'მოგება/წაგება',
            cancelOrder: 'შეკვეთის გაუქმება',
            orderType: 'შეკვეთის ტიპი',
            cancelled: 'გაუქმებული',
            tradingHistory: 'ვაჭრობის ისტორია',
            noHistoryLine1: 'თქვენ ჯერ-ჯერობით არ გაქვთ',
            noHistoryLine2: 'დახურული შეკვეთები',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'კალენდარი'
        },
        widgetMarketNews: {
            marketNews: 'ბაზრის ახალი ამბები'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'აქტიური შეკვეთები',
            ordersHistory: 'შეკვეთების ისტორია',
            id: 'ID',
            symbol: 'სიმბოლო',
            type: 'ტიპი',
            volume: 'მოცულობა',
            openPrice: 'ფასი გაღებისას',
            openTime: 'გაღების დრო',
            closePrice: 'ფასი დახურვისას',
            closeTime: 'დახურვის დრო',
            sl: 'SL',
            tp: 'TP',
            price: 'ფასი',
            pnl: 'PnL',
            actions: 'ქმედებები',
            edit: 'შეცვლა',
            close: 'დახურვა',
            commission: 'საკომისიო',
            swap: 'სვოპი',
            swapLong: 'გრძელი სვოპი',
            swapShort: 'მოკლე სვოპი'
        },
        assetInfoModal: {
            assetInfo: 'აქციის მონაცემები',
            tradingConditions: 'სავაჭრო პირობები',
            information: 'ინფორმაცია',
            bid: 'შეთავაზება',
            ask: 'თხოვნა',
            sessionChange: 'შესიის შეცვლა',
            tradeNow: 'ვაჭრობა',
            opens: 'იღება',
            closes: 'იხურება',
            at: 'აქ/ამ მონაცემზე',
            open247: 'ღიაა 24/7',
            today: 'დღეს',
            tomorrow: 'ხვალ',
            sunday: 'კვირა',
            monday: 'ორშაბათი',
            tuesday: 'სამშაბათი',
            wednesday: 'ოთხშაბათი',
            thursday: 'ხუთშაბათი',
            friday: 'პარასკევი',
            saturday: 'შაბათი',
            contractSpecification: 'კონტრაქტის დაზუსტება',
            symbol: 'სიმბოლო',
            name: 'სახელი',
            market: 'ბაზარი',
            baseCurrency: 'საბაზისო ვალუტა',
            digits: 'ციფრები',
            lotSize: 'წილის ზომა',
            lotStep: 'წილის მომატების დონე',
            minVolume: 'მინ. მოცულობა',
            maxVolume: 'მაქს. მოცულობა',
            leverage: 'ბერკეტი',
            commission: 'საკომისიო',
            swapLong: 'გრძელი სვოპი',
            swapShort: 'მოკლე სვოპი',
            schedule: 'განრიგი',
            weekday: 'სამუშაო დღე',
            tradingTime: 'სავაჭრო დრო',
            closed: 'დახურული',
            sell: 'გაყიდვა',
            buy: 'ყიდვა',
            low: 'დაბალი',
            high: 'მაღალი',
            oneHourChange: 'ცვლილება ბოლო 1 სთ.',
            oneDayChange: 'ცვლილება ბოლო 1 დღე',
            oneWeekChange: 'ცვლილება ბოლო 1 კვირა',
            oneMonthChange: 'ცვლილება ბოლო 1 თვე',
            threeMonthsChange: 'ცვლილება ბოლო 3 თვე',
            oneYearChange: 'ცვლილება ბოლო 1 წელი',
            loading: 'იტვირთება...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Low',
            riskLevelMedium: 'Medium',
            riskLevelHigh: 'High',
            perDay: 'გარიგებები დღეში:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'შენახვა',
            cancel: 'გაუქმება',
        },
        cardBox: {
            name: 'სახელი',
            cardNumber: 'ბარათის ნომერი',
            expiration: 'ვადა (თვ/წწ)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "ვაჭრობა",
            startTrading: 'დაიწყეთ ვაჭრობა',
            deposit: 'დეპოზიტი',
            dashboard: 'მართვის დაფა',
            personalInfo: 'პირადი მონაცემები',
            contactInfo: 'კონტაქტური ინფორმაცია',
            withdrawal: 'გამოტანა',
            verification: 'ავტორიზაცია',
            accounts: 'ანგარიშები',
            liveChat: 'ლაივ ჩატი',
            savings: 'დაგროვეული რაოდენობა',
            referrals: 'რეფერალური პროგრამა',
            settings: 'პარამეტრები',
            logOut: 'გასვლა',
        },
        fullScreenDeposit: {
            methods: 'მეთოდები',
            sendOtp: 'otp გაგზავნა',
            otpCode: 'Otp კოდი',
            addWalletAddress: 'დეპოზიტის საფულე არაა მითითებული. გთოხვთ, დაუკავშირდით აგენტს ან მხარდაჭერის პერსონალს',
            addressReceipt: 'გთხოვთ, დაიცადეთ, სანამ მისამართი იტვირთება',
            makeDeposit: 'დეპოზიტის შექმნა',
            instant: 'დაუყოვნებელი',
            minutes: 'წუთები',
            hours: 'საათი',
            days: 'დღე',
            amount: 'რაოდენობა',
            continue: 'გაგრძელება',
            qrCode: 'QR კოდი',
            depositAddress: 'დეპოზიტის მისამართი',
            copy: 'კოპირება',
            last: 'ბოლო',
            deposits: 'დეპოზიტები',
            time: 'დრო',
            currency: 'ვალუტა',
            status: 'სტატუსი',
            info: 'ინფორმაცია',
        },
        fullScreenCcExt: {
            pleaseWait: 'გთხოვთ, დაიცადეთ. ვუკავშირდებით გადახდის პროვაიდერს...',
            cardPayment: 'ბარათით გადახდა',
            inOrder: 'იმისათვის, რომ გაიგზავნოს ეს ოდენობა,',
            pleaseProvide: 'გთხოვთ, მიუთითეთ ბარათის მონაცემები'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'ჯერ შეკვეთა არ არის',
            totalBalance: 'ჯამური ბალანსი',
            totalPnl: 'ჯამური PNL',
            profitableOrders: 'მომგებიანი შეკვეთები',
            roi: 'მოგება ინვესტიციიდან',
            activityLog: 'ქმედებების ისტორია',
            loginFromIp: 'შესვლა IP-დან',
            tradingResults: 'ვაჭრობის შედეგები',
            week: 'კვირა',
            month: 'თვე',
            year: 'წელი',
            successRate: 'წარმატების სიხშირე',
            closedWithProfit: 'დაიხურა მოგებით',
            closedWithLoss: 'დაიხურა წაგებით',
            account: 'ანგარიში',
            balance: 'ბალანსი',
            leverage: 'ბერკეტი',
            credit: 'კრედიტი',
            tradeNow: 'ვაჭრობა',
            usingCurrentRate: 'გამოიყენება ვალუტის ახლანდელი კურსი',
            totalDeposits: 'ჯამური დეპოზიტები',
            totalWithdrawals: "ჯამური გამოტანები",
            returnOnInvestment: "ინვესტიციის დაბრუნება"
        },
        fullScreenPersonal: {
            personalDetails: 'პირადი მონაცემები',
            profilePhoto: 'პროფილის სურათი',
            firstName: 'სახელი',
            country: 'ქვეყანა',
            lastName: 'გვარი',
            address: 'მისამართი',
            email: 'ი-მეილი',
            phone: 'ტელეფონი',
            saveChanges: 'ცვლილებების შენახვა',
            state: 'ქალაქი',
            postcode: 'საფოსტო ინდექსი',
            created: "ანგარიში შექმნილია",
        },
        fullScreenAvatar: {
            dropFile: 'სურათის ასატვირთათ, შეიყვანეთ ზემოთ აღნიშნულ წრეში',
            notAllowed: 'შენახვა არაა შესაძლებელი',
            notAllowedLine1: 'პორნოგრაფიული ხასიათის სურათები',
            notAllowedLine2: 'ეთნიკური ზიზღის ან რასიზმის შემცველი სურათები',
            notAllowedLine3: '18 წლამდე ასაკის ბავშვების შემცველი სურათები',
            notAllowedLine4: 'სურათები, რომლებიც დაცულია საავტორო უფლებებით',
            notAllowedLine5: '5 MB-ზე დიდი სურათები ან სურათები, რომლებიც არ არიან  JPG, GIF ან PNG ფორმატის',
            requirements: 'თქვენი სახე მკაფიოდ უნდა ჩანდეს. ყველა სურათი უნდა ექვემდებარებოდეს სტანდარტებს, ან წაიშლება.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'გთხოვთ, დაუკავშირდით თქვენი ანგარიშის მენეჯერს გამოტანის დეტალებთან დაკავშირებით.',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'გამოტანა',
            requestNewWithdrawal: 'ახალი გამოტანის მოთხოვნა',
            amount: 'რაოდენობა',
            currentBalance: 'თქვენი ანგარიშის ბალანსი არის',
            withdrawAll: 'ყველაფრის გამოტანა',
            requestWithdrawal: 'გამოტანის მოთხოვნა',
            last: 'ბოლო',
            withdrawalRequests: 'გამოტანის მოთხოვნები',
            time: 'დრო',
            currency: 'ვალუტა',
            info: 'ინფორმაცია',
            status: 'სტატუსი',
            bank: 'ბანკი',
            bitcoin: 'ბიტკოინი',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'ბარათი',
            ethereum: 'ეთერიუმი',
            eth: 'ეთერიუმი',
            usdt: 'USDT',
            withdrawalDetails: 'გამოტანის დეტალები',
            address: 'საფულის მისამართი',
            bankDetails: 'საბანკო დეტალები',
            bankAccount: 'ანგარიშის ნომერი',
            bankHolder: 'ანგარიშის მფლობელის სახელი',
            bankIban: 'IBAN',
            bankSwift: 'ბანკის SWIFT კოდი',
            recipientAddress: 'მიმღების მისამართი',
            routingNumber: 'საბანკო მარშრუტის ნომერი',
            bankBeneficiary: 'ბენეფიციარის ბანკის სახელი',
            bankAddress: 'ბანკის მისამართი',
            bankCode: 'ბანკის კოდი (BSB)',
            desc: 'აღწერა',
            cardNumber: 'ბარათის ნომერი',
            cardHolder: 'ბარათის მფლობელის სახელი',
        },
        fullScreenVerification: {
            dragAndDrop: 'ჩააგდეთ დოკუმენტი აქ',
            documentsList: 'ატვირთული დოკუმენტების სია',
            document: 'დოკუმენტი',
            timeUploaded: 'ატვირთვის დრო',
            timeProcessed: 'დამუშავების დრო',
            status: 'სტატუსი',
            types: ['Id-ს სინამდვილე', 'რეზიდენციის მტკიცებულება', 'ბარათის წინა მხარე', 'ბარათის უკანა მხარე', 'Id-ს სინამდვილე', 'სელფი', 'Other'],
            types2: ['იდენტიფიკაციის მტკიცებულება (POI)', 'სარეზიდენციო მისამართის მტკიცებულება', 'ბარათის წინა მხარე', 'ბარათის უკანა მხარე', 'ID-ს უკანა მხარე', 'სელფი ID-თან ერთად'],
            upload: 'ატვირთვა',
            isConfirmed: 'დადასტურებულია',
            uploadAnotherDocument: 'ატვირთეთ ახალი დოკუმენტი',
            isUnderReview: 'მიმდინარეობს მიმოხილვა',
            questionnaire: 'კითხვარი',
            sendAnswers: 'პასუხების გაგზავნა',
            progressBarOf: 'დან',
            progressBarText: 'დოკუმენტი აიტვირთა და დადასტურდა'
        },
        fullScreenAccounts: {
            manageAccounts: 'თანხის გადარიცხვა ანგარიშებს შორის',
            transferFrom: 'ამ ანგარიშიდან',
            transferTo: 'ამ ანგარიშზე',
            amount: 'რაოდენობა',
            makeTransfer: 'გადარიცხვა',
            yourAccounts: 'თქვენი ანგარიშები',
            account: 'ანგარიში',
            currency: 'ვალუტა',
            balance: 'ბალანსი',
            credit: 'კრედიტი',
            makeActive: 'გააქტიურება',
            active: 'აქტიური'
        },
        fullScreenSupport: {
            sendMessage: 'მესიჯის გაგზავნა'
        },
        fullScreenSavings: {
            pct_0: 'მოქნილი',
            pct_30: 'დახურულია 1 თვის განმავლობაში',
            pct_90: 'დახურულია 3 თვის განმავლობაში',
            pct_180: 'დახურულია 6 თვის განმავლობაში',
            pct_365: 'დახურულია 1 წლის განმავლობაში',
            savings: 'დაგროვილი თანხა',
            detailedInformation: 'ინფორმაცია',
            pleaseSelect: 'გთხოვთ, აირჩიეთ ვალუტა და პერიოდი',
            openSavings: 'დაგროვილი თანხის ნახვა',
            currentTime: 'დრო',
            releaseTime: 'გაშვების დრო',
            currencyChosen: 'არჩეული ვალუტა',
            periodChosen: 'არჩეული პერიოდი',
            yourRate: 'თქვენი დონე',
            yourEarnings: 'თქვენი მოგება',
            accountFrom: 'ანგარიშები, საიდანაც ირიცხება თანხა',
            enterAmount: 'შეიყვანეთ რაოდენობა',
            in: '-ში',
            openAndInvest: 'გახსენით დაგროვილი თანხების ანგარიში და ჩადევით ინვესტიცია',
            investment: 'ინვესტიცია',
            period: 'პერიოდი',
            daysTotal: 'დღეების ჯამური რაოდენობა',
            finalEarnings: 'საბოლოო მოგება',
            created: 'შექმნილია',
            daysElapsed: 'გასული დღეები',
            days: 'დღეები',
            earnings: 'მოგება',
            fundsReleasedIn: 'გაშვებული ოდენობა',
            claim: 'მიღება',
            name: "სახელი",
        },
        fullScreenSettings: {
            updatePassword: 'პაროლის განახლება',
            currentPassword: 'ახლანდელი პაროლი',
            newPassword: 'ახალი პაროლი',
            changePassword: 'პაროლის შეცვლა',
            activationForGoogle: 'Google Authenticator-ის აქტივაციის კოდი',
            activateAndEnter: 'ჩართეთ ავტორიზაცია და შეიყვანეთ კოდი ქვემოთ',
            qrCode: 'QR კოდი',
            activateProtection: '2FA დაცვის აქტივაცია',
            protectionActive: '2FA დაცვა აქტიურია',
            twoFactorCode: '2FA კოდი',
            disableProtection: '2FA დაცვის გაუქმება',
            language: 'ენა',
            dashboardCurrency: 'მართვის პანელის ვალუტა',
            confirmNewPassword: 'ახალი პაროლის დადასტურება',
        },
        fullScreenReferrals: {
            yourLink: 'თქვენი რეფერალური ლინკი',
            referredFriends: 'რეფერალით მოსარგებლე მეგობრები',
            id: 'ID',
            name: 'სახელი',
            registered: 'რეგისტრაცია',
            level: 'დონე',
            payout: 'კომპენსაცია',
            lastPayouts: 'უკანასკნელი რეფერალების კომპენსაცია',
            time: 'დრო',
            amount: 'რაოდენობა',
            currency: 'ვალუტა',
            info: 'ინფო',
            referralDesc:"რეფერალური პროგრამა შექმნილია, რათა დაგაჯილდოვოთ ჩვენი ფასიანი ინვესტორების საზოგადოების გაფართოებისთვის.",
            shareLink:"გააზიარეთ ბმული:",
            subtitle:"მიუთითეთ თქვენი სახელი, ელფოსტა ან სავაჭრო ID.",
            descSuccesfull:"როდესაც თქვენი რეკომენდაცია წარმატებით დარეგისტრირდება, თქვენი ფინანსური მრჩეველი დაუკავშირდება მას, რათა დაეხმაროს პირველ ნაბიჯებზე საინვესტიციო გზაზე პროფესიონალურად და ეფექტურად.",
            descTracked:"მისი ყველა შენატანი დაფიქსირდება თქვენი რეფერალის ქვეშ."
        },
        months: {
            Jan: 'იანვარი',
            Feb: 'თებერვალი',
            Mar: 'მარტი',
            Apr: 'აპრილი',
            May: 'მაისი',
            Jun: 'ივნისი',
            Jul: 'ივლისი',
            Aug: 'აგვისტო',
            Sep: 'სექტემბერი',
            Oct: 'ოქტომბერი',
            Nov: 'ნოემბერი',
            Dec: 'დეკემბერი'
        },
        orderTypes: ['ყიდვა', 'გაყიდვა', 'ყიდვის ლიმიტი', 'გაყიდვის ლიმიტი', 'ყიდვის შეჩერება', 'გაყიდვის შეჩერება'],
        statuses: ['განიხილება', 'დადასტურებულია', 'უარყოფილია', 'მუშავდება'],
        errors: {
            NOT_FOUND: 'დაფიქსირდა შეცდომა',
            FORBIDDEN: 'შევლა უარყოფილია. გთხოვთ, სცადოთ განმეორებით',
            SERVER_ERROR: 'ქმედება ვერ შესრულდა. გთხოვთ, სცადეთ მოგვიანებით',
            INVALID_ACCOUNT: 'სესია ამოიწრუა. გთხოვთ, თავიდან შედით.',
            INVALID_EMAIL: 'არასწორი ი-მეილი',
            INVALID_PHONE: 'არასწორი ტელ. ნომერი',
            INVALID_COUNTRY: 'არასწორი ქვეყანა',
            INVALID_LOGIN: 'არასწორი მონაცემები',
            USER_BLOCKED: 'ანგარიში დაბლოკილია, გთხოვთ, დაუკავშირდით მხარდაჭერის გუნდს',
            USER_REGISTERED: 'ეს ი-მეილი ან ტელ. ნომერი დაკავშირებულია არსებულ ანგარიშთან',
            INVALID_USER: 'მომხმარებელი არ იძებნება',
            REJECTED: 'ოპერაცია არაა დაშვებული პლატფორმის წესებიდან გამომდინარე',
            INVALID_OLD_PASSWORD: 'ძველი პაროლი არაა სწორი',
            INVALID_SYMBOL: 'არასწორი სავაჭრო სიმბოლო',
            INVALID_SYMBOL_PRECISION: 'სიმბოლოს შეუსაბამო სიზუსტე',
            INVALID_FRAME: 'ცხრილის ჩარჩო არასწორია',
            INVALID_CURRENCY: 'არასწორი ვალუტა',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'ანგარისთან შეუსაბამო ვაჭრობის ტიპი',
            INSUFFICIENT_FUNDS: 'თანხა არაა საკმარისი',
            INVALID_WITHDRAWAL: 'გამოტანა არ იძებნება',
            INVALID_STATUS: 'სტატუსი არასწორია',
            INVALID_FILE: 'ფაილის ტიპი არასწორია ან ფაილი ზომის ლიმტის აჭარბებს',
            INVALID_DOCUMENT: 'დოკუმენტი არ იძებნება',
            INVALID_NAME: 'არასწორი სახელი',
            INVALID_LOT_SIZE: 'წილის არასწორი ზომა',
            INVALID_LOT_STEP: 'წილის მომატების დონე არასწორია',
            INVALID_MIN_VOLUME: 'მინიმალური მოცულობა არასწორია',
            INVALID_MAX_VOLUME: 'მაქსიმალური მოცულობა არასწორია',
            INVALID_GROUP: 'ჯგუფი არასწორია',
            INVALID_SPREAD: 'სხვაობა არასწორია',
            REQUEST_TIMEOUT: 'მოთხოვნა გაუქმებულია. გთხოვთ, სცადეთ მოგვიანებით',
            EXISTING_OPEN_ORDERS: 'თქვენ გაქვთ ღია შეკვეთა, ჯერ დახურეთ შეკვეთა ამ მომხმარებლისთვის და სცადეთ თავიდან.',
            INVALID_TYPE: 'შეკვეთის ტიპი არასწორია',
            INVALID_VOLUME: 'შეკვეთის მოცულობა არასწორია',
            INVALID_SL: 'წაგების შეჩერების ოდენობა არასწორია',
            INVALID_TP: 'მოგების გამოტანის ოდენობა არასწორია',
            INVALID_PRICE: 'შეკვეთის ფასი არასწორია',
            INVALID_EXPIRATION: 'შეკვეთის გაუქმება არასწორია',
            NOT_ENOUGH_MARGIN: 'მოგება არაა საკმარისი',
            INVALID_ORDER: 'შეკვეთა არ იძებნება',
            MARKET_CLOSED: 'ვაჭრობა არაა ხელმისაწვდომი, გთხოვთ, სცადეთ მოგვიანებით',
            INVALID_BALANCE_TYPE: 'ბალანსის ოპერაციის ტიპი არასწორია',
            INVALID_HASH: 'ჰეში არასწორია',
            HASH_EXPIRED: 'პაროლის შეცვლის ლინკი გაუქმებულია, გთხოვთ, მოითხოვეთ კიდევ ერთხელ.',
            INVALID_CODE: 'არასწორი 2FA კოდი',
            CHAT_DISABLED: 'საფორთის ჩატზე წვდომა შეჩერებულია',
            WITHDRAWAL_NOT_ALLOWED: 'გამოტანებზე წვდომა შეჩერებულია',
            TRADING_DISABLED: 'ვაჭრობა შეჩერებულია ამ ანგარიშზე',
            PENDING_DEPOSITS_LIMIT: 'დეპოზიტის ლიმიტი გადაჭარბებულია',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'დაშვებულია მხოლოდ ერთი მიმდინარე გამოტანა',
            LOCAL_DEPOSIT_ONLY_REAL: 'დეპოზიტები ხელმისაწვდომია მხოლოდ რეაური ანგარიშებისთვის',
            LOCAL_DEPOSIT_POSITIVE: 'დეპოზიტის ოდენობა უნდა აღემატებოდეს 0-ს',
            LOCAL_INVALID_CARD_NUMBER: 'ბარათის ნომერი არასწორია',
            LOCAL_INVALID_CARD_EXPIRATION: 'ბარათის ვადა არასწორია',
            LOCAL_INVALID_CVC: 'CVV/CVC კოდი არასწორია',
            LOCAL_PASSWORDS_NOT_MATCH: 'პაროლები ერთმანეთს არ შეესაბამება, გთხოვთ, შეიყვანთ თავიდან',
            LOCAL_NO_TRADING_ACCOUNTS: 'არ გაქვთ ხელმისაწვდომი სავაჭრო ანგარიში, გთხოვთ, დაუკავშირდით მხარდაჭერის გუნდს.',
            LOCAL_MIN_DEPOSIT: 'მინიმალური დეპოზიტი არის $',
            LOCAL_MIN_WITHDRAWAL: 'გამოტანის მინიმალური ოდენობა არის $',
            LOCAL_INVALID_FILE_TYPE: 'ფაილის ფორმატი არასწორია. გთხოვთ, გამოიყენეთ JPG, PNG ან PDF',
            LOCAL_MIN_STAKING: 'მოცემული ვალუტის მინიმალური დეპოზიტი არის ',
            FLOOD_REJECTED: 'თქვენ გადააჭარბეთ მესიჯების ლიმიტს. გთხოვთ, სცადეთ მოგვიანებით.'
        },
        countries: {
            AF: 'ავღანეთი',
            AX: 'აალანდის კუნძულები',
            AL: 'ალბანეთი',
            DZ: 'ალჟირი',
            AS: 'ამერიკული სამოა',
            AD: 'ანდორა',
            AO: 'ანგოლა',
            AI: 'ანგუილა',
            AQ: 'ანტარქტიდა',
            AG: 'ანტიგუია და ბარბუდა',
            AR: 'არგენტინა',
            AM: 'სომხეთი',
            AW: 'არუბა',
            AU: 'ავსტრალია',
            AT: 'ავსტრია',
            AZ: 'აზერბაიჯანი',
            BS: 'ბაჰამის კუნძულები',
            BH: 'ბაჰრეინი',
            BD: 'ბანგლადეში',
            BB: 'ბარბადოსი',
            BY: 'ბელორუსი',
            BE: 'ბელგია',
            BZ: 'ბელიზი',
            BJ: 'ბენინი',
            BM: 'ბერმუდა',
            BT: 'ბუტანი',
            BO: 'ბოლივია',
            BA: 'ბოსნია და ჰერცეგოვინა',
            BW: 'ბოტსვანა',
            BV: 'ბუვეტის კუნძული',
            BR: 'ბრაზილია',
            IO: 'ინდოეთის ოკეანის ბრიტანული ტერიტორია',
            BN: 'ბრუნეი დარუსალამი',
            BG: 'ბულგარეთი',
            BF: 'ბურკინა ფასო',
            BI: 'ბურუნდი',
            KH: 'კამბოჯა',
            CM: 'კამერუნი',
            CA: 'კანადა',
            CV: 'კაპე ვერდე',
            KY: 'კაიმანის კუნძულები',
            CF: 'ცენტრალური აფრიკის რესპუბლიკა',
            TD: 'ჩადი',
            CL: 'ჩილე',
            CN: 'ჩინეთი',
            CX: 'ქრისტმასის კუნძული',
            CC: 'ქოქოსის კუნძულები',
            CO: 'კოლუმბია',
            KM: 'კომოროსი',
            CG: 'კონგო',
            CD: 'კონგოს დემოკრატიული რესპუბლიკა',
            CK: 'ქუკის კუნძულები',
            CR: 'კოსტა რიკა',
            CI: 'კოტ-დ იუვარი',
            HR: 'ხორვატია',
            CU: 'კუბა',
            CY: 'კვიპროსი',
            CZ: 'ჩეხეთის რესპუბლიკა',
            DK: 'დანია',
            DJ: 'ჯიბუტი',
            DM: 'დომინიკა',
            DO: 'დომინიკის რესპუბლიკა',
            EC: 'ეკუადორი',
            EG: 'ეგვიპტე',
            SV: 'ელ სალვადორი',
            GQ: 'ეკვატორული გვინეა',
            ER: 'ერითრეა',
            EE: 'ესტონეთი',
            ET: 'ეთიოპია',
            FK: 'ფალკლანდის კუნძულები',
            FO: 'ფარერის კუნძულები',
            FJ: 'ფიჯი',
            FI: 'ფინეთი',
            FR: 'საფრანგეთი',
            GF: 'საფრანგეთის გვიანა',
            PF: 'საფრანგეთის პოლინეზია',
            TF: 'საფრანგეთის სამხრეთი ტერიტორიები',
            GA: 'გაბონი',
            GM: 'გამბია',
            GE: 'საქართველო',
            DE: 'გერმანია',
            GH: 'განა',
            GI: 'გიბრალტარი',
            GR: 'საბერძნეთი',
            GL: 'გრენლანდია',
            GD: 'გრენადა',
            GP: 'გუადალუპე',
            GU: 'გუამი',
            GT: 'გუატემალა',
            GG: 'გერნზი',
            GN: 'გვინეა',
            GW: 'გვიანა-ბისაუ',
            GY: 'გაიანა',
            HT: 'ჰაიტი',
            HM: 'ჰერდის კუნძული & მაკდონალდის კუნძულები',
            VA: 'წმინდა ეპარქია (ქალაქ ვატიკანი)',
            HN: 'ჰონდურასი',
            HK: 'ჰონგ კონგი',
            HU: 'უნგრეთი',
            IS: 'ისლანდია',
            IN: 'ინდოეთი',
            ID: 'ინდონეზია',
            IR: 'ირანი',
            IQ: 'ერაყი',
            IE: 'ირლანდია',
            IM: 'მანის კუნძული',
            IL: 'ისრაელი',
            IT: 'იტალია',
            JM: 'იამაიკა',
            JP: 'იაპონია',
            JE: 'ჯერზი',
            JO: 'იორდანია',
            KZ: 'ყაზახეთი',
            KE: 'კენია',
            KI: 'კირიბატი',
            KR: 'კორეა',
            KW: 'კუვეიტი',
            KG: 'ყირღიზეთი',
            LA: 'ლაოსის სახალხო დემოკრატიული რესპუბლიკა',
            LV: 'ლატვია',
            LB: 'ლებანონი',
            LS: 'ლესოტო',
            LR: 'ლიბერია',
            LY: 'ლიბია',
            LI: 'ლიხტენშტეინი',
            LT: 'ლიტვა',
            LU: 'ლუქსემბურგი',
            MO: 'მაკაო',
            MK: 'მაკედონია',
            MG: 'მადაგასგარი',
            MW: 'მალავი',
            MY: 'მალაიზია',
            MV: 'მალდივები',
            ML: 'მალი',
            MT: 'მალტა',
            MH: 'მარშალის კუნძულები',
            MQ: 'მარტინიკი',
            MR: 'მაურიტანია',
            MU: 'მაურიტიუს',
            YT: 'მაიოტა',
            MX: 'მექსიკა',
            FM: 'მიკრონეზია',
            MD: 'მოლდოვა',
            MC: 'მონაკო',
            MN: 'მონღოლეთი',
            ME: 'მონტენეგრო',
            MS: 'მონტსერატი',
            MA: 'მაროკო',
            MZ: 'მოზამბიკი',
            MM: 'მიანმარი',
            NA: 'ნამიბია',
            NR: 'ნაურუ',
            NP: 'ნეპალი',
            NL: 'ნიდერლანდები',
            AN: 'ნიდერლანდების ანტილები',
            NC: 'ახალი კალედონია',
            NZ: 'ახალი ზელანდია',
            NI: 'ნიკარაგუა',
            NE: 'ნიგერი',
            NG: 'ნიგერია',
            NU: 'ნიუე',
            NF: 'ნორფოლკის კუნძულები',
            MP: 'ჩრდილოეთ მარიანას კუნძულები',
            NO: 'ნორვეგია',
            OM: 'ომანი',
            PK: 'პაკისტანი',
            PW: 'პალაუ',
            PS: 'პალესტინის ოკუპირებული ტერიტორიები',
            PA: 'პანამა',
            PG: 'პაპუა ახალი გვინეა',
            PY: 'პარაგვაი',
            PE: 'პერუ',
            PH: 'ფილიპინები',
            PN: 'პიტკერნის კუნძულები',
            PL: 'პოლონეთი',
            PT: 'პორტუგალია',
            PR: 'პუერტო რიკო',
            QA: 'ყატარი',
            RE: 'რეუნიონი',
            RO: 'რუმინეთი',
            RU: 'რუსეთი',
            RW: 'რუანდა',
            BL: 'სენ-ბართლემი',
            SH: 'სენტ-ელენეს კუნძული',
            KN: 'სენ-კიტსი და ნევისი',
            LC: 'სენტ-ლუსიას კუნძული',
            MF: 'სენტ-მარტინის კუნძული',
            PM: 'სენტ-პიერის და მიკელონის კუნძული',
            VC: 'სენტ-ვინსენტი და გრენადიები',
            WS: 'სამოა',
            SM: 'სან მარინო',
            ST: 'სან-ტომე და პრინსიპი',
            SA: 'საუდის არაბეთი',
            SN: 'სენეგალი',
            RS: 'სერბეთი',
            SC: 'შეიშელის კუნძულები',
            SL: 'სიერა ლეონე',
            SG: 'სუნგაპური',
            SK: 'სლოვაკია',
            SI: 'სლოვენია',
            SB: 'სოლომონის კუნძულები',
            SO: 'სომალია',
            ZA: 'სამხრეთ აფრიკა',
            GS: 'სამხრეთ ჯორჯიას და სენდვიჩის კუნძულები',
            ES: 'ესპანეთი',
            LK: 'შრილანკა',
            SD: 'სუდანი',
            SR: 'სურინამი',
            SJ: 'შვალბარდი და იან-მაიენი',
            SZ: 'სვაზილენდი',
            SE: 'შვედეთი',
            CH: 'შვეიცარია',
            SY: 'სირია',
            TW: 'ტაივანი',
            TJ: 'ტაიჯიკეთი',
            TZ: 'ტანზანია',
            TH: 'ტაილანდი',
            TL: 'ტიმორ-ლესტე',
            TG: 'ტოგო',
            TK: 'ტოკელაუ',
            TO: 'ტონგა',
            TT: 'ტრინიდადი და ტობაგო',
            TN: 'ტუნიზია',
            TR: 'თურქეთი',
            TM: 'თურქმენეთი',
            TC: 'ტერქსისა და კაიკოსის კუნძულები',
            TV: 'ტუვალუ',
            UG: 'უგანდა',
            UA: 'უკრაინა',
            AE: 'არაბთა გაერთიანებული ემირატები',
            GB: 'გაერთინაებული სამეფო (ბრიტანეთი)',
            US: 'შეერთებული შტატები',
            UM: 'აშშ-ის მიმდებარე კუნძულები',
            UY: 'ურუგვაი',
            UZ: 'უზბეკეთი',
            VU: 'ვანუატუ',
            VE: 'ვენეზუელა',
            VN: 'ვიეტნამი',
            VG: 'ბრიტანეთის ვირჯინიის კუნძულები',
            VI: 'აშშ-ის ვირჯინიის კუნძულები',
            WF: 'უოლისი და ფუტუნა',
            EH: 'დასავლეთ საჰარა',
            YE: 'იემენი',
            ZM: 'ზამბია',
            ZW: 'ზიმბაბვე'
        },
    },
    az: {
        _name: 'Azerbaijani',
        newDesign: {
            withdrawalRequests: 'Hələ geri çəkilmə sorğusu yoxdur',
            withdrawalRequestsText1: 'İndiyədək heç bir geri çəkilmə sorğusu etməmisiniz.',
            withdrawalRequestsText2: 'Pul çıxarmağa başladıqdan sonra əməliyyat təfərrüatlarınız burada görünəcək',
            pdfAttachment: 'Pdf əlavəsi',
            agent: "Agent",
            closed: 'Bağlıdır',
            pending: 'Gözlənir',
            approved: 'Təsdiq edildi',
            declined: 'Rədd edildi',
            document: 'Sənəd',
            frontSide: 'Ön tərəf',
            backSide: 'Arxa tərəf',
            address: 'Ünvan',
            frontSideDocument: 'Sənədin ön tərəfi',
            creditCard: 'Kredit Kartı',
            selfie: 'Selfie',
            other: 'Digər',
            noDocument: 'Hələ heç bir sənəd yüklənməyib',
            amountDeposited: 'Depozit qoyulan məbləğ',
            makeTransfer: 'Transfer edin',
            otherDetails: 'Sifariş təfərrüatları',
            manualWallets: 'Kripto cüzdanlar',
            depositText: 'Depozit 5-10 dəqiqə ərzində işlənir',
            pendingBankTransfer: 'Gözləyən bank köçürmə əmanətiniz var.',
            completeDeposit: 'Zəhmət olmasa depozitinizi tamamlamaq üçün aşağıdakı təlimatlara əməl edin',
            downloadInvoice: 'Fakturanı yükləyin',
            payInvoice: 'Hesab-fakturanı bankınızda ödəyin',
            useYourBankText1: 'Hesab-fakturanı ödəmək üçün bankınızın onlayn və ya oflayn ödəniş sistemindən istifadə edin.',
            useYourBankText2: 'Ödəniş təfərrüatlarının düzgün olduğundan əmin olun.',
            uploadReceipt: 'Ödəniş təfərrüatlarının düzgün olduğundan əmin olun.',
            afterMakingPayment: 'Ödənişi etdikdən sonra əmanəti təsdiqləmək üçün qəbzinizi yükləyin',
            clickToUpload: 'Yükləmək üçün klikləyin',
            orDrag: 'və ya sürükləyib buraxın',
            fileFormat: 'PDF, JPG, JPEG, PNG 10MB-dan az',
            waitForDeposit: 'Depozit təsdiqini gözləyin',
            onceReceipt: 'Qəbz yükləndikdən sonra depozitinizi yoxlayacağıq və təsdiq edəcəyik',
            paymentDetails: 'Ödəniş təfərrüatları',
            nameOnCard: 'Kartda ad',
            cardNumber: 'Kart nömrəsi',
            expirationDate: 'İstifadə müddəti',
            securityCode: 'Təhlükəsizlik kodu',
            continue: 'Davam et',
            viewAccounts: 'Hesablara baxın',
            continueWithdrawal: 'Çıxarmağa davam edin',
            accountBalance: 'Hesab balansı',
            accountBalanceText1: 'Niyə ticarət edə bilmirəm?',
            accountsBalanceText2: 'Vəsaitləri necə depozit edirəm?',
            accountsBalanceText3: 'Zelle vasitəsilə pul yatırtmaq istəyirəm',
            tradingActions: 'Ticarət Fəaliyyətləri',
            tradingActionsText1: 'Necə qısa satış edə bilərəm?',
            tradingActionsText2: 'Bir ticarəti necə ləğv edə bilərəm?',
            tradingActionsText3: 'Minimum ticarət məbləği nə qədərdir?',
            marketInfo: 'Bazar məlumatı',
            marketInfoText1: 'Bazar tendensiyalarını harada görə bilərəm?',
            marketInfoText2: 'Bu gün ən çox qazananlar hansılardır?',
            securityAndSettings: 'Təhlükəsizlik və Parametrlər',
            securityAndSettingsText1: 'Parolu necə dəyişə bilərəm?',
            securityAndSettingsText2: 'Təhlükəsizlik xüsusiyyətləri hansılardır?',
            securityAndSettingsText3: 'Hesab məlumatımı necə yeniləyə bilərəm?',
            messageCopied: 'Mesaj mübadilə buferinə kopyalandı',
            addSymbol: 'Simvol əlavə edin',
            iHaveRead: 'Mən şərtləri oxudum və razılaşdım',
            toggleToTurn: 'Avtomatik ticarəti aktiv etmək üçün keçid edin',
            selectTheRisk: 'Risk səviyyəsini seçin',
            confirm: 'Təsdiq edin',
            cancel: 'Ləğv et',
            chooseClosingTime: 'Bağlanma vaxtını seçin',
            setOrderClosingTime: 'Sifarişin bağlanma vaxtını təyin edin',
            closingTime: 'Bağlanma vaxtı',
            date: 'Tarix',
            time: 'Vaxt',
            setCloseTime: 'Bağlanma vaxtını təyin edin',
            '15m': '15 Dəqiqə',
            '30m': '30 Dəqiqə',
            '1h': '1 saat',
            custom: 'Xüsusi',
            verifyOtp: 'OTP-nizi yoxlayın',
            enterDigits: '[email/telefon] ünvanınıza göndərilən 6 rəqəmli kodu daxil edin',
            submit: 'Təqdim et',
            order: 'Sifariş',
            closedSuccessfully: 'uğurla bağlandı',
            openSuccessfully: 'uğurla açılır',
            deposit: 'depozit',
            withdrawal: 'geri çəkilmə',
            completeVerification: 'Tam Doğrulama',
            verification: 'Doğrulama',
            avatar: 'Avatar',
            saveChanges: 'Dəyişiklikləri Saxla',
            darkMode: 'Qaranlıq rejim',
            dashboardCurrency: 'İdarəetmə paneli valyutası',
            language: 'Dil',
            twoFactor: '2 faktorlu qoruma',
            googleTwoFactorIsActive: 'Google 2 faktorlu autentifikator aktivdir',
            activateCodeForGoogle: 'Google Authenticator kodunu aktivləşdirin',
            ifYouWishDisableEnterCode: 'Qorunmanı söndürmək istəyirsinizsə, autentifikator tətbiq kodunu daxil edin',
            scanOrManuallyEnter: 'Token əlavə etmək üçün Google 2FA-da QR kodunu skan edin və ya əl ilə daxil edin',
            copyCode: 'Kodu kopyalayın',
            qrCode: 'QR kodu',
            activateAndEnterAuthenticator: 'Authenticator-u aktivləşdirin və yaradılan kodu aşağıdakı sahəyə daxil edin',
            enterTheDigitsCodeGenerated: 'Yaradılmış 6 rəqəmli kodu daxil edin',
            disableTwoFactor: 'İki faktorlu qorunmanı söndürün',
            activateTwoFactor: '2FA Müdafiəsini aktivləşdirin',
            fileAdded: 'Fayl uğurla əlavə edildi',
            theVerificationStatus: 'Yoxlama statusuna yüklənmiş sənədlərin siyahısında baxmaq olar',
            confirmWithdrawal: 'Çıxarışı təsdiqləyin',
            minimumWithdrawal: 'Minimum çəkilmə məbləği',
            welcomeToTradingPlatform: 'Ticarət platformasına xoş gəlmisiniz',
            signIn: 'Daxil ol',
            activeAccount: 'Aktiv hesab',
            save: 'Saxla',
            selectCurrencyAndPeriod: 'Valyuta və abunə müddəti seçin',
            savingsNotFound: 'Əmanət Hesabları Tapılmadı',
            pleaseCheckInvestment: 'Davam etmək üçün investisiya məlumatlarınızı yoxlayın',
            allPeriodInvestment: 'Bütün dövr investisiyaları',
            pct_0: 'Çevik',
            pct_30: '1 Ay',
            pct_60: '1 rüb',
            pct_90: '6 Ay',
            pct_180: '6 Ay',
            pct_365: '1 il',
            savingsCreated: 'Əmanət hesabı uğurla yaradıldı',
            transferDetails: 'Transfer təfərrüatları',
            transferAmount: 'Köçürmə məbləği',
            investmentDetails: 'İnvestisiya Təfərrüatları',
            investment: 'İnvestisiya',
            Period: 'Dövr',
            created: 'Yaradılıb',
            releaseTime: 'Buraxılış vaxtı',
            viewSavingsAccounts: 'Əmanət Hesablarına baxın',
            continueInvestment: 'İnvestisiyaya davam edin',
            chooseSubscription: 'Abunəliyinizi seçin',
            selectCurrencyAndSubscription: 'Davam etmək üçün valyuta və abunə müddəti seçin.',
            rate: 'Qiymətləndirmə',
            accountTransferFunds: 'Pul köçürmək üçün hesab',
            amount: 'Məbləğ',
            'in': 'in',
            openSavingsAndInvest: 'Əmanət Hesabı açın və İnvestisiya edin',
            strongSell: 'Güclü satış',
            sell: 'Sat',
            neutral: 'Neytral',
            buy: 'al',
            stringBuy: 'Güclü alış',
            chooseCryptoWallet: 'Kriptovalyuta pul kisəsini seçin',
            chooseMethod: 'Bir metod seçin',
            maximumDeposit: 'Maksimum əmanətdir',
            minimumDeposit: "Minimum depozit",
            pleaseCompleteAddress: 'Zəhmət olmasa ünvanınızı tamamlayın',
            pleaseCompleteCity: 'Zəhmət olmasa şəhərinizi tamamlayın',
            pleaseCompleteCountry: 'Zəhmət olmasa ölkənizi tamamlayın',
            pleaseCompleteZip: 'Zəhmət olmasa poçt indeksinizi doldurun',
            amountDeposit: 'Depozit məbləği',
            transactionStatus: 'Əməliyyat statusu',
            paymentMethod: 'Ödəniş üsulu',
            depositTo: 'əmanət',
            depositAddress: 'Depozit ünvanı',
            scanTheQr: 'QR kodunu skan edin və ya depozit ünvanını kopyalayın',
            wallet: 'Pul kisəsi',
            copyDepositAddress: 'Depozit ünvanını kopyalayın',
            copy: 'Kopyala',
            addressCopied: 'Ünvan buferə kopyalandı',
            '5min:': '5-10 dəq',
            '2-5days': '2-5 gün / 5-10 dəq',
            otherMethod: 'Digər üsul',
            profileSettings: 'Profil Parametrləri',
            savingsAccounts: 'Əmanət hesabları',
            subscribe: 'Abunə ol',
            toCompleteVerification: 'Hesabınız üçün yoxlama prosesini başa çatdırmaq üçün aşağıdakı sənədlərin aydın və oxunaqlı surətlərini təqdim edin',
            amountToWithdrawal: 'Çıxarılacaq məbləğ',
            cardDetails: 'Kart təfərrüatları',
            cardHolderName: 'Kart sahibinin adı',
            withdrawalSuccessful: 'Çıxarma Uğurlu',
            selectWithdrawalMethod: 'Çıxarma üsulunu seçin',
            creditDebit: 'Kredit/debet kartı',
            crypto: 'Kripto',
            cryptoWalletAddress: 'Kripto pul kisəsinin ünvanı',
            cryptoSubtitle: 'Kripto çıxarışları cüzdanınıza 🚀',
            bankWithdrawal: 'Bank çıxarışı',
            bankSubtitle: 'Bank hesabı 🏦',
            zelleSubtitle: 'Sürətli bank köçürmələri ⚡🏦',
            tradingResults: 'Ticarət nəticələri',
            day: 'Gün',
            month: 'Ay',
            noTradingData: 'Ticarət məlumatları mövcud deyil',
            startTradingToSee: 'Nəticələrinizi burada görmək üçün ticarətə başlayın.',
            yourPerformanceMetric: 'Performans göstəriciləriniz data əlçatan olduqdan sonra göstəriləcək.',
            favorite: 'Sevimli',
            all: 'Hamısı',
            availableFullTime: '24/7 mövcuddur',
            yourProfileAndSettings: 'Profiliniz və parametrləriniz',
            generateInvoice: 'Hesab-faktura yaradın',
            downloadPdf: 'PDF yükləyin',
            confirmDeposit: 'Depoziti təsdiqləyin',
            howCanIAssist: 'Sizə necə kömək edə bilərəm?',
            maxFileSize: 'Maksimum fayl ölçüsü 5 MB-dir və qəbul edilən formatlar JPEG, PNG və ya GIF-dir',
            uploadPhoto: 'Şəkil yükləyin',
            featuresDisabled: 'Bu funksiya deaktiv edilib',
            welcome: 'Xoş gəlmisiniz',
            signUp: 'Qeydiyyatdan keçin',
            registration: 'Qeydiyyat',
            pleaseConfirmAgreement: 'Xahiş olunur, müştəri razılaşmasını təsdiq edin',
            pleaseEnterPassword: 'Zəhmət olmasa, parolu daxil edin',
            passwordNotSame: 'Parollar uyğun deyil',
            noActiveOrders: 'Aktiv sifariş yoxdur',
            itLikeNoHaveActiveOrders: 'Hazırda aktiv sifarişiniz yoxdur',
            cryptoWallet: 'Kripto cüzdan',
            bankCard: 'Bank/Kart',
            selected: 'Seçilmiş',
            amountWithdrawal: 'Çıxarılan məbləğ',
            withdrawalTo: 'Çıxarışın ünvanı',
            switchToNewDesignMessage: 'İndi Yeni Platforma Dizaynımıza keçin!',
            switchToOldDesignMessage: 'Siz hələ də klassik dizaynı məhdud müddətə istifadə edə bilərsiniz',
            switchToOldDesignButton: 'Yeni Dizayna keçin',
            switchToNewDesignButton: 'Klassik Dizayna qayıdın',
            governmentIssuedPhoto1: 'Hökumət tərəfindən verilmiş şəxsiyyət vəsiqəsi (məsələn, pasport, sürücülük vəsiqəsi, milli şəxsiyyət vəsiqəsi)',
            governmentIssuedPhoto2: 'Yaşayış icazəsi (əgər tətbiq olunursa)',
            governmentIssuedPhoto3: 'Sənəd etibarlı olmalıdır (müddəti bitməməlidir)',
            governmentIssuedPhoto4: 'Tam ad, doğum tarixi və şəkil görünən olmalıdır.',
            uploadRecent1: 'Son 3 ay ərzində verilmiş kommunal ödəniş qəbzi, bank çıxarışı və ya kirayə müqaviləsini yükləyin',
            uploadRecent2: 'Tam adınızın və cari yaşayış ünvanınızın aydın şəkildə görünməsini təmin edin.',
            uploadCopy1: 'Kredit kartınızın ön tərəfinin bir nüsxəsini yükləyin, ilk altı və son dörd rəqəmin görünməsinə əmin olun',
            uploadCopy2: 'Təhlükəsizlik üçün orta rəqəmləri örtün',
            takeAndUpload1: 'ID kartınızı üzünüzün yanında tutaraq bir selfie çəkin və yükləyin',
            takeAndUpload2: 'Həm üzünüzün, həm də ID kartınızın aydın şəkildə görünməsini təmin edin',
            otherDocuments: 'Digər sənədlər',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Sosial Təminat Nömrəsi (SSN) sənədinizin aydın və oxunaqlı şəklini yükləyin. Bütün detalların görünən və maneəsiz olduğuna əmin olun.',
            frontSideSsnDocument: 'SSN sənədinin ön tərəfi',
            answerSaved: 'Cavablar yadda saxlanıldı',
            cryptoWalletDetails: 'Kripto cüzdan detalları',
            bitcoinWalletAddress: 'Bitcoin cüzdan ünvanı',
            recovery: 'Bərpa',
            passwordRecovery: 'Şifrənin bərpası',
            searchCountry: 'Ölkəni axtar',
            questionnaire: 'Sorğu vərəqi',
            questionnaireText: 'Zəhmət olmasa, şəxsiyyətinizi təsdiqləmək üçün sorğunu doldurun',
            sendAnswers: 'Cavabları göndər',
        },
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Mənfəət götür və Zərəri dayandır',
            takeProfit: 'Mənfəət götür',
            stopLoss: 'Zərəri dayandır',
            updateOrder: 'Sifarişi yenilə',
        },
        balanceModal: {
            balance: 'Balans',
            credit: 'Kredit',
            equity: 'Kapital',
            practice: 'MƏŞQ',
            real: 'REAL',
            account: 'HESAB',
            invested: 'İnvestisiya edilib',
            profit: 'Mənfəət',
            loss: 'Zərər',
            margin: 'Marja',
            marginLevel: 'Marja səviyyəsi',
            marginFree: 'Sərbəst marja'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platforma',
            deposit: 'Depozit'
        },
        sideMenu: {
            marketWatch: 'Bazara baxış',
            activeOrders: 'Aktiv sifarişlər',
            tradingHistory: 'Ticarət tarixçəsi',
            economicCalendar: 'Təqvim',
            marketNews: 'Bazar xəbərləri'
        },
        closePositionModal: {
            closeOrder: 'Pozisiyanı bağla',
            areYouSure: 'Pozisiyanı bağlamaq istədiyinizə əminsiniz?',
            buy: 'Al',
            sell: 'Sat',
            yes: 'Hə',
            no: 'Yox',
        },
        pciDssModal: {
            desc: `Ödəniş Kartları Sənədlərinin Təhlükəsizliyi İndustri Standartı (PCI DSS) compliance Visa/Master Card/Stripe kart sahibinin məlumatlarını saxlayan, emal edən və ya ötürən bütün şəxslər üçün vacibdir, bu daxil olmaqla, maliyyə institutları, təcili və xidmət təminatçıları.
            Bu, kart sahibinin məlumatlarını və/ya hassas avtentikasiya məlumatlarını saxlayan, emal edən və ya ötürən bütün şəxslər üçün global təhlükəsizlik standartıdır. PCI DSS istehsalçılar üçün bazal səviyyədə qoruma təyin edir və istehlakçılar üçün məsələlərin minimum səviyyədə saxlanmasına kömək edir və bütün ödəniş ekosistemində dolandırıcılığı və məlumatlarının pozulmasını azaldır.`
        },
        chart: {
            addNewChart: 'Yeni qrafik əlavə et',
        },
        symbolsModal: {
            watchlist: 'İzləmə siyahısı',
            asset: 'Aktiv',
            price: 'Qiymət',
            changePct: '24 saat dəyiş'
        },
        pendingModal: {
            pending: 'Gözləyir',
            assetPrice: 'Aktivin qiyməti',
            current: 'Cari',
            revert: 'Bazar qiymətinə qayıt',
            automatically: 'Qiymət bu səviyyəyə çatdıqda pozisiya avtomatik olaraq açılacaq'
        },
        orderMenu: {
            default: 'standart',
            volume: 'Həcm',
            lots: 'lot',
            units: 'vahid',
            currency: 'valyuta',
            contractSize: 'Müqavilə ölçüsü',
            position: 'Pozisiya',
            margin: 'Marja',
            freeMargin: 'Sərbəst marja',
            takeProfitStopLoss: 'Mənfəət götür və Zərəri dayandır',
            pending: 'Gözləyir',
            market: 'Bazar',
            leverage: 'Çiyin',
            spread: 'Spred',
            notSet: 'Təyin edilməyib',
            at: 'burada',
            buy: 'al',
            sell: 'sat',
        },
        footer: {
            supportBanner: 'HƏR GÜN, 24 SAAT ƏRZİNDƏ',
            currentTime: 'HAZIRKI VAXT',
            liveChat: 'Canlı Çat'
        },
        statusBar: {
            balance: 'Balans',
            credit: 'Kredit',
            equity: 'Kapital',
            margin: 'Marja',
            marginLevel: 'Marja səviyyəsi',
            freeMargin: 'Sərbəst marja',
            pnl: 'PnL',
            profitTotal: 'Ömürlük PnL'
        },
        accountModal: {
            dateRegistered: 'Qeydiyyat tarixi',
            userId: 'İstifadəçi ID',
            dashboard: 'İdarəetmə paneli',
            personalData: 'Şəxsi məlumatlar',
            deposit: 'Depozit',
            withdrawFunds: 'Vəsaitləri çıxar',
            savings: 'Əmanətlər',
            settings: 'Parametrlər',
            logout: 'Çıxış'
        },
        depositModal: {
            BT_INVOICE: 'Məbləği daxil et və faktura yarat',
            BT_TITLE: 'Bank məlumatlarımız',
            account: 'Hesab',
            bank_name: 'Bankın adı',
            beneficiary: 'Benefisiar',
            beneficiary_address: 'Benefisiarın ünvanı',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Məbləğ',
            TOKEN_SUBTOTAL: 'Ara toplam məbləğ',
            TOKEN_TOTAL: 'Toplam məbləğ',
            TOKEN_DESCRIPTION: 'Təsvir',
            TOKEN_PAYMENT_DETAILS: 'Ödəniş detalları',
            PENDING_DEPOSITS_LIMIT: 'Depozit limiti',
            makeDeposit: 'Depozit qoymaq',
            practiceAccount: 'Məşq hesabı',
            realAccount: 'Real hesab',
            noWithdrawals: 'Pul çıxarma yoxdur',
            easyWithdrawals: 'Asan pul çıxarma',
            allAssets: 'Bütün aktivlər mövcuddur',
            fullFledged: 'Universal platforma',
            fillUpTo: 'Bu məbləğədək doldur',
            freeReplenishment: 'Pulsuz doldurma',
            topUp: 'Hesabın balansını artır',
            minimumAmount: 'Minimum məbləğ',
            canSwitch: 'İstənilən vaxt hesablarınız arasında keçid edə bilərsiniz'
        },
        gridsModal: {
            chartGrids: 'QRAFİK ŞƏBƏKƏLƏR',
            chart1: '1 qrafik',
            chart2: '2 qrafik',
            chart3: '3 qrafik',
            chart4: '4 qrafik',
        },
        noConnectionModal: {
            connectionLost: 'Server ilə əlaqə kəsildi',
            retryNow: 'Yenidən cəhd et',
        },
        loginModal: {
            loginToTradeRoom: 'Ticarət otağına daxil ol',
            email: 'E-poçt ünvanı',
            enterEmail: 'E-poçt ünvanınızı daxil edin',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA Kodu (əgər aktivdirsə)',
            twoFactorAuth: 'İki faktorlu autentifikasiya',
            password: 'Şifrə',
            yourPassword: 'Şifrəniz',
            signIn: 'Daxil ol',
            dontHaveAccount: 'Hesabınız yoxdur?',
            restore: 'Bərpa et',
            fullName: 'Tam ad',
            havePromo: 'Promokodum var',
            promo: 'Promokod',
            login: 'Giriş',
            haveAccount: 'Artıq hesabınız var?',
            hide: 'Gizlət',
            goTo: 'Geriyə qayıt'
        },
        newPasswordModal: {
            setNewPassword: 'Yeni şifrə təyin et',
            newPassword: 'Yeni şifrə',
            confirmNewPassword: 'Yeni şifrəni təsdiqlə',
            setButton: 'Təyin et'
        },
        registerModal: {
            repeatPassword: 'Şifrəni təkrarla',
            createNewAccount: 'Yeni hesab yarat',
            email: 'E-poçt ünvanı',
            firstName: 'Ad',
            lastName: 'Soyad',
            country: 'Ölkə',
            phone: 'Telefon',
            password: 'Şifrə',
            createAccount: 'Hesab yarat',
            currency: 'Valyuta',
            privacyAndPolicy: "Gizlilik və Siyasət",
            customerAgreement: 'Müştəri müqaviləsi',

        },
        forgotPasswordModal: {
            forgotPassword: 'Şifrəni unutmusunuz?',
            submitEmail: 'Zəhmət olmasa qeydiyyat zamanı istifadə etdiyiniz e-poçt ünvanını göndərin, gələnlər qutusunu yoxlayın və verilən təlimatlara əməl edin',
            submit: 'Göndər'
        },
        notifications: {
            error: 'Xəta',
            success: 'Müvəffəqiyyət',
            deposit: 'Depozit',
            withdrawal: 'Çıxarma',
            depositApproved: 'təsdiq edildi',
            depositDeclined: 'Depozit rədd edildi',
            withdrawalApproved: 'Çıxarma təsdiq edildi',
            withdrawalDeclined: 'Çıxarma rədd edildi'
        },
        markets: {
            favorites: 'İzləmə siyahısı', 
            all: 'Bütün simvollar',
            forex: 'Forex',
            stocks: 'Səhmlər',
            commodities: 'Əmtəələr',
            indices: 'İndekslər',
            crypto: 'Kripto',
            metals: 'Metallar',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'AL',
            profitCalculator: 'Mənfəət kalkulyatoru',
            sell: 'SAT',
            volume: 'Həcm',
            entryPrice: 'Giriş qiyməti',
            takeProfit: 'Mənfəət götür',
            stopLoss: 'Zərəri dayandır',
            maxPosition: 'Maks. pozisiya',
            calculate: 'Hesabla',
            calculationsFor: 'Hesablamalar',
            leverage: 'Çiyin',
            requiredMargin: 'Tələb olunan marja',
            profitFromTP: 'TP-dən qazanc',
            lossFromSL: 'SL-dan zərər',
            roe: 'İnvestisiya gəliri',
        },
        depositSuccessModal: {
            title: 'Uğurlu depozit əməliyyatı',
            text: 'Depozitiniz uğurla emal edildi!',
        },
        depositFailModal: {
            title: 'Uğursuz depozit əməliyyatı',
            text: 'Depozitiniz emal edilmədi.',
        },
        widgetMarketWatch: {
            marketWatch: 'Bazara baxış',
            search: 'Axtarış...',
        },
        widgetOrders: {
            commission: 'Komissiya',
            volume: 'Həcm',
            margin: 'Marja',
            active: 'Aktiv',
            pending: 'Gözləyir',
            activeOrders: 'Aktiv sifarişlər',
            portfolio: 'Portfolio',
            allPositions: 'Bütün pozisiyalar',
            noPositionsLine1: 'Açıq pozisiyalarınız',
            noPositionsLine2: 'hələki yoxdur',
            show: 'Göstər',
            more: 'daha çox',
            less: 'daha az',
            purchaseTime: 'Alış vaxtı',
            closePosition: 'Pozisiyanı bağla',
            priceOpen: 'Alış qiyməti',
            priceSl: 'Zərəri dayandırma qiyməti',
            priceTp: 'Mənfəəti götürmə qiyməti',
            type: 'Pozisiyanın istiqaməti',
            pnl: 'Mənfəət/Zərər',
            cancelOrder: 'Sifarişi ləğv et',
            orderType: 'Sifariş növü',
            cancelled: 'Ləğv olunub',
            tradingHistory: 'Ticarət tarixçəsi',
            noHistoryLine1: 'Sizin hələki heç bir',
            noHistoryLine2: 'bağlanmış əməliyyatınız yoxdur',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Təqvim'
        },
        widgetMarketNews: {
            marketNews: 'Bazar xəbərləri'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Aktiv sifarişlər',
            ordersHistory: 'Sifarişlərin tarixçəsi',
            id: 'ID',
            symbol: 'Simvol',
            type: 'Növ',
            volume: 'Həcm',
            openPrice: 'Açılma qiyməti',
            openTime: 'Açılma vaxtı',
            closePrice: 'Bağlanma qiyməti',
            closeTime: 'Bağlanma vaxtı',
            sl: 'SL',
            tp: 'TP',
            price: 'Qiymət',
            pnl: 'PnL',
            actions: 'Fəaliyyətlər',
            edit: 'Dəyiş',
            close: 'Bağla',
            commission: 'Komisssiya',
            swap: 'Swap',
            swapLong: 'Swap Uzun',
            swapShort: 'Swap Qısa'
        },
        assetInfoModal: {
            assetInfo: 'Aktiv haqqında məlumat',
            tradingConditions: 'Ticarət şərtləri',
            information: 'Seansın dəyişdirilməsi',
            bid: 'Təklif',
            ask: 'Tələb',
            sessionChange: 'Seansın dəyişdirilməsi',
            tradeNow: 'Ticarətə indi başla',
            opens: 'Açılır',
            closes: 'Bağlanır',
            at: 'burada',
            open247: '24/7 açıq',
            today: 'bu gün',
            tomorrow: 'sabah',
            sunday: 'Bazar günü',
            monday: 'Bazar ertəsi',
            tuesday: 'Çərşənbə axşamı',
            wednesday: 'Çərşənbə',
            thursday: 'Cümə axşamı',
            friday: 'Cümə',
            saturday: 'Şənbə',
            contractSpecification: 'Müqavilənin xüsusiyyətləri',
            symbol: 'Simvol',
            name: 'Ad',
            market: 'Bazar',
            baseCurrency: 'Əsas valyuta',
            digits: 'Rəqəmlər',
            lotSize: 'Lot ölçüsü',
            lotStep: 'Lot addımı',
            minVolume: 'Min. həcm',
            maxVolume: 'Maks. həcm',
            leverage: 'Çiyin',
            commission: 'Komissiya',
            swapLong: 'Swap Uzun',
            swapShort: 'Swap Qısa',
            schedule: 'Cədvəl',
            weekday: 'Həftə içi',
            tradingTime: 'Ticarət vaxtı',
            closed: 'bağlanıb',
            sell: 'Sat',
            buy: 'Al',
            low: 'Aşağı',
            high: 'Yuxarı',
            oneHourChange: '1 saat dəyişikliyi',
            oneDayChange: '1 gün dəyişikliyi',
            oneWeekChange: '1 həftə dəyişikliyi',
            oneMonthChange: '1 ay dəyişikliyi',
            threeMonthsChange: '3 ay dəyişikliyi',
            oneYearChange: '1 il dəyişikliyi',
            loading: 'Yüklənir...',
        },
        autoTrader: {
            days: 'days',
            title: 'Avtomatik treyder',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk səviyyəsi: Aşağı',
            riskLevelMedium: 'Risk səviyyəsi: Orta',
            riskLevelHigh: 'Risk səviyyəsi: Yüksək',
            perDay: 'Gündəlik əməliyyat sayı:',
            desc: `CFD və kredit çiyini istifadə olunduğu digər məhsullarla ticarət itkilərə səbəb ola bilər. Ticarət etməzdən əvvəl müştərilər Risklərə dair xəbərdarlıq səhifəsindəki müvafiq risk bəyanatları ilə tanış olmalıdırlar. Avtomatlaşdırılmış ticarət nəticələrə zəmanət vermir. Şirkət avtomatlaşdırılmış ticarət zamanı baş verə biləcək pul itkilərinə görə heç bir məsuliyyət daşımır. Riskləri tam başa düşdüyünüzə və riskləri idarə etmək üçün lazımi tədbirlər gördüyünüzə əmin olun.`,
            accept: 'Qəbul et',
            save: 'Yadda saxla',
            cancel: 'Ləğv et',
        },
        cardBox: {
            name: 'Ad',
            cardNumber: 'Kart nömrəsi',
            expiration: 'Bitmə (ay/ill)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Ticarət",
            startTrading: 'Ticarətə başla',
            deposit: 'Depozit',
            dashboard: 'İdarəetmə paneli',
            personalInfo: 'Şəxsi məlumatlar',
            contactInfo: 'Əlaqə məlumatları',
            withdrawal: 'Çıxarma',
            verification: 'Təsdiqləmə',
            accounts: 'Hesablar',
            liveChat: 'Canlı çat',
            savings: 'Əmanətlər',
            referrals: 'Tövsiyə proqramı',
            settings: 'Parametrlər',
            logOut: 'Çıxış',
        },
        fullScreenDeposit: {
            methods: 'ÜSULLAR',
            confirm: 'Bankınızın tətbiqində təsdiqləyin',
            sendOtp: 'Birdəfəlik şifrə göndər',
            otpCode: 'Birdəfəlik şifrə',
            addWalletAddress: 'Bu depozit üsulu üçün pul kisəsi təyin edilməyib. Zəhmət olmasa agentiniz və ya dəstək xidməti ilə əlaqə saxlayın.',
            addressReceipt: 'Zəhmət olmasa, ünvanın yüklənməsini gözləyin',
            makeDeposit: 'DEPOZİT YERLƏŞDİR',
            instant: 'Dərhal',
            minutes: 'dəqiqə',
            hours: 'saat',
            days: 'gün',
            amount: 'Məbləğ',
            continue: 'Davam et',
            qrCode: 'QR KOD',
            depositAddress: 'DEPOZİT ÜNVANI',
            copy: 'Kopiyala',
            last: 'SON',
            deposits: 'DEPOZİTLƏR',
            time: 'Vaxt',
            currency: 'Valyuta',
            status: 'Status',
            info: 'Məlumat',
        },
        fullScreenCcExt: {
            pleaseWait: 'Zəhmət olmasa, gözləyin. Ödəniş xidməti provayderi ilə bağlantı yaradılır...',
            cardPayment: 'Kartla ödəniş',
            inOrder: 'Məbləği göndərmək üçün',
            pleaseProvide: 'kart məlumatlarını qeyd edin:'
        },
        fullScreenDashboard: {
            completeVerification: 'Zəhmət olmasa, təsdiqləməni tamamlayın',
            noOrderYet: 'Hələ sifariş yoxdur',
            totalBalance: 'Ümumi balans',
            totalPnl: 'Ümumi PNL',
            profitableOrders: 'Mənfəətli sifarişlər',
            roi: 'İnvestisiya gəliri',
            activityLog: 'Fəaliyyət jurnalı',
            loginFromIp: 'IP-dən giriş',
            tradingResults: 'Ticarət nəticələri',
            week: 'Həftə',
            month: 'Ay',
            year: 'İl',
            successRate: 'Müvəffəqiyyət dərəcəsi',
            closedWithProfit: 'Mənfəətlə bağlanıb',
            closedWithLoss: 'Zərərlə bağlanıb',
            account: 'Hesab',
            balance: 'Balans',
            leverage: 'Çiyin',
            credit: 'Kredit',
            tradeNow: 'İndi ticarət et',
            usingCurrentRate: 'hazırkı məzənnədən istifadə edərək',
            totalDeposits: 'Ümumi depozit sayı',
            totalWithdrawals: "Ümumi çıxarışlar",
            returnOnInvestment: "İnvestisiya qaytarışı"
        },
        fullScreenPersonal: {
            personalDetails: 'Şəxsi məlumatlar',
            profilePhoto: 'Profil şəkli',
            firstName: 'Ad',
            country: 'Ölkə',
            lastName: 'Soyad',
            address: 'Ünvan',
            email: 'E-poçt',
            phone: 'Telefon nömrəsi',
            saveChanges: 'Dəyişiklikləri yadda saxla',
            state: 'Şəhər',
            postcode: 'Poçt indeksi',
            created: "Hesab yaradıldı",
        },
        fullScreenAvatar: {
            dropFile: 'Yükləmək üçün faylı yuxarıdakı dairəyə atın',
            notAllowed: 'Bunu dərc etməyə icazə verilmir',
            notAllowedLine1: 'Aşkar seksual və ya pornoqrafik xarakterli fotoşəkillər',
            notAllowedLine2: 'Etnik və ya irqi nifrət və ya təcavüzü qızışdırmağa yönəlmiş şəkillər',
            notAllowedLine3: '18 yaşından kiçik şəxslərin iştirak etdiyi fotoşəkillər',
            notAllowedLine4: 'Üçüncü tərəfin müəllif hüquqları ilə qorunan fotoşəkilləri',
            notAllowedLine5: '5 MB-dan böyük və JPG, GIF və ya PNG formatından başqa formatda olan şəkillər',
            requirements: 'Şəkildə üzünüz aydın görünməlidir. Yüklədiyiniz bütün foto və videolar bu tələblərə uyğun olmalıdır, əks halda onlar silinə bilər.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Pul çıxarma əməliyyatı ilə bağlı daha ətraflı məlumat əldə etmək üçün hesab menecerinizlə əlaqə saxlayın',
            cryptocurrency: 'Kriptovalyuta',
            withdrawal: 'Çıxarma',
            requestNewWithdrawal: 'YENİ ÇIXARMA SORĞULA',
            amount: 'Hesab',
            currentBalance: 'Cari hesab balansınız',
            withdrawAll: 'Hamısını çıxar',
            requestWithdrawal: 'Çıxarma sorğula',
            last: 'SON',
            withdrawalRequests: 'ÇIXARMA SORĞULARI',
            time: 'Vaxt',
            currency: 'Valyuta',
            info: 'Məlumat',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Kart',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Çıxarma məlumatları',
            address: 'Pul kisəsinin ünvanı',
            bankDetails: 'Bank rekvizitləri',
            bankAccount: 'Hesab nömrəsi',
            bankHolder: 'Hesab sahibinin adı',
            bankIban: 'IBAN',
            bankSwift: 'Bankın SWIFT kodu',
            recipientAddress: 'Alıcının ünvanı',
            routingNumber: 'Bankın yönləndirmə nömrəsi',
            bankBeneficiary: 'Benefisiarın bankının adı',
            bankAddress: 'Bank ünvanı',
            bankCode: 'Bank kodu (BSB)',
            desc: 'Təsvir',
            cardNumber: 'Kartın nömrəsi',
            cardHolder: 'Kart sahibinin adı',
        },
        fullScreenVerification: {
            dragAndDrop: 'sənədi bu sahəyə çəkib atın',
            documentsList: 'YÜKLƏNMİŞ SƏNƏDLƏRİN SİYAHISI',
            document: 'Sənəd',
            timeUploaded: 'Yüklənmə vaxtı',
            timeProcessed: 'Emal olunma vaxt',
            status: 'Status',
            types: ['Şəxsiyyəti təsdiq edən sənəd', 'Yaşayış ünvanını təsdiq edən sənəd', 'Kredit kartının ön tərəfi', 'Kredit kartının arxa tərəfi', 'Şəxsiyyəti təsdiq edən sənədin arxa tərəfi', 'Selfi', 'Other'],
            types2: ['Şəxsiyyəti təsdiq edən sənəd (POI)', 'Yaşayış ünvanını təsdiq edən sənəd', 'Kredit kartının ön tərəfi', 'Kredit kartının arxa tərəfi', 'Şəxsiyyəti təsdiq edən sənədin arxa tərəfi', 'Şəxsiyyət vəsiqəsi ilə selfi şəkli'],
            upload: 'Yüklə',
            isConfirmed: 'təsdiqlənib',
            uploadAnotherDocument: 'Başqa bir sənəd yükləyin',
            isUnderReview: 'baxılır',
            questionnaire: 'Anket',
            sendAnswers: 'Cavabları göndər',
            progressBarOf: '',
            progressBarText: 'sənədiniz yükləndi və təsdiqləndi'
        },
        fullScreenAccounts: {
            manageAccounts: 'Vəsaitlərinizi hesablarınız arasında köçürün',
            transferFrom: 'Hesabından',
            transferTo: 'Hesaba',
            amount: 'Məbləğ',
            makeTransfer: 'Köçür',
            yourAccounts: 'Hesablarınız',
            account: 'Hesab',
            currency: 'Valyuta',
            balance: 'Balans',
            credit: 'Kredit',
            makeActive: 'Aktivləşdir',
            active: 'Aktiv'
        },
        fullScreenSupport: {
            sendMessage: 'Mesaj göndər'
        },
        fullScreenSavings: {
            pct_0: 'Çevik',
            pct_30: 'Bloklanıb 1 ay',
            pct_90: 'Bloklanıb 1 kvartal',
            pct_180: 'Bloklanıb 6 ay',
            pct_365: 'Bloklanıb 1 il',
            savings: 'Əmanətlər',
            detailedInformation: 'Ətraflı məlumat',
            pleaseSelect: 'Zəhmət olmasa, valyutanı və dövrü seçin',
            openSavings: 'Əmanətləri açın',
            currentTime: 'Hazırkı vaxt',
            releaseTime: 'Buraxılış vaxtı',
            currencyChosen: 'Valyuta seçilib',
            periodChosen: 'Dövr seçilib',
            yourRate: 'Dərəcəniz',
            yourEarnings: 'Qazanclarınız',
            accountFrom: 'Hansı hesabdan vəsaitlər köçürüləcək',
            enterAmount: 'Məbləği daxil edin',
            in: 'burada',
            openAndInvest: 'Əmanət hesabı açın və investisiya qoyun',
            investment: 'İnvestisiya',
            period: 'Dövr',
            daysTotal: 'Günlərin ümumi sayı',
            finalEarnings: 'Yekun qazanc',
            created: 'Yaradılıb',
            daysElapsed: 'Gün keçib',
            days: 'gün',
            earnings: 'Qazanc',
            fundsReleasedIn: 'Bu dövrdə buraxılan vəsaitlər',
            claim: 'Götürmək',
            name: "Ad",
        },
        fullScreenSettings: {
            updatePassword: 'ŞİFRƏNİ YENİLƏ',
            currentPassword: 'Hazırkı şifrə',
            newPassword: 'Yeni şifrə',
            changePassword: 'Şifrəni dəyişdir',
            activationForGoogle: 'Google Autentifikatoru üçün aktivasiya kodu',
            activateAndEnter: 'Autentifikatoru aktivləşdirin və generasiya olunmuş kodu aşağıdakı sahəyə daxil edin',
            qrCode: 'QR kod',
            activateProtection: '2FA qorunmanı aktivləşdir',
            protectionActive: '2FA qorunma aktivdir',
            twoFactorCode: '2FA kodu',
            disableProtection: '2FA qorunmasını söndür',
            language: 'Dil',
            dashboardCurrency: 'İdarəetmə paneli valyutası',
            confirmNewPassword: 'Yeni şifrəni təsdiqlə',
        },
        fullScreenReferrals: {
            yourLink: 'DƏVƏT LİNKİNİZ',
            referredFriends: 'DƏVƏT OLUNMUŞ DOSTLAR',
            id: 'ID',
            name: 'Ad',
            registered: 'Qeydiyyatdan keç',
            level: 'Səviyyə',
            payout: 'Ödəniş',
            lastPayouts: 'DƏVƏTƏ GÖRƏ SON ÖDƏNİŞLƏR',
            time: 'Vaxt',
            amount: 'Məbləğ',
            currency: 'Valyuta',
            info: 'Məlumat',
            referralDesc:"Referal Proqramı, dəyərli investorlar cəmiyyətimizi genişləndirdiyiniz üçün sizi mükafatlandırmaq üçün nəzərdə tutulmuşdur.",
            shareLink:"Linki paylaşın:",
            subtitle:"Ona adınızı, e-poçtunuzu və ya ticarət ID-nizi verin.",
            descSuccesfull:"Tövsiyə etdiyiniz şəxs uğurla qeydiyyatdan keçdikdən sonra, maliyyə məsləhətçiniz onunla əlaqə saxlayacaq və investisiya yolunda ilk addımlarına peşəkar və səmərəli şəkildə kömək edəcək.",
            descTracked:"Bütün töhfələri sizin referalınız altında izlənəcək."
        },
        months: {
            Jan: 'Yan',
            Feb: 'Fev',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'May',
            Jun: 'İyn',
            Jul: 'İyl',
            Aug: 'Avq',
            Sep: 'Sen',
            Oct: 'Okt',
            Nov: 'Noy',
            Dec: 'Dek'
        },
        orderTypes: ['Al', 'Sat', 'Alış limiti', 'Satış Limiti', 'Alışı dayandır', 'Satışı dayandır'],
        statuses: ['Gözləyir', 'Təsdiqlənib', 'Rədd edilib', 'Emal olunur'],
        errors: {
            NOT_FOUND: 'Sorğu xətası',
            FORBIDDEN: 'Girişə icazə verilmədi. Zəhmət olmasa yenidən daxil olun',
            SERVER_ERROR: 'Fəaliyyət uğursuz oldu. Zəhmət olmasa bir az sonra yenidən cəhd edin',
            INVALID_ACCOUNT: 'Seansın vaxtı bitib. Zəhmət olmasa yenidən daxil olun',
            INVALID_EMAIL: 'Yanlış e-poçt ünvanı',
            INVALID_PHONE: 'Yanlış telefon nömrəsi',
            INVALID_COUNTRY: 'Yanlış ölkə',
            INVALID_LOGIN: 'Yanlış istifadəçi adı və ya şifrə',
            USER_BLOCKED: 'Hesab bloklanıb. Zəhmət olmasa, dəstək xidməti ilə əlaqə saxlayın',
            USER_REGISTERED: 'Daxil edilmiş e-poçt və ya telefon nömrəsi olan istifadəçi artıq qeydiyyatdan keçib',
            INVALID_USER: 'İstifadəçi tapılmadı',
            REJECTED: 'Platformanın qoyduğu məhdudiyyətlər səbəbindən əməliyyata icazə verilmir',
            INVALID_OLD_PASSWORD: 'Köhnə şifrə səhvdir',
            INVALID_SYMBOL: 'Yanlış ticarət simvolu',
            INVALID_SYMBOL_PRECISION: 'Yanlış simvol dəqiqliyi',
            INVALID_FRAME: 'Yanlış qrafik çərçivəsi',
            INVALID_CURRENCY: 'Yanlış valyuta',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Yanlış ticarət hesabı növü',
            INSUFFICIENT_FUNDS: 'Kifayət qədər vəsait yoxdur',
            INVALID_WITHDRAWAL: 'Çıxarma əməliyyatı tapılmadı',
            INVALID_STATUS: 'Yanlış status',
            INVALID_FILE: 'Yanlış fayl formatı və ya faylın ölçüsü çox böyükdür',
            INVALID_DOCUMENT: 'Sənəd tapılmadı',
            INVALID_NAME: 'Yanlış ad',
            INVALID_LOT_SIZE: 'Yanlış lot ölçüsü',
            INVALID_LOT_STEP: 'Yanlış lot addımı',
            INVALID_MIN_VOLUME: 'Yanlış minimal həcm',
            INVALID_MAX_VOLUME: 'Yanlış maksimal həcm',
            INVALID_GROUP: 'Yanlış qrup',
            INVALID_SPREAD: 'Yanlış spred',
            REQUEST_TIMEOUT: 'Sorğunun vaxtı bitdi. Zəhmət olmasa, bir az sonra yenidən cəhd edin',
            EXISTING_OPEN_ORDERS: 'Açıq sifarişiniz var, əvvəlcə bu istifadəçi üçün sifarişi bağlayın və yenidən cəhd edin.',
            INVALID_TYPE: 'Yanlış sifariş növü',
            INVALID_VOLUME: 'Yanlış sifariş həcmi',
            INVALID_SL: 'Yanlış zərəri dayandırma dəyəri',
            INVALID_TP: 'Yanlış mənfəət götürmə dəyəri',
            INVALID_PRICE: 'Yanlış sifariş qiyməti',
            INVALID_EXPIRATION: 'Yanlış sifarişin bitmə müddəti',
            NOT_ENOUGH_MARGIN: 'Kifayət qədər marja yoxdur',
            INVALID_ORDER: 'Sifariş tapılmadı',
            MARKET_CLOSED: 'Ticarət işləmir. Zəhmət olmasa, yenidən cəhd edin',
            INVALID_BALANCE_TYPE: 'Yanlış balans əməliyyatı növü',
            INVALID_HASH: 'Yanlış hash',
            HASH_EXPIRED: 'Şifrəni sıfırlama linkinizin etibarlılıq müddəti bitib. Zəhmət olmasa, yenisini sorğulayın',
            INVALID_CODE: 'Yanlış 2FA kodu',
            CHAT_DISABLED: 'Dəstək xidməti çatına giriş bağlanıb',
            WITHDRAWAL_NOT_ALLOWED: 'Çıxarma əməliyyatına giriş bağlanıb',
            TRADING_DISABLED: 'Hazırkı hesab üçün ticarət funksiyası söndürülüb',
            PENDING_DEPOSITS_LIMIT: 'Depozit limiti aşılıb',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Yalnız bir gözləyən çıxarma əməliyyatına icazə verilir',
            LOCAL_DEPOSIT_ONLY_REAL: 'Depozit funksiyası yalnız real hesabda işləyir',
            LOCAL_DEPOSIT_POSITIVE: 'Depozit məbləği 0-dan çox olmalıdır',
            LOCAL_INVALID_CARD_NUMBER: 'Yanlış kart nömrəsi',
            LOCAL_INVALID_CARD_EXPIRATION: 'Yanlış kartın bitmə tarixi',
            LOCAL_INVALID_CVC: 'Yanlış CVV/CVC kod',
            LOCAL_PASSWORDS_NOT_MATCH: 'Şifrələr bir-birinə uyğun gəlmir, zəhmət olmasa, yenidən daxil edin',
            LOCAL_NO_TRADING_ACCOUNTS: 'Əlçatan ticarət hesablarınız yoxdur. Zəhmət olmasa dəstək xidməti ilə əlaqə saxlayın',
            LOCAL_MIN_DEPOSIT: 'Minimum depozit məbləği $ təşkil edir',
            LOCAL_MIN_WITHDRAWAL: 'Minimum çıxarma məbləği $ təşkil edir',
            LOCAL_INVALID_FILE_TYPE: 'Bu fayl formatı dəstəklənmir. Zəhmət olmasa, yalnız JPG, PNG və ya PDF fayllarını əlavə edin',
            LOCAL_MIN_STAKING: 'Bu valyuta üçün minimum depozit: ',
            FLOOD_REJECTED: 'Siz çox tez-tez mesaj göndərirsiniz. Zəhmət olmasa bir az sonra yenidən cəhd edin'
        },
        countries: {
            AF: 'Əfqanıstan',
            AX: 'Aland adaları',
            AL: "Albaniya",
            DZ: 'Əlcəzair',
            AS: 'Amerika Samoası',
            AD: 'Andorra',
            AO: 'Anqola',
            AI: 'Angilya',
            AQ: 'Antarktida',
            AG: 'Antiqua və Barbuda',
            AR: 'Argentina',
            AM: 'Ermənistan',
            AW: 'Aruba',
            AU: 'Avstraliya',
            AT: 'Avstriya',
            AZ: 'Azərbaycan',
            BS: 'Baham adaları',
            BH: 'Bəhreyn',
            BD: 'Banqladeş',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belçika',
            BZ: 'Beliz',
            BJ: 'Benin',
            BM: 'Bermud adaları',
            BT: 'Butan',
            BO: 'Boliviya',
            BA: 'Bosniya və Herseqovina',
            BW: 'Botsvana',
            BV: 'Buve adası',
            BR: 'Braziliya',
            IO: 'Britaniya Hind okeanı əraziləri',
            BN: 'Bruney',
            BG: 'Bolqarıstan',
            BF: 'Burkina-Faso',
            BI: 'Burundi',
            KH: 'Kamboca',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Keyp Qrin',
            KY: 'Kayman adaları',
            CF: 'Mərkəzi Afrika Respublikası',
            TD: 'Çad',
            CL: 'Çili',
            CN: 'Çin',
            CX: 'Milad adası',
            CC: 'Kokos adaları',
            CO: 'Kolumbiya',
            KM: 'Komor adaları',
            CG: 'Konqo',
            CD: 'Konqo Demokratik Respublikası',
            CK: 'Kuk adaları',
            CR: 'Kosta-Rika',
            CI: 'Fildişi Sahili',
            HR: "Xorvatiya",
            CU: 'Kuba',
            CY: 'Kipr',
            CZ: 'Çexiya',
            DK: 'Danimarka',
            DJ: 'Cibuti',
            DM: 'Dominika',
            DO: 'Dominikan Respublikası',
            AK: 'Ekvador',
            EG: 'Misir',
            SV: 'Salvador',
            GQ: 'Ekvatorial Qvineya',
            ER: 'Eritreya',
            EE: 'Estoniya',
            ET: 'Efiopiya',
            FK: 'Folklend adaları (Malvin adaları)',
            FO: 'Farer adaları',
            FJ: 'Fici',
            FI: 'Finlandiya',
            FR: 'Fransa',
            GF: 'Fransa Qvianası',
            PF: 'Fransız Polineziyası',
            TF: 'Fransanın Cənub əraziləri',
            GA: 'Qabon',
            GM: 'Qambiya',
            GE: 'Gürcüstan',
            DE: 'Almaniya',
            GH: 'Qana',
            GI: 'Cəbəllütariq',
            GR: 'Yunanıstan',
            GL: 'Qrenlandiya',
            GD: 'Qrenada',
            GP: 'Qvadelupa',
            GU: 'Quam',
            GT: 'Qvatemala',
            GG: 'Gernsi',
            GN: 'Qvineya',
            GW: 'Qvineya-Bisau',
            GY: 'Qayana',
            HT: 'Haiti',
            HM: 'Herd adası və Makdonald adaları',
            VA: 'Müqəddəs Taxt (Vatikan Dövləti)',
            HN: 'Honduras',
            HK: 'Honkonq',
            HU: 'Macarıstan',
            IS: 'İslandiya',
            ID: 'İndoneziya',
            IR: 'İran',
            IQ: 'İraq',
            IE: 'İrlandiya',
            IM: 'Men adası',
            IL: 'İsrail',
            IT: 'İtaliya',
            JM: 'Yamayka',
            JP: 'Yaponiya',
            JE: 'Cersi',
            JO: 'İordaniya',
            KZ: 'Qazaxıstan',
            KE: 'Keniya',
            KI: 'Kiribati',
            KR: 'Koreya',
            KW: 'Küveyt',
            KG: 'Qırğızıstan',
            LA: 'Laos Xalq Demokratik Respublikası',
            LV: 'Latviya',
            LB: 'Livan',
            LS: 'Lesoto',
            LR: 'Liberiya',
            LY: 'Liviya',
            LI: 'Lixtenşteyn',
            LT: 'Litva',
            LU: 'Lüksemburq',
            MO: 'Makao',
            MK: 'Makedoniya',
            MG: 'Madaqaskar',
            MW: 'Malavi',
            MY: 'Malayziya',
            MV: 'Maldiv adaları',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marşal adaları',
            MQ: 'Martinika',
            MR: 'Mavritaniya',
            IN: 'Mavrikiy',
            YT: 'Mayotta',
            MX: 'Meksika',
            FM: 'Mikroneziya',
            MD: 'Moldova',
            MC: "Monako",
            MN: 'Monqolustan',
            ME: 'Monteneqro',
            MS: 'Montserrat',
            MA: 'Mərakeş',
            MZ: 'Mozambik',
            MM: 'Myanma',
            NA: 'Namibiya',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Niderland',
            AN: 'Niderland Antil adaları',
            NC: 'Yeni Kaledoniya',
            NZ: 'Yeni Zelandiya',
            NI: 'Nikaraqua',
            NE: 'Niger',
            NG: 'Nigeriya',
            NU: 'Niue',
            NF: 'Norfolk adası',
            MP: 'Şimali Marian adaları',
            NO: 'Norveç',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'İşğal olunmuş Fələstin ərazisi',
            PA: 'Panama',
            PG: 'Papua Yeni Qvineya',
            PY: 'Paraqvay',
            PE: 'Peru',
            PH: 'Filippin',
            PN: 'Pitkern adaları',
            PL: 'Polşa',
            PT: 'Portuqaliya',
            PR: 'Puerto-Riko',
            QA: 'Qətər',
            RE: 'Reyunyon',
            RO: 'Rumıniya',
            RU: 'Rusiya',
            RW: 'Ruanda',
            BL: 'Sen-Bartelemi',
            SH: 'Müqəddəs Yelena adası',
            KN: 'Sent-Kits və Nevis',
            LC: 'Sent-Lüsiya',
            MF: 'Sen-Marten',
            PM: 'Sen-Pyer və Mikelon',
            VC: 'Sent Vinsent və Qrenadinlər',
            WS: 'Samoa',
            SM: 'San-Marino',
            ST: 'San-Tome və Prinsipi',
            SA: 'Səudiyyə Ərəbistanı',
            SN: 'Seneqal',
            RS: 'Serbiya',
            SC: 'Seyşel adaları',
            SL: 'Syerra-Leone',
            SG: 'Sinqapur',
            SK: 'Slovakiya',
            SI: 'Sloveniya',
            SB: 'Solomon adaları',
            SO: 'Somali',
            ZA: 'Cənubi Afrika Respublikası',
            GS: 'Cənubi Georgiya və Sandviç adaları',
            ES: 'İspaniya',
            LK: 'Şri-Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard və Jan Mayen',
            SZ: 'Svazilend',
            SE: 'İsveç',
            CH: 'İsveçrə',
            SY: 'Suriya Ərəb Respublikası',
            TW: 'Tayvan',
            TJ: 'Tacikistan',
            TZ: 'Tanzaniya',
            TH: 'Tayland',
            TL: 'Şərqi Timor',
            TG: 'Toqo',
            TK: 'Tokelau',
            TO: 'Tonqa',
            TT: 'Trinidad və Tobaqo',
            TN: 'Tunis',
            TR: 'Türkiyə',
            TM: 'Türkmənistan',
            TC: 'Törks və Kaykos adaları',
            TV: 'Tuvalu',
            UG: 'Uqanda',
            UA: 'Ukrayna',
            AE: 'Birləşmiş Ərəb Əmirlikləri',
            GB: 'Birləşmiş Krallıq',
            US: 'Amerika Birləşmiş Ştatları',
            UM: 'Birləşmiş Ştatların xarici adaları',
            UY: 'Uruqvay',
            UZ: 'Özbəkistan',
            VU: 'Vanuatu',
            VE: 'Venesuela',
            VN: 'Vyetnam',
            VG: 'Britaniya Vircin adaları ',
            VI: 'ABŞ Vircin adaları',
            WF: 'Uollis və Futuna',
            EH: 'Qərbi Sahara',
            YE: 'Yəmən',
            ZM: 'Zambiya',
            ZW: 'Zimbabve'
        },
    },
    tr: {
        _name: 'Türkçe',
        timePickerModal: {
            title: 'Close In Time'
        },
        newDesign: {
            withdrawalRequests: 'No withdrawal requests yet',
            withdrawalRequestsText1: 'You haven\'t made any withdrawal requests so far.',
            withdrawalRequestsText2: 'Once you initiate a withdrawal, your transaction details will appear here',
            pdfAttachment: 'PDF attachment',
            agent: "Agent",
            closed: 'Closed',
            pending: 'Pending',
            approved: 'Approved',
            declined: 'Declined',
            document: 'Belge',
            frontSide: 'Front Side',
            backSide: 'Back Side',
            address: 'Address',
            frontSideDocument: 'Front side of the document',
            creditCard: 'Credit Card',
            selfie: 'Selfie',
            other: 'Other',
            noDocument: 'No documents uploaded yet',
            amountDeposited: 'Amount deposited',
            makeTransfer: 'Make Transfer',
            otherDetails: 'Order details',
            manualWallets: 'Kripto cüzdanlar',
            depositText: 'Yatırım işlemi 5-10 dakika içinde tamamlanır',
            pendingBankTransfer: 'You have a pending bank transfer deposit.',
            completeDeposit: 'Please follow the instructions below to complete your deposit',
            downloadInvoice: 'Download the invoice',
            payInvoice: 'Pay the invoice in your bank',
            useYourBankText1: 'Use your bank’s online or offline payment system to pay the invoice.',
            useYourBankText2: 'Ensure the payment details are correct.',
            uploadReceipt: 'Upload the confirmation receipt',
            afterMakingPayment: 'After making the payment, upload your receipt to confirm the deposit',
            clickToUpload: 'Click to Upload',
            orDrag: 'or drag and drop',
            fileFormat: 'PDF, JPG, JPEG, PNG less than 10MB',
            waitForDeposit: 'Wait for deposit confirmation',
            onceReceipt: 'Once the receipt is uploaded, we\'ll verify and approve your deposit',
            paymentDetails: 'Payment details',
            nameOnCard: 'Name on card',
            cardNumber: 'Card number',
            expirationDate: 'Expiration date',
            securityCode: 'Security code',
            continue: 'Continue',
            viewAccounts: 'View Accounts',
            continueWithdrawal: 'Continue Withdrawal',
            accountBalance: 'Account Balance',
            accountBalanceText1: 'Why can’t I place a trade?',
            accountsBalanceText2: 'How do I deposit funds?',
            accountsBalanceText3: 'Zelle ile para yatırmak istiyorum',
            tradingActions: 'Trading Actions',
            tradingActionsText1: 'How do I short sell?',
            tradingActionsText2: 'How can I cancel a trade?',
            tradingActionsText3: 'What is the minimum trade amount?',
            marketInfo: 'Market Info',
            marketInfoText1: 'Where can I see market trends?',
            marketInfoText2: 'What are the top gainers today?',
            securityAndSettings: 'Security and Settings',
            securityAndSettingsText1: 'How do I change my password?',
            securityAndSettingsText2: 'What are the security features?',
            securityAndSettingsText3: 'How do I update my account info?',
            messageCopied: 'Message copied to clipboard',
            addSymbol: 'Add Symbol',
            iHaveRead: 'I have read and agree to the terms and conditions',
            toggleToTurn: 'Toggle to turn on AutoTrade',
            selectTheRisk: 'Select the risk level',
            confirm: 'Confirm',
            cancel: 'Cancel',
            chooseClosingTime: 'Choose a closing time',
            setOrderClosingTime: 'Set Order Closing Time',
            closingTime: 'Closing Time',
            date: 'Date',
            time: 'Time',
            setCloseTime: 'Set Close Time',
            '15m': '15 Minutes',
            '30m': '30 Minutes',
            '1h': '1 hour',
            custom: 'Custom',
            verifyOtp: 'Verify Your OTP',
            enterDigits: 'Enter the 6-digit code sent to your [email/phone]',
            submit: 'Submit',
            order: 'Order',
            closedSuccessfully: 'numaralı sipariş başarıyla kapatıldı',
            openSuccessfully: 'open successfully',
            deposit: 'deposit',
            withdrawal: 'withdrawal',
            completeVerification: 'Complete Verification',
            verification: 'Verification',
            avatar: 'Avatar',
            saveChanges: 'Save Changes',
            darkMode: 'Dark mode',
            dashboardCurrency: 'Dashboard Currency',
            language: 'Language',
            twoFactor: '2-factor Protection',
            googleTwoFactorIsActive: 'Google 2-factor authenticator is active',
            activateCodeForGoogle: 'Activate code for Google Authenticator',
            ifYouWishDisableEnterCode: 'If you wish to disable protection, enter the authenticator app code',
            scanOrManuallyEnter: 'Scan or manually enter the QR code in Google 2FA to add a token',
            copyCode: 'Copy Code',
            qrCode: 'QR Code',
            activateAndEnterAuthenticator: 'Activate Authenticator and enter a generated code in field below',
            enterTheDigitsCodeGenerated: 'Enter the 6-digit code generated',
            disableTwoFactor: 'Disable Two-Factor Protection',
            activateTwoFactor: 'Activate 2FA Protection',
            fileAdded: 'File successfully added',
            theVerificationStatus: 'The verification status can be viewed in the list of uploaded documents',
            confirmWithdrawal: 'Para çekmeyi onayla',
            minimumWithdrawal: 'Minimum withdrawal amount is',
            welcomeToTradingPlatform: 'Welcome to the trading platform',
            signIn: 'Sign In',
            activeAccount: 'Active account',
            save: 'Save',
            selectCurrencyAndPeriod: 'Bir para birimi ve abonelik süresi seçin',
            savingsNotFound: 'Savings Accounts Not Found',
            pleaseCheckInvestment: 'Please check your investment details to continue',
            allPeriodInvestment: 'All period investment',
            pct_0: 'Flexible',
            pct_30: '1 Month',
            pct_60: '1 Quarter',
            pct_90: '6 Month',
            pct_180: '6 Month',
            pct_365: '1 Year',
            savingsCreated: 'Savings account created successfully',
            transferDetails: 'Transfer Details',
            transferAmount: 'Transfer Amount',
            investmentDetails: 'Yatırım Detayları',
            investment: 'Investment',
            Period: 'Period',
            created: 'Created',
            releaseTime: 'Release time',
            viewSavingsAccounts: 'View Savings Accounts',
            continueInvestment: 'Continue Investment',
            chooseSubscription: 'Aboneliğinizi Seçin',
            selectCurrencyAndSubscription: 'Devam etmek için bir para birimi ve abonelik süresi seçin.',
            rate: 'Rate',
            accountTransferFunds: 'Account to transfer funds from',
            amount: 'Tutar',
            'in': 'in',
            openSavingsAndInvest: 'Birikim Hesabı Aç ve Yatırım Yap',
            strongSell: 'Strong sell',
            sell: 'Sell',
            neutral: 'Neutral',
            buy: 'Buy',
            stringBuy: 'Strong buy',
            chooseCryptoWallet: 'Choose a Wallet',
            chooseMethod: 'Bir yöntem seçin',
            maximumDeposit: 'Maximum deposit is',
            minimumDeposit: "Minimum depozito",
            pleaseCompleteAddress: 'Please complete your address',
            pleaseCompleteCity: 'Please complete your city',
            pleaseCompleteCountry: 'Please complete your country',
            pleaseCompleteZip: 'Please complete your ZIP code',
            amountDeposit: 'Amount deposit',
            transactionStatus: 'Transaction status',
            paymentMethod: 'Payment method',
            depositTo: 'Deposit to',
            depositAddress: 'Deposit Address',
            scanTheQr: 'Scan the QR code or copy the deposit address',
            wallet: 'Wallet',
            copyDepositAddress: 'Copy Deposit Address',
            copy: 'Kopyala',
            addressCopied: 'Address copied to clipboard',
            '5min:': '5-10 min',
            '2-5days': '2-5 days / 5-10 min',
            otherMethod: 'Other method',
            profileSettings: 'Profile Settings',
            savingsAccounts: 'Mevduat hesapları',
            subscribe: 'Abone Ol',
            toCompleteVerification: 'To complete the verification process for your account, please provide clear and legible copies of the following documents',
            amountToWithdrawal: 'Amount to withdrawal',
            cardDetails: 'Card details',
            cardHolderName: 'Card holder name',
            withdrawalSuccessful: 'Withdrawal Successful',
            selectWithdrawalMethod: 'Select withdrawal method',
            creditDebit: 'Kredi/Banka Kartı',
            crypto: 'Crypto',
            cryptoWalletAddress: 'Crypto wallet address',
            cryptoSubtitle: 'Cüzdanınıza kripto çekimleri 🚀',
            bankWithdrawal: 'Banka Çekimi',
            bankSubtitle: 'Banka hesabı 🏦',
            zelleSubtitle: 'Hızlı banka transferleri ⚡🏦',
            tradingResults: 'Trading results',
            day: 'Day',
            month: 'Month',
            noTradingData: 'No trading data available',
            startTradingToSee: 'Start trading to see your results here.',
            yourPerformanceMetric: 'Your performance metrics will be displayed once data is available.',
            favorite: 'Favorite',
            all: 'All',
            availableFullTime: 'Available 24/7',
            yourProfileAndSettings: 'Your profile and settings',
            switchToNewDesignMessage: 'Switch to Our New Platform Design Now!',
            switchToOldDesignMessage: 'You can still use the classic design for a limited time',
            switchToOldDesignButton: 'Switch to New Design',
            switchToNewDesignButton: 'Revert to Classic Design',
            howCanIAssist: 'How can I assist you today?',
            generateInvoice: 'Generate Invoice',
            downloadPdf: 'Download PDF',
            confirmDeposit: 'Confirm Deposit',
            maxFileSize: 'The maximum file size is 5MB, and accepted formats are JPEG, PNG, or GIF',
            uploadPhoto: 'Upload Photo',
            featuresDisabled: 'This feature is disabled',
            welcome: 'Welcome',
            signUp: 'Sign Up',
            registration: 'Registration',
            pleaseConfirmAgreement: 'Please confirm customer agreement',
            pleaseEnterPassword: 'Please enter a password',
            passwordNotSame: 'Passwords are not the same',
            noActiveOrders: 'No Active Orders',
            itLikeNoHaveActiveOrders: 'It looks like you don\'t have any active orders at the moment',
            cryptoWallet: 'Crypto wallet',
            bankCard: 'Bank/Card',
            selected: 'Selected',
            amountWithdrawal: 'Amount withdrawn',
            withdrawalTo: 'Withdrawal to',
            governmentIssuedPhoto1: 'Devlet tarafından verilen fotoğraflı kimlik (örn. pasaport, sürücü belgesi, ulusal kimlik kartı)',
            governmentIssuedPhoto2: 'Oturma izni (varsa)',
            governmentIssuedPhoto3: 'Belge geçerli olmalıdır (süresi dolmamış)',
            governmentIssuedPhoto4: 'Tam ad, doğum tarihi ve fotoğraf görünür olmalıdır.',
            uploadRecent1: 'Son 3 ay içinde düzenlenmiş bir fatura, banka ekstresi veya kira sözleşmesini yükleyin',
            uploadRecent2: 'Tam adınızın ve mevcut ikamet adresinizin net bir şekilde görünür olduğundan emin olun.',
            uploadCopy1: 'Kredi kartınızın ön yüzünün bir kopyasını yükleyin, ilk altı ve son dört hanenin görünür olduğundan emin olun',
            uploadCopy2: 'Orta haneleri güvenlik için kapatın',
            takeAndUpload1: 'Kimliğinizi yüzünüzün yanında tutarak bir selfie çekin ve yükleyin',
            takeAndUpload2: 'Hem yüzünüzün hem de kimliğinizin net bir şekilde göründüğünden emin olun',
            otherDocuments: 'Diğer belgeler',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Sosyal Güvenlik Numarası (SSN) belgenizin net ve okunaklı bir görüntüsünü yükleyin. Tüm ayrıntıların görünür ve engellenmemiş olduğundan emin olun.',
            frontSideSsnDocument: 'SSN belgesinin ön yüzü',
            answerSaved: 'Cevaplar kaydedildi',
            cryptoWalletDetails: 'Kripto cüzdan bilgileri',
            bitcoinWalletAddress: 'Bitcoin cüzdan adresi',
            recovery: 'Kurtarma',
            passwordRecovery: 'Şifre kurtarma',
            searchCountry: 'Ülke ara',
            questionnaire: 'Anket',
            questionnaireText: 'Lütfen kimliğinizi doğrulamak için anketi doldurun',
            sendAnswers: 'Cevapları Gönder',
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Kârı Al ve Zararı Durdur',
            takeProfit: 'Kârı Al',
            stopLoss: 'Zararı Durdur',
            updateOrder: 'Emri Güncelle',
        },
        balanceModal: {
            balance: 'Bakiye',
            credit: 'Kredi',
            equity: 'Özkaynak',
            practice: 'PRATİK',
            real: 'GERÇEK',
            account: 'HESAP',
            invested: 'Yatırıldı',
            profit: 'Kâr',
            loss: 'Zarar',
            margin: 'Marjin',
            marginLevel: 'Marjin Seviyesi',
            marginFree: 'Serbest Marjin'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Para Yatır'
        },
        sideMenu: {
            marketWatch: 'Piyasa Takibi',
            activeOrders: 'Aktif Emirler',
            tradingHistory: 'İşlem Geçmişi',
            economicCalendar: 'Takvim',
            marketNews: 'Piyasa Haberleri'
        },
        closePositionModal: {
            closeOrder: 'Pozisyonu Kapat',
            areYouSure: 'Pozisyonu kapatmak istediğinizden emin misiniz',
            buy: 'Satın Al',
            sell: 'Sat',
            yes: 'Evet',
            no: 'Hayır',
        },
        pciDssModal: {
            desc: `Ödeme Kartı Endüstrisi Veri Güvenliği Standartları (PCI DSS) uyumluluğu, Visa/Master Card/Stripe kart sahibi verilerini depolayan, işleyen veya ileten tüm kuruluşlardan, finansal kuruluşlardan, satıcılar ve hizmet sağlayıcılardan istenir. PCI DSS, kart sahibi verilerini ve/veya hassas kimlik doğrulama verilerini depolayan, işleyen veya ileten tüm kuruluşlar için küresel güvenlik standartıdır. PCI DSS, tüketiciler için bir taban seviyesi koruma belirler ve tüm ödeme ekosistemi genelinde dolandırıcılığı ve veri ihlallerini azaltmaya yardımcı olur.`
        },
        chart: {
            addNewChart: 'Yeni Grafik Ekle',
        },
        symbolsModal: {
            watchlist: 'Takip Listesi',
            asset: 'Varlık',
            price: 'Fiyat',
            changePct: '24s Değişim'
        },
        pendingModal: {
            pending: 'Beklemede',
            assetPrice: 'Varlık Fiyatı',
            current: 'Güncel',
            revert: 'Piyasa Fiyatına Geri Dön',
            automatically: 'Fiyat bu seviyeye ulaştığında pozisyon otomatik olarak açılacak'
        },
        orderMenu: {
            default: 'varsayılan',
            volume: 'Hacim',
            lots: 'lot',
            units: 'birim',
            currency: 'para birimi',
            contractSize: 'Sözleşme büyüklüğü',
            position: 'Pozisyon',
            margin: 'Marjin',
            freeMargin: 'Serbest Marjin',
            takeProfitStopLoss: 'Kârı Al ve Zararı Durdur',
            pending: 'Beklemede',
            market: 'Piyasa',
            leverage: 'Kaldıraç',
            spread: 'Yayılım',
            notSet: 'Ayarlanmadı',
            at: 'saat',
            buy: 'satın al',
            sell: 'sat',
        },
        footer: {
            supportBanner: 'HER GÜN, HER SAAT',
            currentTime: 'GÜNCEL SAAT',
            liveChat: 'Canlı Sohbet'
        },
        statusBar: {
            balance: 'Bakiye',
            credit: 'Kredi',
            equity: 'Özkaynak',
            margin: 'Marjin',
            marginLevel: 'Marjin seviyesi',
            freeMargin: 'Serbest Marjin',
            pnl: 'PnL',
            profitTotal: 'Ömür Boyu PnL'
        },
        accountModal: {
            dateRegistered: 'Kayıt Tarihi',
            userId: 'Kullanıcı ID`si',
            dashboard: 'Pano',
            personalData: 'Kişisel Veriler',
            deposit: 'Para Yatır',
            withdrawFunds: 'Para Çek',
            savings: 'Birikimler',
            settings: 'Ayarlar',
            logout: 'Çıkış Yap'
        },
        depositModal: {
            BT_INVOICE: 'Tutarı girin ve faturayı oluşturun',
            BT_TITLE: 'Banka bilgilerimiz',
            account: 'Hesap',
            bank_name: 'Banka Adı',
            beneficiary: 'Lehdar',
            beneficiary_address: 'Lehdar Adresi',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Tutar',
            TOKEN_SUBTOTAL: 'Ara Toplam',
            TOKEN_TOTAL: 'Toplam',
            TOKEN_DESCRIPTION: 'Açıklama',
            TOKEN_PAYMENT_DETAILS: 'Ödeme Detayları',
            PENDING_DEPOSITS_LIMIT: 'Para Yatırma Limiti',
            makeDeposit: 'Para Yatır',
            practiceAccount: 'Pratik Hesabı',
            realAccount: 'Gerçek Hesap',
            noWithdrawals: 'Para Çekme Yok',
            easyWithdrawals: 'Kolay Para Çekme',
            allAssets: 'Tüm varlıklar mevcut',
            fullFledged: 'Tam teşekküllü platform',
            fillUpTo: 'Maksimum Doldurma',
            freeReplenishment: 'Ücretsiz yükleme',
            topUp: 'Hesabınıza Para Yükleyin',
            minimumAmount: 'Minimum tutar',
            canSwitch: 'Hesaplarınız arasında istediğiniz zaman geçiş yapabilirsiniz'
        },
        gridsModal: {
            chartGrids: 'GRAFİK ÇİZELGELERİ',
            chart1: '1 grafik',
            chart2: '2 grafik',
            chart3: '3 grafik',
            chart4: '4 grafik',
        },
        noConnectionModal: {
            connectionLost: 'Sunucu ile bağlantı kesildi',
            retryNow: 'Şimdi Tekrar Dene',
        },
        loginModal: {
            loginToTradeRoom: 'Traderoom`a Giriş Yapın',
            email: 'E-posta adresi',
            enterEmail: 'E-posta adresinizi girin',
        enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA Kodu (etkinleştirildiyse)',
            twoFactorAuth: 'İki faktörlü kimlik doğrulama',
            password: 'Şifre',
            yourPassword: 'Şifreniz',
            signIn: 'Giriş Yap',
            dontHaveAccount: 'Hesabınız yok mu?',
            restore: 'Geri Yükle',
            fullName: 'Ad Soyad',
            havePromo: 'Bir promosyon kodum var',
            promo: 'Promosyon kodu',
            login: 'Giriş Yap',
            haveAccount: 'Zaten bir hesabınız var mı?',
            hide: 'Gizle',
            goTo: 'Geri dön'
        },
        newPasswordModal: {
            setNewPassword: 'Yeni Şifre Belirle',
            newPassword: 'Yeni şifre',
            confirmNewPassword: 'Yeni şifreyi onayla',
            setButton: 'Ayarla'
        },
        registerModal: {
            repeatPassword: 'Şifreyi Tekrarla',
            createNewAccount: 'Yeni Bir Hesap Oluşturun',
            email: 'E-posta adresi',
            firstName: 'Ad',
            lastName: 'Soyadı',
            country: 'Ülke',
            phone: 'Telefon',
            password: 'Şifre',
            createAccount: 'Hesap Oluştur',
            currency: 'Para Birimi',
            privacyAndPolicy: "Gizlilik ve Politika",
            customerAgreement: 'Müşteri anlaşması',

        },
        forgotPasswordModal: {
            forgotPassword: 'Şifrenizi mi unuttunuz?',
            submitEmail: 'Lütfen kayıt olurken kullandığınız e-postayı yazın, gelen kutunuzu kontrol edin ve verilen talimatları izleyin',
            submit: 'Gönder'
        },
        notifications: {
            error: 'Hata',
            success: 'Başarılı',
            deposit: 'Para Yatır',
            withdrawal: 'Para Çek',
            depositApproved: 'işlemi onaylandı',
            depositDeclined: 'Para yatırma işlemi reddedildi',
            withdrawalApproved: 'Para çekme işlemi onaylandı',
            withdrawalDeclined: 'Para çekme işlemi reddedildi'
        },
        markets: {
            favorites: 'İzleme listesi', 
            all: 'Tüm semboller',
            forex: 'Forex',
            stocks: 'Hisseler',
            commodities: 'Emtialar',
            indices: 'Endeksler',
            crypto: 'Kripto',
            metals: 'Metaller',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'AL',
            profitCalculator: 'Kâr Hesaplayıcı',
            sell: 'SAT',
            volume: 'Hacim',
            entryPrice: 'Giriş Fiyatı',
            takeProfit: 'Kârı Al',
            stopLoss: 'Zararı Durdur',
            maxPosition: 'Maks Pozisyon',
            calculate: 'Hesapla',
            calculationsFor: 'Hesaplama sonucu',
            leverage: 'Kaldıraç',
            requiredMargin: 'Gerekli Marjin',
            profitFromTP: 'TP`den kâr',
            lossFromSL: 'SL`den zarar',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Para yatırma başarılı',
            text: 'Para yatırma işleminiz başarıyla tamamlandı!',
        },
        depositFailModal: {
            title: 'Para yatırma başarısız',
            text: 'Para yatırma işleminiz gerçekleştirilmedi.',
        },
        widgetMarketWatch: {
            marketWatch: 'Piyasa Takibi',
            search: 'Ara...',
        },
        widgetOrders: {
            commission: 'Komisyon',
            volume: 'Hacim',
            margin: 'Marjin',
            active: 'Aktif',
            pending: 'Beklemede',
            activeOrders: 'Aktif Emirler',
            portfolio: 'Portföy',
            allPositions: 'Tüm Pozisyonlar',
            noPositionsLine1: 'Henüz hiç açık',
            noPositionsLine2: 'pozisyonunuz yok',
            show: 'Göster',
            more: 'daha fazla',
            less: 'daha az',
            purchaseTime: 'Satın Alma Zamanı',
            closePosition: 'Pozisyonu Kapat',
            priceOpen: 'Satın Alma Fiyatı',
            priceSl: 'Zararı Durdurma Fiyatı',
            priceTp: 'Kârı Alma Fiyatı',
            type: 'Pozisyon Yönü',
            pnl: 'Kâr/Zarar',
            cancelOrder: 'Emiri İptal Et',
            orderType: 'Emir Türü',
            cancelled: 'İptal Edildi',
            tradingHistory: 'İşlem Geçmişi',
            noHistoryLine1: 'Henüz hiç',
            noHistoryLine2: 'kapalı anlaşmanız yok',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Takvim'
        },
        widgetMarketNews: {
            marketNews: 'Piyasa Haberleri'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Aktif Emirler',
            ordersHistory: 'Emir Geçmişi',
            id: 'Kimlik',
            symbol: 'Sembol',
            type: 'Tür',
            volume: 'Hacim',
            openPrice: 'Açılış Fiyatı',
            openTime: 'Açılış Zamanı',
            closePrice: 'Kapanış Fiyatı',
            closeTime: 'Kapanış Zamanı',
            sl: 'SL',
            tp: 'TP',
            price: 'Fiyat',
            pnl: 'PnL',
            actions: 'Eylemler',
            edit: 'Düzenle',
            close: 'Kapat',
            commission: 'Komisyon',
            swap: 'Takas',
            swapLong: 'Uzun Takas',
            swapShort: 'Kısa Takas'
        },
        assetInfoModal: {
            assetInfo: 'Varlık Bilgisi',
            tradingConditions: 'İşlem Koşulları',
            information: 'Oturum Değişikliği',
            bid: 'Teklif',
            ask: 'İste',
            sessionChange: 'Oturum Değişikliği',
            tradeNow: 'Şimdi Al-Sat',
            opens: 'Açıklar',
            closes: 'Kapalılar',
            at: 'saat',
            open247: '7/24 açık',
            today: 'bugün',
            tomorrow: 'yarın',
            sunday: 'Pazar',
            monday: 'Pazartesi',
            tuesday: 'Salı',
            wednesday: 'Çarşamba',
            thursday: 'Perşembe',
            friday: 'Cuma',
            saturday: 'Cumartesi',
            contractSpecification: 'Sözleşme Özellikleri',
            symbol: 'Sembol',
            name: 'Adı',
            market: 'Piyasa',
            baseCurrency: 'Baz Para Birimi',
            digits: 'Basamak',
            lotSize: 'Lot Boyutu',
            lotStep: 'Lot Adımı',
            minVolume: 'Min Hacim',
            maxVolume: 'Maks Hacim',
            leverage: 'Kaldıraç',
            commission: 'Komisyon',
            swapLong: 'Uzun Takas',
            swapShort: 'Kısa Takas',
            schedule: 'Çizelge',
            weekday: 'Hafta içi',
            tradingTime: 'İşlem Süresi',
            closed: 'kapalı',
            sell: 'Sat',
            buy: 'Al',
            low: 'Düşük',
            high: 'Yüksek',
            oneHourChange: '1 saatlik değişim',
            oneDayChange: '1 günlük değişim',
            oneWeekChange: '1 haftalık değişim',
            oneMonthChange: '1 aylık değişim',
            threeMonthsChange: '3 aylık değişim',
            oneYearChange: '1 yıllık değişim',
            loading: 'Yükleniyor...',
        },
        autoTrader: {
            days: 'days',
            title: 'Oto Al-Sat',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Seviyesi: Düşük',
            riskLevelMedium: 'Risk Seviyesi: Orta',
            riskLevelHigh: 'Risk Seviyesi: Yüksek',
            perDay: 'Günlük işlem sayısı:',
            desc: `CFD'ler ve diğer kaldıraçlı ürünlerle işlem yapmak zarara yol açabilir. Müşteriler işlem yapmadan önce
                Risk Bildirimi sayfamızdaki ilgili risk bildirimlerini mutlaka okumalıdır. Otomatik alım satım sonuç garantisi vermez. Şirket, otomatik işlemlerde fon kaybı konusunda hiçbir sorumluluk kabul etmez. Lütfen riskleri tam olarak anladığınızdan ve risk yönetimi için önlemler aldığınızdan emin olun.`,
            accept: 'Kabul Et',
            save: 'Kaydet',
            cancel: 'İptal',
        },
        cardBox: {
            name: 'Ad',
            cardNumber: 'Kart Numarası',
            expiration: 'Son kullanma tarihi (aa/gg)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Al-Sat",
            startTrading: 'Alım Satıma Başla',
            deposit: 'Para Yatırma',
            dashboard: 'Pano',
            personalInfo: 'Kişisel Bilgiler',
            contactInfo: 'İletişim Bilgileri',
            withdrawal: 'Para Çekme',
            verification: 'Doğrulama',
            accounts: 'Hesaplar',
            liveChat: 'Canlı Sohbet',
            savings: 'Birikimler',
            referrals: 'Yönlendirme programı',
            settings: 'Ayarlar',
            logOut: 'Çıkış Yap',
        },
        fullScreenDeposit: {
            methods: 'YÖNTEMLER',
            confirm: 'Bankanızın uygulamasında onaylayın',
            sendOtp: 'Otp gönder',
            otpCode: 'Otp kodu',
            addWalletAddress: 'Bu para yatırma yöntemi için cüzdan ayarlanmadı. Lütfen temsilcinizle veya destek ekibinizle iletişime geçin.',
            addressReceipt: 'Lütfen adresin yüklenmesini bekleyin',
            makeDeposit: 'PARA YATIRIN',
            instant: 'Anında',
            minutes: 'dakika',
            hours: 'saat',
            days: 'gün',
            amount: 'Tutar',
            continue: 'Devam Et',
            qrCode: 'QR KODU',
            depositAddress: 'YATIRMA ADRESİ',
            copy: 'Kopyala',
            last: 'SONUNCU',
            deposits: 'YATIRMA İŞLEMLERİ',
            time: 'Zaman',
            currency: 'Para Birimi',
            status: 'Durum',
            info: 'Bilgi',
        },
        fullScreenCcExt: {
            pleaseWait: 'Lütfen bekleyin. Ödeme hizmeti sağlayıcısına bağlanılıyor...',
            cardPayment: 'Kartlı ödeme',
            inOrder: 'Tutarı göndermek için',
            pleaseProvide: 'lütfen kart bilgilerinizi girin:'
        },
        fullScreenDashboard: {
            completeVerification: 'Lütfen Doğrulamayı Tamamlayın',
            noOrderYet: 'Henüz emir yok',
            totalBalance: 'Toplam Bakiye',
            totalPnl: 'Toplam PNL',
            profitableOrders: 'Kârlı Emirler',
            roi: 'ROI',
            activityLog: 'Aktivite Günlüğü',
            loginFromIp: 'IP`den giriş',
            tradingResults: 'Al-Sat Sonuçları',
            week: 'Hafta',
            month: 'Ay',
            year: 'Yıl',
            successRate: 'Başarı oranı',
            closedWithProfit: 'Kârla Kapandı',
            closedWithLoss: 'Zararla Kapandı',
            account: 'Hesap',
            balance: 'Bakiye',
            leverage: 'Kaldıraç',
            credit: 'Kredi',
            tradeNow: 'Şimdi Al-Sat',
            usingCurrentRate: 'güncel döviz kurunu kullanarak',
            totalDeposits: 'Toplam Yatırma',
            totalWithdrawals: "Toplam Çekimler",
            returnOnInvestment: "Yatırım Getirisi"
        },
        fullScreenPersonal: {
            personalDetails: 'Kişisel Bilgiler',
            profilePhoto: 'Profil Fotoğrafı',
            firstName: 'Ad',
            country: 'Ülke',
            lastName: 'Soyadı',
            address: 'Adres',
            email: 'E-posta',
            phone: 'Telefon numarası',
            saveChanges: 'Değişiklikleri Kaydet',
            state: 'Şehir',
            postcode: 'Posta kodu',
            created: "Hesap oluşturuldu",
        },
        fullScreenAvatar: {
            dropFile: 'Yüklemek için yukarıdaki dairenin üzerine bir dosya bırakın',
            notAllowed: 'Aşağıdakilerin yayınlanmasına izin verilmez;',
            notAllowedLine1: 'Açıkça cinsel veya pornografik nitelikteki fotoğraflar',
            notAllowedLine2: 'Etnik veya ırkçı nefret veya saldırganlığı kışkırtmaya yönelik görüntüler',
            notAllowedLine3: 'On sekiz yaşından küçük kişilerin yer aldığı fotoğraflar',
            notAllowedLine4: 'Üçüncü taraf telif hakkı ile korunan fotoğraflar',
            notAllowedLine5: '5 MB`den büyük olan ve JPG, GIF veya PNG haricindeki bir formatta olan görüntüler',
            requirements: 'Yüzünüz fotoğrafta açıkça görülebilir olmalıdır. Yüklediğiniz tüm fotoğraf ve videolar bu şartlara uygun olmalıdır, aksi takdirde kaldırılabilirler.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Para çekme ile ilgili detaylar için lütfen hesap yöneticinizle iletişime geçin',
            cryptocurrency: 'Kripto Para',
            withdrawal: 'Para Çekme',
            requestNewWithdrawal: 'YENİ BİR PARA ÇEKME TALEBİNDE BULUNUN',
            amount: 'Tutar',
            currentBalance: 'Güncel hesap bakiyeniz',
            withdrawAll: 'Hepsini çek',
            requestWithdrawal: 'Para Çekme Talebinde Bulunun',
            last: 'SON',
            withdrawalRequests: 'PARA ÇEKME TALEBİ',
            time: 'Zaman',
            currency: 'Para Birimi',
            info: 'Bilgi',
            status: 'Durum',
            bank: 'Banka',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Kart',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Para Çekme Detayları',
            address: 'Cüzdan Adresi',
            bankDetails: 'Banka bilgileri',
            bankAccount: 'Hesap Numarası',
            bankHolder: 'Hesap Sahibinin Adı',
            bankIban: 'IBAN',
            bankSwift: 'Banka SWIFT kodu',
            recipientAddress: 'Alıcının Adresi',
            routingNumber: 'Banka yönlendirme numarası',
            bankBeneficiary: 'Lehdarın bankasının adı',
            bankAddress: 'Banka Adresi',
            bankCode: 'Banka kodu (BSB)',
            desc: 'Açıklama',
            cardNumber: 'Kart Numarası',
            cardHolder: 'Kart Sahibi Adı',
        },
        fullScreenVerification: {
            dragAndDrop: 'belgeyi bu alana sürükleyip bırakın',
            documentsList: 'YÜKLENEN BELGELERİN LİSTESİ',
            document: 'Belge',
            timeUploaded: 'Yüklenme Zamanı',
            timeProcessed: 'İşlenme Zamanı',
            status: 'Durum',
            types: ['Kimlik Kanıtı', 'İkametgâh Kanıtı', 'Kredi Kartı Ön Yüzü', 'Kredi Kartı Arka Yüzü', 'Kimlik Arka Yüzünün Kanıtı', 'Özçekim', 'Other'],
            types2: ['Kimlik kanıtı (POI)', 'İkametgah adresinin kanıtı', 'Kredi Kartı Ön Yüzü', 'Kredi Kartı Arka Yüzü', 'Kimlik Arka Yüzünün Kanıtı', 'Kimlik ile Özçekim'],
            upload: 'Yükleme',
            isConfirmed: 'onaylandı',
            uploadAnotherDocument: 'Başka bir belge yükleyin',
            isUnderReview: 'inceleme altında',
            questionnaire: 'Anket',
            sendAnswers: 'Cevap Gönder',
            progressBarOf: '/',
            progressBarText: 'belgeniz yüklendi ve onaylandı'
        },
        fullScreenAccounts: {
            manageAccounts: 'Hesaplarınız arasında para transferi yapın',
            transferFrom: 'Hesaptan',
            transferTo: 'Hesaba',
            amount: 'Tutar',
            makeTransfer: 'Transfer Yap',
            yourAccounts: 'Hesaplarınız',
            account: 'Hesap',
            currency: 'Para Birimi',
            balance: 'Bakiye',
            credit: 'Kredi',
            makeActive: 'Etkinleştirin',
            active: 'Etkin'
        },
        fullScreenSupport: {
            sendMessage: 'Mesaj Gönder'
        },
        fullScreenSavings: {
            pct_0: 'Esnek',
            pct_30: '1 Ay Kilitli',
            pct_90: '3 Ay Kilitli',
            pct_180: '6 Ay Kilitli',
            pct_365: '1 Yıl Kilitli',
            savings: 'Birikimler',
            detailedInformation: 'Detaylı Bilgi',
            pleaseSelect: 'Lütfen bir para birimi ve bir dönem seçin',
            openSavings: 'Birikimleri Aç',
            currentTime: 'Güncel Zaman',
            releaseTime: 'Çıkış Zamanı',
            currencyChosen: 'Seçilen Para Birimi',
            periodChosen: 'Seçilen Dönem',
            yourRate: 'Oranınız',
            yourEarnings: 'Kazancınız',
            accountFrom: 'Fon aktarılacak hesap',
            enterAmount: 'Tutar Girin',
            in: 'zaman',
            openAndInvest: 'Birikim Hesabı açın ve yatırım yapın',
            investment: 'Yatırım',
            period: 'Dönem',
            daysTotal: 'Toplam gün',
            finalEarnings: 'Nihai kazançlar',
            created: 'Oluşturuldu',
            daysElapsed: 'Geçen Günler',
            days: 'gün',
            earnings: 'Kazançlar',
            fundsReleasedIn: 'Fonların Serbest Bırakıldığı Zaman',
            claim: 'Al',
            name: "Ad",
        },
        fullScreenSettings: {
            updatePassword: 'ŞİFREYİ GÜNCELLE',
            currentPassword: 'Geçerli Şifre',
            newPassword: 'Yeni Şifre',
            changePassword: 'Şifre Değiştir',
            activationForGoogle: 'Google Authenticator için aktivasyon kodu',
            activateAndEnter: 'Authenticator`ı etkinleştirin ve oluşturulan kodu aşağıdaki alana girin',
            qrCode: 'QR Kodu',
            activateProtection: '2FA Korumasını Etkinleştir',
            protectionActive: '2FA koruması etkin',
            twoFactorCode: '2FA Kodu',
            disableProtection: '2FA Korumasını Devre Dışı Bırak',
            language: 'Dil',
            dashboardCurrency: 'Pano Para Birimi',
            confirmNewPassword: 'Yeni şifreyi onayla',
        },
        fullScreenReferrals: {
            yourLink: 'REFERANS BAĞLANTINIZ',
            referredFriends: 'YÖNLENDİRİLEN ARKADAŞLAR',
            id: 'ID',
            name: 'Adı',
            registered: 'Kayıt Ol',
            level: 'Seviye',
            payout: 'Ödeme',
            lastPayouts: 'SON YÖNLENDİRME ÖDEMESİ',
            time: 'Zaman',
            amount: 'Tutar',
            currency: 'Para Birimi',
            info: 'Bilgi',
            referralDesc:"Referans Programı, değerli yatırımcı topluluğumuzu genişlettiğiniz için sizi ödüllendirmek üzere tasarlanmıştır.",
            shareLink:"Bağlantıyı paylaşın:",
            subtitle:"Ona adınızı, e-posta adresinizi veya işlem kimliğinizi verin.",
            descSuccesfull:"Referansınız başarıyla kaydolduktan sonra, Finansal Danışmanınız onunla iletişime geçecek ve yatırım yolculuğunun ilk adımlarında ona en etkili ve profesyonel şekilde yardımcı olacaktır.",
            descTracked:"Tüm katkıları sizin referansınız altında izlenmektedir."
        },
        months: {
            Jan: 'Oca',
            Feb: 'Şub',
            Mar: 'Mar',
            Apr: 'Nis',
            May: 'May',
            Jun: 'Haz',
            Jul: 'Tem',
            Aug: 'Ağu',
            Sep: 'Eyl',
            Oct: 'Eki',
            Nov: 'Kas',
            Dec: 'Ara'
        },
        orderTypes: ['Al', 'Sat', 'Alım Limiti', 'Satış Limiti', 'Alış Stopu', 'Satış Stopu'],
        statuses: ['Beklemede', 'Onaylandı', 'Reddedildi', 'İşleniyor'],
        errors: {
            NOT_FOUND: 'Talep hatası',
            FORBIDDEN: 'Erişim reddedildi. Lütfen yeniden giriş yapın',
            SERVER_ERROR: 'İşlem başarısız oldu. Lütfen daha sonra tekrar deneyin',
            INVALID_ACCOUNT: 'Oturumun süresi doldu. Lütfen yeniden giriş yapın',
            INVALID_EMAIL: 'Geçersiz e-posta adresi',
            INVALID_PHONE: 'Geçersiz telefon numarası',
            INVALID_COUNTRY: 'Geçersiz ülke',
            INVALID_LOGIN: 'Geçersiz giriş bilgileri',
            USER_BLOCKED: 'Hesap engellendi. Lütfen destek ekibiyle iletişime geçin',
            USER_REGISTERED: 'Girilen e-posta veya telefon numarasıyla zaten kayıtlı bir kullanıcı var',
            INVALID_USER: 'Kullanıcı bulunamadı',
            REJECTED: 'Platform kısıtlamaları nedeniyle işleme izin verilmiyor',
            INVALID_OLD_PASSWORD: 'Eski şifre geçersiz',
            INVALID_SYMBOL: 'Geçersiz işlem sembolü',
            INVALID_SYMBOL_PRECISION: 'Geçersiz sembol doğruluğu',
            INVALID_FRAME: 'Geçersiz grafik çerçevesi',
            INVALID_CURRENCY: 'Geçersiz para birimi',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Geçersiz hesap işlem türü',
            INSUFFICIENT_FUNDS: 'Yetersiz fon',
            INVALID_WITHDRAWAL: 'Para çekme işlemi bulunamadı',
            INVALID_STATUS: 'Geçersiz durum',
            INVALID_FILE: 'Geçersiz dosya uzantısı veya dosya çok büyük',
            INVALID_DOCUMENT: 'Belge bulunamadı',
            INVALID_NAME: 'Geçersiz ad',
            INVALID_LOT_SIZE: 'Geçersiz lot büyüklüğü',
            INVALID_LOT_STEP: 'Geçersiz lot adımı',
            INVALID_MIN_VOLUME: 'Geçersiz minimum hacim',
            INVALID_MAX_VOLUME: 'Geçersiz maksimum hacim',
            INVALID_GROUP: 'Geçersiz grup',
            INVALID_SPREAD: 'Geçersiz yayılım',
            REQUEST_TIMEOUT: 'İstek zaman aşımına uğradı. Lütfen daha sonra tekrar deneyin',
            EXISTING_OPEN_ORDERS: 'Açık bir siparişiniz var, önce bu kullanıcı için siparişi kapatın ve tekrar deneyin.',
            INVALID_TYPE: 'Geçersiz emir türü',
            INVALID_VOLUME: 'Geçersiz emir hacmi',
            INVALID_SL: 'Geçersiz zarar durdurma değeri',
            INVALID_TP: 'Geçersiz kâr alma değeri',
            INVALID_PRICE: 'Geçersiz emir fiyatı',
            INVALID_EXPIRATION: 'Geçersiz emir süresi',
            NOT_ENOUGH_MARGIN: 'Yeterli marjim yok',
            INVALID_ORDER: 'Emir bulunamadı',
            MARKET_CLOSED: 'İşlem yapılamıyor. Lütfen tekrar deneyin',
            INVALID_BALANCE_TYPE: 'Geçersiz bakiye işlemi türü',
            INVALID_HASH: 'Geçersiz hash',
            HASH_EXPIRED: 'Şifre sıfırlama bağlantınızın süresi doldu. Lütfen yeni bir bağlantı talep edin',
            INVALID_CODE: 'Geçersiz 2FA kodu',
            CHAT_DISABLED: 'Destek sohbetine erişim kapatıldı',
            WITHDRAWAL_NOT_ALLOWED: 'Para çekme işlemlerine erişim kapatıldı',
            TRADING_DISABLED: 'Mevcut hesap için alım satım işlemi devre dışı bırakıldı',
            PENDING_DEPOSITS_LIMIT: 'Para yatırma limiti aşıldı',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Beklemede sadece bir para çekme işlemi olabilir',
            LOCAL_DEPOSIT_ONLY_REAL: 'Para yatırma işlemi yalnızca gerçek hesap için geçerlidir',
            LOCAL_DEPOSIT_POSITIVE: 'Para yatırma miktarı 0`dan fazla olmalıdır',
            LOCAL_INVALID_CARD_NUMBER: 'Geçersiz kart numarası',
            LOCAL_INVALID_CARD_EXPIRATION: 'Geçersiz kart son kullanma tarihi',
            LOCAL_INVALID_CVC: 'Geçersiz CVV/CVC kodu',
            LOCAL_PASSWORDS_NOT_MATCH: 'Şifreler eşleşmiyor, lütfen tekrar girin',
            LOCAL_NO_TRADING_ACCOUNTS: 'Kullanılabilir işlem hesabınız yok. Lütfen destek ile iletişime geçin',
            LOCAL_MIN_DEPOSIT: 'Minimum para yatırma $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum para çekme $',
            LOCAL_INVALID_FILE_TYPE: 'Dosya formatı desteklenmiyor. Lütfen yalnızca JPG, PNG veya PDF biçiminde dosya ekleyin',
            LOCAL_MIN_STAKING: 'Bu para birimi için minimum para yatırma tutarı ',
            FLOOD_REJECTED: 'Çok sık mesaj gönderiyorsunuz. Lütfen daha sonra tekrar deneyin'
        },
        countries: {
            AF: 'Afganistan',
            AX: 'Aland Adaları',
            AL: 'Arnavutluk',
            DZ: 'Cezayir',
            AS: 'Amerikan Samoası',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktika',
            AG: 'Antigua ve Barbuda',
            AR: 'Arjantin',
            AM: 'Ermenistan',
            AW: 'Aruba',
            AU: 'Avustralya',
            AT: 'Avusturya',
            AZ: 'Azerbaycan',
            BS: 'Bahamalar',
            BH: 'Bahreyn',
            BD: 'Bangladeş',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belçika',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivya',
            BA: 'Bosna Hersek',
            BW: 'Botsvana',
            BV: 'Bouvet Adası',
            BR: 'Brezilya',
            IO: 'Britanya Hint Okyanusu Toprakları',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaristan',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kamboçya',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Cape Verde',
            KY: 'Cayman Adaları',
            CF: 'Orta Afrika Cumhuriyeti',
            TD: 'Çad',
            CL: 'Şili',
            CN: 'Çin',
            CX: 'Christmas Adası',
            CC: 'Cocos (Keeling) Adaları',
            CO: 'Kolombiya',
            KM: 'Komorlar',
            CG: 'Kongo',
            CD: 'Demokratik Kongo Cumhuriyeti',
            CK: 'Cook Adaları',
            CR: 'Kosta Rika',
            CI: 'Cote D\'Ivoire',
            HR: 'Hırvatistan',
            CU: 'Küba',
            CY: 'Kıbrıs',
            CZ: 'Çek Cumhuriyeti',
            DK: 'Danimarka',
            DJ: 'Djibouti',
            DM: 'Dominika',
            DO: 'Dominik Cumhuriyeti',
            EC: 'Ekvador',
            EG: 'Mısır',
            SV: 'El Salvador',
            GQ: 'Ekvator Ginesi',
            ER: 'Eritre',
            EE: 'Estonya',
            ET: 'Etiyopya',
            FK: 'Falkland Adaları (Malvinas)',
            FO: 'Faroe Adaları',
            FJ: 'Fiji',
            FI: 'Finlandiya',
            FR: 'Fransa',
            GF: 'Fransız Guyanası',
            PF: 'Fransız Polinezyası',
            TF: 'Fransız Güney Toprakları',
            GA: 'Gabon',
            GM: 'Gambiya',
            GE: 'Gürcistan',
            DE: 'Almanya',
            GH: 'Gana',
            GI: 'Cebelitarık',
            GR: 'Yunanistan',
            GL: 'Grönland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Gine',
            GW: 'Gine-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Adası ve Mcdonald Adaları',
            VA: 'Holy See (Vatikan Şehir Devleti)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Macaristan',
            IS: 'İzlanda',
            IN: 'Hindistan',
            ID: 'Endonezya',
            IR: 'İran',
            IQ: 'Irak',
            IE: 'İrlanda',
            IM: 'Isle Of Man',
            IL: 'İsrail',
            IT: 'İtalya',
            JM: 'Jamaika',
            JP: 'Japonya',
            JE: 'Jersey',
            JO: 'Ürdün',
            KZ: 'Kazakistan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Kore',
            KW: 'Kuveyt',
            KG: 'Kırgızistan',
            LA: 'Lao Demokratik Halk Cumhuriyeti',
            LV: 'Letonya',
            LB: 'Lübnan',
            LS: 'Lesotho',
            LR: 'Liberya',
            LY: 'Libya Arap Cemahiriyesi',
            LI: 'Liechtenstein',
            LT: 'Litvanya',
            LU: 'Lüksemburg',
            MO: 'Makao',
            MK: 'Makedonya',
            MG: 'Madagaskar',
            MW: 'Malavi',
            MY: 'Malezya',
            MV: 'Maldivler',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Adaları',
            MQ: 'Martinik',
            MR: 'Moritanya',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Meksika',
            FM: 'Mikronezya',
            MD: 'Moldova',
            MC: 'Monako ',
            MN: 'Moğolistan',
            ME: 'Karadağ',
            MS: 'Montserrat',
            MA: 'Fas',
            MZ: 'Mozambik',
            MM: 'Myanmar',
            NA: 'Namibya',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Hollanda',
            AN: 'Hollanda Antilleri',
            NC: 'Yeni Kaledonya',
            NZ: 'Yeni Zelanda',
            NI: 'Nikaragua',
            NE: 'Niger',
            NG: 'Nijerya',
            NU: 'Niue',
            NF: 'Norfolk Adası',
            MP: 'Kuzey  Mariana Adaları',
            NO: 'Norveç',
            OM: 'Umman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'İşgal Altındaki Filistin Toprakları',
            PA: 'Panama',
            PG: 'Papua Yeni Gine',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipinler',
            PN: 'Pitcairn',
            PL: 'Polonya',
            PT: 'Portekiz',
            PR: 'Porto Riko',
            QA: 'Katar',
            RE: 'Reunion',
            RO: 'Romanya',
            RU: 'Rusya',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts ve Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre ve Miquelon',
            VC: 'Saint Vincent ve Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome ve Principe',
            SA: 'Suudi Arabistan',
            SN: 'Senegal',
            RS: 'Sırbistan',
            SC: 'Seyşeller',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slovakya',
            SI: 'Slovenya',
            SB: 'Solomon Adaları',
            SO: 'Somali',
            ZA: 'Güney Afrika',
            GS: 'Güney Georgia ve Sandwich Adaları',
            ES: 'İspanya',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard ve Jan Mayen',
            SZ: 'Svaziland',
            SE: 'İsveç',
            CH: 'İsviçre',
            SY: 'Suriye Arap Cumhuriyeti',
            TW: 'Tayvan',
            TJ: 'Tacikistan',
            TZ: 'Tanzanya',
            TH: 'Tayland',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad ve Tobago',
            TN: 'Tunus',
            TR: 'Türkiye',
            TM: 'Türkmenistan',
            TC: 'Turks ve Caicos Adaları',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukrayna',
            AE: 'Birleşik Arap Emirlikleri',
            GB: 'Birleşik Krallık',
            US: 'Birleşik Devletler',
            UM: 'Birleşik Devletler Dış Adaları',
            UY: 'Uruguay',
            UZ: 'Özbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Britanya Virjin Adaları',
            VI: 'ABD Virjin Adaları',
            WF: 'Wallis ve Futuna',
            EH: 'Batı Sahra',
            YE: 'Yemen',
            ZM: 'Zambiya',
            ZW: 'Zimbabve'
        },
    },
    rs: {
        _name: 'Srpski',
        timePickerModal: {
            title: 'Close In Time'
        },
        newDesign: {
            withdrawalRequests: 'No withdrawal requests yet',
            withdrawalRequestsText1: 'You haven\'t made any withdrawal requests so far.',
            withdrawalRequestsText2: 'Once you initiate a withdrawal, your transaction details will appear here',
            pdfAttachment: 'PDF attachment',
            agent: "Agent",
            closed: 'Closed',
            pending: 'Pending',
            approved: 'Approved',
            declined: 'Declined',
            document: 'Dokument',
            frontSide: 'Front Side',
            backSide: 'Back Side',
            address: 'Address',
            frontSideDocument: 'Front side of the document',
            creditCard: 'Credit Card',
            selfie: 'Selfie',
            other: 'Other',
            noDocument: 'No documents uploaded yet',
            amountDeposited: 'Amount deposited',
            makeTransfer: 'Make Transfer',
            otherDetails: 'Order details',
            manualWallets: 'Kripto novčanici',
            depositText: 'Depozit se obrađuje u roku od 5-10 minuta',
            pendingBankTransfer: 'You have a pending bank transfer deposit.',
            completeDeposit: 'Please follow the instructions below to complete your deposit',
            downloadInvoice: 'Download the invoice',
            payInvoice: 'Pay the invoice in your bank',
            useYourBankText1: 'Use your bank’s online or offline payment system to pay the invoice.',
            useYourBankText2: 'Ensure the payment details are correct.',
            uploadReceipt: 'Upload the confirmation receipt',
            afterMakingPayment: 'After making the payment, upload your receipt to confirm the deposit',
            clickToUpload: 'Click to Upload',
            orDrag: 'or drag and drop',
            fileFormat: 'PDF, JPG, JPEG, PNG less than 10MB',
            waitForDeposit: 'Wait for deposit confirmation',
            onceReceipt: 'Once the receipt is uploaded, we\'ll verify and approve your deposit',
            paymentDetails: 'Payment details',
            nameOnCard: 'Name on card',
            cardNumber: 'Card number',
            expirationDate: 'Expiration date',
            securityCode: 'Security code',
            continue: 'Continue',
            viewAccounts: 'View Accounts',
            continueWithdrawal: 'Continue Withdrawal',
            accountBalance: 'Account Balance',
            accountBalanceText1: 'Why can’t I place a trade?',
            accountsBalanceText2: 'How do I deposit funds?',
            accountsBalanceText3: 'Želim da položim novac putem Zelle-a',
            tradingActions: 'Trading Actions',
            tradingActionsText1: 'How do I short sell?',
            tradingActionsText2: 'How can I cancel a trade?',
            tradingActionsText3: 'What is the minimum trade amount?',
            marketInfo: 'Market Info',
            marketInfoText1: 'Where can I see market trends?',
            marketInfoText2: 'What are the top gainers today?',
            securityAndSettings: 'Security and Settings',
            securityAndSettingsText1: 'How do I change my password?',
            securityAndSettingsText2: 'What are the security features?',
            securityAndSettingsText3: 'How do I update my account info?',
            messageCopied: 'Message copied to clipboard',
            addSymbol: 'Add Symbol',
            iHaveRead: 'I have read and agree to the terms and conditions',
            toggleToTurn: 'Toggle to turn on AutoTrade',
            selectTheRisk: 'Select the risk level',
            confirm: 'Confirm',
            cancel: 'Cancel',
            chooseClosingTime: 'Choose a closing time',
            setOrderClosingTime: 'Set Order Closing Time',
            closingTime: 'Closing Time',
            date: 'Date',
            time: 'Time',
            setCloseTime: 'Set Close Time',
            '15m': '15 Minutes',
            '30m': '30 Minutes',
            '1h': '1 hour',
            custom: 'Custom',
            verifyOtp: 'Verify Your OTP',
            enterDigits: 'Enter the 6-digit code sent to your [email/phone]',
            submit: 'Submit',
            order: 'Nalog',
            closedSuccessfully: 'je uspešno zatvoren',
            openSuccessfully: 'open successfully',
            deposit: 'deposit',
            withdrawal: 'withdrawal',
            completeVerification: 'Complete Verification',
            verification: 'Verification',
            avatar: 'Avatar',
            saveChanges: 'Save Changes',
            darkMode: 'Dark mode',
            dashboardCurrency: 'Dashboard Currency',
            language: 'Language',
            twoFactor: '2-factor Protection',
            googleTwoFactorIsActive: 'Google 2-factor authenticator is active',
            activateCodeForGoogle: 'Activate code for Google Authenticator',
            ifYouWishDisableEnterCode: 'If you wish to disable protection, enter the authenticator app code',
            scanOrManuallyEnter: 'Scan or manually enter the QR code in Google 2FA to add a token',
            copyCode: 'Copy Code',
            qrCode: 'QR Code',
            activateAndEnterAuthenticator: 'Activate Authenticator and enter a generated code in field below',
            enterTheDigitsCodeGenerated: 'Enter the 6-digit code generated',
            disableTwoFactor: 'Disable Two-Factor Protection',
            activateTwoFactor: 'Activate 2FA Protection',
            fileAdded: 'File successfully added',
            theVerificationStatus: 'The verification status can be viewed in the list of uploaded documents',
            confirmWithdrawal: 'Potvrdi isplatu',
            minimumWithdrawal: 'Minimum withdrawal amount is',
            welcomeToTradingPlatform: 'Welcome to the trading platform',
            signIn: 'Sign In',
            activeAccount: 'Active account',
            save: 'Save',
            selectCurrencyAndPeriod: 'Изаберите валуту и период претплате',
            savingsNotFound: 'Savings Accounts Not Found',
            pleaseCheckInvestment: 'Please check your investment details to continue',
            allPeriodInvestment: 'All period investment',
            pct_0: 'Flexible',
            pct_30: '1 Month',
            pct_60: '1 Quarter',
            pct_90: '6 Month',
            pct_180: '6 Month',
            pct_365: '1 Year',
            savingsCreated: 'Savings account created successfully',
            transferDetails: 'Transfer Details',
            transferAmount: 'Transfer Amount',
            investmentDetails: 'Detalji investicije',
            investment: 'Investment',
            Period: 'Period',
            created: 'Created',
            releaseTime: 'Release time',
            viewSavingsAccounts: 'View Savings Accounts',
            continueInvestment: 'Continue Investment',
            chooseSubscription: 'Izaberite svoju pretplatu',
            selectCurrencyAndSubscription: 'Izaberite valutu i period pretplate za nastavak.',
            rate: 'Rate',
            accountTransferFunds: 'Account to transfer funds from',
            amount: 'Iznos',
            'in': 'in',
            openSavingsAndInvest: 'Otvori štedni račun i investiraj',
            strongSell: 'Strong sell',
            sell: 'Sell',
            neutral: 'Neutral',
            buy: 'Buy',
            stringBuy: 'Strong buy',
            chooseCryptoWallet: 'Choose a Wallet',
            chooseMethod: 'Izaberite metod',
            maximumDeposit: 'Maximum deposit is',
            minimumDeposit: "Minimalni depozit je",
            pleaseCompleteAddress: 'Please complete your address',
            pleaseCompleteCity: 'Please complete your city',
            pleaseCompleteCountry: 'Please complete your country',
            pleaseCompleteZip: 'Please complete your ZIP code',
            amountDeposit: 'Amount deposit',
            transactionStatus: 'Transaction status',
            paymentMethod: 'Payment method',
            depositTo: 'Deposit to',
            depositAddress: 'Deposit Address',
            scanTheQr: 'Scan the QR code or copy the deposit address',
            wallet: 'Wallet',
            copyDepositAddress: 'Copy Deposit Address',
            copy: 'Kopiraj',
            addressCopied: 'Address copied to clipboard',
            '5min:': '5-10 min',
            '2-5days': '2-5 days / 5-10 min',
            otherMethod: 'Other method',
            profileSettings: 'Profile Settings',
            savingsAccounts: 'Štedni računi',
            subscribe: 'Pretplati se',
            toCompleteVerification: 'To complete the verification process for your account, please provide clear and legible copies of the following documents',
            amountToWithdrawal: 'Amount to withdrawal',
            cardDetails: 'Card details',
            cardHolderName: 'Card holder name',
            withdrawalSuccessful: 'Withdrawal Successful',
            selectWithdrawalMethod: 'Select withdrawal method',
            creditDebit: 'Kreditna/debitna kartica',
            crypto: 'Crypto',
            cryptoWalletAddress: 'Crypto wallet address',
            cryptoSubtitle: 'Kripto isplate na vaš novčanik 🚀',
            bankWithdrawal: 'Bankovna isplata',
            bankSubtitle: 'Bankovni račun 🏦',
            zelleSubtitle: 'Brzi bankovni transferi ⚡🏦',
            tradingResults: 'Trading results',
            day: 'Day',
            month: 'Month',
            noTradingData: 'No trading data available',
            startTradingToSee: 'Start trading to see your results here.',
            yourPerformanceMetric: 'Your performance metrics will be displayed once data is available.',
            favorite: 'Favorite',
            all: 'All',
            availableFullTime: 'Available 24/7',
            yourProfileAndSettings: 'Your profile and settings',
            switchToNewDesignMessage: 'Switch to Our New Platform Design Now!',
            switchToOldDesignMessage: 'You can still use the classic design for a limited time',
            switchToOldDesignButton: 'Switch to New Design',
            switchToNewDesignButton: 'Revert to Classic Design',
            howCanIAssist: 'How can I assist you today?',
            generateInvoice: 'Generate Invoice',
            downloadPdf: 'Download PDF',
            confirmDeposit: 'Confirm Deposit',
            maxFileSize: 'The maximum file size is 5MB, and accepted formats are JPEG, PNG, or GIF',
            uploadPhoto: 'Upload Photo',
            featuresDisabled: 'This feature is disabled',
            welcome: 'Welcome',
            signUp: 'Sign Up',
            registration: 'Registration',
            pleaseConfirmAgreement: 'Please confirm customer agreement',
            pleaseEnterPassword: 'Please enter a password',
            passwordNotSame: 'Passwords are not the same',
            noActiveOrders: 'No Active Orders',
            itLikeNoHaveActiveOrders: 'It looks like you don\'t have any active orders at the moment',
            cryptoWallet: 'Crypto wallet',
            bankCard: 'Bank/Card',
            selected: 'Selected',
            amountWithdrawal: 'Amount withdrawn',
            withdrawalTo: 'Withdrawal to',
            governmentIssuedPhoto1: 'Документ са фотографијом издат од стране владе (нпр. пасош, возачка дозвола, национална лична карта)',
            governmentIssuedPhoto2: 'Боравишна дозвола (ако је примењиво)',
            governmentIssuedPhoto3: 'Документ мора бити важећи (не сме бити истекао)',
            governmentIssuedPhoto4: 'Пуно име, датум рођења и фотографија морају бити видљиви.',
            uploadRecent1: 'Отпремите недавни рачун за комуналне услуге, извод из банке или уговор о закупу издат у последња 3 месеца',
            uploadRecent2: 'Уверите се да су ваше пуно име и тренутна адреса становања јасно видљиви.',
            uploadCopy1: 'Отпремите копију предње стране ваше кредитне картице, уверите се да су видљиве првих шест и последње четири цифре',
            uploadCopy2: 'Молимо прекријте средње цифре из безбедносних разлога',
            takeAndUpload1: 'Сликајте селфи држећи личну карту поред лица и отпремите је',
            takeAndUpload2: 'Уверите се да су ваше лице и лична карта јасно видљиви',
            otherDocuments: 'Други документи',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Otpremite jasnu i čitljivu sliku vašeg dokumenta sa brojem socijalnog osiguranja (SSN). Uverite se da su svi detalji vidljivi i bez prepreka.',
            frontSideSsnDocument: 'Prednja strana SSN dokumenta',
            answerSaved: 'Одговори су сачувани',
            cryptoWalletDetails: 'Детаљи крипто новчаника',
            bitcoinWalletAddress: 'Биткоин адреса новчаника',
            recovery: 'Опоравак',
            passwordRecovery: 'Опоравак лозинке',
            searchCountry: 'Претрага земље',
            questionnaire: 'Upitnik',
            questionnaireText: 'Molimo vas da popunite upitnik kako biste potvrdili svoj identitet',
            sendAnswers: 'Pošalji odgovore',
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Uzmite profit i sprečite gubitak',
            takeProfit: 'Uzmite profit',
            stopLoss: 'Sprečite gubitak',
            updateOrder: 'Izmeni narudžbinu',
        },
        balanceModal: {
            balance: 'Saldo',
            credit: 'Kredit',
            equity: 'Kapital',
            practice: 'VEŽBA',
            real: 'REALNO',
            account: 'NALOG',
            invested: 'Investirano',
            profit: 'Profit',
            loss: 'Gubitak',
            margin: 'Marža',
            marginLevel: 'Nivo marže',
            marginFree: 'Besplatna marža'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platforma',
            deposit: 'Depozit'
        },
        sideMenu: {
            marketWatch: 'Market Watch',
            activeOrders: 'Aktivne narudžbine',
            tradingHistory: 'Istorija trgovanja',
            economicCalendar: 'Kalendar',
            marketNews: 'Novosti sa tržišta'
        },
        closePositionModal: {
            closeOrder: 'Zatvori poziciju',
            areYouSure: 'Sigurno želite da zatvorite poziciju?',
            buy: 'Kupi',
            sell: 'Prodaj',
            yes: 'Da',
            no: 'Ne',
        },
        pciDssModal: {
            desc: `Usklađenost sa Standardima sigurnosti podataka industrije platnih kartica (PCI DSS) je obavezna za sve subjekte koji skladište, obrađuju ili prenose podatke o vlasnicima kartica Visa/Master Card/Stripe, uključujući finansijske institucije, trgovce i pružaoce usluga. To je globalni sigurnosni standard za sve subjekte koji skladište, obrađuju ili prenose podatke o vlasnicima kartica i/ili osetljive autentifikacijske podatke. PCI DSS postavlja osnovni nivo zaštitе za potrošače i pomaže u smanjenju prevara i curenja podataka širom celokupnog plaćanja.`
        },
        chart: {
            addNewChart: 'Dodaj nov grafikon',
        },
        symbolsModal: {
            watchlist: 'Lista za gledanje',
            asset: 'Imovina',
            price: 'Cena',
            changePct: 'Promeni 24h'
        },
        pendingModal: {
            pending: 'Na čekanju',
            assetPrice: 'Cena imovine',
            current: 'trenutno',
            revert: 'Povratak na tržišnu cenu',
            automatically: 'Pozicija će se automatski otvoriti kada cena dostigne ovaj nivo'
        },
        orderMenu: {
            default: 'podrazumevano',
            volume: 'Obim',
            lots: 'lotovi',
            units: 'jedinice',
            currency: 'valuta',
            contractSize: 'Veličina ugovora',
            position: 'Pozicija',
            margin: 'Marža',
            freeMargin: 'Besplatna marža',
            takeProfitStopLoss: 'Uzmite profit i sprečite gubitak',
            pending: 'Na čekanju',
            market: 'Tržište',
            leverage: 'Leveridž',
            spread: 'Širenje',
            notSet: 'Nije podešeno',
            at: 'u',
            buy: 'kupi',
            sell: 'prodaj',
        },
        footer: {
            supportBanner: 'SVAKI DAN, SVAKI SAT',
            currentTime: 'TRENUTNO VREME',
            liveChat: 'Čet uživo'
        },
        statusBar: {
            balance: 'Saldo',
            credit: 'Kredit',
            equity: 'Kapital',
            margin: 'Marža',
            marginLevel: 'Nivo marže',
            freeMargin: 'Besplatna marža',
            pnl: 'PnL',
            profitTotal: 'Doživotni PnL'
        },
        accountModal: {
            dateRegistered: 'Datum registracije',
            userId: 'Korisnički ID',
            dashboard: 'Komandna tabla',
            personalData: 'Lični podaci',
            deposit: 'Depozit',
            withdrawFunds: 'Povuci sredstva',
            savings: 'Uštede',
            settings: 'Podešavanja',
            logout: 'Odjava'
        },
        depositModal: {
            BT_INVOICE: 'Unesite iznos i generišete fakturu',
            BT_TITLE: 'Naši bankovni podaci',
            account: 'Račun',
            bank_name: 'Naziv banke',
            beneficiary: 'Korisnik',
            beneficiary_address: 'Adresa korisnika',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Iznos',
            TOKEN_SUBTOTAL: 'Suma stavke',
            TOKEN_TOTAL: 'Ukupno',
            TOKEN_DESCRIPTION: 'Opis',
            TOKEN_PAYMENT_DETAILS: 'Podaci o plaćanju',
            PENDING_DEPOSITS_LIMIT: 'Limit depozita',
            makeDeposit: 'Uplatite depozit',
            practiceAccount: 'Nalog za vežbanje',
            realAccount: 'Pravi nalog',
            noWithdrawals: 'Bez povlačenja',
            easyWithdrawals: 'Jednostavna povlačenja',
            allAssets: 'Sva sredstva dostupna',
            fullFledged: 'Punopravna platforma',
            fillUpTo: 'Napuniti do',
            freeReplenishment: 'Besplatno dopunjavanje',
            topUp: 'Dopunite svoj račun',
            minimumAmount: 'Minimalni iznos',
            canSwitch: 'U svakom trenutku možete da prelazite sa jednog naloga na drugi'
        },
        gridsModal: {
            chartGrids: 'MREŽE GRAFIKONA',
            chart1: '1 grafikon',
            chart2: '2 grafikona',
            chart3: '3 grafikona',
            chart4: '4 grafikona',
        },
        noConnectionModal: {
            connectionLost: 'Veza sa serverom je izgubljena',
            retryNow: 'Pokušajte ponovo sada',
        },
        loginModal: {
            loginToTradeRoom: 'Prijava u Sobu za trgovanje',
            email: 'Email adresa',
            enterEmail: 'Unesite svoj email',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA kod (ako je omogućen)',
            twoFactorAuth: 'Dvofaktorska autentifikacija',
            password: 'Lozinka',
            yourPassword: 'Vaša lozinka',
            signIn: 'Prijava',
            dontHaveAccount: 'Nemate nalog?',
            restore: 'Povrati',
            fullName: 'Ime i prezime',
            havePromo: 'Imam promo-kod',
            promo: 'Promo-kod',
            login: 'Prijava',
            haveAccount: 'Već imate nalog?',
            hide: 'Sakrij',
            goTo: 'Idi pozadi'
        },
        newPasswordModal: {
            setNewPassword: 'Podesite novu lozinku',
            newPassword: 'Nova lozinka',
            confirmNewPassword: 'Potvrdite novu lozinku',
            setButton: 'Podesi'
        },
        registerModal: {
            repeatPassword: 'Ponovite lozinku',
            createNewAccount: 'Kreirajte nov nalog',
            email: 'Email adresa',
            firstName: 'Ime',
            lastName: 'Prezime',
            country: 'Zemlja',
            phone: 'Telefon',
            password: 'Lozinka',
            createAccount: 'Kreirajte nalog',
            currency: 'Valuta',
            privacyAndPolicy: "Privatnost i Politika",
            customerAgreement: 'Ugovor sa klijentom',

        },
        forgotPasswordModal: {
            forgotPassword: 'Zaboravili ste lozinku?',
            submitEmail: 'Pošaljite email koji ste koristili za registraciju, proverite inboks i pratite data uputstva',
            submit: 'Pošalji'
        },
        notifications: {
            error: 'Greška',
            success: 'Uspešno',
            deposit: 'Depozit',
            withdrawal: 'Povlačenje',
            depositApproved: 'je odobren',
            depositDeclined: 'Depozit je odbijen',
            withdrawalApproved: 'Povlačenje je odobreno',
            withdrawalDeclined: 'Povlačenje je odbijeno'
        },
        markets: {
            favorites: 'Lista praćenja', 
            all: 'Svi simboli',
            forex: 'Forex',
            stocks: 'Akcije',
            commodities: 'Roba',
            indices: 'Indeksi',
            crypto: 'Kripto',
            metals: 'Metali',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'KUPI',
            profitCalculator: 'Kalkulator profita',
            sell: 'PRODAJ',
            volume: 'Obim',
            entryPrice: 'Ulazna cena',
            takeProfit: 'Uzmite profit',
            stopLoss: 'Sprečite gubitak',
            maxPosition: 'Maks. pozicija',
            calculate: 'Izračunaj',
            calculationsFor: 'Proračun za',
            leverage: 'Leveridž',
            requiredMargin: 'Zahtevana marža',
            profitFromTP: 'Profit od TP',
            lossFromSL: 'Gubitak od SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Depozit uspešan',
            text: 'Vaš depozit je uspešno obrađen!',
        },
        depositFailModal: {
            title: 'Depozit neuspešan',
            text: 'Vaš depozit je neuspešan.',
        },
        widgetMarketWatch: {
            marketWatch: 'Market Watch',
            search: 'Pretraga...',
        },
        widgetOrders: {
            commission: 'Provizija',
            volume: 'Obim',
            margin: 'Marža',
            active: 'Aktivno',
            pending: 'Na čekanju',
            activeOrders: 'Aktivne narudžbine',
            portfolio: 'Portfolio',
            allPositions: 'Sve pozicije',
            noPositionsLine1: 'Nemate otvorenih',
            noPositionsLine2: 'pozicija još uvek',
            show: 'Prikaži',
            more: 'više',
            less: 'manje',
            purchaseTime: 'Vreme kupovine',
            closePosition: 'Zatvori poziciju',
            priceOpen: 'Kupovna cena',
            priceSl: 'Cena sprečavanja gubitka',
            priceTp: 'Cena uzimanja profita',
            type: 'Smer pozicije',
            pnl: 'Profit/gubitak',
            cancelOrder: 'Otkaži narudžbinu',
            orderType: 'Tip narudžbine',
            cancelled: 'Otkazano',
            tradingHistory: 'Istorija trgovanja',
            noHistoryLine1: 'Nemate nijedan',
            noHistoryLine2: 'završeni posao još uvek',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Kalendar'
        },
        widgetMarketNews: {
            marketNews: 'Novosti na tržištu'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Aktivne narudžbine',
            ordersHistory: 'Istorija narudžbina',
            id: 'ID',
            symbol: 'Simbol',
            type: 'Tip',
            volume: 'Obim',
            openPrice: 'Cena otvaranja',
            openTime: 'Vreme otvaranja',
            closePrice: 'Cena zatvaranja',
            closeTime: 'Vreme zatvaranja',
            sl: 'SL',
            tp: 'TP',
            price: 'Cena',
            pnl: 'PnL',
            actions: 'Radnje',
            edit: 'Uredi',
            close: 'Zatvori',
            commission: 'Provizija',
            swap: 'Zamena',
            swapLong: 'Zameni dugo',
            swapShort: 'Zameni kratko'
        },
        assetInfoModal: {
            assetInfo: 'Podaci o sredstvima',
            tradingConditions: 'Uslovi trgovanja',
            information: 'Promena sesije',
            bid: 'Licitiraj',
            ask: 'Pitaj',
            sessionChange: 'Promena sesije',
            tradeNow: 'Trguj sada',
            opens: 'Otvaranje',
            closes: 'Zatvaranje',
            at: 'u',
            open247: 'Otvoreno 24/7',
            today: 'danas',
            tomorrow: 'sutra',
            sunday: 'Nedelja',
            monday: 'Ponedeljak',
            tuesday: 'Utorak',
            wednesday: 'Sreda',
            thursday: 'Četvrtak',
            friday: 'Petak',
            saturday: 'Subota',
            contractSpecification: 'Specifikacije ugovora',
            symbol: 'Simbol',
            name: 'Naziv',
            market: 'Tržište',
            baseCurrency: 'Osnovna valuta',
            digits: 'Cifre',
            lotSize: 'Veličina lota',
            lotStep: 'Korak lota',
            minVolume: 'Min. obim',
            maxVolume: 'Maks. obim',
            leverage: 'Leveridž',
            commission: 'Provizija',
            swapLong: 'Zameni dugo',
            swapShort: 'Zameni kratko',
            schedule: 'Raspored',
            weekday: 'Radni dan',
            tradingTime: 'Vreme trgovanja',
            closed: 'zatvoreno',
            sell: 'Prodaj',
            buy: 'Kupi',
            low: 'Nisko',
            high: 'Visoko',
            oneHourChange: '1-satna promena',
            oneDayChange: '1-dnevna promena',
            oneWeekChange: '1-nedeljna promena',
            oneMonthChange: '1-mesečna promena',
            threeMonthsChange: '3-mesečna promena',
            oneYearChange: '1-godišnja promena',
            loading: 'Učitavanje...',
        },
        autoTrader: {
            days: 'days',
            title: 'Automatski trgovac',
            titleAi: 'AI Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Nivo rizika: nizak',
            riskLevelMedium: 'Nivo rizika: srednji',
            riskLevelHigh: 'Nivo rizika: visok',
            perDay: 'Trgovanja po danu:',
            desc: `Trgovanje CFD-ovima i drugim proizvodima sa leveridžomom može dovesti do gubitaka. Pre trgovanja, klijenti treba da pročitaju relevantne izjave o riziku na našoj stranici za otkrivanje rizika. Automatsko trgovanje ne garantuje rezultate. Kompanija ne prihvata odgovornost za gubitak sredstava u automatskom trgovanju. Uverite se da ste potpuno razumeli rizike i preduzeli mere za upravljanje rizicima.`,
            accept: 'Prihvati',
            save: 'Sačuvaj',
            cancel: 'Otkaži',
        },
        cardBox: {
            name: 'Ime',
            cardNumber: 'Broj kartice',
            expiration: 'Ističe (mm/gg)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Trgovanje",
            startTrading: 'Započni trgovanje',
            deposit: 'Depozit',
            dashboard: 'Komandna tabla',
            personalInfo: 'Lični podaci',
            contactInfo: 'Kontakt informacije',
            withdrawal: 'Povlačenje',
            verification: 'Verifikacija',
            accounts: 'Nalozi',
            liveChat: 'Čet uživo',
            savings: 'Uštede',
            referrals: 'Програм препорука',
            settings: 'Podešavanja',
            logOut: 'Odjava',
        },
        fullScreenDeposit: {
            methods: 'METODE',
            confirm: 'Potvrdite u aplikaciji svoje banke',
            sendOtp: 'Pošalji otp',
            otpCode: 'Otp kod',
            addWalletAddress: 'Novčanik za ovaj metod depozita nije podešen. Molimo kontaktirajte svog agenta ili tim za podršku.',
            addressReceipt: 'Sačekajte da se adresa učita',
            makeDeposit: 'UPLATITE DEPOZIT',
            instant: 'Odmah',
            minutes: 'minuti',
            hours: 'sati',
            days: 'dani',
            amount: 'Iznos',
            continue: 'Nastavi',
            qrCode: 'QR KOD',
            depositAddress: 'ADRESA DEPOZITA',
            copy: 'Kopiraj',
            last: 'POSLEDNJI',
            deposits: 'DEPOZITI',
            time: 'Vreme',
            currency: 'Valuta',
            status: 'Status',
            info: 'Informacije',
        },
        fullScreenCcExt: {
            pleaseWait: 'Sačekajte. Povezivanje sa provajderom usluga plaćanja...',
            cardPayment: 'Plaćanje karticom',
            inOrder: 'Kako biste poslali iznos',
            pleaseProvide: 'obezbedite podatke sa kartice:'
        },
        fullScreenDashboard: {
            completeVerification: 'Završite verifikaciju',
            noOrderYet: 'Još nema porudžbine',
            totalBalance: 'Ukupni saldo',
            totalPnl: 'Ukupni PNL',
            profitableOrders: 'Profitabilne narudžbine',
            roi: 'ROI',
            activityLog: 'Evidencija aktivnosti',
            loginFromIp: 'Prijava sa IP',
            tradingResults: 'Rezultati trgovanja',
            week: 'Nedelja',
            month: 'Mesec',
            year: 'Godina',
            successRate: 'Stopa uspešnosti',
            closedWithProfit: 'Zatvori sa profitom',
            closedWithLoss: 'Zatvori sa gubitkom',
            account: 'Račun',
            balance: 'Saldo',
            leverage: 'Leveridž',
            credit: 'Kredit',
            tradeNow: 'Trgujte sada',
            usingCurrentRate: 'koristeći trenutni kurs',
            totalDeposits: 'Ukupni depoziti',
            totalWithdrawals: "Ukupna povlačenja",
            returnOnInvestment: "Povrat investicije"
        },
        fullScreenPersonal: {
            personalDetails: 'Lični podaci',
            profilePhoto: 'Profilna slika',
            firstName: 'Ime',
            country: 'Zemlja',
            lastName: 'Prezime',
            address: 'Adresa',
            email: 'E-mail',
            phone: 'Broj telefona',
            saveChanges: 'Sačuvaj promene',
            state: 'Grad',
            postcode: 'Poštanski broj',
            created: "Nalog kreiran",
        },
        fullScreenAvatar: {
            dropFile: 'spustite datoteku u krug iznad da biste je otpremili​',
            notAllowed: 'Nije dozvoljeno objavljivanje',
            notAllowedLine1: 'Fotografije eksplicitno seksualne ili pornografske prirode',
            notAllowedLine2: 'Slika koje imaju za cilj podsticanje etničke ili rasne mržnje ili agresije',
            notAllowedLine3: 'Fotografija koje uključuju osobe mlađe od 18 godina',
            notAllowedLine4: 'Fotografije zaštićene autorskim pravima trećih strana',
            notAllowedLine5: 'Slike veće od 5 MB i u formatu koji nije JPG, GIF ili PNG',
            requirements: 'Vaše lice mora biti jasno vidljivo na fotografiji. Sve fotografije i video snimci koje ste otpremili moraju biti u skladu sa ovim zahtevima, inače se mogu ukloniti.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Kontaktirajte svog menadžera naloga za detalje o povlačenju',
            cryptocurrency: 'Kripto-valuta',
            withdrawal: 'Povlačenje',
            requestNewWithdrawal: 'ZATRAŽITE NOVO POVLAČENJE',
            amount: 'Iznos',
            currentBalance: 'Vaš trenutni saldo na računu je',
            withdrawAll: 'Povuci sve',
            requestWithdrawal: 'Zatražite povlačenje',
            last: 'POSLEDNI',
            withdrawalRequests: 'ZAHTEVI ZA POVLAČENJE',
            time: 'Vreme',
            currency: 'Valuta',
            info: 'Info',
            status: 'Status',
            bank: 'Banka',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Kartica',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Podaci o povlačenju',
            address: 'Adresa novčanika',
            bankDetails: 'Банкарски подаци',
            bankAccount: 'Broj računa',
            bankHolder: 'Име власника рачуна',
            bankIban: 'IBAN',
            bankSwift: 'Bankarski SWIFT kod',
            recipientAddress: 'Адреса примаоца',
            routingNumber: 'Банкарски број усмеравања',
            bankBeneficiary: 'Назив банке примаоца',
            bankAddress: 'Адреса банке',
            bankCode: 'Банкарски код (BSB)',
            desc: 'Опис',
            cardNumber: 'Broj kartice',
            cardHolder: 'Ime vlasnika kartica',
        },
        fullScreenVerification: {
            dragAndDrop: 'prevucite i otpustite dokument u ovu oblast',
            documentsList: 'POPIS OTPREMLJENIH DOKUMENATA',
            document: 'Dokument',
            timeUploaded: 'Vreme otpremanja',
            timeProcessed: 'Vreme obrade',
            status: 'Status',
            types: ['Dokaz o ličnom dokumentu', 'Dokaz o prebivalištu', 'Prednja strana kreditne kartice', 'Povratak kreditne kartice', 'Dokaz o ličnom identitetu', 'Selfi', 'Other'],
            types2: ['Dokaz o identifikaciji (POI)', 'Dokaz adrese stanovanja', 'Prednja strana kreditne kartice', 'Poleđina kreditne kartice', 'Dokaz o identifikaciji nazad', 'Selfi sa ličnom kartom'],
            upload: 'Otpremanje',
            isConfirmed: 'je potvrđeno',
            uploadAnotherDocument: 'Otpremite drugi dokument',
            isUnderReview: 'pregleda se',
            questionnaire: 'Upitnik',
            sendAnswers: 'Pošaljite odgovore',
            progressBarOf: '',
            progressBarText: 'da su vaši dokumenti otpremljeni i potvrđeni'
        },
        fullScreenAccounts: {
            manageAccounts: 'Prenesite sredstva između svojih naloga',
            transferFrom: 'Sa naloga',
            transferTo: 'Na nalog',
            amount: 'Iznos',
            makeTransfer: 'Prenesite',
            yourAccounts: 'Svoje naloge',
            account: 'Nalog',
            currency: 'Valuta',
            balance: 'Saldo',
            credit: 'Kredit',
            makeActive: 'Aktivirajte',
            active: 'Aktivno'
        },
        fullScreenSupport: {
            sendMessage: 'Pošalji poruku'
        },
        fullScreenSavings: {
            pct_0: 'Fleksibilno',
            pct_30: 'Zaključano 1 mesec',
            pct_90: 'Zaključano 3 meseca',
            pct_180: 'Zaključano 6 meseci',
            pct_365: 'Zaključano 1 godinu',
            savings: 'Uštede',
            detailedInformation: 'Detaljne informacije',
            pleaseSelect: 'Izaberite valutu i period',
            openSavings: 'Otvorene uštede',
            currentTime: 'Trenutno vreme',
            releaseTime: 'Vreme objavljivanja',
            currencyChosen: 'Izabrana valuta',
            periodChosen: 'Izabran period',
            yourRate: 'Vaša stopa',
            yourEarnings: 'Vaša zarada',
            accountFrom: 'Račun za prenos sredstava sa',
            enterAmount: 'Unesite iznos',
            in: 'U',
            openAndInvest: 'Otvorite štedni račun i investirajte',
            investment: 'Investicija',
            period: 'Period',
            daysTotal: 'Ukupno dana',
            finalEarnings: 'Krajnja zarada',
            created: 'Kreirano',
            daysElapsed: 'Protekli dani',
            days: 'dani',
            earnings: 'Zarade',
            fundsReleasedIn: 'Sredstva puštena u',
            claim: 'Potraživanje',
            name: "Име",
        },
        fullScreenSettings: {
            updatePassword: 'IZMENI LOZINKU',
            currentPassword: 'Trenutna lozinka',
            newPassword: 'Nova lozinka',
            changePassword: 'Izmeni lozinku',
            activationForGoogle: 'Aktivacioni kod za Google Authenticator',
            activateAndEnter: 'Aktivirajte Authenticator i unesite generisani kod u polje ispod',
            qrCode: 'QR kod',
            activateProtection: 'Aktivirajte 2FA zaštitu',
            protectionActive: '2FA zaštita je aktivna',
            twoFactorCode: '2FA kod',
            disableProtection: 'Onemogućite 2FA zaštitu',
            language: 'Jezik',
            dashboardCurrency: 'Valuta komandne table',
            confirmNewPassword: 'Potvrdite novu lozinku',
        },
        fullScreenReferrals: {
            yourLink: 'VAŠ REFERALNI LINK',
            referredFriends: 'REFERIRANI PRIJATELJI',
            id: 'ID',
            name: 'Ime',
            registered: 'Registracija',
            level: 'Nivo',
            payout: 'Isplata',
            lastPayouts: 'POSLEDNJE ISPLATE ZA REFERALE',
            time: 'Vreme',
            amount: 'Iznos',
            currency: 'Valuta',
            info: 'Info',
            referralDesc:"Program preporuka je osmišljen da vas nagradi za proširenje naše zajednice vrednih investitora.",
            shareLink:"Podelite link:",
            subtitle:"Dajte mu svoje ime, e-mail ili ID za trgovanje.",
            descSuccesfull:"Kada se vaša preporuka uspešno registruje, vaš finansijski savetnik će ga kontaktirati da mu pomogne oko prvih koraka na njegovom investicionom putu na najefikasniji i najprofesionalniji način.",
            descTracked:"Svi njegovi doprinosi se prate pod vašom preporukom."
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Maj',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Avg',
            Sep: 'Sep',
            Oct: 'Okt',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Buy', 'Sell', 'Buy Limit', 'Sell Limit', 'Buy Stop', 'Sell Stop'],
        statuses: ['Na čekanju', 'Odobreno', 'Odbijeno', 'Obrada'],
        errors: {
            NOT_FOUND: 'Zahtev za greškom',
            FORBIDDEN: 'Pristup odbijen. Ponovo se prijavite',
            SERVER_ERROR: 'Radnja neuspešna. Pokušajte ponovo kasnije',
            INVALID_ACCOUNT: 'Sesija je istekla. Ponovo se prijavite',
            INVALID_EMAIL: 'Nevažeća e-mail adresa',
            INVALID_PHONE: 'Nevažeći broj telefona',
            INVALID_COUNTRY: 'Nevažeća zemlja',
            INVALID_LOGIN: 'Nevažeći podaci za prijavu',
            USER_BLOCKED: 'Račun je blokiran. Molimo kontaktirajte podršku',
            USER_REGISTERED: 'Korisnik sa unetim e-mailom ili brojem telefona je već registrovan',
            INVALID_USER: 'Korisnik nije pronađen',
            REJECTED: 'Rad nije dozvoljen zbog ograničenja platforme',
            INVALID_OLD_PASSWORD: 'Stara lozinka je nevažeća',
            INVALID_SYMBOL: 'Nevažeći simbol za trgovanje',
            INVALID_SYMBOL_PRECISION: 'Nevažeći simbol',
            INVALID_FRAME: 'Nevažeći okvir grafikona',
            INVALID_CURRENCY: 'Nevažeća valuta',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Nevažeći tip trgovanja na nalogu',
            INSUFFICIENT_FUNDS: 'Nedovoljno sredstava',
            INVALID_WITHDRAWAL: 'Povlačenje nije pronađeno',
            INVALID_STATUS: 'Nevažeći status',
            INVALID_FILE: 'Nevažeća ekstenzija datoteke ili je datoteka prevelika',
            INVALID_DOCUMENT: 'Dokument nije pronađen',
            INVALID_NAME: 'Nevažeće ime',
            INVALID_LOT_SIZE: 'Nevažeća veličina lota',
            INVALID_LOT_STEP: 'Nevažeći korak lota',
            INVALID_MIN_VOLUME: 'Nevažeći minimalni obim',
            INVALID_MAX_VOLUME: 'Nevažeći maksimalni obim',
            INVALID_GROUP: 'Nevažeća grupa',
            INVALID_SPREAD: 'Nevažeće širenje',
            REQUEST_TIMEOUT: 'Zahtev istekao. Pokušajte ponovo kasnije',
            EXISTING_OPEN_ORDERS: 'Imate otvorenu narudžbu, prvo zatvorite narudžbu za ovog korisnika i pokušajte ponovo.',
            INVALID_TYPE: 'Nevažeći tip narudžbine',
            INVALID_VOLUME: 'Nevažeći obim narudžbine',
            INVALID_SL: 'Nevažeća vrednost sprečavanja gubitka',
            INVALID_TP: 'Nevažeća vrednost uzimanja profita',
            INVALID_PRICE: 'Nevažeća cena narudžbine',
            INVALID_EXPIRATION: 'Nevažeći istek porudžbine',
            NOT_ENOUGH_MARGIN: 'Nedovoljno marže',
            INVALID_ORDER: 'Narudžbina nije pronađena',
            MARKET_CLOSED: 'Trgovanje nije dostupno. Molim vas, pokušajte ponovo',
            INVALID_BALANCE_TYPE: 'Nevažeći tip operacije salda',
            INVALID_HASH: 'Nevažeći hash',
            HASH_EXPIRED: 'Vaš link za resetovanje lozinke je istekla. Zatražite novu',
            INVALID_CODE: 'Nevažeći 2FA kod',
            CHAT_DISABLED: 'Pristup ćaskanju podrške je zatvoren',
            WITHDRAWAL_NOT_ALLOWED: 'Pristup isplatama je zatvoren',
            TRADING_DISABLED: 'Trgovanje je onemogućeno za tekući račun',
            PENDING_DEPOSITS_LIMIT: 'Prekoračen je limit depozita',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Dozvoljeno je samo jedno povlačenje na čekanju',
            LOCAL_DEPOSIT_ONLY_REAL: 'Depozit je dostupan samo za pravi račun',
            LOCAL_DEPOSIT_POSITIVE: 'Iznos depozita mora biti veći od 0',
            LOCAL_INVALID_CARD_NUMBER: 'Nevažeći broj kartice',
            LOCAL_INVALID_CARD_EXPIRATION: 'Nevažeći istek kartice',
            LOCAL_INVALID_CVC: 'Nevažeći CVV/CVC kod',
            LOCAL_PASSWORDS_NOT_MATCH: 'Lozinke se ne podudaraju, unesite ponovo',
            LOCAL_NO_TRADING_ACCOUNTS: 'Nemate dostupnih trgovačkih računa. Molimo kontaktirajte podršku',
            LOCAL_MIN_DEPOSIT: 'Minimum depozit je $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum iznos za povlačenje je $',
            LOCAL_INVALID_FILE_TYPE: 'Format datoteke nije podržan. Priložite samo JPG, PNG ili PDF',
            LOCAL_MIN_STAKING: 'Minimum depozit za ovu valutu je ',
            FLOOD_REJECTED: 'Prečesto šaljete poruke. Pokušajte ponovo kasnije'
        },
        countries: {
            AF: 'Avganistan',
            AX: 'Alandska Ostrva',
            AL: 'Albanija',
            DZ: 'Alžir',
            AS: 'Američka Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Ankila',
            AQ: 'Antarktik',
            AG: 'Antigva i Barbuda',
            AR: 'Argentina',
            AM: 'Jermenija',
            AW: 'Aruba',
            AU: 'Australija',
            AT: 'Austrija',
            AZ: 'Azerbejdžan',
            BS: 'Bahami',
            BH: 'Bahrein',
            BD: 'Bangladeš',
            BB: 'Barbados',
            BY: 'Belorusija',
            BE: 'Belgija',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Butan',
            BO: 'Bolivija',
            BA: 'Bosna i Hercegovina',
            BW: 'Bocvana',
            BV: 'Bouvet Ostrvo',
            BR: 'Brazil',
            IO: 'British Indian Ocean teritorija',
            BN: 'Brunei Darussalam',
            BG: 'Bugarska',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodža',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Cape Verde',
            KY: 'Kajmanska Ostrva',
            CF: 'Centralno-afrička Republika',
            TD: 'Čad',
            CL: 'Čile',
            CN: 'Kina',
            CX: 'Božićna Ostrva',
            CC: 'Koko (Keeling) Ostrva',
            CO: 'Kolumbija',
            KM: 'Komoros',
            CG: 'Kongo',
            CD: 'Kongo, Demokratska Republika',
            CK: 'Kukova Ostrva',
            CR: 'Kostarika',
            CI: 'Obala slonovače',
            HR: 'Hrvatska',
            CU: 'Kuba',
            CY: 'Kipar',
            CZ: 'Češka Republika',
            DK: 'Danska',
            DJ: 'Džibuti',
            DM: 'Dominika',
            DO: 'Dominikanska Republika',
            EC: 'Ekvador',
            EG: 'Egipat',
            SV: 'El Salvador',
            GQ: 'Ekvatorijalna Gvineja',
            ER: 'Eritreja',
            EE: 'Estonija',
            ET: 'Etiopija',
            FK: 'Folklandska Ostrva (Malvinas)',
            FO: 'Farska Ostrva',
            FJ: 'Fidži',
            FI: 'Finska',
            FR: 'Francuska',
            GF: 'Francuska Gvajana',
            PF: 'Francuska Polinezija',
            TF: 'Francuske Južne Teritorije',
            GA: 'Gabon',
            GM: 'Gambija',
            GE: 'Gruzija',
            DE: 'Nemačka',
            GH: 'Gana',
            GI: 'Gibraltar',
            GR: 'Grčka',
            GL: 'Grenland',
            GD: 'Grenada',
            GP: 'Gvadalupe',
            GU: 'Guam',
            GT: 'Gvatemala',
            GG: 'Gernzi',
            GN: 'Gvineja',
            GW: 'Gvineja-Bisao',
            GY: 'Gvajana',
            HT: 'Haiti',
            HM: 'Ostrvo Herd i Mekdonald Ostrva',
            VA: 'Vatikan',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Mađarska',
            IS: 'Island',
            IN: 'Indija',
            ID: 'Indonezija',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irska',
            IM: 'Ostrvo Čoveka',
            IL: 'Izrael',
            IT: 'Italija',
            JM: 'Jamajka',
            JP: 'Japan',
            JE: 'Džerzi',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenija',
            KI: 'Kiribati',
            KR: 'Koreja',
            KW: 'Kuvajt',
            KG: 'Kirgistan',
            LA: 'Lao Narodna Demokratska Republika',
            LV: 'Letonija',
            LB: 'Libanon',
            LS: 'Lesoto',
            LR: 'Liberija',
            LY: 'Libija',
            LI: 'Lihtenštajn',
            LT: 'Litvanija',
            LU: 'Luksemburg',
            MO: 'Makao',
            MK: 'Makedonija',
            MG: 'Madagaskar',
            MW: 'Malavi',
            MY: 'Malezija',
            MV: 'Maldivi',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Maršalska Ostrva',
            MQ: 'Martinik',
            MR: 'Mauritanija',
            MU: 'Mauricijus',
            YT: 'Majote',
            MX: 'Meksiko',
            FM: 'Mikronezija',
            MD: 'Moldavija',
            MC: 'Monako',
            MN: 'Mongolija',
            ME: 'Crna gora',
            MS: 'Montserat',
            MA: 'Maroko',
            MZ: 'Mozambik',
            MM: 'Mjanmar',
            NA: 'Namibija',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Nizozemska',
            AN: 'Holandski antili',
            NC: 'Nova Kaledonija',
            NZ: 'Novi Zeleand',
            NI: 'Nikaragva',
            NE: 'Niger',
            NG: 'Nigerija',
            NU: 'Niue',
            NF: 'Norfolk Ostrva',
            MP: 'Severna Marijanska Ostrva',
            NO: 'Norveška',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestinska Teritorija, okupirana',
            PA: 'Panama',
            PG: 'Papua Nova Gvineja',
            PY: 'Paragvaj',
            PE: 'Peru',
            PH: 'Filipini',
            PN: 'Pitcairn',
            PL: 'Poljska',
            PT: 'Portugal',
            PR: 'Portoriko',
            QA: 'Katar',
            RE: 'Reunion',
            RO: 'Rumunija',
            RU: 'Rusija',
            RW: 'Ruanda',
            BL: 'Sveti Bartelemi,',
            SH: 'Sveta Jelena',
            KN: 'Sveti Kits i Nevis',
            LC: 'Sveta Lucija',
            MF: 'Sveti Martin',
            PM: 'Sveti Pjer i Mikelon',
            VC: 'Sveti vinsent i Grenadini',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome i Principe',
            SA: 'Saudijska Arabija',
            SN: 'Senegal',
            RS: 'Srbija',
            SC: 'Sejšeli',
            SL: 'Sijera Leone',
            SG: 'Singapor',
            SK: 'Slovačka',
            SI: 'Slovenija',
            SB: 'Solomonska Ostrva',
            SO: 'Somalija',
            ZA: 'Južna Afrika',
            GS: 'Južna Džordžija i Sendvič Ostrvo',
            ES: 'Španija',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard i Jan Majen',
            SZ: 'Svazilend',
            SE: 'Švedska',
            CH: 'Švajcarska',
            SY: 'Sirijska Arapska Republika',
            TW: 'Tajvan',
            TJ: 'Tadžikistan',
            TZ: 'Tanzanija',
            TH: 'Tajland',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad i Tobago',
            TN: 'Tunis',
            TR: 'Turska',
            TM: 'Turkmenistan',
            TC: 'Ostrva Turks i Kaikos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukrajina',
            AE: 'Ujedinjeni Arapski Emirati',
            GB: 'Ujedinjeno Kraljevstvo',
            US: 'Sjedinjenje Države',
            UM: 'Spoljna ostrva Sjedinjenih Država',
            UY: 'Urugvaj',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venecuela',
            VN: 'Vijetnam',
            VG: 'Devičanska Ostrva, Britanska',
            VI: 'Devičanska Ostrva, SAD',
            WF: 'Valis i Futuna',
            EH: 'Zapadna Sahara',
            YE: 'Jemen',
            ZM: 'Zambija',
            ZW: 'Zimbabve'
        },
    },
    pt: {
        _name: 'Português',
        newDesign: {
            withdrawalRequests: 'Ainda não há pedidos de levantamento',
            withdrawalRequestsText1: 'Até à data, não efectuou qualquer pedido de levantamento.',
            withdrawalRequestsText2: 'Depois de iniciar uma retirada, os detalhes da transação aparecerão aqui',
            pdfAttachment: 'Anexo PDF',
            agent: "Agência",
            closed: 'Fechado',
            pending: 'Em curso',
            approved: 'Aprovado',
            declined: 'Recusado',
            document: 'Documento',
            frontSide: 'Frente',
            backSide: 'Parte traseira',
            address: 'Endereço',
            frontSideDocument: 'Frente do documento',
            creditCard: 'Cartão de crédito',
            selfie: 'Fotografia',
            other: 'Outros',
            noDocument: 'Ainda não foram carregados documentos',
            amountDeposited: 'Montante depositado',
            makeTransfer: 'Efetuar transferência',
            otherDetails: 'Detalhes da encomenda',
            manualWallets: 'Carteiras de criptomoedas',
            depositText: 'O depósito é processado dentro de 5-10 minutos',
            pendingBankTransfer: 'Tem um depósito por transferência bancária pendente.',
            completeDeposit: 'Siga as instruções abaixo para efetuar o seu depósito',
            downloadInvoice: 'Descarregar a fatura',
            payInvoice: 'Pagar a fatura no seu banco',
            useYourBankText1: 'Utilize o sistema de pagamento online ou offline do seu banco para pagar a fatura.',
            useYourBankText2: 'Certifique-se de que os dados de pagamento estão corretos.',
            uploadReceipt: 'Carregar o recibo de confirmação',
            afterMakingPayment: 'Depois de efetuar o pagamento, carregue o seu recibo para confirmar o depósito',
            clickToUpload: 'Clique para carregar',
            orDrag: 'ou arrastar e largar',
            fileFormat: 'PDF, JPG, JPEG, PNG com menos de 10MB',
            waitForDeposit: 'Aguardar a confirmação do depósito',
            onceReceipt: 'Assim que o recibo for carregado, verificaremos e aprovaremos o seu depósito',
            paymentDetails: 'Detalhes do pagamento',
            nameOnCard: 'Nome no cartão',
            cardNumber: 'Número do cartão',
            expirationDate: 'Data de expiração',
            securityCode: 'Código de segurança',
            continue: 'Continuar',
            viewAccounts: 'Ver contas',
            continueWithdrawal: 'Continuar a retirada',
            howCanIAssist: 'Como posso ajudar-vos?',
            accountBalance: 'Saldo da conta',
            accountBalanceText1: 'Porque é que não consigo efetuar uma transação?',
            accountsBalanceText2: 'Como é que deposito fundos?',
            accountsBalanceText3: 'Quero depositar dinheiro via Zelle',
            tradingActions: 'Acções de negociação',
            tradingActionsText1: 'Como é que se vende a descoberto?',
            tradingActionsText2: 'Como posso cancelar uma transação?',
            tradingActionsText3: 'Qual é o montante mínimo de transação?',
            marketInfo: 'Informações sobre o mercado',
            marketInfoText1: 'Onde posso ver as tendências do mercado?',
            marketInfoText2: 'Quais são os principais ganhos de hoje?',
            securityAndSettings: 'Segurança e definições',
            securityAndSettingsText1: 'Como é que altero a minha palavra-passe?',
            securityAndSettingsText2: 'Quais são as caraterísticas de segurança?',
            securityAndSettingsText3: 'Como posso atualizar as informações da minha conta?',
            messageCopied: 'Mensagem copiada para a área de transferência',
            addSymbol: 'Adicionar símbolo',
            iHaveRead: 'Li e concordo com os termos e condições',
            toggleToTurn: 'Alternar para ativar o AutoTrade',
            selectTheRisk: 'Selecionar o nível de risco',
            confirm: 'Confirmação',
            cancel: 'Cancelar',
            chooseClosingTime: 'Escolher uma hora de fecho',
            setOrderClosingTime: 'Definir hora de fecho da encomenda',
            closingTime: 'Hora de fecho',
            date: 'Data',
            time: 'Tempo',
            setCloseTime: 'Definir hora de fecho',
            '15m': '15 minutos',
            '30m': '30 minutos',
            '1h': '1 hora',
            custom: 'Personalizado',
            verifyOtp: 'Verificar a sua OTP',
            enterDigits: 'Introduza o código de 6 dígitos enviado para o seu [email/telemóvel]',
            submit: 'Enviar',
            order: 'Ordem',
            closedSuccessfully: 'encerrada com sucesso',
            openSuccessfully: 'abrir com êxito',
            deposit: 'depósito',
            withdrawal: 'retirada',
            completeVerification: 'Verificação completa',
            verification: 'Verificação',
            avatar: 'Avatar',
            saveChanges: 'Guardar alterações',
            darkMode: 'Modo escuro',
            dashboardCurrency: 'Moeda do ecrã',
            language: 'Língua',
            twoFactor: 'Proteção de 2 factores',
            googleTwoFactorIsActive: 'O autenticador de 2 factores do Google está ativo',
            activateCodeForGoogle: 'Código de ativação do Google Authenticator',
            ifYouWishDisableEnterCode: 'Se pretender desativar a proteção, introduza o código da aplicação de autenticação',
            scanOrManuallyEnter: 'Digitalize ou introduza manualmente o código QR no Google 2FA para adicionar um token',
            copyCode: 'Código de cópia',
            qrCode: 'Código QR',
            activateAndEnterAuthenticator: 'Ativar o Authenticator e introduzir um código gerado no campo abaixo',
            enterTheDigitsCodeGenerated: 'Introduzir o código de 6 dígitos gerado',
            disableTwoFactor: 'Desativar a proteção de dois factores',
            activateTwoFactor: 'Ativar a proteção 2FA',
            fileAdded: 'Ficheiro adicionado com sucesso',
            theVerificationStatus: 'O estado de verificação pode ser visualizado na lista de documentos carregados',
            confirmWithdrawal: 'Confirmar retirada',
            minimumWithdrawal: 'O montante mínimo de levantamento é de',
            welcomeToTradingPlatform: 'Bem-vindo à plataforma de negociação',
            signIn: 'Iniciar sessão',
            activeAccount: 'Conta ativa',
            save: 'Guardar',
            selectCurrencyAndPeriod: 'Selecionar uma moeda e um período de subscrição',
            savingsNotFound: 'Contas de poupança não encontradas',
            pleaseCheckInvestment: 'Verifique os seus dados de investimento para continuar',
            allPeriodInvestment: 'Investimento para todo o período',
            pct_0: 'Flexível',
            pct_30: '1 mês',
            pct_60: '1 trimestre',
            pct_90: '6 meses',
            pct_180: '6 meses',
            pct_365: '1 ano',
            savingsCreated: 'Conta poupança criada com sucesso',
            transferDetails: 'Detalhes da transferência',
            transferAmount: 'Montante da transferência',
            investmentDetails: 'Detalhes do investimento',
            investment: 'Investimento',
            Period: 'Período',
            created: 'Criado',
            releaseTime: 'Tempo de libertação',
            viewSavingsAccounts: 'Ver contas de poupança',
            continueInvestment: 'Continuar o investimento',
            chooseSubscription: 'Escolha a sua subscrição',
            selectCurrencyAndSubscription: 'Selecione uma moeda e um período de subscrição para continuar.',
            rate: 'Taxa',
            accountTransferFunds: 'Conta para transferir fundos de',
            amount: 'Montante',
            'in': 'em',
            openSavingsAndInvest: 'Abrir uma conta poupança e investir',
            strongSell: 'Venda forte',
            sell: 'Vender',
            neutral: 'Neutro',
            buy: 'Comprar',
            stringBuy: 'Compra forte',
            chooseCryptoWallet: 'Escolher uma carteira de criptomoeda',
            chooseMethod: 'Escolha um método',
            maximumDeposit: 'O depósito máximo é de',
            minimumDeposit: "O depósito mínimo é",
            pleaseCompleteAddress: 'Por favor, preencha o seu endereço',
            pleaseCompleteCity: 'Por favor, preencha a sua cidade',
            pleaseCompleteCountry: 'Preencha o seu país',
            pleaseCompleteZip: 'Preencha o seu código postal',
            amountDeposit: 'Depósito do montante',
            transactionStatus: 'Estado da transação',
            paymentMethod: 'Método de pagamento',
            depositTo: 'Depósito para',
            depositAddress: 'Nome do depósito',
            scanTheQr: 'Digitalizar o código QR ou copiar o endereço de depósito',
            wallet: 'Carteira',
            copyDepositAddress: 'Cópia do endereço de depósito',
            copy: 'Copiar',
            addressCopied: 'Endereço copiado para a área de transferência',
            '5min:': '5-10 min',
            '2-5days': '2-5 dias / 5-10 min',
            otherMethod: 'Outro método',
            profileSettings: 'Definições de perfil',
            savingsAccounts: 'Contas de poupança',
            subscribe: 'Subscrever',
            toCompleteVerification: 'Para concluir o processo de verificação da sua conta, forneça cópias claras e legíveis dos seguintes documentos',
            amountToWithdrawal: 'Montante a retirar',
            cardDetails: 'Detalhes do cartão',
            cardHolderName: 'Nome do titular do cartão',
            withdrawalSuccessful: 'Retirada com êxito',
            selectWithdrawalMethod: 'Selecionar o método de levantamento',
            creditDebit: 'Cartão de crédito/débito',
            crypto: 'Criptografia',
            cryptoWalletAddress: 'Endereço da carteira Crypro',
            cryptoSubtitle: 'Saques de cripto para sua carteira 🚀',
            bankWithdrawal: 'Saque bancário',
            bankSubtitle: 'Conta bancária 🏦',
            zelleSubtitle: 'Transferências bancárias rápidas ⚡🏦',
            tradingResults: 'Resultados das transacções',
            day: 'Dia',
            month: 'Mês',
            noTradingData: 'Não existem dados comerciais disponíveis',
            startTradingToSee: 'Comece a negociar para ver os seus resultados aqui.',
            yourPerformanceMetric: 'Os seus indicadores de desempenho serão apresentados assim que os dados estiverem disponíveis.',
            favorite: 'Favorito',
            all: 'Todos',
            availableFullTime: 'Disponível 24/7',
            yourProfileAndSettings: 'O seu perfil e definições',
            switchToNewDesignMessage: 'Mude agora para o novo design da nossa plataforma!',
            switchToOldDesignMessage: 'Ainda pode utilizar o design clássico por um período limitado',
            switchToOldDesignButton: 'Mudar para novo design',
            switchToNewDesignButton: 'Reverter para o design clássico',
            governmentIssuedPhoto1: 'Documento de identidade emitido pelo governo (por exemplo, passaporte, carteira de motorista, carteira de identidade nacional)',
            governmentIssuedPhoto2: 'Autorização de residência (se aplicável)',
            governmentIssuedPhoto3: 'O documento deve ser válido (não expirado)',
            governmentIssuedPhoto4: 'O nome completo, a data de nascimento e a fotografia devem estar visíveis.',
            uploadRecent1: 'Carregue uma conta recente de serviços públicos, extrato bancário ou contrato de aluguel emitido nos últimos 3 meses',
            uploadRecent2: 'Certifique-se de que seu nome completo e endereço residencial atual estejam claramente visíveis.',
            uploadCopy1: 'Envie uma cópia do lado frontal do seu cartão de crédito, garantindo que os primeiros seis e os últimos quatro dígitos estejam visíveis',
            uploadCopy2: 'Cubra os dígitos do meio por segurança',
            takeAndUpload1: 'Tire e envie uma selfie segurando seu documento de identidade ao lado do rosto',
            takeAndUpload2: 'Certifique-se de que tanto o rosto quanto o documento estejam claramente visíveis',
            otherDocuments: 'Outros documentos',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Carregue uma imagem clara e legível do seu documento de Número de Segurança Social (SSN). Certifique-se de que todos os detalhes estão visíveis e sem obstruções.',
            frontSideSsnDocument: 'Lado frontal do documento SSN',
            answerSaved: 'Respostas foram salvas',
            cryptoWalletDetails: 'Detalhes da carteira de criptomoeda',
            bitcoinWalletAddress: 'Endereço da carteira Bitcoin',
            recovery: 'Recuperação',
            passwordRecovery: 'Recuperação de senha',
            searchCountry: 'Pesquisar país',
            questionnaire: 'Questionário',
            questionnaireText: 'Por favor, preencha o questionário para verificar sua identidade',
            sendAnswers: 'Enviar respostas',
        },
        timePickerModal: {
            title: 'Perto no Tempo'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit e Stop Loss',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            updateOrder: 'Ordem de Atualização',
        },
        balanceModal: {
            balance: 'Saldo',
            credit: 'Crédito',
            equity: 'Patrimônio líquido',
            practice: 'PRÁTICA',
            real: 'REAL',
            account: 'CONTA',
            invested: 'Investido',
            profit: 'Lucro',
            loss: 'Perda',
            margin: 'Margem',
            marginLevel: 'Nível de Margem',
            marginFree: 'Margem Livre'
        },
        header: {
            cfd: 'CFD',
            platform: 'Plataforma',
            deposit: 'Depósito'
        },
        sideMenu: {
            marketWatch: 'Observação do Mercado',
            activeOrders: 'Ordens Ativas',
            tradingHistory: 'Histórico de Negociação',
            economicCalendar: 'Calendário',
            marketNews: 'Notícias do Mercado'
        },
        closePositionModal: {
            closeOrder: 'Fechar Posição',
            areYouSure: 'Tem certeza de que fechará a posição',
            buy: 'Comprar',
            sell: 'Vender',
            yes: 'Sim',
            no: 'Não',
        },
        pciDssModal: {
            desc: `A conformidade com o Padrão de Segurança de Dados do Setor de Cartões de Pagamento (PCI DSS) é exigida de todas as entidades que armazenam, processam ou transmitem dados de titulares de cartões Visa/Master Card/Stripe, 
            incluindo instituições financeiras, comerciantes e provedores de serviços.
            A TI é o padrão de segurança global para todas as entidades que armazenam, processam ou transmitem dados de titulares de cartões e/ou dados de autenticação confidenciais. O PCI DSS define um nível básico de proteção para os consumidores e ajuda a reduzir fraudes e violações de dados em todo o ecossistema de pagamentos.`
        },
        chart: {
            addNewChart: 'Adicionar Novo Gráfico',
        },
        symbolsModal: {
            watchlist: 'Lista de Observação',
            asset: 'Ativo',
            price: 'Preço',
            changePct: 'Mudança 24h'
        },
        pendingModal: {
            pending: 'Pendente',
            assetPrice: 'Preço do Ativo',
            current: 'Atual',
            revert: 'Reverter para o Preço de Mercado',
            automatically: 'A posição será aberta automaticamente quando o preço atingir esse nível'
        },
        orderMenu: {
            default: 'padrão',
            volume: 'Volume',
            lots: 'lotes',
            units: 'unidades',
            currency: 'moeda',
            contractSize: 'Tamanho do Contrato',
            position: 'Posição',
            margin: 'Margem',
            freeMargin: 'Margem Livre',
            takeProfitStopLoss: 'Take Profit e Stop Loss',
            pending: 'Pendente',
            market: 'Mercado',
            leverage: 'Alavancagem',
            spread: 'Spread',
            notSet: 'Não definido',
            at: 'na',
            buy: 'compra',
            sell: 'venda',
        },
        footer: {
            supportBanner: 'TODOS OS DIAS, 24 HORAS POR DIA',
            currentTime: 'HORA ATUAL',
            liveChat: 'Bate-papo ao Vivo'
        },
        statusBar: {
            balance: 'Saldo',
            credit: 'Crédito',
            equity: 'Patrimônio líquido',
            margin: 'Margem',
            marginLevel: 'Nível da margem',
            freeMargin: 'Margem livre',
            pnl: 'PnL',
            profitTotal: 'PnL vitalícia'
        },
        accountModal: {
            dateRegistered: 'Fecha de Registro',
            userId: 'ID do Usuário',
            dashboard: 'Painel',
            personalData: 'Dados Pessoais',
            deposit: 'Depósito',
            withdrawFunds: 'Retirada de Fundos',
            savings: 'Poupança',
            settings: 'Configurações',
            logout: 'Logout'
        },
        depositModal: {
            BT_INVOICE: 'Insira o valor e gere a fatura',
            BT_TITLE: 'Nossos dados bancários',
            account: 'Conta',
            bank_name: 'Nome do Banco',
            beneficiary: 'Beneficiário',
            beneficiary_address: 'Endereço do Beneficiário',
            swift: 'Swift',
            reference: 'Reference',
            routing_number: 'Routing Number',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Conta do correspondente',
            TOKEN_AMOUNT: 'Montante',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Descrição',
            TOKEN_PAYMENT_DETAILS: 'Detalhes do Pagamento',
            PENDING_DEPOSITS_LIMIT: 'Limite de Depósito',
            makeDeposit: 'Fazer um Depósito',
            practiceAccount: 'Conta de Prática',
            realAccount: 'Conta Real',
            noWithdrawals: 'Sem saques',
            easyWithdrawals: 'Retiradas fáceis',
            allAssets: 'Todos os ativos disponíveis',
            fullFledged: 'Plataforma completa',
            fillUpTo: 'Preencher até',
            freeReplenishment: 'Reabastecimento gratuito',
            topUp: 'Recarregue sua Conta',
            minimumAmount: 'Valor mínimo',
            canSwitch: 'Você pode alternar entre suas contas a qualquer momento'
        },
        gridsModal: {
            chartGrids: 'GRADES DE GRÁFICOS',
            chart1: '1 gráfico',
            chart2: '2 gráfico',
            chart3: '3 gráfico',
            chart4: '4 gráfico',
        },
        noConnectionModal: {
            connectionLost: 'A conexão com o servidor é perdida',
            retryNow: 'Tentar Novamente Agora',
        },
        loginModal: {
            loginToTradeRoom: 'Login na Sala de Negociação',
            email: 'Endereço de e-mail',
            enterEmail: 'Digite seu e-mail',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: 'Código 2FA (se habilitado)',
            twoFactorAuth: 'Autenticação de dois fatores',
            password: 'Senha',
            yourPassword: 'Sua senha',
            signIn: 'Entrar',
            dontHaveAccount: 'Não tem uma conta?',
            restore: 'Restaurar',
            fullName: 'Nome Completo',
            havePromo: 'Tenho um código promocional',
            promo: 'Código promocional',
            login: 'Login',
            haveAccount: 'Já tem uma conta?',
            hide: 'Esconder',
            goTo: 'Ir para trás'
        },
        newPasswordModal: {
            setNewPassword: 'Definir Nova Senha',
            newPassword: 'Nova senha',
            confirmNewPassword: 'Confirmar a nova senha',
            setButton: 'Conjunto'
        },
        registerModal: {
            repeatPassword: 'Repetir Senha',
            createNewAccount: 'Criar uma Nova Conta',
            email: 'Endereço de e-mail',
            firstName: 'Primeiro Nome',
            lastName: 'Sobrenome',
            country: 'País',
            phone: 'Telefone',
            password: 'Senha',
            createAccount: 'Criar Conta',
            currency: 'Moeda',
            privacyAndPolicy: "Privacidade e Política",
            customerAgreement: 'Acordo com o cliente',

        },
        forgotPasswordModal: {
            forgotPassword: `Esqueceu sua senha?`,
            submitEmail: 'Envie um e-mail usado para registro, verifique sua caixa de entrada e siga as instruções fornecidas',
            submit: 'Enviar'
        },
        notificações: {
            error: 'Erro',
            success: 'Sucesso',
            depósito: 'Depósito',
            withdrawal: 'Retirada',
            depositApproved: 'foi aprovado',
            depositDeclined: 'O depósito foi recusado',
            withdrawalApproved: 'A retirada foi aprovada',
            withdrawalDeclined: 'A retirada foi recusada'
        },
        markets: {
            favorites: 'Lista de observação', 
            all: 'Todos os símbolos',
            forex: 'Forex',
            stocks: 'Ações',
            commodities: 'Mercadorias',
            indices: 'Índices',
            crypto: 'Cripto',
            metals: 'Metais',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'Comprar',
            profitCalculator: 'Calculadora de lucro',
            sell: 'Vender',
            volume: 'Volume',
            entryPrice: 'Preço de entrada',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Posição máxima',
            calculate: 'Calcular',
            calculationsFor: 'Cálculos para',
            leverage: 'Alavancagem',
            requiredMargin: 'Margem necessária',
            profitFromTP: 'Lucro do TP',
            lossFromSL: 'Perda de SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Depósito bem-sucedido',
            text: 'Seu depósito foi processado com sucesso!',
        },
        depositFailModal: {
            title: 'Depósito falhou',
            text: 'Seu depósito não foi processado',
        },
        widgetMarketWatch: {
            marketWatch: 'Observação do mercado',
            search: 'Pesquisar...',
        },
        widgetOrders: {
            commission: 'Comissão',
            volume: 'Volume',
            margin: 'Margem',
            active: `Ativo`,
            pending: `Pendente`,
            activeOrders: 'Ordens ativas',
            portfolio: 'Portfólio',
            allPositions: 'Todas as posições',
            noPositionsLine1: 'Você não tem nenhuma aberta',
            noPositionsLine2: 'posições ainda',
            show: 'Mostrar',
            more: 'mais',
            less: 'menos',
            purchaseTime: 'Tempo de compra',
            closePosition: 'Posição de fechamento',
            priceOpen: 'Preço de compra',
            priceSl: 'Preço de Stop Loss',
            priceTp: `Preço de Take Profit`,
            type: `Direção da posição`,
            pnl: 'Lucro/Perda',
            cancelOrder: 'Cancelar ordem',
            orderType: 'Tipo de ordem',
            cancelled: 'Cancelado',
            tradingHistory: 'Histórico de negociação',
            noHistoryLine1: 'Você não tem nenhum',
            noHistoryLine2: 'negócios fechados ainda',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendário'
        },
        widgetMarketNews: {
            marketNews: 'Notícias do mercado'
        },
        ordersPane: {
            closeInTime: 'Fechar no tempo',
            timedOrder: 'Ordem Temporizada',
            ordersActive: 'Ordens ativas',
            ordersHistory: 'Histórico de pedidos',
            id: 'ID',
            symbol: 'Símbolo',
            type: 'Tipo',
            volume: 'Volume',
            openPrice: 'Preço de abertura',
            openTime: 'Hora de abertura',
            closePrice: 'Preço de fechamento',
            closeTime: 'Hora de fechamento',
            sl: 'SL',
            tp: 'TP',
            price: 'Preço',
            pnl: 'PnL',
            actions: 'Ações',
            edit: 'Editar',
            close: 'Fechar',
            commission: 'Comissão',
            swap: 'Trocar',
            swapLong: 'Troca longa',
            swapShort: 'Troca curta'
        },
        assetInfoModal: {
            assetInfo: 'Informações sobre o ativo',
            tradingConditions: 'Condições de negociação',
            information: 'Mudança de sessão',
            bid: 'Lance',
            ask: 'Pergunte',
            sessionChange: 'Mudança de sessão',
            tradeNow: 'Negociar agora',
            opens: 'Abre',
            closes: 'Fecha',
            at: 'no',
            open247: 'Aberto 24/7',
            today: 'hoje',
            tomorrow: 'amanhã',
            sunday: 'Domingo',
            monday: 'Segunda-feira',
            tuesday: 'terça-feira',
            wednesday: 'Quarta-feira',
            thursday: 'Quinta-feira',
            friday: 'sexta-feira',
            saturday: 'Sábado',
            contractSpecification: 'Especificação do contrato',
            symbol: 'Símbolo',
            name: 'Nome',
            market: 'Mercado',
            baseCurrency: 'Moeda base',
            digits: 'Dígitos',
            lotSize: 'Tamanho do lote',
            lotStep: 'Etapa do lote',
            minVolume: 'Volume mínimo',
            maxVolume: 'Volume máximo',
            leverage: 'Alavancagem',
            commission: 'Comissão',
            swapLong: 'Troca longa',
            swapShort: 'Troca curta',
            schedule: 'Agenda',
            weekday: 'Dia da semana',
            tradingTime: 'Horário de negociação',
            closed: 'fechado',
            sell: 'Vender',
            buy: 'Comprar',
            low: 'Baixo',
            high: 'Alto',
            oneHourChange: 'Mudança de 1 hora',
            oneDayChange: 'Mudança de 1 dia',
            oneWeekChange: 'Mudança de 1 semana',
            oneMonthChange: 'Mudança de 1 mês',
            threeMonthsChange: 'Mudança de 3 meses',
            oneYearChange: 'Mudança de 1 ano',
            loading: 'Carregando...',
        },
        autoTrader: {
            dias: 'dias',
            title: 'Auto Trader',
            titleAi: 'AI Trader',
            customTitle: 'Período de tempo Negociador automático',
            riskLevelLow: 'Nível de Risco: baixo',
            riskLevelMedium: 'Nível de Risco: Médio',
            riskLevelHigh: 'Nível de Risco: alto',
            perDay: 'Negociações por dia:',
            desc: `A negociação de CFDs e outros produtos alavancados pode levar a perdas. Antes de negociar, os clientes devem ler as declarações de risco relevantes em nossa página de Divulgação de Risco. A negociação automatizada não garante resultados. A empresa não aceita nenhuma responsabilidade pela perda de fundos em negociações automáticas. Por favor, certifique-se de que você compreenda totalmente os riscos e tome medidas para gerenciá-los.`,
            accept: 'Aceitar',
            save: 'Salvar',
            cancel: 'Cancelar',
        },
        cardBox: {
            name: 'Nome',
            cardNumber: 'Número do cartão',
            expiration: 'Expiração (mm/aa)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Olá',
            trade: 'Negociar',
            startTrading: 'Iniciar negociação',
            deposit: 'Depósito',
            dashboard: 'Painel',
            personalInfo: 'Informações Pessoais',
            contactInfo: 'Informações de Contato',
            withdrawal: 'Levantamento',
            verification: 'Verificação',
            accounts: 'Contas',
            liveChat: 'Chat',
            savings: 'Poupança',
            referrals: 'Programa de indicação',
            settings: 'Configurações',
            logOut: 'Sair',
        },
        fullScreenDeposit: {
            methods: 'MÉTODOS',
            confirm: 'Confirme no aplicativo do seu banco',
            sendOtp: 'Enviar otp',
            otpCode: 'Código otp',
            addWalletAddress: 'A carteira para esse método de depósito não está definida. Por favor, entre em contato com seu agente ou equipe de suporte.',
            addressReceipt: 'Por favor, aguarde o carregamento do endereço',
            makeDeposit: 'FAZER UM DEPÓSITO',
            instant: 'Instantâneo',
            minutes: 'minutos',
            hours: 'horas',
            dias: 'dias',
            amount: 'Valor',
            continue: 'Continuar',
            qrCode: 'CÓDIGO QR',
            depositAddress: 'ENDEREÇO DO DEPÓSITO',
            copy: 'Copiar',
            last: 'ÚLTIMO',
            deposits: 'DEPÓSITOS',
            time: 'Hora',
            currency: 'Moeda',
            status: 'Status',
            info: 'Informações',
        },
        fullScreenCcExt: {
            pleaseWait: 'Aguarde, por favor. Conectando-se ao provedor de serviços de pagamento...',
            cardPayment: 'Pagamento com cartão',
            inOrder: 'A fim de enviar o valor',
            pleaseProvide: 'forneça os detalhes do cartão:'
        },
        fullScreenDashboard: {
            completeVerification: 'Por favor, complete a verificação',
            noOrderYet: 'Nenhum pedido ainda',
            totalBalance: `Saldo total`,
            totalPnl: 'Total PNL',
            profitableOrders: 'Pedidos lucrativos',
            roi: 'ROI',
            activityLog: 'Registro de atividade',
            loginFromIp: 'Login a partir do IP',
            tradingResults: 'Resultados da negociação',
            week: 'Semana',
            month: 'Mês',
            year: 'Ano',
            successRate: 'Taxa de sucesso',
            closedWithProfit: 'Fechado com lucro',
            closedWithLoss: 'Fechado com prejuízo',
            account: 'Conta',
            balance: 'Saldo',
            leverage: 'Alavancagem',
            crédito: 'Crédito',
            tradeNow: 'Negocie agora',
            usingCurrentRate: 'usando a taxa de câmbio atual',
            totalDeposits: 'Depósitos Totais',
            totalWithdrawals: "Retiradas totais",
            returnOnInvestment: "Retorno sobre investimento"
        },
        fullScreenPersonal: {
            personalDetails: 'Detalhes Pessoais',
            profilePhoto: 'Foto do Perfil',
            firstName: 'Primeiro Nome',
            country: 'País',
            lastName: 'Sobrenome',
            address: 'Endereço',
            email: 'E-mail',
            phone: 'Número de telefone',
            saveChanges: 'Salvar alterações',
            state: 'Cidade',
            postcode: 'Código postal',
            created: 'Conta criada em',
        },
        fullScreenAvatar: {
            dropFile: 'Solte um arquivo no círculo acima para fazer o upload',
            notAllowed: 'Não é permitido publicar',
            notAllowedLine1: 'Fotos de natureza explicitamente sexual ou pornográfica',
            notAllowedLine2: 'Imagens destinadas a incitar o ódio ou a agressão étnica ou racial',
            notAllowedLine3: 'Fotos envolvendo pessoas com menos de 18 anos de idade',
            notAllowedLine4: 'Fotos protegidas por direitos autorais de terceiros',
            notAllowedLine5: 'Imagens com mais de 5 MB e em um formato diferente de JPG, GIF ou PNG',
            requirements: 'Seu rosto deve estar claramente visível na foto. Todas as fotos e vídeos carregados por você devem atender a esses requisitos, caso contrário, poderão ser removidos.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Entre em contato com o gerente da sua conta para obter detalhes sobre saques',
            cryptocurrency: 'Criptomoeda',
            withdrawal: 'Retirada',
            requestNewWithdrawal: 'PEDIDO DE LEVANTAMENTO',
            amount: 'Valor',
            currentBalance: 'O saldo de sua conta corrente é',
            withdrawAll: 'Retirar tudo',
            requestWithdrawal: 'Solicitar retirada',
            last: 'ÚLTIMO',
            withdrawalRequests: 'SOLICITAÇÕES DE RETIRADA',
            time: 'Hora',
            currency: 'Moeda',
            info: 'Informações',
            status: 'Status',
            bank: 'Banco',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Cartão',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Detalhes do levantamento',
            address: 'Endereço da Carteira',
            bankDetails: 'Detalhes bancários',
            bankAccount: 'Número da conta',
            bankHolder: 'Nome do Titular da Conta',
            bankIban: 'IBAN',
            bankSwift: 'Código SWIFT bancário',
            recipientAddress: 'Endereço do destinatário',
            routingNumber: 'Número de roteamento bancário',
            bankBeneficiary: 'Nome do banco do beneficiário',
            bankAddress: 'Endereço do banco',
            bankCode: 'Código bancário (BSB)',
            desc: 'Descrição',
            cardNumber: 'Número do Cartão',
            cardHolder: 'Nome do Titular do Cartão',
        },
        fullScreenVerification: {
            dragAndDrop: 'arraste e solte o documento nessa área',
            documentsList: 'LISTA DE DOCUMENTOS CARREGADOS',
            document: 'Documento',
            timeUploaded: 'Tempo Carregado',
            timeProcessed: 'Tempo Processado',
            status: 'Status',
            types: ['Comprovante de Identidade', 'Comprovante de Residência', 'Cartão de Crédito Frente', 'Cartão de Crédito Verso', 'Comprovante de Identidade Verso', 'Selfie', 'Other'],
            types2: ['Comprovante de identificação (POI)', 'Comprovante de endereço residencial', 'Frente do Cartão de Crédito', 'Verso do Cartão de Crédito', 'Verso do Comprovante de Identificação', 'Selfie com ID'],
            upload: 'Upload',
            isConfirmed: 'está confirmado',
            uploadAnotherDocument: 'Fazer upload de outro documento',
            isUnderReview: 'está sendo revisado',
            questionnaire: 'Questionário',
            sendAnswers: 'Enviar respostas',
            progressBarOf: 'de',
            progressBarText: 'de seus documentos foram carregados e confirmados'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transferir fundos entre suas contas',
            transferFrom: 'Da conta',
            transferTo: `Para a conta`,
            amount: 'Valor',
            makeTransfer: 'Fazer transferência',
            yourAccounts: 'Suas contas',
            account: 'Conta',
            currency: 'Moeda',
            balance: 'Saldo',
            credit: 'Crédito',
            makeActive: 'Tornar Ativo',
            active: 'Ativo'
        },
        fullScreenSupport: {
            sendMessage: 'Enviar Mensagem'
        },
        fullScreenSavings: {
            pct_0: 'Flexível',
            pct_30: 'Bloqueado 1 mês',
            pct_90: 'Bloqueado por 1 trimestre',
            pct_180: `Bloqueado 6 meses`,
            pct_365: 'Bloqueado 1 ano',
            savings: 'Poupança',
            detailedInformation: 'Informações Detalhadas',
            pleaseSelect: 'Por favor, selecione uma moeda e um período',
            openSavings: 'Poupança aberta',
            currentTime: 'Hora atual',
            releaseTime: 'Hora da liberação',
            currencyChosen: 'Moeda escolhida',
            periodChosen: 'Período escolhido',
            yourRate: 'Sua taxa',
            yourEarnings: 'Seus ganhos',
            accountFrom: 'Conta da qual transferir fundos',
            enterAmount: 'Digite o Valor',
            in: 'Em',
            openAndInvest: 'Abrir Conta de Poupança e investir',
            investment: 'Investimento',
            period: 'Período',
            daysTotal: 'Total de dias',
            finalEarnings: 'Ganhos finais',
            created: 'Criado',
            daysElapsed: 'Dias decorridos',
            days: 'dias',
            earnings: 'Ganhos',
            fundsReleasedIn: 'Fundos liberados em',
            claim: 'Reivindicação',
            name: "Nome",
        },
        fullScreenSettings: {
            updatePassword: 'ATUALIZAR SENHA',
            currentPassword: 'Senha Atual',
            newPassword: 'Nova Senha',
            changePassword: 'Alterar Senha',
            activationForGoogle: 'Código de ativação do Google Authenticator',
            activateAndEnter: 'Ative o Authenticator(autenticador) e digite um código gerado no campo abaixo',
            qrCode: 'Código QR',
            activateProtection: 'Ativar Proteção 2FA',
            protectionActive: 'A proteção 2FA está ativa',
            twoFactorCode: 'Código 2FA',
            disableProtection: 'Desativar a Proteção 2FA',
            language: 'Idioma',
            dashboardCurrency: 'Moeda do painel',
            confirmNewPassword: 'Confirmar nova senha',
        },
        fullScreenReferrals: {
            yourLink: 'SEU LINK DE REFERÊNCIA',
            referredFriends: 'REFERRED FRIENDS',
            id: 'ID',
            name: 'Nome',
            registered: 'Registrar',
            nível: 'Nível',
            payout: 'Pagamento',
            lastPayouts: 'ÚLTIMOS PAGAMENTOS DE REFERÊNCIA',
            time: 'Hora',
            amount: 'Valor',
            currency: 'Moeda',
            info: 'Informações',
            referralDesc:"O Programa de Indicação foi criado para recompensá-lo por expandir nossa comunidade de investidores valiosos.",
            shareLink:"Compartilhe o link:",
            subtitle:"Forneça a ele seu nome, e-mail ou ID de negociação.",
            descSuccesfull:"Assim que sua indicação se registrar com sucesso, seu Consultor Financeiro entrará em contato para ajudá-lo nos primeiros passos de sua jornada de investimento da forma mais eficiente e profissional possível.",
            descTracked:"Todas as contribuições dele estão sendo rastreadas sob sua indicação."
        },
        months: {
            Jan: 'Jan',
            Feb: 'Fev',
            Mar: 'Mar',
            Apr: 'Abr',
            May: 'Maio',
            Jun: 'Junho',
            Jul: 'Julho',
            Aug: 'Ago',
            Sep: 'Set',
            Oct: 'Out',
            Nov: 'Nov',
            Dec: 'Dez'
        },
        orderTypes: ['Comprar', 'Vender', 'Limite de Compra', 'Limite de Venda', 'Parada de Compra', 'Parada de Venda'],
        statuses: ['Pendente', 'Aprovado', 'Recusado', 'Processando'],
        erros: {
            NOT_FOUND: 'Erro de solicitação',
            FORBIDDEN: 'Acesso negado. Favor refazer o login',
            SERVER_ERROR: 'A ação falhou. Por favor, tente novamente mais tarde',
            INVALID_ACCOUNT: 'A sessão expirou. Favor refazer o login',
            INVALID_EMAIL: 'Endereço de e-mail inválido',
            INVALID_PHONE: 'Número de telefone inválido',
            INVALID_COUNTRY: 'País inválido',
            INVALID_LOGIN: 'Credenciais de login inválidas',
            USER_BLOCKED: 'A conta está bloqueada. Por favor, entre em contato com o suporte',
            USER_REGISTERED: 'O usuário com o e-mail ou número de telefone inserido já está registrado',
            INVALID_USER: `O usuário não foi encontrado`,
            REJECTED: 'A operação não é permitida devido a restrições de plataforma',
            INVALID_OLD_PASSWORD: 'A senha antiga é inválida',
            INVALID_SYMBOL: 'Símbolo de negociação inválido',
            INVALID_SYMBOL_PRECISION: 'Precisão de símbolo inválida',
            INVALID_FRAME: 'Quadro de gráfico inválido',
            INVALID_CURRENCY: 'Moeda inválida',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Tipo de negociação de conta inválido',
            INSUFFICIENT_FUNDS: 'Fundos insuficientes',
            INVALID_WITHDRAWAL: 'A retirada não foi encontrada',
            INVALID_STATUS: 'Status inválido',
            INVALID_FILE: 'Extensão de arquivo inválida ou o arquivo é muito grande',
            INVALID_DOCUMENT: 'O documento não foi encontrado',
            INVALID_NAME: 'Nome inválido',
            INVALID_LOT_SIZE: 'Tamanho do lote inválido',
            INVALID_LOT_STEP: 'Etapa do lote inválida',
            INVALID_MIN_VOLUME: 'Volume mínimo inválido',
            INVALID_MAX_VOLUME: 'Volume máximo inválido',
            INVALID_GROUP: 'Grupo inválido',
            INVALID_SPREAD: 'Propagação inválida',
            REQUEST_TIMEOUT: 'A solicitação expirou. Por favor, tente novamente mais tarde',
            EXISTING_OPEN_ORDERS: 'Você tem um pedido em aberto, primeiro feche o pedido para este usuário e tente novamente.',
            INVALID_TYPE: 'Tipo de pedido inválido',
            INVALID_VOLUME: 'Volume de ordem inválido',
            INVALID_SL: 'Valor de stop loss inválido',
            INVALID_TP: 'Valor de take profit inválido',
            INVALID_PRICE: 'Preço do pedido inválido',
            INVALID_EXPIRATION: 'Expiração inválida da ordem',
            NOT_ENOUGH_MARGIN: 'Margem insuficiente',
            INVALID_ORDER: 'A ordem não foi encontrada',
            MARKET_CLOSED: 'A negociação não está disponível. Por favor, tente novamente',
            INVALID_BALANCE_TYPE: 'Tipo inválido de operação de saldo',
            INVALID_HASH: 'Hash inválido',
            HASH_EXPIRED: 'Seu link de redefinição de senha expirou. Por favor, solicite um novo',
            INVALID_CODE: 'Código 2FA inválido',
            CHAT_DISABLED: 'O acesso ao bate-papo de suporte foi encerrado',
            WITHDRAWAL_NOT_ALLOWED: 'O acesso às retiradas foi fechado',
            TRADING_DISABLED: 'A negociação está desativada para a conta atual',
            PENDING_DEPOSITS_LIMIT: 'Limite de depósitos excedido',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Somente um saque pendente é permitido',
            LOCAL_DEPOSIT_ONLY_REAL: 'O depósito está disponível somente para contas reais',
            LOCAL_DEPOSIT_POSITIVE: 'O valor do depósito deve ser maior que 0',
            LOCAL_INVALID_CARD_NUMBER: 'Número de cartão inválido',
            LOCAL_INVALID_CARD_EXPIRATION: 'Validade do cartão inválida',
            LOCAL_INVALID_CVC: 'Código CVV/CVC inválido',
            LOCAL_PASSWORDS_NOT_MATCH: 'As senhas não correspondem, por favor, digite novamente',
            LOCAL_NO_TRADING_ACCOUNTS: 'Você não tem contas de negociação disponíveis. Por favor, entre em contato com o suporte',
            LOCAL_MIN_DEPOSIT: 'O depósito mínimo é de $',
            LOCAL_MIN_WITHDRAWAL: 'O valor mínimo de saque é $',
            LOCAL_INVALID_FILE_TYPE: 'Formato de arquivo não suportado. Favor anexar somente JPG, PNG ou PDF',
            LOCAL_MIN_STAKING: 'O depósito mínimo para essa moeda é ',
            FLOOD_REJECTED: 'Você está enviando mensagens com muita frequência. Por favor, tente novamente mais tarde'
        },
        countries: {
            AF: 'Afeganistão',
            AX: 'Ilhas Aland',
            AL: 'Albânia',
            DZ: 'Argélia',
            AS: 'Samoa Americana',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antártica',
            AG: 'Antígua e Barbuda',
            AR: 'Argentina',
            AM: 'Armênia',
            AW: 'Aruba',
            AU: 'Austrália',
            AT: 'Áustria',
            AZ: 'Azerbaijão',
            BS: 'Bahamas',
            BH: 'Bahrein',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Bélgica',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Butão',
            BO: 'Bolívia',
            BA: 'Bósnia e Herzegovina',
            BW: 'Botsuana',
            BV: 'Ilha Bouvet',
            BR: 'Brasil',
            IO: 'Território Britânico do Oceano Índico',
            BN: 'Brunei Darussalam',
            BG: 'Bulgária',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Camboja',
            CM: 'Camarões',
            CA: 'Canadá',
            CV: 'Cabo Verde',
            KY: 'Ilhas Cayman',
            CF: 'República Centro-Africana',
            TD: `Chade`,
            CL: 'Chile',
            CN: 'China',
            CX: 'Ilha Christmas',
            CC: 'Ilhas Cocos (Keeling)',
            CO: 'Colômbia',
            KM: 'Comoros',
            CG: 'Congo',
            CD: 'Congo, República Democrática',
            CK: 'Ilhas Cook',
            CR: 'Costa Rica',
            CI: `Cote D'Ivoire`,
            HR: 'Croácia',
            CU: 'Cuba',
            CY: 'Chipre',
            CZ: 'República Tcheca',
            DK: 'Dinamarca',
            DJ: 'Djibuti',
            DM: 'Dominica',
            DO: 'República Dominicana',
            EC: 'Equador',
            EG: 'Egito',
            SV: 'El Salvador',
            GQ: 'Guiné Equatorial',
            ER: 'Eritreia',
            EE: 'Estônia',
            ET: 'Etiópia',
            FK: 'Ilhas Falkland (Malvinas)',
            FO: 'Ilhas Faroe',
            FJ: 'Fiji',
            FI: 'Finlândia',
            FR: 'França',
            GF: 'Guiana Francesa',
            PF: 'Polinésia Francesa',
            TF: 'Territórios Franceses do Sul',
            GA: 'Gabão',
            GM: `Gâmbia`,
            GE: 'Geórgia',
            DE: 'Alemanha',
            GH: 'Gana',
            GI: 'Gibraltar',
            GR: 'Grécia',
            GL: 'Groenlândia',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guiné',
            GW: 'Guiné-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Ilha Heard e Ilhas Mcdonald',
            VA: 'Santa Sé (Estado da Cidade do Vaticano)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungria',
            IS: 'Islândia',
            IN: 'Índia',
            ID: 'Indonésia',
            IR: 'Irã',
            IQ: 'Iraque',
            IE: 'Irlanda',
            IM: 'Ilha de Man',
            IL: 'Israel',
            IT: 'Itália',
            JM: 'Jamaica',
            JP: 'Japão',
            JE: 'Jersey',
            JO: 'Jordânia',
            KZ: 'Cazaquistão',
            KE: 'Quênia',
            KI: 'Kiribati',
            KR: 'Coreia',
            KW: 'Kuwait',
            KG: 'Quirguistão',
            LA: 'República Democrática Popular do Laos',
            LV: 'Letônia',
            LB: 'Líbano',
            LS: 'Lesotho',
            LR: 'Libéria',
            LY: 'Jamahiriya Árabe Líbia',
            LI: 'Liechtenstein',
            LT: 'Lituânia',
            LU: 'Luxemburgo',
            MO: 'Macau',
            MK: 'Macedônia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malásia',
            MV: 'Maldivas',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Ilhas Marshall',
            MQ: 'Martinica',
            MR: 'Mauritânia',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'México',
            FM: 'Micronésia',
            MD: 'Moldova',
            MC: 'Mônaco',
            MN: 'Mongólia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Marrocos',
            MZ: 'Moçambique',
            MM: 'Myanmar',
            NA: 'Namíbia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Países Baixos',
            AN: 'Antilhas Holandesas',
            NC: 'Nova Caledônia',
            NZ: 'Nova Zelândia',
            NI: 'Nicarágua',
            NE: 'Níger',
            NG: 'Nigéria',
            NU: 'Niue',
            NF: 'Ilha Norfolk',
            MP: 'Ilhas Marianas do Norte',
            NO: 'Noruega',
            OM: 'Omã',
            PK: 'Paquistão',
            PW: 'Palau',
            PS: 'Território Palestino, Ocupado',
            PA: 'Panamá',
            PG: 'Papua Nova Guiné',
            PY: 'Paraguai',
            PE: 'Peru',
            PH: 'Filipinas',
            PN: 'Pitcairn',
            PL: 'Polônia',
            PT: 'Portugal',
            PR: 'Porto Rico',
            QA: 'Qatar',
            RE: 'Reunião',
            RO: 'Romênia',
            RU: 'Rússia',
            RW: 'Ruanda',
            BL: 'São Bartolomeu',
            SH: 'Santa Helena',
            KN: 'São Cristóvão e Névis',
            LC: 'Santa Lúcia',
            MF: 'Saint Martin',
            PM: 'São Pedro e Miquelon',
            VC: 'São Vicente e Granadinas',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'São Tomé e Príncipe',
            SA: 'Arábia Saudita',
            SN: 'Senegal',
            RS: 'Sérvia',
            SC: 'Seychelles',
            SL: 'Serra Leoa',
            SG: 'Cingapura',
            SK: 'Eslováquia',
            SI: 'Eslovênia',
            SB: 'Ilhas Salomão',
            SO: 'Somália',
            ZA: 'África do Sul',
            GS: 'Ilhas Geórgia do Sul e Sandwich',
            ES: 'Espanha',
            LK: 'Sri Lanka',
            SD: 'Sudão',
            SR: 'Suriname',
            SJ: 'Svalbard e Jan Mayen',
            SZ: 'Suazilândia',
            SE: 'Suécia',
            CH: 'Suíça',
            SY: 'República Árabe da Síria',
            TW: 'Taiwan',
            TJ: 'Tajiquistão',
            TZ: 'Tanzânia',
            TH: 'Tailândia',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad e Tobago',
            TN: 'Tunísia',
            TR: 'Turquia',
            TM: 'Turcomenistão',
            TC: 'Ilhas Turcas e Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ucrânia',
            AE: 'Emirados Árabes Unidos',
            GB: 'Reino Unido',
            US: 'Estados Unidos',
            UM: 'Ilhas Distantes dos Estados Unidos',
            UY: 'Uruguai',
            UZ: 'Uzbequistão',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnã',
            VG: 'Ilhas Virgens Britânicas',
            VI: 'Ilhas Virgens Americanas',
            WF: 'Wallis e Futuna',
            EH: 'Saara Ocidental',
            YE: 'Iêmen',
            ZM: 'Zâmbia',
            ZW: 'Zimbábue'
        },
    },
    jp: {
        _name: '日本語',
        timePickerModal: {
            title: 'クローズ・イン・タイム'
        },
        newDesign: {
            withdrawalRequests: '出金履歴がありません',
            withdrawalRequestsText1: '出金が行われたことがありません',
            withdrawalRequestsText2: '出金されると、詳細がここに表示されます',
            pdfAttachment: 'PDF添付ファイル',
            agent: "エージェント",
            closed: '完了',
            pending: '保留中',
            approved: '承認済み',
            declined: '却下',
            document: '書類',
            frontSide: '表面',
            backSide: '裏面',
            address: '住所',
            frontSideDocument: '書類表面',
            creditCard: 'クレジットカード',
            selfie: '自撮り',
            other: 'その他',
            noDocument: 'アップロードされた書類がありません',
            amountDeposited: '入金額',
            makeTransfer: '振り込む',
            otherDetails: '注文内容',
            manualWallets: '暗号通貨ウォレット',
            depositText: '入金は5～10分以内に処理されます',
            pendingBankTransfer: '保留中の振込入金があります。',
            completeDeposit: '以下の手順に沿って入金を完了してください',
            downloadInvoice: '請求書をダウンロード',
            payInvoice: '銀行で請求書を支払う',
            useYourBankText1: '銀行のオンライン・オフラインのお支払いシステムで請求書を支払ってください。',
            useYourBankText2: '支払い情報をよくご確認ください。',
            uploadReceipt: '確認用の領収書をアップロード',
            afterMakingPayment: '支払い後、領収書をアップロードして入金をご確認ください',
            clickToUpload: 'クリックしてアップロード',
            orDrag: 'またはドラッグ＆ドロップ',
            fileFormat: 'PDF、JPG、JPEG、PNG 10MB以下',
            waitForDeposit: '入金確認をお待ちください',
            onceReceipt: '領収書がアップロードされると、こちらで確認して入金を承認します',
            paymentDetails: 'お支払いの詳細',
            nameOnCard: 'カード名義',
            cardNumber: 'カード番号',
            expirationDate: '有効期限',
            securityCode: 'セキュリティコード',
            continue: '続ける',
            viewAccounts: 'アカウントを確認する',
            continueWithdrawal: '出金を続ける',
            accountBalance: 'アカウント残高',
            accountBalanceText1: 'なぜ取引できないのですか？？',
            accountsBalanceText2: '入金のやり方を教えてください',
            accountsBalanceText3: 'Zelleでお金を入金したい',
            tradingActions: '取引操作',
            tradingActionsText1: '空売りするにはどうすればいいですか？',
            tradingActionsText2: '取引をキャンセルするにはどうすればいいですか？',
            tradingActionsText3: '最低取引額はいくらですか？',
            marketInfo: '市場情報',
            marketInfoText1: '市場の動向はどこで見られますか？',
            marketInfoText2: '今日の値上がり率上位ランキングは？',
            securityAndSettings: 'セキュリティと設定',
            securityAndSettingsText1: 'パスワードを変更したいです。',
            securityAndSettingsText2: 'どんなセキュリティ機能がありますか？',
            securityAndSettingsText3: 'アカウント情報を更新したいです。',
            messageCopied: 'メッセージがクリップボードにコピーされました',
            addSymbol: '記号を追加する',
            iHaveRead: '利用規約の内容に同意する',
            toggleToTurn: '自動売買をオンにする',
            selectTheRisk: 'リスクレベルを選択',
            confirm: '確定',
            cancel: 'キャンセル',
            chooseClosingTime: '終了時間を選択',
            setOrderClosingTime: '注文終了時間を設定',
            closingTime: '終了時間',
            date: '日付',
            time: '時間',
            setCloseTime: '終了時間を設定',
            '15m': '15分',
            '30m': '30分',
            '1h': '1時間',
            custom: 'カスタム',
            verifyOtp: 'OTPを認証する',
            enterDigits: '[メール/電話]に送信された6桁の認証コードを入力してください',
            submit: '送信する',
            order: '注文',
            closedSuccessfully: 'が正常に終了しました',
            openSuccessfully: '正常に開始しました',
            deposit: '入金',
            withdrawal: '出金',
            completeVerification: '認証を完了する',
            verification: '認証',
            avatar: 'アバター',
            saveChanges: '変更を保存する',
            darkMode: 'ダークモード',
            dashboardCurrency: '通貨ダッシュボード',
            language: '言語',
            twoFactor: '2段階認証',
            googleTwoFactorIsActive: 'Googleの2段階認証が有効になっています',
            activateCodeForGoogle: 'Google認証コードを有効にする',
            ifYouWishDisableEnterCode: '認証を無効にする場合は、認証アプリのコードを入力してください',
            scanOrManuallyEnter: 'Google 2FAでQRコードをスキャンするか、手動で入力してトークンを追加してください',
            copyCode: 'コードをコピーする',
            qrCode: 'QRコード',
            activateAndEnterAuthenticator: '認証アプリを有効化し、生成されたコードを以下の欄に入力してください',
            enterTheDigitsCodeGenerated: '生成された6桁のコードを入力してください',
            disableTwoFactor: '2段階認証を無効にする',
            activateTwoFactor: '2段階認証を有効にする',
            fileAdded: 'ファイルが追加されました',
            theVerificationStatus: '認証状況はアップロード済み書類一覧で確認できます',
            confirmWithdrawal: '出金を確認する',
            minimumWithdrawal: '最低出金額は',
            welcomeToTradingPlatform: '取引プラットフォームへようこそ',
            signIn: 'サインイン',
            activeAccount: 'アクティブアカウント',
            save: '保存',
            selectCurrencyAndPeriod: '通貨とサブスクリプション期間を選択',
            savingsNotFound: '預金口座が見つかりませんでした',
            pleaseCheckInvestment: '続行するには、投資詳細をご確認ください',
            allPeriodInvestment: '全期間',
            pct_0: 'フレキシブル',
            pct_30: '1ヶ月',
            pct_60: '1四半期',
            pct_90: '6ヶ月',
            pct_180: '6ヶ月',
            pct_365: '1年',
            savingsCreated: '預金口座が正常に作成されました',
            transferDetails: '振込詳細',
            transferAmount: '振込額',
            investmentDetails: '投資詳細',
            investment: '投資',
            Period: '期間',
            created: '作成済み',
            releaseTime: '解約時間',
            viewSavingsAccounts: '預金口座を確認する',
            continueInvestment: '投資を続ける',
            chooseSubscription: 'サブスクリプションを選択',
            selectCurrencyAndSubscription: '続行するには、通貨とサブスクリプション期間を選択してください',
            rate: 'レート',
            accountTransferFunds: '資金を振り込むアカウント',
            amount: '金額',
            'in': 'に',
            openSavingsAndInvest: '預金口座を開設して投資を始める',
            strongSell: '強い売り',
            sell: '売り',
            neutral: '中立',
            buy: '買い',
            stringBuy: '強い買い',
            chooseCryptoWallet: '‘暗号通貨ウォレットを選択',
            chooseMethod: '方法を選択してください',
            maximumDeposit: '最大入金額は',
            minimumDeposit: "最低入金額は",
            pleaseCompleteAddress: 'ご住所を入力してください',
            pleaseCompleteCity: '市区町村を入力してください',
            pleaseCompleteCountry: '国を入力してください',
            pleaseCompleteZip: '郵便番号を入力してください',
            amountDeposit: '入金額',
            transactionStatus: '取引状況',
            paymentMethod: '支払い方法',
            depositTo: '預入先',
            depositAddress: '預入用アドレス',
            scanTheQr: 'QRコードをスキャンするか、預入用アドレスをコピーしてください',
            copyDepositAddress: '入金アドレスをコピー',
            copy: 'コピー',
            addressCopied: 'アドレスがクリップボードにコピーされました',
            '5min:': '5-10分',
            '2-5days': '2-5日 / 5-10分',
            otherMethod: 'その他の方法',
            profileSettings: 'プロフィール設定',
            savingsAccounts: '預金口座',
            subscribe: 'サブスクリプション登録',
            toCompleteVerification: 'アカウント認証を完了するには、以下の見えやすい書類のコピーを提出してください',
            amountToWithdrawal: '出金額',
            cardDetails: 'カード詳細',
            cardHolderName: 'カード名義人',
            withdrawalSuccessful: '出金が完了しました',
            selectWithdrawalMethod: '出金方法を選択',
            creditDebit: 'クレジット/デビットカード',
            crypto: '暗号通貨',
            cryptoWalletAddress: '暗号通貨ウォレットアドレス',
            cryptoSubtitle: '暗号資産のウォレットへの出金 🚀',
            bankWithdrawal: '銀行出金',
            bankSubtitle: '銀行口座 🏦',
            zelleSubtitle: '高速銀行振込 ⚡🏦',
            tradingResults: '取引結果',
            day: '日',
            month: '月',
            noTradingData: '取引データがありません',
            startTradingToSee: '取引されると、結果がここに表示されます',
            yourPerformanceMetric: 'データが利用可能になり次第、パフォーマンス指標が表示されます。',
            favorite: 'お気に入り',
            all: 'すべて',
            availableFullTime: '24時間年中無休で対応可能',
            yourProfileAndSettings: 'プロフィールと設定',
            switchToNewDesignMessage: '今すぐ新しいプラットフォームデザインに切り替えましょう！',
            switchToOldDesignMessage: '期間限定のクラシック・デザインはまだ使用可能',
            switchToOldDesignButton: '新しいデザインに変更',
            switchToNewDesignButton: 'クラシックなデザインに回帰',
            generateInvoice: '請求書を生成する',
            downloadPdf: 'PDFをダウンロード',
            confirmDeposit: '入金を確認する',
            howCanIAssist: '今日はどのようにお手伝いできますか？',
            maxFileSize: '最大ファイルサイズは5MBで、対応フォーマットはJPEG、PNG、またはGIFです',
            uploadPhoto: '写真をアップロード',
            featuresDisabled: 'この機能は無効です',
            welcome: 'ようこそ',
            signUp: 'サインアップ',
            registration: '登録',
            pleaseConfirmAgreement: '顧客契約に同意してください',
            pleaseEnterPassword: 'パスワードを入力してください',
            passwordNotSame: 'パスワードが一致しません',
            noActiveOrders: 'アクティブな注文はありません',
            itLikeNoHaveActiveOrders: '現在アクティブな注文がないようです',
            cryptoWallet: '暗号ウォレット',
            bankCard: '銀行カード',
            selected: '選択済み',
            amountWithdrawal: '引き出された金額',
            withdrawalTo: '引き出し先',
            governmentIssuedPhoto1: '政府発行の写真付き身分証明書（例：パスポート、運転免許証、国民身分証明書）',
            governmentIssuedPhoto2: '在留許可証（該当する場合）',
            governmentIssuedPhoto3: '書類は有効でなければなりません（期限切れでないこと）',
            governmentIssuedPhoto4: '氏名、生年月日、写真がはっきりと見える必要があります。',
            uploadRecent1: '最近発行された公共料金の請求書、銀行取引明細書、または賃貸契約書をアップロードしてください（過去3ヶ月以内）',
            uploadRecent2: '氏名と現在の居住住所がはっきりと見えることを確認してください。',
            uploadCopy1: 'クレジットカードの表面コピーをアップロードし、最初の6桁と最後の4桁が見えることを確認してください',
            uploadCopy2: '安全のために中央の数字を隠してください',
            takeAndUpload1: '顔の横にIDを持っているセルフィーを撮影してアップロードしてください',
            takeAndUpload2: '顔とIDの両方がはっきりと見えることを確認してください',
            otherDocuments: 'その他の書類',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: '社会保障番号（SSN）文書の鮮明で判読可能な画像をアップロードしてください。すべての詳細が見えるようにし、遮るものがないことを確認してください。',
            frontSideSsnDocument: 'SSN文書の表側',
            answerSaved: '回答が保存されました',
            cryptoWalletDetails: '暗号ウォレットの詳細',
            bitcoinWalletAddress: 'ビットコインウォレットアドレス',
            recovery: '回復',
            passwordRecovery: 'パスワードの回復',
            searchCountry: '国を検索',
            questionnaire: 'アンケート',
            questionnaireText: 'ご本人確認のためにアンケートにご回答ください',
            sendAnswers: '回答を送信',
        },
        autoCloseModal: {
            takeProfitStopLoss: '利益確定とストップロス',
            takeProfit: '利益を得る',
            stopLoss: 'ストップ・ロス',
            updateOrder: '更新オーダー',
        },
        balanceModal: {
            balance: 'バランス',
            credit: 'クレジット',
            equity: 'エクイティ',
            practice: '実践',
            real: 'リアル',
            account: 'アカウント',
            invested: '投資済み',
            profit: '利益',
            loss: '損失',
            margin: 'マージン',
            marginLevel: 'マージンレベル',
            marginFree: 'フリー・マージン'
        },
        header: {
            cfd: 'CFD',
            platform: 'プラットフォーム',
            deposit: 'デポジット'
        },
        sideMenu: {
            marketWatch: 'マーケット・ウォッチ',
            activeOrders: 'アクティブオーダー',
            tradingHistory: '取引履歴',
            economicCalendar: 'カレンダー',
            marketNews: 'マーケットニュース'
        },
        closePositionModal: {
            closeOrder: 'クローズポジション',
            areYouSure: 'ポジションをクローズしてよろしいですか？',
            buy: '購入',
            sell: '売る',
            yes: 'はい',
            no: 'いいえ',
        },
        pciDssModal: {
            desc: `Payment Card Industry Data Security Standard（PCI DSS）への準拠は、Visa/Master Card/Stripeのカード会員データを保存、処理、または送信するすべての事業体（金融機関、加盟店、プロバイダを含む）に義務付けられています。
            カード会員データを保存、処理、または送信するすべての事業体（金融機関、加盟店、サービスプロバイダを含む）に対して要求されます。
            IT は、カード会員データおよび/または機密性の高い認証データを保存、処理、または送信するすべての事業体に対する世界的なセキュリティ基準です。PCI DSS は、消費者を保護するための基準レベルを設定し、決済エコシステム全体における詐欺行為やデータ漏えいの削減を支援します。`
        },
        chart: {
            addNewChart: '新しいチャートを追加する',
        },
        symbolsModal: {
            watchlist: 'ウォッチリスト',
            asset: '資産',
            price: '価格',
            changePct: '24時間変更'
        },
        pendingModal: {
            pending: '申請中',
            assetPrice: '資産価格',
            current: '現在',
            revert: '市場価格に戻す',
            automatically: '価格がこのレベルに達すると自動的にポジションがオープンされます。'
        },
        orderMenu: {
            default: 'デフォルト',
            volume: 'ボリューム',
            lots: 'ロット',
            units: '単位',
            currency: '通貨',
            contractSize: '契約サイズ',
            position: 'ポジション',
            margin: 'マージン',
            freeMargin: 'フリーマージン',
            takeProfitStopLoss: 'テイクプロフィット & ストップロス',
            pending: '保留中',
            market: 'マーケット',
            leverage: 'レバレッジ',
            spread: 'スプレッド',
            notSet: '設定されていません',
            at: 'で',
            buy: '買い',
            sell: '売り',
        },
        footer: {
            supportBanner: '毎日、24時間対応',
            currentTime: '現在時刻',
            liveChat: 'ライブチャット'
        },
        statusBar: {
            balance: '残高',
            credit: 'クレジット',
            equity: '証拠金残高',
            margin: 'マージン',
            marginLevel: 'マージンレベル',
            freeMargin: 'フリーマージン',
            pnl: '損益',
            profitTotal: '累計損益'
        },
        accountModal: {
            dateRegistered: '登録日',
            userId: 'ユーザーID',
            dashboard: 'ダッシュボード',
            personalData: '個人情報',
            deposit: '入金',
            withdrawFunds: '出金',
            savings: '貯蓄',
            settings: '設定',
            logout: 'ログアウト'
        },
        depositModal: {
            BT_INVOICE: '金額を入力して請求書を生成',
            BT_TITLE: '当社の銀行詳細',
            account: '口座',
            bank_name: '銀行名',
            beneficiary: '受取人',
            beneficiary_address: '受取人住所',
            swift: 'スウィフト',
            reference: '参照',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'コルレス口座',
            TOKEN_AMOUNT: '金額',
            TOKEN_SUBTOTAL: '小計',
            TOKEN_TOTAL: '合計',
            TOKEN_DESCRIPTION: '説明',
            TOKEN_PAYMENT_DETAILS: '支払い詳細',
            PENDING_DEPOSITS_LIMIT: '入金限度額',
            makeDeposit: '入金する',
            practiceAccount: '練習用口座',
            realAccount: 'リアル口座',
            noWithdrawals: '出金不可',
            easyWithdrawals: '簡単な出金',
            allAssets: 'すべての資産利用可能',
            fullFledged: '完全なプラットフォーム',
            fillUpTo: '最大補充額',
            freeReplenishment: '無料補充',
            topUp: '口座にチャージ',
            minimumAmount: '最小金額',
            canSwitch: 'いつでも口座を切り替え可能'
        },
        gridsModal: {
            chartGrids: 'チャートグリッド',
            chart1: '1チャート',
            chart2: '2チャート',
            chart3: '3チャート',
            chart4: '4チャート',
        },
        noConnectionModal: {
            connectionLost: 'サーバーとの接続が失われました',
            retryNow: '今すぐ再試行'
        },
        loginModal: {
            loginToTradeRoom: 'トレードルームにログイン',
            email: 'メールアドレス',
            enterEmail: 'メールアドレスを入力してください',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FAコード（有効な場合）',
            twoFactorAuth: '二要素認証',
            password: 'パスワード',
            yourPassword: 'あなたのパスワード',
            signIn: 'サインイン',
            dontHaveAccount: 'アカウントをお持ちではありませんか？',
            restore: '復元',
            fullName: '氏名',
            havePromo: 'プロモコードをお持ちですか',
            promo: 'プロモコード',
            login: 'ログイン',
            haveAccount: 'すでにアカウントをお持ちですか？',
            hide: '隠す',
            goTo: '戻る'
        },
        newPasswordModal: {
            setNewPassword: '新しいパスワードを設定する',
            newPassword: '新しいパスワード',
            confirmNewPassword: '新しいパスワードを確認',
            setButton: '設定する'
        },
        registerModal: {
            repeatPassword: 'パスワードを再入力する',
            createNewAccount: '新しいアカウントを作成する',
            email: 'メールアドレス',
            firstName: '名（ファーストネーム)',
            lastName: '姓（ラストネーム',
            country: '国',
            phone: '電話番号',
            password: 'パスワード',
            createAccount: 'アカウントを作成する',
            currency: '通貨',
            privacyAndPolicy: 'プライバシーとポリシー',
            customerAgreement: '利用規約 (カスタマーアグリーメント)',
        },
        forgotPasswordModal: {
            forgotPassword: 'パスワードをお忘れですか',
            submitEmail: '登録に使用したメールアドレスを送信してください。受信トレイを確認し、記載された指示に従ってください',
            submit: '送信'
        },
        notifications: {
            error: 'エラー',
            success: '成功',
            deposit: '入金',
            withdrawal: '出金',
            depositApproved: 'が承認されました',
            depositDeclined: '入金が拒否されました',
            withdrawalApproved: '出金が承認されました',
            withdrawalDeclined: '出金が拒否されました'
        },
        markets: {
            favorites: 'ウォッチリスト', 
            all: 'すべてのシンボル',
            forex: '外国為替',
            stocks: '株式',
            commodities: '商品（コモディティ',
            indices: '株価指数 (インデックス)',
            crypto: '暗号通貨 (クリプト)',
            metals: '貴金属',
            nft: '（非代替性トークン'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: '購入',
            profitCalculator: '利益計算機',
            sell: '売却',
            volume: '取引量',
            entryPrice: 'エントリープライス（開始価格）',
            takeProfit: '利益確定 (テイクプロフィット)',
            stopLoss: '損切り (ストップロス)',
            maxPosition: '最大ポジション',
            calculate: '計算する',
            calculationsFor: '計算対象',
            leverage: 'レバレッジ',
            requiredMargin: '必要証拠金',
            profitFromTP: '利益確定（TP）からの利益',
            lossFromSL: '損切り（SL）からの損失',
            roe: '自己資本利益率 (ROE)',
        },
        depositSuccessModal: {
            title: '入金が成功しました',
            text: 'あなたの入金は正常に処理されました！',
        },
        depositFailModal: {
            title: '入金に失敗しました',
            text: 'あなたの入金は処理されませんでした。',
        },
        widgetMarketWatch: {
            marketWatch: 'マーケットウォッチ',
            search: '検索...',
        },
        widgetOrders: {
            commission: '手数料',
            volume: '取引量',
            margin: '証拠金',
            active: 'アクティブ',
            pending: '保留中',
            activeOrders: 'アクティブ注文',
            portfolio: 'ポートフォリオ',
            allPositions: 'すべてのポジション',
            noPositionsLine1: '開いているものはありません',
            noPositionsLine2: 'まだポジションがありません',
            show: '表示',
            more: 'もっと表示する',
            less: '少なく表示する',
            purchaseTime: '購入時間',
            closePosition: 'ポジションをクローズする',
            priceOpen: '購入価格',
            priceSl: '損切り価格 (ストップロス価格)',
            priceTp: '利益確定価格 (テイクプロフィット価格)',
            type: 'ポジションの方向',
            pnl: '利益/損失',
            cancelOrder: '注文をキャンセルする',
            orderType: '注文タイプ',
            cancelled: 'キャンセルされました',
            tradingHistory: '取引履歴',
            noHistoryLine1: '取引履歴はありません。',
            noHistoryLine2: 'まだクローズした取引はありません。',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'カレンダー'
        },
        widgetMarketNews: {
            marketNews: 'マーケットニュース'
        },
        ordersPane: {
            closeInTime: '時間内にクローズする',
            timedOrder: 'タイムドオーダー',
            ordersActive: 'アクティブオーダー',
            ordersHistory: '注文履歴',
            id: 'ID',
            symbol: 'シンボル',
            type: 'タイプ',
            volume: 'ボリューム',
            openPrice: 'オープン価格',
            openTime: 'オープン時間',
            closePrice: 'クローズ価格',
            closeTime: 'クローズ時間',
            sl: '（ストップロス）',
            tp: '（テイクプロフィット）',
            price: '価格',
            pnl: '利益と損失',
            actions: 'アクション',
            edit: '編集',
            close: '閉じる',
            commission: '手数料',
            swap: 'スワップ',
            swapLong: 'ロングスワップ',
            swapShort: 'ショートスワップ'
        },
        assetInfoModal: {
            assetInfo: '資産情報',
            tradingConditions: '取引条件',
            information: 'セッション変更',
            bid: 'ビッド',
            ask: 'アスク',
            sessionChange: 'セッションの変更',
            tradeNow: '今すぐ取引する',
            opens: 'オープン',
            closes: 'クローズ',
            at: 'で',
            open247: '24時間営業',
            today: '今日',
            tomorrow: '明日',
            sunday: '日曜日',
            monday: '月曜日',
            tuesday: '火曜日',
            wednesday: '水曜日',
            thursday: '木曜日',
            friday: '金曜日',
            saturday: '土曜日',
            contractSpecification: '契約仕様',
            symbol: 'シンボル',
            name: '名前',
            market: 'マーケット',
            baseCurrency: '基軸通貨',
            digits: '桁数',
            lotSize: 'ロットサイズ',
            lotStep: 'ロットステップ',
            minVolume: '最小取引量',
            maxVolume: '最大取引量',
            leverage: 'レバレッジ',
            commission: '手数料',
            swapLong: 'ロングスワップ',
            swapShort: 'ショートスワップ',
            schedule: 'スケジュール',
            weekday: '平日',
            tradingTime: '取引時間',
            closed: 'クローズ済み',
            sell: '売却',
            buy: '購入',
            low: '安値',
            high: '高値',
            oneHourChange: '1時間の変化',
            oneDayChange: '1日の変化',
            oneWeekChange: '1週間の変化',
            oneMonthChange: '1ヶ月の変化',
            threeMonthsChange: '3ヶ月の変化',
            oneYearChange: '1年の変化',
            loading: '読み込み中...',
        },
        autoTrader: {
            days: '日数',
            title: '自動トレーダー',
            titleAi: 'AI Trader',
            customTitle: '自動トレーダーの時間範囲',
            riskLevelLow: 'リスクレベル: 低',
            riskLevelMedium: 'リスクレベル: 中',
            riskLevelHigh: 'リスクレベル: 高い',
            perDay: '1日の取引回数:',
            desc: `CFDやその他のレバレッジ製品の取引は、損失を引き起こす可能性があります。取引を行う前に、クライアントはリスク開示ページの関連リスク声明を読むべきです。自動取引は結果を保証しません。会社は自動取引における資金の損失について一切責任を負いません。リスクを完全に理解し、リスク管理の手段を講じるようにしてください。承諾する`,
            save: '保存する',
            cancel: 'キャンセル',
        },
        cardBox: {
            name: '名前',
            cardNumber: 'カード番号',
            expiration: '有効期限 (mm/yy)',
            securityCode: '(カード確認コード)'
        },
        fullScreen: {
            hi: 'こんにちは',
            trade: "取引",
            startTrading: '取引を開始する',
            deposit: '入金',
            dashboard: 'ダッシュボード',
            personalInfo: '個人情報',
            contactInfo: '連絡先情報',
            withdrawal: '出金',
            verification: '本人確認',
            accounts: 'アカウント',
            liveChat: 'ライブチャット',
            savings: '貯蓄',
            referrals: '紹介プログラム',
            settings: '設定',
            logOut: 'ログアウト',
        },
        fullScreenDeposit: {
            methods: '方法',
            confirm: 'あなたの銀行のアプリで確認してください。',
            sendOtp: 'OTPを送信する',
            otpCode: 'OTPコード',
            addWalletAddress: 'この入金方法のウォレットが設定されていません。エージェントまたはサポートチームにお問い合わせください。',
            addressReceipt: 'アドレスが読み込まれるまでお待ちください。',
            makeDeposit: '入金する',
            instant: '即時',
            minutes: '分',
            hours: '時間',
            days: '日',
            amount: '金額',
            continue: '続ける',
            qrCode: 'QRコード',
            depositAddress: '入金アドレス',
            copy: 'コピーする',
            last: '最後の',
            deposits: '入金',
            time: '時間',
            currency: '通貨',
            status: 'ステータス',
            info: '情報',
        },
        fullScreenCcExt: {
            pleaseWait: 'お待ちください。決済サービスプロバイダーに接続中です...',
            cardPayment: 'カード決済',
            inOrder: '金額を送信するためには、',
            pleaseProvide: 'カードの詳細を入力してください：'
        },
        fullScreenDashboard: {
            completeVerification: '本人確認を完了してください。',
            noOrderYet: 'まだ注文はありません。',
            totalBalance: '総残高',
            totalPnl: '総損益 ',
            profitableOrders: '利益のある注文',
            roi: '投資利益率',
            activityLog: 'アクティビティログ',
            loginFromIp: 'IPからのログイン',
            tradingResults: '取引結果',
            week: '週',
            month: '月',
            year: '年',
            successRate: '成功率',
            closedWithProfit: '利益でクローズしました',
            closedWithLoss: '損失でクローズしました',
            account: 'アカウント',
            balance: '残高',
            leverage: 'レバレッジ',
            credit: 'クレジット',
            tradeNow: '今すぐ取引する',
            usingCurrentRate: '現在の為替レートを使用して',
            totalDeposits: '総入金額',
            totalWithdrawals: "合計引き出し額",
            returnOnInvestment: "投資収益率"
        },
        fullScreenPersonal: {
            personalDetails: '個人情報',
            profilePhoto: 'プロフィール写真',
            firstName: '名（ファーストネーム）',
            country: '国',
            lastName: '姓（ラストネーム）',
            address: '住所',
            email: 'メールアドレス',
            phone: '電話番号',
            saveChanges: '変更を保存する',
            state: '市町村',
            postcode: '郵便番号',
            created: 'アカウント作成日',
        },
        fullScreenAvatar: {
            dropFile: '上の円にファイルをドラッグ＆ドロップしてアップロードしてください。',
            notAllowed: '公開することは許可されていません。',
            notAllowedLine1: '露骨な性的またはポルノ的な性質の写真',
            notAllowedLine2: '民族的または人種的憎悪や侵略を扇動することを目的とした画像',
            notAllowedLine3: '18歳未満が写っている写真',
            notAllowedLine4: '第三者の著作権保護された写真',
            notAllowedLine5: '5MBを超え、JPG、GIF、PNG以外の形式の画像',
            requirements: 'あなたの顔がはっきりと写っていること。あなたがアップロードしたすべての写真とビデオは、これらの要件に準拠している必要があります。'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'お引出しの詳細については、アカウント・マネージャーにお問い合わせください。',
            cryptocurrency: '暗号通貨',
            withdrawal: '退会',
            requestNewWithdrawal: '新規引き出しを申請する',
            amount: '金額',
            currentBalance: '現在の口座残高は',
            withdrawAll: 'すべて辞退する',
            requestWithdrawal: '退会申請',
            last: '最後',
            withdrawalRequests: '退会申請',
            time: '時間',
            currency: '通貨',
            info: 'インフォメーション',
            status: 'ステータス',
            bank: '銀行',
            bitcoin: 'ビットコイン',
            zelle: 'ツェレ',
            bnb: 'BNB',
            card: 'カード',
            ethereum: 'イーサリアム',
            eth: 'イーサリアム',
            usdt: '米ドル',
            matic: 'マティック',
            withdrawalDetails: '退会の詳細',
            address: '財布の住所',
            bankDetails: '銀行情報',
            bankAccount: '口座番号',
            bankHolder: '口座名義人の名前',
            bankIban: 'IBAN',
            bankSwift: '銀行SWIFTコード',
            recipientAddress: '受取人の住所',
            routingNumber: '銀行ルーティング番号',
            bankBeneficiary: '受取人の銀行名',
            bankAddress: '銀行の住所',
            bankCode: '銀行コード（BSB）',
            desc: '説明',
            cardNumber: 'カード番号',
            cardHolder: 'カード名義',
        },
        fullScreenVerification: {
            dragAndDrop: 'ドキュメントをこのエリアにドラッグ＆ドロップする',
            documentsList: 'アップロードされたドキュメントのリスト',
            document: 'ドキュメント',
            timeUploaded: 'アップロード時間',
            timeProcessed: '処理時間',
            status: 'ステータス',
            types: ['身分証明', '居住証明', 'クレジットカード・フロント', 'クレジットカード・バック', '身分証明書', 'セルフィー', 'Other'],
            types2: ['身分証明書（POI）', '居住地の住所証明', 'クレジットカード・フロント', 'クレジットカード・バック', '身分証明書 戻る', 'ID付きセルフィー'],
            upload: 'アップロード',
            isConfirmed: 'が確認された',
            uploadAnotherDocument: '別の文書をアップロードする',
            isUnderReview: '検討中',
            questionnaire: 'アンケート',
            sendAnswers: '回答を送信',
            progressBarOf: 'の',
            progressBarText: '書類のアップロードと確認が完了しました。'
        },
        fullScreenAccounts: {
            manageAccounts: '口座間の資金移動',
            transferFrom: 'アカウントより',
            transferTo: '会計',
            amount: '金額',
            makeTransfer: '譲渡する',
            yourAccounts: 'アカウント',
            account: 'アカウント',
            currency: '通貨',
            balance: 'バランス',
            credit: 'クレジット',
            makeActive: 'アクティブにする',
            active: 'アクティブ'
        },
        fullScreenSupport: {
            sendMessage: 'メッセージを送る'
        },
        fullScreenSavings: {
            pct_0: 'フレキシブル',
            pct_30: 'ロック1ヶ月',
            pct_90: 'ロック1クォーター',
            pct_180: 'ロック6ヶ月',
            pct_365: 'ロック1年',
            savings: '貯蓄',
            detailedInformation: '詳細情報',
            pleaseSelect: '通貨と期間を選択してください。',
            openSavings: 'オープン・セービング',
            currentTime: '現在時刻',
            releaseTime: 'リリース時間',
            currencyChosen: '選択された通貨',
            periodChosen: '選ばれた期間',
            yourRate: 'レート',
            yourEarnings: 'あなたの収入',
            accountFrom: '送金元口座',
            enterAmount: '金額を入力',
            in: 'で',
            openAndInvest: '普通預金口座の開設と投資',
            investment: '投資',
            period: '期間',
            daysTotal: '合計日数',
            finalEarnings: '最終利益',
            created: '作成',
            daysElapsed: '経過日数',
            days: '日',
            earnings: '収益',
            fundsReleasedIn: '資金放出',
            claim: 'クレーム',
            name: "名前",
        },
        fullScreenSettings: {
            updatePassword: 'パスワード更新',
            currentPassword: '現在のパスワード',
            newPassword: '新しいパスワード',
            changePassword: 'パスワードの変更',
            activationForGoogle: 'Google Authenticatorのアクティベーションコード',
            activateAndEnter: 'Authenticatorを起動し、以下のフィールドに生成されたコードを入力します。',
            qrCode: 'QRコード',
            activateProtection: '2FA保護を有効にする',
            protectionActive: '2FA保護が有効',
            twoFactorCode: '2FAコード',
            disableProtection: '2FA保護を無効にする',
            language: '言語',
            dashboardCurrency: 'ダッシュボードの通貨',
            confirmNewPassword: '新しいパスワードの確認',
        },
        fullScreenReferrals: {
            yourLink: 'あなたの紹介リンク',
            referredFriends: '友人紹介',
            id: '身分証明書',
            name: '名称',
            registered: '登録',
            level: 'レベル',
            payout: '支払い',
            lastPayouts: '最後の紹介料支払い',
            time: '時間',
            amount: '金額',
            currency: '通貨',
            info: 'インフォメーション',
            referralDesc:"紹介プログラムは、価値ある投資家コミュニティの拡大に貢献してくれた方への報酬を目的としています。",
            shareLink:"リンクを共有してください：",
            subtitle:"名前、メールアドレス、または取引IDを伝えてください。",
            descSuccesfull:"紹介された方が無事に登録を完了すると、ファイナンシャルアドバイザーが連絡を取り、投資の第一歩を最も効果的かつ専門的にサポートします。",
            descTracked:"彼らのすべての貢献はあなたの紹介として追跡されます。"
        },
        months: {
            Jan: 'ヤン',
            Feb: '2月',
            Mar: 'マー',
            Apr: '4月',
            May: '月',
            Jun: 'ジュン',
            Jul: 'ジュル',
            Aug: 'オーグ',
            Sep: 'セプ',
            Oct: 'オクト',
            Nov: 'ノヴ',
            Dec: 'デック'
        },
        orderTypes: ['購入', '売る', 'リミット購入', '売り指値', 'ストップ高', '売りストップ'],
        statuses: ['申請中', '承認済み', '辞退', '加工'],
        errors: {
            NOT_FOUND: 'リクエストエラー',
            FORBIDDEN: 'アクセスが拒否されました。再ログインしてください。',
            SERVER_ERROR: '操作に失敗しました。後で再試行してください。',
            INVALID_ACCOUNT: 'セッションの有効期限が切れました。再ログインしてください。',
            INVALID_EMAIL: '無効な電子メールアドレス',
            INVALID_PHONE: '無効な電話番号',
            INVALID_COUNTRY: '無効な国',
            INVALID_LOGIN: '無効なログイン認証情報',
            USER_BLOCKED: 'アカウントがブロックされています。サポートまでご連絡ください。',
            USER_REGISTERED: '入力された電子メールまたは電話番号のユーザーがすでに登録されている',
            INVALID_USER: 'ユーザーが見つかりません',
            REJECTED: 'プラットフォームの制限により操作不可',
            INVALID_OLD_PASSWORD: '古いパスワードは無効です',
            INVALID_SYMBOL: '無効な取引シンボル',
            INVALID_SYMBOL_PRECISION: '無効なシンボル精度',
            INVALID_FRAME: '無効なチャートフレーム',
            INVALID_CURRENCY: '無効な通貨',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: '無効な口座取引タイプ',
            INSUFFICIENT_FUNDS: '資金不足',
            INVALID_WITHDRAWAL: '引き出しが見つからない',
            INVALID_STATUS: '無効なステータス',
            INVALID_FILE: '無効なファイル拡張子、またはファイルが大きすぎる',
            INVALID_DOCUMENT: 'ドキュメントが見つかりません',
            INVALID_NAME: '無効な名前',
            INVALID_LOT_SIZE: '無効な敷地面積',
            INVALID_LOT_STEP: '無効なロットステップ',
            INVALID_MIN_VOLUME: '無効な最小ボリューム',
            INVALID_MAX_VOLUME: '無効な最大音量',
            INVALID_GROUP: '無効なグループ',
            INVALID_SPREAD: '無効なスプレッド',
            REQUEST_TIMEOUT: 'リクエストがタイムアウトしました。後で再試行してください。',
            EXISTING_OPEN_ORDERS: '未決済の注文があるので、まずこのユーザーの注文を閉じてから、もう一度やり直してください。',
            INVALID_TYPE: '無効な注文タイプ',
            INVALID_VOLUME: '無効な注文量',
            INVALID_SL: '無効なストップロス値',
            INVALID_TP: '無効な利食い値',
            INVALID_PRICE: '無効な注文価格',
            INVALID_EXPIRATION: '無効な注文の有効期限',
            NOT_ENOUGH_MARGIN: 'マージン不足',
            INVALID_ORDER: '注文が見つかりません',
            MARKET_CLOSED: '取引ができません。もう一度お試しください。',
            INVALID_BALANCE_TYPE: '無効なバランス操作のタイプ',
            INVALID_HASH: '無効なハッシュ',
            HASH_EXPIRED: 'パスワードリセットリンクの有効期限が切れています。新しいパスワードをリクエストしてください。',
            INVALID_CODE: '無効な2FAコード',
            CHAT_DISABLED: 'サポートチャットへのアクセスが終了しました',
            WITHDRAWAL_NOT_ALLOWED: '出金へのアクセスは閉鎖されました',
            TRADING_DISABLED: '当座預金は取引不可',
            PENDING_DEPOSITS_LIMIT: '預金限度額超過',
            LOCAL_ONE_PENDING_WITHDRAWAL: '保留中の引き出しは1回のみ',
            LOCAL_DEPOSIT_ONLY_REAL: '入金はリアル口座のみ',
            LOCAL_DEPOSIT_POSITIVE: '預金額は0ドル以上でなければならない',
            LOCAL_INVALID_CARD_NUMBER: '無効なカード番号',
            LOCAL_INVALID_CARD_EXPIRATION: '無効なカードの有効期限',
            LOCAL_INVALID_CVC: '無効なCVV/CVCコード',
            LOCAL_PASSWORDS_NOT_MATCH: 'パスワードが一致しません。',
            LOCAL_NO_TRADING_ACCOUNTS: '取引口座がありません。サポートまでご連絡ください。',
            LOCAL_MIN_DEPOSIT: '最低入金額',
            LOCAL_MIN_WITHDRAWAL: '最低引き出し額',
            LOCAL_INVALID_FILE_TYPE: 'ファイル形式はサポートされていません。JPG、PNG、PDFのみ添付してください。',
            LOCAL_MIN_STAKING: 'この通貨の最低保証金は ',
            FLOOD_REJECTED: 'メッセージの送信頻度が高すぎます。後でもう一度お試しください。'
        },
        countries: {
            AF: 'アフガニスタン',
            AX: 'アランド諸島',
            AL: 'アルバニア',
            DZ: 'アルジェリア',
            AS: '米領サモア',
            AD: 'アンドラ',
            AO: 'アンゴラ',
            AI: 'アングィラ',
            AQ: '南極大陸',
            AG: 'アンティグア・バーブーダ',
            AR: 'アルゼンチン',
            AM: 'アルメニア',
            AW: 'アルバ',
            AU: 'オーストラリア',
            AT: 'オーストリア',
            AZ: 'アゼルバイジャン',
            BS: 'バハマ',
            BH: 'バーレーン',
            BD: 'バングラデシュ',
            BB: 'バルバドス',
            BY: 'ベラルーシ',
            BE: 'ベルギー',
            BZ: 'ベリーズ',
            BJ: 'ベナン',
            BM: 'バーミューダ',
            BT: 'ブータン',
            BO: 'ボリビア',
            BA: 'ボスニア・ヘルツェゴビナ',
            BW: 'ボツワナ',
            BV: 'ブーベ島',
            BR: 'ブラジル',
            IO: '英領インド洋地域',
            BN: 'ブルネイ',
            BG: 'ブルガリア',
            BF: 'ブルキナファソ',
            BI: 'ブルンジ',
            KH: 'カンボジア',
            CM: 'カメルーン',
            CA: 'カナダ',
            CV: 'カーボベルデ',
            KY: 'ケイマン諸島',
            CF: '中央アフリカ共和国',
            TD: 'チャド',
            CL: 'チリ',
            CN: '中国',
            CX: 'クリスマス島',
            CC: 'ココス（キーリング）諸島',
            CO: 'コロンビア',
            KM: 'コモロ',
            CG: 'コンゴ',
            CD: 'コンゴ民主共和国',
            CK: 'クック諸島',
            CR: 'コスタリカ',
            CI: 'コートジボワール',
            HR: 'クロアチア',
            CU: 'キューバ',
            CY: 'キプロス',
            CZ: 'チェコ共和国',
            DK: 'デンマーク',
            DJ: 'ジブチ',
            DM: 'ドミニカ',
            DO: 'ドミニカ共和国',
            EC: 'エクアドル',
            EG: 'エジプト',
            SV: 'エルサルバドル',
            GQ: '赤道ギニア',
            ER: 'エリトリア',
            EE: 'エストニア',
            ET: 'エチオピア',
            FK: 'フォークランド諸島（マルビナス）',
            FO: 'フェロー諸島',
            FJ: 'フィジー',
            FI: 'フィンランド',
            FR: 'フランス',
            GF: 'フランス領ギアナ',
            PF: 'フランス領ポリネシア',
            TF: 'フランス領南方領土',
            GA: 'ガボン',
            GM: 'ガンビア',
            GE: 'グルジア',
            DE: 'ドイツ',
            GH: 'ガーナ',
            GI: 'ジブラルタル',
            GR: 'ギリシャ',
            GL: 'グリーンランド',
            GD: 'グレナダ',
            GP: 'グアドループ',
            GU: 'グアム',
            GT: 'グアテマラ',
            GG: 'ガーンジー',
            GN: 'ギニア',
            GW: 'ギニアビサウ',
            GY: 'ガイアナ',
            HT: 'ハイチ',
            HM: 'ハード島＆マクドナルド諸島',
            VA: 'バチカン市国',
            HN: 'ホンジュラス',
            HK: '香港',
            HU: 'ハンガリー',
            IS: 'アイスランド',
            IN: 'インド',
            ID: 'インドネシア',
            IR: 'イラン',
            IQ: 'イラク',
            IE: 'アイルランド',
            IM: 'マン島',
            IL: 'イスラエル',
            IT: 'イタリア',
            JM: 'ジャマイカ',
            JP: '日本',
            JE: 'ジャージー',
            JO: 'ヨルダン',
            KZ: 'カザフスタン',
            KE: 'ケニア',
            KI: 'キリバス',
            KR: '韓国',
            KW: 'クウェート',
            KG: 'キルギス',
            LA: 'ラオス人民民主共和国',
            LV: 'ラトビア',
            LB: 'レバノン',
            LS: 'レソト',
            LR: 'リベリア',
            LY: 'リビア・アラブ・ジャマーヒリーヤ',
            LI: 'リヒテンシュタイン',
            LT: 'リトアニア',
            LU: 'ルクセンブルク',
            MO: 'マカオ',
            MK: 'マケドニア',
            MG: 'マダガスカル',
            MW: 'マラウイ',
            MY: 'マレーシア',
            MV: 'モルディブ',
            ML: 'マリ',
            MT: 'マルタ',
            MH: 'マーシャル諸島',
            MQ: 'マルティニーク',
            MR: 'モーリタニア',
            MU: 'モーリシャス',
            YT: 'マヨット',
            MX: 'メキシコ',
            FM: 'ミクロネシア',
            MD: 'モルドバ',
            MC: 'モナコ',
            MN: 'モンゴル',
            ME: 'モンテネグロ',
            MS: 'モンセラット',
            MA: 'モロッコ',
            MZ: 'モザンビーク',
            MM: 'ミャンマー',
            NA: 'ナミビア',
            NR: 'ナウル',
            NP: 'ネパール',
            NL: 'オランダ',
            AN: 'オランダ領アンティル',
            NC: 'ニューカレドニア',
            NZ: 'ニュージーランド',
            NI: 'ニカラグア',
            NE: 'ニジェール',
            NG: 'ナイジェリア',
            NU: 'ニウエ',
            NF: 'ノーフォーク島',
            MP: '北マリアナ諸島',
            NO: 'ノルウェー',
            OM: 'オマーン',
            PK: 'パキスタン',
            PW: 'パラオ',
            PS: 'パレスチナ占領地',
            PA: 'パナマ',
            PG: 'パプアニューギニア',
            PY: 'パラグアイ',
            PE: 'ペルー',
            PH: 'フィリピン',
            PN: 'ピトケアン',
            PL: 'ポーランド',
            PT: 'ポルトガル',
            PR: 'プエルトリコ',
            QA: 'カタール',
            RE: '再会',
            RO: 'ルーマニア',
            RU: 'ロシア',
            RW: 'ルワンダ',
            BL: 'サン・バルテルミー',
            SH: 'セントヘレナ',
            KN: 'セントクリストファー・ネイビス',
            LC: 'セントルシア',
            MF: 'セント・マーチン',
            PM: 'サンピエール島・ミクロン島',
            VC: 'セントビンセント・グレナディーン',
            WS: 'サモア',
            SM: 'サンマリノ',
            ST: 'サントメ・プリンシペ',
            SA: 'サウジアラビア',
            SN: 'セネガル',
            RS: 'セルビア',
            SC: 'セーシェル',
            SL: 'シエラレオネ',
            SG: 'シンガポール',
            SK: 'スロバキア',
            SI: 'スロベニア',
            SB: 'ソロモン諸島',
            SO: 'ソマリア',
            ZA: '南アフリカ',
            GS: 'サウス・ジョージア島とサンドイッチ島',
            ES: 'スペイン',
            LK: 'スリランカ',
            SD: 'スーダン',
            SR: 'スリナム',
            SJ: 'スバールバル諸島とヤンマイエン島',
            SZ: 'スワジランド',
            SE: 'スウェーデン',
            CH: 'スイス',
            SY: 'シリア・アラブ共和国',
            TW: '台湾',
            TJ: 'タジキスタン',
            TZ: 'タンザニア',
            TH: 'タイ',
            TL: '東ティモール',
            TG: 'トーゴ',
            TK: 'トケラウ',
            TO: 'トンガ',
            TT: 'トリニダード・トバゴ',
            TN: 'チュニジア',
            TR: 'トルコ',
            TM: 'トルクメニスタン',
            TC: 'タークス・カイコス諸島',
            TV: 'ツバル',
            UG: 'ウガンダ',
            UA: 'ウクライナ',
            AE: 'アラブ首長国連邦',
            GB: 'イギリス',
            US: '米国',
            UM: 'アメリカ離島',
            UY: 'ウルグアイ',
            UZ: 'ウズベキスタン',
            VU: 'バヌアツ',
            VE: 'ベネズエラ',
            VN: 'ベトナム',
            VG: '英領ヴァージン諸島',
            VI: '米バージン諸島',
            WF: 'ウォリス・フテュナ',
            EH: '西サハラ',
            YE: 'イエメン',
            ZM: 'ザンビア',
            ZW: 'ジンバブエ'
        },
    },
    hr: {
        _name: 'Hrvatski',
        newDesign: {
            withdrawalRequests: 'Još nema zahtjeva za isplatu',
            withdrawalRequestsText1: 'Do sada niste nijedan zahtjev za isplatu.',
            withdrawalRequestsText2: 'Nakon što započnete isplatu, ovdje će se pojaviti detalji vaše transakcije',
            pdfAttachment: 'PDF prilog',
            agent: "Agent",
            closed: 'Zatvoreno',
            pending: 'Na čekanju',
            approved: 'Odobreno',
            declined: 'Odbijeno',
            document: 'Dokument',
            frontSide: 'Prednja Strana',
            backSide: 'Stražnja Strana',
            address: 'Adresa',
            frontSideDocument: 'Prednja strana dokumenta',
            creditCard: 'Kreditna Kartica',
            selfie: 'Selfie',
            other: 'Ostalo',
            noDocument: 'Još nema učitanih dokumenata',
            amountDeposited: 'Depozit iznos',
            makeTransfer: 'Napravi Prijenos',
            otherDetails: 'Detalji narudžbe',
            manualWallets: 'Kripto novčanici',
            depositText: 'Depozit se obrađuje unutar 5-10 minuta',
            pendingBankTransfer: 'Imate bankovni depozit na čekanju.',
            completeDeposit: 'Slijedite upute u nastavku kako biste izvršili depozit',
            downloadInvoice: 'Preuzmite fakturu',
            payInvoice: 'Platite fakturu u svojoj banci',
            useYourBankText1: 'Upotrijebite mrežni ili izvanmrežni sustav plaćanja svoje banke za plaćanje fakture.',
            useYourBankText2: 'Provjerite jesu li podaci o plaćanju točni.',
            uploadReceipt: 'Prenesite potvrdu primitka',
            afterMakingPayment: 'Nakon što izvršite uplatu, prenesite svoju potvrdu da potvrdite depozit',
            clickToUpload: 'Kliknite za Prijenos',
            orDrag: 'ili povucite i ispustite',
            fileFormat: 'PDF, JPG, JPEG, PNG manji od 10MB',
            waitForDeposit: 'Pričekajte potvrdu depozita',
            onceReceipt: 'Nakon što se račun učita, provjerit ćemo i odobriti vaš depozit',
            paymentDetails: 'Podaci o plaćanju',
            nameOnCard: 'Ime na kartici',
            cardNumber: 'Broj kartice',
            expirationDate: 'Datum isteka',
            securityCode: 'Sigurnosni kod',
            continue: 'Nastaviti',
            viewAccounts: 'Prikaz Računa',
            continueWithdrawal: 'Nastavi isplatu',
            accountBalance: 'Stanje Računa',
            accountBalanceText1: 'Zašto ne mogu izvršiti trgovinu?',
            accountsBalanceText2: 'Kako mogu položiti depozit?',
            accountsBalanceText3: 'Želim položiti novac putem Zellea',
            tradingActions: 'Trgovačke Akcije',
            tradingActionsText1: 'Kako mogu prodati na kratko?',
            tradingActionsText2: 'Kako mogu otkazati trgovinu?',
            tradingActionsText3: 'Koji je minimalni iznos trgovine?',
            marketInfo: 'Informacije o Tržištu',
            marketInfoText1: 'Gdje mogu vidjeti tržišne trendove?',
            marketInfoText2: 'Koji su najveći dobitnici danas?',
            securityAndSettings: 'Sigurnost i Postavke',
            securityAndSettingsText1: 'Kako mogu promijeniti svoju lozinku?',
            securityAndSettingsText2: 'Koje su sigurnosne značajke?',
            securityAndSettingsText3: 'Kako mogu ažurirati podatke o svom računu?',
            messageCopied: 'Poruka kopirana u međuspremnik',
            addSymbol: 'Dodaj Simbol',
            iHaveRead: 'Pročitao sam i slažem se s odredbama i uvjetima',
            toggleToTurn: 'Prebacite da biste uključili AutoTrade',
            selectTheRisk: 'Odaberite razinu rizika',
            confirm: 'Potvrdi',
            cancel: 'Otkazati',
            chooseClosingTime: 'Odaberite vrijeme zatvaranja',
            setOrderClosingTime: 'Postavite Vrijeme Zatvaranja Narudžbe',
            closingTime: 'Vrijeme Zatvaranja',
            date: 'Datum',
            time: 'Vrijeme',
            setCloseTime: 'Postavi Vrijeme Zatvaranja',
            '15m': '15 Minuta',
            '30m': '30 Minuta',
            '1h': '1 sat',
            custom: 'Običaj',
            verifyOtp: 'Potvrdite Svoj OTP',
            enterDigits: 'Unesite 6-znamenkasti kod poslan na vašu [e-poštu/telefon]',
            submit: 'Pošalji',
            order: 'Nalog',
            closedSuccessfully: 'je uspješno zatvoren',
            openSuccessfully: 'otvoriti uspješno',
            deposit: 'depozit',
            withdrawal: 'povlačenje',
            completeVerification: 'Dovrši Potvrdu',
            verification: 'Provjera',
            avatar: 'Avatar',
            saveChanges: 'Spremi Promjene',
            darkMode: 'Tamni način rada',
            dashboardCurrency: 'Valuta Nadzorne Ploče',
            language: 'Jezik',
            twoFactor: '2-faktorska Zaštita',
            googleTwoFactorIsActive: 'Google 2-faktor autentifikatora je aktivan',
            activateCodeForGoogle: 'Aktiviraj kod za Google Authenticator',
            ifYouWishDisableEnterCode: 'Ako želite onemogućiti zaštitu, unesite kod aplikacije autentifikatora',
            scanOrManuallyEnter: 'Skenirajte ili ručno unesite QR kod u Google 2FA za dodavanje tokena',
            copyCode: 'Kopiraj Kod',
            qrCode: 'QR Kod',
            activateAndEnterAuthenticator: 'Aktivirajte Autentifikator i unesite generirani kod u polje ispod',
            enterTheDigitsCodeGenerated: 'Unesite generirani 6-znamenkasti kod',
            disableTwoFactor: 'Onemogući Dvofaktorsku Zaštitu',
            activateTwoFactor: 'Aktiviraj 2FA Zaštitu',
            fileAdded: 'Datoteka je uspješno dodana',
            theVerificationStatus: 'Status provjere može se vidjeti na popisu učitanih dokumenata',
            confirmWithdrawal: 'Potvrdi isplatu',
            minimumWithdrawal: 'Minimalni iznos za podizanje je',
            welcomeToTradingPlatform: 'Dobrodošli na platformu za trgovanje',
            signIn: 'Prijava',
            activeAccount: 'Aktivni račun',
            save: 'Uštedjeti',
            selectCurrencyAndPeriod: 'Odaberite valutu i razdoblje pretplate',
            savingsNotFound: 'Štedni Računi Nisu Pronađeni',
            pleaseCheckInvestment: 'Provjerite svoje podatke o ulaganju za nastavak',
            allPeriodInvestment: 'Ulaganje za sva razdoblja',
            pct_0: 'Fleksibilan',
            pct_30: '1 Mjesec',
            pct_60: '1 Četvrtina',
            pct_90: '6 Mjesec',
            pct_180: '6 Mjesec',
            pct_365: '1 Godina',
            savingsCreated: 'Štedni račun uspješno kreiran',
            transferDetails: 'Detalji Prijenosa',
            transferAmount: 'Iznos Prijenosa',
            investmentDetails: 'Detalji Ulaganja',
            investment: 'Investicija',
            Period: 'Period',
            created: 'Stvoreno',
            releaseTime: 'Vrijeme oslobađanja',
            viewSavingsAccounts: 'Prikaži Štedne Račune',
            continueInvestment: 'Nastavak Investicije',
            chooseSubscription: 'Odaberi Svoju Pretplatu',
            selectCurrencyAndSubscription: 'Odaberite valutu i razdoblje pretplate za nastavak.',
            rate: 'Stopa',
            accountTransferFunds: 'Račun za prijenos sredstava',
            amount: 'Iznositi',
            'in': 'u',
            openSavingsAndInvest: 'Otvori Štedni Račun i Investiraj',
            strongSell: 'Snažna prodaja',
            sell: 'Prodavati',
            neutral: 'Neutralan',
            buy: 'Kupiti',
            stringBuy: 'Jaka kupnja',
            chooseCryptoWallet: 'Odaberi Novčanik za Kriptovalute',
            chooseMethod: 'Odaberite metodu',
            maximumDeposit: 'Maksimalni depozit je',
            minimumDeposit: "Minimalni depozit je",
            pleaseCompleteAddress: 'Molimo dopunite svoju adresu',
            pleaseCompleteCity: 'Molimo dovršite svoj grad',
            pleaseCompleteCountry: 'Molimo ispunite svoju zemlju',
            pleaseCompleteZip: 'Molimo ispunite svoj poštanski broj',
            amountDeposit: 'Iznos depozita',
            transactionStatus: 'Status transakcije',
            paymentMethod: 'Način plaćanja',
            depositTo: 'Depozit na',
            depositAddress: 'Adresa depozita',
            scanTheQr: 'Skenirajte QR kod ili kopirajte adresu depozita',
            wallet: 'Novčanik',
            copyDepositAddress: 'Kopiraj Adresu Depozita',
            copy: 'Kopiraj',
            addressCopied: 'Adresa kopirana u međuspremnik',
            '5min:': '5-10 minuta',
            '2-5days': '2-5 dana / 5-10 minuta',
            otherMethod: 'Druga metoda',
            profileSettings: 'Postavke Profila',
            savingsAccounts: 'Štedni Računi',
            subscribe: 'Pretplatite se',
            toCompleteVerification: 'Da biste dovršili postupak potvrde za svoj račun, dostavite jasne i čitke kopije sljedećih dokumenata',
            amountToWithdrawal: 'Iznos za povlačenje',
            cardDetails: 'Podaci o kartici',
            cardHolderName: 'Ime vlasnika kartice',
            withdrawalSuccessful: 'Povlačenje Uspješno',
            selectWithdrawalMethod: 'Odaberi način isplate',
            creditDebit: 'Kreditna/debitna Kartica',
            crypto: 'Kripto',
            cryptoWalletAddress: 'Adresa kripto novčanika',
            cryptoSubtitle: 'Kripto isplate na vaš novčanik 🚀',
            bankWithdrawal: 'Bankovno povlačenje',
            bankSubtitle: 'Bankovni račun 🏦',
            zelleSubtitle: 'Brzi bankovni prijenosi ⚡🏦',
            tradingResults: 'Rezultati trgovanja',
            day: 'Dan',
            month: 'Mjesec',
            noTradingData: 'Nema dostupnih podataka o trgovanju',
            startTradingToSee: 'Počnite trgovati kako biste ovdje vidjeli svoje rezultate.',
            yourPerformanceMetric: 'Mjerni podaci o izvedbi bit će prikazani kada podaci budu dostupni.',
            favorite: 'Omiljena',
            all: 'Sve',
            availableFullTime: 'Dostupan 24/7',
            yourProfileAndSettings: 'Vaš profil i postavke',
            switchToNewDesignMessage: 'Prijeđite Na Naš Novi Dizajn Platforme Sada!',
            switchToOldDesignMessage: 'Još uvijek možete koristiti klasični dizajn ograničeno vrijeme',
            switchToOldDesignButton: 'Prijeđi na Novi Dizajn',
            switchToNewDesignButton: 'Povratak na Klasični Dizajn',
            generateInvoice: 'Generiraj račun',
            downloadPdf: 'Preuzmi PDF',
            confirmDeposit: 'Potvrdi depozit',
            howCanIAssist: 'Kako vam mogu pomoći danas?',
            maxFileSize: 'Maksimalna veličina datoteke je 5 MB, prihvaćeni formati su JPEG, PNG ili GIF',
            uploadPhoto: 'Prenesi fotografiju',
            featuresDisabled: 'Ova značajka je onemogućena',
            welcome: 'Dobrodošli',
            signUp: 'Registriraj se',
            registration: 'Registracija',
            pleaseConfirmAgreement: 'Molimo potvrdite korisnički ugovor',
            pleaseEnterPassword: 'Molimo unesite lozinku',
            passwordNotSame: 'Lozinke se ne podudaraju',
            noActiveOrders: 'Nema aktivnih narudžbi',
            itLikeNoHaveActiveOrders: 'Čini se da trenutno nemate aktivnih narudžbi',
            cryptoWallet: 'Kripto novčanik',
            bankCard: 'Banka/Kartica',
            selected: 'Odabrano',
            amountWithdrawal: 'Iznos povučen',
            withdrawalTo: 'Povlačenje na',
            governmentIssuedPhoto1: 'Državna osobna iskaznica s fotografijom (npr. putovnica, vozačka dozvola, nacionalna osobna iskaznica)',
            governmentIssuedPhoto2: 'Dozvola boravka (ako je primjenjivo)',
            governmentIssuedPhoto3: 'Dokument mora biti važeći (ne smije biti istekao)',
            governmentIssuedPhoto4: 'Puno ime, datum rođenja i fotografija moraju biti vidljivi.',
            uploadRecent1: 'Prenesite nedavni račun za komunalne usluge, bankovni izvod ili ugovor o najmu izdan unutar posljednja 3 mjeseca',
            uploadRecent2: 'Provjerite je li vaše puno ime i trenutna adresa stanovanja jasno vidljiva.',
            uploadCopy1: 'Prenesite kopiju prednje strane vaše kreditne kartice, pazeći da su vidljive prve šest i posljednje četiri znamenke',
            uploadCopy2: 'Molimo pokrijte srednje znamenke radi sigurnosti',
            takeAndUpload1: 'Snimi selfie držeći osobnu iskaznicu pored lica i prenesi je',
            takeAndUpload2: 'Provjerite jesu li vaše lice i osobna iskaznica jasno vidljivi',
            otherDocuments: 'Ostali dokumenti',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Učitajte jasnu i čitljivu sliku svog dokumenta s brojem socijalnog osiguranja (SSN). Provjerite jesu li svi detalji vidljivi i bez prepreka.',
            frontSideSsnDocument: 'Prednja strana SSN dokumenta',
            answerSaved: 'Odgovori su spremljeni',
            cryptoWalletDetails: 'Detalji kripto novčanika',
            bitcoinWalletAddress: 'Bitcoin adresa novčanika',
            recovery: 'Oporavak',
            passwordRecovery: 'Oporavak lozinke',
            searchCountry: 'Traži zemlju',
            questionnaire: 'Upitnik',
            questionnaireText: 'Molimo vas da ispunite upitnik kako biste potvrdili svoj identitet',
            sendAnswers: 'Pošalji odgovore',
        },
        timePickerModal: {
            title: 'Vrijeme zatvaranja'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Uzmi profit i zaustavi gubitak',
            takeProfit: 'Uzmi profit',
            stopLoss: 'Zaustavi gubitak',
            updateOrder: 'Ažuriraj nalog',
        },
        balanceModal: {
            balance: 'Stanje',
            credit: 'Kredit',
            equity: 'Kapital',
            practice: 'VJEŽBA',
            real: 'STVARNI',
            account: 'RAČUN',
            invested: 'Uloženo',
            profit: 'Profit',
            loss: 'Gubitak',
            margin: 'Margina',
            marginLevel: 'Razina margine',
            marginFree: 'Slobodna margina'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platforma',
            deposit: 'Depozit'
        },
        sideMenu: {
            marketWatch: 'Tržišni pregled',
            activeOrders: 'Aktivni nalozi',
            tradingHistory: 'Povijest trgovanja',
            economicCalendar: 'Kalendar',
            marketNews: 'Tržišne vijesti'
        },
        closePositionModal: {
            closeOrder: 'Zatvori poziciju',
            areYouSure: 'Jeste li sigurni da želite zatvoriti poziciju',
            buy: 'Kupi',
            sell: 'Prodaj',
            yes: 'DA',
            no: 'NE',
        },
        pciDssModal: {
            desc: `Usklađenost sa standardom zaštite podataka platnih kartica (PCI DSS) obavezna je za sve subjekte koji pohranjuju, obrađuju ili prenose podatke o vlasnicima Visa/Master Card/Stripe kartica, uključujući financijske institucije, trgovce i pružatelje usluga.
            To je globalni sigurnosni standard za sve subjekte koji pohranjuju, obrađuju ili prenose podatke o vlasnicima kartica i/ili osjetljive podatke za autentifikaciju. PCI DSS postavlja osnovnu razinu zaštite za potrošače i pomaže smanjiti prijevare i proboje podataka unutar cijelog sustava plaćanja.`
        },
        chart: {
            addNewChart: 'Dodaj novi grafikon',
        },
        symbolsModal: {
            watchlist: 'Lista praćenja',
            asset: 'Imovina',
            price: 'Cijena',
            changePct: 'Promjena 24h'
        },
        pendingModal: {
            pending: 'Na čekanju',
            assetPrice: 'Cijena imovine',
            current: 'Trenutna',
            revert: 'Vrati na tržišnu cijenu',
            automatically: 'Pozicija će se automatski otvoriti kada cijena dosegne ovu razinu'
        },
        orderMenu: {
            default: 'zadano',
            volume: 'Volumen',
            lots: 'lotovi',
            units: 'jedinice',
            currency: 'valuta',
            contractSize: 'Veličina ugovora',
            position: 'Pozicija',
            margin: 'Margina',
            freeMargin: 'Slobodna margina',
            takeProfitStopLoss: 'Uzmi profit i zaustavi gubitak',
            pending: 'Na čekanju',
            market: 'Tržište',
            leverage: 'Poluga',
            spread: 'Raspon',
            notSet: 'Nije postavljeno',
            at: 'pri',
            buy: 'kupi',
            sell: 'prodaj',
        },
        footer: {
            supportBanner: 'SVAKI DAN, CIJELI DAN',
            currentTime: 'TRENUTNO VRIJEME',
            liveChat: 'Chat uživo'
        },
        statusBar: {
            balance: 'Stanje',
            credit: 'Kredit',
            equity: 'Kapital',
            margin: 'Margina',
            marginLevel: 'Razina margine',
            freeMargin: 'Slobodna margina',
            pnl: 'PnL',
            profitTotal: 'Ukupni PnL'
        },
        accountModal: {
            dateRegistered: 'Datum registracije',
            userId: 'Korisnički ID',
            dashboard: 'Nadzorna ploča',
            personalData: 'Osobni podaci',
            deposit: 'Depozit',
            withdrawFunds: 'Povuci sredstva',
            savings: 'Štednja',
            settings: 'Postavke',
            logout: 'Odjava'
        },
        depositModal: {
            BT_INVOICE: 'Unesite iznos i generirajte fakturu',
            BT_TITLE: 'Naši bankovni podaci',
            account: 'Račun',
            bank_name: 'Naziv banke',
            beneficiary: 'Primatelj',
            beneficiary_address: 'Adresa primatelja',
            swift: 'SWIFT',
            reference: 'Referenca',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Korespondentni račun',
            TOKEN_AMOUNT: 'Iznos',
            TOKEN_SUBTOTAL: 'Međuzbroj',
            TOKEN_TOTAL: 'Ukupno',
            TOKEN_DESCRIPTION: 'Opis',
            TOKEN_PAYMENT_DETAILS: 'Podaci o uplati',
            PENDING_DEPOSITS_LIMIT: 'Limit depozita',
            makeDeposit: 'Izvrši depozit',
            practiceAccount: 'Račun za vježbu',
            realAccount: 'Stvarni račun',
            noWithdrawals: 'Nema povlačenja',
            easyWithdrawals: 'Jednostavno povlačenje',
            allAssets: 'Svi dostupni resursi',
            fullFledged: 'Potpuna platforma',
            fillUpTo: 'Napunite do',
            freeReplenishment: 'Besplatno punjenje',
            topUp: 'Dodajte sredstva na svoj račun',
            minimumAmount: 'Minimalni iznos',
            canSwitch: 'Možete izmjenjivati svoje račune u bilo kojem trenutku'
        },
        gridsModal: {
            chartGrids: 'MREŽE GRAFIKONA',
            chart1: '1 grafikon',
            chart2: '2 grafikona',
            chart3: '3 grafikona',
            chart4: '4 grafikona',
        },
        noConnectionModal: {
            connectionLost: 'Veza s poslužiteljem je izgubljena',
            retryNow: 'Pokušajte ponovno',
        },
        loginModal: {
            loginToTradeRoom: 'Prijava u Traderoom',
            email: 'Email adresa',
            enterEmail: 'Unesite svoju email adresu',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA kod (ako je omogućen)',
            twoFactorAuth: 'Dvofaktorska autentifikacija',
            password: 'Lozinka',
            yourPassword: 'Vaša lozinka',
            signIn: 'Prijavite se',
            dontHaveAccount: 'Nemate račun?',
            restore: 'Obnova',
            fullName: 'Puno ime',
            havePromo: 'Imam promotivni kod',
            promo: 'Promotivni kod',
            login: 'Prijava',
            haveAccount: 'Već imate račun?',
            hide: 'Sakrij',
            goTo: 'Vrati se'
        },
        newPasswordModal: {
            setNewPassword: 'Postavite novu lozinku',
            newPassword: 'Nova lozinka',
            confirmNewPassword: 'Potvrdite novu lozinku',
            setButton: 'Postavite'
        },
        registerModal: {
            repeatPassword: 'Ponovite lozinku',
            createNewAccount: 'Kreirajte novi račun',
            email: 'Email adresa',
            firstName: 'Ime',
            lastName: 'Prezime',
            country: 'Država',
            phone: 'Telefon',
            password: 'Lozinka',
            createAccount: 'Kreirajte račun',
            currency: 'Valuta',
            privacyAndPolicy: 'Privatnost i politika',
            customerAgreement: 'Ugovor s korisnikom',
        },
        forgotPasswordModal: {
            forgotPassword: 'Zaboravljena lozinka?',
            submitEmail: 'Molimo unesite email koji ste koristili za registraciju, provjerite svoju inbox i slijedite upute koje su vam poslane',
            submit: 'Pošaljite'
        },
        notifications: {
            error: 'Greška',
            success: 'Uspjeh',
            deposit: 'Depozit',
            withdrawal: 'Povlačenje',
            depositApproved: 'je odobren',
            depositDeclined: 'Depozit je odbijen',
            withdrawalApproved: 'Povlačenje je odobreno',
            withdrawalDeclined: 'Povlačenje je odbijeno'
        },
        markets: {
            favorites: 'Popis praćenja', 
            all: 'Svi simboli',
            forex: 'Forex',
            stocks: 'Dionice',
            commodities: 'Robe',
            indices: 'Indeksi',
            crypto: 'Kriptovalute',
            metals: 'Metali',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Price from profit',
            buy: 'KUPI',
            profitCalculator: 'Kalkulator profita',
            sell: 'PRODAJ',
            volume: 'Volumen',
            entryPrice: 'Cijena ulaska',
            takeProfit: 'Uzmi profit',
            stopLoss: 'Zaustavi gubitak',
            maxPosition: 'Maksimalna pozicija',
            calculate: 'Izračunaj',
            calculationsFor: 'Izračuni za',
            leverage: 'Poluga',
            requiredMargin: 'Potrebna marža',
            profitFromTP: 'Profit od TP',
            lossFromSL: 'Gubitak od SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Depozit uspješan',
            text: 'Vaš depozit je uspješno obrađen!',
        },
        depositFailModal: {
            title: 'Depozit nije uspio',
            text: 'Vaš depozit nije obrađen.',
        },
        widgetMarketWatch: {
            marketWatch: 'Tržišni pregled',
            search: 'Pretraži...',
        },
        widgetOrders: {
            commission: 'Provizija',
            volume: 'Volumen',
            margin: 'Margina',
            active: 'Aktivno',
            pending: 'Na čekanju',
            activeOrders: 'Aktivni nalozi',
            portfolio: 'Portfelj',
            allPositions: 'Sve pozicije',
            noPositionsLine1: 'Nemate otvorenih',
            noPositionsLine2: 'pozicija',
            show: 'Prikaži',
            more: 'više',
            less: 'manje',
            purchaseTime: 'Vrijeme kupnje',
            closePosition: 'Zatvori poziciju',
            priceOpen: 'Cijena kupnje',
            priceSl: 'Cijena stop gubitka',
            priceTp: 'Cijena uzimanja profita',
            type: 'Smjer pozicije',
            pnl: 'Profit/Gubitak',
            cancelOrder: 'Otkazivanje naloga',
            orderType: 'Vrsta naloga',
            cancelled: 'Otkazano',
            tradingHistory: 'Povijest trgovanja',
            noHistoryLine1: 'Nemate nijednu',
            noHistoryLine2: 'zatvorenu transakciju',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Kalendar'
        },
        widgetMarketNews: {
            marketNews: 'Tržišne vijesti'
        },
        ordersPane: {
            closeInTime: 'Zatvori u vremenu',
            timedOrder: 'Nalog s vremenom',
            ordersActive: 'Aktivni nalozi',
            ordersHistory: 'Povijest naloga',
            id: 'ID',
            symbol: 'Simbol',
            type: 'Vrsta',
            volume: 'Volumen',
            openPrice: 'Cijena otvaranja',
            openTime: 'Vrijeme otvaranja',
            closePrice: 'Cijena zatvaranja',
            closeTime: 'Vrijeme zatvaranja',
            sl: 'SL',
            tp: 'TP',
            price: 'Cijena',
            pnl: 'PnL',
            actions: 'Akcije',
            edit: 'Uredi',
            close: 'Zatvori',
            commission: 'Provizija',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Informacije o imovini',
            tradingConditions: 'Uvjeti trgovanja',
            information: 'Promjena sesije',
            bid: 'Ponudi',
            ask: 'Pitaj',
            sessionChange: 'Promjena sesije',
            tradeNow: 'Trgujte sada',
            opens: 'Otvara se',
            closes: 'Zatvara se',
            at: 'u',
            open247: 'Otvoreno 24/7',
            today: 'danas',
            tomorrow: 'sutra',
            sunday: 'Nedjelja',
            monday: 'Ponedjeljak',
            tuesday: 'Utorak',
            wednesday: 'Srijeda',
            thursday: 'Četvrtak',
            friday: 'Petak',
            saturday: 'Subota',
            contractSpecification: 'Specifikacija ugovora',
            symbol: 'Simbol',
            name: 'Ime',
            market: 'Tržište',
            baseCurrency: 'Osnovna valuta',
            digits: 'Brojevi',
            lotSize: 'Veličina lota',
            lotStep: 'Korak lota',
            minVolume: 'Minimalni volumen',
            maxVolume: 'Maksimalni volumen',
            leverage: 'Poluga',
            commission: 'Provizija',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Raspored',
            weekday: 'Radni dan',
            tradingTime: 'Vrijeme trgovanja',
            closed: 'zatvoreno',
            sell: 'Prodaj',
            buy: 'Kupi',
            low: 'Niska',
            high: 'Visoka',
            oneHourChange: 'Promjena u 1 satu',
            oneDayChange: 'Promjena u 1 danu',
            oneWeekChange: 'Promjena u 1 tjednu',
            oneMonthChange: 'Promjena u 1 mjesecu',
            threeMonthsChange: 'Promjena u 3 mjeseca',
            oneYearChange: 'Promjena u 1 godini',
            loading: 'Učitavanje...',
        },
        autoTrader: {
            days: 'dani',
            title: 'Autotrader',
            titleAi: 'AI Trader',
            customTitle: 'Vrijeme trajanja Autotradera',
            riskLevelLow: 'Razina rizika: Niska',
            riskLevelMedium: 'Razina rizika: Srednja',
            riskLevelHigh: 'Razina rizika: Visoka',
            perDay: 'Transakcije po danu:',
            desc: `Trgovanje CFD-ovima i drugim proizvodima s polugom može dovesti do gubitaka. Prije trgovanja, klijenti bi trebali pročitati odgovarajuće izjave o riziku na našoj stranici s Objašnjenjem rizika. Automatizirano trgovanje ne jamči rezultate. Tvrtka ne prihvaća odgovornost za gubitak sredstava u automatiziranom trgovanju. Molimo vas da se uvjerite da u potpunosti razumijete rizike i poduzimate mjere za upravljanje rizicima.`,
            accept: 'Prihvat',
            save: 'Spremanje',
            cancel: 'Otkazivanje',
        },
        cardBox: {
            name: 'Ime',
            cardNumber: 'Broj kartice',
            expiration: 'Istek (mm/gg)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Pozdrav',
            trade: "Trgovanje",
            startTrading: 'Započnite trgovanje',
            deposit: 'Depozit',
            dashboard: 'Nadzorna ploča',
            personalInfo: 'Osobni podaci',
            contactInfo: 'Kontaktni podaci',
            withdrawal: 'Povlačenje',
            verification: 'Verifikacija',
            accounts: 'Računi',
            liveChat: 'Chat uživo',
            savings: 'Štednja',
            referrals: 'Preporučiteljski program',
            settings: 'Postavke',
            logOut: 'Odjava',
        },
        fullScreenDeposit: {
            methods: 'METODE',
            confirm: 'Potvrdite u aplikaciji vaše banke',
            sendOtp: 'Pošaljite OTP',
            otpCode: 'OTP kod',
            addWalletAddress: 'Novčanik za ovu metodu depozita nije postavljen. Molimo kontaktirajte vašeg agenta ili tim za podršku.',
            addressReceipt: 'Molimo pričekajte da se adresa učita',
            makeDeposit: 'NAPRAVITE DEPOZIT',
            instant: 'Instant',
            minutes: 'minute',
            hours: 'sati',
            days: 'dani',
            amount: 'Iznos',
            continue: 'Nastavite',
            qrCode: 'QR KOD',
            depositAddress: 'ADRESA ZA DEPOZIT',
            copy: 'Kopiraj',
            last: 'POSLJEDNJI',
            deposits: 'DEPOZITI',
            time: 'Vrijeme',
            currency: 'Valuta',
            status: 'Status',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Molimo pričekajte. Spajanje na pružatelja usluga plaćanja...',
            cardPayment: 'Plaćanje karticom',
            inOrder: 'Kako biste poslali iznos',
            pleaseProvide: 'molimo unesite podatke o kartici:'
        },
        fullScreenDashboard: {
            completeVerification: 'Molimo dovršite verifikaciju',
            noOrderYet: 'Još nema naloga',
            totalBalance: 'Ukupno stanje',
            totalPnl: 'Ukupni PnL',
            profitableOrders: 'Profitabilni nalozi',
            roi: 'ROI',
            activityLog: 'Dnevnik aktivnosti',
            loginFromIp: 'Prijava s IP-a',
            tradingResults: 'Rezultati trgovanja',
            week: 'Tjedan',
            month: 'Mjesec',
            year: 'Godina',
            successRate: 'Stopa uspješnosti',
            closedWithProfit: 'Zatvoreno s profitom',
            closedWithLoss: 'Zatvoreno s gubitkom',
            account: 'Račun',
            balance: 'Stanje',
            leverage: 'Poluga',
            credit: 'Kredit',
            tradeNow: 'Trgujte sada',
            usingCurrentRate: 'koristeći trenutni tečaj',
            totalDeposits: 'Ukupni depoziti',
            totalWithdrawals: "Ukupna povlačenja",
            returnOnInvestment: "Povrat ulaganja"
        },
        fullScreenPersonal: {
            personalDetails: 'Osobni podaci',
            profilePhoto: 'Profilna fotografija',
            firstName: 'Ime',
            country: 'Država',
            lastName: 'Prezime',
            address: 'Adresa',
            email: 'E-mail',
            phone: 'Broj telefona',
            saveChanges: 'Spremite promjene',
            state: 'Grad',
            postcode: 'Poštanski broj',
            created: 'Račun kreiran',
        },
        fullScreenAvatar: {
            dropFile: 'Spustite datoteku na krug iznad za učitavanje',
            notAllowed: 'Nije dopušteno objavljivati',
            notAllowedLine1: 'Fotografije eksplicitnog seksualnog ili pornografskog sadržaja',
            notAllowedLine2: 'Slike koje potiču etničku ili rasnu mržnju ili agresiju',
            notAllowedLine3: 'Fotografije koje uključuju osobe mlađe od 18 godina',
            notAllowedLine4: 'Fotografije zaštićene autorskim pravima trećih strana',
            notAllowedLine5: 'Slike veće od 5 MB i u formatu koji nije JPG, GIF ili PNG',
            requirements: 'Vaše lice mora biti jasno vidljivo na fotografiji. Sve fotografije i videozapisi koje učitate moraju udovoljavati ovim zahtjevima, inače mogu biti uklonjeni.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Molimo kontaktirajte svog upravitelja računa za detalje o isplati',
            cryptocurrency: 'Kriptovaluta',
            withdrawal: 'Isplata',
            requestNewWithdrawal: 'Zahtjevi za isplatu',
            amount: 'Iznos',
            currentBalance: 'Trenutno stanje na računu je',
            withdrawAll: 'Isplati sve',
            requestWithdrawal: 'Zatraži isplatu',
            last: 'ZADNJE',
            withdrawalRequests: 'ZAHTJEVI ZA ISPLATU',
            time: 'Vrijeme',
            currency: 'Valuta',
            info: 'Informacije',
            status: 'Status',
            bank: 'Banka',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            bnb: 'BNB',
            card: 'Kartica',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Detalji isplate',
            address: 'Adresa novčanika',
            bankDetails: 'Bankovni podaci',
            bankAccount: 'Broj računa',
            bankHolder: 'Шот иесінің аты',
            bankIban: 'IBAN',
            bankSwift: 'Bankovni SWIFT kod',
            recipientAddress: 'Adresa primatelja',
            routingNumber: 'Bankovni broj usmjeravanja',
            bankBeneficiary: 'Naziv banke primatelja',
            bankAddress: 'Adresa banke',
            bankCode: 'Bankovni kod (BSB)',
            desc: 'Opis',
            cardNumber: 'Broj kartice',
            cardHolder: 'Ime vlasnika kartice',
        },
        fullScreenVerification: {
            dragAndDrop: 'povucite i ispustite dokument na ovo područje',
            documentsList: 'Popis učitanih dokumenata',
            document: 'Dokument',
            timeUploaded: 'Vrijeme učitavanja',
            timeProcessed: 'Vrijeme obrade',
            status: 'Status',
            types: ['Dokaz identiteta', 'Dokaz prebivališta', 'Prednja strana kartice', 'Stražnja strana kartice', 'Stražnja strana dokaza identiteta', 'Fotografija lica', 'Ostalo'],
            types2: ['Dokaz identiteta (POI)', 'Dokaz prebivališta', 'Prednja strana kreditne kartice', 'Stražnja strana kreditne kartice', 'Stražnja strana dokaza identiteta', 'Fotografija lica s identifikacijom'],
            upload: 'Učitaj',
            isConfirmed: 'je potvrđeno',
            uploadAnotherDocument: 'Učitajte još jedan dokument',
            isUnderReview: 'je na pregledu',
            questionnaire: 'Upitnik',
            sendAnswers: 'Pošalji odgovore',
            progressBarOf: 'od',
            progressBarText: 'dokumenata je učitano i potvrđeno'
        },
        fullScreenAccounts: {
            manageAccounts: 'Prenesite sredstva između svojih računa',
            transferFrom: 'S računa',
            transferTo: 'Na račun',
            amount: 'Iznos',
            makeTransfer: 'Izvrši prijenos',
            yourAccounts: 'Vaši računi',
            account: 'Račun',
            currency: 'Valuta',
            balance: 'Stanje',
            credit: 'Kredit',
            makeActive: 'Postavi kao aktivno',
            active: 'Aktivno'
        },
        fullScreenSupport: {
            sendMessage: 'Pošalji poruku'
        },
        fullScreenSavings: {
            pct_0: 'Fleksibilno',
            pct_30: 'Zaključano 1 mjesec',
            pct_90: 'Zaključano 1 kvartal',
            pct_180: 'Zaključano 6 mjeseci',
            pct_365: 'Zaključano 1 godinu',
            savings: 'Štednja',
            detailedInformation: 'Detaljne informacije',
            pleaseSelect: 'Molimo odaberite valutu i razdoblje',
            openSavings: 'Otvorite štedni račun',
            currentTime: 'Trenutno vrijeme',
            releaseTime: 'Vrijeme oslobađanja',
            currencyChosen: 'Odabrana valuta',
            periodChosen: 'Odabrano razdoblje',
            yourRate: 'Vaša kamatna stopa',
            yourEarnings: 'Vaši prihodi',
            accountFrom: 'Račun s kojeg se prenose sredstva',
            enterAmount: 'Unesite iznos',
            in: 'U',
            openAndInvest: 'Otvorite štedni račun i uložite',
            investment: 'Ulaganje',
            period: 'Razdoblje',
            daysTotal: 'Ukupan broj dana',
            finalEarnings: 'Konačni prihod',
            created: 'Kreirano',
            daysElapsed: 'Protekao broj dana',
            days: 'dana',
            earnings: 'Prihodi',
            fundsReleasedIn: 'Sredstva će biti oslobođena za',
            claim: 'Zatraži',
            name: "Ime",
        },
        fullScreenSettings: {
            updatePassword: 'Ažuriraj lozinku',
            currentPassword: 'Trenutna lozinka',
            newPassword: 'Nova lozinka',
            changePassword: 'Promijeni lozinku',
            activationForGoogle: 'Aktivacijski kod Google autentifikatora',
            activateAndEnter: 'Aktivirajte autentifikator i unesite generirani kod u polje ispod',
            qrCode: 'QR kod',
            activateProtection: 'Aktivirajte 2FA zaštitu',
            protectionActive: '2FA zaštita je aktivna',
            twoFactorCode: '2FA kod',
            disableProtection: 'Deaktivirajte 2FA zaštitu',
            language: 'Jezik',
            dashboardCurrency: 'Valuta na nadzornoj ploči',
            confirmNewPassword: 'Potvrdite novu lozinku',
        },
        fullScreenReferrals: {
            yourLink: 'VAŠ REFERALNI LINK',
            referredFriends: 'REFERIRANI PRIJATELJI',
            id: 'ID',
            name: 'Ime',
            registered: 'Registrirano',
            level: 'Razina',
            payout: 'Isplata',
            lastPayouts: 'ZADNJE REFERALNE ISPLATE',
            time: 'Vrijeme',
            amount: 'Iznos',
            currency: 'Valuta',
            info: 'Informacije',
            referralDesc:"Program preporuka osmišljen je kako bi vas nagradio za širenje naše zajednice vrijednih investitora.",
            shareLink:"Podijelite poveznicu:",
            subtitle:"Dajte mu svoje ime, e-poštu ili ID za trgovanje.",
            descSuccesfull:"Kada se vaša preporuka uspješno registrira, vaš financijski savjetnik će ga kontaktirati kako bi mu pomogao u prvim koracima na njegovom investicijskom putu na najučinkovitiji i najprofesionalniji način.",
            descTracked:"Svi njegovi doprinosi prate se pod vašom preporukom."
        },
        months: {
            Jan: 'Sij',
            Feb: 'Velj',
            Mar: 'Ožu',
            Apr: 'Tra',
            May: 'Svi',
            Jun: 'Lip',
            Jul: 'Srp',
            Aug: 'Kol',
            Sep: 'Ruj',
            Oct: 'Lis',
            Nov: 'Stu',
            Dec: 'Pro'
        },
        orderTypes: ['Kupnja', 'Prodaja', 'Kupovni limit', 'Prodajni limit', 'Kupovni stop', 'Prodajni stop'],
        statuses: ['Na čekanju', 'Odobreno', 'Odbijeno', 'U obradi'],
        errors: {
            NOT_FOUND: 'Greška u zahtjevu',
            FORBIDDEN: 'Pristup odbijen. Molimo ponovno se prijavite',
            SERVER_ERROR: 'Radnja nije uspjela. Pokušajte ponovo kasnije',
            INVALID_ACCOUNT: 'Sesija je istekla. Molimo ponovno se prijavite',
            INVALID_EMAIL: 'Neispravna e-mail adresa',
            INVALID_PHONE: 'Neispravan broj telefona',
            INVALID_COUNTRY: 'Neispravna država',
            INVALID_LOGIN: 'Neispravni podaci za prijavu',
            USER_BLOCKED: 'Račun je blokiran. Molimo kontaktirajte podršku',
            USER_REGISTERED: 'Korisnik s unesenim e-mailom ili brojem telefona već postoji',
            INVALID_USER: 'Korisnik nije pronađen',
            REJECTED: 'Radnja nije dopuštena zbog ograničenja platforme',
            INVALID_OLD_PASSWORD: 'Stara lozinka je neispravna',
            INVALID_SYMBOL: 'Neispravan simbol trgovanja',
            INVALID_SYMBOL_PRECISION: 'Neispravna preciznost simbola',
            INVALID_FRAME: 'Neispravan okvir grafikona',
            INVALID_CURRENCY: 'Neispravna valuta',
            INVALID_AMOUNT: 'Invalid amount',
            INVALID_PRODUCT: 'Neispravan tip trgovačkog računa',
            INSUFFICIENT_FUNDS: 'Nedovoljna sredstva',
            INVALID_WITHDRAWAL: 'Povlačenje nije pronađeno',
            INVALID_STATUS: 'Neispravan status',
            INVALID_FILE: 'Neispravan format datoteke ili datoteka je prevelika',
            INVALID_DOCUMENT: 'Dokument nije pronađen',
            INVALID_NAME: 'Neispravno ime',
            INVALID_LOT_SIZE: 'Neispravna veličina lota',
            INVALID_LOT_STEP: 'Neispravan korak lota',
            INVALID_MIN_VOLUME: 'Neispravan minimalni volumen',
            INVALID_MAX_VOLUME: 'Neispravan maksimalni volumen',
            INVALID_GROUP: 'Neispravna grupa',
            INVALID_SPREAD: 'Neispravan spread',
            REQUEST_TIMEOUT: 'Zahtjev je istekao. Molimo pokušajte kasnije',
            EXISTING_OPEN_ORDERS: 'Imate otvoren nalog, prvo zatvorite ovaj nalog i pokušajte ponovo.',
            INVALID_TYPE: 'Neispravan tip naloga',
            INVALID_VOLUME: 'Neispravan volumen naloga',
            INVALID_SL: 'Neispravna vrijednost zaustavljanja gubitka',
            INVALID_TP: 'Neispravna vrijednost uzimanja dobiti',
            INVALID_PRICE: 'Neispravna cijena naloga',
            INVALID_EXPIRATION: 'Neispravno vrijeme isteka naloga',
            NOT_ENOUGH_MARGIN: 'Nedovoljna margina',
            INVALID_ORDER: 'Nalog nije pronađen',
            MARKET_CLOSED: 'Trgovanje nije dostupno. Molimo pokušajte ponovo',
            INVALID_BALANCE_TYPE: 'Neispravna vrsta operacije salda',
            INVALID_HASH: 'Neispravan hash',
            HASH_EXPIRED: 'Link za resetiranje lozinke je istekao. Molimo zatražite novi',
            INVALID_CODE: 'Neispravan 2FA kod',
            CHAT_DISABLED: 'Pristup chat podršci je zatvoren',
            WITHDRAWAL_NOT_ALLOWED: 'Pristup povlačenju sredstava je zatvoren',
            TRADING_DISABLED: 'Trgovanje je onemogućeno za trenutni račun',
            PENDING_DEPOSITS_LIMIT: 'Premašen limit pologa',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Dopušteno je samo jedno povlačenje na čekanju',
            LOCAL_DEPOSIT_ONLY_REAL: 'Polog je dostupan samo za pravi račun',
            LOCAL_DEPOSIT_POSITIVE: 'Iznos pologa mora biti veći od 0',
            LOCAL_INVALID_CARD_NUMBER: 'Neispravan broj kartice',
            LOCAL_INVALID_CARD_EXPIRATION: 'Neispravan datum isteka kartice',
            LOCAL_INVALID_CVC: 'Neispravan CVV/CVC kod',
            LOCAL_PASSWORDS_NOT_MATCH: 'Lozinke se ne podudaraju, molimo unesite ih ponovno',
            LOCAL_NO_TRADING_ACCOUNTS: 'Nemate dostupnih trgovačkih računa. Molimo kontaktirajte podršku',
            LOCAL_MIN_DEPOSIT: 'Minimalni polog je $',
            LOCAL_MIN_WITHDRAWAL: 'Minimalni iznos za povlačenje je $',
            LOCAL_INVALID_FILE_TYPE: 'Format datoteke nije podržan. Prihvaćamo samo JPG, PNG ili PDF',
            LOCAL_MIN_STAKING: 'Minimalni polog za ovu valutu je ',
            FLOOD_REJECTED: 'Prečesto šaljete poruke. Molimo pokušajte kasnije'
        },
        countries: {
            AF: 'Afganistan',
            AX: 'Ålandski Otoci',
            AL: 'Albanija',
            DZ: 'Alžir',
            AS: 'Američka Samoa',
            AD: 'Andora',
            AO: 'Angola',
            AI: 'Angvila',
            AQ: 'Antarktika',
            AG: 'Antigva i Barbuda',
            AR: 'Argentina',
            AM: 'Armenija',
            AW: 'Aruba',
            AU: 'Australija',
            AT: 'Austrija',
            AZ: 'Azerbajdžan',
            BS: 'Bahami',
            BH: 'Bahrein',
            BD: 'Bangladeš',
            BB: 'Barbados',
            BY: 'Bjelorusija',
            BE: 'Belgija',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermudi',
            BT: 'Butan',
            BO: 'Bolivija',
            BA: 'Bosna i Hercegovina',
            BW: 'Bocvana',
            BV: 'Otok Bouvet',
            BR: 'Brazil',
            IO: 'Britanski Indijskooceanski Teritorij',
            BN: 'Brunej Darussalam',
            BG: 'Bugarska',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodža',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Zelenortska Republika',
            KY: 'Kajmanski Otoci',
            CF: 'Srednjoafrička Republika',
            TD: 'Čad',
            CL: 'Čile',
            CN: 'Kina',
            CX: 'Božićni otok',
            CC: 'Kokosovi (Keeling) otoci',
            CO: 'Kolumbija',
            KM: 'Komori',
            CG: 'Kongo',
            CD: 'Demokratska republika Kongo',
            CK: 'Cookovo otočje',
            CR: 'Kostarika',
            CI: 'Obala bjelokosti',
            HR: 'Hrvatska',
            CU: 'Kuba',
            CY: 'Cipar',
            CZ: 'Češka',
            DK: 'Danska',
            DJ: 'Džibuti',
            DM: 'Dominika',
            DO: 'Dominikanska republika',
            EC: 'Ekvador',
            EG: 'Egipat',
            SV: 'Salvador',
            GQ: 'Ekvatorska Gvineja',
            ER: 'Eritreja',
            EE: 'Estonija',
            ET: 'Etiopija',
            FK: 'Falklandski otoci (Malvini)',
            FO: 'Farski otoci',
            FJ: 'Fidži',
            FI: 'Finska',
            FR: 'Francuska',
            GF: 'Francuska Gvajana',
            PF: 'Francuska Polinezija',
            TF: 'Francuski južni teritoriji',
            GA: 'Gabon',
            GM: 'Gambija',
            GE: 'Gruzija',
            DE: 'Njemačka',
            GH: 'Gana',
            GI: 'Gibraltar',
            GR: 'Grčka',
            GL: 'Grenland',
            GD: 'Grenada',
            GP: 'Gvadalupa',
            GU: 'Guam',
            GT: 'Gvatemala',
            GG: 'Guernsey',
            GN: 'Gvineja',
            GW: 'Gvineja Bisau',
            GY: 'Gvajana',
            HT: 'Haiti',
            HM: 'Heardov otok i McDonaldovi otoci',
            VA: 'Sveta stolica (Vatikan)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Mađarska',
            IS: 'Island',
            IN: 'Indija',
            ID: 'Indonezija',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irska',
            IM: 'Otok Man',
            IL: 'Izrael',
            IT: 'Italija',
            JM: 'Jamajka',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazahstan',
            KE: 'Kenija',
            KI: 'Kiribati',
            KR: 'Koreja',
            KW: 'Kuvajt',
            KG: 'Kirgistan',
            LA: 'Laos',
            LV: 'Latvija',
            LB: 'Libanon',
            LS: 'Lesoto',
            LR: 'Liberija',
            LY: 'Libija',
            LI: 'Lihtenštajn',
            LT: 'Litva',
            LU: 'Luksemburg',
            MO: 'Makao',
            MK: 'Sjeverna Makedonija',
            MG: 'Madagaskar',
            MW: 'Malavi',
            MY: 'Malezija',
            MV: 'Maldivi',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Maršalovi otoci',
            MQ: 'Martinik',
            MR: 'Mauritanija',
            MU: 'Mauricijus',
            YT: 'Mayotte',
            MX: 'Meksiko',
            FM: 'Mikronezija',
            MD: 'Moldavija',
            MC: 'Monako',
            MN: 'Mongolija',
            ME: 'Crna Gora',
            MS: 'Montserrat',
            MA: 'Maroko',
            MZ: 'Mozambik',
            MM: 'Mjanmar',
            NA: 'Namibija',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Nizozemska',
            AN: 'Nizozemski Antili',
            NC: 'Nova Kaledonija',
            NZ: 'Novi Zeland',
            NI: 'Nikaragva',
            NE: 'Niger',
            NG: 'Nigerija',
            NU: 'Niue',
            NF: 'Norfolški otok',
            MP: 'Sjevernomarijanski otoci',
            NO: 'Norveška',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestinski teritorij, okupiran',
            PA: 'Panama',
            PG: 'Papua Nova Gvineja',
            PY: 'Paragvaj',
            PE: 'Peru',
            PH: 'Filipini',
            PN: 'Pitcairn',
            PL: 'Poljska',
            PT: 'Portugal',
            PR: 'Portoriko',
            QA: 'Katar',
            RE: 'Reunion',
            RO: 'Rumunjska',
            RU: 'Rusija',
            RW: 'Ruanda',
            BL: 'Sveti Bartolomej',
            SH: 'Sveta Helena',
            KN: 'Sveti Kristofor i Nevis',
            LC: 'Sveta Lucija',
            MF: 'Sveti Martin',
            PM: 'Sveti Petar i Mikelon',
            VC: 'Sveti Vincent i Grenadini',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome i Principe',
            SA: 'Saudijska Arabija',
            SN: 'Senegal',
            RS: 'Srbija',
            SC: 'Sejšeli',
            SL: 'Sijera Leone',
            SG: 'Singapur',
            SK: 'Slovačka',
            SI: 'Slovenija',
            SB: 'Salomonovi otoci',
            SO: 'Somalija',
            ZA: 'Južnoafrička Republika',
            GS: 'Južna Georgia i otočje Južni Sandwich',
            ES: 'Španjolska',
            LK: 'Šri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard i Jan Mayen',
            SZ: 'Svazi',
            SE: 'Švedska',
            CH: 'Švicarska',
            SY: 'Sirija',
            TW: 'Tajvan',
            TJ: 'Tadžikistan',
            TZ: 'Tanzanija',
            TH: 'Tajland',
            TL: 'Istočni Timor',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad i Tobago',
            TN: 'Tunis',
            TR: 'Turska',
            TM: 'Turkmenistan',
            TC: 'Otoci Turks i Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukrajina',
            AE: 'Ujedinjeni Arapski Emirati',
            GB: 'Ujedinjeno Kraljevstvo',
            US: 'Sjedinjene Američke Države',
            UM: 'Mali udaljeni otoci SAD-a',
            UY: 'Urugvaj',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Britanski djevičanski otoci',
            VI: 'Američki djevičanski otoci',
            WF: 'Wallis i Futuna',
            EH: 'Zapadna Sahara',
            YE: 'Jemen',
            ZM: 'Zambija',
            ZW: 'Zimbabve'
        },
    },
    kz: {
        _name: 'Қазақ тілі',
        timePickerModal: {
            title: 'Уақытында жабу'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Уақытында жабу',
            takeProfit: 'Табысты алу',
            stopLoss: 'Шығындарды тоқтату',
            updateOrder: 'Тапсырысты жаңарту',
            uploaded: 'Жүктеп салынды'
        },
        newDesign: {
            withdrawalRequests: 'Шығару туралы сұраулар әлі жоқ',
            withdrawalRequestsText1: 'Сіз әлі ақшаны алу туралы сұрау жіберген жоқсыз.',
            withdrawalRequestsText2: 'Шығаруды бастағаннан кейін транзакция туралы мәліметтер осы жерде пайда болады',
            pdfAttachment: 'PDF файлы',
            agent: "Агент",
            closed: 'Жабық',
            pending: 'Күтуде',
            approved: 'Бекітілді',
            declined: 'Қабылданбады',
            document: 'Анықтау',
            frontSide: 'Алдынғы бет',
            backSide: 'Артқы бет',
            address: 'Мекенжай',
            frontSideDocument: 'Құжаттың алдыңғы жағы',
            creditCard: 'Несие картасы',
            selfie: 'Селфи',
            other: 'Басқа',
            noDocument: 'Құжаттар әлі жүктелмеген',
            amountDeposited: 'Салым сомасы',
            makeTransfer: 'Аударма жасаңыз',
            otherDetails: 'Тапсырыс мәліметтері',
            manualWallets: 'Крипто әмияндар',
            depositText: 'Депозит 5-10 минут ішінде өңделеді',
            pendingBankTransfer: 'Сізде күтудегі банктік аударым депозиті бар.',
            completeDeposit: 'Депозитті аяқтау үшін төмендегі нұсқауларды орындаңыз.',
            downloadInvoice: 'Шот-фактураны жүктеп алыңыз',
            payInvoice: 'Шотыңызды банкіңізде төлеңіз',
            useYourBankText1: 'Шотты төлеу үшін банктің онлайн немесе офлайн төлем жүйесін пайдаланыңыз.',
            useYourBankText2: 'Төлем мәліметтерінің дұрыс екеніне көз жеткізіңіз.',
            uploadReceipt: 'Растау түбіртегін жүктеп салыңыз',
            afterMakingPayment: 'Төлегеннен кейін депозитіңізді растау үшін түбіртекті жүктеп салыңыз',
            clickToUpload: 'Жүктеп алу үшін басыңыз',
            orDrag: 'немесе сүйреп апарыңыз',
            fileFormat: 'PDF, JPG, JPEG, PNG 10 МБ-тан аз болу керек',
            waitForDeposit: 'Депозитті растауды күтіңіз',
            onceReceipt: 'Түбіртек жүктеп салынғаннан кейін, депозитіңізді тексеріп, мақұлдаймыз',
            paymentDetails: 'Төлем мәліметтері',
            nameOnCard: 'Карточкадағы аты',
            cardNumber: 'Карта нөмірі',
            expirationDate: 'Мерзімнің өту күні',
            securityCode: 'Қауіпсіздік коды',
            continue: 'Жалғастыру',
            viewAccounts: 'Есептік жазбаларды көру',
            continueWithdrawal: 'Шығаруды жалғастыру',
            accountBalance: 'Есептік баланс',
            accountBalanceText1: 'Неліктен мен сауда жасай алмаймын?',
            accountsBalanceText2: 'Ақшаны қалай салуға болады?',
            accountsBalanceText3: 'Мен Zelle арқылы ақша салғым келеді',
            tradingActions: 'Сауда әрекеттері',
            tradingActionsText1: 'Қысқа сатуды қалай жүзеге асырамын?',
            tradingActionsText2: 'Транзакциядан қалай бас тартуға болады?',
            tradingActionsText3: 'Ең аз транзакция сомасы қандай?',
            marketInfo: 'Нарық туралы ақпарат',
            marketInfoText1: 'Нарық трендтерін қайдан көруге болады?',
            marketInfoText2: 'Бүгінгі таңда кіріс көшбасшылары кімдер?',
            securityAndSettings: 'Қауіпсіздік және параметрлер',
            securityAndSettingsText1: 'Құпия сөзімді қалай өзгертуге болады?',
            securityAndSettingsText2: 'Қауіпсіздік мүмкіндіктері қандай?',
            securityAndSettingsText3: 'Есептік жазбам туралы ақпаратты қалай жаңартамын?',
            messageCopied: 'Есептік жазбам туралы ақпаратты қалай жаңартамын?',
            addSymbol: 'Таңбаны қосу',
            iHaveRead: 'Мен ережелер мен шарттарды оқыдым және келісемін',
            toggleToTurn: 'AutoTrade қосу үшін ауыстырыңыз',
            selectTheRisk: 'Тәуекел деңгейін таңдаңыз',
            confirm: 'Растау',
            cancel: 'Болдырмау',
            chooseClosingTime: 'Жабу уақытын таңдаңыз',
            setOrderClosingTime: 'Тапсырысты жабу уақытын орнатыңыз',
            closingTime: 'Жабу уақыты',
            date: 'Күн',
            time: 'Уақыт',
            setCloseTime: 'Жабу уақытын орнатыңыз',
            '15m': '15 минут',
            '30m': '30 минут',
            '1h': '1 сағат',
            custom: 'Арнаулы',
            verifyOtp: 'Бір реттік құпия сөзіңізді тексеріңіз',
            enterDigits: '[email/phone] мекенжайыңызға жіберілген 6 таңбалы кодты енгізіңіз',
            submit: 'Жіберу',
            order: 'Тапсырыс беру',
            closedSuccessfully: 'сәтті жабылды',
            openSuccessfully: 'сәтті ашылды',
            deposit: 'депозит',
            withdrawal: 'қаражатты алу',
            completeVerification: 'Толық тексеру',
            verification: 'Емтихан',
            avatar: 'Аватар',
            saveChanges: 'Өзгерістерді сақтау',
            darkMode: 'Қараңғы режим',
            dashboardCurrency: 'Басқару тақтасының валютасы',
            language: 'Тіл',
            twoFactor: 'Екі факторлы қорғаныс',
            googleTwoFactorIsActive: 'Google екі факторлы аутентификациясы белсенді',
            activateCodeForGoogle: 'Google Authenticator кодын белсендіріңіз',
            ifYouWishDisableEnterCode: 'Егер қорғанысты өшіргіңіз келсе, аутентификация қолданбасының кодын енгізіңіз',
            scanOrManuallyEnter: 'Токенді қосу үшін Google 2FA жүйесіне QR кодын сканерлеңіз немесе қолмен енгізіңіз',
            copyCode: 'Кодты көшіру',
            qrCode: 'QR коды',
            activateAndEnterAuthenticator: 'Authenticator бағдарламасын іске қосыңыз және жасалған кодты төмендегі өріске енгізіңіз',
            enterTheDigitsCodeGenerated: 'Authenticator бағдарламасын іске қосыңыз және жасалған кодты төмендегі өріске енгізіңіз',
            disableTwoFactor: 'Екі факторлы қорғанысты өшіріңіз',
            activateTwoFactor: '2FA қорғанысын іске қосыңыз',
            fileAdded: 'Файл сәтті қосылды',
            theVerificationStatus: 'Тексеру күйін жүктеп салынған құжаттар тізімінде көруге болады',
            confirmWithdrawal: 'Шығуды растаңыз',
            minimumWithdrawal: 'Шығарудың ең аз сомасы',
            welcomeToTradingPlatform: 'Сауда платформасына қош келдіңіз',
            signIn: 'Жүйеге кіру',
            activeAccount: 'Белсенді тіркелгі',
            save: 'Сақтау',
            selectCurrencyAndPeriod: 'Валюта мен жазылу кезеңін таңдаңыз',
            savingsNotFound: 'Жинақ шоты табылмады',
            pleaseCheckInvestment: 'Жалғастыру үшін инвестиция мәліметтерін тексеріңіз',
            allPeriodInvestment: 'Барлық инвестициялық кезең',
            pct_0: 'Икемді',
            pct_30: '1 ай',
            pct_60: '1 тоқсан',
            pct_90: '3 ай',
            pct_180: '6 ай',
            pct_365: '1 жыл',
            savingsCreated: 'Жинақ шоты сәтті жасалды',
            transferDetails: 'Аударма мәліметтері',
            transferAmount: 'Аударым сомасы',
            investmentDetails: 'Инвестициялық мәліметтер',
            investment: 'Инвестициялар',
            Period: 'Кезең',
            created: 'Жасалды',
            releaseTime: 'Шығару уақыты',
            viewSavingsAccounts: 'Жинақ шоттарын көру',
            continueInvestment: 'Инвестициялауды жалғастырыңыз',
            chooseSubscription: 'Жазылымды таңдаңыз',
            selectCurrencyAndSubscription: 'Жалғастыру үшін валюта мен жазылым кезеңін таңдаңыз.',
            rate: 'Бағалау',
            accountTransferFunds: 'Ақшаны аударуға арналған шот',
            amount: 'Сомасы',
            'in': 'ішінде',
            openSavingsAndInvest: 'Жинақ шотын ашып, инвестиция жасаңыз',
            strongSell: 'Белсенді сату',
            sell: 'Сату',
            neutral: 'Бейтарап',
            buy: 'Сатып алу',
            stringBuy: 'Белсенді сатып алу',
            chooseCryptoWallet: 'Әмиянды таңдаңыз',
            maximumDeposit: 'Депозиттің максималды сомасы',
            pleaseCompleteAddress: 'Мекенжайыңызды енгізіңіз',
            pleaseCompleteCity: 'Қалаңызды енгізіңіз',
            pleaseCompleteCountry: 'Еліңізді енгізіңіз',
            pleaseCompleteZip: 'Еліңізді енгізіңіз',
            amountDeposit: 'Салым сомасы',
            transactionStatus: 'Транзакция күйі',
            paymentMethod: 'Төлем әдісі',
            depositTo: 'Депозит жасау',
            depositAddress: 'Үлес қосу',
            scanTheQr: 'QR кодын сканерлеңіз немесе депозит мекенжайын көшіріңіз',
            wallet: 'Әмиян',
            copyDepositAddress: 'Депозит мекенжайын көшіріңіз',
            copy: 'Көшіру',
            addressCopied: 'Мекенжай алмасу буферіне көшірілді',
            '5min:': '5-10 мин',
            '2-5days': '2-5 күн/5-10 мин',
            otherMethod: 'Басқа жол',
            profileSettings: 'Профиль параметрлері',
            savingsAccounts: 'Жинақ шоттары',
            subscribe: 'Жазылу',
            toCompleteVerification: 'Есептік жазбаңызды растау процесін аяқтау үшін келесі құжаттардың анық және түсінікті көшірмелерін беріңіз',
            amountToWithdrawal: 'Шығарылатын сома',
            cardDetails: 'Карта мәліметтері',
            cardHolderName: 'Несие карточкасын иеленушісінің аты',
            withdrawalSuccessful: 'Шығару сәтті аяқталды',
            selectWithdrawalMethod: 'Шығару әдісін таңдаңыз',
            creditDebit: 'Несие/дебеттік карта',
            crypto: 'Крипто',
            cryptoWalletAddress: 'Крипто әмиян мекенжайы',
            "cryptoSubtitle": "Крипто аударымдары сіздің әмияныңызға 🚀",
            "bankWithdrawal": "Банк арқылы шығару",
            "bankSubtitle": "Банк шоты 🏦",
            "zelleSubtitle": "Жылдам банк аударымдары ⚡🏦",
            tradingResults: 'Сауда нәтижелері',
            day: 'Күн',
            month: 'Ай',
            noTradingData: 'Сауда деректері жоқ',
            startTradingToSee: 'Нәтижелеріңізді осы жерден көру үшін сауданы бастаңыз.',
            yourPerformanceMetric: 'Өнімділік көрсеткіштері деректер қолжетімді болған кезде көрсетіледі.',
            favorite: 'Таңдаулы',
            all: 'Барлығы',
            availableFullTime: '24/7 қол жетімді',
            yourProfileAndSettings: 'Профиль және параметрлер',
            switchToNewDesignMessage: 'Жаңа платформа дизайнымызға қазір ауысыңыз!',
            switchToOldDesignMessage: 'Сіз әлі де классикалық дизайнды шектеулі уақытқа пайдалана аласыз',
            switchToOldDesignButton: 'Жаңа дизайнға ауысыңыз',
            switchToNewDesignButton: 'Классикалық дизайнға қайта оралу',
            howCanIAssist: 'Бүгін мен сізге қалай көмектесе аламын?',
            generateInvoice: 'Шот-фактура жасау',
            downloadPdf: 'PDF файлын жүктеп алу',
            confirmDeposit: 'Депозитті растау',
            maxFileSize: 'Файлдың максималды өлшемі 5 МБ, қабылданған пішімдері JPEG, PNG немесе GIF',
            uploadPhoto: 'Фотосуретті жүктеп салу',
            featuresDisabled: 'Бұл мүмкіндік өшірілген',
            welcome: 'Қош келдіңіз',
            signUp: 'Тіркелу',
            registration: 'Тіркеу',
            pleaseConfirmAgreement: 'Клиентпен келісімді растаңыз',
            pleaseEnterPassword: 'Құпия сөзді енгізіңіз',
            passwordNotSame: 'Құпия сөздер сәйкес келмейді',
            noActiveOrders: 'Белсенді тапсырыстар жоқ',
            itLikeNoHaveActiveOrders: 'Қазіргі уақытта сізде белсенді тапсырыстар жоқ сияқты',
            cryptoWallet: 'Криптовалюталық әмиян',
            bankCard: 'Банк/карта',
            selected: 'Таңдалған',
            amountWithdrawal: 'Алынған сома',
            withdrawalTo: 'Шығару орны',
            governmentIssuedPhoto1: 'Мемлекет берген фотосуретті сәйкестендіру (мысалы, төлқұжат, жүргізуші куәлігі, ұлттық жеке куәлік)',
            governmentIssuedPhoto2: 'Тұруға рұқсат (бар болса)',
            governmentIssuedPhoto3: 'Құжат жарамды болуы керек (мерзімі өтпеген)',
            governmentIssuedPhoto4: 'Толық аты-жөні, туған күні және фотосуреті көрінуі керек.',
            uploadRecent1: 'Соңғы 3 айда берілген коммуналдық төлемді, банк үзіндісін немесе жалға алу келісімін жүктеп салыңыз',
            uploadRecent2: 'Толық аты-жөніңіз бен ағымдағы тұрғылықты мекенжайыңыз анық көрінетініне көз жеткізіңіз.',
            uploadCopy1: 'Алғашқы алты және соңғы төрт санның көрінетініне көз жеткізіп, несие картаңыздың алдыңғы бетінің көшірмесін жүктеп салыңыз',
            uploadCopy2: 'Қауіпсіздік мақсатында ортаңғы сандарды жабыңыз',
            takeAndUpload1: 'Жеке куәлігіңізді бетіңіздің қасында ұстап тұратын селфи түсіріңіз және жүктеңіз',
            takeAndUpload2: 'Бетіңіз бен сәйкестендіруіңіз анық көрінетініне көз жеткізіңіз',
            otherDocuments: 'Басқа құжаттар',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Әлеуметтік сақтандыру нөмірі (SSN) құжатының анық және оқылатын суретін жүктеңіз. Барлық мәліметтердің көрінетін және кедергісіз екеніне көз жеткізіңіз.',
            frontSideSsnDocument: 'SSN құжатының алдыңғы жағы',
            answerSaved: 'Жауаптар сақталды',
            cryptoWalletDetails: 'Крипто әмиян деректері',
            bitcoinWalletAddress: 'Bitcoin әмиянының мекенжайы',
            recovery: 'Қалпына келтіру',
            passwordRecovery: 'Құпия сөзді қалпына келтіру',
            searchCountry: 'Елді іздеу'
        },
        balanceModal: {
            balance: 'Баланс',
            credit: 'Несие',
            equity: 'Капитал',
            practice: 'ПРАКТИКА',
            real: 'ШЫН',
            account: 'ЕСЕПТІК ЖАЗБА',
            invested: 'Инвестицияланған',
            profit: 'Пайда',
            loss: 'Шағындар',
            margin: 'Маржа',
            marginLevel: 'Маржа деңгейі',
            marginFree: 'Еркін маржа'
        },
        header: {
            cfd: 'CFD',
            platform: 'Платформа',
            deposit: 'Депозит'
        },
        sideMenu: {
            marketWatch: 'Нарыққа шолу',
            activeOrders: 'Белсенді тапсырыстар',
            tradingHistory: 'Сауда тарихы',
            economicCalendar: 'Күнтізбе',
            marketNews: 'Күнтізбе'
        },
        closePositionModal: {
            closeOrder: 'Жабық позиция',
            areYouSure: 'Позицияны шынымен жапқыңыз келе ме?',
            buy: 'Сатып алу',
            sell: 'Сату',
            yes: 'Иә',
            no: 'Жоқ',
        },
        pciDssModal: {
            desc: `Visa/Master Card/Stripe картасын ұстаушы деректерін сақтайтын, өңдейтін немесе тасымалдайтын барлық ұйымдар, соның ішінде қаржы институттары, сатушылар және қызмет жеткізушілері төлем картасы индустриясының деректер қауіпсіздігі стандартына (PCI DSS) сәйкестігі талап етіледі.
            Бұл карта ұстаушы деректерін және/немесе құпия аутентификация деректерін сақтайтын, өңдейтін немесе тасымалдайтын барлық нысандар үшін жаһандық қауіпсіздік стандарты. PCI DSS тұтынушыларды қорғаудың бастапқы деңгейін белгілейді және бүкіл төлем экожүйесінде алаяқтық пен деректердің бұзылуын азайтуға көмектеседі.`
        },
        chart: {
            addNewChart: 'Жаңа диаграмма қосыңыз',
        },
        symbolsModal: {
            watchlist: 'Бақылау тізімі',
            asset: 'Активтер',
            price: 'Бағасы',
            changePct: 'Өзгерту 24 сағ'
        },
        pendingModal: {
            pending: 'Күту',
            assetPrice: 'Актив бағасы',
            current: 'Ағымдағы',
            revert: 'Нарықтық бағаға оралу',
            automatically: 'Баға осы деңгейге жеткенде позиция автоматты түрде ашылады'
        },
        orderMenu: {
            default: 'әдепкі',
            volume: 'Көлемі',
            lots: 'көп',
            units: 'дана',
            currency: 'валюта',
            contractSize: 'Келісімшарт мөлшері',
            position: 'Позиция',
            margin: 'Маржа',
            freeMargin: 'Еркін маржа',
            takeProfitStopLoss: 'Табысты алыңыз және шығынды тоқтатыңыз',
            pending: 'Күту',
            market: 'Нарық',
            leverage: 'Левередж',
            spread: 'Тарату',
            notSet: 'Орнатылмаған',
            at: '',
            buy: 'сатып алу',
            sell: 'сату',
        },
        footer: {
            supportBanner: 'КҮНДЕ, КЕЗ КЕЛГЕН УАҚЫТТА',
            currentTime: 'Ағымдағы уақыт',
            liveChat: 'Онлайн чат'
        },
        statusBar: {
            balance: 'Баланс',
            credit: 'Несие',
            equity: 'Капитал',
            margin: 'Маржа',
            marginLevel: 'Маржа деңгейі',
            freeMargin: 'Еркін маржа',
            pnl: 'PnL',
            profitTotal: 'Барлық PnL'
        },
        accountModal: {
            dateRegistered: 'Тіркеу күні',
            userId: 'Пайдаланушы идентификаторы',
            dashboard: 'Басқару тақтасы',
            personalData: 'Жеке деректер',
            deposit: 'Депозит',
            withdrawFunds: 'Қаражатты алу',
            savings: 'Сақтау',
            settings: 'Параметрлер',
            logout: 'Шығу'
        },
        depositModal: {
            BT_INVOICE: 'Соманы енгізіп, шот-фактураны жасаңыз',
            BT_TITLE: 'Біздің банктік деректемелер',
            account: 'Есептік жазба',
            bank_name: 'Банк атауы',
            beneficiary: 'Бенефициар',
            beneficiary_address: 'Алушының мекенжайы',
            swift: 'Swift',
            reference: 'Анықтама',
            iban: 'IBAN',
            ru_inn: 'ИНН',
            ru_kpp: 'KPP',
            corresponding_account: 'Корреспонденттік шот',
            TOKEN_AMOUNT: 'Сомасы',
            TOKEN_SUBTOTAL: 'Аралық жиынтық',
            TOKEN_TOTAL: 'Барлығы',
            TOKEN_DESCRIPTION: 'Сипаттама',
            TOKEN_PAYMENT_DETAILS: 'Төлем мәліметтері',
            PENDING_DEPOSITS_LIMIT: 'Депозит лимиті',
            makeDeposit: 'Депозит жасаңыз',
            practiceAccount: 'Демо тіркелгі',
            realAccount: 'Нақты есептік жазба',
            noWithdrawals: 'Қорытындылар жоқ',
            easyWithdrawals: 'Қаражатты оңай алу',
            allAssets: 'Барлық активтер қол жетімді',
            fullFledged: 'Толыққанды платформа',
            fillUpTo: 'Дейін толықтыру',
            freeReplenishment: 'Тегін толтыру',
            topUp: 'Есептік жазбаңызды толтырыңыз',
            minimumAmount: 'Ең аз сома',
            canSwitch: 'Есептік жазбалар арасында кез келген уақытта ауыса аласыз'
        },
        gridsModal: {
            chartGrids: 'ДИАГРАМАЛЫҚ ТОР',
            chart1: '1 график',
            chart2: '2 график',
            chart3: '3 график',
            chart4: '4 график',
        },
        noConnectionModal: {
            connectionLost: 'Сервермен байланыс үзілді',
            retryNow: 'Қазір қайталаңыз',
        },
        loginModal: {
            loginToTradeRoom: 'Traderoom жүйесіне кіріңіз',
            email: 'Электрондық поштаның адресі',
            enterEmail: 'Электрондық пошта мекенжайыңызды енгізіңіз',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: '2FA коды (қосылған болса)',
            twoFactorAuth: 'Екі факторлы аутентификация',
            password: 'Құпия сөз',
            yourPassword: 'Құпия сөзіңіз',
            signIn: 'Жүйеге кіру',
            dontHaveAccount: 'Есептік жазбаңыз жоқ па?',
            restore: 'Қалпына келтіру',
            fullName: 'Толық аты-жөні',
            havePromo: 'Менде промо-код бар',
            promo: 'Промокод',
            login: 'Жүйеге кіру',
            haveAccount: 'Есептік жазбаңыз бар ма?',
            hide: 'Жасыру',
            goTo: 'Артқа'
        },
        newPasswordModal: {
            setNewPassword: 'Жаңа құпия сөзді орнатыңыз',
            newPassword: 'Жаңа құпия сөз',
            confirmNewPassword: 'Жаңа құпия сөзді растаңыз',
            setButton: 'Орнату'
        },
        registerModal: {
            repeatPassword: 'Құпия сөзді қайталаңыз',
            createNewAccount: 'Жаңа тіркелгі жасаңыз',
            email: 'Электрондық поштаның адресі',
            firstName: 'Аты',
            lastName: 'Тегі',
            country: 'Ел',
            phone: 'Телефон',
            password: 'Құпия сөз',
            createAccount: 'Аккаунты құру',
            currency: 'Валюта',
            privacyAndPolicy: 'Құпиялылық саясаты',
            customerAgreement: 'Пайдаланушы келісімі',
        },
        forgotPasswordModal: {
            forgotPassword: 'Құпия сөзіңізді ұмыттыңыз ба?',
            submitEmail: 'Тіркелу үшін пайдаланған электрондық пошта мекенжайын енгізіңіз, кіріс жәшігіңізді тексеріңіз және берілген нұсқауларды орындаңыз',
            submit: 'Жіберу'
        },
        notifications: {
            error: 'Қате',
            success: 'Сәттілік',
            deposit: 'Депозит',
            withdrawal: 'Қаражатты алу',
            depositApproved: 'Депозит мақұлданды',
            depositDeclined: 'Депозит қабылданбады',
            withdrawalApproved: 'Шығару мақұлданды',
            withdrawalDeclined: 'Шығарудан бас тартылды'
        },
        markets: {
            favorites: 'Бақылау тізімі', 
            all: 'Барлық символдар',
            forex: 'Forex',
            stocks: 'Сток',
            commodities: 'Тауарлар',
            indices: 'Көрсеткіштер',
            crypto: 'Крипто',
            metals: 'Металдар',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Пайдадан түсетін баға',
            buy: 'САТЫП АЛУ',
            profitCalculator: 'Пайда калькуляторы',
            sell: 'САТУ',
            volume: 'Көлемі',
            entryPrice: 'Кіру бағасы',
            takeProfit: 'Пайда алыңыз',
            stopLoss: 'Шығындарды тоқтату',
            maxPosition: 'Макс. позиция',
            calculate: 'Есептеу',
            calculationsFor: 'үшін есептеулер',
            leverage: 'Левередж',
            requiredMargin: 'Міндетті маржа',
            profitFromTP: 'TP пайда',
            lossFromSL: 'SL шығыны',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Депозит сәтті жасалды',
            text: 'Депозитіңіз сәтті өңделді!',
        },
        depositFailModal: {
            title: 'Депозит жасалмады',
            text: 'Депозитіңіз өңделмеді.',
        },
        widgetMarketWatch: {
            marketWatch: 'Нарыққа шолу',
            search: 'Іздеу...',
        },
        widgetOrders: {
            commission: 'Комиссия',
            volume: 'Көлемі',
            margin: 'Маржа',
            active: 'Белсенді',
            pending: 'Күту',
            activeOrders: 'Белсенді тапсырыстар',
            portfolio: 'Портфель',
            allPositions: 'Барлық позициялар',
            noPositionsLine1: 'Әлі ашылған жоқ',
            noPositionsLine2: 'позициялар жоқ',
            show: 'Көрсету',
            more: 'көбірек',
            less: 'аздау',
            purchaseTime: 'Сатып алу уақыты',
            closePosition: 'Жабық позиция',
            priceOpen: 'Сатып алу бағасы',
            priceSl: 'Шығындарды тоқтату бағасы',
            priceTp: 'Пайда бағасын алыңыз',
            type: 'Позиция бағыты',
            pnl: 'Пайда/шығын',
            cancelOrder: 'Тапсырысты тоқтату',
            orderType: 'Тапсырыс түрі',
            cancelled: 'Бас тартылды',
            tradingHistory: 'Сауда тарихы',
            noHistoryLine1: 'Сізде әлі жоқ',
            noHistoryLine2: 'жабық мәмілелер',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Күнтізбе'
        },
        widgetMarketNews: {
            marketNews: 'Нарық жаңалықтары'
        },
        ordersPane: {
            closeInTime: 'Уақыт бойынша жақын',
            timedOrder: 'Уақыт бойынша тапсырыс',
            ordersActive: 'Белсенді тапсырыстар',
            ordersHistory: 'Тапсырыс тарихы',
            id: 'ID',
            symbol: 'Таңба',
            type: 'Түр',
            volume: 'Көлемі',
            openPrice: 'Ашылу бағасы',
            openTime: 'Ашылу уақыты',
            closePrice: 'Жабу бағасы',
            closeTime: 'Жабу уақыты',
            sl: 'SL',
            tp: 'TP',
            price: 'Бағасы',
            pnl: 'PnL',
            actions: 'Әрекеттер',
            edit: 'Өзгерту',
            close: 'Жабу',
            commission: 'Комиссия',
            swap: 'Ауыстыру',
            swapLong: 'Ұзақ ауыстырыңыз',
            swapShort: 'Қысқаша ауыстырыңыз'
        },
        assetInfoModal: {
            assetInfo: 'Актив туралы ақпарат',
            tradingConditions: 'Сауда шарттары',
            information: 'Сеансты өңдеу',
            bid: 'Ставка',
            ask: 'Сұраныс',
            sessionChange: 'Сеансты өңдеу',
            tradeNow: 'Қазір сауда жасаңыз',
            opens: 'Ашылады',
            closes: 'Жабылып жатыр',
            at: '',
            open247: '24/7 ашық',
            today: 'бүгін',
            tomorrow: 'ертең',
            sunday: 'Жексенбі',
            monday: 'Дүйсенбі',
            tuesday: 'Сейсенбі',
            wednesday: 'Сәрсенбі',
            thursday: 'Бейсенбі',
            friday: 'Жұма',
            saturday: 'Сенбі',
            contractSpecification: 'Шарттың спецификациясы',
            symbol: 'Таңба',
            name: 'Аты',
            market: 'Нарық',
            baseCurrency: 'Негізгі валюта',
            digits: 'Сандар',
            lotSize: 'Лот мөлшері',
            lotStep: 'Лот қадамы',
            minVolume: 'Мин. көлемі',
            maxVolume: 'Макс. көлемі',
            leverage: 'Левередж',
            commission: 'Комиссия',
            swapLong: 'Ұзын позицияны ауыстыру',
            swapShort: 'Қысқа позицияны ауыстыру',
            schedule: 'Кесте',
            weekday: 'Апта күні',
            tradingTime: 'Сауда уақыты',
            closed: 'жабық',
            sell: 'Сату',
            buy: 'Сатып алу',
            low: 'Төмен',
            high: 'Жоғары',
            oneHourChange: '1 сағатта өзгерту',
            oneDayChange: '1 күнде өзгерту',
            oneWeekChange: '1 аптада өзгерту',
            oneMonthChange: '1 айда өзгерту',
            threeMonthsChange: '3 айда өзгерту',
            oneYearChange: '1 жылда өзгерту',
            loading: 'Жүктелуде...',
        },
        autoTrader: {
            days: 'күндер',
            title: 'Авто трейдер',
            titleAi: 'AI Trader',
            customTitle: 'Автотрейдер уақыт кезеңі',
            riskLevelLow: 'Тәуекел деңгейі: төмен',
            riskLevelMedium: 'Тәуекел деңгейі: орташа',
            riskLevelHigh: 'Тәуекел деңгейі: жоғары',
            perDay: 'Күніне өтетін транзакциялар:',
            desc: `TCFD және басқа левереджді өнімдерді саудалау шығындарға әкелуі мүмкін. 		Сауда-саттықты бастамас бұрын, клиенттер тәуекелдерді ашу бетіндегі тиісті 		тәуекел туралы мәлімдемелерді оқуы керек. Автоматтандырылған сауда 			нәтижелерге кепілдік бермейді. Компания автоматтандырылған сауда-саттық 		кезінде қаражаттың жоғалуы үшін жауапты емес. Тәуекелдерді толық 			түсінгеніңізге және оларды басқару үшін қадамдар жасағаныңызға көз жеткізіңіз.`,
            accept: 'Қабылдау',
            save: 'Сақтау',
            cancel: 'Болдырмау',
        },
        cardBox: {
            name: 'Аты',
            cardNumber: 'Карта нөмірі',
            expiration: 'Жарамдылық мерзімі (аа/жж)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Сәлем',
            trade: "Сауданы бастаңыз",
            startTrading: 'Депозит',
            deposit: 'Депозит',
            dashboard: 'Басқару тақтасы',
            personalInfo: 'Жеке ақпарат',
            contactInfo: 'Байланыс ақпараты',
            withdrawal: 'Қаражатты алу',
            verification: 'Тексеру',
            accounts: 'Есептік жазба',
            liveChat: 'Онлайн чат',
            savings: 'Сақтау',
            referrals: 'Рефералдық бағдарлама',
            settings: 'Параметрлер',
            logOut: 'Шығу',
        },
        fullScreenDeposit: {
            methods: 'ӘДІСТЕР',
            confirm: 'Банк қолданбасында растаңыз',
            sendOtp: 'otp жіберу',
            otpCode: 'OTP коды',
            addWalletAddress: 'Бұл депозит әдісі үшін әмиян орнатылмаған. Агентке немесе қолдау қызметіне хабарласыңыз.',
            addressReceipt: 'Мекенжай жүктелгенше күте тұрыңыз',
            makeDeposit: 'ДЕПОЗИТ ЖАСАУ',
            instant: 'Бірден',
            minutes: 'минут',
            hours: 'сағат',
            days: 'күндер',
            amount: 'Сомасы',
            continue: 'Жалғастыру',
            qrCode: 'QR КОД',
            depositAddress: 'ДЕПОЗИТТІҢ МЕКЕН-ЖАЙЫ',
            copy: 'Көшіру',
            last: 'Соңғы',
            deposits: 'Депозиттер',
            time: 'Уақыт',
            currency: 'Валюта',
            status: 'Күй',
            info: 'Ақпарат',
        },
        fullScreenCcExt: {
            pleaseWait: 'Күте тұрыңыз. Төлем жүйесіне қосылуда...',
            cardPayment: 'Карточка арқылы төлеу',
            inOrder: 'Соманы жіберу үшін',
            pleaseProvide: 'карта мәліметтерін беріңіз:'
        },
        fullScreenDashboard: {
            completeVerification: 'Сынақтан өту',
            noOrderYet: 'Әлі тапсырыстар жоқ',
            totalBalance: 'Жалпы баланс',
            totalPnl: 'Жалпы PNL',
            profitableOrders: 'Пайдалы тапсырыстар',
            roi: 'ROI',
            activityLog: 'Әрекет журналы',
            loginFromIp: 'IP арқылы кіру',
            tradingResults: 'Сауда нәтижелері',
            week: 'Апта',
            month: 'Ай',
            year: 'Жыл',
            successRate: 'Іске сәт',
            closedWithProfit: 'Табыспен жабылды',
            closedWithLoss: 'Шығынмен жабылды',
            account: 'Тексеру',
            balance: 'Баланс',
            leverage: 'Левередж',
            credit: 'Несие',
            tradeNow: 'қазір сауда жасаңыз',
            usingCurrentRate: 'Ағымдағы бағам бойынша',
            totalDeposits: 'Жалпы салымдар',
            totalWithdrawals: "Жалпы алынған қаражат",
            returnOnInvestment: "Инвестицияның қайтарымы"
        },
        fullScreenPersonal: {
            personalDetails: 'Жеке деректер',
            profilePhoto: 'Профиль фотосы',
            firstName: 'Аты',
            country: 'Ел',
            lastName: 'Тегі',
            address: 'Мекенжай',
            email: 'Электрондық пошта',
            phone: 'Телефон нөмірі',
            saveChanges: 'Өзгерістерді сақтау',
            state: 'Қала',
            postcode: 'Пошта Индексі',
            created: 'Шот-фактураның жасалған күні',
        },
        fullScreenAvatar: {
            dropFile: 'Жүктеп салу үшін файлды жоғарыдағы шеңберге сүйреңіз',
            notAllowed: 'Жариялауға рұқсат етілмейді',
            notAllowedLine1: 'Ашық сексуалдық немесе порнографиялық сипаттағы фотосуреттер',
            notAllowedLine2: 'Этникалық немесе нәсілдік араздықты немесе агрессияны қоздыруға бағытталған суреттер',
            notAllowedLine3: '18 жасқа толмаған адамдар қатысатын фотосуреттер',
            notAllowedLine4: 'Үшінші тараптың авторлық құқықпен қорғалған фотосуреттері',
            notAllowedLine5: '5 МБ-тан асатын және JPG, GIF немесе PNG пішімінен басқа форматтағы кескіндер',
            requirements: 'Фотосуретте сіздің бетіңіз анық көрінуі керек. Сіз жүктеп салған барлық фотосуреттер мен бейнелер осы талаптарға сай болуы керек, әйтпесе олар жойылуы мүмкін.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Қаражатты алу туралы толық ақпарат алу үшін шот менеджеріне хабарласыңыз',
            cryptocurrency: 'Криптовалюта',
            withdrawal: 'Қаражатты алу',
            requestNewWithdrawal: 'Шығаруға өтініштер',
            amount: 'Сомасы',
            currentBalance: 'Сіздің ағымдағы шотыңыздағы теңгерім',
            withdrawAll: 'Барлығын шығарыңыз',
            requestWithdrawal: 'Шығаруға өтініш',
            last: 'СОҢҒЫ',
            withdrawalRequests: 'Шығаруға өтініштер',
            time: 'Уақыт',
            currency: 'Валюта',
            info: 'Ақпарат',
            status: 'Күй',
            bank: 'Банк',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Карта',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Шығару мәліметтері',
            address: 'Әмиян мекенжайы',
            bankDetails: 'Банк мәліметтері',
            bankAccount: 'Шот нөмірі',
            bankHolder: 'Шот иесінің аты',
            bankIban: 'IBAN',
            bankSwift: 'Банк SWIFT коды',
            recipientAddress: 'Алушының мекенжайы',
            routingNumber: 'Банк бағыттау нөмірі',
            bankBeneficiary: 'Бенефициар банкі',
            bankAddress: 'Банк мекенжайы',
            bankCode: 'Банк коды (BSB)',
            desc: 'Сипаттама',
            cardNumber: 'Карта нөмірі',
            cardHolder: 'Карточканың иеленушісінің аты',
        },
        fullScreenVerification: {
            dragAndDrop: 'құжатты осы аймаққа сүйреңіз',
            documentsList: 'Жүктелген құжаттардың тізімі',
            document: 'Құжат',
            timeUploaded: 'Жүктеу уақыты',
            timeProcessed: 'Өңдеу уақыты',
            status: 'Күй',
            types: ['Жеке куәлік', 'Резиденттік куәлік', 'Несие картасының алдыңғы бөлігі', 'Несие картасының артына', 'Идентификаторды растайтын құжат', 'Селфи', 'Басқа'],
            types2: ['Сәйкестендіру куәлігі (POI)', 'Тұрғылықты мекен-жайды растау', 'Несие картасының алдыңғы жағы', 'Несие картасының арт жағы', 'Сәйкестендіру куәлігі артқы жағы', 'Жеке куәлікпен селфи'],
            upload: 'Жүктелуде',
            isConfirmed: 'расталды',
            uploadAnotherDocument: 'Басқа құжатты жүктеп салыңыз',
            isUnderReview: 'қарастырылуда',
            questionnaire: 'Сауалнама',
            sendAnswers: 'Жауаптарды жіберіңіз',
            progressBarOf: 'бастап',
            progressBarText: 'құжаттарыңыз жүктеп салынды және тексерілді'
        },
        fullScreenAccounts: {
            manageAccounts: 'Шоттарыңыз арасында ақша аударыңыз',
            transferFrom: 'Есептік жазбадан',
            transferTo: 'Есептік жазбаға',
            amount: 'Сомасы',
            makeTransfer: 'Аударма жасау',
            yourAccounts: 'Есептік жазбаларыңыз',
            account: 'Есептік жазба',
            currency: 'Валюта',
            balance: 'Баланс',
            credit: 'Несие',
            makeActive: 'Белсенді ету',
            active: 'Белсенді'
        },
        fullScreenSupport: {
            sendMessage: 'Біз қалай көмектесе аламыз?'
        },
        fullScreenSavings: {
            pct_0: 'Икемді',
            pct_30: '1 айға бұғатталған',
            pct_90: '1 тоқсанға бұғатталған',
            pct_180: '6 айға бұғатталған',
            pct_365: '1 жылға бұғатталған',
            savings: 'Сақтау',
            detailedInformation: 'Егжей-тегжейлі ақпарат',
            pleaseSelect: 'Валюта мен кезеңді таңдаңыз',
            openSavings: 'Жинақтарды ашу',
            currentTime: 'Ағымдағы уақыт',
            releaseTime: 'Шығарылу уақыты',
            currencyChosen: 'Таңдалған валюта',
            periodChosen: 'Таңдалған кезең',
            yourRate: 'Сіздің ставкаңыз',
            yourEarnings: 'Сіздің табысыңыз',
            accountFrom: 'Қаражатты аударуға арналған шот',
            enterAmount: 'Соманы енгізіңіз',
            in: 'ішінде',
            openAndInvest: 'Жинақ шотын ашып, инвестиция салыңыз',
            investment: 'Инвестициялар',
            period: 'Кезең',
            daysTotal: 'Жалпы күндер',
            finalEarnings: 'Жалпы табыс',
            created: 'Жасалған',
            daysElapsed: 'Күндер өтті',
            days: 'күндер',
            earnings: 'Табыс',
            fundsReleasedIn: 'Қаражат шығарылды',
            claim: 'Баға',
            name: 'Аты',
        },
        fullScreenSettings: {
            updatePassword: 'Құпия сөзді жаңарту',
            currentPassword: 'Ағымдағы құпия сөз',
            newPassword: 'Жаңа құпия сөз',
            changePassword: 'Құпия сөзді өзгерту',
            activationForGoogle: 'Google Authenticator үшін белсендіру коды',
            activateAndEnter: 'Authenticator қолданбасын іске қосыңыз және жасалған кодты төмендегі өріске енгізіңіз',
            qrCode: 'QR коды',
            activateProtection: '2FA қорғанысын іске қосыңыз',
            protectionActive: '2FA қорғанысы белсенді',
            twoFactorCode: '2FA коды',
            disableProtection: '2FA қорғанысын өшіріңіз',
            language: 'Тіл',
            dashboardCurrency: 'Басқару тақтасының валютасы',
            confirmNewPassword: 'Жаңа құпия сөзді растаңыз',
        },
        fullScreenReferrals: {
            yourLink: 'СІЗДІҢ АНЫҚТАУ СІЛТЕМЕСІ',
            referredFriends: 'ҚАТЫСТЫ ДОСТАР',
            id: 'ID',
            name: 'Аты',
            registered: 'Тіркеу',
            level: 'Деңгей',
            payout: 'Төлеу',
            lastPayouts: 'СОҢҒЫ РЕФЕРРАЛДЫҚ ТӨЛЕМДЕР',
            time: 'Уақыт',
            amount: 'Сомасы',
            currency: 'Валюта',
            info: 'Ақпарат',
            referralDesc:"Рефералдық бағдарлама — құнды инвесторлар қауымдастығын кеңейткені үшін сізді марапаттау үшін жасалған.",
            shareLink:"Сілтемені бөлісіңіз:",
            subtitle:"Оған өз атыңызды, email немесе сауда ID-ні беріңіз.",
            descSuccesfull:"Сіздің рефералыңыз сәтті тіркелгеннен кейін, Қаржы кеңесшісі оған алғашқы инвестициялық қадамдарда кәсіби әрі тиімді көмектеседі.",
            descTracked:"Оның барлық үлестері сіздің сілтемеңіз арқылы қадағаланады."
        },
        months: {
            Jan: 'Қаңтар',
            Feb: 'Ақпан',
            Mar: 'Наурыз',
            Apr: 'Сәуір',
            May: 'Мамыр',
            Jun: 'Маусым',
            Jul: 'Шілде',
            Aug: 'Тамыз',
            Sep: 'Қыркүйек',
            Oct: 'Қазан',
            Nov: 'Қараша',
            Dec: 'Желтоқсан'
        },
        orderTypes: ['Сатып алу', 'Сату', 'Cатып алу лимиті', 'Сату лимиті', 'Сатып алуды тоқтату', 'Сатуды тоқтату'],
        statuses: ['Күтуде', 'Мақұлданды', 'Қабылданбады', 'Өңдеуде'],
        errors: {
            NOT_FOUND: 'Сұраныс қатесі',
            FORBIDDEN: 'Рұқсат жоқ. Қайта кіріңіз',
            SERVER_ERROR: 'Әрекет орындалмады. Сәл кейінірек қайталап көріңіз',
            INVALID_ACCOUNT: 'Сеанс аяқталды. Қайта кіріңіз',
            INVALID_EMAIL: 'Жарамсыз электрондық пошта мекенжайы',
            INVALID_PHONE: 'Жарамсыз телефон нөмірі',
            INVALID_COUNTRY: 'Қате ел',
            INVALID_LOGIN: 'Кіру тіркелгі деректері дұрыс емес',
            USER_BLOCKED: 'Есептік жазба бұғатталды. Қолдау қызметіне хабарласыңыз',
            USER_REGISTERED: 'Көрсетілген электрондық пошта мекенжайы немесе телефон нөмірі бар пайдаланушы бұрыннан тіркелген',
            INVALID_USER: 'Пайдаланушы табылмады',
            REJECTED: 'Платформа шектеулеріне байланысты жұмыс істеуге рұқсат етілмейді',
            INVALID_OLD_PASSWORD: 'Ескі құпия сөз жарамсыз',
            INVALID_SYMBOL: 'Жарамсыз сауда белгісі',
            INVALID_SYMBOL_PRECISION: 'Қате таңба дәлдігі',
            INVALID_FRAME: 'Жарамсыз диаграмма жақтауы',
            INVALID_CURRENCY: 'Жарамсыз валюта',
            INVALID_PRODUCT: 'Есептік жазбадағы сауда түрі жарамсыз',
            INSUFFICIENT_FUNDS: 'Қаражат жеткіліксіз',
            INVALID_WITHDRAWAL: 'Шығару табылмады',
            INVALID_STATUS: 'Жарамсыз күй',
            INVALID_FILE: 'Жарамсыз файл кеңейтімі немесе файл тым үлкен',
            INVALID_DOCUMENT: 'Құжат табылмады',
            INVALID_NAME: 'Жарамсыз атау',
            INVALID_LOT_SIZE: 'Лот өлшемі жарамсыз',
            INVALID_LOT_STEP: 'Қате лот қадамы',
            INVALID_MIN_VOLUME: 'Ең төменгі дыбыс көлемі жарамсыз',
            INVALID_MAX_VOLUME: 'Жарамсыз максималды дыбыс',
            INVALID_GROUP: 'Жарамсыз топ',
            INVALID_SPREAD: 'Дұрыс емес таралу',
            REQUEST_TIMEOUT: 'Сұрау уақыты аяқталды. Сәл кейінірек қайталап көріңіз',
            EXISTING_OPEN_ORDERS: 'Сізде ашық тапсырыс бар, алдымен осы пайдаланушының тапсырысын жауып, әрекетті қайталаңыз.',
            INVALID_TYPE: 'Тапсырыс түрі жарамсыз',
            INVALID_VOLUME: 'Жарамсыз тапсырыс көлемі',
            INVALID_SL: 'Қате тоқтату жоғалту мәні',
            INVALID_TP: 'Пайда құнын дұрыс қабылдау',
            INVALID_PRICE: 'Жарамсыз тапсырыс бағасы',
            INVALID_EXPIRATION: 'Тапсырыс мерзімі жарамсыз',
            NOT_ENOUGH_MARGIN: 'Маржа жеткіліксіз',
            INVALID_ORDER: 'Тапсырыс табылмады',
            MARKET_CLOSED: 'Сауда қол жетімді емес. Қайталап көріңіз',
            INVALID_BALANCE_TYPE: 'Жарамсыз баланс транзакция түрі',
            INVALID_HASH: 'Жарамсыз хэш',
            HASH_EXPIRED: 'Құпия сөзді қалпына келтіру сілтемеңіздің мерзімі аяқталды. Жаңасын сұраңыз',
            INVALID_CODE: 'Жарамсыз 2FA коды',
            CHAT_DISABLED: 'Қолдау чатына кіру жабық',
            WITHDRAWAL_NOT_ALLOWED: 'Қаражатты алуға рұқсат жабық',
            TRADING_DISABLED: 'Ағымдағы шотқа сауда жасау өшірілген',
            PENDING_DEPOSITS_LIMIT: 'Депозит шегінен асып кетті',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Тек бір ғана күтудегі алып тастауға рұқсат етіледі',
            LOCAL_DEPOSIT_ONLY_REAL: 'Депозит тек нақты шот үшін қол жетімді',
            LOCAL_DEPOSIT_POSITIVE: 'Депозит сомасы 0-ден жоғары болуы керек',
            LOCAL_INVALID_CARD_NUMBER: 'Жарамсыз карта нөмірі',
            LOCAL_INVALID_CARD_EXPIRATION: 'Картаның жарамдылық мерзімі дұрыс емес',
            LOCAL_INVALID_CVC: 'Жарамсыз CVV/CVC коды',
            LOCAL_PASSWORDS_NOT_MATCH: 'Құпия сөздер сәйкес келмейді, қайта енгізіңіз',
            LOCAL_NO_TRADING_ACCOUNTS: 'Сізде қолжетімді сауда шоттары жоқ. Қолдау қызметіне хабарласыңыз',
            LOCAL_MIN_DEPOSIT: 'Минималды салым $',
            LOCAL_MIN_WITHDRAWAL: 'Шығарудың ең аз сомасы $',
            LOCAL_INVALID_FILE_TYPE: 'Файл пішіміне қолдау көрсетілмейді. Тек JPG, PNG немесе PDF файлдарын тіркеңіз',
            LOCAL_MIN_STAKING: 'Бұл валюта үшін ең аз депозит',
            FLOOD_REJECTED: 'Сіз тым жиі хабарлама жібересіз. Әрекетті кейінірек қайталаңыз'
        },
        countries: {
            AF:  'Ауғанстан',
            AX:  'Аланд аралдары',
            AL:  'Албания',
            DZ:  'Алжир',
            AS:  'Американдық Самоа',
            AD:  'Андорра',
            AO:  'Ангола',
            AI:  'Ангилья',
            AQ:  'Антарктида',
            AG:  'Антигуа және Барбуда',
            AR:  'Аргентина',
            AM:  'Армения',
            AW:  'Аруба',
            AU:  'Австралия',
            AT:  'Австрия',
            AZ:  'Әзірбайжан',
            BS:  'Багам аралдары',
            BH:  'Бахрейн',
            BD:  'Бангладеш',
            BB:  'Барбадос',
            BY:  'Беларусь',
            BE:  'Бельгия',
            BZ:  'Белиз',
            BJ:  'Бенин',
            BM:  'Бермуд аралдары',
            BT:  'Бутан',
            BO:  'Боливия',
            BA:  'Босния және Герцеговина',
            BW:  'Ботсвана',
            BV:  'Бувет аралы',
            BR:  'Бразилия',
            IO:  'Британдық Үнді мұхитының территориясы',
            BN:  'Бруней-Даруссалам',
            BG:  'Болгария',
            BF:  'Буркина-Фасо',
            BI:  'Бурунди',
            KH:  'Камбоджа',
            CM:  'Камерун',
            CA:  'Канада',
            CV:  'Кабо-Верде',
            KY:  'Кайман аралдары',
            CF:  'Орталық Африка Республикасы',
            TD:  'Чад',
            CL:  'Чили',
            CN:  'Қытай',
            CX:  'Рождество аралы',
            CC:  'Кокос (Килинг) аралдары',
            CO:  'Колумбия',
            KM:  'Комор аралдары',
            CG:  'Конго',
            CD:  'Конго, Демократиялық Республикасы',
            CK:  'Кукинг аралдары',
            CR:  'Коста-Рика',
            CI:  'Кот-д Ивуар',
            HR:  'Хорватия',
            CU:  'Куба',
            CY:  'Кипр',
            CZ:  'Чех Республикасы',
            DK:  'Дания',
            DJ:  'Джибути',
            DM:  'Доминика',
            DO:  'Доминикан республикасы',
            EC:  'Эквадор',
            EG:  'Египет',
            SV:  'Сальвадор',
            GQ:  'Экваторлық Гвинея',
            ER:  'Эритрея',
            EE:  'Эстония',
            ET:  'Эфиопия',
            FK:  'Фолкленд аралдары (Мальвин аралдары)',
            FO:  'Фарер аралдары',
            FJ:  'Фиджи',
            FI:  'Финляндия',
            FR:  'Франция',
            GF:  'Француз Гвианасы',
            PF:  'Француз Полинезиясы',
            TF:  'Францияның оңтүстік аумақтары',
            GA:  'Габон',
            GM:  'Гамбия',
            GE:  'Грузия',
            DE:  'Германия',
            GH:  'Гана',
            GI:  'Гибралтар',
            GR:  'Греция',
            GL:  'Гренландия',
            GD:  'Гренада',
            GP:  'Гваделупа',
            GU:  'Гуам',
            GT:  'Гватемала',
            GG:  'Гернси',
            GN:  'Гвинея',
            GW:  'Гвинея-Бисау',
            GY:  'Гайана',
            HT:  'Гаити',
            HM:  'Херд және Макдональд аралдары',
            VA:  'Қасиетті Тақ (Ватикан мемлекеті)',
            HN:  'Гондурас',
            HK:  'Гонконг',
            HU:  'Венгрия',
            IS:  'Исландия',
            IN:  'Үндістан',
            ID:  'Индонезия',
            IR:  'Иран',
            IQ:  'Ирак',
            IE:  'Ирландия',
            IM:  'Мэн аралы',
            IL:  'Израиль',
            IT:  'Италия',
            JM:  'Ямайка',
            JP:  'Жапония',
            JE:  'Джерси',
            JO:  'Иордания',
            KZ:  'Қазақстан',
            KE:  'Кения',
            KI:  'Кирибати',
            KR:  'Корея',
            KW:  'Кувейт',
            KG:  'Қырғызстан',
            LA:  'Лаос Халықтық Демократиялық Республикасы',
            LV:  'Латвия',
            LB:  'Ливан',
            LS:  'Лесото',
            LR:  'Либерия',
            LY:  'Ливия Араб Джамахириясы',
            LI:  'Лихтенштейн',
            LT:  'Литва',
            LU:  'Люксембург',
            MO:  'Макао',
            MK:  'Македония',
            MG:  'Мадагаскар',
            MW:  'Малави',
            MY:  'Малайзия',
            MV:  'Мальдив аралдары',
            ML:  'Мали',
            MT:  'Мальта',
            MH:  'Маршалл аралдары',
            MQ:  'Мартиника',
            MR:  'Мавритания',
            MU:  'Маврикий',
            YT:  'Майотта',
            MX:  'Мексика',
            FM:  'Микронезия',
            MD:  'Молдова',
            MC:  'Монако',
            MN:  'Моңғолия',
            ME:  'Черногория',
            MS:  'Монцеррат',
            MA:  'Марокко',
            MZ:  'Мозамбик',
            MM:  'Мьянма',
            NA:  'Намибия',
            NR:  'Науру',
            NP:  'Непал',
            NL:  'Нидерланды',
            AN:  'Нидерланд Антильдері',
            NC:  'Жаңа Каледония',
            NZ:  'Жаңа зеландия',
            NI:  'Никарагуа',
            NE:  'Нигер',
            NG:  'Нигерия',
            NU:  'Ниуэ',
            NF:  'Норфолк аралы',
            MP:  'Солтүстік Мариан аралдары',
            NO:  'Норвегия',
            OM:  'Оман',
            PK:  'Пәкістан',
            PW:  'Палау',
            PS:  'Оккупацияланған Палестина территориясы',
            PA:  'Панама',
            PG:  'Папуа-Жаңа Гвинея',
            PY:  'Парагвай',
            PE:  'Перу',
            PH:  'Филиппин',
            PN:  'Питкэрн',
            PL:  'Польша',
            PT:  'Португалия',
            PR:  'Пуэрто-Рико',
            QA:  'Катар',
            RE:  'Кездесу',
            RO:  'Румыния',
            RU:  'Ресей',
            RW:  'Руанда',
            BL:  'Сент-Бартелеми',
            SH:  'Әулие Елена аралы',
            KN:  'Сент-Китс және Невис',
            LC:  'Сент-Люсия',
            MF:  'Сент-Мартин',
            PM:  'Сент-Пьер және Микелон',
            VC:  'Сент-Винсент және Гренадин аралдары',
            WS:  'Самоа',
            SM:  'Сан-Марино',
            ST:  'Сан-Томе және Принсипи',
            SA:  'Сауд Арабиясы',
            SN:  'Сенегал',
            RS:  'Сербия',
            SC:  'Сейшель аралдары',
            SL:  'Сьерра-Леоне',
            SG:  'Сингапур',
            SK:  'Словакия',
            SI:  'Словения',
            SB:  'Соломон аралдары',
            SO:  'Сомали',
            ZA:  'Оңтүстік Африка',
            GS:  'Оңтүстік Джорджия және Сэндвич аралдары',
            ES:  'Испания',
            LK:  'Шри-Ланка',
            SD:  'Судан',
            SR:  'Суринам',
            SJ:  'Шпицберген және Ян Майен',
            SZ:  'Свазиленд',
            SE:  'Швеция',
            CH:  'Швейцария',
            SY:  'Сирия Араб Республикасы',
            TW:  'Тайвань',
            TJ:  'Тәжікстан',
            TZ:  'Танзания',
            TH:  'Тайланд',
            TL:  'Тимор-Лесте',
            TG:  'Того',
            TK:  'Токелау',
            TO:  'Тонга',
            TT:  'Тринидад және Тобаго',
            TN:  'Тунис',
            TR:  'Түркия',
            TM:  'Түрікменстан',
            TC:  'Теркс және Кайкос аралдары',
            TV:  'Тувалу',
            UG:  'Уганда',
            UA:  'Украина',
            AE:  'Біріккен Араб Әмірліктері',
            GB:  'Біріккен корольдігі',
            US:  'Америка Құрама Штаттары',
            UM:  'АҚШ-тың шеткі аралдары',
            UY:  'Уругвай',
            UZ:  'Өзбекстан',
            VU:  'Вануату',
            VE:  'Венесуэла',
            VN:  'Вьетнам',
            VG:  'Виргин аралдары, Британ',
            VI:  'Виргин аралдары, АҚШ',
            WF:  'Уоллис және Футуна',
            EH:  'Батыс Сахара',
            YE:  'Йемен',
            ZM:  'Замбия',
            ZW:  'Зимбабве'
        },
    },
    kg: {
        _name: 'Кыргыз тили',
        timePickerModal: {
            title: 'Убагында жабуу'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Пайда алыңыз жана жоготууларды токтотуңуз',
            takeProfit: 'Пайда алыңыз',
            stopLoss: 'жоготууларды токтотуңуз',
            updateOrder: 'Заказды жаңыртуу',
            uploaded: 'Жүктөлгөн'
        },
        newDesign: {
            withdrawalRequests: 'Азырынча акчаны алуу өтүнүчү жок',
            withdrawalRequestsText1: 'Сиз буга чейин акчаны алып салуу өтүнүчүн жасаган жоксуз.',
            withdrawalRequestsText2: 'Акчаны чыгарууну баштагандан кийин, транзакцияңыздын чоо-жайы бул жерде көрүнөт',
            pdfAttachment: 'PDF тиркемеси',
            agent: "Агент",
            closed: 'Жабык',
            pending: 'Күтүүдө',
            approved: 'Бекитилди',
            declined: 'Четке кагылды',
            document: 'Аныктоо',
            frontSide: 'алдыңкы жагы',
            backSide: 'Арткы тарап',
            address: 'Дарек',
            frontSideDocument: 'Документтин алдыңкы жагы',
            creditCard: 'Кредиттик карта',
            selfie: 'Селфи',
            other: 'Башка',
            noDocument: 'Азырынча эч кандай документ жүктөлгөн жок',
            amountDeposited: 'Салынган сумма',
            makeTransfer: 'жасоо өткөрүп берүү',
            otherDetails: 'Заказдын чоо-жайы',
            manualWallets: 'Крипто капчыктар',
            depositText: 'Депозит 5-10 мүнөттүн ичинде иштетилет',
            pendingBankTransfer: 'Күтүүдөгү банктык которуу депозитиңиз бар.',
            completeDeposit: 'Депозитти бүтүрүү үчүн төмөнкү нускамаларды аткарыңыз',
            downloadInvoice: 'Эсеп-фактураны жүктөп алыңыз',
            payInvoice: 'Эсеп-фактураны банкыңызга төлөңүз',
            useYourBankText1: 'Эсеп-фактураны төлөө үчүн банкыңыздын онлайн же оффлайн төлөм системасын колдонуңуз.',
            useYourBankText2: 'Төлөм реквизиттери туура экенин текшериңиз.',
            uploadReceipt: 'Ырастоо дүмүрчөгүн жүктөө',
            afterMakingPayment: 'Төлөмдү аткаргандан кийин, депозитти ырастоо үчүн дүмүрчөгүңүздү жүктөп бериңиз',
            clickToUpload: 'Жүктөө үчүн чыкылдатыңыз',
            orDrag: 'же сүйрөп барып таштаңыз',
            fileFormat: 'PDF, JPG, JPEG, PNG 10 МБдан аз',
            waitForDeposit: 'Депозитти ырастоону күтүңүз',
            onceReceipt: 'Дүмүрчөк жүктөлүп берилгенден кийин, депозитиңизди текшерип, бекитебиз',
            paymentDetails: 'Төлөм чоо-жайы',
            nameOnCard: 'картадагы ат',
            cardNumber: 'Карта номери',
            expirationDate: 'Жарамдык датасы',
            securityCode: 'Коопсуздук коду',
            continue: 'Улантуу',
            viewAccounts: 'Каттоо эсептерин көрүү',
            continueWithdrawal: 'Чыгарууну улантуу',
            accountBalance: 'Эсеп балансы',
            accountBalanceText1: 'Эмне үчүн мен соода кыла албайм?',
            accountsBalanceText2: 'Акча каражаттарын кантип депозитке салам?',
            accountsBalanceText3: 'Мен Zelle менен акча салгым келет',
            tradingActions: 'Соода аракеттери',
            tradingActionsText1: 'Кантип кыска сатам?',
            tradingActionsText2: 'Сооданы кантип жокко чыгарсам болот?',
            tradingActionsText3: 'минималдуу соода суммасы канча?',
            marketInfo: 'Базар маалыматы',
            marketInfoText1: 'Базар тенденцияларын кайдан көрө алам?',
            marketInfoText2: 'Бүгүнкү күндө эң көп пайда тапкандар кайсылар?',
            securityAndSettings: 'Коопсуздук жана Орнотуулар',
            securityAndSettingsText1: 'Сырсөзүмдү кантип өзгөртөм?',
            securityAndSettingsText2: 'Коопсуздук өзгөчөлүктөрү кандай?',
            securityAndSettingsText3: 'Каттоо эсебимдин маалыматын кантип жаңыртсам болот?',
            messageCopied: 'Билдирүү алмашуу буферине көчүрүлдү',
            addSymbol: 'Белги кошуу',
            iHaveRead: 'Мен окуп чыктым жана жоболор менен макулмун',
            toggleToTurn: 'Авто сооданы күйгүзүү үчүн которуңуз',
            selectTheRisk: 'Тобокелдик деңгээлин тандаңыз',
            confirm: 'ырастоо',
            cancel: 'Жокко чыгаруу',
            chooseClosingTime: 'Жабуу убактысын тандаңыз',
            setOrderClosingTime: 'Заказдын жабылуу убактысын коюңуз',
            closingTime: 'Жабуу убактысы',
            date: 'Дата',
            time: 'Убакыт',
            setCloseTime: 'Жабуу убактысын коюу',
            '15m': '15 мүнөт',
            '30m': '30 мүнөт',
            '1h': '1 саат',
            custom: 'Ыңгайлаштырылган',
            verifyOtp: 'Сиздин OTP ырастаңыз',
            enterDigits: 'Сиздин [ электрондук почтаңызга/телефонуңузга] жөнөтүлгөн 6 орундуу кодду киргизиңиз',
            submit: 'Тапшыруу',
            order: 'Заказ',
            closedSuccessfully: 'ийгиликтүү жабылды',
            openSuccessfully: 'ийгиликтүү ачуу',
            deposit: 'депозит',
            withdrawal: 'алып салуу',
            completeVerification: 'Толук текшерүү',
            verification: 'Текшерүү',
            avatar: 'Аватар',
            saveChanges: 'Өзгөрүүлөрдү сактоо',
            darkMode: 'Караңгы режим',
            dashboardCurrency: 'Башкаруу тактасынын валютасы',
            language: 'Тил',
            twoFactor: '2 факторлуу коргоо',
            googleTwoFactorIsActive: 'Google 2 факторлуу аутентификация активдүү',
            activateCodeForGoogle: 'Google аныктыгын текшерүүчү кодду жандырыңыз',
            ifYouWishDisableEnterCode: 'Эгер сиз коргоону өчүргүңүз келсе, аныктыгын текшерүүчү колдонмонун кодун киргизиңиз',
            scanOrManuallyEnter: 'Токен кошуу үчүн Google эки фактордук аутентификация га QR кодун скандаңыз же кол менен киргизиңиз',
            copyCode: 'Кодду көчүрүү',
            qrCode: 'QR коду',
            activateAndEnterAuthenticator: 'Аутентификацияны иштетиңиз жана төмөнкү талаага түзүлгөн кодду киргизиңиз',
            enterTheDigitsCodeGenerated: 'Түзүлгөн 6 орундуу кодду киргизиңиз',
            disableTwoFactor: 'Эки факторлуу коргоону өчүрүү',
            activateTwoFactor: 'эки фактордук аутентификация коргоону иштетүү',
            fileAdded: 'Файл ийгиликтүү кошулду',
            theVerificationStatus: 'Текшерүү статусун жүктөлгөн документтердин тизмесинен көрүүгө болот',
            confirmWithdrawal: 'Чыгууну ырастоо',
            minimumWithdrawal: 'Минималдуу алуу суммасы болуп саналат',
            welcomeToTradingPlatform: 'Соода платформасына кош келиңиз',
            signIn: 'Кирүү',
            activeAccount: 'Активдүү эсеп',
            save: 'Сактоо',
            selectCurrencyAndPeriod: 'Валютаны жана жазылуу мөөнөтүн тандаңыз',
            savingsNotFound: 'Сактык эсептер табылган жок',
            pleaseCheckInvestment: 'Улантуу үчүн инвестицияңыздын чоо-жайын текшериңиз',
            allPeriodInvestment: 'Бардык мезгилдеги инвестиция',
            pct_0: 'ийкемдүү',
            pct_30: '1 ай',
            pct_60: '1 квартал',
            pct_90: '6 ай',
            pct_180: '6 ай',
            pct_365: '1 жыл',
            savingsCreated: 'Сактык эсеби ийгиликтүү түзүлдү',
            transferDetails: 'Өткөрүп берүү чоо-жайы',
            transferAmount: 'Которуу суммасы',
            investmentDetails: 'Инвестициялардын чоо-жайы',
            investment: 'Инвестиция',
            Period: 'Мезгил',
            created: 'түзүлгөн',
            releaseTime: 'Чыгаруу убактысы',
            viewSavingsAccounts: 'Сактык эсептерди көрүү',
            continueInvestment: 'Инвестицияларды улантуу',
            chooseSubscription: 'Жазылууңузду тандаңыз',
            selectCurrencyAndSubscription: 'Улантуу үчүн валютаны жана жазылуу мөөнөтүн тандаңыз.',
            rate: 'Баа',
            accountTransferFunds: 'Акча которуу үчүн эсеп',
            amount: 'Суммасы',
            in: 'ичинде',
            openSavingsAndInvest: 'Сактык эсебин ачуу жана инвестициялоо',
            strongSell: 'Күчтүү сат',
            sell: 'сат',
            neutral: 'Нейтралдуу',
            buy: 'Сатып ',
            stringBuy: 'Күчтүү Сатып ',
            chooseCryptoWallet: 'Капчыкты тандаңыз',
            maximumDeposit: 'Максималдуу депозит',
            pleaseCompleteAddress: 'Сураныч, дарегиңизди толтуруңуз',
            pleaseCompleteCity: 'Сураныч, шаарыңызды бүтүрүңүз',
            pleaseCompleteCountry: 'Сураныч, өлкөңүздү бүтүрүңүз',
            pleaseCompleteZip: 'Почта индексиңизди толтуруңуз',
            amountDeposit: 'Депозиттин суммасы',
            transactionStatus: 'Транзакциянын абалы',
            paymentMethod: 'Төлөм ыкмасы',
            depositTo: 'Депозитке',
            depositAddress: 'Депозиттин дареги',
            scanTheQr: 'QR кодун сканерлеңиз же депозиттин дарегин көчүрүңүз',
            wallet: 'Капчык',
            copyDepositAddress: 'Депозиттин дарегин көчүрүү',
            copy: 'Көчүрүү',
            addressCopied: 'Дарек алмашуу буферине көчүрүлдү',
            '5min:': '5-10 мин',
            '2-5days': '2-5 күн / 5-10 мин',
            otherMethod: 'Башка ыкма',
            profileSettings: 'Профиль орнотуулары',
            savingsAccounts: 'Сактык эсептер',
            subscribe: 'Жазылуу',
            toCompleteVerification: 'Каттоо эсебиңизди текшерүү процессин аяктоо үчүн төмөнкү документтердин так жана түшүнүктүү көчүрмөлөрүн бериңиз',
            amountToWithdrawal: 'Алынуучу сумма',
            cardDetails: 'Карта чоо-жайы',
            cardHolderName: 'Карта ээсинин аты',
            withdrawalSuccessful: 'Ийгиликтүү алып салуу',
            selectWithdrawalMethod: 'Чыгаруу ыкмасын тандаңыз',
            creditDebit: 'Кредиттик/дебеттик карта',
            crypto: 'Крипто',
            cryptoWalletAddress: 'Крипто капчык дареги',
            "cryptoSubtitle": "Крипто чыгаруулар сиздин капчыгыңызга 🚀",
            "bankWithdrawal": "Банк аркылуу чыгарып алуу",
            "bankSubtitle": "Банк эсеби 🏦",
            "zelleSubtitle": "Тез банктык которуулар ⚡🏦",
            tradingResults: 'Сооданын натыйжалары',
            day: 'күн',
            month: 'Ай',
            noTradingData: 'Соода маалыматы жок',
            startTradingToSee: 'Натыйжаларыңызды бул жерден көрүү үчүн сооданы баштаңыз.',
            yourPerformanceMetric: 'Майнаптуулук көрсөткүчтөрүңүз дайындар жеткиликтүү болгондон кийин көрсөтүлөт.',
            favorite: 'Сүйүктүү',
            all: 'Баары',
            availableFullTime: '24/7 жеткиликтүү',
            yourProfileAndSettings: 'Профилиңиз жана жөндөөлөрүңүз',
            switchToNewDesignMessage: 'Биздин жаңы платформа дизайныбызга азыр которулуңуз!',
            switchToOldDesignMessage: 'Сиз дагы эле чектелген убакыт үчүн классикалык дизайн колдоно аласыз',
            switchToOldDesignButton: 'Жаңы дизайнга өтүү',
            switchToNewDesignButton: 'Классикалык дизайнга кайтуу',
            howCanIAssist: 'Бүгүн мен сизге кантип жардам бере алам?',
            generateInvoice: 'Эсеп-фактураны түзүү',
            downloadPdf: 'PDF жүктөп алуу',
            confirmDeposit: 'Депозитти ырастоо',
            maxFileSize: 'Файлдын максималдуу өлчөмү 5 МБ жана кабыл алынган форматтары JPEG, PNG же GIF',
            uploadPhoto: 'Сүрөт жүктөө',
            featuresDisabled: 'Бул функция өчүрүлгөн',
            welcome: 'Кош келдиңиз',
            signUp: 'Кирүү',
            registration: 'Каттоо',
            pleaseConfirmAgreement: 'Кардар келишимин ырастаңыз',
            pleaseEnterPassword: 'Сырсөздү киргизиңиз',
            passwordNotSame: 'Сырсөздөр бирдей эмес',
            noActiveOrders: 'Активдүү заказдар жок',
            itLikeNoHaveActiveOrders: 'Учурда жигердүү буйруктарыңыз жок окшойт',
            cryptoWallet: 'Крипто капчык',
            bankCard: 'Банк/карта',
            selected: 'Тандалган',
            amountWithdrawal: 'Алынган сумма',
            withdrawalTo: 'чейин алып салуу',
            governmentIssuedPhoto1: 'Өкмөт тарабынан берилген сүрөт ID (мисалы, паспорт, айдоочулук күбөлүк, улуттук ID карта)',
            governmentIssuedPhoto2: 'Жашоого уруксат (эгер бар болсо)',
            governmentIssuedPhoto3: 'Документ жарактуу болушу керек (мөөнөтү бүтө элек)',
            governmentIssuedPhoto4: 'Толук аты-жөнү, туулган жылы жана сүрөтү көрүнүп турушу керек.',
            uploadRecent1: 'Акыркы 3 айдын ичинде берилген акыркы коммуналдык эсепти, банк көчүрмөсүн же ижара келишимин жүктөп бериңиз',
            uploadRecent2: 'Толук аты-жөнүңүз жана учурдагы жашаган дарегиңиз даана көрүнүп турушун камсыз кылыңыз.',
            uploadCopy1: 'Кредит картаңыздын алдыңкы бетинин көчүрмөсүн жүктөп бериңиз, биринчи алты жана акыркы төрт сан көрүнүп турсун',
            uploadCopy2: 'Коопсуздук үчүн ортоңку сандарды жаап коюңуз',
            takeAndUpload1: 'Өзүңүздүн документиңизди бетиңиздин жанында кармап, селфи тартып, жүктөп бериңиз',
            takeAndUpload2: 'Жүзүңүз да, идентификаторуңуз да ачык көрүнүп турушун камсыз кылыңыз',
            otherDocuments: 'Башка документтер',
            ssn: 'Social Security Number (SSN)',
            ssnDocuments: 'Социалдык камсыздандыру номери (SSN) документиңиздин так жана окулгудай сүрөтүн жүктөңүз. Бардык маалыматтар көрүнүктүү жана тоскоолдуксуз экенине ынаныңыз.',
            frontSideSsnDocument: 'SSN документинин алдыңкы тарабы',
            answerSaved: 'Жооптор сакталды',
            cryptoWalletDetails: 'Крипто капчыктын чоо-жайы',
            bitcoinWalletAddress: 'биткоин капчык дареги',
            recovery: 'Калыбына келтирүү',
            passwordRecovery: 'Сырсөздү калыбына келтирүү',
            searchCountry: 'Өлкө издөө'
        },
        balanceModal: {
            balance: 'Баланс',
            credit: 'Кредит',
            equity: 'Өздүк капитал',
            practice: 'ПРАКТИКА',
            real: 'РЕАЛ',
            account: 'эсеп',
            invested: 'Инвестицияланган',
            profit: 'Пайда',
            loss: 'жоготуу',
            margin: 'Маржа',
            marginLevel: 'Маржа деңгээли',
            marginFree: 'Эркин маржа'
        },
        header: {
            cfd: 'CFD',
            platform: 'Платформа',
            deposit: 'Депозит'
        },
        sideMenu: {
            marketWatch: 'Рынокту көзөмөлдөө',
            activeOrders: 'Активдүү заказдар',
            tradingHistory: 'Соода тарыхы',
            economicCalendar: 'Календар',
            marketNews: 'базар жаңылыктары'
        },
        closePositionModal: {
            closeOrder: 'Позицияны жабуу',
            areYouSure: 'Сиз позицияны жабасызбы',
            buy: 'Buy',
            sell: 'Сат',
            yes: 'Ооба',
            no: 'Жок',
        },
        pciDssModal: {
            desc: `Төлөм Картасынын Өнөр жайынын Маалыматтар Коопсуздук Стандартына (PCI DSS) ылайык келүүсү визаны/мастер картаны/ сызыгын сактаган, иштеткен же өткөрүп берген бардык уюмдардан талап кылынат.
            Карт ээсинин маалыматтары, анын ичинде каржы институттары, соодагерлер жана кызмат көрсөтүүчүлөр.
            IT карта ээсинин маалыматтарын жана/же аныктыгын текшерүүнүн купуя маалыматтарын сактаган, иштеткен же өткөргөн бардык субъекттер үчүн глобалдык коопсуздук стандарты болуп саналат. PCI DSS керектөөчүлөр үчүн коргоонун баштапкы деңгээлин белгилейт жана бүтүндөй төлөм экосистемасы боюнча алдамчылыкты жана маалыматтарды бузууну азайтууга жардам берет.`
        },
        chart: {
            addNewChart: 'Жаңы диаграмма кошуу',
        },
        symbolsModal: {
            watchlist: 'Көрүү тизмеси',
            asset: 'Актив',
            price: 'Баасы',
            changePct: '24 саат өзгөртүү'
        },
        pendingModal: {
            pending: 'Күтүүдө',
            assetPrice: 'Актив баасы',
            current: 'Учурдагы',
            revert: 'Рыноктук баага кайтуу',
            automatically: 'Баа ушул деңгээлге жеткенде позиция автоматтык түрдө ачылат'
        },
        orderMenu: {
            default: 'демейки',
            volume: 'Көлөм',
            lots: 'лот',
            units: 'бирдиктер',
            currency: 'валюта',
            contractSize: 'Келишимдин өлчөмү',
            position: 'Позиция',
            margin: 'Маржа',
            freeMargin: 'Акысыз маржа',
            takeProfitStopLoss: 'Пайда алыңыз жана жоготууларды токтотуңуз',
            pending: 'Күтүүдө',
            market: 'Базар',
            leverage: 'Левередж',
            spread: 'Жайылуу',
            notSet: 'коюлган эмес',
            at: 'саат',
            buy: 'сатып',
            sell: 'сат',
        },
        footer: {
            supportBanner: 'КУН САЙЫН, СААТТЫК',
            currentTime: 'Учурдагы убакыт',
            liveChat: 'Жандуу чат'
        },
        statusBar: {
            balance: 'Баланс',
            credit: 'Кредит',
            equity: 'Өздүк капитал',
            margin: 'Маржа',
            marginLevel: 'Маржа деңгээли',
            freeMargin: 'Эркин маржа',
            pnl: 'PnL',
            profitTotal: 'Өмүр бою PnL'
        },
        accountModal: {
            dateRegistered: 'Катталган датасы',
            userId: 'Колдонуучунун идентификациясы',
            dashboard: 'Куралдар тактасы',
            personalData: 'Өздүк маалымат',
            deposit: 'Депозит',
            withdrawFunds: 'Каражаттарды алуу',
            savings: 'Үнөмдөө',
            settings: 'Орнотуулар',
            logout: 'Чыгуу'
        },
        depositModal: {
            BT_INVOICE: 'Сумманы киргизип, эсеп-фактураны түзүңүз',
            BT_TITLE: 'Биздин банк реквизиттери',
            account: 'Каттоо эсеби',
            bank_name: 'Банктын аталышы',
            beneficiary: 'Бенефициар',
            beneficiary_address: 'Бенефициардын дареги',
            swift: 'тез',
            reference: 'Маалымдама',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Корреспонденттик эсеп',
            TOKEN_AMOUNT: 'Сумма',
            TOKEN_SUBTOTAL: 'Аралык сумма',
            TOKEN_TOTAL: 'Бардыгы',
            TOKEN_DESCRIPTION: 'сүрөттөмө',
            TOKEN_PAYMENT_DETAILS: 'Төлөмдүн чоо-жайы',
            PENDING_DEPOSITS_LIMIT: 'Депозиттин чеги',
            makeDeposit: 'Депозит салыңыз',
            practiceAccount: 'Машыгуу эсеби',
            realAccount: 'Чыныгы эсеп',
            noWithdrawals: 'Эч кандай акча алып салуу',
            easyWithdrawals: 'Жөнөкөй акча каражаттарын алуу',
            allAssets: 'Бардык активдер жеткиликтүү',
            fullFledged: 'Толук тепкичтүү платформа',
            fillUpTo: 'чейин толтур',
            freeReplenishment: 'Акысыз толуктоо',
            topUp: 'Аккаунтуңузду толуктоо',
            minimumAmount: 'Минималдуу сумма',
            canSwitch: 'Каалаган убакта аккаунттарыңыздын ортосунда которула аласыз'
        },
        gridsModal: {
            chartGrids: 'диаграмма торлору',
            chart1: '1 диаграмма',
            chart2: '2 диаграмма',
            chart3: '3 диаграмма',
            chart4: '4 диаграмма',
        },
        noConnectionModal: {
            connectionLost: 'Сервер менен байланыш үзүлдү',
            retryNow: 'Азыр кайталап көрүңүз',
        },
        loginModal: {
            loginToTradeRoom: 'соода бөлмөсүнө кирүү',
            email: 'Электрондук почта дареги',
            enterEmail: 'Электрондук почтаңызды киргизиңиз',
            enterTwoFactor: 'Enter 2FA code',
            twoFactor: 'эки фактордук аутентификация коду (эгер иштетилген болсо)',
            twoFactorAuth: 'Эки факторлуу аутентификация',
            password: 'Купуя сөз',
            yourPassword: 'Сиздин сырсөзүңүз',
            signIn: 'Кирүү',
            dontHaveAccount: 'Каттоо эсебиңиз жокпу?',
            restore: 'Калыбына келтирүү',
            fullName: 'Толук аты',
            havePromo: 'Менде промо-код бар',
            promo: 'Промо код',
            login: 'Логин',
            haveAccount: 'Каттоо эсебиңиз барбы?',
            hide: 'Жашыруу',
            goTo: 'Артка кет'
        },
        newPasswordModal: {
            setNewPassword: 'Жаңы сырсөз коюу',
            newPassword: 'Жаңы сыр сөз',
            confirmNewPassword: 'Жаңы сырсөздү ырастаңыз',
            setButton: 'Топтом'
        },
        registerModal: {
            repeatPassword: 'Сырсөздү кайталаңыз',
            createNewAccount: 'Жаңы каттоо эсебин түзүү',
            email: 'Электрондук почта дареги',
            firstName: 'Ысым',
            lastName: 'Акыркы аты',
            country: 'Өлкө',
            phone: 'Телефон',
            password: 'Купуя сөз',
            createAccount: 'Эсеп түзүү',
            currency: 'Валюта',
            privacyAndPolicy: 'Купуялык жана саясат',
            customerAgreement: 'Кардар келишими',
        },
        forgotPasswordModal: {
            forgotPassword: 'Паролду унуттуңузбу?',
            submitEmail: 'Каттоо үчүн колдонулган электрондук почтаны тапшырыңыз, почтаңызды текшериңиз жана берилген нускамаларды аткарыңыз',
            submit: 'тапшырыңыз'
        },
        notifications: {
            error: 'Ката',
            success: 'ийгилик',
            deposit: 'Депозит',
            withdrawal: 'алып салуу',
            depositApproved: 'Депозит бекитилди',
            depositDeclined: 'Депозит четке кагылды',
            withdrawalApproved: 'Чыгып алуу жактырылды',
            withdrawalDeclined: 'Чыгып алуу четке кагылды'
        },
        markets: {
            favorites: 'Көзөмөл тизмеси', 
            all: 'Бардык символдор',
            forex: 'форекс',
            stocks: 'запастар',
            commodities: 'товарлар',
            indices: 'Индекстер',
            crypto: 'Крипто',
            metals: 'Металлдар',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            priceFromProfit: 'Пайдадан баа',
            buy: 'сатып',
            profitCalculator: 'Пайда эсептегич',
            sell: 'сат',
            volume: 'Көлөм',
            entryPrice: 'Кирүү баасы',
            takeProfit: 'Profit',
            stopLoss: 'Жоголууну токтотуу',
            maxPosition: 'Максималдуу позиция',
            calculate: 'Эсептеңиз',
            calculationsFor: 'үчүн эсептөөлөр',
            leverage: 'Левередж',
            requiredMargin: 'Талап кылынган маржа',
            profitFromTP: 'TP пайда',
            lossFromSL: 'SL ден жоготуу',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Депозит ийгиликтүү',
            text: 'Депозитиңиз ийгиликтүү иштетилди!',
        },
        depositFailModal: {
            title: 'Депозит ишке ашкан жок',
            text: 'Депозитиңиз иштетилген жок.',
        },
        widgetMarketWatch: {
            marketWatch: 'Базар күзөтү',
            search: 'изде...',
        },
        widgetOrders: {
            commission: 'Комиссия',
            volume: 'Көлөм',
            margin: 'Маржа',
            active: 'Активдүү',
            pending: 'Күтүүдө',
            activeOrders: 'Активдүү заказдар',
            portfolio: 'Портфолио',
            allPositions: 'Бардык Позициялар',
            noPositionsLine1: 'Сенде ачык жок',
            noPositionsLine2: 'позициялары азырынча',
            show: 'шоу',
            more: 'көбүрөөк',
            less: 'азыраак',
            purchaseTime: 'Сатып алуу убактысы',
            closePosition: 'Позицияны жабуу',
            priceOpen: 'Сатып алуу баасы',
            priceSl: 'жоготууларды токтотуу баасы',
            priceTp: 'Пайда баасын алыңыз',
            type: 'Позиция багыты',
            pnl: 'Пайда/зыян',
            cancelOrder: 'Буйрутманы жокко чыгаруу',
            orderType: 'Заказ түрү',
            cancelled: 'Жокко чыгарылды',
            tradingHistory: 'соода тарыхы',
            noHistoryLine1: 'Сизде эч нерсе жок',
            noHistoryLine2: 'Жабылган бүтүмдөр барбы',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Календар'
        },
        widgetMarketNews: {
            marketNews: 'Базар жаңылыктары'
        },
        ordersPane: {
            closeInTime: 'Убагында жабуу',
            timedOrder: 'Убактылуу тартип',
            ordersActive: 'Активдүү заказдар',
            ordersHistory: 'Заказдардын тарыхы',
            id: 'идентификация',
            symbol: 'Символ',
            type: 'түрү',
            volume: 'Көлөм',
            openPrice: 'Ачык баа',
            openTime: 'Ачык убакыт',
            closePrice: 'Жабу баасы',
            closeTime: 'Жабуу убактысы',
            sl: 'SL',
            tp: 'TP',
            price: 'Баасы',
            pnl: 'PnL',
            actions: 'Аракеттер',
            edit: 'Түзөтүү',
            close: 'Жабуу',
            commission: 'Комиссия',
            swap: 'алмашуу',
            swapLong: 'Узак своп',
            swapShort: 'Кыска своп '
        },
        assetInfoModal: {
            assetInfo: 'Актив маалыматы',
            tradingConditions: 'Соода шарттары',
            information: 'Сеанс өзгөртүү',
            bid: 'сунуш кылуу',
            ask: 'сура',
            sessionChange: 'Сеанс өзгөртүү',
            tradeNow: 'Азыр соода',
            opens: 'ачат',
            closes: 'Жабат',
            at: 'саат',
            open247: '24/7 ачык',
            today: 'бугун',
            tomorrow: 'эртең',
            sunday: 'Жекшемби',
            monday: 'Дүйшөмбү',
            tuesday: 'Шейшемби',
            wednesday: 'Шаршемби',
            thursday: 'Бейшемби',
            friday: 'Жума',
            saturday: 'Ишемби',
            contractSpecification: 'Контракттын спецификациясы',
            symbol: 'Символ',
            name: 'аты',
            market: 'Базар',
            baseCurrency: 'Базалык валюта',
            digits: 'Сандар',
            lotSize: 'Лот өлчөмү',
            lotStep: 'Лот кадам',
            minVolume: 'Минималдуу көлөм',
            maxVolume: 'Максималдуу көлөм',
            leverage: 'Левередж',
            commission: 'Комиссия',
            swapLong: 'Узак своп',
            swapShort: 'Кыска своп ',
            schedule: 'График',
            weekday: 'Иш күнү',
            tradingTime: 'Соода убактысы',
            closed: 'жабык',
            sell: 'сат',
            buy: 'сатып',
            low: 'Төмөн',
            high: 'Жогорку',
            oneHourChange: '1 саат өзгөртүү',
            oneDayChange: '1 күн өзгөртүү',
            oneWeekChange: '1 жума өзгөртүү',
            oneMonthChange: '1 ай өзгөртүү',
            threeMonthsChange: '3 ай өзгөртүү',
            oneYearChange: '1 жыл өзгөрүү',
            loading: 'Жүктөө...',
        },
        autoTrader: {
            days: 'күндөрү',
            title: 'автотрейдер',
            titleAi: 'AI Trader',
            customTitle: 'Убакыт мезгили автотрейдер',
            riskLevelLow: 'Тобокелдик деңгээли: Төмөн',
            riskLevelMedium: 'Тобокелдик деңгээли: Орто',
            riskLevelHigh: 'Тобокелдик деңгээли: Жогорку',
            perDay: 'Күнүнө соода:',
            desc: `CFDs жана башка рычагдар өнүмдөрдү соодалоо жоготууларга алып келиши мүмкүн. соода алдында, кардарлар окуу керек
                Тобокелдиктерди ачыкка чыгаруу баракчабыздагы тиешелүү тобокелдик билдирүүлөрү. Автоматташтырылган соода натыйжаларга кепилдик бербейт. 
                Компания автоматтык соодада каражаттардын жоголушу үчүн жоопкерчиликти албайт.
                 Сураныч, тобокелдиктерди толук түшүнгөнүңүзгө жана тобокелдиктерди башкаруу боюнча чараларды көрүүгө ынаныңыз.`,
            accept: 'Кабыл алуу',
            save: 'сактоо',
            cancel: 'Жокко чыгаруу',
        },
        cardBox: {
            name: 'аты',
            cardNumber: 'Карта номери',
            expiration: 'Жарамдуулук мөөнөтү (мм/жж)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Салам',
            trade: "Сауда",
            startTrading: 'Соода баштаңыз',
            deposit: 'Депозит',
            dashboard: 'Куралдар тактасы',
            personalInfo: 'Жеке маалымат',
            contactInfo: 'Байланыш маалыматы',
            withdrawal: 'алып салуу',
            verification: 'Текшерүү',
            accounts: 'Эсептер',
            liveChat: 'Жандуу чат',
            savings: 'Үнөмдөө',
            referrals: 'Рефералдык программа',
            settings: 'Орнотуулар',
            logOut: 'Чыгуу',
        },
        fullScreenDeposit: {
            methods: 'ыкмалары',
            confirm: 'Банкыңыздын өтүнмөсүнөн ырастаңыз.',
            sendOtp: 'otp жөнөтүү',
            otpCode: 'Otp коду',
            addWalletAddress: 'Бул депозиттик ыкма үчүн капчык орнотулган эмес. Сураныч, агентиңизге же колдоо тобуңузга кайрылыңыз.',
            addressReceipt: 'Сураныч, даректин жүктөлүшүн күтө туруңуз',
            makeDeposit: 'АМАНАТ ТҮШҮҢҮЗ',
            instant: 'Ыкчам',
            minutes: 'мүнөттөр',
            hours: 'сааттары',
            days: 'күндөрү',
            amount: 'Сумма',
            continue: 'Улантыңыз',
            qrCode: 'QR коду',
            depositAddress: 'Депозиттик дарек',
            copy: 'Көчүрүү',
            last: 'акыркы',
            deposits: 'Депозиттер',
            time: 'Убакыт',
            currency: 'Валюта',
            status: 'Статус',
            info: 'Маалымат',
        },
        fullScreenCcExt: {
            pleaseWait: 'Күтө туруңуз. Төлөм кызматын камсыздоочуга туташууда...',
            cardPayment: 'Карта төлөмү',
            inOrder: 'сумманы жөнөтүү үчүн',
            pleaseProvide: 'картанын чоо-жайын бериңиз:'
        },
        fullScreenDashboard: {
            completeVerification: 'Сураныч, текшерүүнү бүтүрүңүз',
            noOrderYet: 'Азырынча заказ жок',
            totalBalance: 'Жалпы баланс',
            totalPnl: 'Жалпы PNL',
            profitableOrders: 'Пайдалуу заказдар',
            roi: 'ROI',
            activityLog: 'Аракеттер журналы',
            loginFromIp: 'IPден кирүү',
            tradingResults: 'Сооданын натыйжалары',
            week: 'Апта',
            month: 'ай',
            year: 'Жыл',
            successRate: 'Ийгилик деңгээли',
            closedWithProfit: 'Пайда менен жабылды',
            closedWithLoss: 'Жоготуу менен жабылды',
            account: 'Каттоо эсеби',
            balance: 'Баланс',
            leverage: 'Левередж',
            credit: 'Кредит',
            tradeNow: 'Азыр соода',
            usingCurrentRate: 'учурдагы алмашуу курсун колдонуу',
            totalDeposits: 'Жалпы депозиттер',
            totalWithdrawals: "Жалпы чыгарылган каражат",
            returnOnInvestment: "Инвестициянын кайтарымы"
        },
        fullScreenPersonal: {
            personalDetails: 'Жеке маалыматтар',
            profilePhoto: 'Профиль сүрөтү',
            firstName: 'Ысым',
            country: 'өлкө',
            lastName: 'Акыркы аты',
            address: 'Дарек',
            email: 'электрондук почта',
            phone: 'Телефон номери',
            saveChanges: 'Өзгөрүүлөрдү сактоо',
            state: 'шаар',
            postcode: 'ZIP',
            created: 'Аккаунт түзүлгөн күнү',
        },
        fullScreenAvatar: {
            dropFile: 'Жүктөп берүү үчүн файлды жогорудагы тегерекке таштаңыз',
            notAllowed: 'Жарыялоого жол берилбейт',
            notAllowedLine1: 'Ачык сексуалдык же порнографиялык мүнөздөгү сүрөттөр',
            notAllowedLine2: 'Этникалык же расалык кастыкты же агрессияны козутууга багытталган сүрөттөр',
            notAllowedLine3: '18 жашка чейинкилер тартылган сүрөттөр',
            notAllowedLine4: 'Үчүнчү тараптын автордук укук менен корголгон сүрөттөрү',
            notAllowedLine5: '5 МБдан чоңураак жана JPG, GIF же PNG форматынан башка форматтагы сүрөттөр',
            requirements: 'Сүрөттө сиздин жүзүңүз даана көрүнүп турушу керек. Сиз жүктөгөн бардык сүрөттөр жана видеолор бул талаптарга жооп бериши керек, антпесе аларды алып салууга болот.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Акчаны алуу чоо-жайы үчүн эсеп менеджериңизге кайрылыңыз',
            cryptocurrency: 'Криптовалюта',
            withdrawal: 'Алып салуу',
            requestNewWithdrawal: 'Акча алуу өтүнүчтөрү',
            amount: 'Сумма',
            currentBalance: 'Сиздин учурдагы эсебиңиздин балансы',
            withdrawAll: 'Баарын тарт',
            requestWithdrawal: 'өтүнүч Алып салуу',
            last: 'акыркы',
            withdrawalRequests: 'өтүнүч Алып салуу',
            time: 'Убакыт',
            currency: 'Валюта',
            info: 'Маалымат',
            status: 'Статус',
            bank: 'Банк',
            bitcoin: 'биткоин',
            zelle: 'зелле',
            card: 'Карта',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Матик',
            withdrawalDetails: 'Акча алуунун чоо-жайы',
            address: 'Капчык дареги',
            bankDetails: 'Банк реквизиттери',
            bankAccount: 'Эсеп номери',
            bankHolder: 'Эсеп ээсинин аты',
            bankIban: 'IBAN',
            bankSwift: 'Банк SWIFT коду',
            recipientAddress: 'Алуучунун дареги',
            routingNumber: 'Банк багыттоо номери',
            bankBeneficiary: 'Алуучунун банкатынын аты',
            bankAddress: 'Банктын дареги',
            bankCode: 'Банк коду (BSB)',
            desc: 'Сүрөттөмө',
            cardNumber: 'Карта номери',
            cardHolder: 'Карта ээсинин аты',
        },
        fullScreenVerification: {
            dragAndDrop: 'документти бул аймакка сүйрөп барып таштаңыз',
            documentsList: 'Жүктөлгөн документтердин тизмеси',
            document: 'Документ',
            timeUploaded: 'Жүктөлгөн убакыт',
            timeProcessed: 'Иштелген убакыт',
            status: 'Статус',
            types: ['Идентификатордун далили', 'Жашаган жерин тастыктаган документ', 'Кредиттик карта фронту', 'Кредиттик картаны кайтаруу', 'Идентификатордун Артка далили', 'селфи', 'Башка'],
            types2: ['Идентификатордун далили', 'Жашаган жерин тастыктоочу документ', 'Кредиттик карта фронту', 'Кредиттик карта кайра', 'Жеке күбөлүктүн арткы тарабынын далили', 'Идентификация менен селфи'],
            upload: 'жүктөп берүү',
            isConfirmed: 'тастыкталат',
            uploadAnotherDocument: 'жүктөп берүү башка документ',
            isUnderReview: 'каралып жатат',
            questionnaire: 'Анкета',
            sendAnswers: 'Жоопторду жөнөтүңүз',
            progressBarOf: 'нын',
            progressBarText: 'Сиздин документтериңиз жүктөлүп, тастыкталды'
        },
        fullScreenAccounts: {
            manageAccounts: 'Сиздин эсептердин ортосунда акча которуу',
            transferFrom: 'эсептен',
            transferTo: 'эсепке алуу',
            amount: 'Сумма',
            makeTransfer: 'Трансферт жасоо',
            yourAccounts: 'Сиздин аккаунттарыңыз',
            account: 'Каттоо эсеби',
            currency: 'Валюта',
            balance: 'Баланс',
            credit: 'Кредит',
            makeActive: 'активдүү кылуу',
            active: 'активдүү'
        },
        fullScreenSupport: {
            sendMessage: 'Кантип жардам бере алабыз?'
        },
        fullScreenSavings: {
            pct_0: 'ийкемдүү',
            pct_30: '1 айга жабылды',
            pct_90: '1 чейрек жабылды',
            pct_180: '6 ай жабык',
            pct_365: '1 жыл жабык',
            savings: 'Үнөмдөө',
            detailedInformation: 'Толук маалымат',
            pleaseSelect: 'Сураныч, валютаны жана мезгилди тандаңыз',
            openSavings: 'ачык Үнөмдөө',
            currentTime: 'Учурдагы убакыт',
            releaseTime: 'Чыгаруу убактысы',
            currencyChosen: 'Тандалган валюта',
            periodChosen: 'Тандалган мезгил',
            yourRate: 'Сиздин баа',
            yourEarnings: 'Сиздин кирешеңиз',
            accountFrom: 'Акча которуу үчүн эсеп',
            enterAmount: 'Сумманы киргизиңиз',
            in: 'ичинде',
            openAndInvest: 'ачык Үнөмдөө Эсеп жана инвестиция',
            investment: 'Инвестиция',
            period: 'Период',
            daysTotal: 'Жалпы күндөр',
            finalEarnings: 'акыркы киреше',
            created: 'түзүлгөн',
            daysElapsed: 'Күндөр өттү',
            days: 'күндөрү',
            earnings: 'Киреше',
            fundsReleasedIn: 'Чыгарылган каражаттар',
            claim: 'Доомат',
            name: 'аты',
        },
        fullScreenSettings: {
            updatePassword: 'Сырсөздү жаңыртуу',
            currentPassword: 'Учурдагы сырсөз',
            newPassword: 'жаңы сыр сөз',
            changePassword: 'Сырсөздү өзгөртүү',
            activationForGoogle: 'Google аныктыгын текшерүүчү үчүн активдештирүү коду',
            activateAndEnter: 'Аутентификацияны иштетиңиз жана төмөнкү талаага түзүлгөн кодду киргизиңиз',
            qrCode: 'QR коду',
            activateProtection: 'эки фактордук аутентификация коргоону иштетүү',
            protectionActive: 'эки фактордук аутентификация коргоо активдүү',
            twoFactorCode: 'эки фактордук аутентификация коду',
            disableProtection: 'Эки фактордук аутентификацияны өчүрүү',
            language: 'Тил',
            dashboardCurrency: 'Башкаруу тактасынын валютасы',
            confirmNewPassword: 'Жаңы сырсөздү ырастаңыз',
        },
        fullScreenReferrals: {
            yourLink: 'сиздин жолдомо шилтемеңиз',
            referredFriends: 'кайрылган достор',
            id: 'идентификация',
            name: 'аты',
            registered: 'Каттоо',
            level: 'Деңгээл',
            payout: 'Төлөм',
            lastPayouts: 'акыркы жолдомо төлөө',
            time: 'убакыт',
            amount: 'Сумма',
            currency: 'Валюта',
            info: 'маалымат',
            referralDesc:"Рефералдык программа - кымбаттуу инвесторлор коомчулугун кеңейтүүгө кошкон салымыңыз үчүн сизди сыйлоо максатында түзүлгөн.",
            shareLink:"Шилтемени бөлүшүңүз:",
            subtitle:"Ага атыңызды, email же соода ID бериңиз.",
            descSuccesfull:"Сиздин сунуш боюнча ал ийгиликтүү катталгандан кийин, Финансылык Кеңешчи алгачкы инвестициялык кадамдарына мүмкүн болушунча кесипкөй жардам берет.",
            descTracked:"Анын бардык салымдары сиздин сунушуңуз менен катталат."
        },
        months: {
            Jan: 'январь айы',
            Feb: 'февраль',
            Mar: 'Март',
            Apr: 'Апрель',
            May: 'Май',
            Jun: 'Июнь',
            Jul: 'Июль',
            Aug: 'Август',
            Sep: 'Сентябрь',
            Oct: 'Октябрь',
            Nov: 'Ноябрь',
            Dec: 'Декабрь'
        },
        orderTypes: ['Сатып алуу', 'Сат', 'Сатып алуу чеги', 'сатуу чеги', 'Сатып алуу токто', 'сатуу токто'],
        statuses: ['Күтүүдө', 'Бекитилди', 'Четке кагылды', 'Иштетүү'],
        errors: {
            NOT_FOUND: 'Сурам катасы',
            FORBIDDEN: 'Кирүү четке кагылды. Сураныч, кайра кириңиз',
            SERVER_ERROR: 'Аракет ишке ашкан жок. Сураныч, кийинчерээк кайра аракет кылыңыз',
            INVALID_ACCOUNT: 'Сеанс бүттү. Сураныч, кайра кириңиз',
            INVALID_EMAIL: 'Жараксыз электрондук почта дареги',
            INVALID_PHONE: 'Жараксыз телефон номери',
            INVALID_COUNTRY: 'Жараксыз өлкө',
            INVALID_LOGIN: 'Жараксыз кирүү эсептик дайындары',
            USER_BLOCKED: 'Каттоо эсеби бөгөттөлгөн. Сураныч, колдоо бөлүмүнө кайрылыңыз',
            USER_REGISTERED: 'Киргизилген электрондук почтасы же телефон номери бар колдонуучу мурунтан эле катталган',
            INVALID_USER: 'Колдонуучу табылган жок',
            REJECTED: 'Платформанын чектөөлөрүнөн улам операцияга жол берилбейт',
            INVALID_OLD_PASSWORD: 'Эски сырсөз жараксыз',
            INVALID_SYMBOL: 'Жараксыз соода белгиси',
            INVALID_SYMBOL_PRECISION: 'Символдун тактыгы жараксыз',
            INVALID_FRAME: 'Жараксыз диаграмма алкагы',
            INVALID_CURRENCY: 'Жараксыз валюта',
            INVALID_PRODUCT: 'Эсептин соода түрү жараксыз',
            INSUFFICIENT_FUNDS: 'Каражат жетишсиз',
            INVALID_WITHDRAWAL: 'Чыгаруу табылган жок',
            INVALID_STATUS: 'Жараксыз статус',
            INVALID_FILE: 'Жараксыз файл кеңейтүүсү же файл өтө чоң',
            INVALID_DOCUMENT: 'Документ табылган жок',
            INVALID_NAME: 'Жараксыз ат',
            INVALID_LOT_SIZE: 'Лоттун өлчөмү жараксыз',
            INVALID_LOT_STEP: 'Лот кадамы жараксыз',
            INVALID_MIN_VOLUME: 'Минималдуу көлөм жараксыз',
            INVALID_MAX_VOLUME: 'Максималдуу көлөм жараксыз',
            INVALID_GROUP: 'Жараксыз топ',
            INVALID_SPREAD: 'Жараксыз жайылуу',
            REQUEST_TIMEOUT: 'Сурамдын мөөнөтү бүттү. Сураныч, кийинчерээк кайра аракет кылыңыз',
            EXISTING_OPEN_ORDERS: 'Ачык буйрутмаңыз бар, адегенде бул колдонуучуга буйрутманы жаап, кайра аракет кылыңыз.',
            INVALID_TYPE: 'Буйрутма түрү жараксыз',
            INVALID_VOLUME: 'Буйрутма көлөмү жараксыз',
            INVALID_SL: 'Токтоо жоготуу мааниси жараксыз',
            INVALID_TP: 'Жараксыз пайданын мааниси',
            INVALID_PRICE: 'Буйрутма баасы жараксыз',
            INVALID_EXPIRATION: 'Буйрутманын мөөнөтү жараксыз',
            NOT_ENOUGH_MARGIN: 'Маржа жетишсиз',
            INVALID_ORDER: 'Заказ табылган жок',
            MARKET_CLOSED: 'Соода мүмкүн эмес. Сураныч, кайра аракет кылыңыз',
            INVALID_BALANCE_TYPE: 'Баланс операциясынын түрү жараксыз',
            INVALID_HASH: 'Жараксыз хэш',
            HASH_EXPIRED: 'Сырсөзүңүздү баштапкы абалга келтирүү шилтемесинин мөөнөтү бүттү. Сураныч, жаңысын сураңыз',
            INVALID_CODE: 'жараксыз эки фактордук аутентификация код',
            CHAT_DISABLED: 'Колдоо чатына кирүү жабылды',
            WITHDRAWAL_NOT_ALLOWED: 'Акча каражаттарын алуу мүмкүнчүлүгү жабылды',
            TRADING_DISABLED: 'Учурдагы эсеп үчүн соодалоо өчүрүлгөн',
            PENDING_DEPOSITS_LIMIT: 'Депозиттер чегинен ашып кетти',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Бир гана күтүүдөгү алып салууга уруксат берилди',
            LOCAL_DEPOSIT_ONLY_REAL: 'Депозит чыныгы эсеп үчүн гана жеткиликтүү',
            LOCAL_DEPOSIT_POSITIVE: 'Депозиттин суммасы 0ден ашык болушу керек',
            LOCAL_INVALID_CARD_NUMBER: 'Картанын номери жараксыз',
            LOCAL_INVALID_CARD_EXPIRATION: 'Картанын мөөнөтү жараксыз',
            LOCAL_INVALID_CVC: 'CVV/CVC коду жараксыз',
            LOCAL_PASSWORDS_NOT_MATCH: 'Сырсөздөр дал келбейт, кайра киргизиңиз',
            LOCAL_NO_TRADING_ACCOUNTS: 'Сизде соода эсептери жок. Сураныч, колдоо бөлүмүнө кайрылыңыз',
            LOCAL_MIN_DEPOSIT: 'Минималдуу депозит 1 доллар',
            LOCAL_MIN_WITHDRAWAL: 'Минималдуу алуу суммасы 1 долларды түзөт',
            LOCAL_INVALID_FILE_TYPE: 'Файл форматы колдоого алынбайт. Сураныч, JPG, PNG же PDF гана тиркеңиз',
            LOCAL_MIN_STAKING: 'Бул валюта үчүн минималдуу депозит',
            FLOOD_REJECTED: 'Сиз өтө көп билдирүү жөнөтүп жатасыз. Сураныч, кийинчерээк кайра аракет кылыңыз'
        },
        countries: {
            AF: 'Ооганстан',
            AX: 'Аланд аралдары',
            AL: 'Албания',
            DZ: 'Алжир',
            AS: 'Америкалык Самоа',
            AD: 'Андора',
            AO: 'Ангола',
            AI: 'Ангилья',
            AQ: 'Антарктида',
            AG: 'Антигуа жана Барбуда',
            AR: 'Аргентина',
            AM: 'Армения',
            AW: 'Аруба',
            AU: 'Австралия',
            AT: 'Австрия',
            AZ: 'Азербайжан',
            BS: 'Багам аралдары',
            BH: 'Бахрейн',
            BD: 'Бангладеш',
            BB: 'Барбадос',
            BY: 'Беларус',
            BE: 'Белгия',
            BZ: 'Белиз',
            BJ: 'Бенин',
            BM: 'Бермуда',
            BT: 'Бутан',
            BO: 'Боливия',
            BA: 'Босния жана Герцеговина',
            BW: 'Ботсвана',
            BV: 'Бувет аралы',
            BR: 'Бразилия',
            IO: 'Британдык Инди океанынын аймагы',
            BN: 'Бруней Даруссалам',
            BG: 'Болгария',
            BF: 'Буркина Фасо',
            BI: 'Бурунди',
            KH: 'Камбожа',
            CM: 'Камерун',
            CA: 'Канада',
            CV: 'Кабо-Верде',
            KY: 'Кайман аралдары',
            CF: 'Борбордук Африка Республикасы',
            TD: 'Чад',
            CL: 'Чили',
            CN: 'Кытай',
            CX: 'Рождество аралы',
            CC: 'Кокос (Килинг) аралдары',
            CO: 'Колумбия',
            KM: 'Комор аралдары',
            CG: 'Конго',
            CD: 'Конго, Демократиялык Республикасы',
            CK: 'Кук аралдары',
            CR: 'Коста Рика',
            CI: 'Пил тиши жееги',
            HR: 'Хорватия',
            CU: 'Куба',
            CY: 'Кипр',
            CZ: 'Чех Республикасы',
            DK: 'Дания',
            DJ: 'Жибути',
            DM: 'Доминика',
            DO: 'Доминика республикасы',
            EC: 'Эквадор',
            EG: 'Мысыр',
            SV: 'Сальвадор',
            GQ: 'Экватордук Гвинея',
            ER: 'Эритрия',
            EE: 'Эстония',
            ET: 'Эфиопия',
            FK: 'Фолкленд аралдары (Мальвин)',
            FO: 'Фарер аралдары',
            FJ: 'Фижи',
            FI: 'Финляндия',
            FR: 'Франция',
            GF: 'Француз Гвианасы',
            PF: 'Француз Полинезиясы',
            TF: 'Франциянын түштүк аймактары',
            GA: 'Габон',
            GM: 'Гамбия',
            GE: 'Грузия',
            DE: 'Германия',
            GH: 'Гана',
            GI: 'Гибралтар',
            GR: 'Греция',
            GL: 'Гринландия',
            GD: 'Гренада',
            GP: 'Гваделупа',
            GU: 'Гуам',
            GT: 'Гватемала',
            GG: 'Гернси',
            GN: 'Гинея',
            GW: 'Гвинея-Бисау',
            GY: 'Гайана',
            HT: 'Гаити',
            HM: 'Херд аралы жана Макдональд аралдары',
            VA: 'Ыйык Такта (Ватикан мамлекети)',
            HN: 'Гондурас',
            HK: 'Гонконг',
            HU: 'Венгрия',
            IS: 'Исландия',
            IN: 'Индия',
            ID: 'Индонезия',
            IR: 'Иран',
            IQ: 'Ирак',
            IE: 'Ирландия',
            IM: 'Мэн аралы',
            IL: 'Израиль',
            IT: 'Италия',
            JM: 'Ямайка',
            JP: 'Жапония',
            JE: 'Джерси',
            JO: 'Иордания',
            KZ: 'Казакстан',
            KE: 'Кения',
            KI: 'Кирибати',
            KR: 'Корея',
            KW: 'Кувейт',
            KG: 'Кыргызстан',
            LA: 'Лаос Элдик Демократиялык Республикасы',
            LV: 'Латвия',
            LB: 'Ливан',
            LS: 'Лесото',
            LR: 'Либерия',
            LY: 'Ливия Араб Джамахириясы',
            LI: 'Лихтенштейн',
            LT: 'Литва',
            LU: 'Люксембург',
            MO: 'Макао',
            MK: 'Македония',
            MG: 'Мадагаскар',
            MW: 'Малави',
            MY: 'Малайзия',
            MV: 'Малдивдер',
            ML: 'Мали',
            MT: 'Малта',
            MH: 'Маршал аралдары',
            MQ: 'Мартиника',
            MR: 'Мавритания',
            MU: 'Маврикий',
            YT: 'Майотта',
            MX: 'Мексика',
            FM: 'Микронезия',
            MD: 'Молдова',
            MC: 'Монако',
            MN: 'Монголия',
            ME: 'Монтенегро',
            MS: 'Монтсеррат',
            MA: 'Марокко',
            MZ: 'Мозамбик',
            MM: 'Мьянма',
            NA: 'Намбия',
            NR: 'Науру',
            NP: 'Непал',
            NL: 'Голландия',
            AN: 'Нидерланд Антиль аралдары',
            NC: 'Жаңы Каледония',
            NZ: 'Жаңы Зеландия',
            NI: 'Никарагуа',
            NE: 'Нигер',
            NG: 'Нигерия',
            NU: 'Ниуэ',
            NF: 'Норфолк аралы',
            MP: 'Түндүк Мариан Аралдары',
            NO: 'Норвегия',
            OM: 'Оман',
            PK: 'Пакистан',
            PW: 'Палау',
            PS: 'Палестина жери, оккупацияланган',
            PA: 'Панама',
            PG: 'Папуа Жаңы Гвинея',
            PY: 'Парагвай',
            PE: 'Перу',
            PH: 'Филиппиндер',
            PN: 'Питкэрн',
            PL: 'Польша',
            PT: 'Португалия',
            PR: 'Пуэрто Рико',
            QA: 'Катар',
            RE: 'Reunion',
            RO: 'Румыния',
            RU: 'Орусия',
            RW: 'Руанда',
            BL: 'Сент-Бартелеми',
            SH: 'Ыйык Елена аралы',
            KN: 'Сент-Китс жана Невис',
            LC: 'Сент-Люсия',
            MF: 'Сент-Мартин',
            PM: 'Сент-Пьер жана Микелон',
            VC: 'Сент-Винсент жана Гренадиндер',
            WS: 'Самоа',
            SM: 'Сан Марино',
            ST: 'Сан-Томе жана Принсипи',
            SA: 'Сауд Арабиясы',
            SN: 'Сенегал',
            RS: 'Сербия',
            SC: 'Сейшел аралдары',
            SL: 'Сьерра-Леоне',
            SG: 'Сингапур',
            SK: 'Словакия',
            SI: 'Словения',
            SB: 'Соломон Аралдары',
            SO: 'Сомалия',
            ZA: 'Түштүк Африка',
            GS: 'Түштүк Джорджия жана Сэндвич аралы.',
            ES: 'Испания',
            LK: 'Шри Ланка',
            SD: 'Судан',
            SR: 'Суринам',
            SJ: 'Шпицберген жана Ян Майен',
            SZ: 'Свазиленд',
            SE: 'Швеция',
            CH: 'Швейцария',
            SY: 'Сирия Араб Республикасы',
            TW: 'Тайвань',
            TJ: 'Тажикистан',
            TZ: 'Танзания',
            TH: 'Тайланд',
            TL: 'Тимор-Лесте',
            TG: 'Того',
            TK: 'Токелау',
            TO: 'Тонга',
            TT: 'Тринидад жана Тобаго',
            TN: 'Тунис',
            TR: 'Туркия',
            TM: 'Түркмөнстан',
            TC: 'Теркс жана Кайкос аралдары',
            TV: 'Тувалу',
            UG: 'Уганда',
            UA: 'Украина',
            AE: 'Кошмо Араб Эмираттар',
            GB: 'Бириккен королдук',
            US: 'Америка Кошмо Штаттары',
            UM: 'Америка Кошмо Штаттарынын четки аралдары',
            UY: 'Уругвай',
            UZ: 'Өзбекстан',
            VU: 'Вануату',
            VE: 'Венесуэла',
            VN: 'Вьетнам',
            VG: 'Виргин аралдары, Британия',
            VI: 'Виргин аралдары, АКШ.',
            WF: 'Уоллис и Футуна',
            EH: 'Батыш Сахара',
            YE: 'Йемен',
            ZM: 'Замбия',
            ZW: 'Зимбабве'
        },
    }
}

export default langs
